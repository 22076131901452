import { Button } from '@mui/material';

import { Flex } from '../Layout';

export interface ButtonListControlProps {
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
}

export const ButtonList = ({ options, value, onChange }: ButtonListControlProps) => {
  return (
    <Flex sx={{ flexWrap: 'wrap' }}>
      {options.map((option) => (
        <Button
          key={option.value}
          variant={value === option.value ? 'contained' : 'outlined'}
          onClick={() => onChange(option.value)}
          sx={{
            height: '32px',
            marginRight: '8px !important',
            marginTop: '8px !important',
            fontSize: '14px !important',
            color: 'black !important',
            border: value === option.value ? '1px solid var(--border-selected, #388BFF) !important' : undefined,
            background: value === option.value ? 'var(--background-selected-default, #F2F7FF) !important' : undefined,
            '&.MuiButton-sizeMedium': {
              borderRadius: '16px !important'
            },
            '&.MuiButton-text': {
              '&:not(:active, :hover)': {
                background: 'transparent'
              }
            }
          }}
        >
          {option.label}
        </Button>
      ))}
    </Flex>
  );
};
