import { styled } from '@mui/material/styles';

export const StyledCompanyDetails = styled('div')(() => ({
  display: 'block',
  '.company-details': {
    '&__info': {
      display: 'flex',
      alignItems: 'center'
    },
    '&__logo': {
      width: '136px',
      height: '136px',
      margin: '0 24px 0 0',
      borderRadius: '50%',
      img: {
        objectFit: 'cover',
        height: '100%'
      }
    },
    '&__status': {
      margin: '0 0 16px 0'
    }
  }
}));

StyledCompanyDetails.defaultProps = {
  className: 'company-details'
};
