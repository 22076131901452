import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import { GET_REGIONS_SIDEBAR, GetRegionsSidebarQuery } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';
import { NavListSimple } from '@/separatedModules/layouts/components/SideBarProvider/components/NavListSimple';

import { CompanyIcon, MuiTreeView } from '@/separatedModules/components';

import { LINKS_PAGES, LINKS_SIDEBAR_COMPANIES_MENU } from '@/subsidiaryBinders/constants';

import { ManageAccessModal } from './components/ManageAccessModal';
import { MoveRegionToRegionModal } from './components/MoveRegionToRegionModal/MoveRegionToRegionModal';
import { AddFolderModal, CompaniesActionsMenu, RenameFolderModal } from './components';

import { canCreateSubfolder, CompaniesActionTypeEnum, LocationFolder } from './helpers';

interface ModalState {
  isOpen: boolean;
  action?: CompaniesActionTypeEnum;
  nodeId?: string;
  locationName?: string;
}

export const SideBarCompanies = () => {
  const navigateTo = useNavigate();
  const { folderId } = useParams<{ folderId?: string }>();
  const { currentLocationFolderData, setCurrentLocationFolderData } = useLocations();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    action: undefined,
    nodeId: undefined,
    locationName: undefined
  });

  const { data: dataRegions, loading: loadingRegions } = useQuery<GetRegionsSidebarQuery>(GET_REGIONS_SIDEBAR, {
    variables: {
      pageSize: 100,
      page: 0
    }
  });

  const handleRegionClick = useCallback(
    (nodeId: string) => {
      if (nodeId === 'root') {
        setCurrentLocationFolderData({
          folderId: nodeId,
          canCreateFolder: true
        });
      } else {
        const canCreate = canCreateSubfolder(
          nodeId,
          dataRegions?.dataItems?.items.filter((item) => item != null) as LocationFolder[]
        );

        setCurrentLocationFolderData({
          folderId: nodeId,
          canCreateFolder: canCreate
        });
      }

      navigateTo(nodeId === 'root' ? LINKS_PAGES.companies : `${LINKS_PAGES.companies}/${nodeId}`);
    },
    [dataRegions, setCurrentLocationFolderData]
  );

  const handleOpenModal = (action: CompaniesActionTypeEnum) => {
    setAnchorEl(null);
    setModalState((prevState) => ({
      ...prevState,
      isOpen: true,
      action: action
    }));
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      action: undefined,
      nodeId: undefined,
      locationName: undefined
    });
  };

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (dataRegions) {
      const currentFolder = dataRegions.dataItems.items.find(
        (folder: any) => folder.id === currentLocationFolderData.folderId
      );

      const canCreate = canCreateSubfolder(
        currentLocationFolderData.folderId || '',
        dataRegions?.dataItems?.items.filter((item) => item != null) as LocationFolder[]
      );

      if (currentFolder) {
        setCurrentLocationFolderData({
          ...currentLocationFolderData,
          // @ts-ignore TODO: Fix type
          isActive: currentFolder?.active,
          canCreateFolder: canCreate
        });
      }
    }
  }, [
    dataRegions,
    setCurrentLocationFolderData,
    currentLocationFolderData.folderId,
    currentLocationFolderData.isActive
  ]);

  return (
    <Sidebar>
      {loadingRegions ? (
        <SideBarGroup>
          <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={32} animation={'wave'} />
        </SideBarGroup>
      ) : null}

      {dataRegions?.dataItems ? (
        <SideBarGroup>
          <MuiTreeView
            tree={{
              id: 'root',
              name: 'Companies',
              // @ts-ignore // TODO: Should fix type
              groupItems: dataRegions.dataItems
            }}
            defaultExpanded={['root']}
            rootItemIcon={<CompanyIcon />}
            onNodeSelect={handleRegionClick}
            onNodeActionClick={(event, node) => {
              setModalState((prevState) => ({
                ...prevState,
                nodeId: node.id,
                locationName: node.name
              }));
              handleClickButton(event);
            }}
            selected={folderId ?? 'root'}
          />

          <CompaniesActionsMenu
            anchorEl={anchorEl}
            folderId={modalState.nodeId}
            onClose={() => setAnchorEl(null)}
            onModalOpen={(action) => handleOpenModal(action)}
          />
        </SideBarGroup>
      ) : null}

      <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))', margin: '8px 0' }} />

      <SideBarGroup>
        <NavListSimple menu={LINKS_SIDEBAR_COMPANIES_MENU} />
      </SideBarGroup>

      {modalState.isOpen && modalState.nodeId ? (
        <>
          {modalState.action === CompaniesActionTypeEnum.RenameFolder ? (
            <RenameFolderModal isModalOpen folderId={modalState.nodeId} onCloseModal={handleCloseModal} />
          ) : null}

          {modalState.action === CompaniesActionTypeEnum.CreateFolder ? (
            <AddFolderModal isModalOpen folderId={modalState.nodeId} onCloseModal={handleCloseModal} />
          ) : null}

          {modalState.action === CompaniesActionTypeEnum.Move ? (
            <MoveRegionToRegionModal
              isModalOpen
              folderId={modalState.nodeId}
              locationName={modalState.locationName}
              onCloseModal={handleCloseModal}
            />
          ) : null}

          {modalState.action === CompaniesActionTypeEnum.ManageAccess ? (
            <ManageAccessModal
              isModalOpen
              folderId={modalState.nodeId}
              folderName={modalState.locationName}
              onCloseModal={handleCloseModal}
            />
          ) : null}
        </>
      ) : null}
    </Sidebar>
  );
};
