import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { ParameterType } from '@/logicLayers/domain';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n';

import { VisibilityIcon } from '../../../../../../../../components/Icon/svg-mui-theme/Visibility';
import { VisibilityOffIcon } from '../../../../../../../../components/Icon/svg-mui-theme/VisibilityOff';

interface TypeList {
  [key: string]: string;
}

export const PARAMETER_TYPE_LIST: TypeList = {
  NUMERIC_INPUT: LINKS_IMAGES.parameterTypeNumericInput,
  NUMERIC_FORMULA: LINKS_IMAGES.parameterTypeNumericFormula,
  NUMERIC_FIXED: LINKS_IMAGES.parameterTypeNumericFixed,
  BOOLEAN: LINKS_IMAGES.parameterTypeBoolean,
  STRING: LINKS_IMAGES.parameterTypeString,
  DATA_TIME: LINKS_IMAGES.parameterTypeDateTime
};

export const PARAMETER_TYPE: TypeList = {
  DATA_TIME: 'Date & Time',
  STRING: 'String',
  BOOLEAN: 'Boolean',
  NUMERIC_INPUT: 'Data input',
  NUMERIC_FORMULA: 'Set formula',
  NUMERIC_FIXED: 'Fixed value'
};

export const ParameterTypeTyped = {
  NUMERIC_INPUT: ParameterType.NumericInput.toUpperCase(),
  NUMERIC_FORMULA: ParameterType.NumericFormula.toUpperCase(),
  NUMERIC_FIXED: ParameterType.NumericFixed.toUpperCase(),
  DATA_TIME: ParameterType.DataTime.toUpperCase(),
  BOOLEAN: ParameterType.Boolean.toUpperCase(),
  STRING: ParameterType.String.toUpperCase()
};

export const isUnVisibleItemParams = [ParameterTypeTyped.NUMERIC_FIXED, ParameterTypeTyped.NUMERIC_FORMULA];

export const isVisibleItemParams = [
  ParameterTypeTyped.NUMERIC_INPUT,
  ParameterTypeTyped.BOOLEAN,
  ParameterTypeTyped.STRING,
  ParameterTypeTyped.DATA_TIME
];

export const ParametersVisibilityList = [
  {
    checked: false,
    label: i18n.t('equipmentItem.parameters.table.rowsCells.visible', { ns: 'templates' }),
    value: isVisibleItemParams,
    icon: <VisibilityIcon sx={{ fontSize: 20 }} />
  },
  {
    checked: false,
    label: i18n.t('equipmentItem.parameters.table.rowsCells.unVisible', { ns: 'templates' }),
    value: isUnVisibleItemParams,
    icon: <VisibilityOffIcon sx={{ fontSize: 20 }} />
  }
];

export const checkIsFormulaOrFixed = (type: string) => {
  const isFormulaOrFixed = isUnVisibleItemParams.includes(type.toUpperCase());

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isFormulaOrFixed ? (
        <>
          {ParametersVisibilityList[1].icon}
          <Text variant={'bodyMd'} sx={{ margin: '0 0 0 8px', fontSize: '16px' }}>
            {ParametersVisibilityList[1].label}
          </Text>
        </>
      ) : (
        <>
          {ParametersVisibilityList[0].icon}
          <Text variant={'bodyMd'} sx={{ margin: '0 0 0 8px', fontSize: '16px' }}>
            {ParametersVisibilityList[0].label}
          </Text>
        </>
      )}
    </Box>
  );
};
