import { Dayjs } from 'dayjs';

export const FrequencyLabels = {
  DAILY: 'every day',
  WEEKLY: 'every week',
  MONTHLY: 'every month',
  EVERY_3_MONTH: 'every 3 months',
  EVERY_6_MONTH: 'every 6 months',
  YEARLY: 'every year',
  SINGLE_TIME_ROUND: 'one-time round'
} as const;

export type Frequency = keyof typeof FrequencyLabels;

export const getDisplayText = (
  frequency: Frequency,
  selectedDate: Dayjs | null,
  selectedTime: Dayjs | null
): string => {
  const formattedDate = selectedDate?.format('D MMM YYYY');
  const formattedTime = selectedTime?.format('h:mm A');
  const dayOfWeek = selectedDate?.format('dddd');
  const baseText = 'Round will occur';
  const dateText = `${formattedTime} starting from ${formattedDate}`;

  switch (frequency) {
    case 'DAILY':
      return `${baseText} every day at ${dateText}`;
    case 'WEEKLY':
      return `${baseText} every week on ${dayOfWeek} at ${dateText}`;
    case 'MONTHLY':
      return `${baseText} every month at ${dateText}`;
    case 'EVERY_3_MONTH':
      return `${baseText} every 3 months at ${dateText}`;
    case 'EVERY_6_MONTH':
      return `${baseText} every 6 months at ${dateText}`;
    case 'YEARLY':
      return `${baseText} every year at ${dateText}`;
    case 'SINGLE_TIME_ROUND':
      return `${baseText} one-time at ${dateText}`;
    default:
      return 'Please, choose the round frequency';
  }
};
