import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PlusIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'21'}
      viewBox={'0 0 20 21'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: '21px !important', ...sx }}
      {...restProps}
    >
      <path d="M9.37516 11.1249H5.2085C5.03141 11.1249 4.88298 11.065 4.76318 10.9451C4.64339 10.8253 4.5835 10.6768 4.5835 10.4996C4.5835 10.3225 4.64339 10.1741 4.76318 10.0544C4.88298 9.93474 5.03141 9.87492 5.2085 9.87492H9.37516V5.70825C9.37516 5.53117 9.43508 5.38273 9.55491 5.26294C9.67476 5.14315 9.82326 5.08325 10.0004 5.08325C10.1776 5.08325 10.326 5.14315 10.4456 5.26294C10.5653 5.38273 10.6251 5.53117 10.6251 5.70825V9.87492H14.7918C14.9689 9.87492 15.1173 9.93484 15.2371 10.0547C15.3569 10.1745 15.4168 10.323 15.4168 10.5002C15.4168 10.6773 15.3569 10.8257 15.2371 10.9454C15.1173 11.0651 14.9689 11.1249 14.7918 11.1249H10.6251V15.2915C10.6251 15.4686 10.5652 15.6171 10.4454 15.7369C10.3255 15.8566 10.177 15.9165 9.99987 15.9165C9.8227 15.9165 9.67429 15.8566 9.55464 15.7369C9.43499 15.6171 9.37516 15.4686 9.37516 15.2915V11.1249Z" />
    </SvgIcon>
  );
}
