import { UPDATE_COMPANY } from '@/logicLayers/domain';

import { COMPANY_ITEM_FIELDS } from '@/separatedModules/pages/Company';

import { EditInputItem } from '@/separatedModules/components';

import {
  ADDRESS_NAME_REG_EXP,
  COMPANY_NAME_REG_EXP,
  COMPANY_REPRESENTATIVE_REG_EXP,
  EMAIL_REG_EXP,
  USER_NUMBER_MASK
} from '@/utils';

import {
  GetEditComponentProps,
  onSubmitFormCompanyAddress,
  onSubmitFormCompanyEmail,
  onSubmitFormCompanyName,
  onSubmitFormCompanyPhoneNumber,
  onSubmitFormCompanyRepresentative
} from './helpers';

export const CompanyEditProvider = ({
  /* custom fields */
  editType,
  navigateToUrl,
  tCompany,
  tGlobal,
  i18n,
  /* Company fields */
  ...restCompany
}: GetEditComponentProps) => {
  const maskPattern = USER_NUMBER_MASK[i18n.language];

  return editType === COMPANY_ITEM_FIELDS.NAME ? (
    <EditInputItem
      editFieldName={'name'}
      editFieldValue={restCompany.company.name}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldLength')}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      inputLabel={tCompany('create.blank.companyNameLabel')}
      pageTitle={tCompany('settings.mainInfo.companyName')}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_COMPANY}
      regExp={COMPANY_NAME_REG_EXP}
      onSubmitForm={onSubmitFormCompanyName({ ...restCompany })}
    />
  ) : editType === COMPANY_ITEM_FIELDS.ADDRESS ? (
    <EditInputItem
      editFieldName={'address'}
      editFieldValue={restCompany.company.address as string}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.addressValid')}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      inputLabel={tCompany('edit.address.filedLabel')}
      pageTitle={tCompany('settings.mainInfo.companyAddress')}
      pageSubTitle={tCompany('edit.address.pageSubTitle')}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_COMPANY}
      regExp={ADDRESS_NAME_REG_EXP}
      onSubmitForm={onSubmitFormCompanyAddress({ ...restCompany })}
    />
  ) : editType === COMPANY_ITEM_FIELDS.REPRESENTATIVE ? (
    <EditInputItem
      editFieldName={'representative'}
      editFieldValue={restCompany.company.contactInfo?.representative as string}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.representativeValid')}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      inputLabel={tCompany('edit.representative.filedLabel')}
      pageTitle={tCompany('settings.contactInfo.companyRepresentative')}
      pageSubTitle={tCompany('edit.representative.pageSubTitle')}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_COMPANY}
      regExp={COMPANY_REPRESENTATIVE_REG_EXP}
      onSubmitForm={onSubmitFormCompanyRepresentative({ ...restCompany })}
    />
  ) : editType === COMPANY_ITEM_FIELDS.PHONE_NUMBER ? (
    <EditInputItem
      editFieldType={'phone'}
      editFieldName={'phoneNumber'}
      editFieldValue={restCompany.company.contactInfo?.phoneNumber as string}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.phoneRequired')}
      patternMessage={tGlobal('validation.phoneValid')}
      inputLabel={tCompany('edit.number.filedLabel')}
      pageTitle={tCompany('settings.contactInfo.companyPhoneNumber')}
      pageSubTitle={tCompany('edit.number.pageSubTitle')}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_COMPANY}
      regExp={maskPattern.patternHookForm}
      onSubmitForm={onSubmitFormCompanyPhoneNumber({ ...restCompany })}
    />
  ) : editType === COMPANY_ITEM_FIELDS.EMAIL ? (
    <EditInputItem
      editFieldName={'email'}
      editFieldValue={restCompany.company.contactInfo?.email as string}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.emailValid')}
      inputLabel={tCompany('edit.email.filedLabel')}
      pageTitle={tCompany('settings.contactInfo.companyPhoneEmail')}
      pageSubTitle={tCompany('edit.email.pageSubTitle')}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_COMPANY}
      regExp={EMAIL_REG_EXP}
      onSubmitForm={onSubmitFormCompanyEmail({ ...restCompany })}
    />
  ) : null;
};
