import { memo, ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

interface MultiStepHeaderProps {
  title: string;
  titleStep: string;
  currentStep: number;
  totalStep: number;
  description?: ReactNode;
  sxBox?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
}

export const MultiStepHeader = memo(
  ({ title, titleStep, currentStep, totalStep, description, sxBox, sxDescription }: MultiStepHeaderProps) => {
    const percentWidth = ((currentStep / totalStep) * 100).toFixed();

    return (
      <Box sx={{ padding: '0 0 24px', ...sxBox }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text variant={'titleXl'}>{title}</Text>

          <Text variant={'detail'}>{titleStep}</Text>
        </Box>

        {description ? (
          <Text variant={'bodyLg'} sx={{ color: ' var(--text-subtle, #505668)', ...sxDescription }}>
            {description}
          </Text>
        ) : null}

        <Box
          sx={{
            height: '8px',
            overflow: 'hidden',
            borderRadius: 'var(--8, 8px)',
            background: 'var(--background-neutral-subtle-hovered, rgba(9, 14, 22, 0.06))'
          }}
        >
          <Box
            sx={{ height: '100%', width: `${percentWidth}%`, background: 'var(--Base-tokens-Blue-Blue500, #388BFF)' }}
          />
        </Box>
      </Box>
    );
  }
);
