import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled('button')(() => ({
  background: 'white',
  border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
  borderRadius: '16px',
  outline: 'none',
  padding: '5px 12px',
  transition: '.25s ease',
  cursor: 'pointer',

  ':hover': {
    background: '#090E160F'
  },

  ':disabled': {
    pointerEvents: 'none',
    color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))',
    borderColor: '#090E160F'
  },

  '&.Mui-selected': {
    background: 'var(--background-selected-default, #F2F7FF)',
    border: '1px solid var(--border-selected, #388BFF)'
  }
}));

export const StyledSearchInput = styled(OutlinedInput)(() => ({
  height: '32px',
  borderRadius: '16px',
  border: '1px solid #090E1626',
  fontSize: '14px',
  paddingLeft: '12px',

  '.MuiSvgIcon-root': {
    color: 'inherit'
  }
}));

interface CounterProps {
  disabled?: boolean;
}

export const StyledCounter = styled('div')(({ disabled }: CounterProps) => ({
  minWidth: '18px',
  height: '18px',
  background: '#020204',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '4px !important',
  borderRadius: '50%',
  color: '#FFFFFF',
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 500,

  ...(disabled && {
    background: '#090E164D'
  })
}));
