import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { ImageLazy, PhotoCameraIcon } from '@/separatedModules/components';

interface AvatarImagePreviewButtonProps {
  imgAlt: string;
  oldImage?: string;
  imageWidth?: number;
  imageHeight?: number;
  sxBox?: SxProps<Theme>;
  sxImageWrap?: SxProps<Theme>;
  sxImage?: SxProps<Theme>;
  children?: ReactNode;
  onClick?: () => void;
}

export const AvatarImagePreviewButton = ({
  imgAlt,
  oldImage,
  imageWidth = 136,
  imageHeight = 136,
  sxBox,
  sxImage,
  sxImageWrap,
  children,
  onClick
}: AvatarImagePreviewButtonProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        margin: '32px 0',
        ...sxBox
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          flex: '0 0 100%',
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          maxWidth: `${imageWidth}px`,
          cursor: 'pointer',
          borderRadius: '4px',
          background: 'var(--background-neutral-subtle-hovered, rgba(9, 14, 22, 0.06))',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 24px 0 0',
          img: {
            height: '100%',
            objectFit: 'cover'
          },
          ...sxImageWrap
        }}
      >
        {oldImage ? (
          <ImageLazy variant={'rectangular'} width={imageWidth} height={imageHeight} src={oldImage} alt={imgAlt} />
        ) : (
          <PhotoCameraIcon sx={{ fontSize: 80, ...sxImage }} />
        )}
      </Box>

      {children}
    </Box>
  );
};
