import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ArchiveIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'#313849'}
      sx={{ fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M12 10.2885C11.7872 10.2885 11.609 10.3602 11.4654 10.5038C11.3218 10.6474 11.25 10.8256 11.25 11.0385V14.7385L9.92688 13.4154C9.78842 13.2769 9.61439 13.2061 9.40478 13.2029C9.19518 13.1997 9.01794 13.2705 8.87308 13.4154C8.72819 13.5602 8.65575 13.7359 8.65575 13.9423C8.65575 14.1487 8.72819 14.3243 8.87308 14.4692L11.3673 16.9634C11.5481 17.1442 11.759 17.2346 12 17.2346C12.241 17.2346 12.4519 17.1442 12.6327 16.9634L15.1269 14.4692C15.2653 14.3307 15.3362 14.1567 15.3394 13.9471C15.3426 13.7375 15.2718 13.5602 15.1269 13.4154C14.982 13.2705 14.8064 13.1981 14.6 13.1981C14.3936 13.1981 14.2179 13.2705 14.0731 13.4154L12.75 14.7385V11.0385C12.75 10.8256 12.6782 10.6474 12.5346 10.5038C12.391 10.3602 12.2128 10.2885 12 10.2885ZM4.99997 7.90383V18.6923C4.99997 18.782 5.02883 18.8557 5.08653 18.9134C5.14423 18.9711 5.21795 19 5.3077 19H18.6923C18.782 19 18.8557 18.9711 18.9134 18.9134C18.9711 18.8557 19 18.782 19 18.6923V7.90383H4.99997ZM5.38463 20.5C4.88591 20.5 4.44712 20.3105 4.06827 19.9317C3.68942 19.5528 3.5 19.114 3.5 18.6153V7.00578C3.5 6.79168 3.5343 6.58751 3.6029 6.39328C3.67148 6.19906 3.77437 6.0199 3.91155 5.8558L5.3154 4.1577C5.4795 3.9436 5.68462 3.78046 5.93077 3.66828C6.17692 3.55609 6.43718 3.5 6.71155 3.5H17.2692C17.5435 3.5 17.8054 3.55609 18.0547 3.66828C18.3041 3.78046 18.5108 3.9436 18.6749 4.1577L20.0884 5.87503C20.2256 6.03913 20.3285 6.21989 20.3971 6.41733C20.4657 6.61476 20.5 6.82053 20.5 7.03463V18.6153C20.5 19.114 20.3105 19.5528 19.9317 19.9317C19.5528 20.3105 19.114 20.5 18.6153 20.5H5.38463ZM5.39035 6.40385H18.6L17.5096 5.10575C17.4775 5.0737 17.4407 5.04806 17.399 5.02883C17.3573 5.00959 17.314 4.99998 17.2692 4.99998H6.72117C6.67629 4.99998 6.63302 5.00959 6.59135 5.02883C6.54968 5.04806 6.51282 5.0737 6.48075 5.10575L5.39035 6.40385Z" />
    </SvgIcon>
  );
}
