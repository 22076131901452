import merge from 'lodash/merge';

import { EditProfileReqInput, GetProfileQuery } from '@/logicLayers/domain';

export const updateUserProfileModel = (
  data: GetProfileQuery,
  update: Partial<EditProfileReqInput>
): EditProfileReqInput => {
  return merge(
    {
      phoneNumber: data.profile.phoneNumber || '',
      lastName: data.profile.lastName || '',
      firstName: data.profile.firstName || '',
      fileKey: data.profile.fileKey || ''
    },
    update
  );
};
