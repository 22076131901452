import { FC, useCallback, useState } from 'react';

import { RenderWhen } from '@/separatedModules/components';

import { AddLocationButton, AddLocationModal, EditLocationButton, EditLocationModal } from './components';

import { LocationActionTypeEnum } from './helpers';

interface CreateOrEditDomainProps {
  id?: string;
  actionType: LocationActionTypeEnum;
  isDisabled?: boolean;
}

interface StateProps {
  isOpen: boolean;
  actionType?: LocationActionTypeEnum;
}

export const AddOrEditLocation: FC<CreateOrEditDomainProps> = ({ id, actionType, isDisabled }) => {
  const [modalState, setModalState] = useState<StateProps>({
    isOpen: false,
    actionType: undefined
  });

  const handleOpenModal = useCallback(() => {
    setModalState({
      isOpen: true,
      actionType: actionType
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalState({ isOpen: false, actionType: undefined });
  }, []);

  return (
    <>
      <RenderWhen condition={actionType === LocationActionTypeEnum.Create}>
        <AddLocationButton disabled={isDisabled} onOpenModal={handleOpenModal} />
      </RenderWhen>

      <RenderWhen condition={actionType === LocationActionTypeEnum.Edit}>
        <EditLocationButton id={id} onOpenModal={handleOpenModal} />
      </RenderWhen>

      {modalState.isOpen ? (
        <>
          {modalState.actionType === LocationActionTypeEnum.Create ? (
            <AddLocationModal isModalOpen={modalState.isOpen} onCloseModal={handleCloseModal} />
          ) : null}

          {modalState.actionType === LocationActionTypeEnum.Edit ? (
            <EditLocationModal domainId={id} isModalOpen={modalState.isOpen} onCloseModal={handleCloseModal} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
