import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const FolderItem = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'selected' && prop !== 'disabled'
})(({ selected, disabled }: { selected: boolean; disabled: boolean }) => ({
  height: '40px',
  background: selected ? '#E9F2FF' : 'transparent',
  padding: '0 12px',
  borderRadius: '4px',
  transition: '0.25s ease',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  ...(disabled && {
    pointerEvents: 'none'
  }),

  ':hover': {
    background: '#E9F2FF',

    '.clickableItem': {
      display: 'flex !important'
    }
  }
}));
