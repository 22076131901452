export const Project = {
  displayedRows: {
    minWidth: '100px',
    textAlign: 'center'
  },
  selectIcon: {
    right: '12px',
    fill: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'auto',
    backgroundImage:
      'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIGlkPSJleHBhbmRfbW9yZSI+CiAgICAgICAgPHBhdGggaWQ9InZlY3RvciIKICAgICAgICAgICAgICBkPSJNOC45OTk3NSAxMC45OTYzQzguOTA5MzYgMTAuOTk2MyA4LjgyNTIzIDEwLjk4MTkgOC43NDczNSAxMC45NTNDOC42Njk0NiAxMC45MjQyIDguNTk1NDMgMTAuODc0NyA4LjUyNTI0IDEwLjgwNDVMNS4xNTQ1NyA3LjQzMzgxQzUuMDUwNzIgNy4zMjk5NyA0Ljk5NzYgNy4xOTk0NSA0Ljk5NTIgNy4wNDIyNEM0Ljk5Mjc4IDYuODg1MDIgNS4wNDU5MSA2Ljc1MjA5IDUuMTU0NTcgNi42NDM0NEM1LjI2MzIyIDYuNTM0NzkgNS4zOTQ5NSA2LjQ4MDQ3IDUuNTQ5NzUgNi40ODA0N0M1LjcwNDU1IDYuNDgwNDcgNS44MzYyNyA2LjUzNDc5IDUuOTQ0OTIgNi42NDM0NEw4Ljk5OTc1IDkuNjk4MjdMMTIuMDU0NiA2LjY0MzQ0QzEyLjE1ODQgNi41Mzk2MSAxMi4yODg5IDYuNDg2NDggMTIuNDQ2MSA2LjQ4NDA3QzEyLjYwMzMgNi40ODE2NyAxMi43MzYzIDYuNTM0NzkgMTIuODQ0OSA2LjY0MzQ0QzEyLjk1MzYgNi43NTIwOSAxMy4wMDc5IDYuODgzODIgMTMuMDA3OSA3LjAzODY0QzEzLjAwNzkgNy4xOTM0NCAxMi45NTM2IDcuMzI1MTYgMTIuODQ0OSA3LjQzMzgxTDkuNDc0MjUgMTAuODA0NUM5LjQwNDA3IDEwLjg3NDcgOS4zMzAwMyAxMC45MjQyIDkuMjUyMTQgMTAuOTUzQzkuMTc0MjYgMTAuOTgxOSA5LjA5MDEzIDEwLjk5NjMgOC45OTk3NSAxMC45OTYzWiIKICAgICAgICAgICAgICBmaWxsPSIjMzEzODQ5Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=)'
  },
  toolbar: {
    minHeight: '40px',
    paddingLeft: 0,
    margin: '16px 0 0',
    justifyContent: 'flex-end'
  },
  select: {
    border: '1px solid #090E1626',
    borderRadius: '16px',
    textAlign: 'left',
    paddingLeft: '12px',
    textAlignLast: 'left',
    background: 'transparent'
  },
  input: {
    minWidth: '70px',
    marginRight: '64px',
    marginLeft: '16px'
  },
  actions: {
    marginLeft: '36px',
    flex: '0 1 auto',
    display: 'flex'
  },
  spacer: {
    display: 'none'
  }
};

export const Base = {};
