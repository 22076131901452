import { MouseEvent, ReactNode, SyntheticEvent, useState } from 'react';

import Box from '@mui/material/Box';

import { FolderIcon } from '@/separatedModules/components';

import { RenderTree, renderTree } from '../../helpers';

import { StyledTreeView } from './style';

interface MuiTreeViewProps {
  tree: RenderTree;
  rootItemIcon?: ReactNode;
  defaultExpanded?: string[];
  onNodeSelect: (nodeIds: string) => void;
  onNodeActionClick: (event: MouseEvent<HTMLElement>, node: RenderTree) => void;
  selected?: string;
  isDisabled?: boolean;
}

export const MuiTreeView = ({
  tree,
  rootItemIcon,
  defaultExpanded = ['root'],
  onNodeSelect,
  onNodeActionClick,
  selected,
  isDisabled
}: MuiTreeViewProps) => {
  const [expanded, setExpanded] = useState<string[]>(defaultExpanded);

  // only expand if icon was clicked
  const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
    const iconClicked = (event.target as HTMLElement).closest('.MuiTreeItem-iconContainer');

    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  return (
    <Box>
      <StyledTreeView
        expanded={expanded}
        defaultExpanded={defaultExpanded}
        defaultEndIcon={<FolderIcon />}
        onNodeSelect={(_: any, nodeIds: string) => onNodeSelect(nodeIds)}
        onNodeToggle={(e: SyntheticEvent<Element, Event>, nodeIds: string[]) => handleToggle(e, nodeIds)}
        selected={selected}
        isDisabled={isDisabled}
      >
        {renderTree(tree, onNodeActionClick, rootItemIcon)}
      </StyledTreeView>
    </Box>
  );
};
