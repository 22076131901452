import merge from 'lodash/merge';

import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

import { Base, Project } from './style';

const newStyleOverrides: Record<string, any> = merge(Base, Project);

export const MuiCheckbox: {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides['MuiCheckbox'];
  variants?: ComponentsVariants['MuiCheckbox'];
} = {
  defaultProps: {},
  styleOverrides: {
    ...newStyleOverrides
  }
};

/*
export const themeMuiCheckbox = {
  formControlLabel: {
    color: 'blue',
    margin: '0 0 12px 0'
  },
  svg: {
    color: 'var(--default-gray-500)'
  },
  hover: {
    bg: 'var(--primary-25)',
    svg: {
      color: 'var(--primary-800)'
    }
  },
  active: {
    bg: 'var(--primary-50)'
  },
  checked: {
    svg: {
      color: 'var(--primary-800)'
    },
    active: {
      svg: {
        bg: 'var(--primary-50)'
      }
    }
  },
  disabled: {
    svg: {
      color: 'var(--default-gray-500)'
    }
  },
  error: {
    hover: {
      bg: 'var(--extended-red-50)'
    },
    active: {
      bg: 'var(--extended-red-100)'
    },
    svg: {
      color: 'var(--extended-red-700)'
    },
    checked: {
      svg: {
        color: 'var(--extended-red-700)'
      },
      active: {
        bg: 'var(--extended-red-100)'
      }
    }
  }
};
*/
