import { MouseEvent, useState } from 'react';

import IconButton from '@mui/material/IconButton';

import { DescriptionIcon, NotificationsFillIcon } from '@/separatedModules/components';

import { NotificationsPopover } from '../NotificationsPopover';
import { RightItemAvatar } from '../RightItemAvatar';

export const RightItem = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isOpenPopover = Boolean(anchorEl);
  const idPopover = isOpenPopover ? 'notifications-popover' : undefined;

  return (
    <div className={'header__right__i'}>
      <div className={'header__right__i__line'}></div>

      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#DCDFE4' }} aria-label={''} onClick={handleOpenPopover}>
        <NotificationsFillIcon />
      </IconButton>

      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#DCDFE4' }} aria-label={''}>
        <DescriptionIcon />
      </IconButton>

      <RightItemAvatar />

      <NotificationsPopover
        idPopover={idPopover}
        anchorEl={anchorEl}
        isOpenPopover={isOpenPopover}
        handleClosePopover={handleClosePopover}
      />
    </div>
  );
};
