import classNames from 'classnames';

import { SxProps, Theme } from '@mui/material/styles';

import {
  AutocompleteTags,
  AutocompleteTagsProps,
  TextBlockControl,
  TextBlockControlProps
} from '@/separatedModules/components';

import { StyledAutocompleteTagsControl } from './style';

interface AutocompleteTagsControlProps extends AutocompleteTagsProps, TextBlockControlProps {
  sxSelect?: SxProps<Theme>;
  sxSelectBox?: SxProps<Theme>;
}

export const AutocompleteTagsControl = ({
  title,
  subTitle,
  sxTitle,
  sxSubTitle,
  sxBox,
  sxSelect,
  sxSelectBox,
  ...rest
}: AutocompleteTagsControlProps) => {
  const isHasTitles = !title && !subTitle;

  return (
    <StyledAutocompleteTagsControl
      className={classNames({
        'no-titles': isHasTitles
      })}
      sx={sxSelectBox}
    >
      <TextBlockControl
        className={'control-autocomplete-tags__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
        sxBox={sxBox}
      />

      <AutocompleteTags sx={sxSelect} {...rest} />
    </StyledAutocompleteTagsControl>
  );
};
