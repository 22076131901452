import { ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

export interface TextBlockControlProps {
  className?: string;
  title?: string;
  subTitle?: string;
  sxTitle?: SxProps<Theme>;
  sxSubTitle?: SxProps<Theme>;
  sxBox?: SxProps<Theme>;
  ContentAfterTitle?: ReactNode;
  isTitleLg?: boolean;
  isBodyLg?: boolean;
}

export const TextBlockControl = ({
  className,
  title,
  subTitle,
  sxTitle,
  sxSubTitle,
  sxBox,
  ContentAfterTitle,
  isTitleLg = false,
  isBodyLg = false
}: TextBlockControlProps) => {
  const isTextsEmpty = !title && !subTitle;

  if (isTextsEmpty) return null;

  return (
    <Box className={className} sx={{ margin: isTitleLg ? '32px 0 48px' : '32px 0', ...sxBox }}>
      {title ? (
        <Text
          variant={isTitleLg ? 'titleLg' : 'titleMd'}
          sx={sxTitle}
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
      ) : null}

      {subTitle ? (
        <Text
          variant={isBodyLg ? 'bodyLg' : 'bodyMd'}
          sx={{ color: 'var(--text-subtle, #505668)', ...sxSubTitle }}
          dangerouslySetInnerHTML={{
            __html: subTitle
          }}
        />
      ) : null}

      {ContentAfterTitle ? ContentAfterTitle : null}
    </Box>
  );
};
