import { AxiosError } from 'axios';

import { ApiResponseT } from '@/subsidiaryBinders/types';

export const APIErrorHandler = (error: AxiosError): ApiResponseT => {
  const errorStatusCode = error.response?.status || 400;
  const errorStatusText = error.response?.statusText || '';
  const errorData = error.response?.data || { message: '', error: '', statusCode: '' };

  const errorContent = {
    data: errorData,
    code: errorStatusCode,
    text: errorStatusText
  };

  return { isSuccess: false, error: errorContent, data: undefined };
};
