import { ReactNode } from 'react';

export const EDIT_BUTTON_CLASS = 'edit-role-button';

export enum EditRoleMenuListNamesEnum {
  EDIT = 'edit'
}

interface MenuList {
  id: number;
  name: string;
  disabled?: boolean;
  icon?: ReactNode;
}

export const menuList: MenuList[] = [
  {
    id: 1,
    name: EditRoleMenuListNamesEnum.EDIT,
    disabled: false
  }
];
