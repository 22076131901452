import { useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { GET_REGIONS, GetRegionsQuery } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import { ClickableItem, LocationFolders } from '..';

export const AllLocationsTab = () => {
  const { startMoving, progressList, setDestinationData, setLocationToProgressListWithNextStep } = useLocations();

  const [selectedFolder, setSelectedFolder] = useState('');

  const { data: dataRegions, loading: loadingRegions } = useQuery<GetRegionsQuery>(GET_REGIONS, {
    variables: {
      pageSize: 100,
      page: 0
    },
    fetchPolicy: 'no-cache'
  });

  const handleClickLocation = (id: string, name: string) => {
    setSelectedFolder(id);
    setDestinationData({
      id,
      locationName: name
    });
  };

  const handleClickNextLocation = (id: string, name: string) => {
    setLocationToProgressListWithNextStep({ id: id, locationName: name });
    setSelectedFolder(id);
    startMoving();
  };

  const folders = dataRegions?.regions.items;

  return (
    <>
      {loadingRegions ? (
        <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={210} animation={'wave'} />
      ) : (
        <>
          {progressList.length === 0 &&
            folders?.map((folder) => (
              <Box key={folder?.id}>
                {folder?.id && folder?.name ? (
                  <ClickableItem
                    disabled={!folder.active}
                    folderId={folder.id}
                    folderName={folder.name}
                    selectedFolderId={selectedFolder}
                    onSelectLocation={handleClickLocation}
                    onClickNextLocation={handleClickNextLocation}
                  />
                ) : null}
              </Box>
            ))}
        </>
      )}

      <>{progressList.length ? <LocationFolders /> : null}</>
    </>
  );
};
