import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ArrowBackIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#3C4656', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M3.18459 12.0003L10.7173 19.533C10.916 19.7317 11.0128 19.9657 11.0077 20.2349C11.0025 20.5041 10.9006 20.7381 10.7019 20.9368C10.5032 21.1355 10.2692 21.2349 9.99999 21.2349C9.73075 21.2349 9.49679 21.1355 9.29809 20.9368L1.62694 13.2811C1.44614 13.1003 1.31215 12.8977 1.22499 12.6734C1.13782 12.449 1.09424 12.2247 1.09424 12.0003C1.09424 11.776 1.13782 11.5516 1.22499 11.3272C1.31217 11.1029 1.44616 10.9003 1.62694 10.7196L9.29809 3.04841C9.49679 2.84969 9.73332 2.75289 10.0077 2.75801C10.282 2.76314 10.5186 2.86506 10.7173 3.06378C10.916 3.2625 11.0154 3.49646 11.0154 3.76568C11.0154 4.03491 10.916 4.26889 10.7173 4.46761L3.18459 12.0003Z" />
    </SvgIcon>
  );
}
