import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { ExpandLessIcon } from '@/separatedModules/components';

import { LinksPagesT } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

type HeaderMenuItemProps = LinksPagesT;

export const HeaderMenuItem = ({ id, title, link, children }: HeaderMenuItemProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickLink = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!children?.length) {
    return (
      <NavLink
        key={id}
        to={link}
        className={({ isActive, isPending }) => {
          return [isPending ? 'pending' : '', isActive ? 'header__menu__i--active' : '', 'header__menu__i']
            .join(' ')
            .trim();
        }}
        end={false}
      >
        {tGlobal(title)}

        <span className={'header__menu__i__line'}></span>
      </NavLink>
    );
  }

  return (
    <NavLink
      key={id}
      to={link}
      className={({ isActive, isPending }) => {
        return [isPending ? 'pending' : '', isActive ? 'header__menu__i--active' : '', 'header__menu__i']
          .join(' ')
          .trim();
      }}
      end={false}
      onClick={(e) => {
        e.preventDefault();
      }}
      style={{
        padding: 0
      }}
    >
      <Box
        className={'m-0 header__menu__i'}
        aria-haspopup={'true'}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickLink}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '.MuiSvgIcon-root': {
            margin: '0 0 0 4px',
            transition: 'transform 0.3s ease',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)' // Поворот на 180 градусов при открытии
          }
        }}
      >
        {tGlobal(title)}

        <ExpandLessIcon sx={{ fill: '#3C4656', fontSize: 20 }} />
      </Box>

      <span className={'header__menu__i__line'}></span>

      <Menu
        id={'header-templates-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'header-templates-menu-button',
          sx: {
            width: 200
          }
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 200,
              maxHeight: '400px !important'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          margin: '0'
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuList sx={{ padding: 0 }}>
          {children.map((i) => {
            return (
              <MenuItem key={i.id} className={'MuiMenuItem-root-as-link'}>
                <NavLink
                  to={i.link}
                  style={{
                    display: i.isHidden ? 'none' : 'flex',
                    color: 'inherit',
                    flex: '0 0 100%',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {i.icon}

                  {tGlobal(i.title)}
                </NavLink>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </NavLink>
  );
};
