import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { GlobalReducer, MoveLocationTabReducer, UserProfileReducer } from './modules';

const logger = createLogger({
  collapsed: true
});

export const ReduxStore = configureStore({
  reducer: {
    global: GlobalReducer,
    user: UserProfileReducer,
    moveLocation: MoveLocationTabReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(logger);
  },
  devTools: process.env.NODE_ENV !== 'production'
});

export type ReduxStore = typeof ReduxStore;
export type ReduxDispatch = typeof ReduxStore.dispatch;
export type ReduxState = ReturnType<typeof ReduxStore.getState>;

export const useReduxDispatch: () => ReduxDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useReduxSelector: TypedUseSelectorHook<ReduxState> = useSelector;
