import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DotIcon(props: SvgIconProps) {
  const { sx, fill = '#5C6C83', ...restProps } = props;

  return (
    <SvgIcon width={'20'} height={'20'} viewBox={'0 0 20 20'} sx={{ fontSize: 20, fill: fill, ...sx }} {...restProps}>
      <path
        id="vector"
        d="M10.0003 13.3327C9.07175 13.3327 8.28406 13.0093 7.63723 12.3624C6.9904 11.7156 6.66699 10.9279 6.66699 9.99935C6.66699 9.07078 6.9904 8.28308 7.63723 7.63625C8.28406 6.98943 9.07175 6.66602 10.0003 6.66602C10.9289 6.66602 11.7166 6.98943 12.3634 7.63625C13.0102 8.28308 13.3337 9.07078 13.3337 9.99935C13.3337 10.9279 13.0102 11.7156 12.3634 12.3624C11.7166 13.0093 10.9289 13.3327 10.0003 13.3327Z"
      />
    </SvgIcon>
  );
}
