import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AlertWarningNoticeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'20'} height={'20'} viewBox={'0 0 20 20'} {...props}>
      <path
        d="M2.85446 17.0834C2.71321 17.0834 2.58636 17.0488 2.4739 16.9798C2.36144 16.9107 2.27397 16.8197 2.2115 16.7068C2.14652 16.5946 2.11077 16.473 2.10427 16.3422C2.09777 16.2113 2.13309 16.0817 2.21021 15.9533L9.34417 3.63015C9.42129 3.50179 9.51782 3.40689 9.63373 3.34546C9.74965 3.28402 9.87171 3.2533 9.99992 3.2533C10.1281 3.2533 10.2502 3.28402 10.3661 3.34546C10.482 3.40689 10.5785 3.50179 10.6557 3.63015L17.7896 15.9533C17.8668 16.0817 17.9021 16.2113 17.8956 16.3422C17.8891 16.473 17.8533 16.5946 17.7883 16.7068C17.7259 16.8197 17.6384 16.9107 17.5259 16.9798C17.4135 17.0488 17.2866 17.0834 17.1454 17.0834H2.85446ZM9.99992 14.8398C10.1906 14.8398 10.3505 14.7753 10.4795 14.6463C10.6085 14.5173 10.673 14.3574 10.673 14.1667C10.673 13.976 10.6085 13.8161 10.4795 13.6872C10.3505 13.5582 10.1906 13.4936 9.99992 13.4936C9.80921 13.4936 9.64936 13.5582 9.52036 13.6872C9.39136 13.8161 9.32686 13.976 9.32686 14.1667C9.32686 14.3574 9.39136 14.5173 9.52036 14.6463C9.64936 14.7753 9.80921 14.8398 9.99992 14.8398ZM10.0002 12.6603C10.1774 12.6603 10.3258 12.6004 10.4454 12.4806C10.5651 12.3608 10.6249 12.2124 10.6249 12.0353V9.11863C10.6249 8.94155 10.565 8.79312 10.4451 8.67334C10.3253 8.55355 10.1768 8.49365 9.99965 8.49365C9.82248 8.49365 9.67407 8.55355 9.55442 8.67334C9.43477 8.79312 9.37494 8.94155 9.37494 9.11863V12.0353C9.37494 12.2124 9.43486 12.3608 9.55469 12.4806C9.67454 12.6004 9.82304 12.6603 10.0002 12.6603Z"
        fill="#CF9F02"
      />
    </SvgIcon>
  );
}
