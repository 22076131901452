import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { MoveLocationTabActions } from './Locations';
import { UserProfileActions } from './User';

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators({ ...MoveLocationTabActions, ...UserProfileActions }, dispatch);
  }, [dispatch]);
};
