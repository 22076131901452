import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQuery } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';

import { GET_PRESIGNED_UPLOAD_URL, GetPresignedUploadUrlQuery } from '@/logicLayers/domain';
import { ApiService } from '@/logicLayers/infrastructure/services';

import { AddPhotoIcon, DeleteTrashIcon } from '@/separatedModules/components';

import { blobFromUrl, validateUploadImage } from '@/utils';

import { useTranslation } from '@/i18n';

import { ImageDropzone } from '../SingleImageUploader';

import { ImageReader, UploaderButton, UploaderButtonSm } from './components';

import {
  getImageDimensions,
  ImageUploaderProps,
  INIT_STATE,
  showAlertErrorMessage,
  showAlertSuccessMessage
} from './helpers';

// https://blog.logrocket.com/top-react-image-cropping-libraries
export const ImageUploader = ({
  path = 'COMPANY_LOGO',
  imgAlt = 'some image',
  oldImageUrl,
  oldImageFileKey = '',
  modalTitle,
  modalTitleToDelete,
  modalSubTitle,
  imageMinHeight = 0,
  imageMinWidth = 0,
  imageRequired = true,
  imageMaxSize,
  imageAllowedExtensions,
  maxDimensionsText,
  minDimensionsText,
  maxFileSizeText,
  btnUploadPhotoText,
  modalContent,
  titleContent,
  withPreview = false,
  isOpen = false,
  isVisibleDeleteBtn = true,
  renderUploaderButton = ({ imgAlt, oldImage, handleOpenModalPhoto }) => {
    return <UploaderButtonSm imgAlt={imgAlt} oldImage={oldImage} handleOpenModalPhoto={handleOpenModalPhoto} />;
  },
  onImageUploadSuccess,
  onImageDelete,
  onImageUploadError,
  isDisabled
}: ImageUploaderProps) => {
  const { t: tGlobal } = useTranslation('global');

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': [] // 'image/*': ['.jpeg', '.png', '.jpg', '.svg']
    },
    onDrop: (acceptedFiles) => {
      handleUploadImage(acceptedFiles);
    },
    onDragEnter: () => handleOnDrag(true),
    onDragLeave: () => handleOnDrag(false),
    noClick: true, // Disable click and keydown behavior
    noKeyboard: true
  });

  const getImage: any = useRef(null);

  const [state, setState] = useState({
    ...INIT_STATE,
    isOpen
  });

  const { refetch } = useQuery<GetPresignedUploadUrlQuery>(GET_PRESIGNED_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    variables: { path },
    skip: true
  });

  const handleOpenFileDialog = () => {
    open();
  };

  const handleOnDrag = (value: boolean) => {
    setState((state) => ({ ...state, isOnDrag: value }));
  };

  const handleUploadImage = async (files: File[]) => {
    const firstFile = files[0];

    const file = {
      blob: firstFile,
      name: firstFile.name,
      type: firstFile.type,
      preview: URL.createObjectURL(firstFile)
    };

    if (!file) return;

    const { height, width } = await getImageDimensions(file.preview);

    const { isValid, error: message } = validateUploadImage({
      value: file.blob as File,
      width,
      height,
      imageRequired,
      imageMinWidth,
      imageMinHeight,
      imageMaxSize,
      imageAllowedExtensions,
      minDimensionsText,
      maxFileSizeText,
      maxDimensionsText
    });

    if (!state.isUpload && isValid) {
      setState((state) => ({
        ...state,
        upload: true,
        isOnDrag: false,
        file,
        oldFile: withPreview ? file : INIT_STATE.oldFile,
        error: { isValid: true, message: '' }
      }));
    } else if (!isValid && message !== state.error.message) {
      setState((state) => ({ ...state, upload: false, error: { isValid, message } }));
    }
  };

  const handleGetUrlForUpload = async () => {
    return await refetch().then(({ data }) => data);
  };

  const handleCloseModalPhoto = () => {
    setTimeout(() => {
      setState((state) => ({ ...state, isModalOpen: false }));

      setTimeout(() => {
        setDefaultState();

        setState((state) => ({ ...state, isClickedSaveBtn: false }));
      }, 200);
    }, 200);
  };

  const handleResetDefaultImageToBlob = async (oldImageUrl: string, oldImageFileKey: string) => {
    if (oldImageFileKey) {
      const BlobFile = await blobFromUrl(oldImageUrl);

      if (!BlobFile) return;

      setState((state) => {
        const oldFile = {
          blob: BlobFile as File,
          name: 'file-test.png',
          type: BlobFile.type,
          base64: '',
          preview: URL.createObjectURL(BlobFile)
        };

        return {
          ...state,
          file: oldFile,
          oldFile,
          oldImageFileKey,
          oldImageUrl
        };
      });
    } else if (!oldImageFileKey && state.file?.blob) {
      setState(() => ({ ...INIT_STATE }));
    }
  };

  const setDefaultState = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        isWantToDelete: false,
        // ToDo Last Changes
        file: oldImageFileKey || withPreview ? state.oldFile : INIT_STATE.file,
        error: {
          isValid: true,
          message: ''
        }
      };
    });
  }, []);

  const handleSaveImage = useCallback(async () => {
    const file = await getImage.current?.();

    setState((state) => ({ ...state, isClickedSaveBtn: true }));

    if (file || state.file?.blob) {
      const imageUrlForUpload = await handleGetUrlForUpload();

      const { isSuccess } = await ApiService.AmazonS3.updateImage(
        imageUrlForUpload.presignedUploadUrl.url,
        file || state.file?.blob
      );

      if (isSuccess) {
        onImageUploadSuccess(imageUrlForUpload.presignedUploadUrl.fileKey, imageUrlForUpload.presignedUploadUrl.url);

        showAlertSuccessMessage(tGlobal('imageUploader.showAlertSuccess'));

        handleCloseModalPhoto();
      } else {
        onImageUploadError();

        handleCloseModalPhoto();
      }
    }
  }, [state]);

  const handleClickDeleteBtn = useCallback(() => {
    setState((state) => ({ ...state, isWantToDelete: true }));
  }, []);

  const handleClickCancelDeleteImage = useCallback(() => {
    setState((state) => ({ ...state, isWantToDelete: false }));
  }, []);

  const handleClickApplyDeleteImage = useCallback(() => {
    onImageDelete();

    setState((state) => ({ ...state, isModalOpen: false }));
  }, []);

  const handleOpenModalPhoto = useCallback(() => {
    setState((state) => ({ ...state, isModalOpen: true }));
  }, []);

  useEffect(() => {
    handleResetDefaultImageToBlob(oldImageUrl, oldImageFileKey);
  }, [oldImageFileKey]);

  useEffect(() => {
    if (state.error.message) {
      showAlertErrorMessage(state.error.message);
    }
  }, [state.error.message]);

  const isImageExist = state.file?.preview;
  const caseFirst = isImageExist && !state.isWantToDelete;
  const caseSecond = isImageExist && state.isWantToDelete;
  const caseThird = !isImageExist && !state.isWantToDelete;
  const sxCase = caseFirst
    ? { '.MuiModal-content': { padding: '0 !important' } }
    : caseSecond
      ? { '.MuiModal-content': { padding: '16px 24px 32px !important' } }
      : {};
  const UploaderButtonImage = withPreview ? state.oldFile?.preview : state.oldImageUrl;

  // ToDo Remove
  console.log('ImageUploader state - ', state);

  return (
    <>
      <UploaderButton
        imgAlt={imgAlt}
        oldImage={UploaderButtonImage}
        handleOpenModalPhoto={handleOpenModalPhoto}
        renderUploaderButton={renderUploaderButton}
      />

      <Dialog
        headerTitle={state.isWantToDelete ? modalTitleToDelete : modalTitle}
        headerSubTitle={(!state.file?.preview || state.isWantToChange) && modalSubTitle}
        contentFooter={
          caseFirst ? (
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              {oldImageFileKey && isVisibleDeleteBtn ? (
                <IconButton sx={{ color: '#DCDFE4' }} aria-label={''} onClick={handleClickDeleteBtn}>
                  <DeleteTrashIcon />
                </IconButton>
              ) : null}

              <Box style={{ display: 'flex', justifyContent: 'center', margin: '0 0 0 auto' }}>
                <Button
                  variant={'text'}
                  startIcon={<AddPhotoIcon />}
                  sx={{ margin: '0 24px 0 0 !important' }}
                  onClick={handleOpenFileDialog}
                >
                  {tGlobal('imageUploader.btnChange')}
                </Button>

                <Button
                  className={'m-0'}
                  variant={'contained'}
                  disabled={state.isClickedSaveBtn}
                  onClick={handleSaveImage}
                >
                  {tGlobal('imageUploader.btnSave')}
                </Button>
              </Box>
            </Box>
          ) : caseSecond ? (
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleClickCancelDeleteImage}>
                {tGlobal('imageUploader.onDelete.btnCancel')}
              </Button>

              <Button className={'m-0'} variant={'contained'} onClick={handleClickApplyDeleteImage}>
                {tGlobal('imageUploader.onDelete.btnApply')}
              </Button>
            </Box>
          ) : null
        }
        sx={sxCase as SxProps<Theme>}
        hideHeaderBorder
        hideFooterBorder={!state.isWantToDelete}
        open={state.isModalOpen && !isDisabled}
        onClose={handleCloseModalPhoto}
      >
        {caseFirst ? <ImageReader state={state} getImage={getImage} /> : null}

        {caseSecond ? (
          <Text variant={'bodyLg'} margin={0}>
            {tGlobal('imageUploader.onDelete.content')}
          </Text>
        ) : null}

        {caseThird ? (
          <>
            {modalContent ? modalContent : null}

            <ImageDropzone
              btnUploadPhotoText={btnUploadPhotoText}
              titleContent={titleContent}
              parentState={state}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              handleOpenFileDialog={handleOpenFileDialog}
              handleUpdateState={setState}
            />
          </>
        ) : null}
      </Dialog>
    </>
  );
};
