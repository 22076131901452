import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function EmailIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M3.58942 16.25C3.16848 16.25 2.81217 16.1041 2.52051 15.8125C2.22884 15.5208 2.08301 15.1645 2.08301 14.7436V5.25643C2.08301 4.83549 2.22884 4.47918 2.52051 4.18752C2.81217 3.89585 3.16848 3.75002 3.58942 3.75002H16.4099C16.8308 3.75002 17.1871 3.89585 17.4788 4.18752C17.7705 4.47918 17.9163 4.83549 17.9163 5.25643V14.7436C17.9163 15.1645 17.7705 15.5208 17.4788 15.8125C17.1871 16.1041 16.8308 16.25 16.4099 16.25H3.58942ZM16.6663 6.20189L10.4051 10.2099C10.341 10.2462 10.2748 10.2748 10.2064 10.2956C10.138 10.3165 10.0691 10.3269 9.99965 10.3269C9.93021 10.3269 9.8613 10.3165 9.79292 10.2956C9.72455 10.2748 9.65831 10.2462 9.59422 10.2099L3.33299 6.20189V14.7436C3.33299 14.8183 3.35703 14.8798 3.40511 14.9279C3.4532 14.976 3.51463 15 3.58942 15H16.4099C16.4847 15 16.5461 14.976 16.5942 14.9279C16.6423 14.8798 16.6663 14.8183 16.6663 14.7436V6.20189ZM9.99965 9.16666L16.5381 4.99999H3.4612L9.99965 9.16666ZM3.33299 6.39418V5.4415V5.46633V5.43989V6.39418Z" />
    </SvgIcon>
  );
}
