import { HttpClient } from './../request';

/*
 * Example Names of Methods
 * Get request - getUserCoins
 * Post request - createUserCoins
 * Update/Put request - updateUserCoins
 * Delete request - deleteUserCoins
 */

export class AmazonStorageApiServiceClass {
  request: typeof HttpClient;

  constructor(request: typeof HttpClient) {
    this.request = request;
  }

  /*
  async getUrlForUploadImage() {
    try {
      const { isSuccess, data } = await this.request.get<GetUrlForUploadT>(LINKS_REQUEST.amazonStorageUploadUrl);

      return { isSuccess, data };
    } catch (err) {
      return err;
    }
  }*/

  async updateImage(url: string, image: Blob) {
    const myHeaders: any = new Headers();

    myHeaders.append('Content-Type', image.type || 'image/jpeg');

    const requestOptions: any = {
      method: 'PUT',
      headers: myHeaders,
      body: image,
      redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      return { isSuccess: true, data: '' };
    } else {
      // ToDo Remove
      console.log('AmazonStorageApiServiceClass.updateImage error - ', response);

      return { isSuccess: false, data: undefined };
    }
  }
}
