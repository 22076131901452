import type { ThemeT } from '../../theme';

// import merge from 'lodash/merge';
// import { ComponentsOverrides } from '@mui/material/styles/overrides';
// import { ComponentsProps } from '@mui/material/styles/props';
// import { ComponentsVariants } from '@mui/material/styles/variants';

// import { Base, Project } from './style';
// const newStyleOverrides: Record<string, any> = merge(Base, Project);

export const MuiContainer = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }: ThemeT) => ({
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
      [theme.breakpoints.up('lg')]: {
        // maxWidth: '1400px'
      }
    })
  }
};
