import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AlertInfoNoticeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'20'} height={'20'} viewBox={'0 0 20 20'} {...props}>
      <path
        d="M10.0002 13.9583C10.1773 13.9583 10.3257 13.8984 10.4454 13.7786C10.5651 13.6589 10.6249 13.5104 10.6249 13.3334V9.79167C10.6249 9.6146 10.565 9.46617 10.4451 9.34637C10.3253 9.22658 10.1768 9.16669 9.99963 9.16669C9.82246 9.16669 9.67405 9.22658 9.5544 9.34637C9.43475 9.46617 9.37492 9.6146 9.37492 9.79167V13.3334C9.37492 13.5104 9.43484 13.6589 9.55467 13.7786C9.67452 13.8984 9.82302 13.9583 10.0002 13.9583ZM9.9999 7.74042C10.1906 7.74042 10.3505 7.67592 10.4795 7.54692C10.6085 7.41792 10.673 7.25806 10.673 7.06735C10.673 6.87666 10.6085 6.71681 10.4795 6.58779C10.3505 6.45879 10.1906 6.39429 9.9999 6.39429C9.80919 6.39429 9.64934 6.45879 9.52034 6.58779C9.39134 6.71681 9.32684 6.87666 9.32684 7.06735C9.32684 7.25806 9.39134 7.41792 9.52034 7.54692C9.64934 7.67592 9.80919 7.74042 9.9999 7.74042ZM10.0013 17.9167C8.90635 17.9167 7.87715 17.7089 6.91371 17.2933C5.95025 16.8778 5.11218 16.3138 4.3995 15.6015C3.68681 14.8891 3.12259 14.0514 2.70686 13.0884C2.29112 12.1253 2.08325 11.0964 2.08325 10.0014C2.08325 8.90647 2.29103 7.87728 2.70659 6.91383C3.12214 5.95037 3.6861 5.11231 4.39846 4.39963C5.11084 3.68693 5.94854 3.12272 6.91156 2.70698C7.87458 2.29124 8.90356 2.08337 9.9985 2.08337C11.0934 2.08337 12.1226 2.29115 13.0861 2.70671C14.0495 3.12226 14.8876 3.68622 15.6003 4.39858C16.313 5.11096 16.8772 5.94866 17.2929 6.91169C17.7087 7.8747 17.9165 8.90368 17.9165 9.99863C17.9165 11.0936 17.7088 12.1228 17.2932 13.0862C16.8777 14.0497 16.3137 14.8877 15.6013 15.6004C14.889 16.3131 14.0513 16.8773 13.0882 17.2931C12.1252 17.7088 11.0962 17.9167 10.0013 17.9167Z"
        fill="#0055CC"
      />
    </SvgIcon>
  );
}
