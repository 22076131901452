import { styled } from '@mui/material/styles';

export const StyledImage = styled('div')(
  ({
    status,
    variant
  }: {
    status: 'loading' | 'loaded';
    variant: 'text' | 'rectangular' | 'rounded' | 'circular';
  }) => ({
    visibility: status === 'loading' ? 'hidden' : 'initial',
    overflow: 'hidden',
    borderRadius: variant === 'rectangular' ? 0 : '50%',
    img: {}
  })
);
