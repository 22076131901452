import { useEffect, useMemo, useState } from 'react';

import {
  ParametersTypesList,
  ParametersVisibilityList
} from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameters/helpers';

import { TableDropdownCheckbox, TableSortByOneItem } from '@/separatedModules/components';

type TableFiltersBoxProviderProps = {
  tableState: any;
  selectedDependencies: string[];
};

export const TableFiltersBoxProvider = ({ tableState, selectedDependencies }: TableFiltersBoxProviderProps) => {
  const [stateTypes, setStateTypes] = useState(ParametersTypesList);
  const [stateVisibility, setStateVisibility] = useState(ParametersVisibilityList);

  useEffect(() => {
    if (tableState.state.filters.sortByTypeSelected === 'type2') {
      const updated = stateTypes.map((i) => {
        const isChecked = selectedDependencies.includes(i.value);

        if (isChecked) {
          return {
            ...i,
            checked: true,
            disabled: false
          };
        }

        return {
          ...i,
          checked: false,
          disabled: true
        };
      });

      setStateTypes(updated);
    } else if (tableState.state.filters.sortByTypeSelected === 'type1') {
      const updated = stateVisibility.map((i) => {
        const isChecked =
          Boolean(selectedDependencies.length) && selectedDependencies.every((item) => i.value.includes(item));

        return {
          ...i,
          checked: isChecked
        };
      });

      setStateVisibility(updated);
    }
  }, [selectedDependencies]);

  const memoTypes = useMemo(() => stateTypes, [stateTypes]);
  const memoVisibility = useMemo(() => stateVisibility, [stateVisibility]);

  return (
    <>
      <TableDropdownCheckbox
        // ToDo Refactor
        title={'Type'}
        menuList={memoTypes}
        withSelectAll
        withSearch
        onChange={(value) => {
          tableState.handleChangeByType(value, 'type1');
        }}
      />

      <TableSortByOneItem
        // ToDo Refactor
        title={'Visibility'}
        // @ts-ignore
        menu={memoVisibility}
        onChange={(value) => {
          tableState.handleChangeByType(value, 'type2');
        }}
      />
    </>
  );
};
