import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LockIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M5.25642 17.9167C4.84082 17.9167 4.48585 17.7695 4.19152 17.4751C3.89717 17.1808 3.75 16.8258 3.75 16.4102V8.58979C3.75 8.17419 3.89717 7.81923 4.19152 7.52489C4.48585 7.23055 4.84082 7.08337 5.25642 7.08337H6.25V5.41671C6.25 4.37612 6.61485 3.49098 7.34454 2.76129C8.07425 2.0316 8.9594 1.66675 9.99998 1.66675C11.0406 1.66675 11.9257 2.0316 12.6554 2.76129C13.3851 3.49098 13.75 4.37612 13.75 5.41671V7.08337H14.7435C15.1591 7.08337 15.5141 7.23055 15.8084 7.52489C16.1028 7.81923 16.25 8.17419 16.25 8.58979V16.4102C16.25 16.8258 16.1028 17.1808 15.8084 17.4751C15.5141 17.7695 15.1591 17.9167 14.7435 17.9167H5.25642ZM5.25642 16.6667H14.7435C14.8183 16.6667 14.8798 16.6426 14.9279 16.5946C14.9759 16.5465 15 16.485 15 16.4102V8.58979C15 8.515 14.9759 8.45356 14.9279 8.40548C14.8798 8.35739 14.8183 8.33335 14.7435 8.33335H5.25642C5.18163 8.33335 5.12019 8.35739 5.0721 8.40548C5.02402 8.45356 4.99998 8.515 4.99998 8.58979V16.4102C4.99998 16.485 5.02402 16.5465 5.0721 16.5946C5.12019 16.6426 5.18163 16.6667 5.25642 16.6667ZM9.99998 13.9583C10.4049 13.9583 10.7492 13.8165 11.0328 13.5329C11.3165 13.2492 11.4583 12.9049 11.4583 12.5C11.4583 12.0951 11.3165 11.7508 11.0328 11.4672C10.7492 11.1835 10.4049 11.0417 9.99998 11.0417C9.59506 11.0417 9.25079 11.1835 8.96715 11.4672C8.68349 11.7508 8.54167 12.0951 8.54167 12.5C8.54167 12.9049 8.68349 13.2492 8.96715 13.5329C9.25079 13.8165 9.59506 13.9583 9.99998 13.9583ZM7.49998 7.08337H12.5V5.41671C12.5 4.72226 12.2569 4.13198 11.7708 3.64587C11.2847 3.15976 10.6944 2.91671 9.99998 2.91671C9.30553 2.91671 8.71526 3.15976 8.22915 3.64587C7.74303 4.13198 7.49998 4.72226 7.49998 5.41671V7.08337Z" />
    </SvgIcon>
  );
}
