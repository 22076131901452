import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import { CREATE_LOCATION_FOLDER, GET_LOCATIONS_SIDEBAR, MutationCreateLocationFolderArgs } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_FOLDER_NAME } from './helpers';

interface FormProps {
  [FIELD_FOLDER_NAME]: string;
}

interface AddLocationModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const AddLocationModal: FC<AddLocationModalProps> = ({ isModalOpen, onCloseModal }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  const { folderId = '' } = useParams();

  const [CreateFolder] = useMutation<MutationCreateLocationFolderArgs>(CREATE_LOCATION_FOLDER, {
    variables: {
      parentId: folderId?.length ? folderId : undefined
    },
    refetchQueries: [GET_LOCATIONS_SIDEBAR, 'locationFolders']
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_FOLDER_NAME]: ''
    }
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    CreateFolder({
      variables: { name: data.name },
      onCompleted: () => {
        handleCloseModal();
        // TODO: here some response issue with the parent id
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.folderCreated'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog
      headerTitle={tLocations('table.create.modal.title')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tLocations('table.create.modal.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_FOLDER_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tLocations('table.create.modal.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        <Controller
          name={FIELD_FOLDER_NAME}
          control={control}
          rules={{
            required: {
              value: true,
              message: tGlobal('validation.required')
            },
            pattern: {
              value: ROLE_NAME_REG_EXP,
              message: tGlobal('validation.textFieldLength')
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextFieldControl
                label={tLocations('table.create.modal.locationInputLabel')}
                placeholder={tLocations('table.create.modal.locationInputPlaceholder')}
                name={FIELD_FOLDER_NAME}
                value={value}
                validation={{
                  isValid: !errors[FIELD_FOLDER_NAME]?.message,
                  error: errors[FIELD_FOLDER_NAME]?.message
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
      </form>
    </Dialog>
  );
};
