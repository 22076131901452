import { styled } from '@mui/material/styles';

export const StyledHeaderMenu = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 48px',
  '.header__menu': {
    '&__i': {
      color: '#121B2A',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '20px',
      padding: '25px 0',
      position: 'relative',
      whiteSpace: 'nowrap',
      '&:not(:last-child)': {
        margin: '0 24px 0 0'
      },
      '&__line': {
        position: 'absolute',
        bottom: '0',
        left: '0',
        borderRadius: '1px 1px 0px 0px',
        background: '#326CC9',
        height: '4px',
        width: '100%',
        transformOrigin: 'bottom left',
        transition: 'transform 0.25s ease-out',
        transform: 'scaleX(0)'
      },
      '&--active, &:hover': {
        '.header__menu__i__line': {
          transform: 'scaleX(1)'
        }
      }
    }
  }
}));
