import { ReactNode } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { PlusIcon } from '@/separatedModules/components';
export interface NotFoundCompaniesProps {
  type: string;
  imageUrl: string;
  title?: string;
  subTitle?: string;
  buttonText?: string;
  onClick?: () => void;
  afterContent?: ReactNode;
}

export const NotFoundCompanies = ({ type, imageUrl, title, subTitle, buttonText, onClick }: NotFoundCompaniesProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {imageUrl ? (
        <img src={imageUrl} className={'not-found-screens__img img-fluid'} alt={`Not found ${type} image`} />
      ) : null}

      {title ? (
        <Text className={'not-found-screens__title'} variant={'titleLg'}>
          {title}
        </Text>
      ) : null}

      {subTitle ? (
        <Text className={'not-found-screens__subTitle'} variant={'bodyLg'}>
          {subTitle}
        </Text>
      ) : null}

      {buttonText ? (
        <Button
          variant={'text'}
          size={'small'}
          sx={{
            margin: '0 !important',
            '&.MuiButton-text': {
              '&:not(:active, :hover)': {
                background: 'transparent'
              }
            }
          }}
          startIcon={<PlusIcon />}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      ) : null}
    </Box>
  );
};
