// export const NODE_ENV = process.env.NODE_ENV;
// export const IS_PRODUCTION = NODE_ENV === 'production';

export const APP_URL = process.env.REACT_APP_APP_URL;
export const API_REST_URL = process.env.REACT_APP_API_REST_URL;
export const API_GRAPHQL_URL = process.env.REACT_APP_API_GRAPHQL_URL;
export const API_WS_URL = process.env.REACT_APP_API_WS_URL;

export const KEYCLOAK_ISSUER = process.env.REACT_APP_KEYCLOAK_ISSUER;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const KEYCLOAK_CLIENT_SECRET = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;
