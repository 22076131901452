import { styled } from '@mui/material/styles';

export const StyledNotFoundScreens = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'center',
  '.not-found-screens': {
    '&__content': {},
    '&__img': {
      margin: '0 auto 48px',
      flex: '0 1 auto'
    },
    '&__title': {},
    '&__subTitle': {},
    '&__title, &__subTitle': {
      textAlign: 'center'
    }
  }
}));

StyledNotFoundScreens.defaultProps = {
  className: 'not-found-screens'
};
