export const Project = {
  root: {
    margin: '24px 0',
    background: 'var(--background-neutral-subtle-hovered, rgba(9, 14, 22, 0.06))',
    '&.MuiSkeleton-root__table': {
      background: 'linear-gradient(-45deg, #D8DDDF 0%, #EEEFF0 100%)',
      margin: '0 8px'
    }
  }
};

export const Base = {
  root: {}
};
