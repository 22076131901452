import { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { useTranslation } from '@/i18n';

interface ModalDiscardAllChangesProps {
  navigateToUrl: string;
  sxBox?: SxProps<Theme>;
}

export const ModalDiscardAllChanges = memo(({ navigateToUrl, sxBox }: ModalDiscardAllChangesProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigateTo = useNavigate();

  const handleGoHomePage = useCallback(() => {
    setIsModalOpen(false);

    navigateTo(navigateToUrl);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Box sx={{ ...sxBox }}>
      <Button variant={'text'} size={'small'} sx={{ margin: '0 0 0 -16px !important' }} onClick={handleOpenModal}>
        {tGlobal('modal.discardAllChanges.btnCancel')}
      </Button>

      <Dialog
        headerTitle={tGlobal('modal.discardAllChanges.title')}
        hideHeaderBorder
        contentFooter={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className={'my-0'} variant={'text'} onClick={handleCloseModal}>
              {tGlobal('modal.discardAllChanges.btnNo')}
            </Button>
            <Button className={'my-0'} variant={'contained'} onClick={handleGoHomePage}>
              {tGlobal('modal.discardAllChanges.btnYes')}
            </Button>
          </div>
        }
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <Text variant={'bodyLg'} sx={{ maxWidth: '400px', color: 'var(--text-default, #090E16)' }}>
          {tGlobal('modal.discardAllChanges.content')}
        </Text>
      </Dialog>
    </Box>
  );
});
