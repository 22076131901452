import { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'infinitytechnologies-ui';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useLocations } from '@/logicLayers/infrastructure/redux';

import { AddFolderIcon, EquipmentIcon, PlusIcon } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

interface AddLocationButtonProps {
  onOpenModal: () => void;
  disabled?: boolean;
}

export const AddLocationButton: FC<AddLocationButtonProps> = ({ onOpenModal, disabled = false }) => {
  const { t: tLocations } = useTranslation('locations');
  const navigateTo = useNavigate();
  const {
    currentLocationFolderData: { folderId, canCreateFolder }
  } = useLocations();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant={'contained'}
        startIcon={<PlusIcon />}
        disableElevation
        aria-controls={open ? 'create location' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickButton}
      >
        {tLocations('create.btnAdd')}
      </Button>

      <Menu
        id={'add-location-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'location-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ margin: '12px 0 0 0' }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigateTo(LINKS_PAGES.equipmentItemCreate.replace(':locationId', folderId || 'root'));
          }}
        >
          <EquipmentIcon />

          {tLocations('table.create.menuList.equipment')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            onOpenModal();
          }}
          disabled={!canCreateFolder}
        >
          <AddFolderIcon />

          {tLocations('table.create.menuList.location')}
        </MenuItem>
      </Menu>
    </>
  );
};
