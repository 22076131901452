import { forwardRef, ReactNode } from 'react';

import { MenuItemProps } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { SxProps, Theme } from '@mui/material/styles';

import { CustomCheckIcon } from '@/separatedModules/components';

import { StyledMenuItemDivider } from './styles';

interface BaseMenuItemProps extends MenuItemProps {
  sxBox?: SxProps<Theme>;
  sxMenuItem?: SxProps<Theme>;
  children: ReactNode;
  hasDivider?: boolean;
  checked?: boolean;
}

export const BaseMenuItem = forwardRef<HTMLLIElement, BaseMenuItemProps>(
  ({ children, hasDivider = false, sxBox, sxMenuItem, checked, ...restProps }, ref) => {
    return (
      <Box display={'flex'} flexWrap={'wrap'} sx={{ ...sxBox }}>
        <MenuItem ref={ref} sx={{ ...sxMenuItem }} {...restProps}>
          {children}
          {checked && <CustomCheckIcon className={'m-0'} />}
        </MenuItem>

        {hasDivider ? <StyledMenuItemDivider hasSpace={'hasSpace'} state={'active'} sx={{ width: '100%' }} /> : null}
      </Box>
    );
  }
);
