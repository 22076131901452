export const Project = {
  root: {}
};

export const Base = {
  root: {
    transition: 'all 0.3s ease-in-out',
    borderRadius: 'var(--16, 16px)',
    '&.MuiChip-sizeSmall': {},
    '&.MuiChip-sizeMedium': {}
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    padding: '0 8px'
  },
  sizeSmall: {
    padding: '4px'
  },
  outlined: {},
  filled: {},
  colorSuccess: {
    background: 'var(--Custom-styles-Primary-50, #E6F6EF)',
    color: 'var(--text-success, #086445)'
  },
  colorError: {
    background: 'var(--background-danger-default, #FFF5F7)',
    color: 'var(--text-danger, #BE0E2C)'
  },
  colorSecondary: {
    background: 'var(--background-neutral-subtle-default, rgba(9, 14, 22, 0.03))',
    color: 'var(--text-subtle, #505668)'
  },
  colorWarning: {
    background: 'var(--background-warning-default, #FFFAE7)',
    color: 'var(--text-warning, #946F00)'
  },
  colorInfo: {}
};
