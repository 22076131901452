import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function HomeFillIcon(props: SvgIconProps) {
  const { sx, fill = '#2D96FF', ...restProps } = props;

  return (
    <SvgIcon
      width={'25'}
      height={'24'}
      viewBox={'0 0 25 24'}
      fill={'none'}
      sx={{ fill: fill, fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M6.49997 19H9.84615V13.9615C9.84615 13.7055 9.93277 13.4908 10.106 13.3176C10.2792 13.1443 10.4939 13.0577 10.75 13.0577H14.25C14.5061 13.0577 14.7207 13.1443 14.8939 13.3176C15.0672 13.4908 15.1538 13.7055 15.1538 13.9615V19H18.5V10.1539C18.5 10.1026 18.4888 10.0561 18.4663 10.0144C18.4439 9.97277 18.4134 9.93591 18.375 9.90386L12.6827 5.62501C12.6314 5.58015 12.5705 5.55771 12.5 5.55771C12.4295 5.55771 12.3686 5.58015 12.3173 5.62501L6.62497 9.90386C6.58652 9.93591 6.55607 9.97277 6.53362 10.0144C6.51119 10.0561 6.49997 10.1026 6.49997 10.1539V19ZM5 19V10.1539C5 9.86765 5.06402 9.5965 5.19207 9.34041C5.32012 9.08433 5.49712 8.87344 5.72307 8.70774L11.4154 4.41929C11.7313 4.17826 12.0923 4.05774 12.4984 4.05774C12.9046 4.05774 13.2666 4.17826 13.5846 4.41929L19.2769 8.70774C19.5028 8.87344 19.6798 9.08433 19.8079 9.34041C19.9359 9.5965 20 9.86765 20 10.1539V19C20 19.409 19.8522 19.7612 19.5567 20.0567C19.2612 20.3522 18.9089 20.5 18.5 20.5H14.5577C14.3016 20.5 14.0869 20.4134 13.9137 20.2401C13.7404 20.0669 13.6538 19.8522 13.6538 19.5961V14.5577H11.3461V19.5961C11.3461 19.8522 11.2595 20.0669 11.0863 20.2401C10.913 20.4134 10.6984 20.5 10.4423 20.5H6.49997C6.09101 20.5 5.73877 20.3522 5.44327 20.0567C5.14776 19.7612 5 19.409 5 19Z" />
      <path d="M6.49997 19H9.84615V13.9615C9.84615 13.7055 9.93277 13.4908 10.106 13.3176C10.2792 13.1443 10.4939 13.0577 10.75 13.0577H14.25C14.5061 13.0577 14.7207 13.1443 14.8939 13.3176C15.0672 13.4908 15.1538 13.7055 15.1538 13.9615V19H18.5V10.1539C18.5 10.1026 18.4888 10.0561 18.4663 10.0144C18.4439 9.97277 18.4134 9.93591 18.375 9.90386L12.6827 5.62501C12.6314 5.58015 12.5705 5.55771 12.5 5.55771C12.4295 5.55771 12.3686 5.58015 12.3173 5.62501L6.62497 9.90386C6.58652 9.93591 6.55607 9.97277 6.53362 10.0144C6.51119 10.0561 6.49997 10.1026 6.49997 10.1539V19Z" />
    </SvgIcon>
  );
}

<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"></svg>;
