import { FC } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

interface ReportPageProps {}

export const ReportPage: FC<ReportPageProps> = () => {
  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} md={10} lg={8} item>
        <Text component={'h2'}>Templates Report Page</Text>
      </Grid>
    </Grid>
  );
};
