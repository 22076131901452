import { FC } from 'react';

import Box from '@mui/material/Box';

import { AllLocationsTab, FavoritesTab, RecentTab } from './components';

interface TabsContentProps {
  tabIndex: number;
  sourceLocationId?: string;
}

export const TabsContent: FC<TabsContentProps> = ({ tabIndex, sourceLocationId }) => (
  <Box width={'100%'} minHeight={'240px'} height={'100%'} maxHeight={'240px'} sx={{ overflow: 'auto' }} py={'12px'}>
    {tabIndex === 0 ? <AllLocationsTab sourceLocationId={sourceLocationId} /> : null}
    {tabIndex === 1 ? <RecentTab /> : null}
    {tabIndex === 2 ? <FavoritesTab /> : null}
  </Box>
);
