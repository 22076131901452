import { gql as gqlApollo } from '@apollo/client';

export const CREATE_ROLE_TEMPLATE = gqlApollo(`
  mutation CreateRoleTemplate($name: String!, $description: String, $permissions: [String]!, $domainIds: [String]) {
    createRoleTemplate(request: { name: $name, description: $description, permissions: $permissions, domainIds: $domainIds }) {
      name
      permissions {
        name
        operation
        resource
        description
      }
    }
  }
`);

export const GET_ROLE_TEMPLATES = gqlApollo(`
  query GetRoleTemplates($searchCriteria: SearchCriteriaInput!) {
    dataItems: roleTemplates(searchCriteria: $searchCriteria) {
      items {
        id
        companyId
        name
        lastModifiedDate
        createdDate
        usageCount
        description
        permissions {
          name
          operation
          resource
          description
        }
        createdBy {
          firstName
          lastName
          userAvatar {
            fileKey
            url
          }
        }
        domains {
          id
          name
          color
        }
        domainIds
      }
      total
    }
  }
`);

export const GET_ROLE_TEMPLATE_BY_ID = gqlApollo(`
  query GetRoleTemplate($id: String!) {
    roleTemplate(id: $id) {
      id
      name
      description
      permissions {
        name
        operation
        resource
      }
      domains {
        id
        name
      }
    }
  }
`);

export const GET_ROLE_TEMPLATE_DETAILS_BY_ID = gqlApollo(`
  query GetRoleTemplateDetails($id: String!) {
    roleTemplate(id: $id) {
      id
      name
      description
      createdBy {
        firstName
        lastName
        userAvatar {
          fileKey
          url
        }
      }
      usageCount
      createdDate
      lastModifiedDate
    }
  }
`);

export const EDIT_ROLE_TEMPLATE = gqlApollo(`
  mutation EditRoleTemplate($id: String!, $name: String!, $description: String, $permissions: [String]!, $domainIds: [String]) {
    editRoleTemplate(id: $id, request: { name: $name, description: $description, permissions: $permissions, domainIds: $domainIds }) {
      name
      permissions {
        name
        operation
        resource
      }
    }
  }
`);
