import { useCallback, useState } from 'react';
import { Button } from 'infinitytechnologies-ui';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

import { ModalDiscardAllChanges, TextBlockControl } from '@/separatedModules/components';

import { LINKS_IMAGES, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ChooseFlowItem } from './components/ChooseFlowItem';

const FlowTypes: Record<string, string> = {
  blank: 'blank',
  template: 'template'
};

type Keys = keyof typeof FlowTypes;

interface ChooseFlowProps {
  setSelectedFlow: (type: (typeof FlowTypes)[Keys]) => void;
}

export const ChooseFlow = ({ setSelectedFlow }: ChooseFlowProps) => {
  const { t: tCompany } = useTranslation('company');

  const [selectedFlowType, setSelectedFlowType] = useState<string | null>(null);
  const [selectedFlowError, setSelectedFlowError] = useState(false);

  const handleSelectFlow = useCallback((id: string) => {
    setSelectedFlowType(id);
    setSelectedFlowError(false);
  }, []);

  const handleValidateFlow = () => {
    if (!selectedFlowType) {
      setSelectedFlowError(true);
    } else {
      setSelectedFlow(selectedFlowType);
    }
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        <ModalDiscardAllChanges navigateToUrl={LINKS_PAGES.home} />

        <TextBlockControl title={tCompany('create.choseFlow.title')} isTitleLg />

        <Grid justifyContent={'center'} container row>
          <Grid xs={12} sm={8} md={6} item>
            <ChooseFlowItem
              id={FlowTypes.blank}
              title={tCompany('create.choseFlow.blankTitle')}
              titleSub={tCompany('create.choseFlow.blankDescription')}
              imageSrc={LINKS_IMAGES.companyCreateSelectFlowBlank}
              selected={FlowTypes.blank === selectedFlowType}
              onClick={handleSelectFlow}
            />
          </Grid>

          <Grid xs={12} sm={8} md={6} item>
            <ChooseFlowItem
              id={FlowTypes.template}
              title={tCompany('create.choseFlow.templateTitle')}
              titleSub={tCompany('create.choseFlow.templateDescription')}
              imageSrc={LINKS_IMAGES.companyCreateSelectFlowTemplate}
              disabled
              selected={FlowTypes.template === selectedFlowType}
              onClick={handleSelectFlow}
            />
          </Grid>
        </Grid>

        {!selectedFlowType && selectedFlowError ? (
          <FormHelperText component={'div'} sx={{ margin: '8px 0 0 0' }} error={selectedFlowError}>
            <ErrorOutlineIcon sx={{ fontSize: '16px' }} />

            <div className={'MuiFormHelperText-root__text'}>{tCompany('create.validation.selectFLow')}</div>
          </FormHelperText>
        ) : null}

        <Button className={'create-company__content__btn'} variant={'contained'} onClick={handleValidateFlow}>
          {tCompany('create.choseFlow.btn.continue')}
        </Button>
      </Grid>
    </Grid>
  );
};
