import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { AuthProvider as OIDCAuthProvider, AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

import { APP_URL, KEYCLOAK_ISSUER } from '@/config';

import { CLIENT_ID_NAME, CLIENT_SECRET_NAME } from '@/subsidiaryBinders/constants';

const onSignInCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

// @ts-ignore
const OIDC_CONFIG: AuthProviderProps = {
  // @ts-ignore
  email: '',
  authority: KEYCLOAK_ISSUER,
  // ToDo Add service
  client_id: localStorage.getItem(CLIENT_ID_NAME) || '',
  // ToDo Add service
  client_secret: localStorage.getItem(CLIENT_SECRET_NAME) || '',
  redirect_uri: APP_URL,
  post_logout_redirect_uri: APP_URL,
  scope: 'profile email',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: onSignInCallback
};

interface IAuthProviderProps {
  children: ReactNode;
}

interface CompanyAuthParamsI {
  client_id: string;
  client_secret: string;
  email: string;
}

export interface AuthContextI {
  state: AuthProviderProps;
  handleSetClientId: (value: CompanyAuthParamsI) => void;
}

export const AuthContext = createContext<AuthContextI>({
  state: {},
  handleSetClientId() {}
});

export const AuthOidcProviderWrapper = ({ children }: IAuthProviderProps) => {
  const [state, setState] = useState(OIDC_CONFIG);

  const handleSetClientId = ({ client_id, client_secret, email }: CompanyAuthParamsI) => {
    client_id && localStorage.setItem('client_id', client_id);
    client_id && localStorage.setItem('client_secret', client_secret);

    setState((state: any) => ({
      ...state,
      client_id,
      client_secret,
      email
    }));
  };

  const contextValue = useMemo(
    () => ({
      state,
      handleSetClientId
    }),
    [state]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const AuthOidcProvider = ({ children }: IAuthProviderProps) => {
  const { state } = useContext(AuthContext);

  return (
    <OIDCAuthProvider key={state.client_id} {...state}>
      {children}
    </OIDCAuthProvider>
  );
};
