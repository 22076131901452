import { ReactNode } from 'react';

// ToDo Remove
export const DEFAULT_HIDDEN_KEY_COLUMN = 'id';

// ToDo Remove
export const MIN_TABLE_WIDTH = '650px';

// ToDo Remove
export type CustomField = `__${string}`;

export type TableRow = Record<string, ReactNode | any> & { selected?: boolean };

// ToDo discuss
export interface TableColumn {
  key: string | CustomField;
  title: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  visible?: boolean;
  selected?: boolean;
}
