import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1852_94082)">
          <path
            d="M200 47.9172V152.083C200 164.751 189.751 175 177.083 175H22.9172C20.8328 175 18.8339 174.75 16.9998 174.167C7.16705 171.584 0 162.666 0 152.083V47.9172C0 35.2493 10.2493 25 22.9172 25H177.083C189.751 25 200 35.2493 200 47.9172Z"
            fill="#F3F4F6"
          />
          <path
            d="M58.3328 66.6672C58.3328 75.8713 50.8713 83.3328 41.6672 83.3328C32.4615 83.3328 25 75.8713 25 66.6672C25 57.4615 32.4615 50 41.6672 50C50.8713 50 58.3328 57.4615 58.3328 66.6672Z"
            fill="#FCD34D"
          />
          <path
            d="M200 132.834V152.083C200 164.751 189.751 175 177.083 175H22.9174C20.833 175 18.8341 174.75 17 174.167L118.834 72.3327C124.5 66.6671 133.833 66.6671 139.501 72.3327L200 132.834Z"
            fill="#059669"
          />
          <path
            d="M142.166 175H22.9172C20.8328 175 18.8339 174.75 16.9998 174.167C7.16705 171.583 0 162.666 0 152.083V149.499L47.9996 101.5C53.6667 95.8327 63.0005 95.8327 68.6661 101.5L142.166 175Z"
            fill="#10B981"
          />
        </g>
        <defs>
          <clipPath id="clip0_1852_94082">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
