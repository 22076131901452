import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'52'} height={'33'} viewBox={'0 0 52 33'} {...props} style={{ fill: 'black' }}>
      <path d="M1 32.7943V18.2653H7.73356C8.97078 18.2653 10.0027 18.4237 10.8294 18.7406C11.656 19.0575 12.2773 19.4973 12.6934 20.0601C13.1095 20.6182 13.3175 21.2614 13.3175 21.9898C13.3175 22.5573 13.1861 23.0563 12.9233 23.4866C12.6606 23.9123 12.2992 24.2623 11.8394 24.5366C11.385 24.8062 10.8649 24.9977 10.2792 25.1112V25.2531C10.9197 25.2768 11.5191 25.4328 12.0775 25.7213C12.6414 26.0098 13.0985 26.4142 13.4489 26.9344C13.7992 27.45 13.9744 28.0648 13.9744 28.7789C13.9744 29.5498 13.7527 30.238 13.3093 30.8434C12.8713 31.444 12.2226 31.9193 11.3631 32.2693C10.5036 32.6193 9.44432 32.7943 8.1852 32.7943H1ZM4.55565 30.2829H7.45436C8.44524 30.2829 9.16786 30.1197 9.62224 29.7934C10.0766 29.4623 10.3038 29.0225 10.3038 28.4739C10.3038 28.0719 10.1916 27.7172 9.96713 27.4098C9.74268 27.1023 9.42243 26.8611 9.00637 26.6861C8.59579 26.5112 8.10582 26.4237 7.53648 26.4237H4.55565V30.2829ZM4.55565 24.3451H7.19159C7.67882 24.3451 8.1113 24.2717 8.48903 24.1251C8.87225 23.9738 9.17334 23.761 9.39232 23.4866C9.61677 23.2123 9.72899 22.8836 9.72899 22.5006C9.72899 21.9756 9.51275 21.5523 9.08027 21.2307C8.65327 20.9091 8.0456 20.7483 7.25729 20.7483H4.55565V24.3451Z" />
      <path d="M16.7505 18.2653H21.1356L25.7669 28.0269H25.964L30.5954 18.2653H34.9804V32.7943H31.5315V23.3377H31.3919L27.0397 32.7233H24.6912L20.339 23.3022H20.1994V32.7943H16.7505V18.2653Z" />
      <path d="M47.4956 22.4438C47.4299 21.8715 47.1479 21.427 46.6498 21.1101C46.1516 20.7932 45.4755 20.6348 44.6215 20.6348C44.0412 20.6348 43.5512 20.7057 43.1516 20.8476C42.752 20.9848 42.4454 21.1763 42.2319 21.4222C42.0239 21.6682 41.9199 21.9472 41.9199 22.2594C41.9089 22.5195 41.9719 22.7465 42.1087 22.9404C42.2511 23.1343 42.4454 23.3022 42.6917 23.4441C42.9381 23.5812 43.2228 23.7018 43.5458 23.8059C43.8688 23.9052 44.2136 23.9903 44.5804 24.0613L46.0914 24.3734C46.825 24.5153 47.4983 24.7045 48.1114 24.941C48.7246 25.1774 49.2556 25.4683 49.7045 25.8136C50.1534 26.1588 50.501 26.5655 50.7474 27.0338C50.9992 27.502 51.1279 28.0388 51.1333 28.6441C51.1279 29.5333 50.8651 30.3042 50.345 30.9569C49.8304 31.6048 49.0859 32.1085 48.1114 32.4679C47.1425 32.8226 45.9737 33 44.6051 33C43.2474 33 42.0649 32.8203 41.0576 32.4608C40.0558 32.1014 39.273 31.5693 38.7091 30.8646C38.1507 30.1552 37.8578 29.2779 37.8304 28.2327H41.2711C41.3095 28.7198 41.471 29.1266 41.7556 29.4529C42.0458 29.7745 42.4317 30.0181 42.9135 30.1836C43.4007 30.3444 43.9509 30.4248 44.564 30.4248C45.1662 30.4248 45.689 30.3491 46.1324 30.1978C46.5813 30.0464 46.929 29.836 47.1753 29.5664C47.4217 29.2968 47.5448 28.987 47.5448 28.6371C47.5448 28.3107 47.4326 28.0364 47.2082 27.8141C46.9892 27.5918 46.6662 27.4027 46.2392 27.2466C45.8177 27.0905 45.3003 26.9486 44.6872 26.8209L42.856 26.4237C41.4381 26.1257 40.3186 25.6598 39.4974 25.0261C38.6762 24.3923 38.2684 23.5387 38.2739 22.4651C38.2684 21.5854 38.5394 20.8169 39.0868 20.1595C39.6398 19.5021 40.398 18.9889 41.3615 18.62C42.325 18.2511 43.4198 18.0667 44.6461 18.0667C45.8943 18.0667 46.9837 18.2511 47.9144 18.62C48.8505 18.9889 49.5786 19.5021 50.0987 20.1595C50.6187 20.8169 50.887 21.5783 50.9034 22.4438H47.4956Z" />
      <path d="M9.67188 3.70925H8.71061C8.63903 3.42535 8.5095 3.16308 8.32201 2.92243C8.13794 2.67909 7.90615 2.46684 7.62663 2.28568C7.34711 2.10452 7.03009 1.96392 6.67558 1.86388C6.32107 1.76384 5.93759 1.71381 5.52513 1.71381C4.86383 1.71381 4.26048 1.85036 3.71508 2.12345C3.17308 2.39654 2.73847 2.79806 2.41123 3.32801C2.08739 3.85526 1.92548 4.50148 1.92548 5.26667C1.92548 6.03726 2.08739 6.68619 2.41123 7.21344C2.73847 7.74068 3.17308 8.14085 3.71508 8.41394C4.26048 8.68433 4.86383 8.81952 5.52513 8.81952C5.93759 8.81952 6.32107 8.7695 6.67558 8.66946C7.03009 8.56941 7.34711 8.43017 7.62663 8.25171C7.90615 8.07055 8.13794 7.8583 8.32201 7.61496C8.5095 7.37161 8.63903 7.10799 8.71061 6.82408H9.67188C9.58666 7.20532 9.42645 7.56088 9.19125 7.89075C8.95945 8.21791 8.66289 8.50452 8.30156 8.75057C7.94364 8.99662 7.53118 9.18859 7.06418 9.32649C6.59718 9.46439 6.08416 9.53333 5.52513 9.53333C4.64567 9.53333 3.86506 9.35894 3.18331 9.01014C2.50156 8.65864 1.96638 8.16384 1.57778 7.52573C1.19259 6.88762 1 6.1346 1 5.26667C1 4.39873 1.19259 3.64571 1.57778 3.0076C1.96638 2.3695 2.50156 1.87605 3.18331 1.52725C3.86506 1.17575 4.64567 1 5.52513 1C6.08416 1 6.59718 1.06895 7.06418 1.20684C7.53118 1.34204 7.94364 1.53401 8.30156 1.78276C8.66289 2.02881 8.95945 2.31542 9.19125 2.64259C9.42645 2.96975 9.58666 3.32531 9.67188 3.70925Z" />
      <path d="M11.8 1.11356H12.9045L16.0081 5.05577H16.1002L19.2038 1.11356H20.3083L16.5297 5.82231V9.41977H15.5786V5.82231L11.8 1.11356Z" />
      <path d="M22.9682 9.41977V1.11356H26.5014C27.1831 1.11356 27.7507 1.2082 28.204 1.39747C28.6608 1.58673 29.0034 1.84495 29.2318 2.17212C29.4602 2.49928 29.5744 2.87106 29.5744 3.28745C29.5744 3.63354 29.5011 3.92962 29.3545 4.17567C29.2079 4.42172 29.0085 4.62045 28.7563 4.77186C28.504 4.92328 28.2228 5.03278 27.9126 5.10038V5.16527C28.2501 5.1842 28.5773 5.27883 28.8943 5.44918C29.2113 5.61681 29.4721 5.85205 29.6766 6.15488C29.8812 6.45771 29.9834 6.82003 29.9834 7.24183C29.9834 7.66092 29.8658 8.0354 29.6306 8.36527C29.3954 8.69244 29.0341 8.95066 28.5466 9.13992C28.0626 9.32649 27.4456 9.41977 26.6957 9.41977H22.9682ZM23.9243 8.7384H26.6957C27.5035 8.7384 28.1001 8.59645 28.4853 8.31255C28.8739 8.02864 29.0682 7.67174 29.0682 7.24183C29.0682 6.92548 28.971 6.63887 28.7767 6.382C28.5858 6.12243 28.3148 5.91694 27.9637 5.76553C27.616 5.61411 27.207 5.5384 26.7366 5.5384H23.9243V8.7384ZM23.9243 4.86515H26.5883C27.0008 4.86515 27.3621 4.79755 27.6723 4.66236C27.9825 4.52717 28.2245 4.3406 28.3983 4.10266C28.5722 3.86472 28.6591 3.59299 28.6591 3.28745C28.6591 2.84673 28.4784 2.48847 28.1171 2.21267C27.7558 1.93418 27.2172 1.79493 26.5014 1.79493H23.9243V4.86515Z" />
      <path d="M33.1546 9.41977V1.11356H39.229V1.79493H34.1108V4.92193H38.9069V5.6033H34.1108V8.7384H39.3313V9.41977H33.1546Z" />
      <path d="M42.5677 9.41977V1.11356H45.9475C46.6906 1.11356 47.311 1.22036 47.8087 1.43397C48.3098 1.64487 48.6864 1.93823 48.9387 2.31407C49.1943 2.6872 49.3222 3.11576 49.3222 3.59975C49.3222 4.08373 49.1943 4.51094 48.9387 4.88137C48.6864 5.2518 48.3115 5.54111 47.8138 5.7493C47.3161 5.9575 46.6991 6.0616 45.9628 6.0616H43.0841V5.36806H45.9373C46.4827 5.36806 46.936 5.29641 47.2974 5.15311C47.6621 5.0098 47.9331 4.80566 48.1104 4.54068C48.291 4.27571 48.3814 3.96206 48.3814 3.59975C48.3814 3.23743 48.291 2.92108 48.1104 2.6507C47.9297 2.37761 47.657 2.16671 47.2923 2.018C46.9309 1.86929 46.4742 1.79493 45.9219 1.79493H43.5239V9.41977H42.5677ZM47.1849 5.67224L49.7619 9.41977H48.6575L46.106 5.67224H47.1849Z" />
      <path
        d="M9.67188 3.70925H8.71061C8.63903 3.42535 8.5095 3.16308 8.32201 2.92243C8.13794 2.67909 7.90615 2.46684 7.62663 2.28568C7.34711 2.10452 7.03009 1.96392 6.67558 1.86388C6.32107 1.76384 5.93759 1.71381 5.52513 1.71381C4.86383 1.71381 4.26048 1.85036 3.71508 2.12345C3.17308 2.39654 2.73847 2.79806 2.41123 3.32801C2.08739 3.85526 1.92548 4.50148 1.92548 5.26667C1.92548 6.03726 2.08739 6.68619 2.41123 7.21344C2.73847 7.74068 3.17308 8.14085 3.71508 8.41394C4.26048 8.68433 4.86383 8.81952 5.52513 8.81952C5.93759 8.81952 6.32107 8.7695 6.67558 8.66946C7.03009 8.56941 7.34711 8.43017 7.62663 8.25171C7.90615 8.07055 8.13794 7.8583 8.32201 7.61496C8.5095 7.37161 8.63903 7.10799 8.71061 6.82408H9.67188C9.58666 7.20532 9.42645 7.56088 9.19125 7.89075C8.95945 8.21791 8.66289 8.50452 8.30156 8.75057C7.94364 8.99662 7.53118 9.18859 7.06418 9.32649C6.59718 9.46439 6.08416 9.53333 5.52513 9.53333C4.64567 9.53333 3.86506 9.35894 3.18331 9.01014C2.50156 8.65864 1.96638 8.16384 1.57778 7.52573C1.19259 6.88762 1 6.1346 1 5.26667C1 4.39873 1.19259 3.64571 1.57778 3.0076C1.96638 2.3695 2.50156 1.87605 3.18331 1.52725C3.86506 1.17575 4.64567 1 5.52513 1C6.08416 1 6.59718 1.06895 7.06418 1.20684C7.53118 1.34204 7.94364 1.53401 8.30156 1.78276C8.66289 2.02881 8.95945 2.31542 9.19125 2.64259C9.42645 2.96975 9.58666 3.32531 9.67188 3.70925Z"
        strokeWidth="0.5"
      />
      <path
        d="M11.8 1.11356H12.9045L16.0081 5.05577H16.1002L19.2038 1.11356H20.3083L16.5297 5.82231V9.41977H15.5786V5.82231L11.8 1.11356Z"
        strokeWidth="0.5"
      />
      <path
        d="M22.9682 9.41977V1.11356H26.5014C27.1831 1.11356 27.7507 1.2082 28.204 1.39747C28.6608 1.58673 29.0034 1.84495 29.2318 2.17212C29.4602 2.49928 29.5744 2.87106 29.5744 3.28745C29.5744 3.63354 29.5011 3.92962 29.3545 4.17567C29.2079 4.42172 29.0085 4.62045 28.7563 4.77186C28.504 4.92328 28.2228 5.03278 27.9126 5.10038V5.16527C28.2501 5.1842 28.5773 5.27883 28.8943 5.44918C29.2113 5.61681 29.4721 5.85205 29.6766 6.15488C29.8812 6.45771 29.9834 6.82003 29.9834 7.24183C29.9834 7.66092 29.8658 8.0354 29.6306 8.36527C29.3954 8.69244 29.0341 8.95066 28.5466 9.13992C28.0626 9.32649 27.4456 9.41977 26.6957 9.41977H22.9682ZM23.9243 8.7384H26.6957C27.5035 8.7384 28.1001 8.59645 28.4853 8.31255C28.8739 8.02864 29.0682 7.67174 29.0682 7.24183C29.0682 6.92548 28.971 6.63887 28.7767 6.382C28.5858 6.12243 28.3148 5.91694 27.9637 5.76553C27.616 5.61411 27.207 5.5384 26.7366 5.5384H23.9243V8.7384ZM23.9243 4.86515H26.5883C27.0008 4.86515 27.3621 4.79755 27.6723 4.66236C27.9825 4.52717 28.2245 4.3406 28.3983 4.10266C28.5722 3.86472 28.6591 3.59299 28.6591 3.28745C28.6591 2.84673 28.4784 2.48847 28.1171 2.21267C27.7558 1.93418 27.2172 1.79493 26.5014 1.79493H23.9243V4.86515Z"
        strokeWidth="0.5"
      />
      <path
        d="M33.1546 9.41977V1.11356H39.229V1.79493H34.1108V4.92193H38.9069V5.6033H34.1108V8.7384H39.3313V9.41977H33.1546Z"
        strokeWidth="0.5"
      />
      <path
        d="M42.5677 9.41977V1.11356H45.9475C46.6906 1.11356 47.311 1.22036 47.8087 1.43397C48.3098 1.64487 48.6864 1.93823 48.9387 2.31407C49.1943 2.6872 49.3222 3.11576 49.3222 3.59975C49.3222 4.08373 49.1943 4.51094 48.9387 4.88137C48.6864 5.2518 48.3115 5.54111 47.8138 5.7493C47.3161 5.9575 46.6991 6.0616 45.9628 6.0616H43.0841V5.36806H45.9373C46.4827 5.36806 46.936 5.29641 47.2974 5.15311C47.6621 5.0098 47.9331 4.80566 48.1104 4.54068C48.291 4.27571 48.3814 3.96206 48.3814 3.59975C48.3814 3.23743 48.291 2.92108 48.1104 2.6507C47.9297 2.37761 47.657 2.16671 47.2923 2.018C46.9309 1.86929 46.4742 1.79493 45.9219 1.79493H43.5239V9.41977H42.5677ZM47.1849 5.67224L49.7619 9.41977H48.6575L46.106 5.67224H47.1849Z"
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
}
