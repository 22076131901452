import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ColumnIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 18, ...sx }}
      {...restProps}
    >
      <path d="M3.5904 15H6.94778V4.99998H3.5904C3.51561 4.99998 3.45417 5.02402 3.40609 5.0721C3.35801 5.12019 3.33396 5.18163 3.33396 5.25642V14.7435C3.33396 14.8183 3.35801 14.8798 3.40609 14.9279C3.45417 14.9759 3.51561 15 3.5904 15ZM8.19773 15H11.8035V4.99998H8.19773V15ZM13.0535 15H16.4109C16.4857 15 16.5471 14.9759 16.5952 14.9279C16.6433 14.8798 16.6673 14.8183 16.6673 14.7435V5.25642C16.6673 5.18163 16.6433 5.12019 16.5952 5.0721C16.5471 5.02402 16.4857 4.99998 16.4109 4.99998H13.0535V15ZM3.5904 16.25C3.16946 16.25 2.81315 16.1041 2.52148 15.8125C2.22982 15.5208 2.08398 15.1645 2.08398 14.7435V5.25642C2.08398 4.83547 2.22982 4.47917 2.52148 4.1875C2.81315 3.89583 3.16946 3.75 3.5904 3.75H16.4109C16.8318 3.75 17.1881 3.89583 17.4798 4.1875C17.7714 4.47917 17.9173 4.83547 17.9173 5.25642V14.7435C17.9173 15.1645 17.7714 15.5208 17.4798 15.8125C17.1881 16.1041 16.8318 16.25 16.4109 16.25H3.5904Z" />
    </SvgIcon>
  );
}
