import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { Breadcrumbs } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  }
];

export function UiPage() {
  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <Breadcrumbs links={BreadcrumbsLinks} />
      </Grid>

      <Grid xs={12} item>
        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiButtons}>to Buttons</Link>
        </div>
        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiInputs}>to Inputs, Select, Autocomplete</Link>
        </div>

        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiModals}>to Modal</Link>
        </div>

        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiRadioSwitchCheckbox}>to Radio, Switch, Checkbox</Link>
        </div>

        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiOther}>to Other</Link>
        </div>

        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiIcons}>to Custom Icons</Link>
        </div>

        <div className={'mb-2'}>
          <Link to={LINKS_PAGES.uiTextEditors}>to TextEditors</Link>
        </div>
      </Grid>
    </Grid>
  );
}
