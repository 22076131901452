import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function BellIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'80'} height={'80'} viewBox={'0 0 80 80'} fill={'none'} {...props}>
      <path
        d="M44.6875 14.5677C43.3931 14.5677 42.3438 13.5183 42.3438 12.2239V7.03125C42.3438 5.73891 41.2923 4.6875 40 4.6875C38.7077 4.6875 37.6562 5.73891 37.6562 7.03125V12.2239C37.6562 13.5183 36.6069 14.5677 35.3125 14.5677C34.0181 14.5677 32.9688 13.5183 32.9688 12.2239V7.03125C32.9688 3.15422 36.123 0 40 0C43.877 0 47.0312 3.15422 47.0312 7.03125V12.2239C47.0312 13.5183 45.9819 14.5677 44.6875 14.5677Z"
        fill="#FCD34D"
      />
      <path
        d="M42.3438 7.03125V12.2239C42.3438 13.5183 43.3931 14.5677 44.6875 14.5677C45.9819 14.5677 47.0312 13.5183 47.0312 12.2239V7.03125C47.0312 3.15422 43.877 0 40 0V4.6875C41.2925 4.6875 42.3438 5.73891 42.3438 7.03125Z"
        fill="#FB923C"
      />
      <path
        d="M40 80C33.5383 80 28.2812 74.743 28.2812 68.2812C28.2812 66.9869 29.3306 65.9375 30.625 65.9375H49.375C50.6694 65.9375 51.7188 66.9869 51.7188 68.2812C51.7188 74.743 46.4619 80 40 80Z"
        fill="#FCD34D"
      />
      <path
        d="M49.375 65.9375H40V80C46.4617 80 51.7188 74.743 51.7188 68.2812C51.7188 66.9869 50.6694 65.9375 49.375 65.9375Z"
        fill="#FB923C"
      />
      <path
        d="M72.8125 70.625H7.18753C6.24846 70.625 5.40018 70.0645 5.03174 69.2008C4.66331 68.337 4.84596 67.3369 5.49596 66.6591C12.1285 59.7422 15.7813 50.6552 15.7813 41.0722V33.5938C15.7813 20.2395 26.6458 9.375 40 9.375C53.3542 9.375 64.2188 20.2395 64.2188 33.5938V41.0722C64.2188 50.6553 67.8716 59.7422 74.5042 66.6591C75.1541 67.3369 75.3367 68.337 74.9683 69.2008C74.6 70.0645 73.7516 70.625 72.8125 70.625Z"
        fill="#FDE68A"
      />
      <path
        d="M72.8125 70.625C73.7516 70.625 74.5998 70.0645 74.9683 69.2008C75.3367 68.337 75.1541 67.3369 74.5042 66.6591C67.8716 59.7422 64.2188 50.6552 64.2188 41.0722V33.5938C64.2188 20.2395 53.3542 9.375 40 9.375V70.625H72.8125Z"
        fill="#FCD34D"
      />
    </SvgIcon>
  );
}
