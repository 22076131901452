import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LogOutCustomIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_157_20390)">
          <path
            d="M135.546 5.85938V158.985C135.546 162.265 132.968 164.844 129.686 164.844H71.0938L12.5 0H129.686C132.968 0 135.546 2.57721 135.546 5.85938Z"
            fill="#E5E7EB"
          />
          <path
            d="M135.545 5.85938V158.985C135.545 162.265 132.968 164.844 129.686 164.844H76.9531V0H129.686C132.968 0 135.545 2.57721 135.545 5.85938Z"
            fill="#E5E7EB"
          />
          <path
            d="M68.0787 199.166L9.48487 164.009C7.72247 162.95 6.64062 161.044 6.64062 158.985V5.85927C6.64062 3.74898 7.77283 1.80348 9.60999 0.761303C11.4472 -0.279348 13.7009 -0.251882 15.5152 0.836071L74.109 35.9924C75.8714 37.0498 76.9532 38.9556 76.9532 41.0156V194.141C76.9532 196.253 75.8195 198.198 73.9823 199.239C72.1482 200.279 69.893 200.25 68.0787 199.166Z"
            fill="#FACC15"
          />
          <path
            d="M53.5039 111.719C53.5039 114.955 50.8809 117.578 47.6445 117.578C44.4097 117.578 41.7852 114.955 41.7852 111.719C41.7852 108.482 44.4097 105.859 47.6445 105.859C50.8809 105.859 53.5039 108.482 53.5039 111.719Z"
            fill="white"
          />
          <path
            d="M191.562 77.4993L168.124 59.9212C166.249 58.7493 164.023 58.6318 162.03 59.6877C160.155 60.7421 158.983 62.7349 158.983 64.8437V70.7031H106.25C102.968 70.7031 100.391 73.2803 100.391 76.5625V88.2812C100.391 91.5619 102.968 94.1406 106.25 94.1406H158.983V100C158.983 102.109 160.155 104.102 162.03 105.156C163.905 106.21 166.249 106.093 168.124 104.921L191.562 87.3428C193.202 86.1725 194.139 84.4131 194.139 82.4218C194.139 80.429 193.202 78.6712 191.562 77.4993Z"
            fill="#FACC15"
          />
          <path
            d="M194.139 82.4219C194.139 84.4131 193.202 86.1725 191.562 87.3428L168.124 104.921C166.249 106.093 163.905 106.21 162.03 105.156C160.155 104.102 158.983 102.109 158.983 100V94.1406H106.25C102.968 94.1406 100.391 91.5619 100.391 88.2813V82.4219H194.139Z"
            fill="#FACC15"
          />
        </g>
        <defs>
          <clipPath id="clip0_157_20390">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
