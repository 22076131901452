import { useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { Flex } from '@/separatedModules/components';

import { ActionsContainer } from './components/ActionsContainer';
import { FiltersContainer } from './components/FiltersContainer';
import { PageTopBox } from './components/PageTopBox';
import { ProviderState, ProviderUpdateState } from './components/Providers';
import { TableRoles } from './components/TableRoles';

export const RoleUsersPage = () => {
  const { roleId = '' } = useParams();

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item sx={{ minHeight: '70vh' }}>
        <ProviderState roleId={roleId}>
          <ProviderUpdateState>
            <PageTopBox />

            <Flex width={'100%'}>
              <Box sx={{ width: '100%' }}>
                <ActionsContainer />

                <FiltersContainer />

                <TableRoles />
              </Box>
            </Flex>
          </ProviderUpdateState>
        </ProviderState>
      </Grid>
    </Grid>
  );
};
