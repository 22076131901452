import { HeaderWrap } from '../HeaderWrap';

import { LeftPart, RightPart } from './components';

export const HeaderSuperAdmin = () => {
  return (
    <HeaderWrap>
      <LeftPart />

      <RightPart />
    </HeaderWrap>
  );
};
