import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Flex, PlusIcon } from '@/separatedModules/components';
import { TextBlockControl } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ActionsContainer, Aside, FiltersContainer, ProviderState, ProviderUpdateState, TableWrap } from './components';

export const EquipmentItemFromTemplate: FC = () => {
  const { t: tEquipment } = useTranslation('equipment');
  const navigateTo = useNavigate();

  return (
    <Stack minHeight={'70vh'}>
      <Box sx={{ display: 'flex' }}>
        <TextBlockControl
          title={tEquipment('createEquipmentItem.step2.template.title')}
          subTitle={tEquipment('createEquipmentItem.step2.template.description')}
          sxBox={{ marginTop: '24px' }}
          sxSubTitle={{ marginBottom: 0, maxWidth: 660 }}
        />
        <Box>
          <Button
            size="small"
            sx={{ width: '180px' }}
            onClick={() => navigateTo(LINKS_PAGES.templatesEquipmentCreate)}
            startIcon={<PlusIcon />}
          >
            {tEquipment('createEquipmentItem.step2.template.createTemplateBtn')}
          </Button>
        </Box>
      </Box>
      <ProviderState>
        <ProviderUpdateState>
          <Flex>
            <Box width={'100%'}>
              <ActionsContainer />
              <FiltersContainer />
              <TableWrap />
            </Box>
            <Aside />
          </Flex>
        </ProviderUpdateState>
      </ProviderState>
    </Stack>
  );
};
