import Grid from '@mui/material/Grid';

import { Breadcrumbs, FroalaEditor } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiOther, titleFallback: 'Other components' }
];

export const UiTextEditorsPage = () => {
  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <Breadcrumbs className={'mb-10'} links={BreadcrumbsLinks} />
      </Grid>

      <Grid xs={12} item>
        <FroalaEditor />
      </Grid>
    </Grid>
  );
};
