export const Project = {
  root: {}
};

export const Base = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '300px',
    minHeight: '300px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    '&.MuiPreloader-full-screen': {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: '100',
      backgroundColor: '#fff !important'
    },
    '&.MuiPreloader-base': {
      '.MuiPreloader-spin': {
        position: 'relative',
        width: '100px',
        height: '100px',
        margin: '0 auto',
        borderRadius: '50%',
        border: '2px solid transparent',
        borderTopColor: '#3498DB',
        animation: 'spin 1s linear infinite',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          borderRadius: '50%',
          border: '2px solid transparent'
        },
        '&:before': {
          top: '5px',
          left: '5px',
          right: '5px',
          bottom: '5px',
          borderTopColor: '#e74c3c',
          animation: 'spin 1.25s linear infinite'
        },
        '&:after': {
          top: '15px',
          left: '15px',
          right: '15px',
          bottom: '15px',
          borderTopColor: '#f9c922',
          animation: 'spin 1.5s linear infinite'
        }
      }
    },
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    }
  }
};
