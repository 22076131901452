import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { GetCompanyQuery, GetProfileQuery } from '@/logicLayers/domain';

interface UserProfileI extends GetProfileQuery {}
interface UserCompanyI extends GetCompanyQuery {}

interface UserStateI extends UserProfileI, UserCompanyI {}

const initialState: UserStateI = {
  profile: {
    email: '',
    firstName: '',
    id: '',
    companyId: '',
    lastName: '',
    lastPasswordChangeDate: '',
    phoneNumber: '',
    createdDate: '',
    permissionTag: '',
    fileKey: '',
    permissions: [],
    profileAvatar: null,
    domains: []
  },

  company: {
    active: true,
    address: null,
    id: null,
    name: '',
    language: '',
    domains: [],
    contactInfo: {
      email: null,
      phoneNumber: null,
      representative: null
    },
    logo: null,
    users: {
      total: null,
      items: []
    }
  }
};

const userProfileSlice = createSlice({
  name: 'USER-PROFILE',
  initialState,
  reducers: {
    setUserProfile(state: UserStateI, action: PayloadAction<UserProfileI>) {
      state.profile = action.payload.profile;
    },
    setUserCompany(state: UserStateI, action: PayloadAction<UserCompanyI>) {
      state.company = action.payload.company;
    }
  }
});

export const UserProfileActions = userProfileSlice.actions;
export const UserProfileReducer = userProfileSlice.reducer;
