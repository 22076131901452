import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { SEARCH_DOMAINS, SearchDomainsQuery } from '@/logicLayers/domain';

import { AutocompleteControl, AutocompleteOptionT, ChipIcon, TextFieldControl } from '@/separatedModules/components';

import { TEXT_FIELD_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_EQUIPMENT_DOMAIN_ID, FIELD_EQUIPMENT_MODEL_NUMBER, FIELD_EQUIPMENT_TITLE } from '../../../../../helpers';

export const EquipmentItemNew: FC = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tEquipment } = useTranslation('equipment');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string
    }));
  }, [domainsData]);

  return (
    <Stack minHeight={'60vh'}>
      <Controller
        name={FIELD_EQUIPMENT_TITLE}
        control={control}
        rules={{
          required: {
            value: true,
            message: tGlobal('validation.required')
          },
          pattern: {
            value: TEXT_FIELD_REG_EXP,
            message: tGlobal('validation.textFieldLength')
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <TextFieldControl
              name={FIELD_EQUIPMENT_TITLE}
              title={tEquipment('createEquipmentItem.step2.new.nameTitle')}
              subTitle={tEquipment('createEquipmentItem.step2.new.nameSubTitle')}
              label={tEquipment('createEquipmentItem.step2.new.nameLabel')}
              placeholder={tEquipment('createEquipmentItem.step2.new.namePlaceholder')}
              value={value}
              validation={{
                isValid: !errors[FIELD_EQUIPMENT_TITLE]?.message,
                error: String(errors[FIELD_EQUIPMENT_TITLE]?.message)
              }}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />

      <Controller
        name={FIELD_EQUIPMENT_DOMAIN_ID}
        control={control}
        rules={{
          required: {
            value: true,
            message: tGlobal('validation.required')
          },
          validate: (value) => {
            const isMoreOneItem = value.length > 1;

            return isMoreOneItem ? tGlobal('validation.arrayFieldMaxLength', { value: 1 }) : true;
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <AutocompleteControl
              name={FIELD_EQUIPMENT_DOMAIN_ID}
              title={tEquipment('createEquipmentItem.step2.new.domainTitle')}
              subTitle={tEquipment('createEquipmentItem.step2.new.domainSubTitle')}
              label={tEquipment('createEquipmentItem.step2.new.domainLabel')}
              placeholder={tEquipment('createEquipmentItem.step2.new.domainPlaceholder')}
              options={domainsOptions}
              value={value}
              validation={{
                isValid: !errors[FIELD_EQUIPMENT_DOMAIN_ID]?.message,
                error: String(errors[FIELD_EQUIPMENT_DOMAIN_ID]?.message)
              }}
              renderTags={(tagValue, getTagProps) => {
                return (tagValue as AutocompleteOptionT[]).map((option, index) => {
                  const { ...tagProps } = getTagProps({ index });

                  return <Chip {...tagProps} key={option.key} label={option.label} deleteIcon={<ChipIcon />} />;
                });
              }}
              multiple
              disabled={Boolean(!domainsOptions.length)}
              onChange={(_, value) => {
                onChange(value);
              }}
              onBlur={onBlur}
            />
          );
        }}
      />

      <Controller
        name={FIELD_EQUIPMENT_MODEL_NUMBER}
        control={control}
        rules={{
          pattern: {
            value: TEXT_FIELD_REG_EXP,
            message: tGlobal('validation.textFieldLength')
          },
          minLength: {
            value: 3,
            message: tGlobal('validation.textFieldLength')
          },
          maxLength: {
            value: 64,
            message: tGlobal('validation.textFieldLength')
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <TextFieldControl
              name={FIELD_EQUIPMENT_MODEL_NUMBER}
              title={tEquipment('createEquipmentItem.step2.new.modelNumberTitle')}
              subTitle={tEquipment('createEquipmentItem.step2.new.modelNumberSubTitle')}
              label={tEquipment('createEquipmentItem.step2.new.modelNumberLabel')}
              placeholder={tEquipment('createEquipmentItem.step2.new.modelNumberPlaceholder')}
              value={value}
              validation={{
                isValid: !errors[FIELD_EQUIPMENT_MODEL_NUMBER]?.message,
                error: String(errors[FIELD_EQUIPMENT_MODEL_NUMBER]?.message)
              }}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />
    </Stack>
  );
};
