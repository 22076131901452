import { FC, memo, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { useTranslation } from '@/i18n';

import { TableDropdown } from '../TableDropdown';
import { TableMenuItem } from '../TableMenuItem';

import { TableDropdownProps, TableMenuOption } from '../../helpers';

interface CheckboxesDropdownProps extends Omit<TableDropdownProps, 'anchorEl' | 'onOpen' | 'onClose'> {
  withSelectAll?: boolean;
  defaultChecked?: boolean;
  menuList: TableMenuOption[];
  onChange: (value: string[]) => void;
  searchPlaceholder?: string;
  selectAllTitle?: string;
}

export const TableDropdownCheckbox: FC<CheckboxesDropdownProps> = memo(
  ({
    id = 'table-item-sort-checkbox',
    title,
    searchPlaceholder,
    menuList,
    // selectedDependencies,
    withSearch = false,
    withSelectAll = false,
    defaultChecked = false,
    disabled = false,
    selectAllTitle,
    onChange
  }) => {
    const { t: tGlobal } = useTranslation('global');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchFilter, setSearchFilter] = useState('');
    const [filterCount, setFilterCount] = useState(0);

    const [stagedSelectedItems, setStagedSelectedItems] = useState<TableMenuOption[]>([]);
    const [selectedItems, setSelectedItems] = useState<TableMenuOption[]>([]);

    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setSearchFilter('');
    };

    const handleItemClick = (option: TableMenuOption) => {
      setStagedSelectedItems((prev) =>
        prev.map((prevColumn) => {
          if (prevColumn.value === option.value) {
            return { ...prevColumn, checked: !option.checked };
          }

          return prevColumn;
        })
      );
    };

    const handleSelectAllClick = () => {
      setStagedSelectedItems((prev) => prev.map((prevColumn) => ({ ...prevColumn, checked: !areAllChecked })));
    };

    const handleApplyClick = () => {
      const finalSelectedItems = stagedSelectedItems.filter((option) => option.checked !== defaultChecked);

      onChange?.(finalSelectedItems.length === menuList.length ? [] : finalSelectedItems.map((i) => String(i.value)));

      handleMenuClose();

      setFilterCount(finalSelectedItems.length);
      setSelectedItems(stagedSelectedItems);
      setStagedSelectedItems(stagedSelectedItems);
    };

    const handleCancelClick = () => {
      handleMenuClose();
      setStagedSelectedItems(selectedItems);
    };

    const areAllChecked = useMemo(() => {
      return stagedSelectedItems.every((option) => option.checked);
    }, [stagedSelectedItems]);

    const filteredMenuList = useMemo(() => {
      return stagedSelectedItems.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
    }, [stagedSelectedItems, searchFilter]);

    useEffect(() => {
      const items = menuList.map((item) => ({ ...item, checked: defaultChecked }));

      setStagedSelectedItems(items);
      setSelectedItems(items);
    }, []);

    useEffect(() => {
      const finalSelectedItems = menuList.filter((option) => option.checked);

      setFilterCount(finalSelectedItems.length);
      setStagedSelectedItems(menuList);
      setSelectedItems(menuList);
    }, [menuList]);

    return (
      <TableDropdown
        id={id}
        title={title}
        counter={filterCount}
        withCounter
        searchPlaceholder={searchPlaceholder}
        searchValue={searchFilter}
        anchorEl={anchorEl}
        disabled={disabled}
        withSearch={withSearch}
        renderFooter={() => {
          return (
            <>
              <Button size={'small'} onClick={handleCancelClick}>
                {tGlobal('filterDropdown.btnCancel')}
              </Button>

              <Button size={'small'} variant={'contained'} onClick={handleApplyClick}>
                {tGlobal('filterDropdown.btnApply')}
              </Button>
            </>
          );
        }}
        onChangeSearch={setSearchFilter}
        onOpen={handleMenuOpen}
        onClose={handleCancelClick}
      >
        {withSelectAll ? (
          <>
            <TableMenuItem sx={{ padding: '4px 12px', height: '40px' }} onClick={handleSelectAllClick}>
              <Checkbox
                className={'m-0'}
                size={'small'}
                label={''}
                inputProps={{ 'aria-label': 'controlled' }}
                checked={areAllChecked}
              />

              <Text className={'m-0'} variant={'bodyMd'}>
                {selectAllTitle || tGlobal('filterDropdown.selectAll')}
              </Text>
            </TableMenuItem>

            <Box sx={{ background: 'var(--border-default, rgba(9, 14, 22, 0.15))', height: '1px', margin: '8px 0' }} />
          </>
        ) : null}

        <Box sx={{ height: 175, maxHeight: 175, overflow: 'auto' }}>
          {filteredMenuList.map((option) => (
            <TableMenuItem
              key={String(option.value)}
              sx={{ padding: '4px 12px', height: '40px' }}
              onClick={() => handleItemClick(option)}
            >
              <Checkbox
                className={'m-0'}
                size={'small'}
                label={''}
                inputProps={{ 'aria-label': 'controlled' }}
                checked={option.checked}
              />

              <Text
                className={'m-0'}
                variant={'bodyMd'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all',
                  maxWidth: '186px'
                }}
              >
                {option.label}
              </Text>
            </TableMenuItem>
          ))}
        </Box>
      </TableDropdown>
    );
  }
);
