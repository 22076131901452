import { FC } from 'react';
import { Button } from 'infinitytechnologies-ui';

import { PlusIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface AddDomainButtonProps {
  onOpenModal: () => void;
}

export const AddDomainButton: FC<AddDomainButtonProps> = ({ onOpenModal }) => {
  const { t: tTemplates } = useTranslation('templates');

  return (
    <>
      <Button variant={'contained'} startIcon={<PlusIcon />} disableElevation onClick={onOpenModal}>
        {tTemplates('domains.create.btnAdd')}
      </Button>
    </>
  );
};
