import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import { UserStatus } from '@/logicLayers/domain';

import { useTranslation } from '@/i18n';

interface UserActionsModalProps {
  open: boolean;
  canActivate: boolean;
  firstName: string;
  lastName: string;
  action: UserStatus | undefined;
  onClose: () => void;
  onConfirm: () => void;
}

export const UserActionsModal: FC<UserActionsModalProps> = ({
  open,
  canActivate,
  firstName,
  lastName,
  action,
  onClose,
  onConfirm
}) => {
  const { t: tUser } = useTranslation('user');

  const isDeleteAction = action === UserStatus.Deleted;
  const activateOrDeactivateTitleKey = canActivate ? 'activateTitle' : 'deactivateTitle';

  return (
    <Dialog
      open={open}
      headerTitle={tUser(`management.modal.${isDeleteAction ? 'deleteTitle' : activateOrDeactivateTitleKey}`)}
      hideHeaderBorder
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button className={'my-0'} variant={'text'} onClick={onClose}>
            {tUser('management.modal.btnCancel')}
          </Button>
          <Button className={'my-0'} variant={'contained'} onClick={onConfirm}>
            {tUser('management.modal.btnConfirm')}
          </Button>
        </div>
      }
      onClose={onClose}
    >
      <Text variant={'bodyLg'} sx={{ color: '#090E16', whiteSpace: 'pre-line' }}>
        {isDeleteAction ? (
          <Trans
            i18nKey={tUser('management.modal.deleteContent')}
            values={{ firstName: firstName.length ? firstName : '-', lastName: lastName }}
            components={{ span: <span style={{ fontWeight: 600 }} /> }}
          />
        ) : (
          <Trans
            i18nKey={tUser(`management.modal.${canActivate ? 'activateContent' : 'deactivateContent'}`)}
            values={{ firstName: firstName.length ? firstName : '-', lastName: lastName }}
            components={{ span: <span style={{ fontWeight: 600 }} /> }}
          />
        )}
      </Text>
    </Dialog>
  );
};
