import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Avatar, Button } from 'infinitytechnologies-ui';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { GET_USER_BY_ID, GetUserQuery } from '@/logicLayers/domain';

import {
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  Breadcrumbs,
  ChevronRightIcon,
  EditIcon,
  EMPTY_CELL_VALUE,
  PageTitle,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { getUserStatusColor, USER_ITEM_FIELDS } from './helpers';

export const UserPage = () => {
  const { t: tUser } = useTranslation('user');
  const { t: tGlobal } = useTranslation('global');
  const { userId = '' } = useParams();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });
  const navigateTo = useNavigate();

  const location = useLocation();
  const isShowVisibility = location.pathname.includes('/visibility');

  const { data: dataUser, loading: loadingUser } = useQuery<GetUserQuery>(GET_USER_BY_ID, {
    variables: { uid: userId }
  });

  const handleEditItem = (toPage: string) => {
    navigateTo({
      pathname: LINKS_PAGES.user + '/' + userId + '/edit',
      search: `field=${toPage}${isShowVisibility ? '&visibility=true' : ''}`
    });
  };

  const firstName = dataUser?.user.firstName ?? '';
  const lastName = dataUser?.user.lastName ?? '';
  const id = dataUser?.user.id ?? '';
  const status = dataUser?.user.status;
  const email = dataUser?.user.email;
  const phoneNumber = dataUser?.user.phoneNumber;
  const company = dataUser?.user.company;
  const createdDate = dataUser?.user.createdDate
    ? dayjs(dataUser?.user.createdDate).format('DD MMM YYYY, HH:mm A')
    : '-';

  const isDisabled = false;

  const name = firstName.length && lastName.length ? `${firstName}${lastName}` : EMPTY_CELL_VALUE;

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.users, titleFallback: tGlobal('header.menu.users') },
    { link: LINKS_PAGES.users, titleFallback: tUser('settings.usersBreadcrumbs') },
    { link: LINKS_PAGES.userItem.replace(':userId', id), titleFallback: name },
    { link: LINKS_PAGES.userItem.replace(':userId', id), titleFallback: tUser('settings.pageTitle') }
  ];

  return loadingUser ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} md={10} xl={8} item>
        <Breadcrumbs
          links={breadcrumbsLinks}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />
        <PageTitle title={tUser('settings.pageTitle')} subTitle={tUser('settings.pageSubTitle')} />

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={tUser('settings.mainInfo.title')}
            label={tUser('settings.mainInfo.photoLabel')}
            description={tUser('settings.mainInfo.photoDescription')}
            sxDescription={{ maxWidth: '520px' }}
            Edit={
              <>
                {firstName && lastName ? (
                  <Avatar size={'64'}>
                    {firstName.at(0)}
                    {lastName.at(0)}
                  </Avatar>
                ) : null}
              </>
            }
          />

          <BlockInfoWithEditItem
            label={tUser('settings.mainInfo.nameLabel')}
            description={!firstName || !lastName ? tUser('settings.mainInfo.nameDescription') : ''}
            descriptionSecond={firstName && lastName ? `${firstName} ${lastName}` : ''}
            Edit={
              <>
                {isUserCanCRUD && (
                  <IconButton onClick={() => handleEditItem(USER_ITEM_FIELDS.NAME)}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            }
          />

          <BlockInfoWithEditItem
            label={tUser('settings.mainInfo.statusLabel')}
            description={tUser(`overview.status.description.${status}`)}
            subLabel={
              <Chip
                sx={{ mb: '16px' }}
                variant={'filled'}
                size={'small'}
                color={getUserStatusColor(status)}
                label={tUser(`overview.status.type.${status}`)}
              />
            }
          />

          <BlockInfoWithEditItem label={tUser('settings.mainInfo.emailLabel')} descriptionSecond={email} />

          <BlockInfoWithEditItem
            label={tUser('settings.mainInfo.phoneLabel')}
            description={!phoneNumber ? tUser('settings.mainInfo.phoneDescription') : ''}
            descriptionSecond={phoneNumber ?? ''}
            Edit={
              <>
                {isUserCanCRUD && (
                  <IconButton onClick={() => handleEditItem(USER_ITEM_FIELDS.PHONE_NUMBER)}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            }
          />

          <BlockInfoWithEditItem
            label={tUser('settings.mainInfo.companyLabel')}
            descriptionSecond={company ? <p>{company.name}</p> : '-'}
          />
        </BlockInfoWithEdit>

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={tUser('settings.history.title')}
            label={tUser('settings.history.registrationLabel')}
            description={!createdDate && '-'}
            descriptionSecond={createdDate}
          />
        </BlockInfoWithEdit>

        <BlockInfoWithEdit disabled>
          <BlockInfoWithEditItem
            title={tUser('settings.userStatus.title')}
            label={isDisabled ? tUser('settings.userStatus.description') : tUser('settings.userStatus.description')}
            description={
              isDisabled
                ? tUser('settings.userStatus.descriptionIsVisible')
                : tUser('settings.userStatus.descriptionIsVisible')
            }
            Edit={
              <Button sx={{ margin: '0 !important', whiteSpace: 'nowrap' }} onClick={() => {}}>
                {isDisabled ? tUser('settings.btn.disabled') : tUser('settings.btn.enabled')}
              </Button>
            }
          />
        </BlockInfoWithEdit>
      </Grid>
    </Grid>
  );
};
