import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface StyledEquipmentItemPlateProps {
  selected: boolean;
}

export const StyledEquipmentItemPlate = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'selected'
})(({ selected }: StyledEquipmentItemPlateProps) => ({
  width: 'calc(50% - 12px)',
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid rgba(9, 14, 22, 0.15)',
  borderRadius: '8px',
  transition: '.25s ease',

  ...(selected && {
    background: '#F2F7FF',
    borderColor: '#388BFF'
  }),

  ':hover': {
    background: '#F2F7FF',
    cursor: 'pointer',
    borderColor: '#388BFF'
  }
}));
