import { gql as gqlApollo } from '@apollo/client';

export const GET_USERS = gqlApollo(`
  query GetUsers($searchCriteria: SearchCriteriaInput, $permissions: [String], $templateIds: [String]) {
    dataItems: users(searchCriteria: $searchCriteria, permissions: $permissions, templateIds: $templateIds) {
      items {
        createdDate
        email
        firstName
        id
        lastName
        phoneNumber
        permissionTag
        status
        userAvatar {
          fileKey
          url
        }
        company {
          name
          id
        }
        domains {
          color
          name
        }
      }
      total
    }
  }
`);

export const GET_USERS_WITH_ACCESS = gqlApollo(`
  query UsersWithAccess($resourceId: String!) {
    dataItems: usersWithAccess(resourceId: $resourceId) {
      user {
        id
        firstName
        lastName
        userAvatar {
          url
        }
      }
      accessType
    }
  }
`);

export const GET_ROUND_USERS = gqlApollo(`
  query GetUsers($searchCriteria: SearchCriteriaInput, $permissions: [String]) {
    dataItems: users(searchCriteria: $searchCriteria, permissions: $permissions) {
      items {
        id
        permissionTag
        firstName
        lastName
        email
        phoneNumber
        userAvatar {
          url
        }
        domains {
          color
          name
        }
      }
      total
    }
  }
`);

export const CREATE_REGION_RESOURCE_PERMISSION = gqlApollo`
  mutation CreateRegionResourcePermission($userIds: [String]!, $resourceId: String!, $access: AccessType!) {
    createRegionResourcePermission(request: { userIds: $userIds, resourceId: $resourceId, access: $access })
  }
`;

export const UPDATE_REGION_RESOURCE_PERMISSION = gqlApollo`
  mutation UpdateRegionResourcePermission($userId: String!, $resourceId: String!, $access: AccessType!) {
    updateRegionResourcePermission(request: { userId: $userId, resourceId: $resourceId, access: $access }) {
      id
      userId
      resourceId
      access
    }
  }
`;
