import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

import { EDIT_BUTTON_CLASS } from '../AddOrEditDomain/components';

export const StyledTableRow = styled(TableRow)`
  position: relative;

  :hover {
    .${EDIT_BUTTON_CLASS} {
      visibility: visible;
    }
  }
` as typeof TableRow;
