import { Text } from 'infinitytechnologies-ui';

import { Flex } from '@/separatedModules/components';

interface SelectedParametersValidationHeaderConditionsProps {
  title: string;
  value: string | undefined;
  isModal?: boolean;
}

export const SelectedParametersValidationHeaderConditions = ({
  title,
  value,
  isModal
}: SelectedParametersValidationHeaderConditionsProps) => {
  return (
    <Flex alignItems="center" sx={{ position: 'relative' }}>
      <Text sx={{ fontSize: '14px', fontWeight: '400', color: '#505668' }}>{title}</Text>
      <Text
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          position: 'absolute',
          left: isModal ? '23%' : '85%',
          width: isModal ? 'auto' : '100%'
        }}
      >
        {value}
      </Text>
    </Flex>
  );
};
