import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface PlateWrapperProps {
  isSelected?: boolean;
}

export const PlateWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isSelected'
})(({ isSelected }: PlateWrapperProps) => ({
  minHeight: '171px',
  background: isSelected ? '#F2F7FF' : '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  width: 'calc(50% - 12px)',
  border: `1px solid ${isSelected ? '#388BFF' : 'rgba(9, 14, 22, 0.15)'}`,
  borderRadius: '8px',
  transition: '0.25s ease',

  ':hover': {
    borderColor: '#388BFF',
    background: '#F2F7FF'
  }
}));

export const DetailsBtn = styled(Button)(() => ({
  background: 'transparent !important',
  margin: '0 !important',

  ':hover': {
    background: 'rgba(17, 24, 39, 0.05) !important'
  }
})) as typeof Button;

export const RoleCreationPlateWrapper = styled(PlateWrapper)(() => ({
  width: '100%',
  flexDirection: 'row',
  minHeight: '88px',
  height: '88px',
  alignItems: 'center',
  borderStyle: 'dashed',
  marginBottom: '24px',

  ':hover': {
    borderColor: '#388BFF',
    cursor: 'pointer'
  }
}));

export const StyledText = styled(Text)(() => ({
  margin: 0,
  fontSize: '12px',
  fontWeight: 400,
  color: '#505668',

  ':first-letter': {
    textTransform: 'uppercase'
  }
}));
