import { Controller, useFormContext } from 'react-hook-form';
import { Text } from 'infinitytechnologies-ui';

import { Grid, Stack } from '@mui/material';

import { TextFieldControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { FIELD_TASK_DESCRIPTION, FIELD_TASK_TITLE } from '../../../../../helpers';

export const TaskCustomStep2 = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTasks } = useTranslation('tasks');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Stack minHeight={'70vh'}>
      <Grid justifyContent={'center'} container row mb={'77px'}>
        <Grid xs={12} sm={10} md={8} lg={8} item>
          <Controller
            name={FIELD_TASK_TITLE}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: /^(?!\s)[\w\s-,#№]{3,100}(?<!\s)$/,
                message: tGlobal('validation.descriptionLength', {
                  value: 100
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  title={tTasks('createTask.step2.taskTitle.title')}
                  ContentAfterTitle={
                    <ul style={{ color: 'var(--text-subtle, #505668)' }}>
                      <li>
                        <Text className={'m-0'} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                          {tTasks('createTask.step2.taskTitle.subTitleList.item1')}
                        </Text>
                      </li>
                      <li>
                        <Text className={'m-0'} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                          {tTasks('createTask.step2.taskTitle.subTitleList.item2')}
                        </Text>
                      </li>
                    </ul>
                  }
                  label={tTasks('createTask.step2.taskTitle.titleLabel')}
                  placeholder={tTasks('createTask.step2.taskTitle.titlePlaceholder')}
                  name={FIELD_TASK_TITLE}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_TASK_TITLE]?.message,
                    error: errors[FIELD_TASK_TITLE]?.message as string
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name={FIELD_TASK_DESCRIPTION}
            defaultValue={''}
            control={control}
            rules={{
              pattern: {
                value: /^[\w\s`'.\-,#№()_]{3,400}$/,
                message: tGlobal('validation.textFieldMinMaxLength', {
                  valueMin: 3,
                  valueMax: 400
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  title={tTasks('createTask.step2.taskDescription.title')}
                  subTitle={tTasks('createTask.step2.taskDescription.subTitle')}
                  label={tTasks('createTask.step2.taskDescription.titleLabel')}
                  placeholder={tTasks('createTask.step2.taskDescription.titlePlaceholder')}
                  name={FIELD_TASK_DESCRIPTION}
                  rows={9}
                  maxContentLength={400}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_TASK_DESCRIPTION]?.message,
                    error: errors[FIELD_TASK_DESCRIPTION]?.message as string
                  }}
                  multiline
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
