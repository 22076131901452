import { User } from 'oidc-client-ts';

import { KEYCLOAK_ISSUER } from '@/config';

/*
 * When i must delete LocalStorage:
 * 1. When i get 401 error
 * 2. When i press logout on keycloak
 */
class AuthServiceClass {
  private handleLogOutCallback: () => void;
  // private handleLogInCallback: () => void;
  private handleSignOutSilent: () => void;

  constructor() {
    this.logOutCallBackFormOtherPages();
  }

  // ToDo it someday
  logOutCallBackFormOtherPages = () => {
    /*
    window.addEventListener('storage', (event) => {
       // const { newValue, oldValue, key } = event;
       // console.log('storage newValue, oldValue - ', newValue, oldValue);
       // const KEYCLOAK_CLIENT_ID = localStorage.getItem('client_id');
       // const StorageAuthKey = `oidc.user:${KEYCLOAK_ISSUER}:${KEYCLOAK_CLIENT_ID}`;
 
       // if (!StorageAuthKey) {
       // this.removeUserClientIdSecret();
       // }
 
       // if (key === StorageAuthKey && newValue === null && oldValue !== null) {
       // console.log('storage newValue - ', newValue);
       // console.log('storage key - ', key);
       // console.log('storage StorageAuthKey - ', StorageAuthKey);
 
       // this.handleSignOutSilent?.();
       // }
     });
     */
  };

  getAccessToken(): string | null {
    const KEYCLOAK_CLIENT_ID = localStorage.getItem('client_id');

    if (!KEYCLOAK_CLIENT_ID) {
      return null;
    }

    const oidcStorage = localStorage.getItem(`oidc.user:${KEYCLOAK_ISSUER}:${KEYCLOAK_CLIENT_ID}`);

    if (!oidcStorage) {
      return null;
    }

    return User.fromStorageString(oidcStorage).access_token;
  }

  saveHandleLogOut = (callback: () => void) => {
    this.handleLogOutCallback = callback;
  };

  handleLogOut = () => {
    this.handleLogOutCallback();
  };

  saveHandleSignOutSilent = (callback: () => void) => {
    this.handleSignOutSilent = callback;
  };
}

export const AuthService = new AuthServiceClass();
