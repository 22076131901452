import { Controller, useFormContext } from 'react-hook-form';
import { Text } from 'infinitytechnologies-ui';

import { Box, Grid, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { FIELD_TASK_START_DATE, maxDate, minDate } from '../../../../../helpers';

export const TaskCustomStep3 = () => {
  const { t: tTasks } = useTranslation('tasks');

  const { control } = useFormContext();

  return (
    <Stack minHeight={'70vh'}>
      <Grid justifyContent={'center'} container row mb={'77px'}>
        <Grid xs={12} sm={10} md={8} lg={7} item>
          <Controller
            name={FIELD_TASK_START_DATE}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <TextBlockControl
                    className={'control-autocomplete-tags__text-wrp'}
                    title={tTasks('createTask.step3.startDate.title')}
                    subTitle={tTasks('createTask.step3.startDate.subTitle')}
                    sxBox={{ width: '592px' }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        borderRadius: '4px',
                        background: 'var(--background-neutral-hovered, #F7F8F9)',
                        padding: '24px 16px',
                        marginTop: '24px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        width: '592px'
                      }}
                    >
                      <Box sx={{ display: 'inline-flex', justifyContent: 'space-around' }}>
                        <DatePicker
                          label={tTasks('createTask.step3.startDate.startDate')}
                          value={value?.date}
                          onChange={(newDate) => onChange({ date: newDate!, time: value.time })}
                          sx={{ width: '288px', marginTop: '16px', marginRight: '16px' }}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              helperText: 'MM/DD/YYYY'
                            }
                          }}
                        />
                        <TimePicker
                          label={tTasks('createTask.step3.startDate.startTime')}
                          value={value?.time}
                          onChange={(newTime) => onChange({ date: value.date, time: newTime! })}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                          sx={{
                            width: '288px'
                          }}
                          slotProps={{
                            textField: {
                              helperText: 'HH:MM'
                            }
                          }}
                        />
                      </Box>
                      <Text m={0} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)', marginTop: '20px' }}>
                        {value.date.isValid() &&
                          value.time.isValid() &&
                          tTasks('createTask.step3.startDate.activatedOn', {
                            date: value?.date?.format('dddd, D MMM YYYY'),
                            time: value?.time?.format('h:mm A')
                          })}
                      </Text>
                    </Box>
                  </LocalizationProvider>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
