import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ChevronLeftIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M10.4534 12.0001L14.5265 16.0732C14.6649 16.2117 14.7358 16.3857 14.739 16.5954C14.7422 16.805 14.6713 16.9822 14.5265 17.127C14.3816 17.2719 14.206 17.3444 13.9996 17.3444C13.7932 17.3444 13.6175 17.2719 13.4727 17.127L8.97843 12.6328C8.88483 12.5392 8.81881 12.4405 8.78036 12.3367C8.74189 12.2328 8.72266 12.1207 8.72266 12.0001C8.72266 11.8796 8.74189 11.7675 8.78036 11.6636C8.81881 11.5598 8.88483 11.4611 8.97843 11.3675L13.4727 6.87325C13.6111 6.73478 13.7851 6.66395 13.9948 6.66075C14.2044 6.65753 14.3816 6.72837 14.5265 6.87325C14.6713 7.01812 14.7438 7.19375 14.7438 7.40015C14.7438 7.60655 14.6713 7.78218 14.5265 7.92705L10.4534 12.0001Z" />
    </SvgIcon>
  );
}
