import { useSelector } from 'react-redux';

import { ReduxState } from '../../config';
import { useActions } from '../hooks';

export const useLocations = () => {
  // ToDo Refactor
  const { isStartMoving, progressList, destinationData, currentLocationFolderData } = useSelector(
    (state: ReduxState) => state.moveLocation
  );
  const {
    startMoving,
    cancelMoving,
    setDestinationData,
    removeLocationFromProgressList,
    setLocationToProgressListWithNextStep,
    setCurrentLocationFolderData
  } = useActions();

  return {
    isStartMoving,
    destinationData,
    startMoving,
    cancelMoving,
    setDestinationData,
    progressList,
    removeLocationFromProgressList,
    setLocationToProgressListWithNextStep,
    setCurrentLocationFolderData,
    currentLocationFolderData
  };
};
