import { Text } from 'infinitytechnologies-ui';

import { Box, Checkbox, SxProps } from '@mui/material';

import { Flex } from '@/separatedModules/components';

interface SelectedParametersListCheckboxProps {
  title: string;
  description: string;
  sx?: SxProps;
  onChange: () => void;
  value: boolean;
}

export const SelectedParametersListCheckbox = ({
  title,
  description,
  sx,
  onChange,
  value
}: SelectedParametersListCheckboxProps) => {
  return (
    <Flex alignItems="start" sx={sx}>
      <Checkbox
        sx={{
          transform: 'translateX(-10px) translateY(-11px)'
        }}
        checked={value}
        onChange={onChange}
      />
      <Box>
        <Text sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '18px', marginBottom: '8px' }}>{title}</Text>
        <Text sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '21px', color: 'var(--text-subtle, #505668)' }}>
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
