import { memo, MouseEvent, useEffect, useState } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { SortIcon, TableMenuOption } from '@/separatedModules/components';

import { i18n } from '@/i18n';

import { TableDropdown } from '../TableDropdown';
import { TableMenuItem } from '../TableMenuItem';

export const SORT_BY_DATE_CREATE = [
  {
    checked: false,
    label: i18n.t('table.filters.sortByDate.descending', { ns: 'global' }),
    key: i18n.t('table.filters.sortByDate.descending', { ns: 'global' }),
    value: 'DESC'
  },
  {
    checked: false,
    label: i18n.t('table.filters.sortByDate.ascending', { ns: 'global' }),
    key: i18n.t('table.filters.sortByDate.ascending', { ns: 'global' }),
    value: 'ASC'
  }
];

export const SORT_BY_ROUND_DATE_CREATE = [
  {
    checked: false,
    label: 'Start date (new to old)',
    key: 'activationTargetDate',
    value: 'DESC'
  },
  {
    checked: false,
    label: 'Start date (old to new)',
    key: 'activationTargetDate',
    value: 'ASC'
  },
  {
    checked: false,
    label: 'Creation date (new to old)',
    key: 'createdDate',
    value: 'DESC'
  },
  {
    checked: false,
    label: 'Creation date (old to new)',
    key: 'createdDate',
    value: 'ASC'
  }
];

type TableSortByOneItemProps = {
  id?: string;
  title?: string;
  menu: TableMenuOption[];
  disabled?: boolean;
  showIcon?: boolean;
  onChange: (value: string[]) => void;
};

export const TableSortByOneItem = memo(
  ({
    // Default value is SortByDate
    id = 'table-item-sort-by-date',
    // Default value is SortByDate
    title = i18n.t('table.filters.sortByDate.title', { ns: 'global' }),
    // Default value is SortByDate
    menu,
    showIcon = false,
    disabled = false,
    onChange
  }: TableSortByOneItemProps) => {
    const [selectedItems, setSelectedItems] = useState<TableMenuOption[]>(menu);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleItemClick = (option: TableMenuOption) => {
      setSelectedItems((prev) => {
        const arr = prev.map((prevColumn) => {
          if (prevColumn.value === option.value) {
            return { ...prevColumn, checked: !option.checked };
          } else {
            return { ...prevColumn, checked: false };
          }
        });

        const filtered = arr.filter((i) => {
          return i.checked;
        });
        const sendValue = filtered.length
          ? Array.isArray(filtered[0].value)
            ? filtered[0].value
            : [filtered[0].value]
          : [];

        // @ts-ignore
        onChange?.(sendValue);

        return arr;
      });
    };

    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      setSelectedItems(menu);
    }, [menu]);

    return (
      <TableDropdown
        id={id}
        title={title}
        anchorEl={anchorEl}
        btnIcon={showIcon && <SortIcon color={disabled ? 'disabled' : undefined} sx={{ margin: '0 4px 0 0' }} />}
        disabled={disabled}
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
      >
        {selectedItems.map((option) => {
          return (
            <TableMenuItem
              key={String(option.value)}
              sx={{
                justifyContent: 'space-between'
              }}
              checked={option.checked}
              onClick={() => handleItemClick(option)}
            >
              {option.icon ? option.icon : null}

              <Text variant={'bodyMd'} m={0}>
                {option.label}
              </Text>
            </TableMenuItem>
          );
        })}
      </TableDropdown>
    );
  }
);

interface TableMenuOptionType extends TableMenuOption {
  key: string;
}

interface TableSortByOneItemWithKeyLabelProps {
  id?: string;
  title?: string;
  menu: TableMenuOptionType[];
  showIcon?: boolean;
  disabled?: boolean;
  onChange?: (selected: { key: string; value: string }) => void;
}

export const TableSortByOneItemWithKeyLabel: React.FC<TableSortByOneItemWithKeyLabelProps> = memo(
  ({
    id = 'table-item-sort-by-date',
    title = i18n.t('table.filters.sortByDate.title', { ns: 'global' }),
    menu,
    showIcon = false,
    disabled = false,
    onChange
  }) => {
    const [selectedItems, setSelectedItems] = useState<TableMenuOptionType[]>(menu);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleItemClick = (option: TableMenuOptionType) => {
      setSelectedItems((prev) => {
        const arr = prev.map((prevColumn) => {
          if (prevColumn.label === option.label) {
            return { ...prevColumn, checked: !option.checked };
          } else {
            return { ...prevColumn, checked: false };
          }
        });

        const filtered = arr.filter((i) => i.checked);
        const sendValue = filtered.length ? { key: filtered[0].key, value: String(filtered[0].value) } : null;

        onChange?.(sendValue as { key: string; value: string });

        return arr;
      });
    };

    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      setSelectedItems(menu);
    }, [menu]);

    return (
      <TableDropdown
        id={id}
        title={title}
        anchorEl={anchorEl}
        btnIcon={showIcon && <SortIcon color={disabled ? 'disabled' : undefined} sx={{ margin: '0 4px 0 0' }} />}
        disabled={disabled}
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
      >
        {selectedItems.map((option) => (
          <TableMenuItem
            key={String(option.value)}
            sx={{ justifyContent: 'space-between' }}
            checked={option.checked}
            onClick={() => handleItemClick(option)}
          >
            {option.icon ? option.icon : null}

            <Text variant={'bodyMd'} m={0}>
              {option.label}
            </Text>
          </TableMenuItem>
        ))}
      </TableDropdown>
    );
  }
);
