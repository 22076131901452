import { useMemo } from 'react';

import { AlertCheckNoticeIcon, DoNotDisturbOnIcon, SelectsDropdown } from '@/separatedModules/components';

import { i18n, useTranslation } from '@/i18n';

import { useContextUpdateState } from '../../../Providers';

const createStatuses = () => {
  return [
    {
      checked: false,
      label: i18n.t('equipment.table.statuses.enabled', { ns: 'templates' }),
      key: i18n.t('equipment.table.statuses.enabled', { ns: 'templates' }),
      value: true,
      icon: <AlertCheckNoticeIcon sx={{ fontSize: 20 }} />
    },
    {
      checked: false,
      label: i18n.t('equipment.table.statuses.disabled', { ns: 'templates' }),
      key: i18n.t('equipment.table.statuses.disabled', { ns: 'templates' }),
      value: false,
      icon: <DoNotDisturbOnIcon />
    }
  ];
};

export const SortByStatus = () => {
  const { t: tCompanies } = useTranslation('companies');

  const { handleChangeSortByStatus } = useContextUpdateState();

  const menuList = useMemo(() => createStatuses(), []);

  return (
    <SelectsDropdown
      id={'equipment-items-sort-by-status'}
      title={tCompanies('table.filters.sortByStatus')}
      menuList={menuList}
      onChange={handleChangeSortByStatus}
    />
  );
};
