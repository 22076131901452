import { ImageUploaderI } from '@/separatedModules/components/FilesUploader/SingleImageUploader/helpers';

import { i18n } from '@/i18n'; // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const EMAIL_REG_EXP: RegExp =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]{2,}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+)+$/;

// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
export const PASS_REG_EXP: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,64}$/;
export const FIRST_NAME_REG_EXP: RegExp = /^[A-Za-z`'-]{3,64}$/;

export const LAST_NAME_REG_EXP: RegExp = FIRST_NAME_REG_EXP;
export const TEXT_FIELD_REG_EXP: RegExp = /^(?!\s)[\w\s-,#№]+(?<!\s)$/;
export const COMPANY_NAME_REG_EXP: RegExp = /^(?!\s)[\w\s-,#№]+(?<!\s)$/;
export const ROLE_NAME_REG_EXP: RegExp = /^(?!\s)[\w\s-,#№]{3,64}(?<!\s)$/;
export const ROLE_DESCRIPTION_REG_EXP: RegExp = /^(?!\s)[\w\s-,#№]{3,100}(?<!\s)$/;
export const PARAMETER_TITLE_REG_EXP: RegExp = /^(?! )[\w!@#$%^&*()\-_=+{}\[\]|;:'",<.>/?\\ ]{1,64}$/;
// export const PARAMETER_VALUE_REG_EXP: RegExp = /^\d+(\.\d+){0,63}$/;
export const PARAMETER_VALUE_REG_EXP: RegExp = /^\d+([.,/]\d{1,2})*$/;
export const ADDRESS_NAME_REG_EXP: RegExp = /^(?!\s)[\w\s-,#№]+(?<!\s)$/;
export const COMPANY_REPRESENTATIVE_REG_EXP: RegExp = /^(?!\s)[\w\s]+(?<!\s)$/;

export const USER_NUMBER_MASK: Record<string, any> = {
  definitions: {
    '#': /[1-9]/
  },
  en: {
    pattern: '+1 (000) 000-0000',
    patternHookForm: /^\+\d\s\(\d{3}\)\s\d{3}-\d{4}$/
  }
};

/* Validation messages */
const IS_VALID = { isValid: true, error: '' };

/* End Validation messages */

interface ValidateImageUploaderI extends Omit<ImageUploaderI, 'allowedExtensionsText'> {
  width: number;
  height: number;
  value: File;
}

export const validateUploadImage = ({
  value,
  minDimensionsText,
  maxFileSizeText,
  width,
  height,
  imageMinWidth,
  imageMinHeight,
  imageMaxSize,
  imageAllowedExtensions,
  imageRequired = true
}: ValidateImageUploaderI) => {
  const FILE_TYPE_REG_EXP = /[^.]+$/;

  const IS_REQUIRED = { isValid: false, error: i18n.t('validation.required', { ns: 'global' }) };

  const IS_INVALID_SIZE = {
    isValid: false,
    error: i18n.t('imageUploader.validation.maxSize', { ns: 'global', value: maxFileSizeText })
  };

  const IS_INVALID_MIN_DIMENSIONS_WIDTH_HEIGHT = {
    isValid: false,
    error: i18n.t('imageUploader.validation.dimensionsMin', { ns: 'global', value: minDimensionsText })
  };

  const isInvalidTypeMsg =
    imageAllowedExtensions.length > 1 ? imageAllowedExtensions?.join(', ') : imageAllowedExtensions[0];
  const IS_INVALID_TYPE = {
    isValid: false,
    error: i18n.t('validation.imageValid', { ns: 'global', value: isInvalidTypeMsg })
  };

  if (!imageRequired) return IS_VALID;
  else {
    if (value) {
      const fileResultRegExp = FILE_TYPE_REG_EXP.exec(value.name);

      if (fileResultRegExp && imageAllowedExtensions.includes(fileResultRegExp[0])) {
        if ((width && width < imageMinWidth) || (height && height < imageMinHeight)) {
          return IS_INVALID_MIN_DIMENSIONS_WIDTH_HEIGHT;
        } else if (value.size > imageMaxSize) {
          return IS_INVALID_SIZE;
        } else {
          return IS_VALID;
        }
      } else {
        return IS_INVALID_TYPE;
      }
    } else {
      return IS_REQUIRED;
    }
  }
};
