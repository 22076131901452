import { FC } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { TableHead as MuiTableHead } from '@mui/material';

import { TableColumn } from './table.config';

import { StyledTableCell, StyledTableHeaderRow } from './style';

interface TableHeadProps {
  columns: TableColumn[];
}

export const TableHead: FC<TableHeadProps> = ({ columns }) => (
  <MuiTableHead>
    <StyledTableHeaderRow>
      {columns.map((column) => (
        <StyledTableCell key={column?.title} align={column?.align ?? 'inherit'} hide={!!column.visible}>
          <Text variant={'labelMd'} marginBottom={0}>
            {column?.title}
          </Text>
        </StyledTableCell>
      ))}
    </StyledTableHeaderRow>
  </MuiTableHead>
);
