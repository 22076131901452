import { LocationFolderRes, Maybe } from '@/logicLayers/domain';

export const getBreadcrumbs = (location?: Maybe<LocationFolderRes>) => {
  const breadcrumbParts = location?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  breadcrumbParts.push(location?.name);

  const breadcrumbsLinks = breadcrumbParts
    .filter((item: string | undefined) => !!item)
    .map((item: string) => ({
      titleFallback: item
    }));

  if (breadcrumbsLinks.length > 2) {
    breadcrumbsLinks.splice(1, breadcrumbsLinks.length - 2, { titleFallback: '...' });
  }

  return breadcrumbsLinks;
};
