import { Text } from 'infinitytechnologies-ui';

import { Box, IconButton } from '@mui/material';

import { ParameterTypeBox } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter/components/ParameterTypeBox';
import { PARAMETER_TYPE } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter/helpers';
import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components';

import { CloseIcon, Flex, ImageLazy } from '@/separatedModules/components';

import { SelectedParametersListProps } from '../../SelectedParametersList';

interface SelectedParametersHeaderProps extends SelectedParametersListProps {
  handleOpenModal: () => void;
}

export const SelectedParametersHeader = ({ parameter, handleOpenModal }: SelectedParametersHeaderProps) => {
  const paramItemConfig = PARAMETERS_LIST_FULL.find((paramItem) => parameter.type === paramItem.parameterType);

  const isNumeric = parameter.type.includes('NUMERIC');

  const description = isNumeric ? parameter.value : parameter.description;

  return (
    <Flex justifyContent="space-between">
      <Flex>
        {paramItemConfig?.logoPath ? (
          <ImageLazy
            src={paramItemConfig?.logoPath}
            alt={`Equipment item parameter ${paramItemConfig.title} type preview`}
            variant={'rectangular'}
            width={48}
            height={48}
            sxStyle={{
              margin: '0 8px 0 0',
              display: 'flex',
              alignItems: 'center'
            }}
          />
        ) : null}
        <Box sx={{ marginLeft: '8px' }}>
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '18px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              width: isNumeric ? '259px' : '300px'
            }}
          >
            {parameter?.title}
          </Text>
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              marginTop: '8px',
              color: 'var(--text-subtle, #505668)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              width: isNumeric ? '259px' : '300px'
            }}
          >
            {description}
          </Text>
        </Box>
      </Flex>
      <Flex>
        <ParameterTypeBox title={isNumeric ? 'Numeric data' : PARAMETER_TYPE[parameter.type as any]} />

        {isNumeric ? <ParameterTypeBox title={PARAMETER_TYPE[parameter.type as any]} /> : null}
        <IconButton size={'small'} sx={{ marginLeft: '16px' }} onClick={handleOpenModal}>
          <CloseIcon />
        </IconButton>
      </Flex>
    </Flex>
  );
};
