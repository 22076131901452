import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  MAX_GUIDE_LENGTH,
  MIN_GUIDE_LENGTH
} from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps/Step2/components';

import { TextFieldControl } from '@/separatedModules/components';

import { ROUND_DESCRIPTION } from '../../../../helpers';

export const RoundDescription = () => {
  const { t: tGlobal } = useTranslation('global');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={ROUND_DESCRIPTION}
      defaultValue={''}
      control={control}
      rules={{
        required: {
          value: true,
          message: tGlobal('validation.required')
        },
        pattern: {
          value: /^[\w\s`'.\-,#№()_]{3,400}$/,
          message: tGlobal('validation.textFieldMinMaxLength', {
            valueMin: MIN_GUIDE_LENGTH,
            valueMax: MAX_GUIDE_LENGTH
          })
        }
      }}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <TextFieldControl
            title={'Description'}
            subTitle={
              'Provide a clear and concise description of the round regulation. Clearly communicate the objectives and expectations to the service staff'
            }
            label={'Description'}
            placeholder={'Description'}
            name={ROUND_DESCRIPTION}
            rows={9}
            maxContentLength={400}
            value={value}
            sxTitle={{ marginTop: '48px' }}
            validation={{
              isValid: !errors[ROUND_DESCRIPTION]?.message,
              error: errors[ROUND_DESCRIPTION]?.message as string
            }}
            multiline
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};
