import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Text } from 'infinitytechnologies-ui';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';

import {
  EquipmentQuery,
  GET_EQUIPMENT,
  GET_ROLE_TEMPLATES,
  GetRoleTemplatesQuery,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import {
  AutocompleteOptionT,
  Flex,
  InfoIcon,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  TableAside,
  TableDropdownCheckbox,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap
} from '@/separatedModules/components';

import { UsersAsideContent } from './components';

import { ROUND_USERS } from '../../helpers';
import { columns, initState, skeletonTableLoaderCells, useTableData } from './helpers';

export const Step2 = () => {
  const { templateId = '' } = useParams();

  const { profile } = useReduxSelector(selectUserData);

  const companyId = profile.companyId;
  const { renderCount, renderItems, ...tableState } = useTableData(initState, companyId);
  const navigateTo = useNavigate();

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });

  const equipmentDomainName = dataEquipmentTemplate?.equipment.domain.name;

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const { data: dataRoleTemplates } = useQuery<GetRoleTemplatesQuery>(GET_ROLE_TEMPLATES, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const rolesOptions = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return dataRoleTemplates?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.name as string,
      key: domain?.id as string,
      checked: false,
      disabled: false
    }));
  }, [dataRoleTemplates]);

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string,
      checked: false,
      disabled: false
    }));
  }, [domainsData]);

  const { watch, setValue } = useFormContext();

  const selectedUsers = watch(ROUND_USERS);

  return (
    <Grid justifyContent={'center'} container row sx={{ marginTop: '48px' }}>
      <Grid xs={12} item>
        <TablePageTopBox title={'Select service staff (optional)'} isStep>
          <></>
        </TablePageTopBox>
        <Text
          sx={{
            maxWidth: '664px',
            fontSize: '14px',
            color: 'var(--text-subtle, #505668)',
            fontWeight: '400',
            marginBottom: '24px'
          }}
        >
          Select the service staff (individual or group) tasked with conducting equipment inspection rounds. Choose a
          user who has access to the domain corresponding to the current equipment template domain
        </Text>

        {Boolean(!tableState.state.selectedItems.length) && (
          <TableFiltersBox
            searchPlaceholder={'Search by User name'}
            selectedItems={tableState.state.selectedItems}
            handleToggleAside={tableState.handleToggleAside}
            handleChangeBySearch={tableState.handleChangeBySearch}
          >
            <TableDropdownCheckbox
              // ToDo Refactor
              title={'Domains'}
              menuList={domainsOptions as any}
              withSelectAll
              withSearch
              searchPlaceholder="Search domains"
              selectAllTitle="Show all"
              onChange={(value) => {
                tableState.handleChangeByDomains(value);
              }}
            />

            <TableDropdownCheckbox
              // ToDo Refactor
              title={'Role'}
              menuList={rolesOptions as any}
              withSelectAll
              withSearch
              searchPlaceholder="Search role"
              selectAllTitle="Show all"
              onChange={(value) => {
                tableState.handleChangeByRoles(value);
              }}
            />
          </TableFiltersBox>
        )}
        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : renderCount || tableState.state.filters?.search.length ? (
          renderItems ? (
            <Flex>
              <Box width={'100%'}>
                <Box sx={{ overflow: 'auto', maxHeight: '400px' }}>
                  <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                    {Boolean(tableState.state.selectedItems.length) && (
                      <Flex
                        sx={{
                          width: '100%',
                          height: '32px',
                          background: 'var(--background-neutral-pressed, #F1F2F4)',
                          borderRadius: '4px',
                          padding: '0px 4px',
                          marginBottom: '16px'
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex alignItems="center">
                          <IconButton
                            size={'medium'}
                            className={'aside-table__btn__close'}
                            onClick={() => {
                              setValue(ROUND_USERS, []);
                              tableState.handleClearSelectedItems();
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <Text sx={{ fontSize: '14px', marginLeft: '16px' }}>
                            {tableState.state.selectedItems.length} selected
                          </Text>
                        </Flex>
                        <IconButton
                          size={'medium'}
                          sx={{ borderRadius: '100%' }}
                          onClick={tableState.handleToggleAside}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Flex>
                    )}
                    <Table className={'m-0 MuiTable-root__columns-3'}>
                      <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <TableCell key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {table.getRowModel().rows.map((row) => {
                          const isHidden = Boolean(
                            row.original.domains.filter((domain) => domain?.name === equipmentDomainName).length
                          );

                          return (
                            <TableRowWrap
                              key={row.original.id}
                              id={row.original.id as string}
                              isSelected={selectedUsers.includes(row.original.id as string)}
                              handleClickRow={(userId) => {
                                const inSelectedExist = selectedUsers.includes(userId);

                                const user = theData.find((user) => user?.id === userId);
                                const isNotHidden = Boolean(
                                  user?.domains?.filter((domain) => domain?.name === equipmentDomainName).length
                                );

                                const updatedSelectedItems = inSelectedExist
                                  ? selectedUsers.filter((selectedUserId: string) => selectedUserId !== userId)
                                  : isNotHidden
                                    ? [userId, ...selectedUsers]
                                    : selectedUsers;

                                setValue(ROUND_USERS, updatedSelectedItems);

                                tableState.handleClickRow(userId);
                              }}
                              handleDoubleClickRow={tableState.handleDoubleClickRow}
                              style={{ opacity: !isHidden ? '0.5' : 1 }}
                            >
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </TableCell>
                                );
                              })}
                            </TableRowWrap>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <MuiTablePagination
                  count={renderCount}
                  page={tableState.state.page[0]}
                  rowsPerPage={tableState.state.rowsPerPage[0]}
                  rowsPerPageOptions={[20, 50, 100]}
                  onChangePage={tableState.handleChangePagination}
                  onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                />
              </Box>
              <TableAside
                rows={table.getRowModel().rows}
                selectedItems={tableState.state.selectedItems}
                isAsideOpen={tableState.state.isAsideOpen}
                handleToggleAside={tableState.handleToggleAside}
                sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {(props: any) => {
                  const isHidden = Boolean(
                    props.domains.filter((domain: { name: string | undefined }) => domain?.name === equipmentDomainName)
                      .length
                  );

                  return (
                    <UsersAsideContent
                      {...props}
                      isHidden={isHidden}
                      handleChangeTest={() => {
                        navigateTo(`/user/${tableState.state.selectedItems[0]}`);
                      }}
                    />
                  );
                }}
              </TableAside>
            </Flex>
          ) : (
            <Box sx={{ height: '59vh' }}>
              <NotFoundScreens type={'search'} />
            </Box>
          )
        ) : (
          <NotFoundScreens
            type={'companies'}
            title={'No round users yet'}
            subTitle={'Click “Create regulation" to add round regulation parameter'}
          />
        )}
      </Grid>
    </Grid>
  );
};
