import { memo } from 'react';

import Box from '@mui/material/Box';

import { ImageLazy, PhotoCameraIcon } from '@/separatedModules/components';

import { RenderUploaderButtonProps } from '../../helpers';

interface RenderUploaderButtonSmProps extends RenderUploaderButtonProps {}

export const UploaderButtonSm = memo(({ imgAlt, oldImage, handleOpenModalPhoto }: RenderUploaderButtonSmProps) => {
  return (
    <Box
      sx={{
        flex: '0 0 64px',
        width: '64px',
        height: '64px',
        cursor: 'pointer',
        borderRadius: '50%',
        background: 'var(--background-neutral-subtle-hovered, rgba(9, 14, 22, 0.06))',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        img: {
          height: '100%'
        }
      }}
      onClick={handleOpenModalPhoto}
    >
      {oldImage ? (
        <ImageLazy width={64} height={64} src={oldImage} alt={imgAlt} />
      ) : (
        <PhotoCameraIcon sx={{ fontSize: 36 }} />
      )}
    </Box>
  );
});
