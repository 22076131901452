import { MouseEvent, ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { TranslationT } from '@/subsidiaryBinders/types';

import { i18n } from '@/i18n';

export const EMPTY_CELL_VALUE = '—';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT) => ReactNode;
}

export interface ConfigTableI<Model = Record<string, any>> {
  cells: TableCellI<Model>[];
  pagination: {
    page: number[];
    rowsPerPage: number[];
    rowsPerPageOptions: number[];
  };
}

export const checkTablePagination = (count: number = 0, paginationRowsPerPage: number[]): any => {
  const isVisiblePagination = count > paginationRowsPerPage[0];

  if (isVisiblePagination) {
    if (count < paginationRowsPerPage[1] && paginationRowsPerPage.length > 2) {
      paginationRowsPerPage.pop();
      return {
        isVisiblePagination: true,
        rowsPerPageOptions: paginationRowsPerPage
      };
    } else {
      return {
        isVisiblePagination: true,
        rowsPerPageOptions: paginationRowsPerPage
      };
    }
  }

  return {
    isVisiblePagination: false,
    rowsPerPageOptions: paginationRowsPerPage
  };
};

/*
export type DropdownProps = Omit<TableDropdownProps, 'children' | 'anchorEl' | 'onDropdownClick' | 'onClose'> & {
  onChange: (options: MenuOption[]) => void;
};
*/

/* Done New Version */
type MenuOptionNew<T = string | number> = {
  label: string;
  value: T;
  icon?: ReactNode;
};

/* Done New Version */
export type TableMenuOption = MenuOptionNew & {
  checked: boolean;
  component?: ReactNode;
  onToggleVisibility?: (value?: boolean) => void;
  onChange?: (event: unknown) => void;
};

/* Done New Version */
export type TableDropdownProps = {
  id?: string;
  counter?: number;
  searchPlaceholder?: string;
  searchValue?: string;
  disabled?: boolean;
  withSearch?: boolean;
  withCounter?: boolean;
  anchorEl: null | HTMLElement;
  title: ReactNode;
  children?: ReactNode;
  renderFooter?: () => ReactNode | null;
  btnIcon?: ReactNode;
  onChangeSearch?: (value: string) => void;
  onOpen: (event: MouseEvent<HTMLElement>) => void;
  onClose: () => void;
};

/* Done New Version */
export const handleSetSavedColumns = (name: string, hiddenColumns: Record<any, any>) => {
  hiddenColumns && localStorage.setItem(name, JSON.stringify(hiddenColumns));
};

/* Done New Version */
export const handleGetSavedColumns = (name: string) => {
  const data = localStorage.getItem(name);

  return data ? JSON.parse(data) : {};
};

/* Done New Version */
export const createHeaderCellItem = (columnId: string) => {
  return (
    <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
      {i18n.t(`table.cells.${columnId}`, { ns: 'global' })}
    </Text>
  );
};
