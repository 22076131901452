import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function RenameIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M2.91648 20C2.57568 20 2.28214 19.8782 2.03588 19.6346C1.78963 19.391 1.6665 19.0962 1.6665 18.75C1.6665 18.4092 1.78963 18.1157 2.03588 17.8694C2.28214 17.6232 2.57568 17.5001 2.91648 17.5001H17.0832C17.424 17.5001 17.7175 17.6219 17.9638 17.8654C18.21 18.109 18.3332 18.4039 18.3332 18.75C18.3332 19.0908 18.21 19.3844 17.9638 19.6306C17.7175 19.8769 17.424 20 17.0832 20H2.91648ZM4.99984 13.6779H6.03027L12.947 6.77405L12.4229 6.24198L11.9037 5.73078L4.99984 12.6475V13.6779ZM3.74986 14.1747V12.4295C3.74986 12.3291 3.76668 12.2335 3.80034 12.1427C3.834 12.0519 3.88983 11.9675 3.96782 11.8895L13.0912 2.7869C13.2119 2.66618 13.3489 2.57484 13.5023 2.51288C13.6556 2.45091 13.814 2.41992 13.9774 2.41992C14.1462 2.41992 14.3067 2.45091 14.459 2.51288C14.6112 2.57484 14.752 2.67046 14.8812 2.79973L15.8828 3.81415C16.0121 3.93487 16.1056 4.07269 16.1633 4.22759C16.221 4.38251 16.2498 4.54436 16.2498 4.71315C16.2498 4.86807 16.221 5.02218 16.1633 5.17548C16.1056 5.32879 16.0121 5.47008 15.8828 5.59936L6.78025 14.7019C6.70227 14.7799 6.61787 14.8371 6.52707 14.8734C6.43625 14.9097 6.34062 14.9279 6.24019 14.9279H4.50307C4.28833 14.9279 4.10912 14.856 3.96542 14.7123C3.82171 14.5686 3.74986 14.3894 3.74986 14.1747ZM12.947 6.77405L12.4229 6.24198L11.9037 5.73078L12.947 6.77405Z" />
    </SvgIcon>
  );
}
