import { ReactNode, useState } from 'react';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';

import { EditIcon } from '@/separatedModules/components';

type EditItemHoverProps = {
  children: ReactNode;
  sxBox?: SxProps<Theme>;
  sxBoxWrap?: SxProps<Theme>;
  isDisabled?: boolean;
  onClick?: () => void;
};

const boxPropsMain = {
  transition: 'all .2s ease-in-out',
  borderRadius: 'var(--2, 2px)',
  padding: '4px',
  marginLeft: '-4px',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  '.content-part': {
    transition: 'all .2s ease-in-out',
    opacity: '0',
    alignSelf: 'flex-start',
    margin: '0 0 0 8px'
  }
};

const boxPropsHover = {
  background: 'var(--background-neutral-pressed, #F1F2F4)',
  '.content-part': {
    ...boxPropsMain['.content-part'],
    opacity: '1'
  }
};

export const EditItemHover = ({ children, sxBoxWrap, sxBox, isDisabled, onClick }: EditItemHoverProps) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleEdit = () => {
    onClick?.();
  };

  const boxProps = {
    ...boxPropsMain,
    ...(isHover ? boxPropsHover : {}),
    ...sxBox
  };

  return (
    <Box sx={sxBoxWrap}>
      <Box
        className={classNames({
          'Mui-disabled': isDisabled
        })}
        sx={boxProps}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}

        <Box className={'content-part'}>
          <IconButton size={'small'} onClick={handleEdit}>
            <EditIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
