import { GetCompanyQuery, updateCompanyModel } from '@/logicLayers/domain';

import { i18nT, TranslationT } from '@/subsidiaryBinders/types';

export interface GetEditComponentProps extends GetCompanyQuery {
  navigateToUrl: string;
  editType: string;
  tGlobal: TranslationT;
  tCompany: TranslationT;
  i18n: i18nT;
}

export const onSubmitFormCompanyName = (data: GetCompanyQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { name } = getValues();

    updateModel({ variables: { id: data.company.id, ...updateCompanyModel(data, { name }) } });
  };
};

export const onSubmitFormCompanyAddress = (data: GetCompanyQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { address } = getValues();

    updateModel({ variables: { id: data.company.id, ...updateCompanyModel(data, { address }) } });
  };
};

export const onSubmitFormCompanyRepresentative = (data: GetCompanyQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { representative } = getValues();

    updateModel({
      variables: { id: data.company.id, ...updateCompanyModel(data, { contactInfo: { representative } }) }
    });
  };
};

export const onSubmitFormCompanyPhoneNumber = (data: GetCompanyQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { phoneNumber } = getValues();

    updateModel({
      variables: { id: data.company.id, ...updateCompanyModel(data, { contactInfo: { phoneNumber } }) }
    });
  };
};

export const onSubmitFormCompanyEmail = (data: GetCompanyQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { email } = getValues();

    updateModel({
      variables: { id: data.company.id, ...updateCompanyModel(data, { contactInfo: { email } }) }
    });
  };
};
