import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function IdeaIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'200'} height={'200'} viewBox={'0 0 200 200'} fill={'none'} {...props}>
      <g clipPath="url(#clip0_25_12978)">
        <path
          d="M123.438 169.18V182.422C123.438 192.148 115.586 200 105.859 200H94.1406C84.4137 200 76.5625 192.148 76.5625 182.422V169.18C76.5625 165.898 79.1402 162.93 82.4219 162.93H117.578C120.86 162.93 123.438 165.898 123.438 169.18Z"
          fill="#FDBA74"
        />
        <path
          d="M123.438 169.18V182.422C123.438 192.148 115.586 200 105.859 200H100V162.93H117.578C120.86 162.93 123.438 165.898 123.438 169.18Z"
          fill="#FB923C"
        />
        <path
          d="M99.9993 47.2656C95.7806 47.2656 91.5622 47.734 87.3427 48.7891C68.5931 53.125 53.4763 68.0074 48.9056 86.6406C44.3353 105.39 49.6087 124.375 63.0849 137.617C67.8899 142.422 70.7024 149.57 70.7024 157.305V158.594C70.7024 168.32 78.5536 176.562 88.2806 176.562H111.718C121.445 176.562 129.296 168.32 129.296 158.594V157.305C129.296 149.687 132.226 142.304 137.382 137.265C147.226 127.304 152.734 114.062 152.734 100C152.734 70.4684 128.828 47.2656 99.9993 47.2656Z"
          fill="#FDE68A"
        />
        <path
          d="M152.734 100C152.734 114.062 147.227 127.304 137.383 137.266C132.227 142.305 129.297 149.688 129.297 157.305V158.594C129.297 168.32 121.446 176.562 111.719 176.562H100V47.2656C128.829 47.2656 152.734 70.4684 152.734 100Z"
          fill="#FCD34D"
        />
        <path
          d="M46.1392 54.4237L29.5681 37.8526C27.2794 35.564 27.2794 31.8558 29.5681 29.5671C31.8567 27.2784 35.5649 27.2784 37.8536 29.5671L54.4247 46.1382C56.7134 48.4269 56.7134 52.1351 54.4247 54.4237C52.136 56.7128 48.4282 56.7128 46.1392 54.4237Z"
          fill="#FDBA74"
        />
        <path
          d="M29.6875 105.859H5.85938C2.6207 105.859 0 103.239 0 100C0 96.7613 2.6207 94.1406 5.85938 94.1406H29.6875C32.9262 94.1406 35.5469 96.7613 35.5469 100C35.5469 103.239 32.9262 105.859 29.6875 105.859Z"
          fill="#FDBA74"
        />
        <path
          d="M194.141 105.859H170.312C167.074 105.859 164.453 103.239 164.453 100C164.453 96.7613 167.074 94.1406 170.312 94.1406H194.141C197.379 94.1406 200 96.7613 200 100C200 103.239 197.379 105.859 194.141 105.859Z"
          fill="#FB923C"
        />
        <path
          d="M145.576 54.4237C143.287 52.1351 143.287 48.4269 145.576 46.1382L162.147 29.5671C164.436 27.2784 168.144 27.2784 170.433 29.5671C172.721 31.8558 172.721 35.564 170.433 37.8526L153.861 54.4237C151.572 56.7128 147.865 56.7128 145.576 54.4237Z"
          fill="#FB923C"
        />
        <path
          d="M105.859 5.85938V29.6875C105.859 32.9684 103.282 35.5469 100 35.5469C96.7184 35.5469 94.1406 32.9684 94.1406 29.6875V5.85938C94.1406 2.57773 96.7184 0 100 0C103.282 0 105.859 2.57773 105.859 5.85938Z"
          fill="#FDBA74"
        />
        <path
          d="M105.859 5.85938V29.6875C105.859 32.9684 103.282 35.5469 100 35.5469V0C103.282 0 105.859 2.57773 105.859 5.85938Z"
          fill="#FB923C"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_12978">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
