import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import dayjs, { Dayjs } from 'dayjs';

import { Box, SxProps, Theme } from '@mui/material';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { DateAndTimePicker } from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate/components/ActivateRound/components/DateAndTimePicker';
import {
  Frequency,
  getDisplayText
} from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate/components/ActivateRound/helpers';

import { ButtonLoading, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export interface EditDateAndTimeProps {
  editFieldName: string;
  editFieldValue: string;
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  frequency: Frequency;
  ContentAfterTitle?: ReactNode;
  sxTitle?: SxProps<Theme>;
  onSubmitForm: (getValues: any, updateModel: any) => void;
}

const styles = [
  'color: #090E16',
  'background: #E6F6EF',
  'font-size: 10px',
  'border: 1px solid #06845B',
  'padding: 2px'
].join(';');

export const EditDateAndTime = ({
  editFieldName,
  editFieldValue,
  navigateToUrl,
  mutationQuery,
  successAlertMessage,
  errorAlertMessage,
  requiredMessage,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  ContentAfterTitle,
  onSubmitForm,
  frequency,
  sxTitle
}: EditDateAndTimeProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data, error }] = useMutation(mutationQuery);

  const parsedDateTime = dayjs(editFieldValue);

  const defaultDate = parsedDateTime.format('YYYY-MM-DD');
  const defaultTime = parsedDateTime.format('HH:mm');

  const defaultDateTime = dayjs(`${defaultDate}T${defaultTime}`);

  const { getValues, control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      [editFieldName]: {
        date: defaultDateTime,
        time: defaultDateTime
      }
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm?.(getValues, updatedData);
  });

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const REQUIRED_MESSAGE = requiredMessage || tGlobal('validation.required');
  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  // ToDo Remove
  console.log('%c' + `editFieldName, editFieldValue - ${editFieldName}, ${editFieldValue}`, styles);

  return (
    <form onSubmit={handleSubmitForm}>
      <TextBlockControl
        title={pageTitle}
        subTitle={pageSubTitle}
        ContentAfterTitle={ContentAfterTitle}
        isTitleLg
        isBodyLg
        sxTitle={sxTitle}
      />

      <Controller
        name={editFieldName}
        control={control}
        rules={{
          required: {
            value: true,
            message: REQUIRED_MESSAGE
          }
        }}
        render={({ field }) => {
          const { onChange, value } = field;

          const minDate = dayjs();
          const maxDate = dayjs().add(2, 'year').endOf('year');
          const minTime = dayjs().subtract(1, 'minute');

          const displayText = getDisplayText(frequency, value.date, value.time);

          return (
            <Box
              sx={{
                borderRadius: '4px',
                background: 'var(--background-neutral-hovered, #F7F8F9)',
                padding: '8px 0px',
                marginTop: '24px'
              }}
            >
              <DateAndTimePicker
                onChange={(date: Dayjs, time: Dayjs) => {
                  onChange({ date, time });
                }}
                value={value}
                minTime={minTime}
                displayText={displayText}
                maxDate={maxDate}
                minDate={minDate}
              />
            </Box>
          );
        }}
      />

      <ButtonLoading
        className={'edit__content__btn'}
        variant={'contained'}
        type={'submit'}
        loading={loading}
        disabled={Boolean(data)}
      >
        {SUBMIT_BTN_TEXT}
      </ButtonLoading>
    </form>
  );
};
