import { gql as gqlApollo } from '@apollo/client';

export const REGION_ITEM_FRAGMENT = gqlApollo(`
  fragment regionRes on RegionRes {
    id
    name
    active
  }
`);

export const GET_REGIONS_SIDEBAR = gqlApollo`
  ${REGION_ITEM_FRAGMENT}
  query GetRegionsSidebar($page: Int, $pageSize: Int) {
    dataItems: regions(page: $page, pageSize: $pageSize) {
      items {
        ...regionRes,
        groupItems: regions {
          items {
            ...regionRes,
            groupItems: regions {
              items {
                ...regionRes,
                groupItems: regions {
                  items {
                    ...regionRes,
                    groupItems: regions {
                      items {
                        ...regionRes,
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_REGION = gqlApollo`
  query GetRegion($id: String!, $page: Int, $pageSize: Int) {
    region(id: $id) {
      id
      name
      active
      regions(page: $page, pageSize: $pageSize) {
        items {
          id
          name
          active
        }
      }
    }
  }
`;

export const GET_REGIONS = gqlApollo`
  query GetRegions($page: Int, $pageSize: Int, $query: String, $regionId: String) {
    regions(page: $page, pageSize: $pageSize, query: $query, regionId: $regionId) {
      items {
        id
        name
        active
      }
    }
  }
`;

export const CREATE_REGION = gqlApollo`
  mutation CreateRegion($name: String!, $description: String, $parentId: String) {
    createRegion(region: { name: $name, description: $description, parentId: $parentId}) {
      id
      name
    }
  }
`;

export const UPDATE_REGION = gqlApollo`
  mutation UpdateRegion($id: String!, $name: String!, $description: String) {
    updateRegion(regionId: $id, region: { name: $name, description: $description }) {
      id
      name
    }
  }
`;

export const MOVE_REGION = gqlApollo`
  mutation MoveRegion($sourceRegionId: String!, $destinationRegionId: String) {
    moveRegion(request: { sourceRegionId: $sourceRegionId, destinationRegionId: $destinationRegionId})
  }
`;
