import { Maybe, PermissionRes } from '@/logicLayers/domain';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

export const getTypeForResource =
  (permissions: Maybe<Array<Maybe<PermissionRes>>> | undefined) => (resource: string) => {
    const resourceLength = permissions?.filter((permission) => permission?.resource === resource).length;
    return !resourceLength ? 'RESTRICTED' : resourceLength === 1 ? 'VIEW' : 'EDIT';
  };

export const asidePermissions = (permissions: Maybe<Array<Maybe<PermissionRes>>> | undefined) => {
  const getTypeForResourcePermissions = getTypeForResource(permissions);

  return [
    {
      title: 'Locations',
      type: getTypeForResourcePermissions('LOCATIONS'),
      image: LINKS_IMAGES.locations
    },
    {
      title: 'Equipments',
      type: getTypeForResourcePermissions('EQUIPMENTS'),
      image: LINKS_IMAGES.equipment
    },
    {
      title: 'User management',
      type: getTypeForResourcePermissions('USERS'),
      image: LINKS_IMAGES.userManagement
    },
    {
      title: 'Tasks',
      type: getTypeForResourcePermissions('TASKS'),
      image: LINKS_IMAGES.tasks
    },
    {
      title: 'Permissions',
      type: getTypeForResourcePermissions('PERMISSIONS'),
      image: LINKS_IMAGES.permissions
    },
    {
      title: 'Companies',
      type: getTypeForResourcePermissions('COMPANIES'),
      image: LINKS_IMAGES.companies
    },
    {
      title: 'Reports',
      type: getTypeForResourcePermissions('REPORTS'),
      image: LINKS_IMAGES.reports
    }
  ];
};
