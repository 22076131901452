import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_COMPANIES, GetCompaniesQuery } from '@/logicLayers/domain';

import { ItemsDropdown, MenuOption } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface SortByCompanyProps {
  onChangeFilterByCompany: (option: MenuOption[]) => void;
}

export const SortByCompany = ({ onChangeFilterByCompany }: SortByCompanyProps) => {
  const { t: tUsers } = useTranslation('users');

  const { data: dataCompanies } = useQuery<GetCompaniesQuery>(GET_COMPANIES, {
    fetchPolicy: 'no-cache',
    variables: {
      page: 0,
      pageSize: 1000,
      active: true
    }
  });

  const handleFilterByCompany = (options: MenuOption[]) => {
    onChangeFilterByCompany(options);
  };

  const companiesList = useMemo(() => {
    if (!dataCompanies?.dataItems.items) return [];

    return dataCompanies.dataItems.items.map((companyItem) => {
      if (!companyItem) return;

      return {
        checked: false,
        label: companyItem.name,
        key: companyItem.id,
        value: companyItem.id
      };
    });
  }, [dataCompanies?.dataItems.items]);

  return (
    <ItemsDropdown
      id={'User-management-sort-by-company'}
      title={tUsers('table.filters.filterByCompany.title')}
      menuList={companiesList as MenuOption[]}
      hasSelectAll
      disabled={!dataCompanies?.dataItems.items}
      onChange={handleFilterByCompany}
    />
  );
};
