import { useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { GET_REGION, RegionByIdQuery } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import { NotFoundScreens } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { ClickableItem } from '../ClickableItem';

export const LocationFolders = () => {
  const { t: tLocations } = useTranslation('locations');

  const { progressList, setLocationToProgressListWithNextStep } = useLocations();

  const [selectedFolder, setSelectedFolder] = useState('');

  const folderId = progressList[progressList.length - 1].id;

  const { data: dataRegions, loading: loadingRegions } = useQuery<RegionByIdQuery>(GET_REGION, {
    variables: {
      id: folderId
    },
    fetchPolicy: 'no-cache',
    skip: !folderId
  });

  const folders = dataRegions?.region?.regions?.items;

  const handleClickLocation = (id: string) => {
    if (id) {
      setSelectedFolder(id);
    }
  };

  const handleClickNextLocation = (id: string, name: string) => {
    setLocationToProgressListWithNextStep({ id: id, locationName: name });
    setSelectedFolder(id);
  };

  return (
    <>
      {loadingRegions ? (
        <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={210} animation={'wave'} />
      ) : (
        <>
          {folders && folders?.length > 0 ? (
            <>
              {folders?.map((folder) => (
                <Box key={folder?.id}>
                  {folder?.id && folder?.name ? (
                    <ClickableItem
                      disabled={!folder.active}
                      folderId={folder.id}
                      folderName={folder.name}
                      selectedFolderId={selectedFolder}
                      onSelectLocation={handleClickLocation}
                      onClickNextLocation={handleClickNextLocation}
                    />
                  ) : null}
                </Box>
              ))}
            </>
          ) : (
            <NotFoundScreens type={'locations'} subTitle={tLocations('actions.modal.move.emptyState.description')} />
          )}
        </>
      )}
    </>
  );
};
