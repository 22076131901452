export const Project = {
  root: {}
};

export const Base = {
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    background: 'red'
  }
};
