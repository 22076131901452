import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ExpandMoreIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'18'}
      height={'18'}
      viewBox={'0 0 18 18'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 18, ...sx }}
      {...restProps}
    >
      <path d="M9.00036 7.82879L5.94553 10.8836C5.84169 10.9875 5.71117 11.0406 5.55396 11.043C5.39676 11.0454 5.26383 10.9923 5.15518 10.8836C5.04652 10.775 4.99219 10.6432 4.99219 10.4884C4.99219 10.3336 5.04652 10.2019 5.15518 10.0932L8.52585 6.72257C8.59604 6.65239 8.67008 6.60287 8.74796 6.57402C8.82584 6.54518 8.90997 6.53076 9.00036 6.53076C9.09074 6.53076 9.17488 6.54518 9.25275 6.57402C9.33064 6.60287 9.40468 6.65239 9.47486 6.72257L12.8455 10.0932C12.9494 10.1971 13.0025 10.3276 13.0049 10.4848C13.0073 10.642 12.9542 10.775 12.8455 10.8836C12.7369 10.9923 12.6052 11.0466 12.4504 11.0466C12.2956 11.0466 12.1638 10.9923 12.0552 10.8836L9.00036 7.82879Z" />
    </SvgIcon>
  );
}
