import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function NoteIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: '21px !important', ...sx }}
      {...restProps}
    >
      <path d="M7.49998 14.7913H12.5C12.6773 14.7913 12.8258 14.7315 12.9455 14.6118C13.0651 14.4922 13.125 14.3437 13.125 14.1663C13.125 13.989 13.0651 13.8405 12.9455 13.7208C12.8258 13.6012 12.6773 13.5413 12.5 13.5413H7.49998C7.32263 13.5413 7.17413 13.6012 7.05448 13.7208C6.93483 13.8405 6.875 13.989 6.875 14.1663C6.875 14.3437 6.93483 14.4922 7.05448 14.6118C7.17413 14.7315 7.32263 14.7913 7.49998 14.7913ZM7.49998 11.458H12.5C12.6773 11.458 12.8258 11.3981 12.9455 11.2785C13.0651 11.1588 13.125 11.0103 13.125 10.833C13.125 10.6556 13.0651 10.5071 12.9455 10.3875C12.8258 10.2678 12.6773 10.208 12.5 10.208H7.49998C7.32263 10.208 7.17413 10.2678 7.05448 10.3875C6.93483 10.5071 6.875 10.6556 6.875 10.833C6.875 11.0103 6.93483 11.1588 7.05448 11.2785C7.17413 11.3981 7.32263 11.458 7.49998 11.458ZM5.25642 17.9163C4.83547 17.9163 4.47917 17.7705 4.1875 17.4788C3.89583 17.1871 3.75 16.8308 3.75 16.4099V3.58943C3.75 3.16848 3.89583 2.81217 4.1875 2.52051C4.47917 2.22884 4.83547 2.08301 5.25642 2.08301H11.2516C11.4525 2.08301 11.6456 2.12201 11.8309 2.20001C12.0163 2.27799 12.1773 2.38536 12.3141 2.52211L15.8109 6.01886C15.9476 6.15563 16.055 6.31669 16.133 6.50205C16.211 6.68741 16.25 6.88052 16.25 7.08136V16.4099C16.25 16.8308 16.1041 17.1871 15.8125 17.4788C15.5208 17.7705 15.1645 17.9163 14.7435 17.9163H5.25642ZM11.25 6.32976V3.33299H5.25642C5.19231 3.33299 5.13353 3.3597 5.0801 3.41311C5.02669 3.46654 4.99998 3.52531 4.99998 3.58943V16.4099C4.99998 16.474 5.02669 16.5328 5.0801 16.5862C5.13353 16.6396 5.19231 16.6663 5.25642 16.6663H14.7435C14.8077 16.6663 14.8664 16.6396 14.9199 16.5862C14.9733 16.5328 15 16.474 15 16.4099V7.08297H12.0032C11.7885 7.08297 11.6093 7.01111 11.4656 6.8674C11.3219 6.72371 11.25 6.54449 11.25 6.32976Z" />
    </SvgIcon>
  );
}
