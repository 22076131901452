import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import {
  DomainQuery,
  GET_DOMAIN_BY_ID,
  SEARCH_DOMAINS,
  UPDATE_DOMAIN,
  UpdateDomainMutation
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Preloader, PreloaderVariantsE, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_FOLDER_NAME } from './helpers';

interface FormProps {
  [FIELD_FOLDER_NAME]: string;
}

interface EditLocationModalProps {
  domainId?: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const EditLocationModal: FC<EditLocationModalProps> = ({ domainId, isModalOpen, onCloseModal }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  const { data: domainData, loading: domainLoading } = useQuery<DomainQuery>(GET_DOMAIN_BY_ID, {
    variables: {
      id: domainId
    }
  });
  const [UpdateDomain] = useMutation<UpdateDomainMutation>(UPDATE_DOMAIN, {
    refetchQueries: [SEARCH_DOMAINS]
  });

  const domain = domainData?.domain;

  // ToDo Remove
  console.log('domainDate', domainData);

  const {
    setValue,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_FOLDER_NAME]: ''
    }
  });

  useEffect(() => {
    if (domain?.name) {
      setValue(FIELD_FOLDER_NAME, domain?.name);
    }
  }, [domain?.name]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    UpdateDomain({
      variables: { id: domainId, name: data.name },
      onCompleted: ({ updateDomain }) => {
        handleCloseModal();

        if (updateDomain.id) {
          setTimeout(() => {
            AlertService.showAlert({
              title: tGlobal('alertMessages.success.domainCreated'),
              severity: 'success'
            });
          }, 500);
        }
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog
      headerTitle={tLocations('table.edit.modal.title')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tLocations('table.edit.modal.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_FOLDER_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tLocations('table.edit.modal.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        {domainLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '150px !important' }} isContainer />
        ) : (
          <>
            <Controller
              name={FIELD_FOLDER_NAME}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: ROLE_NAME_REG_EXP,
                  message: tGlobal('validation.textFieldLength')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextFieldControl
                    label={tLocations('table.edit.modal.locationInputLabel')}
                    placeholder={tLocations('table.edit.modal.locationInputPlaceholder')}
                    name={FIELD_FOLDER_NAME}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_FOLDER_NAME]?.message,
                      error: errors[FIELD_FOLDER_NAME]?.message
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </>
        )}
      </form>
    </Dialog>
  );
};
