import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import { ImageLazy, TooltipOverflowItem } from '@/separatedModules/components';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

export const CompanyLogoName = () => {
  const { company } = useReduxSelector(selectUserData);

  const companyName = company?.name;
  const companyLogo = company?.logo?.url || LINKS_IMAGES.companyUserLogoEmpty;

  return (
    <Box
      sx={{
        borderRadius: 'var(--2, 2px)',
        background: '#F5F7FA',
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        flex: '0 0 188px',
        maxWidth: '188px',
        width: 188
      }}
    >
      <ImageLazy
        className={'company-details__logo'}
        src={companyLogo}
        alt={`Company ${companyName} logo`}
        width={32}
        height={32}
        sxStyle={{
          margin: '0 8px 0 0'
        }}
      />

      {companyName ? (
        <Text
          variant={'titleSm'}
          sx={{
            color: '#121B2A',
            margin: '0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            width: 132
          }}
        >
          <TooltipOverflowItem>{companyName}</TooltipOverflowItem>
        </Text>
      ) : null}
    </Box>
  );
};
