import { useFormContext } from 'react-hook-form';

import { Grid } from '@mui/material';

import { EquipmentParametersQuery } from '@/logicLayers/domain';

import { AddParameter } from './components/AddParameter';
import { RoundDescription } from './components/RoundDescription';
import { SelectedParametersList } from './components/SelectedParametersList';
import { StepParametersHeader } from './components/StepParametersHeader';

import { ROUND_PARAMETERS } from '../../helpers';

export type EquipmentParameterItem = NonNullable<EquipmentParametersQuery['dataItems']['items'][number]>;

export const Step4 = () => {
  const { watch } = useFormContext();

  const selectedParameters: EquipmentParameterItem[] = watch(ROUND_PARAMETERS);

  return (
    <Grid justifyContent={'center'} container row mb={'77px'} sx={{ marginTop: '48px' }}>
      <Grid xs={12} sm={10} md={8} lg={7} item sx={{ padding: '0px' }}>
        <StepParametersHeader />
        <AddParameter />

        {selectedParameters.map((parameter) => (
          <SelectedParametersList key={parameter.id} parameter={parameter} />
        ))}

        <RoundDescription />
      </Grid>
    </Grid>
  );
};
