import { UPDATE_USER_BY_ID, UserRes } from '@/logicLayers/domain';

import { USER_ITEM_FIELDS } from '@/separatedModules/pages/User/helpers';

import { EditInputItem, EditMultipleInputItems } from '@/separatedModules/components';

import { COMPANY_NAME_REG_EXP, USER_NUMBER_MASK } from '@/utils';

import { onSubmitFormUserName, onSubmitFormUserPhone, UserEditProviderProps } from './helpers';

export const UserEditProvider = ({
  editType,
  navigateToUrl,
  tUser,
  tGlobal,
  i18n,
  ...restUser
}: UserEditProviderProps) => {
  const maskPattern = USER_NUMBER_MASK[i18n.language];

  return editType === USER_ITEM_FIELDS.NAME ? (
    <EditMultipleInputItems<UserRes>
      fields={['firstName', 'lastName']}
      defaultValues={{
        firstName: restUser.firstName,
        lastName: restUser.lastName
      }}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldLength')}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      pageTitle={tUser('edit.name.title')}
      inputLabels={[tUser('edit.name.inputFirstNameLabel'), tUser('edit.name.inputLastNameLabel')]}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_USER_BY_ID}
      regsExp={[COMPANY_NAME_REG_EXP, COMPANY_NAME_REG_EXP]}
      onSubmitForm={onSubmitFormUserName({ ...restUser })}
    />
  ) : editType === USER_ITEM_FIELDS.PHONE_NUMBER ? (
    <EditInputItem
      editFieldType={'phone'}
      editFieldName={'phoneNumber'}
      editFieldValue={restUser.phoneNumber as string}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.phoneRequired')}
      patternMessage={tGlobal('validation.phoneValid')}
      pageTitle={tUser('edit.phone.title')}
      pageSubTitle={tUser('edit.phone.description')}
      inputLabel={tUser('edit.phone.inputPhoneLabel')}
      submitBtnText={tGlobal('editPage.btnSave')}
      mutationQuery={UPDATE_USER_BY_ID}
      regExp={maskPattern.patternHookForm}
      onSubmitForm={onSubmitFormUserPhone({ ...restUser })}
    />
  ) : null;
};
