import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AlertWarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'24'} height={'24'} viewBox={'0 0 24 24'} {...props}>
      <path
        d="M3.42457 20.4999C3.25507 20.4999 3.10285 20.4584 2.9679 20.3756C2.83295 20.2927 2.72799 20.1835 2.65302 20.048C2.57504 19.9133 2.53215 19.7675 2.52435 19.6105C2.51655 19.4534 2.55892 19.2979 2.65147 19.1438L11.2122 4.35603C11.3048 4.202 11.4206 4.08813 11.5597 4.01441C11.6988 3.94068 11.8453 3.90381 11.9991 3.90381C12.153 3.90381 12.2994 3.94068 12.4385 4.01441C12.5776 4.08813 12.6935 4.202 12.786 4.35603L21.3468 19.1438C21.4393 19.2979 21.4817 19.4534 21.4739 19.6105C21.4661 19.7675 21.4232 19.9133 21.3452 20.048C21.2703 20.1835 21.1653 20.2927 21.0303 20.3756C20.8954 20.4584 20.7432 20.4999 20.5737 20.4999H3.42457ZM4.44912 18.9999H19.5491L11.9991 5.99991L4.44912 18.9999ZM11.9991 17.8076C12.228 17.8076 12.4198 17.7302 12.5746 17.5754C12.7294 17.4206 12.8068 17.2288 12.8068 16.9999C12.8068 16.7711 12.7294 16.5792 12.5746 16.4244C12.4198 16.2696 12.228 16.1922 11.9991 16.1922C11.7703 16.1922 11.5784 16.2696 11.4236 16.4244C11.2688 16.5792 11.1914 16.7711 11.1914 16.9999C11.1914 17.2288 11.2688 17.4206 11.4236 17.5754C11.5784 17.7302 11.7703 17.8076 11.9991 17.8076ZM11.9994 15.1922C12.212 15.1922 12.3901 15.1204 12.5337 14.9766C12.6773 14.8329 12.7491 14.6547 12.7491 14.4423V10.9422C12.7491 10.7297 12.6772 10.5516 12.5334 10.4079C12.3896 10.2641 12.2114 10.1922 11.9988 10.1922C11.7862 10.1922 11.6081 10.2641 11.4645 10.4079C11.3209 10.5516 11.2491 10.7297 11.2491 10.9422V14.4423C11.2491 14.6547 11.321 14.8329 11.4648 14.9766C11.6087 15.1204 11.7869 15.1922 11.9994 15.1922Z"
        fill="#CF9F02"
      />
    </SvgIcon>
  );
}
