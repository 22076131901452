import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { EquipmentParameterItem } from '../../Step4';
import { RemoveParameterModal } from '../RemoveParameterModal';
import { SelectedParametersListCheckbox } from '../SelectedParametersListCheckbox';
import { ValidationModal } from '../ValidationModal';

import { SelectedParametersHeader } from './components/SelectedParametersHeader';
import { ConditionType, SelectedParametersValidationHeader } from './components/SelectedParametersValidationHeader';
import { SelectedParametersValidationHeaderConditions } from './components/SelectedParametersValidationHeader/SelectedParametersValidationHeaderConditions';
import { SelectedParametersVisibility } from './components/SelectedParametersVisibility';

import { ROUND_PARAMETERS, ROUND_SELECTED_PARAMETERS } from '../../../../helpers';

export interface SelectedParametersListProps {
  parameter: EquipmentParameterItem;
}

export const SelectedParametersList = ({ parameter }: SelectedParametersListProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenValidationModal, setIsOpenValidationModal] = useState(false);
  const [isOpenEditValidationModal, setIsOpenEditValidationModal] = useState(false);
  const [isOpenDeleteConditionsModal, setIsOpenDeleteConditionsModal] = useState(false);

  const { setValue, watch } = useFormContext();

  const parameters: EquipmentParameterItem[] = watch(ROUND_PARAMETERS);
  const selectedParameters: EquipmentParameterItem[] = watch(ROUND_SELECTED_PARAMETERS);

  const selectedParameter = selectedParameters.find((selectedParameter) => selectedParameter.id === parameter.id);

  const parameterValidation = selectedParameter?.condition;

  const isNumericFixed = parameter.type === 'NUMERIC_FIXED';
  const isNumericFormula = parameter.type === 'NUMERIC_FORMULA';
  const isString = parameter.type === 'STRING';
  const isTime = parameter.type === 'DATA_TIME';

  const handleRemoveParameter = () => {
    const updatedParameters = parameters.filter((selectedParameter) => selectedParameter.id !== parameter.id);
    const updatedSelectedParameters = selectedParameters.filter(
      (selectedParameter) => selectedParameter.id !== parameter.id
    );

    setValue(ROUND_SELECTED_PARAMETERS, updatedSelectedParameters);
    setValue(ROUND_PARAMETERS, updatedParameters);
    setIsOpenModal(false);
  };

  const handleRemoveValidation = () => {
    const selectedParameterIndex = selectedParameters.findIndex(
      (selectedParameter) => selectedParameter.id === parameter.id
    );

    if (selectedParameterIndex !== -1) {
      const selectedParameter = { ...selectedParameters[selectedParameterIndex] };

      selectedParameter.condition = undefined;

      const updatedSelectedParameters = [
        ...selectedParameters.slice(0, selectedParameterIndex),
        selectedParameter,
        ...selectedParameters.slice(selectedParameterIndex + 1)
      ];

      setValue(ROUND_SELECTED_PARAMETERS, updatedSelectedParameters);

      setIsOpenDeleteConditionsModal(false);
    }
  };

  const booleanConditionType = `Validate if ${parameterValidation?.exactValue === 'true' ? '"Yes"' : '"No"'}`;

  const conditionType =
    parameter.type === 'BOOLEAN'
      ? booleanConditionType
      : ConditionType[parameterValidation?.type as keyof typeof ConditionType];

  const handleAddValidation = (
    selectedCondition: string,
    roundExecValue: string | undefined,
    roundMinValue: string | undefined,
    roundMaxValue: string | undefined
  ) => {
    const selectedParameterIndex = selectedParameters.findIndex(
      (selectedParameter) => selectedParameter.id === parameter.id
    );

    if (selectedParameterIndex !== -1) {
      const selectedParameter = { ...selectedParameters[selectedParameterIndex] };

      const booleanExactValue = selectedCondition === 'Validate if “Yes”' ? 'true' : 'false';

      selectedParameter.condition =
        selectedCondition === 'Match the value' || parameter.type === 'BOOLEAN'
          ? {
              type: 'MATCH',
              exactValue: parameter.type === 'BOOLEAN' ? booleanExactValue : roundExecValue
            }
          : {
              type: selectedCondition === 'Fit the range' ? 'FIT_RANGE' : 'OUT_OF_RANGE',
              minValue: roundMinValue,
              maxValue: roundMaxValue
            };

      const updatedSelectedParameters = [
        ...selectedParameters.slice(0, selectedParameterIndex),
        selectedParameter,
        ...selectedParameters.slice(selectedParameterIndex + 1)
      ];

      setValue(ROUND_SELECTED_PARAMETERS, updatedSelectedParameters);

      setIsOpenValidationModal(false);
      setIsOpenEditValidationModal(false);
    }
  };

  const handleChangePhoto = (type: string) => {
    const selectedParameterIndex = selectedParameters.findIndex(
      (selectedParameter) => selectedParameter.id === parameter.id
    );

    if (selectedParameterIndex !== -1) {
      const selectedParameter = { ...selectedParameters[selectedParameterIndex] };

      (selectedParameter as any)[type] = !(selectedParameter as any)[type];

      const updatedSelectedParameters = [
        ...selectedParameters.slice(0, selectedParameterIndex),
        selectedParameter,
        ...selectedParameters.slice(selectedParameterIndex + 1)
      ];

      setValue(ROUND_SELECTED_PARAMETERS, updatedSelectedParameters);
    }
  };

  return (
    <Box
      sx={{
        background: 'var(--background-neutral-hovered, #F7F8F9)',
        border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
        borderRadius: 'var(--8, 8px)',
        marginTop: '24px',
        padding: '20px'
      }}
    >
      <SelectedParametersHeader parameter={parameter} handleOpenModal={() => setIsOpenModal(true)} />
      <SelectedParametersVisibility parameter={parameter} />
      {!isNumericFixed && (
        <Box sx={{ marginTop: '26px', borderTop: '1px solid rgba(9, 14, 22, 0.15)' }}>
          {!isString && !isTime && (
            <SelectedParametersValidationHeader
              handleOpenValidationModal={() => setIsOpenValidationModal(true)}
              selectedParameters={selectedParameters}
              parameterId={String(parameter.id)}
              handleRemoveParameterConditionModal={() => setIsOpenDeleteConditionsModal(true)}
              handleOpenEditValidationModal={() => setIsOpenEditValidationModal(true)}
              isBoolean={parameter.type === 'BOOLEAN'}
            />
          )}
          {!isNumericFormula && (
            <Box sx={{ marginTop: '24px' }}>
              <SelectedParametersListCheckbox
                title="Need to upload photo"
                description="Service staff should attach visual evidence during equipment rounds"
                sx={{ marginBottom: '24px' }}
                onChange={() => handleChangePhoto('uploadPhoto')}
                value={Boolean(selectedParameter?.uploadPhoto)}
              />
              <SelectedParametersListCheckbox
                title="Ability to add note"
                description="Service staff will have the ability to provide additional context, details, or recommendations related
                to the equipment inspection"
                onChange={() => handleChangePhoto('addNote')}
                value={Boolean(selectedParameter?.addNote)}
              />
            </Box>
          )}
        </Box>
      )}
      <RemoveParameterModal
        isOpen={isOpenModal}
        handleCloseModal={() => {
          setIsOpenModal(false);
        }}
        handleModalAction={handleRemoveParameter}
        modalBtnText="Remove"
        modalContent="Are you sure that you want to remove this parameter from the round regulation?"
        modalTitle="Remove parameter"
        parameter={parameter}
      />
      <RemoveParameterModal
        isOpen={isOpenDeleteConditionsModal}
        handleCloseModal={() => {
          setIsOpenDeleteConditionsModal(false);
        }}
        handleModalAction={handleRemoveValidation}
        modalBtnText="Remove"
        modalContent="Are you sure that you want to remove validation conditions for this parameter? "
        modalTitle="Remove validation conditions"
        parameter={parameter}
        isCondition
      >
        <Box sx={{ marginTop: '16px', borderTop: '1px solid rgba(9, 14, 22, 0.15)' }}>
          <Text
            sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '18px', marginTop: '16px', marginBottom: '16px' }}
          >
            Validation conditions
          </Text>
          <Box>
            <SelectedParametersValidationHeaderConditions title="Condition type:" value={conditionType} isModal />

            {parameterValidation?.type === 'MATCH' && parameter.type !== 'BOOLEAN' && (
              <SelectedParametersValidationHeaderConditions
                title="Exec value:"
                value={parameterValidation.exactValue}
                isModal
              />
            )}
            {parameterValidation?.type !== 'MATCH' && (
              <>
                <SelectedParametersValidationHeaderConditions
                  title="Minimum value:"
                  value={parameterValidation?.minValue}
                  isModal
                />
                <SelectedParametersValidationHeaderConditions
                  title="Maximum value:"
                  value={parameterValidation?.maxValue}
                  isModal
                />
              </>
            )}
          </Box>
        </Box>
      </RemoveParameterModal>
      <ValidationModal
        isOpen={isOpenEditValidationModal || isOpenValidationModal}
        isOpenModalAddValidation={isOpenValidationModal}
        handleCloseModal={() => {
          setIsOpenValidationModal(false);
          setIsOpenEditValidationModal(false);
        }}
        handleModalAction={handleAddValidation}
        modalBtnText={isOpenEditValidationModal ? 'Edit' : 'Add'}
        modalContent="Please specify the validation rules for the parameter. 
        If the entered value fails to meet the specified conditions, the parameter will be marked as unsatisfactory, 
        potentially resulting in the failure of the round"
        modalTitle={`${isOpenEditValidationModal ? 'Edit' : 'Add'} validation conditions`}
        parameter={parameter}
        isOpenEditValidationModal={isOpenEditValidationModal}
      />
    </Box>
  );
};
