import { isBrowser } from '@/utils';

const SESSION_STORAGE_SIGN_IN_KEY = 'signInData';

type DataT = object;

class SessionStorage {
  #keyName = '';

  constructor(name: string) {
    this.#keyName = name;
  }

  get() {
    if (!isBrowser()) return;

    const sessionData = sessionStorage.getItem(this.#keyName);

    return sessionData ? JSON.parse(sessionData) : null;
  }

  set(data: DataT) {
    if (!isBrowser()) return;

    data && sessionStorage.setItem(this.#keyName, JSON.stringify(data));
  }

  update(data: DataT) {
    if (!isBrowser()) return;

    const sessionData = this.get() || null;
    const updatedData = { ...sessionData, ...data };

    this.set(updatedData);

    return updatedData;
  }

  delete() {
    if (!isBrowser()) return;

    sessionStorage.removeItem(this.#keyName);
  }
}

export class SessionStorageServiceClass {
  signIn = new SessionStorage(SESSION_STORAGE_SIGN_IN_KEY);
}

export const SessionStorageService = new SessionStorageServiceClass();
