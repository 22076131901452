export const Project = {
  root: {
    alignItems: 'flex-start',
    '.MuiFormHelperText-root__text': {
      color: 'var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '500',
      margin: '0'
    },
    '&.Mui-error': {
      '.MuiFormHelperText-root__text': {
        color: 'var(--text-danger, #BE0E2C)'
      },
      '.MuiSvgIcon-root': {
        margin: '0 4px 0 0'
      }
    },
    '&.Mui-disabled:not(.Mui-error)': {
      '.MuiFormHelperText-root__text': {
        color: 'var(--custom-styles-grey-30, rgba(17, 24, 39, 0.30)) !important'
      }
    }
  }
};

export const Base = {
  root: {
    margin: '4px 0',
    display: 'flex',
    alignItems: 'center',
    '.MuiFormHelperText-root__text': {
      color: 'var(--default-gray-500)',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '500',
      margin: '0'
    }
  }
};
