import { styled } from '@mui/material/styles';

export const StyledCreateCompany = styled('div')(({ theme }) => ({
  '.create-company': {
    '&__content': {
      '&__title': {
        '&__wrp': {
          margin: '32px 0 48px'
        },
        '&__sub': {
          margin: '32px 0'
        }
      },
      '&__btn': {
        margin: '48px 0 0 auto',
        display: 'block'
      }
    },
    '&__flow': {
      '&__i': {
        padding: '32px 24px',
        borderRadius: 'var(--8, 8px)',
        border: '1px solid var(--border-disabled, rgba(9, 14, 22, 0.06))',
        background: 'var(--background-neutral-default, #FFF)',
        textAlign: 'center',
        transition: 'all .2s ease-in-out',
        '&:last-child': {
          [theme.breakpoints.down('md')]: {
            margin: '0 0 24px'
          }
        },
        '&:hover, &.Mui-selected': {
          cursor: 'pointer',
          border: '1px solid var(--border-selected, #388BFF)',
          background: 'var(--background-selected-default, #F2F7FF)'
        },
        '&.Mui-disabled': {
          pointerEvents: 'none',
          background: 'var(--background-neutral-default, #FFF)',
          border: '1px solid var(--border-disabled, rgba(9, 14, 22, 0.06)) !important',
          img: {
            opacity: '0.5'
          },
          '*': {
            color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))'
          }
        },
        '&.Mui-error': {
          border: '1px solid var(--border-danger, #BE0E2C)'
        },
        '&__img': {
          width: '88px',
          height: '88px',
          margin: '0 auto 32px'
        },
        '&__titleSub': {
          [theme.breakpoints.up('md')]: {
            minHeight: '40px'
          }
        }
      }
    }
  }
}));

StyledCreateCompany.defaultProps = {
  className: 'create-company'
};
