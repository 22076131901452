import { HomeIcon } from '@/separatedModules/components/Icon/svg-mui-theme/Home';
import { HomeFillIcon } from '@/separatedModules/components/Icon/svg-mui-theme/HomeFill';
import { NotificationsIcon } from '@/separatedModules/components/Icon/svg-mui-theme/Notifications';
import { NotificationsFillIcon } from '@/separatedModules/components/Icon/svg-mui-theme/NotificationsFill';

import { i18n } from '@/i18n';

export const NavBarItems = [
  {
    label: i18n.t('mobile.menu.home', { ns: 'global' }),
    icon: <HomeIcon />,
    selectedIcon: <HomeFillIcon />,
    to: '/'
  },
  {
    label: i18n.t('mobile.menu.notifications', { ns: 'global' }),
    icon: <NotificationsIcon />,
    selectedIcon: <NotificationsFillIcon />,
    to: '/notifications'
  }
];
