import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USERS, GetUsersQuery, UsersRoleTemplateRes } from '@/logicLayers/domain';

import { checkTablePagination } from '@/separatedModules/components';

import { ContextStateI, initState, initStateI, TableProviderProps } from './helpers';

export const ContextState = createContext<ContextStateI>({
  ...initState,
  loadingRoleTemplates: false,
  setState: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ProviderState = ({ children, roleId }: TableProviderProps) => {
  const [state, setState] = useState<initStateI>(initState);

  const { data: dataRoleTemplates, loading: loadingRoleTemplates } = useQuery<GetUsersQuery>(GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          page: state.pagination.page[0],
          pageSize: state.pagination.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'lastModifiedDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0].value : undefined
        },
        query: state.filters.search.length ? state.filters.search[0] : undefined
      },
      templateIds: [roleId]
    }
  });

  const contextValue = useMemo(() => {
    return {
      ...state,
      loadingRoleTemplates,
      setState
    };
  }, [state]);

  useEffect(() => {
    if (!dataRoleTemplates) return;

    const { isVisiblePagination, rowsPerPageOptions } = checkTablePagination(
      dataRoleTemplates?.dataItems.total || 0,
      state.pagination.rowsPerPageOptions
    );

    const bodyDataRows = (dataRoleTemplates?.dataItems.items || []) as UsersRoleTemplateRes[];
    const resultSearchCount = bodyDataRows?.length || 0;
    const resultTotalCount = dataRoleTemplates?.dataItems.total;

    setState((state) => {
      return {
        ...state,
        isVisiblePagination,
        bodyDataRows,
        loadingRoleTemplates,
        resultSearchCount,
        resultTotalCount,
        pagination: {
          ...state.pagination,
          rowsPerPageOptions
        }
      };
    });
  }, [dataRoleTemplates, loadingRoleTemplates]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
