import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DomainIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M6.69212 7.6955L9.35554 3.33017C9.43354 3.20196 9.52903 3.11061 9.642 3.05612C9.75499 3.00164 9.87424 2.9744 9.99977 2.9744C10.1253 2.9744 10.2446 3.00164 10.3575 3.05612C10.4705 3.11061 10.566 3.20196 10.644 3.33017L13.3074 7.6955C13.3854 7.82103 13.4244 7.95284 13.4244 8.09094C13.4244 8.22902 13.3924 8.35522 13.3283 8.46952C13.2642 8.58384 13.176 8.67519 13.0638 8.74356C12.9517 8.81194 12.8208 8.84613 12.6712 8.84613H7.32833C7.17815 8.84613 7.04674 8.8116 6.9341 8.74256C6.82147 8.67352 6.73386 8.58251 6.67129 8.46952C6.60718 8.35694 6.57512 8.23184 6.57512 8.09423C6.57512 7.95662 6.61412 7.82371 6.69212 7.6955ZM14.5831 18.0128C13.6269 18.0128 12.8163 17.6803 12.1512 17.0152C11.4862 16.3502 11.1536 15.5395 11.1536 14.5833C11.1536 13.6271 11.4862 12.8165 12.1512 12.1515C12.8163 11.4864 13.6269 11.1539 14.5831 11.1539C15.5393 11.1539 16.3499 11.4864 17.015 12.1515C17.68 12.8165 18.0126 13.6271 18.0126 14.5833C18.0126 15.5395 17.68 16.3502 17.015 17.0152C16.3499 17.6803 15.5393 18.0128 14.5831 18.0128ZM2.82031 16.8427V12.3219C2.82031 12.1084 2.89252 11.9298 3.03694 11.7861C3.18134 11.6424 3.36028 11.5705 3.57377 11.5705H8.09458C8.30807 11.5705 8.48666 11.6428 8.63035 11.7872C8.77405 11.9316 8.8459 12.1105 8.8459 12.324V16.8448C8.8459 17.0583 8.77369 17.2369 8.62927 17.3806C8.48487 17.5243 8.30592 17.5961 8.09244 17.5961H3.57163C3.35814 17.5961 3.17955 17.5239 3.03585 17.3795C2.89216 17.2351 2.82031 17.0562 2.82031 16.8427ZM14.5829 16.7628C15.1931 16.7628 15.7089 16.5522 16.1304 16.1308C16.5519 15.7095 16.7626 15.1938 16.7626 14.5836C16.7626 13.9734 16.5519 13.4575 16.1306 13.0361C15.7093 12.6146 15.1935 12.4038 14.5833 12.4038C13.9731 12.4038 13.4573 12.6145 13.0358 13.0358C12.6143 13.4572 12.4036 13.9729 12.4036 14.5831C12.4036 15.1933 12.6143 15.7091 13.0356 16.1306C13.4569 16.5521 13.9727 16.7628 14.5829 16.7628ZM4.07027 16.3462H7.59594V12.8205H4.07027V16.3462ZM8.19848 7.59617H11.8011L9.99977 4.69871L8.19848 7.59617Z" />
    </SvgIcon>
  );
}
