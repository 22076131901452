import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function SearchIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M19.5408 20.577L13.26 14.2962C12.76 14.7091 12.185 15.0321 11.535 15.2655C10.885 15.4988 10.2126 15.6155 9.51769 15.6155C7.80853 15.6155 6.36201 15.0237 5.17814 13.8401C3.99428 12.6566 3.40234 11.2104 3.40234 9.50169C3.40234 7.79296 3.99413 6.34628 5.17769 5.16167C6.36126 3.97707 7.80741 3.38477 9.51614 3.38477C11.2249 3.38477 12.6715 3.9767 13.8561 5.16057C15.0408 6.34443 15.6331 7.79095 15.6331 9.50012C15.6331 10.2142 15.5132 10.8963 15.2734 11.5463C15.0337 12.1963 14.7138 12.7617 14.3138 13.2424L20.5946 19.5232L19.5408 20.577ZM9.51769 14.1155C10.8062 14.1155 11.8975 13.6684 12.7917 12.7742C13.686 11.8799 14.1331 10.7886 14.1331 9.50012C14.1331 8.21165 13.686 7.1203 12.7917 6.22607C11.8975 5.33183 10.8062 4.88472 9.51769 4.88472C8.22923 4.88472 7.13788 5.33183 6.24364 6.22607C5.34943 7.1203 4.90232 8.21165 4.90232 9.50012C4.90232 10.7886 5.34943 11.8799 6.24364 12.7742C7.13788 13.6684 8.22923 14.1155 9.51769 14.1155Z" />
    </SvgIcon>
  );
}
