export const Project = {
  root: {
    '&.MuiDialog-root': {
      '.MuiDialog-container': {
        '.MuiPaper-root': {
          width: '100%',
          borderRadius: '12px'
        }
      },
      '.MuiModal': {
        '&-box': {
          background: 'var(--white)',
          maxWidth: '600px'
        },
        '&-header': {
          borderBottom: '1px solid var(--default-gray-200)',
          '&__title': {
            color: 'var(--text-default, #090E16)'
          },
          '&__sub-title, &__sub-title *': {
            color: 'var(--text-default, #090E16)',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400'
          },
          '.MuiSvgIcon-root': {
            fill: 'var(--default-gray-400)',
            '&:hover': {
              fill: 'var(--default-gray-800)'
            }
          }
        },
        '&-footer': {
          borderTop: '1px solid var(--default-gray-200)'
        }
      }
    }
  }
};

export const Base = {
  root: {
    '&.MuiDialog-root': {
      '.MuiDialog-container': {
        '.MuiPaper-root': {
          boxShadow: 'none',
          margin: '24px 0',
          // ToDo For Override
          borderRadius: '120px',
          '&.MuiDialog-paperScrollPaper': {
            display: 'block'
          }
        }
      },
      '.MuiModal': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
        '&-box': {
          position: 'relative',
          // ToDo For Override
          background: 'var(--white)',
          // ToDo For Override
          maxWidth: '600px',
          zIndex: '1',
          width: '100%',
          overflow: 'hidden',
          minHeight: '200px',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          '&--hide': {
            '&-border-header': {
              '.MuiModal-header': {
                border: 'none !important'
                // paddingBottom: '0 !important'
              }
            },
            '&-border-footer': {
              '.MuiModal-footer': {
                border: 'none !important'
                // paddingTop: '0 !important'
              }
            },
            '&--content-center': {
              '.MuiModal-content': {
                justifyContent: 'center',
                flexDirection: 'column'
              }
            }
          }
        },
        '&-header': {
          padding: '20px 24px 12px',
          // ToDo For Override
          borderBottom: '1px solid var(--default-gray-200)',
          '&__title': {
            // ToDo For Override
            color: 'var(--default-gray-800)',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '30px',
            '&__wrp': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }
          },
          '&__sub-title, &__sub-title *': {
            // ToDo For Override
            color: 'var(--default-gray-800)',
            fontSize: '16px',
            lineHeight: '1.5',
            marginTop: '12px'
          },
          '.MuiSvgIcon-root': {
            // ToDo For Override
            fill: 'var(--default-gray-400)',
            cursor: 'pointer',
            width: '24px',
            height: '24px',
            flex: '0 0 24px',
            marginLeft: 'auto',
            transition: 'all .3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.1)',
              // ToDo For Override
              fill: 'var(--default-gray-800)'
            }
          }
        },
        '&-content': {
          padding: '32px 24px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        },
        '&-footer': {
          padding: '16px 24px',
          // ToDo For Override
          borderTop: '1px solid var(--default-gray-200)'
        },
        '&-header, &-content, &-footer': {
          '@media (max-width: 1024px)': {
            padding: '16px 12px'
          }
        }
      }
    }
  }
};
