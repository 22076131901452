import { FC } from 'react';
import { Text } from 'infinitytechnologies-ui';
import isEmpty from 'lodash/isEmpty';

import { useConstructUserGroupPermissions } from '@/separatedModules/pages/User/helpers';

import { useTranslation } from '@/i18n';

interface DescriptionRoleProps {
  userId: string | null;
}

export const DescriptionRole: FC<DescriptionRoleProps> = ({ userId }) => {
  const { t: tTemplates } = useTranslation('templates');

  const { matchedPermissionsList } = useConstructUserGroupPermissions({
    userId
  });

  return (
    <>
      {!isEmpty(matchedPermissionsList)
        ? Object.entries(matchedPermissionsList).map(([key, value]) => (
            <Text key={key} className={'m-0'} variant={'bodyMd'} color={'#505668'}>
              {value.length
                ? `${value.map((item) => ' ' + tTemplates(`createRole.mainInfo.${item.toLowerCase()}Label`))}: ${key.toLowerCase()};`
                : null}
            </Text>
          ))
        : null}
    </>
  );
};
