import { useQuery } from '@apollo/client';

import { EquipmentItemNameQuery, GET_EQUIPMENT_ITEM_NAME, ParameterType } from '@/logicLayers/domain';

export interface Parameter {
  id: number;
  title: string;
  description: string;
  logoPath: string;
  parameterType: string;
  disabled?: boolean;
}

// Copy of '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameters/helpers';
export const ParameterTypeTyped = {
  NUMERIC_INPUT: ParameterType.NumericInput.toUpperCase(),
  NUMERIC_FORMULA: ParameterType.NumericFormula.toUpperCase(),
  NUMERIC_FIXED: ParameterType.NumericFixed.toUpperCase(),
  DATA_TIME: ParameterType.DataTime.toUpperCase(),
  BOOLEAN: ParameterType.Boolean.toUpperCase(),
  STRING: ParameterType.String.toUpperCase()
};

export const STRING_BOOLEAN_DATE_PARAMS = [
  ParameterTypeTyped.BOOLEAN,
  ParameterTypeTyped.STRING,
  ParameterTypeTyped.DATA_TIME
];

export const FORM_ID = 'create-equipment-item-template';
export const PARAMETER_PROPERTY_ID = 'propertyId';
export const PARAMETER_EQUIPMENT_ID = 'equipmentId';
export const PARAMETER_TYPE = 'type';
export const PARAMETER_TITLE = 'title';
export const PARAMETER_ROUND = 'round';
export const PARAMETER_DATE = 'date';
export const PARAMETER_UNIT_ID = 'unitId';
export const PARAMETER_DESCRIPTION = 'description';
export const PARAMETER_VALUE = 'value';
export const PARAMETER_ORDER = 'order';

export interface FormProps {
  [PARAMETER_PROPERTY_ID]?: string;
  [PARAMETER_EQUIPMENT_ID]?: string;
  [PARAMETER_TYPE]?: string;
  [PARAMETER_TITLE]?: string;
  [PARAMETER_UNIT_ID]?: string;
  [PARAMETER_DESCRIPTION]?: string;
  [PARAMETER_VALUE]?: string;
  [PARAMETER_ORDER]?: number;
}

export const DEFAULT_VALUES: FormProps = {};

export interface StepsStateI {
  currentStep: number;
  totalSteps: number;
  parameterType: string;
  isDataCreated: boolean;
}

export const useData = ({ templateId }: { templateId: string }) => {
  const { data: equipmentItemNameData, loading: equipmentItemNameLoading } = useQuery<EquipmentItemNameQuery>(
    GET_EQUIPMENT_ITEM_NAME,
    {
      variables: {
        id: templateId
      }
    }
  );

  return {
    equipmentItemNameData,
    equipmentItemNameLoading
  };
};
