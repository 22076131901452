import { createTheme } from '@mui/material/styles';

import {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAlert,
  MuiAlertTitle,
  MuiAutocomplete,
  MuiAvatar,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiContainer,
  MuiDialog,
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
  MuiGrid,
  MuiIconButton,
  MuiLinearProgress,
  MuiMenu,
  MuiMenuItem,
  MuiModal,
  MuiPopover,
  MuiPreloader,
  MuiRadio,
  MuiSelect,
  MuiSkeleton,
  MuiSnackbar,
  MuiSvgIcon,
  MuiSwitch,
  MuiTab,
  MuiTable,
  MuiTableCell,
  MuiTablePagination,
  MuiTableRow,
  MuiTabs,
  MuiTextField,
  MuiTooltip,
  MuiTypography
} from './components';

export const theme = createTheme({
  palette: {
    mode: 'light'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1600
    }
  },
  components: {
    MuiAccordion,
    MuiAccordionDetails,
    MuiAccordionSummary,
    MuiAlert,
    MuiAlertTitle,
    MuiAutocomplete,
    MuiAvatar,
    MuiButton,
    MuiCheckbox,
    MuiChip,
    MuiContainer,
    MuiDialog,
    MuiFormControl,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiFormLabel,
    MuiGrid,
    MuiIconButton,
    MuiLinearProgress,
    MuiMenu,
    MuiMenuItem,
    MuiModal,
    MuiPopover,
    MuiPreloader,
    MuiRadio,
    MuiSelect,
    MuiSkeleton,
    MuiSnackbar,
    MuiSvgIcon,
    MuiSwitch,
    MuiTab,
    MuiTable,
    MuiTableCell,
    MuiTablePagination,
    MuiTableRow,
    MuiTabs,
    MuiTextField,
    MuiTooltip,
    MuiTypography
  }
});

export type ThemeT = { theme: typeof theme };
