import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import { CREATE_DOMAIN, CreateDomainMutation, SEARCH_DOMAINS } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_DOMAIN_NAME } from './helpers';

interface FormProps {
  [FIELD_DOMAIN_NAME]: string;
}

interface AddOrEditDomainModalProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const AddDomainModal: FC<AddOrEditDomainModalProps> = ({ isModalOpen, onCloseModal }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');
  const { t: tErrors } = useTranslation('errors');

  const [CreateDomain] = useMutation<CreateDomainMutation>(CREATE_DOMAIN, {
    refetchQueries: [SEARCH_DOMAINS]
  });

  const {
    setError,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_DOMAIN_NAME]: ''
    }
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    CreateDomain({
      variables: { name: data.name },
      onCompleted: ({ createDomain }) => {
        handleCloseModal();

        if (createDomain.id) {
          setTimeout(() => {
            AlertService.showAlert({
              title: tGlobal('alertMessages.success.domainCreated'),
              severity: 'success'
            });
          }, 500);
        }
      },
      onError: (error) => {
        const errorMessage = JSON.parse(error.message);

        if (errorMessage?.errorCode) {
          setError(FIELD_DOMAIN_NAME, {
            message: tErrors(`templates.create.${errorMessage.errorCode.replace('error.', '')}`)
          });
        }
      }
    });
  });

  return (
    <Dialog
      headerTitle={tTemplates('domains.table.create.modal.title')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tTemplates('domains.table.create.modal.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_DOMAIN_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tTemplates('domains.table.create.modal.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        <Controller
          name={FIELD_DOMAIN_NAME}
          control={control}
          rules={{
            required: {
              value: true,
              message: tGlobal('validation.required')
            },
            pattern: {
              value: ROLE_NAME_REG_EXP,
              message: tGlobal('validation.textFieldLength')
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextFieldControl
                label={tTemplates('domains.table.create.modal.domainInputLabel')}
                placeholder={tTemplates('domains.table.create.modal.domainInputPlaceholder')}
                name={FIELD_DOMAIN_NAME}
                value={value}
                validation={{
                  isValid: !errors[FIELD_DOMAIN_NAME]?.message,
                  error: errors[FIELD_DOMAIN_NAME]?.message
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
      </form>
    </Dialog>
  );
};
