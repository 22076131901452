import { useLocation } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CONFIG_TABLE } from '@/separatedModules/pages/Templates/pages/Role/helpers';

import { isSuperAdmin } from '@/utils';

import { useTranslation } from '@/i18n';

export const TableHeader = () => {
  const location = useLocation();
  const isUserSuperAdmin = isSuperAdmin();

  const { t: tTemplates } = useTranslation('templates');

  return (
    <TableHead>
      <TableRow>
        {CONFIG_TABLE.cells.map(({ columnName, title }, index) => {
          const isMainRole = location.pathname === '/role';
          const shouldHideCreatedBy = !isMainRole && columnName === 'createdBy';
          const shouldHideItemsTemplate = isMainRole && columnName === 'itemsTemplate' && isUserSuperAdmin;

          if (shouldHideItemsTemplate || shouldHideCreatedBy) {
            return null;
          }

          return (
            <TableCell key={columnName} className={CONFIG_TABLE.cells[index].columnClass}>
              <Text variant={'labelMd'}>{tTemplates(title)}</Text>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
