import { memo, useCallback } from 'react';

import { Alert as MuiAlert } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';

import { AlertItemProps } from '@/logicLayers/infrastructure/services';

import {
  AlertCheckIcon,
  AlertCheckNoticeIcon,
  AlertCloseIcon,
  AlertErrorIcon,
  AlertErrorNoticeIcon,
  AlertInfoIcon,
  AlertInfoNoticeIcon,
  AlertWarningIcon,
  AlertWarningNoticeIcon
} from '@/separatedModules/components';

interface Alert extends AlertItemProps {}

interface GetAlertIconProps extends Pick<AlertItemProps, 'severity' | 'variant'> {}

const getAlertIcon = ({ variant, severity }: GetAlertIconProps) => {
  return variant === 'notice' ? (
    severity === 'success' ? (
      <AlertCheckNoticeIcon sx={{ fontSize: 20 }} />
    ) : severity === 'error' ? (
      <AlertErrorNoticeIcon sx={{ fontSize: 20 }} />
    ) : severity === 'info' ? (
      <AlertInfoNoticeIcon sx={{ fontSize: 20 }} />
    ) : severity === 'warning' ? (
      <AlertWarningNoticeIcon sx={{ fontSize: 20 }} />
    ) : null
  ) : severity === 'success' ? (
    <AlertCheckIcon sx={{ fontSize: 20 }} />
  ) : severity === 'error' ? (
    <AlertErrorIcon sx={{ fontSize: 20 }} />
  ) : severity === 'info' ? (
    <AlertInfoIcon sx={{ fontSize: 20 }} />
  ) : severity === 'warning' ? (
    <AlertWarningIcon sx={{ fontSize: 20 }} />
  ) : null;
};

export const Alert = memo(
  ({ variant, severity, title, content, action, isHideCloseBtn, onCloseAction, onFooterAction }: Alert) => {
    const handleCloseAction = useCallback(() => {
      onCloseAction?.();
    }, []);

    const handleFooterAction = useCallback(() => {
      onFooterAction?.();
    }, []);

    return (
      <MuiAlert
        variant={variant}
        severity={severity}
        icon={getAlertIcon({ variant, severity })}
        action={
          isHideCloseBtn ? (
            <IconButton aria-label={'close alert'} onClick={handleCloseAction}>
              <AlertCloseIcon />
            </IconButton>
          ) : null
        }
      >
        {title ? <AlertTitle>{title}</AlertTitle> : null}

        {content ? <div className={'MuiAlert-content'}>{content}</div> : null}

        {action ? (
          <div className={'MuiAlert-content MuiAlert-content-action'} onClick={handleFooterAction}>
            {action}
          </div>
        ) : null}
      </MuiAlert>
    );
  }
);
