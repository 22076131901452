import { FC } from 'react';

import Box from '@mui/material/Box';

import { TextBlockControl } from '@/separatedModules/components';

import { EquipmentItem } from '../../helpers';

import { StyledEquipmentItemPlate } from './style';

interface EquipmentItemPlateProps {
  selectedId: number;
  equipmentItem: EquipmentItem;
  onClick: () => void;
}

export const EquipmentItemPlate: FC<EquipmentItemPlateProps> = ({ equipmentItem, selectedId, onClick }) => {
  return (
    <StyledEquipmentItemPlate key={equipmentItem.id} selected={selectedId === equipmentItem.id} onClick={onClick}>
      <Box
        sx={{
          flex: '0 0 56px',
          maxWidth: '56px',
          display: 'flex',
          alignItems: 'center',
          margin: '0 16px 0 0'
        }}
      >
        <img src={equipmentItem.logoPath} alt={'equipment-creation-type'} />
      </Box>

      <TextBlockControl
        className={'m-0'}
        title={equipmentItem.title}
        subTitle={equipmentItem.description}
        sxTitle={{ marginBottom: '8px' }}
        sxSubTitle={{ marginBottom: 0 }}
      />
    </StyledEquipmentItemPlate>
  );
};
