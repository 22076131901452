import classNames from 'classnames';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';

import { SkeletonTableLoaderProps } from './helpers';

export const SkeletonTableLoader = ({ rows = 15, cells }: SkeletonTableLoaderProps) => {
  const array = new Array(rows).fill(0);

  return (
    <Box>
      <LinearProgress className={'Mui-table'} />

      {array.map((_, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {cells.map((items, index) => {
              return (
                <Box
                  key={index}
                  className={classNames('MuiSkeleton-root__wrp')}
                  sx={{ width: '100%', flex: '0 1 100%', padding: '8px 0', display: 'flex', alignItems: 'center' }}
                >
                  {items.map((cellItem, index) => {
                    return (
                      <Skeleton
                        className={'MuiSkeleton-root__table'}
                        key={cellItem.variant + index}
                        variant={cellItem.variant}
                        sx={{ margin: 0, ...cellItem.sx }}
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
