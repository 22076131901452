import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Tab, Tabs } from '@mui/material';

import { Maybe, PermissionRes, UrlInfo } from '@/logicLayers/domain';

import { TabPanel } from '@/separatedModules/pages/Tasks/components/AsideContent/components/TabPanel';

import { DomainStatusChip, Flex, ImageLazy, TableAsideItem } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { AsidePermission } from '../AsidePermission';

import { asidePermissions } from './helpers';

interface AsideItemProps {
  description: Maybe<string> | undefined;
  createdBy: {
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    userAvatar?: Maybe<UrlInfo> | undefined;
  };
  lastModifiedDate: Maybe<string> | undefined;
  createdDate: Maybe<string> | undefined;
  usageCount: number;
  domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string; color: string } | null> | null;
  permissions?: Maybe<Array<Maybe<PermissionRes>>>;
}

export const AsideContent = ({
  description,
  createdBy,
  lastModifiedDate,
  createdDate,
  usageCount,
  domains,
  permissions
}: AsideItemProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tCompany } = useTranslation('company');
  const { t: tUser } = useTranslation('user');
  const { t: tTemplates } = useTranslation('templates');

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        sx={{
          position: 'relative',
          padding: '0px',
          margin: '0px'
        }}
      >
        <Tab
          label={tCompany('overview.pageTitle')}
          sx={{
            backgroundColor: 'transparent',
            textTransform: 'none',
            color: '#505864',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              textTransform: 'none',
              color: '#121B2A'
            }
          }}
        />
        <Tab
          label={tUser('permissions.pageTitle')}
          sx={{
            backgroundColor: 'transparent',
            textTransform: 'none',
            color: '#505864',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              textTransform: 'none',
              color: '#121B2A'
            }
          }}
        />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <hr
          style={{
            height: '1px',
            background: 'rgba(9, 14, 22, 0.15)',
            position: 'absolute',
            top: '120px',
            left: '0',
            width: '100%'
          }}
        />

        <TableAsideItem title={tGlobal('table.aside.description')} subTitle={description} />

        <TableAsideItem
          title={tGlobal('table.aside.createdBy')}
          subTitle={
            <Box display={'flex'}>
              {createdBy.userAvatar?.url ? (
                <ImageLazy
                  src={createdBy.userAvatar.url}
                  alt={`Equipment Created by ${createdBy.firstName} ${createdBy.lastName} logo`}
                  width={24}
                  height={24}
                  sxStyle={{
                    margin: '0 8px 0 0'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    background: 'rgb(240, 240, 241)',
                    border: '1px solid #F7F8F9'
                  }}
                />
              )}

              <Text variant={'bodyLg'}>
                {createdBy.firstName} {createdBy.lastName}
              </Text>
            </Box>
          }
        />

        <TableAsideItem title={tTemplates('role.table.cells.itemsTemplate')} subTitle={usageCount} />

        {lastModifiedDate && (
          <TableAsideItem
            title={tTemplates('role.table.cells.lastModified')}
            subTitle={
              <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
                {dayjs(lastModifiedDate).format('DD MMM YYYY, HH:mm A')}
              </Text>
            }
          />
        )}

        {createdDate && (
          <TableAsideItem
            title={tTemplates('detailsRole.details.mainInfo.createdBy')}
            subTitle={
              <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
                {dayjs(createdDate).format('DD MMM YYYY, HH:mm A')}
              </Text>
            }
          />
        )}
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <hr
          style={{
            height: '1px',
            background: 'rgba(9, 14, 22, 0.15)',
            position: 'absolute',
            top: '120px',
            left: '0',
            width: '100%'
          }}
        />
        <Box sx={{ marginTop: '20px' }}>
          {asidePermissions(permissions).map((value, id) => (
            <AsidePermission key={id} {...value} type={value.type as 'VIEW' | 'EDIT' | 'RESTRICTED'} />
          ))}

          {Boolean(domains?.length) && (
            <TableAsideItem
              title={tGlobal('table.cells.domains')}
              subTitle={
                <Flex flexWrap="wrap">
                  {domains?.map((domain, index) => (
                    <Box key={index} sx={{ marginRight: '8px' }}>
                      <DomainStatusChip color={domain?.color} label={domain?.name} />
                    </Box>
                  ))}
                </Flex>
              }
            />
          )}
        </Box>
      </TabPanel>
    </Box>
  );
};
