export const EDIT_BUTTON_CLASS = 'edit-domain-button';

export enum EditLocationMenuListNamesEnum {
  EDIT = 'edit',
  FAVORITES = 'favorites',
  ARCHIVE = 'archive'
}

interface MenuList {
  id: number;
  name: string;
  disabled?: boolean;
}

export const menuList: MenuList[] = [
  {
    id: 1,
    name: EditLocationMenuListNamesEnum.EDIT,
    disabled: false
  },
  {
    id: 2,
    name: EditLocationMenuListNamesEnum.ARCHIVE,
    disabled: true
  },
  {
    id: 3,
    name: EditLocationMenuListNamesEnum.FAVORITES,
    disabled: true
  }
];
