import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function FolderIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M3.58979 16.25C3.16885 16.25 2.81254 16.1041 2.52087 15.8125C2.22921 15.5208 2.08337 15.1645 2.08337 14.7435V5.25642C2.08337 4.83547 2.22921 4.47917 2.52087 4.1875C2.81254 3.89583 3.16885 3.75 3.58979 3.75H7.54169C7.74253 3.75 7.93564 3.789 8.121 3.867C8.30636 3.94499 8.46742 4.05235 8.60417 4.1891L9.83173 5.41667H16.4102C16.8312 5.41667 17.1875 5.5625 17.4792 5.85417C17.7708 6.14583 17.9167 6.50214 17.9167 6.92308V14.7435C17.9167 15.1645 17.7708 15.5208 17.4792 15.8125C17.1875 16.1041 16.8312 16.25 16.4102 16.25H3.58979ZM3.58979 15H16.4102C16.485 15 16.5465 14.9759 16.5946 14.9279C16.6426 14.8798 16.6667 14.8183 16.6667 14.7435V6.92308C16.6667 6.84829 16.6426 6.78685 16.5946 6.73877C16.5465 6.69069 16.485 6.66665 16.4102 6.66665H9.32054L7.72598 5.0721C7.69927 5.0454 7.67123 5.02669 7.64185 5.016C7.61246 5.00532 7.58174 4.99998 7.54969 4.99998H3.58979C3.515 4.99998 3.45356 5.02402 3.40548 5.0721C3.3574 5.12019 3.33335 5.18163 3.33335 5.25642V14.7435C3.33335 14.8183 3.3574 14.8798 3.40548 14.9279C3.45356 14.9759 3.515 15 3.58979 15Z" />
    </SvgIcon>
  );
}
