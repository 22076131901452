import { ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

type TableAsideItemProps = {
  title?: string;
  subTitle?: ReactNode;
  isShowTitle?: boolean;
};

export const TableAsideItem = ({ title, subTitle, isShowTitle = true }: TableAsideItemProps) => {
  return subTitle ? (
    <Box sx={{ margin: '20px 0 32px' }}>
      {isShowTitle && title ? (
        <Text variant={'labelSm'} sx={{ color: 'var(--text-subtle, #505668)' }}>
          {title}
        </Text>
      ) : null}

      <Text className={'m-0'} component={'div'} variant={'bodyLg'}>
        {subTitle}
      </Text>
    </Box>
  ) : null;
};
