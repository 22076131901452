import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import { useTranslation } from '@/i18n';

interface ModalsProps {
  companyName: string;
  isModalOpen: boolean;
  isDisabled: boolean;
  setIsModalOpen: (open: boolean) => void;
  handleDisableCompany: () => void;
}

export const Modals = ({ companyName, isModalOpen, isDisabled, setIsModalOpen, handleDisableCompany }: ModalsProps) => {
  const { t: tCompany } = useTranslation('company');

  const modalTitle = isDisabled
    ? tCompany('settings.modal.toggleCompany.enable.title')
    : tCompany('settings.modal.toggleCompany.disable.title');
  const modalHtml = isDisabled
    ? tCompany('settings.modal.toggleCompany.enable.contentFirst', { value: companyName })
    : tCompany('settings.modal.toggleCompany.disable.contentFirst', { value: companyName });

  const modalContent = isDisabled
    ? tCompany('settings.modal.toggleCompany.enable.contentSecond')
    : tCompany('settings.modal.toggleCompany.disable.contentSecond');

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleToggleCompany = () => {
    handleDisableCompany?.();
  };

  return (
    <>
      <Dialog
        headerTitle={modalTitle}
        contentFooter={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleClose}>
              {tCompany('settings.modal.toggleCompany.btnCancel')}
            </Button>
            <Button className={'my-0'} variant={'contained'} onClick={handleToggleCompany}>
              {tCompany('settings.modal.toggleCompany.btnConfirm')}
            </Button>
          </div>
        }
        hideHeaderBorder
        open={isModalOpen}
        onClose={handleClose}
      >
        <Text
          className={'mb-0'}
          variant={'bodyLg'}
          sx={{ color: 'var(--text-default, #090E16)' }}
          dangerouslySetInnerHTML={{
            __html: modalHtml
          }}
        />
        <Text variant={'bodyLg'} sx={{ color: 'var(--text-default, #090E16)' }}>
          {modalContent}
        </Text>
      </Dialog>
    </>
  );
};
