import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DriveFileMoveIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M4.3077 19.5C3.80257 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6923V6.3077C2.5 5.80257 2.675 5.375 3.025 5.025C3.375 4.675 3.80257 4.5 4.3077 4.5H9.04998C9.29099 4.5 9.52272 4.5468 9.74515 4.6404C9.96758 4.73398 10.1609 4.86283 10.325 5.02693L11.798 6.5H19.6923C20.1974 6.5 20.625 6.675 20.975 7.025C21.325 7.375 21.5 7.80257 21.5 8.3077V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.3077ZM4.3077 18H19.6923C19.782 18 19.8557 17.9711 19.9134 17.9134C19.9711 17.8557 20 17.782 20 17.6923V8.3077C20 8.21795 19.9711 8.14422 19.9134 8.08652C19.8557 8.02883 19.782 7.99998 19.6923 7.99998H11.1846L9.27113 6.08652C9.23908 6.05447 9.20543 6.03203 9.17018 6.0192C9.13491 6.00638 9.09804 5.99998 9.05958 5.99998H4.3077C4.21795 5.99998 4.14423 6.02882 4.08653 6.08652C4.02883 6.14423 3.99998 6.21795 3.99998 6.3077V17.6923C3.99998 17.782 4.02883 17.8557 4.08653 17.9134C4.14423 17.9711 4.21795 18 4.3077 18ZM12.7962 13.75L11.5539 14.9923C11.4154 15.1308 11.3446 15.3048 11.3414 15.5144C11.3382 15.724 11.409 15.9012 11.5539 16.0461C11.6987 16.191 11.8744 16.2634 12.0808 16.2634C12.2872 16.2634 12.4628 16.191 12.6077 16.0461L15.0211 13.6327C15.2019 13.4519 15.2923 13.241 15.2923 13C15.2923 12.759 15.2019 12.5481 15.0211 12.3673L12.6077 9.95385C12.4692 9.81538 12.2952 9.74455 12.0856 9.74135C11.8759 9.73815 11.6987 9.80898 11.5539 9.95385C11.409 10.0987 11.3366 10.2744 11.3366 10.4808C11.3366 10.6872 11.409 10.8628 11.5539 11.0077L12.7962 12.25H9.09613C8.88333 12.25 8.70513 12.3218 8.56153 12.4654C8.41794 12.609 8.34615 12.7872 8.34615 13C8.34615 13.2128 8.41794 13.391 8.56153 13.5346C8.70513 13.6782 8.88333 13.75 9.09613 13.75H12.7962Z" />
    </SvgIcon>
  );
}
