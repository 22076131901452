import { FC, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import { ColumnIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { TableDropdown } from '../TableDropdown';
import { TableMenuItem } from '../TableMenuItem';

import { TableDropdownProps, TableMenuOption } from '../../helpers';

interface TableDropdownColumnsVisibilityProps
  extends Omit<TableDropdownProps, 'children' | 'onOpen' | 'onChange' | 'anchorEl' | 'onClose' | 'title'> {
  title?: ReactNode;
  menu: TableMenuOption[];
  defaultChecked?: boolean;
  toggleAllColumnsVisible: (value: boolean) => void;
  onSwitchClick?: (option: TableMenuOption) => void;
}

export const TableDropdownColumnsVisibility: FC<TableDropdownColumnsVisibilityProps> = ({
  id,
  title,
  searchPlaceholder = 'Search',
  menu,
  withSearch = false,
  disabled,
  renderFooter,
  onSwitchClick,
  toggleAllColumnsVisible
}) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSearchFilter('');
  };

  const handleItemClick = (option: TableMenuOption) => {
    onSwitchClick?.(option);
  };

  const handleShowAll = () => {
    handleMenuClose();
    toggleAllColumnsVisible(true);
  };

  const filteredMenuList = useMemo(() => {
    return menu.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [menu, searchFilter]);

  const renderFooterDefault = useCallback(() => {
    return (
      <Button size={'small'} variant={'outlined'} onClick={handleShowAll}>
        {tGlobal('filterDropdown.btnShowAll')}
      </Button>
    );
  }, []);

  const hiddenColumns = filteredMenuList.filter((i) => !i.checked);

  const RenderFooter = renderFooter || renderFooterDefault;
  const RenderTitle =
    title ||
    tGlobal('table.filters.hiddenColumns.title', {
      value: hiddenColumns?.length
    });

  return (
    <TableDropdown
      id={id}
      title={RenderTitle}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchFilter}
      anchorEl={anchorEl}
      disabled={disabled}
      withSearch={withSearch}
      renderFooter={RenderFooter}
      btnIcon={<ColumnIcon color={disabled ? 'disabled' : undefined} sx={{ margin: '0 4px 0 0' }} />}
      onChangeSearch={setSearchFilter}
      onOpen={handleMenuOpen}
      onClose={handleMenuClose}
    >
      <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
        {filteredMenuList.map((option) => {
          return (
            <TableMenuItem
              key={String(option.value)}
              onClick={() => {
                handleItemClick(option);
                option?.onToggleVisibility?.(!option.checked);
              }}
            >
              <Switch size={'small'} checked={!option.checked} />

              <Text variant={'bodyMd'} sx={{ margin: '0 0 0 4px' }}>
                {option.label}
              </Text>
            </TableMenuItem>
          );
        })}
      </Box>
    </TableDropdown>
  );
};
