import { ContextConsumer } from './ContextConsumer';
import { ContextProvider } from './ContextProvider';

export const TemplatesParametersCreatePage = () => {
  return (
    <ContextProvider>
      <ContextConsumer />
    </ContextProvider>
  );
};
