import { initReactI18next, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import companiesEN from './locales/en/companies.json';
import companyEN from './locales/en/company.json';
import equipmentEN from './locales/en/equipment.json';
import errorsEN from './locales/en/errors.json';
import globalEN from './locales/en/global.json';
import homeEN from './locales/en/home.json';
import locationsEN from './locales/en/locations.json';
import overviewEN from './locales/en/overview.json';
import profileEN from './locales/en/profile.json';
import securityEN from './locales/en/security.json';
import sidebarEN from './locales/en/sidebar.json';
import tasksEN from './locales/en/tasks.json';
import templatesEN from './locales/en/templates.json';
import userEN from './locales/en/user.json';
import usersEN from './locales/en/users.json';

const resources = {
  en: {
    companies: companiesEN,
    company: companyEN,
    errors: errorsEN,
    global: globalEN,
    overview: overviewEN,
    profile: profileEN,
    security: securityEN,
    sidebar: sidebarEN,
    users: usersEN,
    user: userEN,
    templates: templatesEN,
    locations: locationsEN,
    equipment: equipmentEN,
    tasks: tasksEN,
    home: homeEN
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  });

export { i18n, useTranslation };
