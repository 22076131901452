import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function RocketIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_25_12980)">
          <path
            d="M86.6394 54.5029C85.498 52.597 83.3766 51.5029 81.1613 51.6713L33.5465 55.3494C28.7258 55.722 24.4317 58.2486 21.7645 62.2821L1.78228 92.5053C-0.239969 95.5643 -0.561841 99.392 0.921741 102.745C2.40493 106.098 5.45374 108.435 9.07676 108.996L47.6858 114.976C47.9862 115.022 48.2862 115.045 48.5839 115.045C50.5616 115.045 52.4327 114.042 53.5198 112.342L86.5496 60.6689C87.7461 58.7966 87.7804 56.4091 86.6394 54.5029Z"
            fill="#F87171"
          />
          <path
            d="M145.497 113.36C143.591 112.219 141.203 112.254 139.331 113.45L87.6589 146.48C85.7023 147.731 84.6691 150.019 85.0242 152.314L91.0038 190.923C91.5652 194.546 93.9019 197.595 97.2554 199.078C98.6499 199.695 100.126 199.999 101.596 199.999C103.659 199.999 105.708 199.399 107.495 198.218L137.718 178.235C141.752 175.569 144.279 171.274 144.651 166.453L148.329 118.839C148.5 116.623 147.403 114.502 145.497 113.36Z"
            fill="#EF4444"
          />
          <path
            d="M75.34 161.045C77.672 163.377 80.9856 164.468 84.2383 163.924C131.938 155.948 169.683 116.455 184.053 88.6725C200.01 57.8235 200.601 25.2237 199.767 9.9348C199.481 4.69577 195.305 0.519635 190.066 0.233699C174.777 -0.599888 142.177 -0.00887655 111.328 15.9476C83.5462 30.3174 44.0527 68.0629 36.077 115.762C35.5332 119.015 36.6239 122.329 38.9559 124.661L75.34 161.045Z"
            fill="#F1F1FB"
          />
          <path
            d="M196.772 3.22705L57.1465 142.853L75.3385 161.045C77.6705 163.377 80.9842 164.468 84.2369 163.924C131.937 155.948 169.682 116.455 184.052 88.6729C200.008 57.8239 200.599 25.2241 199.765 9.9352C199.622 7.31569 198.507 4.9618 196.772 3.22705Z"
            fill="#D7D6FB"
          />
          <path
            d="M137.004 92.2798C129.501 92.2802 122 89.4247 116.288 83.7126C110.755 78.1795 107.707 70.8221 107.707 62.9968C107.707 55.1715 110.755 47.8141 116.288 42.2809C127.71 30.8584 146.297 30.858 157.72 42.2809C163.253 47.8141 166.3 55.1715 166.3 62.9968C166.3 70.8221 163.253 78.1795 157.72 83.7126C152.009 89.4235 144.505 92.279 137.004 92.2798Z"
            fill="#466288"
          />
          <path
            d="M36.077 115.762C35.5332 119.015 36.6239 122.329 38.9559 124.661L75.34 161.045C77.672 163.377 80.9856 164.467 84.2383 163.924C94.6175 162.188 104.524 158.959 113.823 154.685L45.316 86.1782C41.0414 95.4762 37.8125 105.384 36.077 115.762Z"
            fill="#466288"
          />
          <path
            d="M5.97832 164.818C7.47754 164.818 8.97754 164.246 10.1213 163.102L29.2514 143.972C31.5396 141.684 31.5396 137.974 29.2514 135.685C26.9635 133.397 23.2537 133.397 20.965 135.685L1.83535 154.816C-0.45293 157.103 -0.45293 160.814 1.83535 163.102C2.97949 164.246 4.47871 164.818 5.97832 164.818Z"
            fill="#FDBA74"
          />
          <path
            d="M46.7834 153.217C44.4955 150.929 40.7854 150.929 38.4971 153.217L1.71816 189.996C-0.570117 192.284 -0.570117 195.994 1.71816 198.283C2.8623 199.427 4.36191 199.999 5.86152 199.999C7.36113 199.999 8.86074 199.426 10.0045 198.282L46.7834 161.503C49.0717 159.215 49.0717 155.505 46.7834 153.217Z"
            fill="#FDBA74"
          />
          <path
            d="M64.3139 170.749C62.026 168.461 58.3158 168.461 56.0275 170.749L36.8979 189.879C34.6096 192.167 34.6096 195.877 36.8979 198.165C38.042 199.309 39.5416 199.882 41.0408 199.882C42.54 199.882 44.04 199.309 45.1838 198.165L64.3139 179.035C66.6021 176.747 66.6021 173.037 64.3139 170.749Z"
            fill="#F97316"
          />
          <path
            d="M1.7168 198.283C2.86094 199.427 4.36055 199.999 5.86016 199.999C7.35977 199.999 8.85938 199.427 10.0031 198.283L46.782 161.504C49.0703 159.216 49.0703 155.506 46.782 153.217L1.7168 198.283Z"
            fill="#F97316"
          />
          <path
            d="M57.1465 142.853L75.3385 161.045C77.6705 163.377 80.9842 164.468 84.2369 163.924C94.6161 162.188 104.523 158.959 113.821 154.685L79.5682 120.432L57.1465 142.853Z"
            fill="#354A67"
          />
          <path
            d="M116.287 83.7125C121.999 89.4245 129.5 92.2804 137.003 92.2796C144.504 92.2788 152.008 89.423 157.718 83.7125C163.252 78.1789 166.299 70.8219 166.299 62.9966C166.299 55.1713 163.252 47.8139 157.718 42.2808L116.287 83.7125Z"
            fill="#354A67"
          />
          <path
            d="M137.004 45.4272C132.503 45.4272 128.001 47.1405 124.575 50.5675C121.255 53.8874 119.426 58.3015 119.426 62.9968C119.426 67.6921 121.255 72.1062 124.575 75.4261C131.428 82.2796 142.58 82.28 149.434 75.4261C152.754 72.1058 154.582 67.6917 154.582 62.9964C154.582 58.3011 152.754 53.887 149.434 50.5671C146.007 47.1405 141.506 45.4272 137.004 45.4272Z"
            fill="#4BBEFD"
          />
          <path
            d="M149.433 50.5674L124.574 75.4264C131.428 82.2799 142.58 82.2803 149.433 75.4264C152.753 72.1061 154.582 67.692 154.582 62.9967C154.582 58.3014 152.754 53.8873 149.433 50.5674Z"
            fill="#0590FB"
          />
        </g>
        <defs>
          <clipPath id="clip0_25_12980">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
