import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import { AddBox } from '@mui/icons-material';
import { Box } from '@mui/material';

import { Flex } from '@/separatedModules/components';

import { useContextState } from '../../../../ContextProvider';

export const AddParameter = () => {
  const { setState } = useContextState();

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  queryParams.set('isRoundTable', 'true');

  return (
    <Flex
      sx={{
        borderRadius: '8px',
        border: '1px dashed var(--border-subtle, rgba(9, 14, 22, 0.30))',
        height: '88px',
        cursor: 'pointer',
        alignItems: 'center',
        padding: '0px 20px',
        width: '100%'
      }}
      onClick={() => {
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        setState((state) => ({ ...state, currentStep: state.currentStep - 1 }));
      }}
    >
      <AddBox sx={{ width: '48px', height: '48px', fill: '#DCDFE4' }} />
      <Box sx={{ marginLeft: '16px' }}>
        <Text sx={{ fontSize: '16px', fontWeight: '600' }}>Add parameter</Text>
        <Text sx={{ fontSize: '14px', fontWeight: '400', color: 'var(--text-subtle, #505668)' }}>
          Include another parameter to the round regulation
        </Text>
      </Box>
    </Flex>
  );
};
