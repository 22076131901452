import { ReactNode } from 'react';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { SingleImageFileT } from '@/subsidiaryBinders/types';

import { ImageUploaderI } from '../SingleImageUploader';

import { RenderUploaderButtonI } from './components/UploaderButton';

export interface ImageUploaderProps extends ImageUploaderI, RenderUploaderButtonI {
  path: string;
  oldImageUrl: string;
  oldImageFileKey: string;
  imgAlt: string;
  modalTitle: string;
  modalTitleToDelete: string;
  modalSubTitle?: string;
  btnUploadPhotoText?: string;
  uploaderButtonContent?: ReactNode;
  modalContent?: ReactNode;
  titleContent: ReactNode;
  withPreview?: boolean;
  isOpen?: boolean;
  isVisibleDeleteBtn?: boolean;
  onImageUploadError: () => void;
  onImageDelete: () => void;
  onImageUploadSuccess: (imageKey: string, imageUrl: string) => void;
  isDisabled?: boolean;
}

export interface ImageUploaderStateI {
  preview: string;
  oldImageUrl: string;
  oldImageFileKey: string;
  file?: SingleImageFileT;
  oldFile?: SingleImageFileT;
  error: {
    isValid: boolean;
    message: string;
  };
  isClickedSaveBtn: boolean;
  isOpen: boolean;
  isUpload: boolean;
  isModalOpen: boolean;
  isWantToDelete: boolean;
  isWantToChange: boolean;
  isOnDrag: boolean;
}

export const INIT_STATE: ImageUploaderStateI = {
  preview: '',
  oldImageFileKey: '',
  oldImageUrl: '',
  file: {
    blob: null,
    name: '',
    type: '',
    base64: '',
    preview: ''
  },
  oldFile: {
    blob: null,
    name: '',
    type: '',
    base64: '',
    preview: ''
  },
  error: {
    isValid: true,
    message: ''
  },
  isClickedSaveBtn: false,
  isOpen: false,
  isUpload: false,
  isOnDrag: false,
  isModalOpen: false,
  isWantToDelete: false,
  isWantToChange: false
};

export const getImageDimensions = async (objectURL: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const image = new Image();

    let imageWidth = 0;
    let imageHeight = 0;

    image.onload = function handleLoad() {
      imageWidth = image.width;
      imageHeight = image.height;

      resolve({
        width: imageWidth,
        height: imageHeight
      });
    };

    image.onerror = (e) => {
      // ToDo Remove
      console.log('getImageDimensions onerror - ', e);

      resolve({
        width: 0,
        height: 0
      });
    };

    image.src = objectURL;
  });
};

export const showAlertErrorMessage = (message: string) => {
  AlertService.showAlert({
    title: message,
    severity: 'error',
    autoHideDuration: 3000
  });
};

export const showAlertSuccessMessage = (message: string) => {
  AlertService.showAlert({
    title: message,
    severity: 'success',
    autoHideDuration: 3000
  });
};
