import merge from 'lodash/merge';

import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

import { Base, Project } from './style';

const newStyleOverrides: Record<string, any> = merge(Base, Project);

export const MuiTypography: {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides['MuiTypography'];
  variants?: ComponentsVariants['MuiTypography'];
} = {
  defaultProps: {
    variantMapping: {
      // Map the new variant to render a <h1> by default
      titleXl: 'h4',
      titleLg: 'h4',
      titleMd: 'h4',
      titleSm: 'h4',
      bodyLg: 'p',
      bodyMd: 'p',
      bodySm: 'p',
      labelLg: 'p',
      labelMd: 'p',
      labelSm: 'p',
      detail: 'p'
    }
  },
  styleOverrides: {
    ...newStyleOverrides
  }
};
