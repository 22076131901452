export const TOOLBAR_BUTTONS = {
  moreText: {
    buttons: [
      'bold',
      'italic',
      'underline'
      // 'strikeThrough',
      // 'subscript',
      // 'superscript',
      // 'fontFamily',
      // 'fontSize',
      // 'textColor',
      // 'backgroundColor',
      // 'inlineClass',
      // 'inlineStyle',
      // 'clearFormatting'
    ]
  },
  moreParagraph: {
    buttons: [
      'alignLeft',
      'alignCenter',
      'formatOLSimple',
      'alignRight',
      // 'alignJustify',
      // 'formatOL',
      'formatUL',
      'paragraphFormat'
      // 'paragraphStyle',
      // 'lineHeight',
      // 'outdent',
      // 'indent',
      // 'quote'
    ],
    buttonsVisible: 6
  },
  moreRich: {
    buttons: [
      'insertLink',
      // 'insertImage',
      // 'insertVideo',
      'insertTable'
      // 'emoticons',
      // 'fontAwesome',
      // 'specialCharacters',
      // 'embedly',
      // 'insertFile',
      // 'insertHR'
    ]
  },
  moreMisc: {
    // buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
    buttons: ['undo', 'redo', 'fullscreen'],
    align: 'right',
    buttonsVisible: 3
  }
};

export const PLUGINS_ENABLED = [
  'table',
  // 'spell',
  // 'quote',
  // 'save',
  'quickInsert',
  'paragraphFormat',
  // 'paragraphStyle',
  // 'help',
  // 'draggable',
  // 'align',
  'link',
  'lists'
  // 'file',
  // 'image',
  // 'emoticons',
  // 'url',
  // 'video',
  // 'embedly',
  // 'colors',
  // 'entities',
  // 'inlineClass',
  // 'inlineStyle',
  // 'codeBeautify'
  // 'spellChecker',
  // 'imageTUI'
];
