import { useSelector } from 'react-redux';

import { selectUserData } from '@/logicLayers/infrastructure/redux';

import { useIsUserCompanyAdmin } from '@/utils';

import { LINKS_HEADER_MENU } from '@/subsidiaryBinders/constants';

import { HeaderMenuItem } from '../HeaderMenuItem';

import { StyledHeaderMenu } from './style';

export const HeaderMenu = () => {
  const isUserCompanyAdmin = useIsUserCompanyAdmin();
  const { profile } = useSelector(selectUserData);

  const sorted = LINKS_HEADER_MENU(isUserCompanyAdmin, profile.permissions)
    .filter((item) => !item.isHidden)
    .sort(({ order: orderA }, { order: orderB }) => {
      return orderA - orderB;
    });

  return (
    <StyledHeaderMenu>
      {sorted.map((menuItem) => {
        return <HeaderMenuItem key={menuItem.id} {...menuItem} />;
      })}
    </StyledHeaderMenu>
  );
};
