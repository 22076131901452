import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ManagementWrapper {
  isOpen: boolean;
}

export const ManagementWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isOpen'
})<ManagementWrapper>(({ isOpen }) => ({
  height: '32px',
  overflow: isOpen ? 'hidden' : 'visible',
  background: isOpen ? '#F1F2F4' : 'none'
}));

export const ActionNodeIconButton = styled(IconButton)(() => ({
  ':disabled': {
    '.MuiSvgIcon-root': {
      fill: '#090E164D'
    }
  }
}));
