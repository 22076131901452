import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import { GET_LOCATIONS_SIDEBAR, GetLocationsSidebarQuery } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';
import { NavListSimple } from '@/separatedModules/layouts/components/SideBarProvider/components/NavListSimple';

import { CompanyIcon, MuiTreeView } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES, LINKS_SIDEBAR_LOCATIONS_MENU } from '@/subsidiaryBinders/constants';

import {
  AddLocationModal,
  ArchiveLocationModal,
  canCreateSubfolder,
  DisableLocationModal,
  LocationActionsMenu,
  LocationActionTypeEnum,
  LocationFolder,
  MoveLocationModal,
  RenameLocationModal
} from './components/LocationActions';

interface ModalState {
  isOpen: boolean;
  action?: LocationActionTypeEnum;
  nodeId?: string;
  locationName?: string;
}

export const SideBarLocations = () => {
  const navigateTo = useNavigate();
  const { folderId } = useParams<{ folderId?: string }>();
  const { currentLocationFolderData, setCurrentLocationFolderData } = useLocations();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    action: undefined,
    nodeId: undefined,
    locationName: undefined
  });

  const { data: dataLocations, loading: loadingLocations } = useQuery<GetLocationsSidebarQuery>(GET_LOCATIONS_SIDEBAR, {
    variables: {
      searchCriteria: {
        pageable: {
          page: 0,
          pageSize: 100
        },
        isArchive: false
      }
    }
  });

  const handleLocationClick = useCallback(
    (nodeId: string) => {
      if (nodeId === 'root') {
        setCurrentLocationFolderData({
          folderId: nodeId,
          canCreateFolder: true
        });
      } else {
        const canCreate = canCreateSubfolder(
          nodeId,
          dataLocations?.locationFolders?.items.filter((item) => item != null) as LocationFolder[]
        );

        setCurrentLocationFolderData({
          folderId: nodeId,
          canCreateFolder: canCreate
        });
      }

      navigateTo(nodeId === 'root' ? LINKS_PAGES.locations : `${LINKS_PAGES.locations}/${nodeId}`);
    },
    [dataLocations, setCurrentLocationFolderData]
  );

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenModal = (action: LocationActionTypeEnum) => {
    setAnchorEl(null);
    setModalState((prevState) => ({
      ...prevState,
      isOpen: true,
      action: action
    }));
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      action: undefined,
      nodeId: undefined,
      locationName: undefined
    });
  };

  useEffect(() => {
    if (dataLocations) {
      const currentFolder = dataLocations.locationFolders.items.find(
        (folder: any) => folder.id === currentLocationFolderData.folderId
      );

      const canCreate = canCreateSubfolder(
        currentLocationFolderData.folderId || '',
        dataLocations?.locationFolders?.items.filter((item) => item != null) as LocationFolder[]
      );

      if (currentFolder) {
        setCurrentLocationFolderData({
          ...currentLocationFolderData,
          // @ts-ignore TODO: Fix type
          isActive: currentFolder?.active,
          canCreateFolder: canCreate
        });
      }
    }
  }, [
    dataLocations,
    setCurrentLocationFolderData,
    currentLocationFolderData.folderId,
    currentLocationFolderData.isActive
  ]);

  return (
    <Sidebar>
      {loadingLocations ? (
        <SideBarGroup>
          <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={32} animation={'wave'} />
        </SideBarGroup>
      ) : null}

      {dataLocations?.locationFolders ? (
        <SideBarGroup>
          <MuiTreeView
            tree={{
              id: 'root',
              name: 'Locations',
              // @ts-ignore TODO: Fix type
              groupItems: dataLocations.locationFolders
            }}
            defaultExpanded={['root']}
            rootItemIcon={<CompanyIcon />}
            onNodeSelect={handleLocationClick}
            onNodeActionClick={(event, node) => {
              setModalState((prevState) => ({
                ...prevState,
                nodeId: node.id,
                locationName: node.name
              }));
              handleClickButton(event);
            }}
            selected={folderId ?? 'root'}
            isDisabled={!isUserCanCRUD}
          />

          <LocationActionsMenu
            anchorEl={anchorEl}
            folderId={modalState.nodeId}
            onClose={() => setAnchorEl(null)}
            onModalOpen={(action) => handleOpenModal(action)}
          />
        </SideBarGroup>
      ) : null}

      <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))', margin: '8px 0' }} />

      <SideBarGroup>
        <NavListSimple menu={LINKS_SIDEBAR_LOCATIONS_MENU} />
      </SideBarGroup>

      {modalState.isOpen && modalState.nodeId ? (
        <>
          {modalState.action === LocationActionTypeEnum.RenameLocation ? (
            <RenameLocationModal isModalOpen folderId={modalState.nodeId} onCloseModal={handleCloseModal} />
          ) : null}

          {modalState.action === LocationActionTypeEnum.CreateLocation ? (
            <AddLocationModal isModalOpen folderId={modalState.nodeId} onCloseModal={handleCloseModal} />
          ) : null}

          {modalState.action === LocationActionTypeEnum.Deactivate ||
          modalState.action === LocationActionTypeEnum.Activate ? (
            <DisableLocationModal
              isModalOpen
              folderId={modalState.nodeId}
              locationName={modalState.locationName}
              variant={modalState.action}
              onCloseModal={handleCloseModal}
            />
          ) : null}

          {modalState.action === LocationActionTypeEnum.Archive ? (
            <ArchiveLocationModal
              isModalOpen
              folderId={modalState.nodeId}
              locationName={modalState.locationName}
              onCloseModal={handleCloseModal}
            />
          ) : null}

          {modalState.action === LocationActionTypeEnum.Move ? (
            <MoveLocationModal
              isModalOpen
              folderId={modalState.nodeId}
              locationName={modalState.locationName}
              onCloseModal={handleCloseModal}
            />
          ) : null}
        </>
      ) : null}
    </Sidebar>
  );
};
