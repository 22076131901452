import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';

import { EquipmentQuery, GET_EQUIPMENT, GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import {
  Breadcrumbs,
  ChevronRightIcon,
  Flex,
  handleGetSavedColumns,
  handleSetSavedColumns,
  MuiTablePagination,
  NotFoundScreens,
  SelectsDropdown,
  SkeletonTableLoader,
  SORT_BY_ROUND_DATE_CREATE,
  TableAside,
  TableDropdownCheckbox,
  TableDropdownColumnsVisibility,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItemWithKeyLabel
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { RoundRegulationsAsideContent } from './components/RoundRegulationsAsideContent';

import {
  columns,
  createStatuses,
  initState,
  ParametersTypesList,
  skeletonTableLoaderCells,
  useTableData
} from './helpers';

export const TemplatesEquipmentItemRoundsRoundRegulationsPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();
  const { renderCount, renderItems, ...tableState } = useTableData(initState, templateId);
  const navigateTo = useNavigate();
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);
  const TABLE_SAVED_COLUMNS_ID = `templates/equipment/item/${templateId}/rounds/round-regulations`;

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;

  const menuList = useMemo(() => createStatuses(), []);

  const breadcrumbsTemplatesLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { titleFallback: dataEquipmentTemplate?.equipment.name }
  ];

  const breadcrumbsEquipmentLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemAllLocations') },
    {
      link: LINKS_PAGES.equipmentModel.replace(':templateId', templateId),
      titleFallback: dataEquipmentTemplate?.equipment?.name
    },
    { titleFallback: tTemplates('equipment.pageItemRoundRegulations') }
  ];

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsTemplatesLinks : breadcrumbsEquipmentLinks;

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const handleClick = () => {
    templateId && navigateTo(LINKS_PAGES.templatesRoundRegulationCreate.replace(':templateId', templateId));
  };

  const columnsVisibility = useMemo(() => {
    const columnsVisibilityStatus = table.getAllLeafColumns().reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.id]: currentValue.getIsVisible() };
    }, {});

    handleSetSavedColumns(TABLE_SAVED_COLUMNS_ID, columnsVisibilityStatus);

    return table.getAllLeafColumns().map((column) => {
      return {
        value: column.id,
        label: i18n.t(`equipmentItem.parameters.table.cells.${column.id}`, { ns: 'templates' }),
        checked: column.getIsVisible(),
        onToggleVisibility: column.toggleVisibility
      };
    });
  }, [columnVisibility]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <Breadcrumbs
          links={breadcrumbs}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />
        <TablePageTopBox
          title={'Round regulations'}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          btnText={'Create regulation'}
          onClickBtn={handleClick}
          isHideButton={!isShowEditItem || !isUserCanCRUD}
        />

        <TableFiltersBox
          searchPlaceholder={'Search by Round regulation title or Service staff'}
          selectedItems={tableState.state.selectedItems}
          handleToggleAside={tableState.handleToggleAside}
          handleChangeBySearch={tableState.handleChangeBySearch}
        >
          <TableDropdownColumnsVisibility
            menu={columnsVisibility}
            toggleAllColumnsVisible={table.toggleAllColumnsVisible}
          />

          <TableDropdownCheckbox
            // ToDo Refactor
            title={'Frequency'}
            menuList={ParametersTypesList}
            withSelectAll
            withSearch
            searchPlaceholder="Search frequency"
            selectAllTitle="Show all"
            onChange={(value) => {
              tableState.handleChangeByFrequencies(value);
            }}
          />

          <SelectsDropdown
            id={'equipment-items-sort-by-status'}
            title={'Status'}
            menuList={menuList}
            isRound
            onChange={(value) => {
              if (value.length) {
                return tableState.handleChangeByStatus(value[0].value as boolean);
              }

              tableState.handleChangeByStatus(undefined);
            }}
          />

          <TableSortByOneItemWithKeyLabel
            menu={SORT_BY_ROUND_DATE_CREATE}
            showIcon={true}
            onChange={(value) => {
              tableState.handleChangeSortByDate(value);
            }}
          />
        </TableFiltersBox>
        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : renderCount || tableState.state.filters?.search.length ? (
          renderItems ? (
            <Flex>
              <Box width={'100%'}>
                <Box sx={{ overflow: 'auto' }}>
                  <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                    <Table className={'m-0 MuiTable-root__columns-5'}>
                      <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <TableCell key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {table.getRowModel().rows.map((row) => {
                          return (
                            <TableRowWrap
                              key={row.original.id}
                              id={row.original.id as string}
                              isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                              handleClickRow={tableState.handleClickRow}
                              handleDoubleClickRow={tableState.handleDoubleClickRow}
                            >
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </TableCell>
                                );
                              })}
                            </TableRowWrap>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <MuiTablePagination
                  count={renderCount}
                  page={tableState.state.page[0]}
                  rowsPerPage={tableState.state.rowsPerPage[0]}
                  rowsPerPageOptions={[20, 50, 100]}
                  onChangePage={tableState.handleChangePagination}
                  onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                />
              </Box>
              <TableAside
                rows={table.getRowModel().rows}
                selectedItems={tableState.state.selectedItems}
                isAsideOpen={tableState.state.isAsideOpen}
                handleToggleAside={tableState.handleToggleAside}
                sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {(props: any) => <RoundRegulationsAsideContent {...props} />}
              </TableAside>
            </Flex>
          ) : (
            <NotFoundScreens type={'search'} />
          )
        ) : (
          <NotFoundScreens
            type={'companies'}
            title={'No round regulations yet'}
            subTitle={'Click “Create regulation" to add round regulation parameter'}
          />
        )}
      </Grid>
    </Grid>
  );
};
