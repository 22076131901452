import { ReactNode } from 'react';
import classNames from 'classnames';
import { Text } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material/styles';

import { StyledBlockInfoWithEdit } from './style';

interface BlockInfoWithEditItemProps {
  title?: ReactNode;
  label: ReactNode;
  subLabel?: ReactNode;
  description?: ReactNode;
  descriptionSecond?: ReactNode;
  sxLabel?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  Edit?: ReactNode;
}

interface BlockInfoWithEditProps {
  children: ReactNode;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const BlockInfoWithEditItem = ({
  title,
  label,
  subLabel,
  description,
  descriptionSecond,
  sxLabel,
  sxDescription,
  Edit
}: BlockInfoWithEditItemProps) => {
  const RenderBox = () => {
    return (
      <>
        {label ? (
          <Text variant={'labelMd'} sx={{ ...sxLabel }}>
            {label}
          </Text>
        ) : null}

        {subLabel ? subLabel : null}

        {description !== null && description !== undefined ? (
          <Text variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)', ...sxDescription }}>
            {description}
          </Text>
        ) : null}

        {descriptionSecond ? (
          <Text variant={'bodyLg'} sx={{ color: '#090E16 !important' }}>
            {descriptionSecond}
          </Text>
        ) : null}
      </>
    );
  };
  return (
    <div className={classNames('block-info__i')}>
      {Edit ? (
        <>
          {title ? <Text variant={'titleMd'}>{title}</Text> : null}

          <div
            className={classNames({
              'block-info__i--row': Edit
            })}
          >
            <div className={'block-info__i__left'}>
              <RenderBox />
            </div>

            <div className={'block-info__i__right'}>{Edit}</div>
          </div>
        </>
      ) : (
        <>
          {title ? <Text variant={'titleMd'}>{title}</Text> : null}

          <RenderBox />
        </>
      )}
    </div>
  );
};

export const BlockInfoWithEdit = ({ children, disabled, sx }: BlockInfoWithEditProps) => {
  return (
    <StyledBlockInfoWithEdit
      sx={sx}
      className={classNames({
        'Mui-disabled': disabled
      })}
    >
      {children}
    </StyledBlockInfoWithEdit>
  );
};
