export const getBreadcrumbs = (data: any) => {
  const breadcrumbParts = data?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  breadcrumbParts.push(data?.name);

  return breadcrumbParts
    .filter((item: string | undefined) => item !== undefined)
    .map((item: string) => ({
      titleFallback: item
    }));
};
