export const Project = {
  tooltip: {
    borderRadius: 'var(--2, 2px)',
    background: 'var(--background-neutral-bold-pressed, #0E1420)',
    padding: '4px',
    color: 'var(--text-inverse, #FFF)',
    fontSize: '12px',
    lineHeight: '16px'
  }
};

export const Base = {
  root: {}
};
