import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from 'infinitytechnologies-ui';
import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { ParameterTypeBox } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter';
import { PARAMETER_TYPE } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter/helpers';
import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components';

import { Flex, ImageLazy } from '@/separatedModules/components';

import { EquipmentParameterItem } from '../../Step4';

interface RemoveParameterModalProps {
  modalTitle: string;
  handleCloseModal: () => void;
  handleModalAction: () => void;
  modalBtnText: string;
  isOpen: boolean;
  modalContent: string;
  parameter: EquipmentParameterItem;
  children?: ReactNode;
  isCondition?: boolean;
}

export const RemoveParameterModal = ({
  handleCloseModal,
  handleModalAction,
  modalBtnText,
  modalTitle,
  isOpen,
  modalContent,
  parameter,
  children,
  isCondition
}: RemoveParameterModalProps) => {
  const { t: tGlobal } = useTranslation('global');

  const paramItemConfig = PARAMETERS_LIST_FULL.find((paramItem) => parameter.type === paramItem.parameterType);

  const isNumeric = parameter.type.includes('NUMERIC');

  return (
    <Dialog
      headerTitle={modalTitle}
      hideHeaderBorder
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className={'my-0'}
            variant={'text'}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleCloseModal}
          >
            {tGlobal('btn.btnCancel')}
          </Button>

          <Button className={'my-0'} variant={'contained'} onClick={handleModalAction}>
            {modalBtnText}
          </Button>
        </div>
      }
      open={isOpen}
      onClose={handleCloseModal}
    >
      <Text
        className={'m-0'}
        variant={'bodyLg'}
        dangerouslySetInnerHTML={{
          __html: modalContent as string
        }}
      />

      <Box sx={{ width: '100%', flex: '0 1 100%' }}>
        <Box
          sx={{
            borderRadius: 'var(--4, 4px)',
            background: 'var(--background-neutral-hovered, #F7F8F9)',
            padding: isCondition ? '16px' : '8px',
            margin: '24px 0 0'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Flex alignItems="center">
              {paramItemConfig?.logoPath ? (
                <ImageLazy
                  src={paramItemConfig?.logoPath}
                  alt={`Equipment item parameter ${paramItemConfig.title} type preview`}
                  variant={'rectangular'}
                  width={32}
                  height={32}
                  sxStyle={{
                    margin: '0 8px 0 0'
                  }}
                />
              ) : null}

              <Text variant={'labelLg'} m={0}>
                {parameter?.title}
              </Text>
            </Flex>

            <Box>
              <ParameterTypeBox title={isNumeric ? 'Numeric data' : PARAMETER_TYPE[parameter.type as any]} />

              {isNumeric ? <ParameterTypeBox title={PARAMETER_TYPE[parameter.type as any]} /> : null}
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};
