import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { Flex, ImageLazy } from '@/separatedModules/components';

import { PermissionType } from './types';

interface AsidePermissionProps {
  title: string;
  type: 'VIEW' | 'EDIT' | 'RESTRICTED';
  image: string;
}

export const AsidePermission = ({ title, type, image }: AsidePermissionProps) => {
  return (
    <Flex marginBottom={'32px'} alignItems="center">
      <Flex minWidth={'32px'} justifyContent="center">
        <ImageLazy alt="" src={image} width={'auto'} height={'auto'} sxStyle={{ borderRadius: '0%' }} />
      </Flex>
      <Box sx={{ marginLeft: '16px' }}>
        <Text sx={{ color: '#505668', fontSize: '12px', fontWeight: '500' }}>{title}</Text>
        <Text sx={{ marginTop: '6px' }}>{PermissionType[type]}</Text>
      </Box>
    </Flex>
  );
};
