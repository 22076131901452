import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LogOutIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M4.42316 17.0834C4.00222 17.0834 3.64591 16.9375 3.35425 16.6459C3.06258 16.3542 2.91675 15.9979 2.91675 15.577V4.42317C2.91675 4.00222 3.06258 3.64591 3.35425 3.35425C3.64591 3.06258 4.00222 2.91675 4.42316 2.91675H9.38308C9.56043 2.91675 9.70893 2.97658 9.82858 3.09625C9.94825 3.2159 10.0081 3.3644 10.0081 3.54175C10.0081 3.7191 9.94825 3.8676 9.82858 3.98725C9.70893 4.1069 9.56043 4.16673 9.38308 4.16673H4.42316C4.35905 4.16673 4.30028 4.19344 4.24685 4.24685C4.19344 4.30028 4.16673 4.35905 4.16673 4.42317V15.577C4.16673 15.6411 4.19344 15.6998 4.24685 15.7533C4.30028 15.8067 4.35905 15.8334 4.42316 15.8334H9.38308C9.56043 15.8334 9.70893 15.8932 9.82858 16.0129C9.94825 16.1325 10.0081 16.281 10.0081 16.4584C10.0081 16.6357 9.94825 16.7842 9.82858 16.9039C9.70893 17.0235 9.56043 17.0834 9.38308 17.0834H4.42316ZM14.6812 10.625H8.20519C8.02784 10.625 7.87934 10.5652 7.75969 10.4456C7.64002 10.3259 7.58019 10.1774 7.58019 10.0001C7.58019 9.82271 7.64002 9.67421 7.75969 9.55456C7.87934 9.43491 8.02784 9.37508 8.20519 9.37508H14.6812L13.0786 7.77252C12.9632 7.65713 12.9042 7.51637 12.9015 7.35025C12.8989 7.18412 12.9579 7.03642 13.0786 6.90714C13.1993 6.77788 13.3457 6.71111 13.5177 6.70683C13.6897 6.70255 13.8403 6.76505 13.9696 6.89433L16.5481 9.47283C16.6987 9.62347 16.7741 9.79921 16.7741 10.0001C16.7741 10.2009 16.6987 10.3767 16.5481 10.5273L13.9696 13.1058C13.8457 13.2297 13.6985 13.2909 13.5281 13.2893C13.3577 13.2877 13.2079 13.2222 13.0786 13.093C12.9579 12.9637 12.8997 12.8152 12.9039 12.6475C12.9082 12.4797 12.9707 12.3355 13.0914 12.2148L14.6812 10.625Z" />
    </SvgIcon>
  );
}
