import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import { GET_EQUIPMENT_ROUND_REGULATION, RoundRegulationQuery } from '@/logicLayers/domain';

import { EditPageWrap, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { RoundEditProvider } from './components';

export const EditRound = () => {
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const editValue = searchParams.get('field') ?? '';

  const { templateId = '', roundRegulationId = '' } = useParams();
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');

  const { data: dataEquipmentTemplateRoundRegulation, loading: isLoading } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId: roundRegulationId || undefined
      }
    }
  );

  const isUsers = editValue === 'roundStaff';
  const isParameters = editValue === 'roundParameters';
  const isRoundTask = location.pathname.includes('roundTask');

  const navigateToUrl = isRoundTask
    ? LINKS_PAGES.tasksDetails.replace(':taskId', roundRegulationId)
    : LINKS_PAGES.templatesEquipmentItemRoundsRoundRegulation
        .replace(':templateId', templateId)
        .replace(':parameterId', roundRegulationId);

  const Component = RoundEditProvider({
    ...dataEquipmentTemplateRoundRegulation,
    /* custom fields */
    editType: editValue,
    navigateToUrl,
    tModel: tTemplates,
    isTask: isRoundTask,
    tGlobal,
    templateId
  });

  const lgValue = isParameters ? 8 : isUsers ? 8 : 6;

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={lgValue} item>
        {isLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
        ) : (
          <EditPageWrap isShowButton={!isParameters} navigateTo={navigateToUrl}>
            {Component}
          </EditPageWrap>
        )}
      </Grid>
    </Grid>
  );
};
