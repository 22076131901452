import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import {
  GET_USER_DETAILED_PERMISSIONS,
  GET_USER_PERMISSIONS_BY_ID,
  GET_USER_ROLE_BY_ID,
  GetUserPermissionsQuery,
  GetUserRoleByIdQuery
} from '@/logicLayers/domain';

import {
  BlockInfoWithEdit,
  Breadcrumbs,
  ChevronRightIcon,
  EditIcon,
  EMPTY_CELL_VALUE,
  Flex,
  PageTitle,
  Preloader,
  PreloaderVariantsE,
  RenderWhen
} from '@/separatedModules/components';

import { useIsUserCanCRUD, useSuperAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { DescriptionRole, DomainsBlock, PermissionsBlock } from './components';

interface UserPermissionsPageProps {}

export const UserPermissionsPage: FC<UserPermissionsPageProps> = () => {
  const { t: tUser } = useTranslation('user');
  const navigateTo = useNavigate();
  const { t: tGlobal } = useTranslation('global');

  const location = useLocation();
  const isShowVisibility = location.pathname.includes('/visibility');

  const { userId = '' } = useParams();

  const { data: dataUser, loading: loadingUser } = useQuery<GetUserPermissionsQuery>(GET_USER_PERMISSIONS_BY_ID, {
    variables: { uid: userId }
  });

  const { data: detailedPermissionsData, loading: detailedPermissionsLoading } = useQuery(
    GET_USER_DETAILED_PERMISSIONS,
    { fetchPolicy: 'no-cache' }
  );

  const { data: dataUserRole, loading: loadingUserRole } = useQuery<GetUserRoleByIdQuery>(GET_USER_ROLE_BY_ID, {
    variables: { uid: userId }
  });

  const userPermissions = dataUser?.user?.permissions;
  const detailedPermissions = detailedPermissionsData?.permissions;
  const { superAdminId } = useSuperAdmin();

  const handleEditRole = () => {
    // Go to edit role page
    navigateTo({
      pathname: LINKS_PAGES.userItemPermissionsManagement.replace(':userId', userId),
      search: `${isShowVisibility ? 'visibility' : ''}`
    });
  };

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const permissionTag = dataUserRole?.user.permissionTag;
  const firstName = dataUserRole?.user.firstName ?? '';
  const lastName = dataUserRole?.user.lastName ?? '';
  const disabledEditButton = userId === superAdminId;

  const name = firstName.length && lastName.length ? `${firstName}${lastName}` : EMPTY_CELL_VALUE;

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.users, titleFallback: tGlobal('header.menu.users') },
    { link: LINKS_PAGES.users, titleFallback: tUser('settings.usersBreadcrumbs') },
    { link: LINKS_PAGES.userItemPermissions.replace(':userId', userId), titleFallback: name },
    { link: LINKS_PAGES.userItemPermissions.replace(':userId', userId), titleFallback: tUser('permissions.pageTitle') }
  ];

  return loadingUser ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={9} item>
        <Breadcrumbs
          links={breadcrumbsLinks}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />
        <PageTitle
          title={tUser('permissions.pageTitle')}
          subTitle={tUser('permissions.pageSubTitle')}
          sxSubTitle={{ color: '#505668' }}
        />

        <RenderWhen condition={!loadingUserRole}>
          <BlockInfoWithEdit>
            <PageTitle
              title={tUser('permissions.mainInfo.roleTitle')}
              sxBox={{ mb: '24px' }}
              sxTitle={{ fontSize: '16px' }}
            />
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Box>
                <Text className={'m-0'} variant={'bodyLg'}>
                  {permissionTag}
                </Text>
                <DescriptionRole userId={userId} />
              </Box>

              {isUserCanCRUD && (
                <Box>
                  <IconButton onClick={handleEditRole} disabled={disabledEditButton}>
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
            </Flex>
          </BlockInfoWithEdit>
        </RenderWhen>

        <BlockInfoWithEdit>
          <PageTitle
            title={tUser('permissions.mainInfo.permissionsTitle')}
            sxBox={{ mb: '24px' }}
            sxTitle={{ fontSize: '16px' }}
          />
          <PermissionsBlock
            userPermissions={userPermissions}
            detailedPermissions={detailedPermissions}
            permissionsLoading={detailedPermissionsLoading}
          />
        </BlockInfoWithEdit>

        <DomainsBlock />
      </Grid>
    </Grid>
  );
};
