import { Grid } from '@mui/material';

import { AddParameter } from './components/AddParameter';
import { SelectedParametersList } from './components/SelectedParametersList';

interface Step2Props {
  selectedItems: any[];
  onChange: any;
  value: any;
  onChangeParameters: any;
}

export const Step2 = ({ selectedItems, value, onChange, onChangeParameters }: Step2Props) => {
  return (
    <>
      <Grid justifyContent={'center'} container row mb={'77px'} sx={{ marginTop: '48px' }}>
        <AddParameter />

        {selectedItems.map((parameter) => (
          <SelectedParametersList
            key={parameter.id}
            parameter={parameter}
            parameters={selectedItems}
            selectedParameters={value}
            setValue={onChange}
            onChangeParameters={onChangeParameters}
          />
        ))}
      </Grid>
    </>
  );
};
