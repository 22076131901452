import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function FolderOpenIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M3.54183 16.25C3.13691 16.25 2.79263 16.1081 2.50898 15.8245C2.22532 15.5408 2.0835 15.1965 2.0835 14.7916V5.25642C2.0835 4.8515 2.23334 4.4992 2.53302 4.19952C2.8327 3.89984 3.185 3.75 3.58991 3.75H7.54181C7.74266 3.75 7.93576 3.789 8.12112 3.867C8.30648 3.94499 8.46754 4.05235 8.60429 4.1891L9.83185 5.41667H17.2197C17.397 5.41667 17.5455 5.4765 17.6652 5.59617C17.7848 5.71582 17.8447 5.86432 17.8447 6.04167C17.8447 6.21901 17.7848 6.36751 17.6652 6.48717C17.5455 6.60682 17.397 6.66665 17.2197 6.66665H9.32066L7.654 4.99998H3.58991C3.51512 4.99998 3.45368 5.02402 3.4056 5.0721C3.35752 5.12019 3.33348 5.18163 3.33348 5.25642V15C3.33348 14.9252 3.34817 14.8971 3.37756 14.9158C3.40693 14.9345 3.44566 14.9572 3.49375 14.984L5.11554 9.5689C5.21597 9.24517 5.40079 8.98503 5.67002 8.78846C5.93925 8.59188 6.2384 8.49358 6.56745 8.49358H17.2854C17.7961 8.49358 18.2013 8.69657 18.5009 9.10254C18.8006 9.50853 18.8794 9.95137 18.7373 10.4311L17.3014 15.2147C17.2063 15.5277 17.0282 15.7785 16.767 15.9671C16.5057 16.1557 16.216 16.25 15.8976 16.25H3.54183ZM4.803 15H15.8655C15.9243 15 15.9764 14.984 16.0218 14.9519C16.0672 14.9198 16.0979 14.8744 16.114 14.8157L17.5338 10.0721C17.5605 9.98662 17.5472 9.91049 17.4937 9.84371C17.4403 9.77693 17.3709 9.74354 17.2854 9.74354H6.56745C6.50869 9.74354 6.4566 9.75957 6.41118 9.79163C6.36578 9.82368 6.33507 9.86909 6.31904 9.92785L4.803 15ZM4.803 15L6.31904 9.92785C6.33507 9.86909 6.34842 9.82368 6.3591 9.79163L6.37512 9.74354L6.34708 9.84371C6.32838 9.91049 6.30568 9.98662 6.27898 10.0721L4.8591 14.8157C4.84307 14.8744 4.82972 14.9198 4.81904 14.9519L4.803 15Z" />
    </SvgIcon>
  );
}
