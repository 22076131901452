import { FC, useState } from 'react';

import Stack from '@mui/material/Stack';

import { ParameterType } from '@/logicLayers/domain';

import { Flex, RenderWhen, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from '../../../ContextProvider';
import { ParameterPlate } from '../../ParameterPlate';

import { NUMERIC_TYPE_LIST, NumericSteps, StringBooleanDateTimeSteps } from './components';

import { ParameterTypeTyped, STRING_BOOLEAN_DATE_PARAMS } from '../../../helpers';

export const Step2: FC = () => {
  const { t: tTemplates } = useTranslation('templates');

  const { parameterType, handleSetParameterType } = useContextState();

  const [selectedNumericItem, setSelectedNumericItem] = useState({
    id: NUMERIC_TYPE_LIST[0].id,
    type: ParameterTypeTyped.NUMERIC_INPUT
  });

  const selectParameterHandled = (id: number, type: ParameterType) => {
    setSelectedNumericItem({
      id,
      type
    });
    handleSetParameterType(type);
  };

  const isNumericParam = !STRING_BOOLEAN_DATE_PARAMS.includes(parameterType);

  return (
    <Stack>
      <RenderWhen condition={!isNumericParam}>
        <StringBooleanDateTimeSteps parameterType={parameterType} />
      </RenderWhen>

      <RenderWhen condition={isNumericParam}>
        <TextBlockControl
          title={tTemplates('createParameterItem.step2.title')}
          subTitle={tTemplates('createParameterItem.step2.description')}
          sxSubTitle={{ marginBottom: 0, maxWidth: 400 }}
        />

        <Flex flexWrap={'wrap'} gap={'24px'} mb={'12px'}>
          {NUMERIC_TYPE_LIST.map((parameter) => {
            return (
              <ParameterPlate
                key={parameter.id}
                parameter={parameter}
                selectedId={selectedNumericItem.id}
                onClick={() => selectParameterHandled(parameter.id, parameter.parameterType as ParameterType)}
              />
            );
          })}
        </Flex>

        <NumericSteps numericStep={selectedNumericItem.type} />
      </RenderWhen>
    </Stack>
  );
};
