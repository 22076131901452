import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CustomCheckIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M7.95946 12.9297L15.1582 5.73101C15.2821 5.60708 15.4293 5.54378 15.5997 5.5411C15.7701 5.53843 15.9199 5.60174 16.0492 5.73101C16.1785 5.86028 16.2431 6.00878 16.2431 6.17651C16.2431 6.34424 16.1785 6.49274 16.0492 6.62201L8.48669 14.1845C8.33605 14.3351 8.16031 14.4105 7.95946 14.4105C7.75861 14.4105 7.58287 14.3351 7.43223 14.1845L3.95306 10.7053C3.82913 10.5814 3.76796 10.4342 3.76956 10.2638C3.77117 10.0934 3.83661 9.94361 3.96587 9.81435C4.09515 9.68507 4.24365 9.62043 4.41137 9.62043C4.57911 9.62043 4.72761 9.68507 4.85687 9.81435L7.95946 12.9297Z" />
    </SvgIcon>
  );
}
