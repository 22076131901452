import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { NotFoundCompanies, NotFoundCompaniesProps, NotFoundSearch, NotFoundSearchProps } from './components';

import { StyledNotFoundScreens } from './style';

const NotFoundScreenImageTypes: Record<string, string> = {
  companies: LINKS_IMAGES.notFoundCompanies,
  search: LINKS_IMAGES.notFoundSearch,
  locations: LINKS_IMAGES.notFoundLocations
};

type NotFoundScreensProps = Omit<NotFoundCompaniesProps, 'imageUrl'> | Omit<NotFoundSearchProps, 'imageUrl'>;

export const NotFoundScreens = ({ type, title, subTitle, buttonText, onClick, afterContent }: NotFoundScreensProps) => {
  const imageUrl = NotFoundScreenImageTypes[type];

  return (
    <StyledNotFoundScreens>
      {type === 'search' ? (
        <NotFoundSearch
          type={type}
          imageUrl={imageUrl}
          title={title}
          subTitle={subTitle}
          buttonText={buttonText}
          onClick={onClick}
          afterContent={afterContent}
        />
      ) : (
        <NotFoundCompanies
          type={type}
          imageUrl={imageUrl}
          title={title}
          subTitle={subTitle}
          buttonText={buttonText}
          onClick={onClick}
        />
      )}
    </StyledNotFoundScreens>
  );
};
