import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid, Stack } from '@mui/material';

import { TextFieldControl } from '@/separatedModules/components';
import { ActiveRoundControl } from '@/separatedModules/components/Controls/ActiveRoundControl';
import { ButtonsListControl } from '@/separatedModules/components/Controls/ButtonsListControl';

import { ROLE_DESCRIPTION_REG_EXP } from '@/utils';

import { ROUND, ROUND_DATE, ROUND_TITLE } from '../../helpers';
import { options } from './helpers';

export const Step1 = () => {
  const { t: tGlobal } = useTranslation('global');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Stack minHeight={'95vh'}>
      <Grid justifyContent={'center'} container row mb={'77px'}>
        <Grid xs={12} sm={10} md={8} lg={7} item>
          <Controller
            name={ROUND_TITLE}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: ROLE_DESCRIPTION_REG_EXP,
                message: tGlobal('validation.descriptionLength', {
                  value: 100
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  title={'Round regulation title'}
                  subTitle={`<ul>
                      <li>Provide a descriptive and informative title for the round regulation.</li>
                      <li>This title serves as a reference point for mechanics when conducting equipment rounds, helping them quickly identify the specific aspect of the equipment they need to monitor</li>
                      </ul>`}
                  label={'Round regulation title'}
                  placeholder={'Round regulation title'}
                  name={ROUND_TITLE}
                  value={value}
                  validation={{
                    isValid: !errors[ROUND_TITLE]?.message,
                    error: errors[ROUND_TITLE]?.message as string
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name={ROUND}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please determine round frequency'
              }
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <ButtonsListControl
                    onChange={onChange}
                    options={options}
                    value={value}
                    title="Round frequency"
                    sxTitle={{ marginTop: '52px' }}
                    subTitle="Determine the frequency at which equipment inspection rounds should occur"
                    validation={{
                      isValid: !errors[ROUND]?.message,
                      error: errors[ROUND]?.message as string
                    }}
                  />
                </>
              );
            }}
          />
          <Controller
            name={ROUND_DATE}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <ActiveRoundControl
                  title="Activate round"
                  value={value}
                  subTitle={`
                        <ul>
                          <li>By Activating round you initiate creation of scheduled tasks for service staff based on the frequency of the round regulation.</li>
                          <li>When activated, all assigned service staff will be notified and receive tasks according to the specified frequency of the round regulation.</li>
                          <li>If the round is not activated, it will be stored in the database for reference purposes, but no tasks will be assigned to service staff</li>
                        </ul>
                      `}
                  sxTitle={{ marginTop: '52px' }}
                  onChange={(date, time) => {
                    onChange({ date, time });
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
