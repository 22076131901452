import { useRef } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import { TimeoutT } from '@/subsidiaryBinders/types';

import { MIN_TABLE_WIDTH, TableColumn, TableRow } from './table.config';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';

interface MuiTableProps {
  columns: TableColumn[];
  rows: TableRow[] | [];
  onRowClick?: (id: string) => void;
  onRowDoubleClick: (id: string) => void;
}

const getHiddenColumns = (columns: TableColumn[]): string[] => {
  // ToDo Refactor
  if (columns?.length === 0) {
    return [];
  }

  return columns?.map((column) => (column?.visible ? column.key : ''));
};

export const MuiTable = ({ columns = [], rows = [], onRowClick, onRowDoubleClick }: MuiTableProps) => {
  const timerId = useRef<TimeoutT>();

  const handleClickTimeout = (id: string, type: number) => {
    clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (type === 1) {
        onRowClick?.(id);
      } else if (type === 2) {
        onRowDoubleClick(id);
      }
    }, 200);

    return () => clearTimeout(timerId.current);
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
        <Table sx={{ minWidth: MIN_TABLE_WIDTH }} aria-label="">
          <TableHead columns={columns} />
          <TableBody rows={rows} hiddenColumns={getHiddenColumns(columns)} onRowClick={handleClickTimeout} />
        </Table>
      </TableContainer>
    </Box>
  );
};
