import { ReactNode } from 'react';

import { SingleImageFileT } from '@/subsidiaryBinders/types';

export interface ImageUploaderI {
  allowedExtensionsText: string;
  minDimensionsText: string;
  maxDimensionsText: string;
  maxFileSizeText: string;
  imageRequired: true;
  imageMinWidth: number;
  imageMinHeight: number;
  imageMaxSize: number;
  imageAllowedExtensions: string[];
}

export interface SingleImageUploaderPropsI extends ImageUploaderI {
  className?: string;
  variant?: 'button' | 'zone';
  titleContent: ReactNode;
  onFileUpload?: (file: SingleImageFileT) => void;
}

export interface SingleImageUploaderStateI {
  file: SingleImageFileT;
  upload: boolean;
  isOnDrag: boolean;
  isLoading: boolean;
  error: {
    isValid: boolean;
    message: string;
  };
}

export const INIT_STATE_SINGLE_IMAGE_UPLOADER: SingleImageUploaderStateI = {
  file: {
    blob: null,
    name: '',
    type: '',
    base64: '',
    preview: ''
  },
  error: {
    isValid: true,
    message: ''
  },
  upload: false,
  isOnDrag: false,
  isLoading: false
};

export const INIT_PROPS_SINGLE_IMAGE_UPLOADER: ImageUploaderI = {
  allowedExtensionsText: '',
  minDimensionsText: '',
  maxDimensionsText: '',
  maxFileSizeText: '',
  imageMinWidth: 0,
  imageMinHeight: 0,
  imageMaxSize: 8388608, // 8MB in Bytes
  imageAllowedExtensions: ['jpg', 'jpeg', 'png'],
  imageRequired: true
};
