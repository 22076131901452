export type ConditionType = 'FIT_RANGE' | 'OUT_OF_RANGE' | 'MATCH';

interface conditionsTitlesType {
  FIT_RANGE: string;
  OUT_OF_RANGE: string;
  MATCH: string;
}

export const conditionsTitles: conditionsTitlesType = {
  FIT_RANGE: 'Fit the range',
  OUT_OF_RANGE: 'Out of the range',
  MATCH: 'Match the value'
};
