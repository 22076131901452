import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

import { EquipmentRes } from '@/logicLayers/domain';

import {
  ConfigTableI,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  ImageLazy,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

export const CONFIG_TABLE: ConfigTableI<EquipmentRes> = {
  cells: [
    {
      order: 0,
      columnName: 'equipment-title',
      columnClass: 'MuiTableCell-equipment-title',
      title: i18n.t('equipment.table.cells.equipmentTitle', { ns: 'templates' }),
      Render: ({ name, fileUrl, templateDescription }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            {fileUrl ? (
              <ImageLazy
                src={fileUrl.url}
                variant={'rectangular'}
                width={24}
                height={24}
                alt={`Equipment ${name} - image`}
              />
            ) : (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  flex: '0 0 24px',
                  margin: '0',
                  background: 'rgba(9, 14, 22, 0.06)',
                  border: '1px solid var(--border-inverse, #F7F8F9)'
                }}
              />
            )}

            <Box sx={{ margin: '0 0 0 16px' }}>
              {name ? (
                <Text
                  className={'m-0'}
                  variant={'titleSm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {name}
                </Text>
              ) : null}

              {templateDescription ? (
                <Text
                  className={'m-0'}
                  variant={'bodySm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all'
                  }}
                >
                  {templateDescription}
                </Text>
              ) : (
                EMPTY_CELL_VALUE
              )}
            </Box>
          </TableCell>
        );
      }
    },
    {
      order: 1,
      columnName: 'equipment-model-number',
      columnClass: 'MuiTableCell-equipment-model-number',
      title: i18n.t('equipment.table.cells.equipmentModelNumber', { ns: 'templates' }),
      Render: ({ name, modelNumber }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            <Text
              className={'m-0'}
              variant={'bodyMd'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {modelNumber || EMPTY_CELL_VALUE}
            </Text>
          </TableCell>
        );
      }
    },
    {
      order: 2,
      columnName: 'equipment-domain',
      columnClass: 'MuiTableCell-equipment-domain',
      title: i18n.t('equipment.table.cells.equipmentDomain', { ns: 'templates' }),
      Render: ({ name, domain }, className, index) => (
        <TableCell key={name + index} className={className}>
          {domain?.id ? <DomainStatusChip color={domain?.color} label={domain.name} /> : EMPTY_CELL_VALUE}
        </TableCell>
      )
    },
    {
      order: 3,
      columnName: 'equipment-creation-date',
      columnClass: 'MuiTableCell-equipment-creation-date',
      title: i18n.t('equipment.table.cells.equipmentCreationDate', { ns: 'templates' }),
      Render: ({ name, createdDate }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            <Text className={'m-0'} variant={'bodyMd'}>
              {createdDate ? dayjs(createdDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
            </Text>
          </TableCell>
        );
      }
    },
    {
      order: 4,
      columnName: 'equipment-status',
      columnClass: 'MuiTableCell-equipment-status',
      title: i18n.t('equipment.table.cells.equipmentStatus', { ns: 'templates' }),
      Render: ({ name, active, archived }, className, index) => {
        const status = active
          ? SystemStatusesEnum.Enabled
          : !active && !archived
            ? SystemStatusesEnum.Disabled
            : SystemStatusesEnum.Archived;

        return (
          <TableCell key={name + index} className={className}>
            <SystemStatusChip status={status} />
          </TableCell>
        );
      }
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
