import { Dispatch, ReactNode, SetStateAction } from 'react';

import { UsersRoleTemplateRes } from '@/logicLayers/domain';

import { MenuOption } from '@/separatedModules/components';

import { CONFIG_TABLE, ConfigTableI } from '../../helpers';

export type PermissionTemplatesFilters = {
  search: string[];
  hiddenColumns: string[];
  sortByDate: MenuOption[];
};

export interface initStateI extends Omit<ConfigTableI, 'cellsHeader' | 'cellsBody' | 'cells'> {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: PermissionTemplatesFilters;
  selectedItems: string[];
  bodyDataRows: UsersRoleTemplateRes[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
}

export interface ContextStateI extends initStateI {
  loadingRoleTemplates: boolean;
  setState: Dispatch<SetStateAction<initStateI>>;
}

export interface ContextUpdateStateI {
  handleChangeBySearch: (value: string) => void;
  handleChangePagination: (a: number) => void;
  handleChangeRowsPerPage: (a: number) => void;
  handleChangeSortByDate: () => void;
  handleClickRow: (a: string) => void;
  handleResetSelectedRows: () => void;
  handleSelectRow: (a: string) => void;
  handleShowAllColumns: () => void;
  handleToggleActions: () => void;
  handleToggleAside: () => void;
  handleToggleColumnVisibility: (key: string) => void;
}

export interface TableProviderProps {
  children: ReactNode;
  roleId?: string;
}

// ToDo Refactor
const getHiddenColumns = localStorage.getItem('roleTableColumns');

export const initState: initStateI = {
  filters: {
    search: [],
    // ToDo Refactor
    hiddenColumns: getHiddenColumns ? JSON.parse(getHiddenColumns) : [],
    sortByDate: []
  },
  actions: {},
  pagination: CONFIG_TABLE.pagination,
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  bodyDataRows: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};
