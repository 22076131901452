import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { useIsUserSuperAdmin } from '@/utils';

import { SideBarProvider } from '../components';

import { BottomNavigationBar, Header, HeaderSuperAdmin } from './components';

import { StyledLayoutBase } from './style';

export const BaseLayout = () => {
  const isUserSuperAdmin = useIsUserSuperAdmin();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  return (
    <>
      {isSmallScreen || isTablet ? (
        <Box>
          <Box component="main">
            <Outlet />
          </Box>
          <BottomNavigationBar />
        </Box>
      ) : (
        <Suspense fallback={<Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isContainer isFullScreen />}>
          {isUserSuperAdmin ? <HeaderSuperAdmin /> : <Header />}

          <StyledLayoutBase>
            <div className={'layout-base__container'}>
              <div className={'layout-base__left'}>
                <SideBarProvider />
              </div>

              <div className={'layout-base__right'}>
                <Grid justifyContent={'center'} container>
                  <Outlet />
                </Grid>
              </div>
            </div>
          </StyledLayoutBase>
        </Suspense>
      )}
    </>
  );
};
