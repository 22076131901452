import { FC } from 'react';

import { Step2 } from './Step2/Step2';
import { Step1 } from './Step1';

interface StepsProps {
  step: string;
  selectedItems: any;
  value: any;
  onChange: any;
  onChangeParameters: any;
}

interface ComponentMap {
  [key: string]: FC<{ selectedItems: any; value: any; onChange: any; onChangeParameters: any }>;
}

const Component: ComponentMap = {
  step1: Step1,
  step2: Step2
};

export const Steps = ({ step, selectedItems, onChange, value, onChangeParameters }: StepsProps) => {
  const SelectedComponent = Component[step];

  return (
    <>
      {SelectedComponent ? (
        <SelectedComponent
          selectedItems={selectedItems}
          onChange={onChange}
          value={value}
          onChangeParameters={onChangeParameters}
        />
      ) : null}
    </>
  );
};
