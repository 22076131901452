import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Avatar, Button } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { GET_ROLE_TEMPLATE_DETAILS_BY_ID, GetRoleTemplateDetailsQuery } from '@/logicLayers/domain';

import {
  ArchiveIcon,
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  EditIcon,
  Flex,
  PageTitle,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

interface RolesRoleDetailsPageProps {}

export const TemplatesRolesRoleDetailsPage: FC<RolesRoleDetailsPageProps> = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();

  const { data: roleTemplateData, loading: roleTemplateDataLoading } = useQuery<GetRoleTemplateDetailsQuery>(
    GET_ROLE_TEMPLATE_DETAILS_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: templateId
      },
      skip: !templateId
    }
  );

  const roleTemplate = roleTemplateData?.roleTemplate;
  const roleName = roleTemplate?.name;
  const roleDescription = roleTemplate?.description;
  const userFirstName = roleTemplate?.createdBy?.firstName ?? '';
  const userLastName = roleTemplate?.createdBy?.lastName ?? '';
  const userLogo = roleTemplate?.createdBy?.userAvatar?.url ?? '';
  const usageCount = roleTemplate?.usageCount ?? '0';
  const createdDate = roleTemplate?.createdDate ?? '-';
  const lastModifiedDate = roleTemplate?.lastModifiedDate ?? '-';

  const handleMoveToRenameRole = () => {
    const url = LINKS_PAGES.templatesRolesRoleDetailsEdit.replace(':templateId', templateId);

    navigateTo(url + '?q=name');
  };

  const handleMoveToUpdateRoleDescription = () => {
    const url = LINKS_PAGES.templatesRolesRoleDetailsEdit.replace(':templateId', templateId);

    navigateTo(url + '?q=description');
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={9} item>
        <PageTitle title={tTemplates('detailsRole.details.pageTitle')} sxTitle={{ mt: '32px' }} />
        {roleTemplateDataLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
        ) : (
          <>
            <BlockInfoWithEdit>
              <PageTitle
                title={tTemplates('detailsRole.details.mainInfo.title')}
                sxBox={{ mb: 0 }}
                sxTitle={{ mb: '16px', fontSize: '16px' }}
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.mainInfo.roleTitle')}
                description={roleName ?? '-'}
                sxLabel={{ mb: '16px' }}
                sxDescription={{ fontSize: '16px', color: '#090E16' }}
                descriptionSecond={''}
                Edit={
                  <IconButton onClick={handleMoveToRenameRole}>
                    <EditIcon />
                  </IconButton>
                }
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.mainInfo.roleDescription')}
                description={roleDescription ?? '-'}
                sxLabel={{ mb: '16px' }}
                sxDescription={{ fontSize: '16px', color: '#090E16' }}
                Edit={
                  <IconButton onClick={handleMoveToUpdateRoleDescription}>
                    <EditIcon />
                  </IconButton>
                }
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.mainInfo.createdBy')}
                description={
                  <Flex>
                    <Avatar
                      size={'32'}
                      sx={{ width: '24px', height: '24px', border: 'none', mr: '8px' }}
                      src={userLogo}
                    >
                      {userFirstName?.slice(0, 1)}
                      {userLastName?.slice(0, 1)}
                    </Avatar>

                    <Flex>
                      {userFirstName + ' '} {userLastName}
                    </Flex>
                  </Flex>
                }
                sxLabel={{ mb: '16px' }}
                sxDescription={{ fontSize: '16px', color: '#090E16' }}
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.mainInfo.items')}
                description={usageCount}
                sxLabel={{ mb: '16px' }}
                sxDescription={{ fontSize: '16px', color: '#090E16' }}
              />
            </BlockInfoWithEdit>

            <BlockInfoWithEdit>
              <PageTitle
                title={tTemplates('detailsRole.details.history.title')}
                sxBox={{ mb: 0 }}
                sxTitle={{ mb: '16px', fontSize: '16px' }}
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.history.created')}
                description={dayjs(createdDate).format('DD MMM YYYY, HH:mm A')}
                sxLabel={{ mb: '16px' }}
                sxDescription={{ fontSize: '16px', color: '#090E16' }}
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.history.lastModified')}
                description={dayjs(lastModifiedDate).format('DD MMM YYYY, HH:mm A')}
                sxLabel={{ mb: '16px' }}
                sxDescription={{ fontSize: '16px', color: '#090E16' }}
              />
            </BlockInfoWithEdit>

            <BlockInfoWithEdit>
              <PageTitle
                title={tTemplates('detailsRole.details.archive.title')}
                sxBox={{ mb: 0 }}
                sxTitle={{ mb: '16px', fontSize: '16px' }}
              />

              <BlockInfoWithEditItem
                label={tTemplates('detailsRole.details.archive.description')}
                sxLabel={{ mb: '16px' }}
                Edit={
                  <Button disabled startIcon={<ArchiveIcon />} variant={'text'}>
                    {tTemplates('detailsRole.details.archive.archiveBtn')}
                  </Button>
                }
              />
            </BlockInfoWithEdit>
          </>
        )}
      </Grid>
    </Grid>
  );
};
