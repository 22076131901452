import { useRouteError } from 'react-router-dom';

export const ErrorBoundary = () => {
  const error: any = useRouteError();

  console.error(error);

  return <div>{error.message}</div>;

  /*
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
  */
};
