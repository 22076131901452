import { useNavigate } from 'react-router-dom';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';

import { EquipmentRes } from '@/logicLayers/domain';

import {
  ArrowForwardIcon,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  ImageLazy,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

const Title = ({ title }: { title: string }) => {
  return (
    <Text variant={'labelSm'} sx={{ fontSize: '12px', lineHeight: '16px', margin: '0 0 8px' }}>
      {title}
    </Text>
  );
};

export const EquipmentItem = ({
  id,
  name,
  active,
  templateDescription,
  domain,
  modelNumber,
  manufacturer,
  fileUrl
}: EquipmentRes) => {
  const { t: tTemplates } = useTranslation('templates');
  const navigateTo = useNavigate();

  const handleClickDetails = () => {
    id && navigateTo(LINKS_PAGES.templatesEquipmentItem.replace(':templateId', id));
  };

  return (
    <Box>
      {fileUrl?.url ? (
        <Box sx={{ margin: '20px 0' }}>
          <ImageLazy
            src={fileUrl?.url}
            alt={`Equipment ${name} logo`}
            variant={'rectangular'}
            width={'100%'}
            height={'auto'}
            minHeight={170}
            sxStyle={{
              img: {
                maxHeight: '170px',
                objectFit: 'cover',
                borderRadius: 'var(--4, 4px)'
              }
            }}
          />
        </Box>
      ) : null}

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tTemplates('equipment.table.aside.status')} />

        <SystemStatusChip status={active ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled} />
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tTemplates('equipment.table.aside.description')} />

        <Text className={'m-0'} variant={'bodyLg'}>
          {templateDescription || EMPTY_CELL_VALUE}
        </Text>
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tTemplates('equipmentItem.overview.domain')} />

        {domain?.id ? (
          <DomainStatusChip color={domain.color} label={domain.name} />
        ) : (
          <Text variant={'bodyLg'}>{EMPTY_CELL_VALUE}</Text>
        )}
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tTemplates('equipmentItem.overview.manufacturer')} />

        <Text variant={'bodyLg'}>{manufacturer || EMPTY_CELL_VALUE}</Text>
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tTemplates('equipmentItem.overview.modelNumber')} />

        <Text variant={'bodyLg'}>{modelNumber || EMPTY_CELL_VALUE}</Text>
      </Box>

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
        onClick={handleClickDetails}
      >
        {tTemplates('equipment.table.aside.btnDetails')}
      </Button>
    </Box>
  );
};
