import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { RoundRegulationsQuery } from '@/logicLayers/domain';
import { GET_TASKS } from '@/logicLayers/domain/tasks';
import { selectUserData } from '@/logicLayers/infrastructure/redux';

import {
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  EquipmentIcon,
  MoreVertIcon,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useIsUserCanCRUD, useIsUserSuperAdmin } from '@/utils';

import { LINKS_IMAGES, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { HeaderMobile } from './components/HeaderMobile';

import { getBreadcrumbs } from './helpers';

export const HomePage = () => {
  const { t: tHome } = useTranslation('home');

  const isUserSuperAdmin = useIsUserSuperAdmin();
  const { profile } = useSelector(selectUserData);
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_VIEWING']
  });
  const isShowLocations =
    profile.permissions?.some((permission) => permission?.name === 'ROLE_LOCATIONS_VIEWING') && isUserCanCRUD;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const { data, loading, fetchMore } = useQuery<RoundRegulationsQuery>(GET_TASKS, {
    variables: {
      searchCriteria: {
        // query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: 0,
          pageSize: 20
        }
        // sortable: {
        //   column: state.filters.sortByDate.length ? 'createdDate' : undefined,
        //   direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined
        // }
      },
      type: 'ROUND'
    }
  });

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPage + 1,
            pageSize: 20
          }
        },
        type: 'ROUND'
      },
      updateQuery: (
        prev: RoundRegulationsQuery,
        { fetchMoreResult }: { fetchMoreResult: RoundRegulationsQuery }
      ): RoundRegulationsQuery => {
        if (!fetchMoreResult) return prev;
        setHasMore((currentPage + 1) * 20 < fetchMoreResult.dataItems.total);
        setCurrentPage(currentPage + 1);

        return {
          dataItems: {
            items: [...prev.dataItems.items, ...fetchMoreResult.dataItems.items],
            total: fetchMoreResult.dataItems.total
          }
        };
      }
    });
  };

  return (
    <>
      {isSmallScreen || isTablet ? (
        <>
          <AppBar sx={{ boxShadow: 'none', borderBottom: 'none' }} component="nav">
            <HeaderMobile />
          </AppBar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              overflowY: 'auto',
              padding: '70px 16px 96px 16px'
            }}
          >
            {loading ? (
              <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer />
            ) : data?.dataItems.items.length ? (
              <Box sx={{ width: '100%', maxWidth: '744px' }}>
                <InfiniteScroll
                  dataLength={data?.dataItems.items.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={null}
                >
                  <>
                    {data?.dataItems?.items?.map((item) => {
                      const location = item?.equipment?.[0]?.location;
                      const equipmentName = item?.equipment?.[0]?.name;
                      const breadcrumbsLinks = getBreadcrumbs(location);

                      return (
                        <Box
                          key={item.id}
                          sx={{
                            display: 'flex',
                            alignSelf: 'stretch',
                            flexDirection: 'column',
                            borderRadius: '4px',
                            border: '1px solid var(--background-disabled, rgba(41, 49, 62, 0.03))',
                            background: 'var(--background-neutral-hovered, #F5F7FA)',
                            marginTop: '16px',
                            padding: '12px',
                            width: '100%',
                            boxSizing: 'border-box'
                          }}
                        >
                          <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{item.name}</Text>
                            <IconButton sx={{ padding: '4px' }} onClick={() => {}}>
                              <MoreVertIcon sx={{ width: '32px', height: '32px' }} />
                            </IconButton>
                          </Box>
                          {breadcrumbsLinks.length > 0 && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                background: 'var(--background-neutral-hovered, #F7F8F9)',
                                borderRadius: 'var(--4, 4px)',
                                gap: '8px',
                                width: 'fit-content'
                              }}
                            >
                              <BuildingIcon sx={{ fontSize: '16px' }} />
                              <Breadcrumbs
                                links={breadcrumbsLinks}
                                separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
                                sx={{ padding: 0 }}
                                fontSize="12px"
                              />
                            </Box>
                          )}
                          <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                            <EquipmentIcon sx={{ fontSize: '16px' }} />
                            <Text sx={{ fontSize: '12px' }}>{equipmentName}</Text>
                          </Box>
                          <Box
                            sx={{
                              marginTop: '8px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <TaskStatusChip status={item.status} />
                            <Button
                              variant={'text'}
                              size={'small'}
                              sx={{
                                margin: '0 0 0 auto !important',
                                '&.MuiButton-text': {
                                  '&:not(:active, :hover)': {
                                    background: 'transparent'
                                  }
                                },
                                span: {
                                  color: '#265299'
                                }
                              }}
                              onClick={() => {}}
                            >
                              {tHome('detailsBtn')}
                            </Button>
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                </InfiniteScroll>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'calc(100vh - 170px)',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={LINKS_IMAGES.notFoundCompanies} alt={'Not found image'} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Text sx={{ color: '#121B2A', fontSize: '16px', fontWeight: '600' }}>No tasks yet</Text>
                  <Text sx={{ fontSize: '14px' }}>Tasks assigned on you will be displayed here</Text>
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Grid xs={12} md={10} lg={8} item>
          <Text component={'h2'}>Home Page</Text>
          <Navigate to={LINKS_PAGES[isUserSuperAdmin || !isShowLocations ? 'users' : 'locations']} />
        </Grid>
      )}
    </>
  );
};
