import { FC, MouseEvent, useMemo, useState } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { BaseMenuItem } from '../..';
import { MenuOption } from '../../dropdown.config';
import { BaseTableDropdown, DropdownProps } from '../BaseTableDropdown';

interface SelectsDropdownProps extends Omit<DropdownProps, 'onChange'> {
  defaultChecked?: boolean;
  menuList: MenuOption[];
  isRound?: boolean;
  // ToDo Check Ts
  onChange: (options: MenuOption[]) => void;
}

export const SelectsDropdown: FC<SelectsDropdownProps> = ({
  id,
  title,
  searchPlaceholder,
  menuList,
  defaultChecked = false,
  hasSearch = false,
  disabled = false,
  onChange,
  isRound
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // ToDo Refactor Remove Memo
  // ToDo Refactor Remove Memo
  const updatedMenuList = useMemo<MenuOption[]>(() => {
    return menuList.map((item) => ({ ...item, checked: defaultChecked }));
  }, [menuList]);

  const [searchFilter, setSearchFilter] = useState('');
  const [selectedItems, setSelectedItems] = useState(updatedMenuList);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchFilter('');
  };

  const handleItemClick = (option: MenuOption) => {
    setSelectedItems((prev) => {
      const arr = prev.map((prevColumn) => {
        if (prevColumn.key === option.key) {
          return { ...prevColumn, checked: !option.checked };
        } else {
          return { ...prevColumn, checked: false };
        }
      });

      const filtered = arr.filter((i) => {
        return i.checked;
      });

      onChange(filtered);

      return arr;
    });
  };

  const filteredMenuList = useMemo(() => {
    return selectedItems.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [selectedItems, searchFilter]);

  return (
    <BaseTableDropdown
      id={id}
      title={title}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchFilter}
      anchorEl={anchorEl}
      disabled={disabled}
      hasSearch={hasSearch}
      onSearchChange={setSearchFilter}
      onDropdownClick={handleClick}
      onClose={handleClose}
      isRound={isRound}
    >
      {filteredMenuList.map((option) => (
        <BaseMenuItem
          key={option.key + option.label}
          selected={option.checked}
          checked={option.checked}
          sxMenuItem={{
            justifyContent: 'space-between'
          }}
          onClick={() => {
            handleItemClick(option);
          }}
        >
          {option.icon ? option.icon : null}

          <Text variant={'bodyMd'} color={'#090E16'} m={0} sx={{ flex: '0 1 100%' }}>
            {option.label}
          </Text>
        </BaseMenuItem>
      ))}
    </BaseTableDropdown>
  );
};
