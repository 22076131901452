import { memo, ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { Breadcrumbs, ChevronRightIcon } from '@/separatedModules/components';

interface PageTitleProps {
  title: string;
  subTitle?: ReactNode;
  dataFoundTitle?: string;
  sxBox?: SxProps<Theme>;
  sxTitle?: SxProps<Theme>;
  sxSubTitle?: SxProps<Theme>;
  sxDataFoundTitle?: SxProps<Theme>;
  breadcrumbsLinks?: { link?: string; titleFallback: string }[];
}

export const PageTitle = memo(function ({
  title,
  subTitle,
  dataFoundTitle,
  sxBox,
  sxTitle,
  sxSubTitle,
  sxDataFoundTitle,
  breadcrumbsLinks
}: PageTitleProps) {
  return (
    <Box className={'PageTitle'} sx={{ margin: '0 0 32px', ...sxBox }}>
      {dataFoundTitle ? (
        <Box sx={{ display: 'flex', alignItems: 'center', ...sxDataFoundTitle }}>
          {breadcrumbsLinks ? (
            <Breadcrumbs
              links={breadcrumbsLinks}
              separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
              sx={{ padding: 0 }}
              fontSize="20px"
            />
          ) : (
            <Text component={'h2'} variant={'titleLg'} sx={{ ...sxTitle }}>
              {title}
            </Text>
          )}

          <Text
            component={'h4'}
            sx={{
              color: 'var(--text-subtle, #505668)',
              fontSize: '11px',
              fontWeight: 600,
              lineHeight: '14px',
              letterSpacing: '0.5px',
              textTransform: 'uppercase',
              margin: '4px 0px 16px 16px',
              alignSelf: 'center',
              ...sxDataFoundTitle
            }}
          >
            {dataFoundTitle}
          </Text>
        </Box>
      ) : breadcrumbsLinks ? (
        <Breadcrumbs
          links={breadcrumbsLinks}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
          fontSize="20px"
        />
      ) : (
        <Text component={'h2'} variant={'titleLg'} sx={{ ...sxTitle }}>
          {title}
        </Text>
      )}

      {subTitle ? (
        <Text component={'h4'} variant={'bodyLg'} sx={{ ...sxSubTitle }}>
          {subTitle}
        </Text>
      ) : null}
    </Box>
  );
});
