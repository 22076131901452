import { styled } from '@mui/material/styles';

export const StyledEdit = styled('div')(() => ({
  '.edit-user': {
    '&__content': {
      '&__title': {
        margin: '32px 0 48px'
      },
      '&__subTitle': {
        margin: '32px 0'
      },
      '&__btn': {
        margin: '48px 0 0 auto',
        display: 'block'
      }
    }
  }
}));

StyledEdit.defaultProps = {
  className: 'edit-user'
};
