import { Text } from 'infinitytechnologies-ui';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { TOP_100_FILMS } from './autocomplete-example-mock';

interface AutocompleteExampleProps {
  exampleType: string;
  label: string;
  freeSolo?: boolean;
  disabled?: boolean;
}

export function AutocompleteExample({ exampleType, label, freeSolo, disabled }: AutocompleteExampleProps) {
  return (
    <div className={'mb-5'} style={{ width: '260px' }}>
      <Text className={'mt-2 mb-3'} component={'h5'} sx={{ fontSize: 16, weight: 600 }}>
        {exampleType}
      </Text>

      <Autocomplete
        options={TOP_100_FILMS}
        freeSolo={freeSolo}
        disabled={disabled}
        renderInput={(params: any) => <TextField {...params} label={label} />}
      />
    </div>
  );
}
