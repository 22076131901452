import { ReactNode, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { Autocomplete } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material';
import Chip from '@mui/material/Chip';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { PARAMETER_UNIT_ID } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/helpers';

import {
  AutocompleteControl,
  AutocompleteOptionT,
  ButtonLoading,
  ChipIcon,
  TextBlockControl
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export interface EditAutocompleteTagsItemProps {
  editFieldName: string;
  editFieldValue: AutocompleteOptionT[];
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  dataQuery: TypedDocumentNode<any, any>;
  dataVars?: Record<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  inputLabel: string;
  inputPlaceholder?: string;
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  regExp: RegExp;
  ContentAfterTitle?: ReactNode;
  multiline?: boolean;
  generateOptions: (data: any) => AutocompleteOptionT[];
  isParameter?: boolean;
  onSubmitForm: (getValues: any, updateModel: any) => void;
  sxTitle?: SxProps<Theme>;
}

export const EditAutocompleteTagsItem = <T,>({
  editFieldName,
  editFieldValue,
  navigateToUrl,
  mutationQuery,
  dataQuery,
  dataVars = {
    pageable: {
      pageSize: 1000
    }
  },
  successAlertMessage,
  errorAlertMessage,
  requiredMessage,
  inputLabel,
  inputPlaceholder,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  generateOptions,
  ContentAfterTitle,
  sxTitle,
  onSubmitForm,
  isParameter
}: EditAutocompleteTagsItemProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data: mutationData, error }] = useMutation(mutationQuery);

  const { data: queryData } = useQuery<T>(dataQuery, {
    fetchPolicy: 'no-cache',
    variables: dataVars
  });

  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [editFieldName]: editFieldValue
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm?.(getValues, updatedData);
  });

  useEffect(() => {
    if (mutationData) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [mutationData, error]);

  const options: AutocompleteOptionT[] = useMemo(() => {
    if (!queryData) {
      return [];
    }

    return generateOptions(queryData);
  }, [queryData]);

  const REQUIRED_MESSAGE = requiredMessage || tGlobal('validation.required');
  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  return (
    <form onSubmit={handleSubmitForm}>
      <TextBlockControl
        title={pageTitle}
        subTitle={pageSubTitle}
        ContentAfterTitle={ContentAfterTitle}
        isTitleLg
        isBodyLg
        sxTitle={sxTitle}
      />

      <Controller
        name={editFieldName}
        control={control}
        rules={{
          required: {
            value: true,
            message: REQUIRED_MESSAGE
          },
          validate: (value) => {
            const isMoreThenOneUser = value.length > 1;

            if (!value[0] && isParameter) {
              return tGlobal('validation.required');
            }

            return isMoreThenOneUser ? tGlobal('validation.arrayFieldMaxLength', { value: 1 }) : true;
          }
        }}
        render={({ field }) => {
          const { onChange, onBlur, value } = field;

          return (
            <>
              {isParameter ? (
                <AutocompleteControl
                  label={inputLabel}
                  name={PARAMETER_UNIT_ID}
                  options={options}
                  value={value[0] as any}
                  validation={{
                    isValid: !errors[PARAMETER_UNIT_ID]?.message,
                    error: errors[PARAMETER_UNIT_ID]?.message as string
                  }}
                  getOptionKey={(option: any) => option.value as string}
                  onChange={(e, value) => {
                    onChange([value]);
                  }}
                  onBlur={onBlur}
                />
              ) : (
                <Autocomplete
                  name={editFieldName}
                  label={inputLabel}
                  placeholder={inputPlaceholder}
                  value={value}
                  options={options}
                  validation={{
                    isValid: !errors[editFieldName]?.message,
                    error: errors[editFieldName]?.message
                  }}
                  multiple
                  renderTags={(tagValue, getTagProps) => {
                    return (tagValue as AutocompleteOptionT[]).map((option, index) => {
                      const { ...tagProps } = getTagProps({ index });

                      return <Chip {...tagProps} key={option.key} label={option.label} deleteIcon={<ChipIcon />} />;
                    });
                  }}
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                  onBlur={onBlur}
                />
              )}
            </>
          );
        }}
      />

      <ButtonLoading
        className={'edit__content__btn'}
        variant={'contained'}
        type={'submit'}
        loading={loading}
        disabled={Boolean(mutationData)}
      >
        {SUBMIT_BTN_TEXT}
      </ButtonLoading>
    </form>
  );
};
