import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function TableAsideItemsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width={'96'}
      height={'96'}
      viewBox={'0 0 96 96'}
      fill={'none'}
      sx={{ width: '96px', height: '96px' }}
      {...props}
    >
      <path
        d="M18.1543 32.9415H2.25C1.0125 32.9415 0 31.929 0 30.6915V14.7874C0 13.5499 1.0125 12.5374 2.25 12.5374H18.1543C19.3918 12.5374 20.4043 13.5499 20.4043 14.7874V30.6917C20.4043 31.929 19.3918 32.9415 18.1543 32.9415Z"
        fill="#CCE0FF"
      />
      <path
        d="M18.1543 60.4398H2.25C1.0125 60.4398 0 59.4273 0 58.1898V42.2855C0 41.048 1.0125 40.0355 2.25 40.0355H18.1543C19.3918 40.0355 20.4043 41.048 20.4043 42.2855V58.1898C20.4043 59.4273 19.3918 60.4398 18.1543 60.4398Z"
        fill="#CCE0FF"
      />
      <path
        d="M18.1543 87.9384H2.25C1.0125 87.9384 0 86.9259 0 85.6884V69.7841C0 68.5466 1.0125 67.5341 2.25 67.5341H18.1543C19.3918 67.5341 20.4043 68.5466 20.4043 69.7841V85.6884C20.4043 86.9259 19.3918 87.9384 18.1543 87.9384Z"
        fill="#CCE0FF"
      />
      <path
        d="M18.0863 29.4392H2.31806C1.04306 29.4392 0 28.3961 0 27.1211V30.6234C0 31.8984 1.04306 32.9415 2.31806 32.9415H18.0863C19.3613 32.9415 20.4043 31.8984 20.4043 30.6234V27.1211C20.4041 28.3961 19.3611 29.4392 18.0863 29.4392Z"
        fill="#85B8FF"
      />
      <path
        d="M18.0863 56.9376H2.31806C1.04306 56.9376 0 55.8945 0 54.6195V58.1218C0 59.3968 1.04306 60.4399 2.31806 60.4399H18.0863C19.3613 60.4399 20.4043 59.3968 20.4043 58.1218V54.6195C20.4041 55.8945 19.3611 56.9376 18.0863 56.9376Z"
        fill="#85B8FF"
      />
      <path
        d="M18.0863 84.4359H2.31806C1.04306 84.4359 0 83.3929 0 82.118V85.6204C0 86.8954 1.04306 87.9384 2.31806 87.9384H18.0863C19.3613 87.9384 20.4043 86.8954 20.4043 85.6204V82.118C20.4041 83.3929 19.3611 84.4359 18.0863 84.4359Z"
        fill="#85B8FF"
      />
      <path
        d="M91.8145 12.5374C52.0101 12.5374 56.1992 12.5374 39.8447 12.5374C37.5428 12.5374 35.6592 14.4208 35.6592 16.7229V28.7561C35.6592 31.058 37.5426 32.9417 39.8447 32.9417C41.4719 32.9417 89.5542 32.9417 91.8145 32.9417C94.1166 32.9417 96.0001 31.0582 96.0001 28.7561V16.7227C96.0001 14.4208 94.1166 12.5374 91.8145 12.5374Z"
        fill="#F1F2F4"
      />
      <path
        d="M91.8145 67.5341C81.0325 67.5341 49.8595 67.5341 39.8447 67.5341C37.5428 67.5341 35.6592 69.4175 35.6592 71.7196V83.7528C35.6592 86.0547 37.5426 87.9384 39.8447 87.9384C42.0471 87.9384 89.6181 87.9384 91.8145 87.9384C94.1166 87.9384 96.0001 86.0549 96.0001 83.7528V71.7196C96.0001 69.4175 94.1166 67.5341 91.8145 67.5341Z"
        fill="#F1F2F4"
      />
      <path
        d="M91.8145 40.0358C90.4232 40.0358 41.1715 40.0358 39.8447 40.0358C37.5428 40.0358 35.6592 41.9192 35.6592 44.2213V56.2545C35.6592 58.5564 37.5426 60.4401 39.8447 60.4401C41.8039 60.4401 89.7304 60.4401 91.8145 60.4401C94.1166 60.4401 96.0001 58.5566 96.0001 56.2545V44.2213C96.0001 41.9192 94.1166 40.0358 91.8145 40.0358Z"
        fill="#F1F2F4"
      />
      <path
        d="M91.8145 29.0177C48.1705 29.0177 59.4524 29.0177 39.8447 29.0177C37.5428 29.0177 35.6592 27.1343 35.6592 24.8322V28.756C35.6592 31.0579 37.5426 32.9415 39.8447 32.9415C41.7799 32.9415 89.9759 32.9415 91.8145 32.9415C94.1166 32.9415 96.0001 31.0581 96.0001 28.756V24.8323C96.0001 27.1343 94.1166 29.0177 91.8145 29.0177Z"
        fill="#DCDFE4"
      />
      <path
        d="M91.8145 84.0144C89.7437 84.0144 41.8791 84.0144 39.8447 84.0144C37.5428 84.0144 35.6592 82.131 35.6592 79.8289V83.7527C35.6592 86.0546 37.5426 87.9382 39.8447 87.9382C42.0471 87.9382 89.6179 87.9382 91.8145 87.9382C94.1166 87.9382 96.0001 86.0548 96.0001 83.7527V79.8289C96.0001 82.131 94.1166 84.0144 91.8145 84.0144Z"
        fill="#DCDFE4"
      />
      <path
        d="M91.8145 56.5161C89.5606 56.5161 42.0923 56.5161 39.8447 56.5161C37.5428 56.5161 35.6592 54.6327 35.6592 52.3306V56.2544C35.6592 58.5563 37.5426 60.4399 39.8447 60.4399C40.2782 60.4399 90.5706 60.4399 91.8145 60.4399C94.1166 60.4399 96.0001 58.5565 96.0001 56.2544V52.3306C96.0001 54.6327 94.1166 56.5161 91.8145 56.5161Z"
        fill="#DCDFE4"
      />
      <path
        d="M87.571 24.1883H44.0885C43.2884 24.1883 42.6396 23.5397 42.6396 22.7395C42.6396 21.9392 43.2882 21.2906 44.0885 21.2906H87.5708C88.3709 21.2906 89.0196 21.9392 89.0196 22.7395C89.0196 23.5397 88.3711 24.1883 87.571 24.1883Z"
        fill="#BFC3CD"
      />
      <path
        d="M87.571 51.6867H44.0885C43.2884 51.6867 42.6396 51.0381 42.6396 50.2379C42.6396 49.4376 43.2882 48.7891 44.0885 48.7891H87.5708C88.3709 48.7891 89.0196 49.4376 89.0196 50.2379C89.0198 51.0381 88.3711 51.6867 87.571 51.6867Z"
        fill="#BFC3CD"
      />
      <path
        d="M87.571 79.185H44.0885C43.2884 79.185 42.6396 78.5364 42.6396 77.7362C42.6396 76.9359 43.2882 76.2874 44.0885 76.2874H87.5708C88.3709 76.2874 89.0196 76.9359 89.0196 77.7362C89.0198 78.5364 88.3711 79.185 87.571 79.185Z"
        fill="#BFC3CD"
      />
      <path
        d="M14.3587 29.5701C14.3283 29.5701 14.2981 29.5697 14.2677 29.569C13.217 29.5416 12.2349 29.0419 11.5942 28.2089L6.94341 22.1631C5.76854 20.6363 6.05448 18.4465 7.5811 17.2718C9.1081 16.0975 11.2975 16.3829 12.4724 17.9099L14.5032 20.5497L24.1492 9.28112C25.4017 7.81806 27.604 7.64724 29.0669 8.89937C30.5302 10.1522 30.7012 12.3539 29.4485 13.8173L17.0086 28.3504C16.3449 29.1254 15.3762 29.5701 14.3587 29.5701Z"
        fill="#6FCEA7"
      />
      <path
        d="M29.072 8.90417L17.0083 22.9978C15.5698 24.6782 12.944 24.6114 11.5942 22.8562L7.41036 17.4174C6.03767 18.62 5.81492 20.6962 6.94348 22.163L11.5942 28.2088C12.2349 29.0419 13.2169 29.5415 14.2678 29.5689C14.298 29.5699 14.3284 29.57 14.3587 29.57C15.3761 29.57 16.3449 29.1253 17.0083 28.3504L29.4482 13.8172C30.6994 12.3557 30.5302 10.1578 29.072 8.90417Z"
        fill="#14B882"
      />
      <path
        d="M14.3587 57.0685C14.3283 57.0685 14.2981 57.0681 14.2677 57.0673C13.217 57.04 12.2349 56.5403 11.5942 55.7072L6.94341 49.6615C5.76854 48.1347 6.05448 45.9448 7.5811 44.7702C9.1081 43.5958 11.2975 43.8812 12.4724 45.4082L14.5032 48.048L24.1492 36.7795C25.4017 35.3162 27.604 35.1456 29.0669 36.3977C30.5302 37.6506 30.7012 39.8522 29.4485 41.3157L17.0086 55.8488C16.3449 56.6237 15.3762 57.0685 14.3587 57.0685Z"
        fill="#6FCEA7"
      />
      <path
        d="M29.072 36.4027L17.0083 50.4963C16.3447 51.2715 15.3761 51.716 14.3587 51.716C14.3284 51.716 14.2982 51.7156 14.2678 51.7149C13.217 51.6875 12.2349 51.1878 11.5942 50.3548L7.41036 44.916C6.03767 46.1186 5.81492 48.1948 6.94348 49.6616L11.5942 55.7073C12.2349 56.5404 13.2169 57.0401 14.2678 57.0675C14.298 57.0684 14.3284 57.0686 14.3587 57.0686C15.3761 57.0686 16.3449 56.6238 17.0083 55.8489L29.4482 41.3158C30.6994 39.854 30.5302 37.6561 29.072 36.4027Z"
        fill="#14B882"
      />
    </SvgIcon>
  );
}
