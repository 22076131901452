import { createSelector } from 'reselect';

import { ReduxState } from '../../config';

export const selectUserData = createSelector(
  (state: ReduxState) => state.user.profile,
  (state: ReduxState) => state.user.company,
  (profile, company) => {
    return {
      profile,
      company
    };
  }
);
