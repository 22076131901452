import classNames from 'classnames';
import { Select, SelectProps } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material/styles';

import { TextBlockControl, TextBlockControlProps } from '@/separatedModules/components';

import { StyledSelectControl } from './style';

interface SelectControlProps extends SelectProps, TextBlockControlProps {
  sxSelect?: SxProps<Theme>;
}

export const SelectControl = ({ title, subTitle, sxTitle, sxSubTitle, sxSelect, ...rest }: SelectControlProps) => {
  const isHasTitles = !title && !subTitle;

  return (
    <StyledSelectControl
      className={classNames({
        'no-titles': isHasTitles
      })}
    >
      <TextBlockControl
        className={'control-select__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
      />

      <Select sx={sxSelect} {...rest} />
    </StyledSelectControl>
  );
};
