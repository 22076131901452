import { NavLink } from 'react-router-dom';

import { useIsUserSuperAdmin } from '@/utils';

import { LINKS_SUPER_ADMIN_HEADER_MENU } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { StyledHeaderMenu } from './style';

export const HeaderMenu = () => {
  const { t } = useTranslation('global');

  const isUserSuperAdmin = useIsUserSuperAdmin();

  const sorted = LINKS_SUPER_ADMIN_HEADER_MENU(isUserSuperAdmin)
    .sort(({ order: orderA }, { order: orderB }) => {
      return orderA - orderB;
    })
    .filter((link) => !link.isHidden);

  return (
    <StyledHeaderMenu>
      {sorted.map(({ title, link }) => {
        return (
          <NavLink
            key={title}
            to={link}
            className={({ isActive, isPending }) =>
              [isPending ? 'pending' : '', isActive ? 'header__menu__i--active' : '', 'header__menu__i']
                .join(' ')
                .trim()
            }
            end
          >
            {t(title)}
            <span className={'header__menu__i__line'}></span>
          </NavLink>
        );
      })}
    </StyledHeaderMenu>
  );
};
