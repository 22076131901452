import { styled } from '@mui/material/styles';

export const StyledButtonListControl = styled('div')(() => ({
  margin: '32px 0 24px',
  '&.no-titles': {
    margin: '20px 0'
  },
  '.control-autocomplete-tags': {
    '&__text-wrp': {
      margin: '0px',
      marginBottom: '24px'
    }
  }
}));
