import { StyledChip } from './style';

interface DomainsStatusChipProps {
  label?: string;
  color?: string;
  fontSize?: string;
}

export const DomainStatusChip = ({ label = '', color = 'DEFAULT', fontSize }: DomainsStatusChipProps) => {
  return <StyledChip variant={'filled'} colorVariant={color} size={'small'} label={label} fontSize={fontSize} />;
};
