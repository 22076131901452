import { useContextState } from '../../../ContextProvider';
import { TaskCustomStep3 } from '../Step3/components';

import { TaskCustomStep4 } from './components';

import { TaskTypes } from '../../../helpers';

export const Step4 = () => {
  const { taskType } = useContextState();

  return taskType === TaskTypes.CUSTOM ? <TaskCustomStep4 /> : <TaskCustomStep3 />; // to do
};
