import { CSSProperties, ReactNode, useRef } from 'react';
import classNames from 'classnames';

import TableRow from '@mui/material/TableRow';

import { TimeoutT } from '@/subsidiaryBinders/types';

type TableRowWrapProps = {
  id: string;
  isSelected: boolean;
  children: ReactNode;
  handleClickRow: (id: string) => void;
  handleDoubleClickRow: (id: string) => void;
  style?: CSSProperties;
};

export const TableRowWrap = ({
  id,
  handleClickRow,
  handleDoubleClickRow,
  isSelected,
  children,
  style
}: TableRowWrapProps) => {
  const timerId = useRef<TimeoutT>();

  const handleClickTimeout = (id: string, type: number) => {
    clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (type === 1) {
        handleClickRow(id);
      } else if (type === 2) {
        handleDoubleClickRow(id);
      }
    }, 200);

    return () => clearTimeout(timerId.current);
  };

  return (
    <TableRow
      key={id}
      className={classNames({
        'Mui-selected': isSelected
      })}
      onClick={(event) => {
        if ((event.detail === 1 || event.detail === 2) && id) {
          handleClickTimeout(id, event.detail);
        }
      }}
      style={style}
    >
      {children}
    </TableRow>
  );
};
