import { FC } from 'react';

import Box from '@mui/material/Box';

import { ModalDiscardAllChanges } from '@/separatedModules/components';

import { EditRoleActionType } from '../components';

import { RenameRole } from './RenameRole';
import { UpdateRoleDescription } from './UpdateRoleDescription';

interface EditDetailsProps {
  type: EditRoleActionType;
}

const Component: { [key: string]: FC } = {
  name: RenameRole,
  description: UpdateRoleDescription
};

export const EditDetails = ({ type }: EditDetailsProps) => {
  const SelectedComponent = Component[type];

  return (
    <Box>
      {/*  as unknown as string this is hack only for applying go back to prev page */}
      <ModalDiscardAllChanges navigateToUrl={-1 as unknown as string} />

      {SelectedComponent ? <SelectedComponent /> : null}
    </Box>
  );
};
