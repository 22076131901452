import { useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';

import {
  EquipmentItemInformationQuery,
  GET_EQUIPMENT_ITEM_INFORMATION,
  GET_EQUIPMENT_ROUND_REGULATION,
  RoundRegulationQuery
} from '@/logicLayers/domain';

export const FORM_ID = 'create-task-based-on-round';
export const TASK_BY_ROUND_DATE = 'taskByRoundDate';
export const TASK_BY_ROUND_SERVICE_STUFF = 'taskByRoundServiceStuff';

export interface FormProps {
  [TASK_BY_ROUND_DATE]?: {
    date: Dayjs;
    time: Dayjs;
  };
  [TASK_BY_ROUND_SERVICE_STUFF]?: Array<string>;
}

export const DEFAULT_VALUES: FormProps = {
  taskByRoundDate: {
    date: dayjs(),
    time: dayjs()
  },
  taskByRoundServiceStuff: []
};

export const minDate = dayjs();
export const maxDate = dayjs().add(2, 'year').endOf('year');

export const useData = ({ templateId }: { templateId: string }) => {
  const { data: equipmentItemNameData, loading: equipmentItemNameLoading } = useQuery<EquipmentItemInformationQuery>(
    GET_EQUIPMENT_ITEM_INFORMATION,
    {
      variables: {
        id: templateId
      }
    }
  );

  return {
    equipmentItemNameData,
    equipmentItemNameLoading
  };
};

export const useRoundData = ({ roundId }: { roundId: string }) => {
  const { data: dataEquipmentTemplateRoundRegulation } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId
      }
    }
  );

  return {
    dataEquipmentTemplateRoundRegulation
  };
};
