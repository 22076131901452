import { styled } from '@mui/material/styles';

export const StyledBlankFlow = styled('div')(({ theme }) => ({
  '.flow-blank': {
    '&__right': {
      '&__img': {
        padding: '32px 0',
        [theme.breakpoints.up('xl')]: {
          maxWidth: '750px'
        }
      }
    },
    '&__btn': {
      '&__create-company': {
        margin: '48px 0 0 auto'
      }
    }
  }
}));
