import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { ReduxStore } from './config';

interface ReduxProviderProps {
  children: ReactNode;
}

export function ReduxProvider({ children }: ReduxProviderProps) {
  return <Provider store={ReduxStore}>{children}</Provider>;
}
