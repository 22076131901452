import { ReactNode } from 'react';

import { ModalDiscardAllChanges } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { StyledEditPageWrap } from './style';

interface EditPageWrapProps {
  navigateTo?: string;
  children: ReactNode;
  isShowButton?: boolean;
}

export const EditPageWrap = ({ navigateTo = LINKS_PAGES.home, children, isShowButton = true }: EditPageWrapProps) => {
  return (
    <StyledEditPageWrap>
      {isShowButton && <ModalDiscardAllChanges navigateToUrl={navigateTo} />}

      {children}
    </StyledEditPageWrap>
  );
};
