import { FormProvider, useForm } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import { Grid } from '@mui/material';

import {
  DomainStatusChip,
  Flex,
  ImageLazy,
  ModalDiscardAllChanges,
  MultiStepFooter,
  MultiStepHeader,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { Steps } from './components/Steps';
import { useContextState } from './ContextProvider';

import {
  DEFAULT_VALUES,
  FORM_ID,
  FormProps,
  maxDate,
  minDate,
  TASK_BY_ROUND_SERVICE_STUFF,
  useData,
  useRoundData
} from './helpers';

export const ContextConsumer = () => {
  const { templateId = '', roundId = '' } = useParams();
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const { equipmentItemNameData, equipmentItemNameLoading } = useData({ templateId });

  const { dataEquipmentTemplateRoundRegulation } = useRoundData({ roundId });

  const { setState, handleSetPrevStep, ...state } = useContextState();

  const methods = useForm<FormProps>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const handleSubmitForm = methods.handleSubmit((data) => {
    if (
      !data.taskByRoundDate?.date.isValid() ||
      !data.taskByRoundDate?.time.isValid() ||
      data.taskByRoundDate?.date < minDate ||
      data.taskByRoundDate?.date > maxDate
    ) {
      return;
    }
    if (state.currentStep < state.totalSteps) {
      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    }
  });

  const watchedFields = useWatch({ control: methods.control });

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={10} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            {equipmentItemNameLoading ? (
              <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '300px !important' }} isContainer />
            ) : (
              <>
                <ModalDiscardAllChanges
                  navigateToUrl={`/templates/equipment/item/${templateId}/rounds/round-regulations/${roundId}`}
                  sxBox={{ margin: '0 0 32px' }}
                />

                <MultiStepHeader
                  title={tTemplates('equipmentItem.round.taskByRound.title')}
                  titleStep={tGlobal('multiStep.titleStep', {
                    currentStep: state.currentStep,
                    totalSteps: state.totalSteps
                  })}
                  description={
                    <>
                      <Trans
                        i18nKey={'Create a single-time task based on the round regulation'}
                        values={{ equipmentName: equipmentItemNameData?.equipment.name }}
                        components={{ span: <span style={{ fontWeight: 600 }} /> }}
                      />
                      <Flex
                        sx={{
                          marginTop: '16px',
                          borderRadius: '4px',
                          background: '#F7F8F9',
                          width: 'fit-content',
                          maxWidth: '380px',
                          height: '72px',
                          alignItems: 'flex-start',
                          padding: '12px 16px',
                          justifyContent: 'space-around',
                          flexDirection: 'column'
                        }}
                      >
                        <Text
                          sx={{
                            fontWeight: '500',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            wordBreak: 'break-all'
                          }}
                        >
                          {dataEquipmentTemplateRoundRegulation?.dataItems.name}
                        </Text>
                        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                          {equipmentItemNameData?.equipment.fileUrl && (
                            <ImageLazy
                              width={24}
                              height={24}
                              sxStyle={{ borderRadius: '2px' }}
                              src={
                                equipmentItemNameData?.equipment.fileUrl
                                  ? equipmentItemNameData?.equipment.fileUrl.url
                                  : ''
                              }
                              alt={
                                equipmentItemNameData?.equipment.fileUrl
                                  ? equipmentItemNameData?.equipment.fileUrl.fileKey
                                  : ''
                              }
                            />
                          )}
                          <Text
                            sx={{
                              marginLeft: equipmentItemNameData?.equipment.fileUrl ? '8px' : '0px',
                              fontWeight: '500',
                              marginRight: '16px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              wordBreak: 'break-all',
                              fontSize: '12px'
                            }}
                          >
                            {equipmentItemNameData?.equipment.name}
                          </Text>
                          <DomainStatusChip
                            color={equipmentItemNameData?.equipment.domain.color}
                            label={equipmentItemNameData?.equipment.domain.name}
                          />
                        </Flex>
                      </Flex>
                    </>
                  }
                  currentStep={state.currentStep}
                  totalStep={state.totalSteps}
                  sxDescription={{ maxWidth: 660 }}
                />
              </>
            )}
            <Steps step={`step${state.currentStep}`} />

            <MultiStepFooter
              btnNextStepText={state.currentStep === 2 ? 'Select & Create task' : tGlobal('multiStep.btnNextStep')}
              btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
              btnCreateTemplateText={
                watchedFields[TASK_BY_ROUND_SERVICE_STUFF]?.length
                  ? tTemplates('equipmentItem.round.taskByRound.btnSelectCreate')
                  : tTemplates('equipmentItem.round.taskByRound.btnCreate')
              }
              isLoadingBtnCreate={false}
              isDisabledBtnCreate={Boolean(
                state.currentStep === 2 &&
                  !watchedFields[TASK_BY_ROUND_SERVICE_STUFF]?.every((item: any) =>
                    item.domains.some((domain: any) => domain.name === equipmentItemNameData?.equipment.domain.name)
                  )
              )}
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
              isRound
              handleSetPrevStep={handleSetPrevStep}
            />
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
