export const Project = {
  root: {},
  paper: {
    borderRadius: 'var(--4, 4px)',
    border: '0.5px solid var(--border-disabled, rgba(9, 14, 22, 0.06))',
    boxShadow: '0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)'
  }
};

export const Base = {
  root: {}
};
