import { ReactNode, useState } from 'react';

import Grid from '@mui/material/Grid';

import { BlankFlow, ChooseFlow } from './components';

import { StyledCreateCompany } from './style';

const FlowComponents: Record<string, ReactNode> = {
  blank: <BlankFlow />
};

export const CompanyCreatePage = () => {
  const [selectedFlow, setSelectedFlow] = useState('');

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <StyledCreateCompany>
          {selectedFlow ? FlowComponents[selectedFlow] : <ChooseFlow setSelectedFlow={setSelectedFlow} />}
        </StyledCreateCompany>
      </Grid>
    </Grid>
  );
};
