import { Text, TextField } from 'infinitytechnologies-ui';

import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';

import { Breadcrumbs } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { AutocompleteExample } from './components/AutocompleteExample';
import { SelectExample } from './components/SelectExample';
import { SELECT_DATA_EXAMPLE } from './components/SelectExample/select-example-mock';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiTextFields, titleFallback: 'TextFields' }
];

export const UiTextFieldsPage = () => {
  return (
    <>
      <Grid justifyContent={'center'} container row>
        <Grid xs={12} item>
          <Breadcrumbs links={BreadcrumbsLinks} />
        </Grid>

        <Grid xs={12} item>
          <Text className={'mt-5 mb-5'} align={'center'} variant={'h5'} sx={{ marginBottom: '16px' }}>
            TextFields
          </Text>
        </Grid>

        <Grid lg={4} item>
          <Grid justifyContent={'center'} container row>
            <Grid xs={12} item>
              <Text className={'mt-5 mb-5'} component={'h4'} align={'center'} sx={{ fontSize: 20, weight: 600 }}>
                TextFields Empty
              </Text>
            </Grid>
            <Grid xs={12} item>
              <TextField name={'test-1-1'} value={''} label={'Label'} helperText={'Supporting text'} autoFocus />
            </Grid>
            <Grid xs={12} item>
              <TextField name={'test-1-2'} value={''} label={'Label'} helperText={'Supporting text'} />
            </Grid>
            <Grid xs={12} item>
              <TextField name={'test-1-3'} value={''} label={'Label'} helperText={'Supporting text'} />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test'}
                label={'Label'}
                value={''}
                helperText={'Supporting text'}
                validation={{ error: 'Error text', isValid: false }}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <TextField name={'test'} label={'Label'} value={''} helperText={'Supporting text'} disabled />
            </Grid>
          </Grid>
        </Grid>

        <Grid lg={4} item>
          <Grid container>
            <Grid xs={12} item>
              <Text className={'mt-5 mb-5'} component={'h4'} align={'center'} sx={{ fontSize: 20, weight: 600 }}>
                with left Icon
              </Text>
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-2-1'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-2-2'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-2-3'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-2-4'}
                label={'Label'}
                value={''}
                helperText={'Supporting text'}
                validation={{ error: '', isValid: false }}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-2-5'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid lg={4} item>
          <Grid container>
            <Grid xs={12} item>
              <Text className={'mt-5 mb-5'} component={'h4'} align={'center'} sx={{ fontSize: 20, weight: 600 }}>
                with right Icon
              </Text>
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-3-1'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-3-2'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-3-3'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-3-4'}
                label={'Label'}
                value={''}
                helperText={'Supporting text'}
                validation={{ error: 'Error text', isValid: false }}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-3-5'}
                label={'Label'}
                value={''}
                helperText={'Some important helper text'}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: 'var(--default-gray-500)' }} />
                }}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid lg={4} item>
          <Grid container>
            <Grid xs={12} item>
              <Text className={'mt-5 mb-5'} component={'h4'} align={'center'} sx={{ fontSize: 20, weight: 600 }}>
                with input value
              </Text>
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-4-1'}
                label={'Label'}
                value={'Value some important helper text'}
                helperText={'Some important helper text'}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-4-2'}
                label={'Label'}
                value={'Value some important helper text'}
                helperText={'Some important helper text'}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-4-3'}
                label={'Label'}
                value={'Value some important helper text'}
                helperText={'Some important helper text'}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-4-4'}
                label={'Label'}
                value={'Value some important helper text'}
                helperText={'Supporting text'}
                validation={{ error: '', isValid: false }}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                name={'test-4-5'}
                label={'Label'}
                value={'Value some important helper text'}
                helperText={'Some important helper text'}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid lg={4} item>
          <Grid container>
            <Grid xs={12} item>
              <Text className={'mt-5 mb-5'} component={'h4'} align={'center'} sx={{ fontSize: 20, weight: 600 }}>
                Select
              </Text>
            </Grid>
            <Grid xs={12} item>
              <SelectExample
                exampleType={'Default'}
                label={'DefaultLabel'}
                name={'select-example-name-1'}
                value={[SELECT_DATA_EXAMPLE[9].value]}
                onChange={() => {}}
              />
            </Grid>
            <Grid xs={12} item>
              <SelectExample
                exampleType={'Multiple'}
                label={'Lorem ipsum dolor sit amet, consectetur adipisicing.'}
                name={'select-example-name-1'}
                value={[SELECT_DATA_EXAMPLE[9].value]}
                multiple
                onChange={() => {}}
              />
            </Grid>
            <Grid xs={12} item>
              <SelectExample
                exampleType={'Error'}
                label={'DefaultLabel'}
                name={'select-example-name-2'}
                helperText={'Supporting text'}
                value={[SELECT_DATA_EXAMPLE[0].value]}
                validation={{ error: '', isValid: false }}
                onChange={() => {}}
              />
            </Grid>
            <Grid xs={12} item>
              <SelectExample
                exampleType={'Error'}
                label={'DefaultLabel'}
                name={'select-example-name-2'}
                helperText={'Supporting text'}
                value={[SELECT_DATA_EXAMPLE[0].value]}
                validation={{ error: 'Error text', isValid: false }}
                onChange={() => {}}
              />
            </Grid>
            <Grid xs={12} item>
              <SelectExample
                exampleType={'Disabled'}
                label={'DefaultLabel'}
                name={'select-example-name-2'}
                helperText={'Supporting text'}
                value={[SELECT_DATA_EXAMPLE[0].value]}
                disabled
                onChange={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid lg={4} item>
          <Grid container>
            <Grid xs={12} item>
              <Text className={'mt-5 mb-5'} component={'h4'} align={'center'} sx={{ fontSize: 20, weight: 600 }}>
                Autocomplete
              </Text>
            </Grid>
            <Grid xs={12} item>
              <AutocompleteExample
                exampleType={'Default'}
                label={'Lorem ipsum dolor sit amet, consectetur adipisicing.'}
              />
            </Grid>
            <Grid xs={12} item>
              <AutocompleteExample exampleType={'Search input'} label={'Search input'} freeSolo />
            </Grid>
            <Grid xs={12} item>
              <AutocompleteExample exampleType={'Disabled'} label={'disabled'} disabled />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
