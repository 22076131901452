export const Project = {
  root: {
    margin: '0',
    '.MuiInputLabel-root': {
      color: 'var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))',
      letterSpacing: '0',
      '&:not(.MuiInputLabel-shrink)': {
        transform: 'translate(16px, 16px) scale(1)'
      },
      '&.Mui-focused': {
        color: 'var(--Custom-styles-Main-Black, #111827)'
      },
      '&.Mui-error': {
        color: 'var(--Custom-styles-Main-Error, #BD1537)'
      },
      '&.Mui-disabled': {
        color: 'var(--custom-styles-grey-30, rgba(17, 24, 39, 0.30))'
      }
    },
    '.MuiInputBase-root': {
      color: 'var(--text-default, #090E16)',
      '.MuiIconButton-root': {
        marginRight: '-4px'
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '4px',
        border: '1px solid var(--custom-styles-grey-20, rgba(17, 24, 39, 0.20))'
      },
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))'
        }
      },
      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--Custom-styles-Main-Error, #BD1537)'
        }
      },
      '&.Mui-disabled': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--custom-styles-grey-10, rgba(17, 24, 39, 0.10))'
        }
      }
    },
    '.MuiFormHelperText-root': {
      paddingLeft: '16px'
    },
    '&:hover': {
      '.MuiInputLabel-root': {
        color: 'var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))'
      },
      '.MuiInputBase-root': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))'
        }
      }
    },
    ' + .MuiTextField-root': {
      margin: '16px 0 0'
    },
    '.MuiInputBase-multiline': {
      minHeight: '56px',
      height: 'auto'
    }
  }
};

export const Base = {
  root: {
    margin: '12px 0',
    minWidth: '200px',
    width: '100%',
    '&:hover': {
      '.MuiInputLabel-root': {
        color: 'var(--default-gray-800)'
      },
      '.MuiInputBase-root': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--default-gray-800)'
        }
      }
    },
    '.MuiInputLabel-root': {
      color: 'var(--default-gray-500)',
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0',
      '&.Mui-focused': {
        color: 'var(--primary-800)'
      },
      '&.Mui-error': {
        color: 'var(--extended-red-700)'
      },
      '&.Mui-disabled': {
        color: 'var(--default-gray-500)',
        opacity: '0.4'
      }
    },
    '.MuiInputBase-root': {
      height: '56px',
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--default-gray-800)',
      '&.MuiInputBase-adornedStart': {
        paddingLeft: '12px'
      },
      '&.MuiInputBase-adornedEnd': {
        paddingRight: '12px'
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px',
        border: '1px solid var(--default-gray-300)',
        transition: 'all 0.2s ease-in-out'
      },
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--primary-800)'
        }
      },
      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--extended-red-700)'
        }
      },
      '&.Mui-disabled': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--default-gray-200) !important'
        }
      }
    },
    '.MuiFormHelperText-root': {
      '&.Mui-disabled': {
        opacity: '0.4'
      }
    }
  }
};
