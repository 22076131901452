import { SyntheticEvent, useState } from 'react';
import { TextField } from 'infinitytechnologies-ui';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AutocompleteProps as MuiAutocompleteProps, default as MuiAutocomplete } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

import { ChipIcon, Flex } from '@/separatedModules/components';

export type AutocompleteOptionT = {
  label: string;
  value: string | number;
  [key: string]: any;
};

type AutocompleteValueT = (string | AutocompleteOptionT)[];

interface AutocompletePropsUpdated
  extends MuiAutocompleteProps<AutocompleteOptionT | string, true, true, true, 'div'> {}

export interface AutocompleteTagsProps extends Omit<AutocompletePropsUpdated, 'renderInput' | 'renderTags'> {
  className?: string;
  name: string;
  label: string;
  helperText?: string;
  placeholder?: string;
  validation?: {
    error: string | boolean | undefined;
    isValid?: boolean;
  };
  onChange?: (e: SyntheticEvent, value: AutocompleteValueT) => void;
  onOpen?: (e: SyntheticEvent) => void;
  onClose?: (e: SyntheticEvent) => void;
}

const AutocompleteTags = ({
  className,
  name,
  label,
  placeholder,
  helperText,
  validation,
  loading,
  options = [],
  freeSolo,
  autoFocus,
  onChange,
  onOpen,
  onClose,
  defaultValue,
  ...props
}: AutocompleteTagsProps) => {
  const [value, setValue] = useState<AutocompleteValueT>(defaultValue as AutocompleteValueT);

  const handleChange = (e: SyntheticEvent, newValue: AutocompleteValueT) => {
    setValue(newValue);
    onChange?.(e, newValue);
  };

  const handleOpen = (e: SyntheticEvent) => {
    onOpen?.(e);
  };

  const handleClose = (e: SyntheticEvent) => {
    onClose?.(e);
  };

  const isError = validation && !validation?.isValid;

  return (
    <MuiAutocomplete
      {...props}
      value={value}
      defaultValue={defaultValue}
      className={className}
      multiple
      // TODO use value instead label
      options={options.map((option) => (option as AutocompleteOptionT)?.label)}
      freeSolo={freeSolo}
      disableClearable
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            name={name}
            autoFocus={autoFocus}
            validation={validation}
            sx={{
              '.MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: isError ? 'var(--extended-red-700) !important' : 'black !important'
                }
              },
              '.MuiOutlinedInput-root': {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: isError ? 'var(--extended-red-700) !important' : 'black !important'
                },
                '&.MuiOutlinedInput-notchedOutline': {
                  borderColor: isError ? 'var(--extended-red-700) !important' : 'black !important'
                }
              }
            }}
            helperText={
              isError ? (
                <Flex alignItems="center">
                  <ErrorOutlineIcon sx={{ fontSize: 16, marginRight: '4px' }} color="error" />

                  <span style={{ color: '#BE0E2C' }}>{validation.error || helperText}</span>
                </Flex>
              ) : helperText ? (
                <span style={{ color: '#BE0E2C' }}>{helperText}</span>
              ) : null
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '8px' }}
                >
                  {loading ? <CircularProgress color={'inherit'} size={20} /> : null}
                  {props.popupIcon ? props.popupIcon : null}
                </InputAdornment>
              )
            }}
          />
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });

          return <Chip {...tagProps} label={option as string} key={key} deleteIcon={<ChipIcon />} />;
        });
      }}
      loading={loading}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
    />
  );
};

export { AutocompleteTags };
