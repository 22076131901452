import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { AuthService } from '@/logicLayers/infrastructure/services';

import { API_GRAPHQL_URL, API_WS_URL } from '@/config';

const httpLink = new HttpLink({
  uri: API_GRAPHQL_URL
});

const wsLink = API_WS_URL
  ? new GraphQLWsLink(
      createClient({
        url: API_WS_URL || '',
        connectionParams: {
          authorization: `Bearer ${AuthService.getAccessToken()}` || null
        },
        retryAttempts: 5,
        shouldRetry: () => true
      })
    )
  : null;

// ToDo ToDo TS types
const logoutLink = onError(({ response, graphQLErrors, networkError }: any) => {
  // ToDo Remove
  console.log('onError graphQLErrors - ', graphQLErrors);

  if (networkError) {
    // ToDo Remove
    console.log('onError networkError - ', networkError);

    if (networkError.statusCode === 401) AuthService.handleLogOut();
  }

  if (response) {
    // ToDo Remove
    console.log('onError response - ', response);
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        authorization: `Bearer ${AuthService.getAccessToken()}` || null
      }
    };
  });

  return forward(operation);
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink ? wsLink : httpLink,
  httpLink
);

const ClientApollo = new ApolloClient({
  link: ApolloLink.from([authMiddleware, logoutLink, link]),
  cache: new InMemoryCache()
});

interface ApolloProviderProps {
  children: React.ReactNode;
}

export function ApolloGraphqlProvider({ children }: ApolloProviderProps) {
  return <ApolloProvider client={ClientApollo}>{children}</ApolloProvider>;
}
