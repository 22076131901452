import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DescriptionIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M7.49998 14.7917H12.5C12.6773 14.7917 12.8258 14.7318 12.9455 14.6122C13.0651 14.4925 13.125 14.344 13.125 14.1667C13.125 13.9893 13.0651 13.8408 12.9455 13.7212C12.8258 13.6015 12.6773 13.5417 12.5 13.5417H7.49998C7.32263 13.5417 7.17413 13.6015 7.05448 13.7212C6.93483 13.8408 6.875 13.9893 6.875 14.1667C6.875 14.344 6.93483 14.4925 7.05448 14.6122C7.17413 14.7318 7.32263 14.7917 7.49998 14.7917ZM7.49998 11.4583H12.5C12.6773 11.4583 12.8258 11.3985 12.9455 11.2789C13.0651 11.1592 13.125 11.0107 13.125 10.8334C13.125 10.656 13.0651 10.5075 12.9455 10.3879C12.8258 10.2682 12.6773 10.2084 12.5 10.2084H7.49998C7.32263 10.2084 7.17413 10.2682 7.05448 10.3879C6.93483 10.5075 6.875 10.656 6.875 10.8334C6.875 11.0107 6.93483 11.1592 7.05448 11.2789C7.17413 11.3985 7.32263 11.4583 7.49998 11.4583ZM5.25642 17.9167C4.83547 17.9167 4.47917 17.7708 4.1875 17.4792C3.89583 17.1875 3.75 16.8312 3.75 16.4103V3.58979C3.75 3.16885 3.89583 2.81254 4.1875 2.52087C4.47917 2.22921 4.83547 2.08337 5.25642 2.08337H11.2516C11.4525 2.08337 11.6456 2.12237 11.8309 2.20037C12.0163 2.27836 12.1773 2.38573 12.3141 2.52248L15.8109 6.01923C15.9476 6.15599 16.055 6.31706 16.133 6.50242C16.211 6.68778 16.25 6.88088 16.25 7.08173V16.4103C16.25 16.8312 16.1041 17.1875 15.8125 17.4792C15.5208 17.7708 15.1645 17.9167 14.7435 17.9167H5.25642ZM11.25 6.33012V3.33335H5.25642C5.19231 3.33335 5.13353 3.36006 5.0801 3.41348C5.02669 3.46691 4.99998 3.52568 4.99998 3.58979V16.4103C4.99998 16.4744 5.02669 16.5331 5.0801 16.5866C5.13353 16.64 5.19231 16.6667 5.25642 16.6667H14.7435C14.8077 16.6667 14.8664 16.64 14.9199 16.5866C14.9733 16.5331 15 16.4744 15 16.4103V7.08333H12.0032C11.7885 7.08333 11.6093 7.01148 11.4656 6.86777C11.3219 6.72408 11.25 6.54486 11.25 6.33012Z" />
    </SvgIcon>
  );
}
