import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const FormulaWrapper = styled(Stack)(() => ({
  border: '1px solid #090E1626',
  borderRadius: '4px'
}));

export const FormulaBlock = styled(Box)(() => ({
  padding: '24px'
}));

export const FormulaTopBlock = styled(FormulaBlock)(() => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',
  background: '#F7F8F9',
  borderBottom: '2px solid #090E1626'
})) as typeof Stack;

export const EditBlock = styled(Box)(({ editable, error }: { editable: boolean; error: boolean }) => ({
  minHeight: '56px',
  background: editable ? 'transparent' : 'rgba(9, 14, 22, 0.03)',
  padding: '12px',
  border: `1px dashed ${editable && !error ? '#505668' : error ? '#BE0E2C' : 'rgba(9, 14, 22, 0.30)'}`,
  borderRadius: '4px',
  display: 'flex',
  flexWrap: 'wrap',

  input: {
    minWidth: '8px',
    width: '8px',
    height: '32px',
    border: 'none',
    paddingLeft: '2px',
    background: 'transparent',
    // background: '#E6F6EF',
    borderRadius: '4px',
    fontSize: '16px',

    /* Chrome, Safari, Edge, Opera */
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },

    /* Firefox */
    'input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },

  '.input-wrapper input:empty + .remove-btn': {
    display: 'none'
  },

  /* Show close btn when number input is valid and not empty*/
  '.input-wrapper input:valid:hover + .remove-btn': {
    display: 'block'
  },

  '.input-wrapper input:valid': {
    border: '1px solid rgba(9, 14, 22, 0.15)'
  },

  '.MuiChip-root': {
    position: 'relative',
    pointerEvents: 'auto',

    '&:hover .remove-btn': {
      display: 'flex'
    }
  },

  '.input-wrapper': {
    position: 'relative',

    '&:hover .remove-btn': {
      display: 'flex'
    }
  },

  '.remove-btn': {
    width: '15px',
    height: '15px',
    background: 'orangered',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    borderRadius: '50%',
    border: 'none',
    fontWeight: 500,
    position: 'absolute',
    right: 0,
    top: -7
  },

  '.remove-btn:hover': {
    cursor: 'pointer'
  }
}));

export const ChipWrapper = styled('div')(() => ({
  background: 'transparent',
  border: 'none',
  padding: 0,

  ':hover': {
    cursor: 'pointer'
  }
}));

export const ParameterChip = styled(Chip)(() => ({
  background: '#EFFFD6',
  border: '1px solid rgba(9, 14, 22, 0.06)',
  borderRadius: '4px',
  fontWeight: 500,
  pointerEvents: 'none'
}));

export const MathChip = styled(ParameterChip)(() => ({
  background: '#FFECF8'
}));
