import { Global } from '@emotion/react';
import { Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

import {
  BellIcon,
  Breadcrumbs,
  ChatIcon,
  CheckIcon,
  ErrorIcon,
  IdeaIcon,
  ImageIcon,
  LockIcon,
  LogOutCustomIcon,
  MagnifyingGlassIcon,
  MailIcon,
  PaperPlaneIcon,
  PasswordIcon,
  QuestionIcon,
  RocketIcon,
  Spinner,
  TimeIcon,
  VoteIcon,
  WelcomeIcon
} from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiOther, titleFallback: 'Other components' }
];

export function UiIconsPage() {
  return (
    <>
      <Grid justifyContent={'center'} container row>
        <Grid xs={12} item>
          <Breadcrumbs className={'mb-10'} links={BreadcrumbsLinks} />
        </Grid>

        <Grid md={10} lg={8} item>
          <Text className={'mb-10 text-center'} variant={'h5'} sx={{ marginBottom: '16px' }}>
            Custom icons
          </Text>

          <div className={'icons-list'}>
            <BellIcon sx={{ fontSize: 80 }} />

            <CheckIcon sx={{ fontSize: 80 }} />

            <ErrorIcon sx={{ fontSize: 80 }} />

            <ChatIcon sx={{ fontSize: 80 }} />

            <IdeaIcon sx={{ fontSize: 80 }} />

            <ImageIcon sx={{ fontSize: 80 }} />

            <LockIcon sx={{ fontSize: 80 }} />

            <LogOutCustomIcon sx={{ fontSize: 80 }} />

            <MagnifyingGlassIcon sx={{ fontSize: 80 }} />

            <MailIcon sx={{ fontSize: 80 }} />

            <WelcomeIcon sx={{ fontSize: 80 }} />

            <VoteIcon sx={{ fontSize: 80 }} />

            <TimeIcon sx={{ fontSize: 80 }} />

            <RocketIcon sx={{ fontSize: 80 }} />

            <QuestionIcon sx={{ fontSize: 80 }} />

            <PasswordIcon sx={{ fontSize: 80 }} />

            <PaperPlaneIcon sx={{ fontSize: 80 }} />

            <Spinner fill={'var(--primary-800)'} size={40} />
          </div>
        </Grid>
      </Grid>

      <Global
        styles={{
          '.icons-list .MuiSvgIcon-root': {
            margin: '0 12px 12px 0'
          }
        }}
      />
    </>
  );
}
