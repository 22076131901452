import { styled } from '@mui/material/styles';

export const StyledNavListSimple = styled('div')(() => ({
  '.nav-simple': {
    '&__i': {
      padding: '8px 12px',
      margin: '0 0 4px 0',
      display: 'flex',
      alignItems: 'center',
      transition: 'all 0.2s ease-in-out',
      position: 'relative',
      '&--active, &:hover': {
        borderRadius: 'var(--4, 4px)',
        background: 'var(--background-selected-hovered, #E9F2FF)'
      },
      '&--disabled': {
        pointerEvents: 'none !important',
        background: 'var(--background-neutral-subtle-hovered, rgba(9, 14, 22, 0.06)) !important',
        '*': {
          color: 'var(--text-disabled, rgba(9, 14, 22, 0.30)) !important',
          fill: 'var(--text-disabled, rgba(9, 14, 22, 0.30)) !important'
        }
      },
      '&__wrp': {
        position: 'relative',
        '&:hover': {
          "[class*='btn-redirect']": {
            visibility: 'visible'
          }
        }
      },
      '&__text': {
        color: 'var(--text-default, #090E16)',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        margin: '0 0 0 8px'
      },
      '&__btn': {
        '&-redirect': {
          position: 'absolute',
          top: '50%',
          right: '4px',
          margin: '-12px 0 0 0',
          zIndex: 5,
          visibility: 'hidden'
        }
      }
    }
  }
}));
