import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function NotificationsIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M4.375 15.7371C4.19792 15.7371 4.04948 15.6771 3.92969 15.5573C3.8099 15.4375 3.75 15.289 3.75 15.1118C3.75 14.9347 3.8099 14.7862 3.92969 14.6666C4.04948 14.5469 4.19792 14.4871 4.375 14.4871H5.25642V8.26913C5.25642 7.14841 5.6023 6.15749 6.29406 5.29638C6.98584 4.43527 7.87393 3.88452 8.95833 3.64415V3.12492C8.95833 2.83557 9.05951 2.58963 9.26188 2.38709C9.46424 2.18453 9.70996 2.08325 9.99904 2.08325C10.2881 2.08325 10.5342 2.18453 10.7371 2.38709C10.9401 2.58963 11.0416 2.83557 11.0416 3.12492V3.64415C12.126 3.88452 13.0141 4.43527 13.7059 5.29638C14.3977 6.15749 14.7435 7.14841 14.7435 8.26913V14.4871H15.625C15.802 14.4871 15.9505 14.547 16.0703 14.6669C16.1901 14.7867 16.25 14.9352 16.25 15.1124C16.25 15.2895 16.1901 15.4379 16.0703 15.5576C15.9505 15.6772 15.802 15.7371 15.625 15.7371H4.375ZM9.99856 18.0768C9.58391 18.0768 9.22942 17.9293 8.93508 17.6343C8.64075 17.3393 8.49358 16.9847 8.49358 16.5704H11.5064C11.5064 16.986 11.3587 17.341 11.0635 17.6353C10.7682 17.9296 10.4132 18.0768 9.99856 18.0768ZM6.50638 14.4871H13.4936V8.26913C13.4936 7.30438 13.1525 6.48093 12.4703 5.79877C11.7882 5.11661 10.9647 4.77552 9.99998 4.77552C9.03523 4.77552 8.21178 5.11661 7.52963 5.79877C6.84746 6.48093 6.50638 7.30438 6.50638 8.26913V14.4871Z" />
    </SvgIcon>
  );
}
