import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ChevronRightIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M12.9467 12L8.87358 7.92689C8.73511 7.78844 8.66428 7.6144 8.66108 7.40479C8.65786 7.19519 8.72869 7.01795 8.87358 6.87309C9.01844 6.7282 9.19408 6.65576 9.40048 6.65576C9.60688 6.65576 9.78251 6.7282 9.92738 6.87309L14.4216 11.3673C14.5152 11.4609 14.5812 11.5596 14.6197 11.6635C14.6581 11.7673 14.6774 11.8795 14.6774 12C14.6774 12.1205 14.6581 12.2327 14.6197 12.3365C14.5812 12.4404 14.5152 12.5391 14.4216 12.6327L9.92738 17.1269C9.78893 17.2654 9.61489 17.3362 9.40527 17.3394C9.19568 17.3426 9.01844 17.2718 8.87358 17.1269C8.72869 16.982 8.65625 16.8064 8.65625 16.6C8.65625 16.3936 8.72869 16.218 8.87358 16.0731L12.9467 12Z" />
    </SvgIcon>
  );
}
