import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import { CREATE_REGION, GET_REGIONS_SIDEBAR, MutationCreateLocationFolderArgs } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_FOLDER_NAME } from '../../helpers';

import { StyledDialog } from './style';

interface FormProps {
  [FIELD_FOLDER_NAME]: string;
}

export type ModalSize = 'big' | 'base';

interface AddFolderModalProps {
  isModalOpen: boolean;
  folderId: string;
  modalSize?: ModalSize;
  onCloseModal: () => void;
}

export const AddFolderModal: FC<AddFolderModalProps> = ({
  isModalOpen,
  folderId,
  modalSize = 'base',
  onCloseModal
}) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tCompany } = useTranslation('company');

  const [CreateFolder] = useMutation<MutationCreateLocationFolderArgs>(CREATE_REGION, {
    variables: {
      parentId: folderId?.length && folderId !== 'root' ? folderId : null
    },
    refetchQueries: [GET_REGIONS_SIDEBAR]
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_FOLDER_NAME]: ''
    }
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    CreateFolder({
      variables: { name: data.name },
      onCompleted: () => {
        handleCloseModal();
        // TODO: here some response issue with the parent id
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.folderCreated'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <StyledDialog
      modalSize={modalSize}
      headerTitle={tCompany('actions.modal.create.title')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tCompany('actions.modal.create.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_FOLDER_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tCompany('actions.modal.create.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        <Controller
          name={FIELD_FOLDER_NAME}
          control={control}
          rules={{
            required: {
              value: true,
              message: tGlobal('validation.required')
            },
            pattern: {
              value: ROLE_NAME_REG_EXP,
              message: tGlobal('validation.textFieldLength')
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextFieldControl
                label={tCompany('actions.modal.create.folderInputLabel')}
                placeholder={tCompany('actions.modal.create.folderInputPlaceholder')}
                name={FIELD_FOLDER_NAME}
                value={value}
                validation={{
                  isValid: !errors[FIELD_FOLDER_NAME]?.message,
                  error: errors[FIELD_FOLDER_NAME]?.message
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
      </form>
    </StyledDialog>
  );
};
