import { ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_COMPANY, GET_USER_PROFILE, GetCompanyQuery, GetProfileQuery } from '@/logicLayers/domain';
import { useActions } from '@/logicLayers/infrastructure/redux/modules/hooks';

import { Preloader, PreloaderVariantsE } from '@/separatedModules/components';

type ActionsGlobalProps = { children: ReactNode };

export const ActionsGlobal = ({ children }: ActionsGlobalProps) => {
  const { setUserProfile, setUserCompany } = useActions();

  const [isLoading, setIsLoading] = useState(true);

  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE, {});

  const { refetch: refetchUserCompany } = useQuery<GetCompanyQuery>(GET_COMPANY, {
    fetchPolicy: 'network-only',
    skip: true
  });

  const getUserCompanyData = async (companyId: string) => {
    return await refetchUserCompany({ companyId }).then(({ data }) => data);
  };

  const getData = async () => {
    if (dataUserProfile?.profile?.companyId) {
      const dataUserCompany = await getUserCompanyData(dataUserProfile.profile.companyId);

      setUserCompany(dataUserCompany);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataUserProfile?.profile?.companyId) {
      setUserProfile(dataUserProfile);

      getData();
    }
  }, [dataUserProfile]);

  return isLoading ? <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer /> : children;
};
