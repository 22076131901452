import { ReactNode } from 'react';

import Grid from '@mui/material/Grid';

import { StyledHeaderWrap } from './style';

interface HeaderWrapProps {
  children: ReactNode;
}

export const HeaderWrap = ({ children }: HeaderWrapProps) => {
  return (
    <StyledHeaderWrap>
      <Grid className={'header__container'} container>
        <Grid justifyContent={'space-between'} alignItems={'center'} container row sx={{ flexWrap: 'nowrap' }}>
          {children}
        </Grid>
      </Grid>
    </StyledHeaderWrap>
  );
};
