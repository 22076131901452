import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

import { USER_NUMBER_MASK } from '@/utils';

import { useTranslation } from '@/i18n';

interface TextFieldMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const TextFieldMask = forwardRef<HTMLInputElement, TextFieldMaskProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  const { i18n } = useTranslation('global');

  const maskPattern = USER_NUMBER_MASK[i18n.language];

  return (
    <IMaskInput
      {...other}
      mask={maskPattern.pattern}
      definitions={USER_NUMBER_MASK.definitions}
      inputRef={ref}
      overwrite
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    />
  );
});
