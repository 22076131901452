import merge from 'lodash/merge';

import { GetCompanyQuery, UpdateCompanyReqInput } from '@/logicLayers/domain';

export const updateCompanyModel = (
  data: GetCompanyQuery,
  update: Partial<UpdateCompanyReqInput>
): UpdateCompanyReqInput => {
  return merge(
    {
      name: data.company.name || '',
      address: data.company.address || '',
      fileKey: data.company.logo?.fileKey || '',
      language: data.company.language,
      contactInfo: {
        representative: data.company.contactInfo.representative || '',
        phoneNumber: data.company.contactInfo.phoneNumber || '',
        email: data.company.contactInfo.email || ''
      }
    },
    update
  );
};
