import { useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';

import { EquipmentItemInformationQuery, GET_EQUIPMENT_ITEM_INFORMATION } from '@/logicLayers/domain';

export interface StepsStateI {
  currentStep: number;
  totalSteps: number;
  parameterType: string;
  isDataCreated: boolean;
}

export const FORM_ID = 'create-round-regulation';
export const ROUND_TITLE = 'title';
export const ROUND = 'round';
export const ROUND_PARAMETERS = 'roundParameters';
export const ROUND_DATE = 'roundDate';
export const ROUND_DESCRIPTION = 'description';
export const ROUND_USERS = 'users';
export const ROUND_MAX_VALUE = 'roundMaxValue';
export const ROUND_MIN_VALUE = 'roundMinValue';
export const ROUND_EXEC_VALUE = 'roundExecValue';
export const ROUND_SELECTED_PARAMETERS = 'roundSelectedParameters';

export interface FormProps {
  [ROUND_TITLE]?: string;
  [ROUND]?: string;
  [ROUND_PARAMETERS]?: [];
  [ROUND_DATE]?: {
    date: Dayjs;
    time: Dayjs;
  };
  [ROUND_DESCRIPTION]?: string;
  [ROUND_SELECTED_PARAMETERS]?: [];
  [ROUND_MAX_VALUE]?: '';
  [ROUND_MIN_VALUE]?: '';
  [ROUND_EXEC_VALUE]?: '';
  [ROUND_USERS]?: string[] | [];
}

export const DEFAULT_VALUES: FormProps = {
  roundDate: {
    date: dayjs(),
    time: dayjs()
  },
  roundParameters: [],
  roundMinValue: '',
  roundMaxValue: '',
  roundExecValue: '',
  users: []
};

export const useData = ({ templateId }: { templateId: string }) => {
  const { data: equipmentItemNameData, loading: equipmentItemNameLoading } = useQuery<EquipmentItemInformationQuery>(
    GET_EQUIPMENT_ITEM_INFORMATION,
    {
      variables: {
        id: templateId
      }
    }
  );

  return {
    equipmentItemNameData,
    equipmentItemNameLoading
  };
};
