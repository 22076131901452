import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';

import {
  DomainQuery,
  GET_DOMAIN_BY_ID,
  SEARCH_DOMAINS,
  UPDATE_DOMAIN,
  UpdateDomainMutation
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { CloseIcon, Preloader, PreloaderVariantsE, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_DOMAIN_NAME } from './helpers';

interface FormProps {
  [FIELD_DOMAIN_NAME]: string;
}

interface AddOrEditDomainModalProps {
  domainId?: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const EditDomainModal: FC<AddOrEditDomainModalProps> = ({ domainId, isModalOpen, onCloseModal }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const { data: domainData, loading: domainLoading } = useQuery<DomainQuery>(GET_DOMAIN_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: domainId
    }
  });
  const [UpdateDomain] = useMutation<UpdateDomainMutation>(UPDATE_DOMAIN, {
    refetchQueries: [SEARCH_DOMAINS]
  });

  const domain = domainData?.domain;

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_DOMAIN_NAME]: ''
    }
  });

  useEffect(() => {
    if (domain?.name) {
      setValue(FIELD_DOMAIN_NAME, domain?.name);
    }
  }, [domain?.name]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    if (domain?.name === data[FIELD_DOMAIN_NAME]) {
      handleCloseModal();
      return;
    }

    UpdateDomain({
      variables: { id: domainId, name: data.name },
      onCompleted: ({ updateDomain }) => {
        handleCloseModal();

        if (updateDomain.id) {
          setTimeout(() => {
            AlertService.showAlert({
              title: tGlobal('alertMessages.success.domainEdited'),
              severity: 'success'
            });
          }, 500);
        }
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog open={isModalOpen} onClose={onCloseModal}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 24px' }}>
          <Text variant="titleLg" sx={{ fontSize: 20 }}>
            {tTemplates('domains.table.edit.modal.title')}
          </Text>
          <CloseIcon onClick={onCloseModal} />
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box sx={{ padding: '32px 24px 20px 24px' }}>
          <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            {domainLoading ? (
              <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '150px !important' }} isContainer />
            ) : (
              <>
                <Controller
                  name={FIELD_DOMAIN_NAME}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    pattern: {
                      value: ROLE_NAME_REG_EXP,
                      message: tGlobal('validation.textFieldLength')
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <TextFieldControl
                        label={tTemplates('domains.table.edit.modal.domainInputLabel')}
                        placeholder={tTemplates('domains.table.edit.modal.domainInputPlaceholder')}
                        name={FIELD_DOMAIN_NAME}
                        value={value}
                        validation={{
                          isValid: !errors[FIELD_DOMAIN_NAME]?.message,
                          error: errors[FIELD_DOMAIN_NAME]?.message
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    );
                  }}
                />
              </>
            )}
          </form>
        </Box>
        <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
        <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 24px' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={onCloseModal}>
            {tTemplates('domains.table.edit.modal.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_DOMAIN_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tTemplates('domains.table.edit.modal.btnSave')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
