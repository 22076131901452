import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 18, height: 18 }} viewBox="0 0 18 18" fill="none">
        <path
          d="M8.99998 11.5586C8.90959 11.5586 8.82546 11.5442 8.74759 11.5154C8.6697 11.4865 8.59566 11.437 8.52547 11.3668L6.19327 9.03461C6.08174 8.92308 6.02669 8.79255 6.02814 8.64304C6.02958 8.49353 6.08462 8.36059 6.19327 8.24424C6.30962 8.12791 6.44327 8.06781 6.59422 8.06396C6.74519 8.06011 6.87884 8.11636 6.99519 8.23271L8.4375 9.67502V3.9375C8.4375 3.77789 8.49134 3.64424 8.59903 3.53655C8.70672 3.42885 8.84037 3.375 8.99998 3.375C9.15959 3.375 9.29324 3.42885 9.40093 3.53655C9.50862 3.64424 9.56246 3.77789 9.56246 3.9375V9.67502L11.0048 8.23271C11.1163 8.12118 11.2488 8.06613 11.4021 8.06756C11.5555 8.06901 11.6903 8.12791 11.8067 8.24424C11.9153 8.36059 11.9716 8.49233 11.9754 8.63944C11.9793 8.78655 11.923 8.91828 11.8067 9.03461L9.47449 11.3668C9.4043 11.437 9.33026 11.4865 9.25237 11.5154C9.1745 11.5442 9.09037 11.5586 8.99998 11.5586ZM4.73077 14.625C4.35193 14.625 4.03125 14.4937 3.76875 14.2312C3.50625 13.9687 3.375 13.648 3.375 13.2692V11.798C3.375 11.6384 3.42885 11.5048 3.53655 11.3971C3.64424 11.2894 3.77789 11.2356 3.9375 11.2356C4.09711 11.2356 4.23076 11.2894 4.33845 11.3971C4.44614 11.5048 4.49998 11.6384 4.49998 11.798V13.2692C4.49998 13.3269 4.52402 13.3798 4.57209 13.4279C4.62018 13.4759 4.67308 13.5 4.73077 13.5H13.2692C13.3269 13.5 13.3798 13.4759 13.4279 13.4279C13.4759 13.3798 13.5 13.3269 13.5 13.2692V11.798C13.5 11.6384 13.5538 11.5048 13.6615 11.3971C13.7692 11.2894 13.9029 11.2356 14.0625 11.2356C14.2221 11.2356 14.3557 11.2894 14.4634 11.3971C14.5711 11.5048 14.625 11.6384 14.625 11.798V13.2692C14.625 13.648 14.4937 13.9687 14.2312 14.2312C13.9687 14.4937 13.648 14.625 13.2692 14.625H4.73077Z"
          fill="#3C4656"
        />
      </svg>
    </SvgIcon>
  );
}
