import { MouseEvent, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  EquipmentRes,
  GET_EQUIPMENT,
  GET_EQUIPMENTS,
  SWITCH_EQUIPMENTS_ACTIVE_STATE,
  SWITCH_EQUIPMENTS_ARCHIVE_STATE,
  SwitchEquipmentsActiveStateMutation
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AlertCheckNoticeIcon,
  ArchiveIcon,
  DoNotDisturbOnIcon,
  EditIcon,
  MoreVertIcon,
  StarOutlineIcon
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';
import { TranslationT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

interface RowActionMenuProps extends EquipmentRes {
  isTableVariant: boolean;
  isEquipmentItem?: boolean;
  renderModalContent?: () => ReactNode;
}

interface RowActionMenuState {
  isModalOpen: boolean;
  modalType: string;
}

const getModalData = (
  row: RowActionMenuProps,
  state: RowActionMenuState,
  tTemplates: TranslationT,
  handleChangeItemActiveState: () => void,
  handleChangeItemArchiveState: () => void
) => {
  const isItemActive = row?.active;

  const checkDisable = (text: string) => {
    return isItemActive && !row?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkEnable = (text: string) => {
    return !isItemActive && !row?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkArchive = (text: string) => {
    return !isItemActive && !row?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const checkUnArchive = (text: string) => {
    return !isItemActive && row?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const modalTitleForDisable = checkDisable(
    tTemplates(`equipment.modal.${!row.isEquipmentItem ? 'titleToDisable' : 'titleToDisableItem'}`)
  );
  const modalTitleForEnable = checkEnable(
    tTemplates(`equipment.modal.${!row.isEquipmentItem ? 'titleToEnable' : 'titleToEnableItem'}`)
  );
  const modalTitleForArchive = checkArchive(
    tTemplates(`equipment.modal.${!row.isEquipmentItem ? 'titleToArchive' : 'titleToArchiveItem'}`)
  );
  const modalTitleForUnArchive = checkUnArchive(tTemplates('equipment.modal.titleToUnArchive'));

  const modalContentForDisable = checkDisable(
    tTemplates(`equipment.modal.${!row.isEquipmentItem ? 'contentToDisable' : 'contentToDisableItem'}`, {
      value: row.name
    })
  );
  const modalContentForEnable = checkEnable(
    tTemplates(`equipment.modal.${!row.isEquipmentItem ? 'contentToEnable' : 'contentToEnableItem'}`, {
      value: row.name
    })
  );
  const modalContentForArchive = checkArchive(
    tTemplates(`equipment.modal.${!row.isEquipmentItem ? 'contentToArchive' : 'contentToArchiveItem'}`, {
      value: row.name
    })
  );
  const modalContentForUnArchive = checkUnArchive(
    tTemplates('equipment.modal.contentToUnArchive', { value: row.name })
  );

  const modalBtnTextForDisable = checkDisable(tTemplates('equipment.modal.btnDisable'));
  const modalBtnTextForEnable = checkEnable(tTemplates('equipment.modal.btnEnable'));
  const modalBtnTextForArchive = checkArchive(tTemplates('equipment.modal.btnArchive'));
  const modalBtnTextForUnArchive = checkUnArchive(tTemplates('equipment.modal.btnUnArchive'));

  const modalTitle = modalTitleForDisable || modalTitleForEnable || modalTitleForArchive || modalTitleForUnArchive;
  const modalContent =
    modalContentForDisable || modalContentForEnable || modalContentForArchive || modalContentForUnArchive;
  const modalBtnText =
    modalBtnTextForDisable || modalBtnTextForEnable || modalBtnTextForArchive || modalBtnTextForUnArchive;

  const handleModalAction =
    modalTitleForDisable || modalTitleForEnable ? handleChangeItemActiveState : handleChangeItemArchiveState;

  return {
    modalTitle,
    modalContent,
    modalBtnText,
    handleModalAction
  };
};

export const RowActionMenu = (row: RowActionMenuProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');
  const navigateTo = useNavigate();

  const [state, setState] = useState<RowActionMenuState>({
    isModalOpen: false,
    modalType: ''
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [SwitchEquipmentsActiveState] = useMutation<SwitchEquipmentsActiveStateMutation>(
    SWITCH_EQUIPMENTS_ACTIVE_STATE,
    {
      refetchQueries: [row?.isTableVariant ? GET_EQUIPMENTS : GET_EQUIPMENT]
    }
  );

  const [SwitchEquipmentsArchiveState] = useMutation<SwitchEquipmentsActiveStateMutation>(
    SWITCH_EQUIPMENTS_ARCHIVE_STATE,
    {
      refetchQueries: [row?.isTableVariant ? GET_EQUIPMENTS : GET_EQUIPMENT]
    }
  );

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickBox = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleEditItem = () => {
    const { isEquipmentItem, id } = row;
    const link = !isEquipmentItem ? LINKS_PAGES.templatesEquipmentItem : LINKS_PAGES.equipmentModel;

    if (id) {
      navigateTo(link.replace(':templateId', id));
    }
    setAnchorEl(null);
  };

  const handleChangeItemActiveState = () => {
    if (row) {
      const isActive = !row?.active;

      SwitchEquipmentsActiveState({
        variables: {
          request: {
            ids: [row?.id],
            active: isActive
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isActive
          ? tGlobal('alertMessages.success.templates.equipment.edit.isEnabled', { value: row?.name })
          : tGlobal('alertMessages.success.templates.equipment.edit.isDisabled', { value: row?.name }),
        severity: 'success',
        autoHideDuration: 1500
      });
    }
  };

  const handleChangeItemArchiveState = () => {
    if (row && row?.active === false) {
      const isArchive = !row?.archived;

      SwitchEquipmentsArchiveState({
        variables: {
          request: {
            ids: [row?.id],
            archived: !row?.archived
          }
        }
      });

      handleCloseModal();

      AlertService.showAlert({
        title: isArchive
          ? tGlobal('alertMessages.success.templates.equipment.edit.isArchived', { value: row?.name })
          : tGlobal('alertMessages.success.templates.equipment.edit.isUnArchived', { value: row?.name }),
        severity: 'success',
        autoHideDuration: 1500
      });

      if (!row?.isTableVariant) {
        setTimeout(() => {
          const { isEquipmentItem } = row;

          const link = !isEquipmentItem ? LINKS_PAGES.templatesEquipment : LINKS_PAGES.locations;

          navigateTo(link);
        }, 1500);
      }
    }
  };

  const handleOpenModal = (modalType: string) => {
    setAnchorEl(null);

    setState({ isModalOpen: true, modalType });
  };

  const handleCloseModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
  };

  const { modalTitle, modalContent, modalBtnText, handleModalAction } = getModalData(
    row,
    state,
    tTemplates,
    handleChangeItemActiveState,
    handleChangeItemArchiveState
  );

  return (
    <Box
      component={'td'}
      className={'table-edit-button'}
      sx={{
        display: 'block',
        '.MuiIconButton-root': {
          visibility: row?.isTableVariant ? 'hidden' : 'visible'
        }
      }}
      onClick={handleClickBox}
    >
      <IconButton size={'small'} sx={{ visibility: open ? 'visible' : 'hidden' }} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {row?.isTableVariant ? (
          <MenuItem onClick={handleEditItem} disabled={!row?.active}>
            <EditIcon sx={{ fontSize: 20 }} />

            {tTemplates('equipment.table.rowEditMenu.edit')}
          </MenuItem>
        ) : null}

        {row?.isTableVariant ? (
          <MenuItem disabled>
            <StarOutlineIcon sx={{ fontSize: 20 }} />

            {tTemplates('equipment.table.rowEditMenu.addToFavorites')}
          </MenuItem>
        ) : null}

        {row?.isTableVariant ? (
          <Box
            sx={{
              height: '1px',
              background: 'var(--border-default, rgba(9, 14, 22, 0.15))'
            }}
          />
        ) : null}

        {!row?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')} disabled={row?.archived}>
            <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 20 }} />

            {tGlobal('statuses.enable')}
          </MenuItem>
        ) : null}

        {row?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')}>
            <DoNotDisturbOnIcon />

            {tGlobal('statuses.disable')}
          </MenuItem>
        ) : null}

        <MenuItem disabled={row?.active} onClick={() => handleOpenModal('toggleArchive')}>
          <ArchiveIcon sx={{ fontSize: 20 }} />

          {row?.archived
            ? tTemplates('equipment.table.rowEditMenu.unArchive')
            : tTemplates('equipment.table.rowEditMenu.archive')}
        </MenuItem>
      </Menu>

      <Dialog
        headerTitle={modalTitle}
        hideHeaderBorder
        contentFooter={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={'my-0'}
              variant={'text'}
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCloseModal}
            >
              {tGlobal('modal.btnCancel')}
            </Button>

            <Button className={'my-0'} variant={'contained'} onClick={handleModalAction}>
              {modalBtnText}
            </Button>
          </div>
        }
        open={state.isModalOpen}
        onClose={handleCloseModal}
      >
        <Text
          className={'m-0'}
          variant={'bodyLg'}
          dangerouslySetInnerHTML={{
            __html: modalContent as string
          }}
          sx={{
            b: {
              wordBreak: 'break-word'
            }
          }}
        />

        <Box sx={{ width: '100%', flex: '0 1 100%' }}>{row?.renderModalContent ? row?.renderModalContent() : null}</Box>
      </Dialog>
    </Box>
  );
};
