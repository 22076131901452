import { useRef } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { Text } from 'infinitytechnologies-ui';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import { GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import { DomainStatusChip, EMPTY_CELL_VALUE, Flex, ImageLazy } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { TimeoutT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../Providers';

import { RowActionMenu } from './components/RowActionMenu';

import { CONFIG_TABLE } from '../../helpers';

export const TableBodyWrap = () => {
  const { t: tCompanies } = useTranslation('companies');
  const state = useContextState();
  const { handleClickRow, handleDoubleClickRow } = useContextUpdateState();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const timerId = useRef<TimeoutT>();

  const handleClickTimeout = (id: string, type: number) => {
    clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (type === 1) {
        handleClickRow(id);
      } else if (type === 2) {
        handleDoubleClickRow(id);
      }
    }, 200);

    return () => clearTimeout(timerId.current);
  };

  return (
    <TableBody>
      {state.bodyDataRows?.map((row) => {
        const isShowEditItem = dataUserProfile?.profile.companyId === row.companyId;

        return (
          <TableRow
            key={row.id}
            className={classNames({
              'Mui-selected': state.selectedItems.includes(row.id as string)
            })}
            onClick={(event) => {
              if ((event.detail === 1 || event.detail === 2) && row.id) {
                handleClickTimeout(row.id, event.detail);
              }
            }}
          >
            {CONFIG_TABLE.cells.map((i, index) => {
              const className = `${CONFIG_TABLE.cells[index].columnClass}`;

              return i.Render(row, className, index, tCompanies);
            })}

            {isShowEditItem && isUserCanCRUD && (
              <RowActionMenu
                {...row}
                isTableVariant
                renderModalContent={() => {
                  return (
                    <Flex
                      sx={{
                        borderRadius: 'var(--4, 4px)',
                        background: 'var(--background-neutral-hovered, #F7F8F9)',
                        padding: '8px',
                        margin: '24px 0 0',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Flex alignItems="center">
                        {row.fileUrl?.url ? (
                          <ImageLazy
                            src={row.fileUrl?.url}
                            alt={`Equipment item parameter ${row.name} type preview`}
                            variant={'rectangular'}
                            width={32}
                            height={32}
                            sxStyle={{
                              margin: '0 8px 0 0'
                            }}
                          />
                        ) : null}

                        <Text
                          variant={'labelLg'}
                          m={0}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            wordBreak: 'break-all',
                            maxWidth: '340px'
                          }}
                        >
                          {row.name}
                        </Text>
                      </Flex>

                      {row.domain ? (
                        <DomainStatusChip color={row.domain?.color} label={row.domain.name} />
                      ) : (
                        EMPTY_CELL_VALUE
                      )}
                    </Flex>
                  );
                }}
              />
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
