import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function SortIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M3.54102 14.5831C3.36393 14.5831 3.21549 14.5232 3.0957 14.4034C2.97591 14.2835 2.91602 14.135 2.91602 13.9579C2.91602 13.7807 2.97591 13.6323 3.0957 13.5126C3.21549 13.393 3.36393 13.3332 3.54102 13.3332H6.77016C6.94725 13.3332 7.09568 13.3931 7.21547 13.5129C7.33527 13.6328 7.39516 13.7813 7.39516 13.9584C7.39516 14.1356 7.33527 14.284 7.21547 14.4036C7.09568 14.5233 6.94725 14.5831 6.77016 14.5831H3.54102ZM3.54102 10.6248C3.36393 10.6248 3.21549 10.5649 3.0957 10.445C2.97591 10.3252 2.91602 10.1767 2.91602 9.99955C2.91602 9.82238 2.97591 9.67397 3.0957 9.55432C3.21549 9.43466 3.36393 9.37484 3.54102 9.37484H11.6099C11.787 9.37484 11.9354 9.43475 12.0552 9.55459C12.175 9.67443 12.2349 9.82294 12.2349 10.0001C12.2349 10.1773 12.175 10.3257 12.0552 10.4453C11.9354 10.565 11.787 10.6248 11.6099 10.6248H3.54102ZM3.54102 6.66648C3.36393 6.66648 3.21549 6.60656 3.0957 6.48671C2.97591 6.36688 2.91602 6.21838 2.91602 6.04121C2.91602 5.86406 2.97591 5.71566 3.0957 5.596C3.21549 5.47634 3.36393 5.4165 3.54102 5.4165H16.4576C16.6347 5.4165 16.7832 5.47643 16.903 5.59628C17.0227 5.71612 17.0826 5.86462 17.0826 6.04177C17.0826 6.21894 17.0227 6.36735 16.903 6.487C16.7832 6.60666 16.6347 6.66648 16.4576 6.66648H3.54102Z" />
    </SvgIcon>
  );
}
