import dayjs, { Dayjs } from 'dayjs';

export interface TaskItem {
  id: number;
  title: string;
  description: string;
  disabled?: boolean;
}

interface DateTime {
  date: Dayjs;
  time: Dayjs;
}

export const TaskTypes = {
  UNPLANNED: 'UNPLANNED',
  CUSTOM: 'CUSTOM'
};

export const FORM_ID = 'create-task';

export const FIELD_TASK_TITLE = 'title';
export const FIELD_TASK_DESCRIPTION = 'description';
export const FIELD_TASK_START_DATE = 'startDate';
export const FIELD_TASK_END_DATE = 'endDate';
export const FIELD_TASK_SERVICE_STUFF = 'serviceStuff';
export const UNPLANNED_TASK_ROUND = 'roundTasks';

export interface FormProps {
  [FIELD_TASK_TITLE]: string;
  [FIELD_TASK_DESCRIPTION]?: string;
  [FIELD_TASK_START_DATE]?: {
    date: Dayjs;
    time: Dayjs;
  };
  [FIELD_TASK_END_DATE]?: {
    date: Dayjs;
    time: Dayjs;
  };
  [FIELD_TASK_SERVICE_STUFF]?: string[];
  [UNPLANNED_TASK_ROUND]?: string[];
}

export const DEFAULT_VALUES: FormProps = {
  title: '',
  description: '',
  startDate: {
    date: dayjs(),
    time: dayjs()
  },
  endDate: {
    date: dayjs(),
    time: dayjs()
  },
  serviceStuff: [],
  roundTasks: []
};

export const minDate = dayjs();
export const maxDate = dayjs().add(2, 'year').endOf('year');

export function isStartDateBeforeEndDate(start: DateTime | undefined, end: DateTime | undefined): boolean {
  const startDateTime = start?.date.hour(start.time.hour()).minute(start.time.minute());
  const endDateTime = end?.date.hour(end.time.hour()).minute(end.time.minute());

  return !!startDateTime?.isBefore(endDateTime);
}
