import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import {
  CREATE_EQUIPMENT,
  CreateEquipmentMutation,
  EquipmentType,
  GetPresignedUploadUrlQuery,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';
import { GET_IMAGE_URL } from '@/logicLayers/domain/global';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteControl,
  AutocompleteOptionT,
  AvatarImagePreviewButton,
  ChipIcon,
  ImageUploader,
  ModalDiscardAllChanges,
  Preloader,
  PreloaderVariantsE,
  TextFieldControl,
  UploaderButtonLg
} from '@/separatedModules/components';

import { TEXT_FIELD_REG_EXP } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { MultiStepFooter, MultiStepHeader } from './components';

import {
  DEFAULT_VALUES,
  FIELD_EQUIPMENT_DOMAIN_ID,
  FIELD_EQUIPMENT_ITEM_DESCRIPTION,
  FIELD_EQUIPMENT_MANUFACTURER,
  FIELD_EQUIPMENT_MODEL_NUMBER,
  FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION,
  FIELD_EQUIPMENT_TITLE,
  FORM_ID,
  FormProps,
  StateI
} from './helpers';

export const EquipmentItemTemplateCreatePage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [state, setState] = useState<StateI>({
    currentStep: 1,
    totalSteps: 3,
    isLoading: false,
    fileKey: '',
    fileUrl: ''
  });

  const { refetch } = useQuery<GetPresignedUploadUrlQuery>(GET_IMAGE_URL, {
    fetchPolicy: 'no-cache',
    skip: true
  });
  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });
  const [CreateEquipmentTemplate] = useMutation<CreateEquipmentMutation>(CREATE_EQUIPMENT);

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const handleSetPrevStep = () => {
    setState((state) => {
      const newCurrentStep = state.currentStep === 1 ? 1 : state.currentStep - 1;
      return { ...state, currentStep: newCurrentStep };
    });
  };

  const handleSetImage = async (fileKey: string) => {
    setValue('fileKey', fileKey);

    const result = await refetch({ fileKey: fileKey }).then(({ data }) => data);

    // @ts-ignore
    if (result?.fileUrl?.fileKey) {
      // @ts-ignore
      setState((state) => ({ ...state, fileUrl: result?.fileUrl?.url, fileKey: result?.fileUrl?.fileKey }));
    }
  };

  const handleSubmitForm = handleSubmit((data) => {
    if (state.currentStep < state.totalSteps) {
      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else {
      CreateEquipmentTemplate({
        variables: {
          equipment: {
            name: data.name || undefined,
            domainId: data.domainId[0]?.value || undefined,
            itemDescription: data.itemDescription || undefined,
            templateDescription: data.templateDescription || undefined,
            manufacturer: data.manufacturer || undefined,
            specifications: data.specifications || undefined,
            modelNumber: data.modelNumber || undefined,
            fileKey: data.fileKey || undefined,
            type: EquipmentType.EquipmentTemplate
          }
        },
        onCompleted: ({ createEquipment: createEquipmentTemplate }) => {
          if (createEquipmentTemplate.id) {
            AlertService.showAlert({
              title: tGlobal('alertMessages.success.equipmentTemplateCreated'),
              severity: 'success'
            });

            setTimeout(() => {
              navigateTo(-1);
            }, 1000);
          }
        },
        onError: () => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.errors.base'),
            severity: 'error'
          });
        }
      });
    }
  });

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string
    }));
  }, [domainsData]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
          {state.isLoading ? (
            <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '300px !important' }} isContainer />
          ) : (
            <>
              <ModalDiscardAllChanges navigateToUrl={LINKS_PAGES.templatesEquipment} sxBox={{ margin: '0 0 32px' }} />

              <MultiStepHeader
                title={tTemplates('createEquipmentItem.multiStep.title')}
                titleStep={tTemplates('createEquipmentItem.multiStep.titleStep', {
                  currentStep: state.currentStep,
                  totalSteps: state.totalSteps
                })}
                currentStep={state.currentStep}
                totalStep={state.totalSteps}
              />

              {state.currentStep === 1 ? (
                <Box>
                  <Controller
                    name={FIELD_EQUIPMENT_TITLE}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: tGlobal('validation.required')
                      },
                      pattern: {
                        value: TEXT_FIELD_REG_EXP,
                        message: tGlobal('validation.textFieldLength')
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <TextFieldControl
                          name={FIELD_EQUIPMENT_TITLE}
                          title={tTemplates('createEquipmentItem.nameTitle')}
                          subTitle={tTemplates('createEquipmentItem.nameSubTitle')}
                          label={tTemplates('createEquipmentItem.nameLabel')}
                          placeholder={tTemplates('createEquipmentItem.namePlaceholder')}
                          value={value}
                          validation={{
                            isValid: !errors[FIELD_EQUIPMENT_TITLE]?.message,
                            error: errors[FIELD_EQUIPMENT_TITLE]?.message
                          }}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />

                  <Controller
                    name={FIELD_EQUIPMENT_DOMAIN_ID}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: tGlobal('validation.required')
                      },
                      validate: (value) => {
                        const isMoreOneItem = value.length > 1;

                        return isMoreOneItem ? tGlobal('validation.arrayFieldMaxLength', { value: 1 }) : true;
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <AutocompleteControl
                          name={FIELD_EQUIPMENT_DOMAIN_ID}
                          title={tTemplates('createEquipmentItem.domainTitle')}
                          subTitle={tTemplates('createEquipmentItem.domainSubTitle')}
                          label={tTemplates('createEquipmentItem.domainLabel')}
                          placeholder={tTemplates('createEquipmentItem.domainPlaceholder')}
                          options={domainsOptions}
                          value={value}
                          validation={{
                            isValid: !errors[FIELD_EQUIPMENT_DOMAIN_ID]?.message,
                            error: errors[FIELD_EQUIPMENT_DOMAIN_ID]?.message
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return (tagValue as AutocompleteOptionT[]).map((option, index) => {
                              const { ...tagProps } = getTagProps({ index });

                              return (
                                <Chip {...tagProps} key={option.key} label={option.label} deleteIcon={<ChipIcon />} />
                              );
                            });
                          }}
                          multiple
                          disabled={Boolean(!domainsOptions.length)}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />

                  <Controller
                    name={FIELD_EQUIPMENT_MODEL_NUMBER}
                    control={control}
                    rules={{
                      pattern: {
                        value: TEXT_FIELD_REG_EXP,
                        message: tGlobal('validation.textFieldLength')
                      },
                      minLength: {
                        value: 3,
                        message: tGlobal('validation.textFieldLength')
                      },
                      maxLength: {
                        value: 64,
                        message: tGlobal('validation.textFieldLength')
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <TextFieldControl
                          name={FIELD_EQUIPMENT_MODEL_NUMBER}
                          title={tTemplates('createEquipmentItem.modelNumberTitle')}
                          subTitle={tTemplates('createEquipmentItem.modelNumberSubTitle')}
                          label={tTemplates('createEquipmentItem.modelNumberLabel')}
                          placeholder={tTemplates('createEquipmentItem.modelNumberPlaceholder')}
                          value={value}
                          validation={{
                            isValid: !errors[FIELD_EQUIPMENT_MODEL_NUMBER]?.message,
                            error: errors[FIELD_EQUIPMENT_MODEL_NUMBER]?.message
                          }}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />
                </Box>
              ) : null}

              {state.currentStep === 2 ? (
                <Box>
                  <Controller
                    name={FIELD_EQUIPMENT_MANUFACTURER}
                    control={control}
                    rules={{
                      pattern: {
                        value: TEXT_FIELD_REG_EXP,
                        message: tGlobal('validation.textFieldLength')
                      },
                      minLength: {
                        value: 3,
                        message: tGlobal('validation.textFieldLength')
                      },
                      maxLength: {
                        value: 64,
                        message: tGlobal('validation.textFieldLength')
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <TextFieldControl
                          name={FIELD_EQUIPMENT_MANUFACTURER}
                          title={tTemplates('createEquipmentItem.manufacturerTitle')}
                          subTitle={tTemplates('createEquipmentItem.manufacturerSubTitle')}
                          label={tTemplates('createEquipmentItem.manufacturerLabel')}
                          placeholder={tTemplates('createEquipmentItem.manufacturerPlaceholder')}
                          value={value}
                          validation={{
                            isValid: !errors[FIELD_EQUIPMENT_MANUFACTURER]?.message,
                            error: errors[FIELD_EQUIPMENT_MANUFACTURER]?.message
                          }}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />

                  <Controller
                    name={FIELD_EQUIPMENT_ITEM_DESCRIPTION}
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[\w\s`'.\-,#№()_]{3,200}$/,
                        message: tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <TextFieldControl
                          name={FIELD_EQUIPMENT_ITEM_DESCRIPTION}
                          title={tTemplates('createEquipmentItem.itemDescriptionTitle')}
                          subTitle={tTemplates('createEquipmentItem.itemDescriptionSubTitle')}
                          label={tTemplates('createEquipmentItem.itemDescriptionLabel')}
                          placeholder={tTemplates('createEquipmentItem.itemDescriptionPlaceholder')}
                          rows={3}
                          maxContentLength={200}
                          value={value}
                          validation={{
                            isValid: !errors[FIELD_EQUIPMENT_ITEM_DESCRIPTION]?.message,
                            error: errors[FIELD_EQUIPMENT_ITEM_DESCRIPTION]?.message
                          }}
                          multiline
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />
                </Box>
              ) : null}

              {state.currentStep === 3 ? (
                <Box>
                  <Controller
                    name={FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION}
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[\w\s`'.\-,#№()_]{3,200}$/,
                        message: tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })
                      }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <TextFieldControl
                          name={FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION}
                          title={tTemplates('createEquipmentItem.templateDescriptionTitle')}
                          subTitle={tTemplates('createEquipmentItem.templateDescriptionSubTitle')}
                          label={tTemplates('createEquipmentItem.templateDescriptionLabel')}
                          placeholder={tTemplates('createEquipmentItem.templateDescriptionPlaceholder')}
                          rows={3}
                          maxContentLength={200}
                          value={value}
                          validation={{
                            isValid: !errors[FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION]?.message,
                            error: errors[FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION]?.message
                          }}
                          multiline
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      );
                    }}
                  />

                  <ImageUploader
                    path={'EQUIPMENT_PHOTO'}
                    imgAlt={tTemplates('createEquipmentItem.imageUploader.alt')}
                    oldImageUrl={state.fileUrl}
                    oldImageFileKey={state.fileKey}
                    modalTitle={tTemplates('createEquipmentItem.imageUploader.title')}
                    modalTitleToDelete={tTemplates('createEquipmentItem.imageUploader.titleToDelete')}
                    modalSubTitle={tTemplates('createEquipmentItem.imageUploader.subTitle')}
                    btnUploadPhotoText={tGlobal('imageUploader.btnUploadPhoto')}
                    allowedExtensionsText={''}
                    minDimensionsText={'72px x 72px'}
                    maxDimensionsText={''}
                    maxFileSizeText={'5'}
                    imageMinWidth={72}
                    imageMinHeight={72}
                    imageMaxSize={5242880} // Bites
                    imageAllowedExtensions={['png', 'jpeg', 'jpg', 'svg']}
                    imageRequired
                    withPreview
                    isVisibleDeleteBtn={false}
                    titleContent={
                      <Text
                        className={'m-0'}
                        variant={'bodyMd'}
                        sx={{ color: 'var(--text-subtle, #505668)', textAlign: 'center' }}
                      >
                        {tTemplates('createEquipmentItem.imageUploader.allowedExtensionsText')}{' '}
                        {tTemplates('createEquipmentItem.imageUploader.titleContent')}
                      </Text>
                    }
                    modalContent={
                      <AvatarImagePreviewButton
                        imgAlt={''}
                        sxBox={{
                          margin: '0 auto 24px'
                        }}
                        sxImageWrap={{ width: 180, height: 180, flex: '0 0 180px', maxWidth: 180 }}
                        sxImage={{ fontSize: 96 }}
                      />
                    }
                    renderUploaderButton={({ imgAlt, oldImage, handleOpenModalPhoto }) => {
                      return (
                        <UploaderButtonLg
                          title={tTemplates('createEquipmentItem.imageUploader.uploaderButton.title')}
                          subTitle={
                            <ul style={{ color: 'var(--text-subtle, #505668)' }}>
                              <li>{tTemplates('createEquipmentItem.imageUploader.uploaderButton.liFirst')}</li>
                              <li>{tTemplates('createEquipmentItem.imageUploader.uploaderButton.liSecond')}</li>
                            </ul>
                          }
                          btnUploadPhotoText={tGlobal('imageUploader.btnUploadPhoto')}
                          imgAlt={imgAlt}
                          oldImage={oldImage}
                          handleOpenModalPhoto={handleOpenModalPhoto}
                        />
                      );
                    }}
                    onImageUploadSuccess={(imageKey: string) => {
                      handleSetImage(imageKey);
                    }}
                    onImageDelete={() => {}}
                    onImageUploadError={() => {
                      // ToDo Remove
                      console.log('ImageUploader handleSaveImage uploadedResult error');
                    }}
                  />
                </Box>
              ) : null}

              <MultiStepFooter
                currentStep={state.currentStep}
                totalSteps={state.totalSteps}
                handleSetPrevStep={handleSetPrevStep}
              />
            </>
          )}
        </form>
      </Grid>
    </Grid>
  );
};
