import { Key } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Button, SvgIcon } from '@mui/material';

import {
  ArrowForwardIcon,
  EMPTY_CELL_VALUE,
  Flex,
  SystemStatusChip,
  SystemStatusesEnum,
  TableAsideItem
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

export const RoundRegulationsAsideContent = (props: any) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();
  const { templateId = '' } = useParams();
  const formattedText = props?.frequency ? props?.frequency.frequency.toLowerCase() : '';
  const capitalizedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');

  const status = props?.active ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled;

  const handleClickDetails = () => {
    const { id } = props;
    const link = isEquipmentTemplate
      ? LINKS_PAGES.templatesEquipmentItemRoundsRoundRegulation
      : LINKS_PAGES.equipmentItemRoundRegulation;

    id && navigateTo(link.replace(':parameterId', id).replace(':templateId', templateId));
  };

  return (
    <>
      <TableAsideItem title={tGlobal('table.aside.description')} subTitle={props?.description} />

      <TableAsideItem
        title={'Service staff'}
        subTitle={
          <Box>
            {props?.serviceStaff.map(
              (
                service: { userAvatar: { url: string | undefined }; firstName: string; lastName: string },
                index: Key | null | undefined
              ) => {
                const displayName =
                  service?.firstName.length && service?.lastName.length
                    ? `${service.firstName} ${service.lastName}`
                    : EMPTY_CELL_VALUE;

                return (
                  <Flex sx={{ alignItems: 'center', marginTop: '12px' }} key={index}>
                    <>
                      {service?.userAvatar?.url ? (
                        <img
                          width={24}
                          height={24}
                          src={service.userAvatar?.url}
                          style={{
                            borderRadius: '16px',
                            border: '1px solid #F7F8F9',
                            position: 'relative'
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            flex: '0 0 24px',
                            borderRadius: '50%',
                            background: 'rgb(240, 240, 241)',
                            border: '1px solid #F7F8F9'
                          }}
                        />
                      )}
                    </>
                    <Text
                      component={'span'}
                      variant={'bodyMd'}
                      sx={{ margin: '0px', marginLeft: '8px', fontSize: '16px' }}
                    >
                      {`${displayName}`}
                    </Text>
                  </Flex>
                );
              }
            )}
          </Box>
        }
      />

      <TableAsideItem
        title={'Start date & time'}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {props?.activationTargetDate
              ? dayjs(props?.activationTargetDate.activationTargetDate).format('DD MMM YYYY, HH:mm A')
              : EMPTY_CELL_VALUE}
          </Text>
        }
      />

      {Boolean(capitalizedText.length) && (
        <TableAsideItem
          title={'Frequency'}
          subTitle={
            <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
              {capitalizedText}
            </Text>
          }
        />
      )}
      <TableAsideItem title={'Status'} subTitle={<SystemStatusChip status={status} />} />

      {props?.lastModifiedDate && (
        <TableAsideItem
          title={'Last modified'}
          subTitle={
            <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
              {dayjs(props?.lastModifiedDate).format('DD MMM YYYY, HH:mm A')}
            </Text>
          }
        />
      )}

      <TableAsideItem
        title={'Creation date'}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {dayjs(props?.createdDate).format('DD MMM YYYY, HH:mm A')}
          </Text>
        }
      />

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        onClick={handleClickDetails}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
      >
        {'Details'}
      </Button>
    </>
  );
};
