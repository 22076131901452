import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function TimeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'200'} height={'200'} viewBox={'0 0 200 200'} fill={'none'} {...props}>
      <g clipPath="url(#clip0_112_8470)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#F87171"
        />
        <path d="M200 100C200 45.0742 154.926 0 100 0V200C154.926 200 200 154.926 200 100Z" fill="#EF4444" />
        <path
          d="M100 23.8281C58 23.8281 23.8281 58 23.8281 100C23.8281 142 58 176.562 100 176.562C142 176.562 176.172 142 176.172 100C176.172 58 142 23.8281 100 23.8281Z"
          fill="#EFF6FF"
        />
        <path
          d="M176.172 100C176.172 58 142 23.8281 100 23.8281V176.562C142 176.562 176.172 142 176.172 100Z"
          fill="#DBEAFE"
        />
        <path
          d="M100 47.2656C96.7613 47.2656 94.1406 49.8863 94.1406 53.125V111.719C94.1406 114.957 96.7613 117.578 100 117.578C103.239 117.578 105.859 114.957 105.859 111.719V53.125C105.859 49.8863 103.239 47.2656 100 47.2656Z"
          fill="#4B5563"
        />
        <path
          d="M105.859 111.719V53.125C105.859 49.8863 103.239 47.2656 100 47.2656V117.578C103.239 117.578 105.859 114.957 105.859 111.719Z"
          fill="#1F2937"
        />
        <path
          d="M135.156 94.1406H100H88.2812C85.0426 94.1406 82.4219 96.7613 82.4219 100C82.4219 103.239 85.0426 105.859 88.2812 105.859H100H135.156C138.395 105.859 141.016 103.239 141.016 100C141.016 96.7613 138.395 94.1406 135.156 94.1406Z"
          fill="#4B5563"
        />
        <path
          d="M141.016 100C141.016 96.7613 138.395 94.1406 135.156 94.1406H100V105.859H135.156C138.395 105.859 141.016 103.239 141.016 100Z"
          fill="#1F2937"
        />
      </g>
      <defs>
        <clipPath id="clip0_112_8470">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
