import { isValidElement, ReactNode } from 'react';
import classNames from 'classnames';

import CircularProgress from '@mui/material/CircularProgress';
import { SxProps, Theme } from '@mui/material/styles';

import { CompanyCircle } from './components/CompanyCircle';
import { CompanyPlanet } from './components/CompanyPlanet';
import { Spinner } from './components/Spinner';
import { StackBlitz } from './components/StackBlitz';

interface PreloaderProps {
  className?: string;
  classNameWrp?: string;
  width?: string;
  height?: string;
  size?: number;
  color?: string;
  thickness?: number;
  text?: string | ReactNode;
  variant?: string;
  sx?: SxProps<Theme>;
  isInline?: boolean;
  isContainer?: boolean;
  isFullScreen?: boolean;
}

export enum PreloaderVariantsE {
  CIRCULAR_PROGRESS = 'circular-progress',
  SPINNER = 'spinner',
  STACK_BLITZ = 'stack-blitz',
  COMPANY_CIRCLE = 'CompanyCircle',
  COMPANY_PLANET = 'CompanyPlanet'
}

import { StyledPreloader } from './style';

export const Preloader = ({
  className = '',
  classNameWrp = '',
  width = '50px',
  height = '50px',
  size,
  color = 'var(--blue-500, #25B2DE)',
  thickness = 3,
  text,
  variant = PreloaderVariantsE.CIRCULAR_PROGRESS,
  sx,
  isContainer = false,
  isInline = false,
  isFullScreen = false
}: PreloaderProps) => {
  return (
    <StyledPreloader
      className={classNames('preloader__wrp', classNameWrp, {
        'preloader__wrp--sb': isFullScreen && variant === PreloaderVariantsE.STACK_BLITZ,
        'preloader__wrp--company-first': variant === PreloaderVariantsE.COMPANY_CIRCLE,
        'preloader__wrp--company-second': variant === PreloaderVariantsE.COMPANY_PLANET,
        'preloader__wrp--centered': isContainer,
        'preloader__wrp--full-screen': isFullScreen,
        'preloader__wrp--inline': isInline
      })}
      sx={sx}
    >
      <div
        className={classNames('preloader', className)}
        // For custom icons, does not for MUI icons
        style={!size ? { width: `${width}`, height: `${height}`, flex: `0 0 ${width}` } : {}}
      >
        {variant === PreloaderVariantsE.CIRCULAR_PROGRESS ? (
          <CircularProgress size={size} thickness={thickness} sx={{ color }} />
        ) : variant === PreloaderVariantsE.STACK_BLITZ ? (
          <StackBlitz />
        ) : variant === PreloaderVariantsE.COMPANY_CIRCLE ? (
          <CompanyCircle />
        ) : variant === PreloaderVariantsE.COMPANY_PLANET ? (
          <CompanyPlanet />
        ) : (
          <Spinner className={'preloader-2__spin'} size={size} />
        )}
      </div>

      {text ? isValidElement(text) ? text : <p className={'preloader__text'}>{text}</p> : null}
    </StyledPreloader>
  );
};
