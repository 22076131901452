export const Project = {
  root: {
    textTransform: 'initial',
    display: 'flex',
    '.animate-spin': {
      display: 'none'
    },
    '.MuiSvgIcon-root': {
      transition: 'all 0.3s ease-in-out'
    },
    '&.MuiButton-round, &.isRound': {
      borderRadius: '100px !important'
    },
    '&.MuiButton-text, &.MuiButton-contained, &.MuiButton-outlined, &.MuiButton-headerBlack': {
      margin: '4px 0'
    },
    /* MuiButton-contained */
    '&.MuiButton-contained': {
      background: 'var(--background-button-default, #326CC9)',
      color: '#fff',
      boxShadow: 'none',
      '.MuiSvgIcon-root': {
        color: '#fff',
        fill: '#fff'
      },
      border: '1px solid var(--border-bold, #326CC9)',
      '&:hover': {
        color: 'var(--text-hover, #326CC9)',
        background: 'var(--background-button-hover, #FFFFFF)',
        border: '1px solid var(--border-bold-hover, #326CC9)',
        '.MuiSvgIcon-root': {
          color: 'var(--icon-hover, #326CC9)',
          fill: 'var(--icon-hover, #326CC9)'
        }
      },
      '&:active, &:focus, &.Mui-focused': {
        color: '#FFF',
        background: 'var(--background-button-hover, #FFFFFF)',
        border: '1px solid var(--border-bold-hover, #326CC9)',
        '.MuiSvgIcon-root': {
          color: 'var(--icon-hover, #326CC9)',
          fill: 'var(--icon-hover, #326CC9)'
        }
      },
      '&:disabled, &.Mui-disabled': {
        border: 'none',
        color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))',
        background: 'var(--background-disabled, rgba(9, 14, 22, 0.03))',
        '.MuiSvgIcon-root': {
          color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))',
          fill: 'var(--text-disabled, rgba(9, 14, 22, 0.30))'
        }
      }
    },
    /* MuiButton-outlined */
    '&.MuiButton-outlined': {
      background: 'var(--white)',
      border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
      '&:hover': {
        border: '1px solid var(--border-bold, #326CC9)'
      },
      color: '#265299',
      '.MuiSvgIcon-root': {
        color: '#265299',
        fill: '#265299'
      },
      '&:active, &:focus, &.Mui-focused': {
        border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
        background: '#FFF'
      },
      '&:disabled, &.Mui-disabled': {
        border: '1px solid var(--border-disabled, rgba(9, 14, 22, 0.06))'
      }
    },
    /* MuiButton-text */
    '&.MuiButton-text': {
      background: 'var(--white)',
      color: 'var(--text-default, #090E16)',
      '&:disabled, &.Mui-disabled': {
        color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))'
      },
      '&:hover': {
        background: 'var(--custom-styles-grey-5, rgba(17, 24, 39, 0.05))'
      },
      '&:active, &:focus, &.Mui-focused': {
        background: 'var(--custom-styles-grey-10, rgba(17, 24, 39, 0.10))'
      }
    },
    /* MuiButton-headerBlack */
    '&.MuiButton-headerBlue': {
      background: '#326CC9',
      color: 'white',
      fontSize: '14px',
      '&:active, &:focus, &.Mui-focused': {},
      '&:disabled, &.Mui-disabled': {
        color: '#A6C1E0'
      }
    },
    '&.MuiButton-headerBlack': {
      background: 'var(--background-neutral-bold-default, #F1F2F4)',
      '&:active, &:focus, &.Mui-focused': {},
      '&:disabled, &.Mui-disabled': {
        color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))'
      }
    },
    '&.MuiButton-loading:not(.Mui-disabled)': {
      '& > span:not([class])': {
        opacity: '0'
      },
      '.MuiButton-startIcon': {
        margin: '0',
        position: 'absolute',
        left: '50%'
      },
      '.animate-spin': {
        display: 'block'
      },
      '&.MuiButton-sizeSmall': {
        // must be a 32px height
        '.animate-spin': {
          fontSize: '16px',
          margin: '0 0 0 -8px'
        }
      },
      '&.MuiButton-sizeMedium': {
        // must be a 40px height
        '.animate-spin': {
          fontSize: '20px',
          margin: '0 0 0 -10px'
        }
      },
      '&.MuiButton-sizeLarge': {
        // must be a 52px height
        '.animate-spin': {
          fontSize: '24px',
          margin: '0 0 0 -12px'
        }
      }
    }
  }
};

export const Base = {
  root: {
    boxShadow: 'none',
    fontWeight: '500',
    letterSpacing: '0.3px',
    transition: 'all 0.3s ease-in-out',
    minWidth: '70px',
    '&:disabled': {
      'pointer-events': 'none !important'
    },
    p: {
      margin: '0 !important',
      lineHeight: 'inherit !important',
      fontSize: 'inherit !important',
      fontWeight: 'inherit !important'
    },
    '&.MuiButton-sizeSmall': {
      // must be a 32px height
      fontSize: '14px',
      lineHeight: '18px',
      borderRadius: '4px !important'
    },
    '&.MuiButton-sizeMedium': {
      // must be a 40px height
      fontSize: '16px',
      lineHeight: '20px',
      borderRadius: '5px !important'
    },
    '&.MuiButton-sizeLarge': {
      // must be a 52px height
      fontSize: '16px',
      lineHeight: '20px',
      borderRadius: '6px !important',
      '.MuiSvgIcon-root': {
        fontSize: '24px'
      }
    },
    /* Variant outlined */
    '&.MuiButton-outlined': {
      '&.MuiButton-sizeSmall': {
        padding: '6px 16px 6px 16px !important'
      },
      '&.MuiButton-sizeMedium': {
        padding: '9px 16px 9px 16px !important'
      },
      '&.MuiButton-sizeLarge': {
        padding: '15px 16px 15px 16px !important'
      },
      '&.MuiButton-sizeLarge.MuiButton-with-icon': {
        padding: '13px 16px 13px 16px !important'
      }
    },
    /* Variant text, contained */
    '&.MuiButton-text, &.MuiButton-contained': {
      '&.MuiButton-sizeSmall': {
        padding: '7px 16px 7px 16px !important'
      },
      '&.MuiButton-sizeMedium': {
        padding: '10px 16px 10px 16px !important'
      },
      '&.MuiButton-sizeLarge': {
        padding: '16px 16px 16px 16px !important'
      },
      '&.MuiButton-sizeLarge.MuiButton-with-icon': {
        padding: '14px 16px 14px 16px !important'
      }
    },
    '&.MuiButton-text, &.MuiButton-contained, &.MuiButton-outlined': {
      borderRadius: '666px'
    }
  }
};
