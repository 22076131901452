export const Project = {
  root: {
    '&:hover': {
      '.MuiInputLabel-root': {
        color: 'var(--default-gray-800)'
      },
      '.MuiInputBase-root': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--default-gray-800)'
        }
      }
    },
    '.MuiInputLabel-root': {
      color: 'var(--default-gray-500)',
      '&.Mui-focused': {
        color: 'var(--primary-800)'
      },
      '&.Mui-error': {
        color: 'var(--extended-red-700)'
      },
      '&.Mui-disabled': {
        color: 'var(--default-gray-500)'
      }
    },
    '.MuiInputBase-root': {
      height: 'auto !important',
      minHeight: '56px',
      color: 'var(--default-gray-800)',
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--default-gray-300)'
      },
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--primary-800)'
        }
      },
      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--extended-red-700)'
        }
      },
      '&.Mui-disabled': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--default-gray-200) !important'
        }
      }
    },
    '.MuiAutocomplete-tag': {
      margin: '0 4px 0 0',
      borderRadius: 'var(--16, 16px)',
      border: 'none !important',
      padding: '4px 8px',
      background: 'var(--background-selected-default, #F2F7FF)',
      color: 'var(--text-default, #090E16)',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '18px'
    }
  },
  option: {
    color: 'var(--Custom-styles-Main-Black, #111827)',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '8px 16px !important',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: 'var(--background-neutral-hovered, #F7F8F9)'
    },
    '&:focus, &:active, &.Mui-selected': {
      background: 'var(--background-selected-hovered, #E9F2FF)'
    }
  },
  endAdornment: {
    display: 'flex'
  }
};

export const Base = {
  root: {
    margin: '12px 0',
    minWidth: '200px',
    width: '100%',
    '&:hover': {
      '.MuiInputLabel-root': {
        color: 'var(--default-gray-800)'
      },
      '.MuiInputBase-root': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--default-gray-800)'
        }
      }
    },
    '.MuiInputLabel-root': {
      color: 'var(--default-gray-500)',
      fontSize: '16px',
      lineHeight: '24px',
      '&.Mui-focused': {
        color: 'var(--primary-800)'
      },
      '&.Mui-error': {
        color: 'var(--extended-red-700)'
      },
      '&.Mui-disabled': {
        color: 'var(--default-gray-500)',
        opacity: '0.4'
      }
    },
    '.MuiInputBase-root': {
      height: '56px',
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--default-gray-800)',
      '&.MuiInputBase-adornedStart': {
        paddingLeft: '12px'
      },
      '&.MuiInputBase-adornedEnd': {
        paddingRight: '12px'
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px',
        border: '1px solid var(--default-gray-300)',
        transition: 'all 0.2s ease-in-out'
      },
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--primary-800)'
        }
      },
      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--extended-red-700)'
        }
      },
      '&.Mui-disabled': {
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--default-gray-200) !important'
        }
      }
    },
    '.MuiFormHelperText-root': {
      paddingLeft: '16px',
      '&.Mui-disabled': {
        opacity: '0.4'
      }
    }
  }
};
