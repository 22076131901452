import { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import { MoreVertIcon } from '@/separatedModules/components';

import { useSuperAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { EDIT_BUTTON_CLASS, EditRoleMenuListNamesEnum, menuList } from './helpers';

interface EditMenuProps {
  id: string;
  companyId: string;
}

export const EditMenu: FC<EditMenuProps> = ({ id, companyId }) => {
  const { t: tTemplates } = useTranslation('templates');
  const { superAdminTemplateId } = useSuperAdmin();

  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const navigateTo = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuItemClick = (itemName: string) => {
    switch (true) {
      case EditRoleMenuListNamesEnum.EDIT === itemName:
        navigateTo(LINKS_PAGES.templatesRolesRoleEdit.replace(':templateId', id));
    }
  };

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const disabledEditButton = id === superAdminTemplateId || dataUserProfile?.profile.companyId !== companyId;

  return (
    <Box
      component={'td'}
      display={'flex'}
      alignItems={'center'}
      mr={'8px'}
      position={'absolute'}
      right={0}
      top={'50%'}
      sx={{ transform: 'translateY(-50%)' }}
    >
      <IconButton
        className={EDIT_BUTTON_CLASS}
        size={'small'}
        sx={{ color: '#313849', visibility: open ? 'visible' : 'hidden' }}
        onClick={handleClickButton}
        disabled={disabledEditButton}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'role-management-edit-role-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'role-management-edit-role-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ margin: '12px 0 0 0' }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {menuList.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              handleClose();
              handleMenuItemClick(item.name);
            }}
          >
            {item?.icon ? item.icon : null}

            {tTemplates(`role.table.edit.menuList.${item.name}`)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
