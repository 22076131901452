import { useMemo } from 'react';

import { SelectsDropdown } from '@/separatedModules/components';

import { i18n, useTranslation } from '@/i18n';

import { useContextUpdateState } from '../../../Providers';

const createStatuses = () => {
  return [
    {
      checked: false,
      label: i18n.t('table.rowsCells.statusDisabled', { ns: 'companies' }),
      key: i18n.t('table.rowsCells.statusDisabled', { ns: 'companies' }),
      value: 0
    },
    {
      checked: false,
      label: i18n.t('table.rowsCells.statusEnabled', { ns: 'companies' }),
      key: i18n.t('table.rowsCells.statusEnabled', { ns: 'companies' }),
      value: 1
    }
  ];
};

export const SortByStatus = () => {
  const { t: tCompanies } = useTranslation('companies');

  const { handleChangeSortByStatus } = useContextUpdateState();

  const menuList = useMemo(() => createStatuses(), []);

  // ToDo Remove Test Table Render
  // console.log('Render SortBy');

  return (
    <SelectsDropdown
      id={'companies-sort-by-status'}
      title={tCompanies('table.filters.sortByStatus')}
      menuList={menuList}
      onChange={handleChangeSortByStatus}
    />
  );
};
