import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function StarOutlineIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M8.72416 17.4715L11.9999 15.3999L15.2756 17.4988L14.4177 13.5737L17.3034 10.9569L13.5077 10.6026L11.9999 6.89553L10.492 10.5753L6.69634 10.9297L9.58209 13.5737L8.72416 17.4715ZM11.9999 17.3226L7.95424 19.8806C7.80359 19.9729 7.65261 20.0116 7.50131 19.997C7.34998 19.9823 7.21299 19.9288 7.09033 19.8366C6.96769 19.7443 6.87304 19.6238 6.80638 19.4749C6.73971 19.326 6.72904 19.1614 6.77438 18.9811L7.84827 14.1649L4.2766 10.9234C4.14328 10.8018 4.05829 10.6609 4.02164 10.5009C3.98498 10.3408 3.99464 10.1853 4.05062 10.0344C4.10662 9.88342 4.18728 9.76006 4.29261 9.66431C4.39794 9.56856 4.54192 9.50601 4.72456 9.47667L9.43814 9.04474L11.268 4.49691C11.3346 4.32778 11.4356 4.20268 11.5709 4.1216C11.7062 4.04053 11.8492 4 11.9999 4C12.1505 4 12.2935 4.04053 12.4288 4.1216C12.5641 4.20268 12.6651 4.32778 12.7318 4.49691L14.5616 9.04474L19.2752 9.47667C19.4578 9.50601 19.6018 9.56856 19.7071 9.66431C19.8125 9.76006 19.8931 9.88342 19.9491 10.0344C20.0051 10.1853 20.0148 10.3408 19.9781 10.5009C19.9415 10.6609 19.8565 10.8018 19.7232 10.9234L16.1515 14.1649L17.2254 18.9811C17.2707 19.1614 17.26 19.326 17.1934 19.4749C17.1267 19.6238 17.0321 19.7443 16.9094 19.8366C16.7868 19.9288 16.6498 19.9823 16.4985 19.997C16.3471 20.0116 16.1962 19.9729 16.0455 19.8806L11.9999 17.3226Z" />
    </SvgIcon>
  );
}
