import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { TextField } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { ButtonLoading, TextBlockControl, TextFieldMask } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export interface EditMultipleInputItemsProps<T> {
  editFieldTypes?: string[];
  fields: (keyof T)[];
  defaultValues?: Partial<T>;
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  patternMessage: string;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  inputLabels: string[];
  inputPlaceholders?: string[];
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  regExpMinLength?: number;
  regExpMaxLength?: number;
  regsExp: RegExp[];
  onSubmitForm: (getValues: any, updateModel: any) => void;
}

export const EditMultipleInputItems = <T,>({
  editFieldTypes,
  fields,
  defaultValues,
  navigateToUrl,
  mutationQuery,
  successAlertMessage,
  errorAlertMessage,
  requiredMessage,
  patternMessage,
  minLengthMessage,
  maxLengthMessage,
  inputLabels,
  inputPlaceholders,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  regsExp,
  regExpMinLength = 3,
  regExpMaxLength = 64,
  onSubmitForm
}: EditMultipleInputItemsProps<T>) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data, error }] = useMutation(mutationQuery);

  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: { ...defaultValues }
  });

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm?.(getValues, updatedData);
  });

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const REQUIRED_MESSAGE = requiredMessage || tGlobal('validation.required');
  const MIN_LENGTH_MESSAGE = minLengthMessage || tGlobal('validation.textFieldMinLength', { value: regExpMinLength });
  const MAX_LENGTH_MESSAGE = maxLengthMessage || tGlobal('validation.textFieldMaxLength', { value: regExpMaxLength });
  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  return (
    <form onSubmit={handleSubmitForm}>
      <TextBlockControl title={pageTitle} subTitle={pageSubTitle} isTitleLg isBodyLg />

      <Stack direction={'column'} spacing={'20px'}>
        {fields?.map((fieldItem, index) => (
          <Box key={fieldItem as string}>
            <Controller
              name={fieldItem as never}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: REQUIRED_MESSAGE
                },
                pattern: {
                  value: regsExp[index],
                  message: patternMessage
                },
                minLength: {
                  value: regExpMinLength,
                  message: MIN_LENGTH_MESSAGE
                },
                maxLength: {
                  value: regExpMaxLength,
                  message: MAX_LENGTH_MESSAGE
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label={inputLabels[index]}
                  placeholder={inputPlaceholders?.[index]}
                  value={value}
                  validation={{
                    // @ts-ignore
                    isValid: !errors[fieldItem]?.message,
                    // @ts-ignore
                    error: errors[fieldItem]?.message
                  }}
                  {...(editFieldTypes?.find((item, itemIndex) => item === 'phone' && itemIndex === index)
                    ? {
                        InputProps: {
                          inputComponent: TextFieldMask as any
                        }
                      }
                    : {})}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Box>
        ))}
      </Stack>

      <ButtonLoading
        className={'edit__content__btn'}
        variant={'contained'}
        type={'submit'}
        loading={loading}
        disabled={Boolean(data)}
      >
        {SUBMIT_BTN_TEXT}
      </ButtonLoading>
    </form>
  );
};
