import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { ChipProps } from '@mui/material/Chip';
import { SxProps, Theme } from '@mui/material/styles';

import { AlertCheckNoticeIcon, ArchiveIcon, DoNotDisturbOnIcon, ScheduleIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export const SystemStatusesEnum = {
  Active: 'active',
  Archived: 'archived',
  Created: 'created',
  Inactive: 'inactive',
  Disabled: 'disabled',
  Enabled: 'enabled'
};

type SystemStatusChipProps = ChipProps & {
  sxIconEnabled?: SxProps<Theme>;
  status: string;
};

export const SystemStatusChip = ({ status }: SystemStatusChipProps) => {
  const { t: tGlobal } = useTranslation('global');
  const statusFormed = status.toLowerCase();

  return (
    <Box>
      {statusFormed === SystemStatusesEnum.Active || statusFormed === SystemStatusesEnum.Enabled ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 16 }} />

          <Text variant={'labelMd'} sx={{ color: 'var(--text-success, #06845B)', margin: '0 0 0 4px' }}>
            {statusFormed === SystemStatusesEnum.Active ? tGlobal('statuses.active') : tGlobal('statuses.enabled')}
          </Text>
        </Box>
      ) : null}

      {statusFormed === SystemStatusesEnum.Inactive ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DoNotDisturbOnIcon sx={{ fill: 'var(--text-danger, #BE0E2C)' }} />

          <Text variant={'labelMd'} sx={{ color: 'var(--text-danger, #BE0E2C)', margin: '0 0 0 4px' }}>
            {tGlobal('statuses.inactive')}
          </Text>
        </Box>
      ) : null}

      {statusFormed === SystemStatusesEnum.Archived ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ArchiveIcon sx={{ fill: 'var(--text-danger, #BE0E2C)' }} />

          <Text variant={'labelMd'} sx={{ color: 'var(--text-danger, #BE0E2C)', margin: '0 0 0 4px' }}>
            {tGlobal('statuses.archived')}
          </Text>
        </Box>
      ) : null}

      {statusFormed === SystemStatusesEnum.Disabled ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DoNotDisturbOnIcon sx={{ fill: 'var(--text-danger, #BE0E2C)' }} />

          <Text variant={'labelMd'} sx={{ color: 'var(--text-danger, #BE0E2C)', margin: '0 0 0 4px' }}>
            {tGlobal('statuses.disabled')}
          </Text>
        </Box>
      ) : null}

      {statusFormed === SystemStatusesEnum.Created ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ScheduleIcon sx={{ fill: 'var(--text-subtle, #505864)', fontSize: 16 }} />

          <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505864)', margin: '0 0 0 4px' }}>
            {tGlobal('statuses.created')}
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};
