import { gql as gqlApollo } from '@apollo/client';

export const GET_PRESIGNED_UPLOAD_URL = gqlApollo(`
  query GetPresignedUploadUrl($path: S3Path!) {
    presignedUploadUrl(path: $path) {
      fileKey
      url
    }
  }
`);
