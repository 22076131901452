import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_USER_BY_ID } from '@/logicLayers/domain';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';

import { UserDetails } from '@/separatedModules/components';

import { LINKS_SIDEBAR_USER_PROFILE_MENU } from '@/subsidiaryBinders/constants';

import { NavListSimple } from '../NavListSimple';

interface SideBarUserProfileProps {
  userId?: string;
}

export const SideBarUserProfile = ({ userId = '' }: SideBarUserProfileProps) => {
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(GET_USER_BY_ID, { variables: { uid: userId } });

  const location = useLocation();
  const isShowVisibility = location.pathname.includes('/visibility');

  const isVisibilityMenu = LINKS_SIDEBAR_USER_PROFILE_MENU.map((menu) => ({
    ...menu,
    link: `${menu.link}/visibility`
  }));

  return (
    <Sidebar>
      <SideBarGroup>
        <UserDetails
          variant={'short'}
          firstName={dataUser?.user.firstName ?? ''}
          lastName={dataUser?.user.lastName ?? ''}
          email={dataUser?.user.email ?? ''}
          loading={loadingUser}
          errorUser={errorUser}
        />

        <NavListSimple
          menu={isShowVisibility ? isVisibilityMenu : LINKS_SIDEBAR_USER_PROFILE_MENU}
          insertValue={userId}
          replaceValue={':userId'}
        />
      </SideBarGroup>
    </Sidebar>
  );
};
