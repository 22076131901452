import { FC, MouseEvent, ReactNode } from 'react';

import Box from '@mui/material/Box';

import { FolderIcon } from '@/separatedModules/components';

import { MuiTreeItem } from './components';

export interface RenderTree {
  id: string;
  name: string;
  active?: boolean;
  hasAction?: boolean;
  groupItems?: {
    items?: readonly RenderTree[];
  };
}

interface IconsContainerProps {
  children: ReactNode;
  customIcon?: ReactNode;
}

export const IconsContainer: FC<IconsContainerProps> = ({ children, customIcon }) => (
  <Box display={'flex'} alignItems={'center'}>
    {children}

    {customIcon ? customIcon : <FolderIcon />}
  </Box>
);

export const renderTree = (
  nodes: RenderTree,
  onNodeActionClick: (event: MouseEvent<HTMLElement>, nodes: RenderTree) => void,
  rootItemIcon?: ReactNode
) => {
  return (
    <MuiTreeItem nodes={nodes} rootItemIcon={rootItemIcon} onNodeActionClick={onNodeActionClick}>
      {Array.isArray(nodes.groupItems?.items)
        ? nodes?.groupItems?.items.map((node) => renderTree(node, onNodeActionClick, rootItemIcon))
        : null}
    </MuiTreeItem>
  );
};
