import { Text } from 'infinitytechnologies-ui';

import { Box, Button } from '@mui/material';

import { Flex, PlusIcon, PreviewIcon } from '@/separatedModules/components';

interface EditRoundParametersHeaderProps {
  title: string;
  description: string;
  isFirstStep?: boolean;
  onClick?: () => void;
}

export const EditRoundParametersHeader = ({
  description,
  title,
  isFirstStep,
  onClick
}: EditRoundParametersHeaderProps) => (
  <Flex justifyContent="space-between" sx={{ marginBottom: '16px' }}>
    <Box sx={{ maxWidth: '481px' }}>
      <Text sx={{ fontWeight: 600, fontSize: '20px' }}>{title}</Text>
      <Text sx={{ fontSize: '16px', fontWeight: '400', marginTop: '16px' }}>{description}</Text>
    </Box>
    {!isFirstStep ? (
      <Button
        variant="outlined"
        startIcon={<PreviewIcon />}
        size="large"
        sx={{
          height: '40px',
          width: '149px',
          '&.MuiButton-outlined.MuiButton-sizeLarge': {
            padding: '0 !important'
          },
          '.MuiButton-startIcon': {
            marginRight: '8px'
          }
        }}
      >
        <span style={{ fontSize: '16px' }}>Round form</span>
      </Button>
    ) : (
      <Button
        className={'m-0'}
        variant={'contained'}
        startIcon={<PlusIcon />}
        sx={{ height: '40px' }}
        onClick={onClick}
      >
        {'Create parameter'}
      </Button>
    )}
  </Flex>
);
