import { ChangeEvent, MouseEvent, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import TablePagination from '@mui/material/TablePagination';

import { checkTablePagination } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface MuiTablePaginationProps {
  page: number;
  count: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (value: number) => void;
}

export const MuiTablePagination = ({
  page,
  count,
  rowsPerPage = 20,
  rowsPerPageOptions = [20, 50, 100],
  onChangePage,
  onChangeRowsPerPage
}: MuiTablePaginationProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPageValue, setRowsPerPageValue] = useState(rowsPerPage);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);

    onChangePage?.(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(event.target.value);

    setRowsPerPageValue(value);
    setCurrentPage(0);

    onChangeRowsPerPage?.(value);
  };

  const { isVisiblePagination, rowsPerPageOptions: rowsPerPageOptionsUpdated } = checkTablePagination(
    count,
    rowsPerPageOptions
  );

  if (!isVisiblePagination) return null;

  return (
    <TablePagination
      component={'div'}
      labelRowsPerPage={tGlobal('table.pagination.labelRowsPerPage')}
      count={count}
      page={currentPage}
      rowsPerPage={rowsPerPageOptions.includes(rowsPerPageValue) ? rowsPerPageValue : rowsPerPageOptions[0]}
      rowsPerPageOptions={rowsPerPageOptionsUpdated}
      slots={{
        actions: {
          lastButtonIcon: KeyboardDoubleArrowRightIcon,
          firstButtonIcon: KeyboardDoubleArrowLeftIcon,
          previousButtonIcon: ChevronLeftIcon,
          nextButtonIcon: ChevronRightIcon
        }
      }}
      showFirstButton
      showLastButton
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
