export const CompanyCircle = () => {
  return (
    <div className={'preloader-company preloader-company__first'}>
      <svg className={'preloader-company__svg'}>
        <circle className={'preloader-company__svg__track'}></circle>
        <circle className={'preloader-company__svg__indicator'}></circle>
      </svg>

      <svg
        className={'preloader-company__logo'}
        width="51"
        height="40"
        viewBox="0 0 51 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.433594 35.7945V21.2655H7.16715C8.40438 21.2655 9.43631 21.424 10.2629 21.7408C11.0896 22.0577 11.7109 22.4976 12.127 23.0604C12.5431 23.6184 12.7511 24.2617 12.7511 24.99C12.7511 25.5575 12.6197 26.0565 12.3569 26.4869C12.0942 26.9125 11.7328 27.2625 11.273 27.5368C10.8186 27.8064 10.2985 27.9979 9.71277 28.1115V28.2533C10.3533 28.277 10.9527 28.4331 11.5111 28.7216C12.075 29.0101 12.5321 29.4144 12.8825 29.9347C13.2328 30.4502 13.408 31.065 13.408 31.7792C13.408 32.5501 13.1863 33.2382 12.7429 33.8436C12.3049 34.4442 11.6562 34.9195 10.7967 35.2695C9.93722 35.6195 8.87792 35.7945 7.6188 35.7945H0.433594ZM3.98924 33.2831H6.88796C7.87883 33.2831 8.60146 33.12 9.05584 32.7936C9.51022 32.4626 9.7374 32.0227 9.7374 31.4741C9.7374 31.0721 9.62518 30.7174 9.40073 30.41C9.17628 30.1026 8.85602 29.8614 8.43996 29.6864C8.02938 29.5114 7.53942 29.4239 6.97008 29.4239H3.98924V33.2831ZM3.98924 27.3453H6.62519C7.11241 27.3453 7.54489 27.272 7.92263 27.1254C8.30584 26.974 8.60693 26.7612 8.82591 26.4869C9.05036 26.2126 9.16259 25.8839 9.16259 25.5008C9.16259 24.9758 8.94635 24.5525 8.51387 24.2309C8.08686 23.9093 7.4792 23.7485 6.69088 23.7485H3.98924V27.3453Z"
          fill="#111827"
        />
        <path
          d="M16.1841 21.2655H20.5692L25.2005 31.0272H25.3976L30.029 21.2655H34.414V35.7945H30.9651V26.3379H30.8255L26.4733 35.7236H24.1248L19.7726 26.3024H19.633V35.7945H16.1841V21.2655Z"
          fill="#111827"
        />
        <path
          d="M46.9292 25.444C46.8635 24.8718 46.5815 24.4272 46.0834 24.1103C45.5852 23.7934 44.9091 23.635 44.0551 23.635C43.4748 23.635 42.9848 23.7059 42.5852 23.8478C42.1856 23.985 41.879 24.1765 41.6655 24.4225C41.4575 24.6684 41.3534 24.9474 41.3534 25.2596C41.3425 25.5197 41.4055 25.7467 41.5423 25.9406C41.6847 26.1345 41.879 26.3024 42.1253 26.4443C42.3717 26.5815 42.6564 26.7021 42.9794 26.8061C43.3023 26.9054 43.6472 26.9906 44.014 27.0615L45.525 27.3737C46.2585 27.5155 46.9319 27.7047 47.545 27.9412C48.1582 28.1777 48.6892 28.4685 49.1381 28.8138C49.587 29.159 49.9346 29.5658 50.181 30.034C50.4328 30.5022 50.5615 31.039 50.5669 31.6444C50.5615 32.5335 50.2987 33.3044 49.7786 33.9571C49.264 34.605 48.5195 35.1087 47.545 35.4682C46.5761 35.8229 45.4073 36.0002 44.0387 36.0002C42.681 36.0002 41.4985 35.8205 40.4912 35.4611C39.4894 35.1016 38.7066 34.5696 38.1427 33.8649C37.5843 33.1554 37.2914 32.2781 37.264 31.2329H40.7047C40.743 31.72 40.9045 32.1268 41.1892 32.4531C41.4794 32.7747 41.8653 33.0183 42.3471 33.1838C42.8343 33.3446 43.3845 33.425 43.9976 33.425C44.5998 33.425 45.1226 33.3494 45.566 33.198C46.0149 33.0467 46.3626 32.8362 46.6089 32.5666C46.8553 32.297 46.9784 31.9873 46.9784 31.6373C46.9784 31.3109 46.8662 31.0366 46.6418 30.8144C46.4228 30.5921 46.0998 30.4029 45.6728 30.2468C45.2512 30.0907 44.7339 29.9489 44.1208 29.8212L42.2896 29.4239C40.8717 29.1259 39.7522 28.6601 38.931 28.0263C38.1098 27.3926 37.702 26.5389 37.7075 25.4653C37.702 24.5856 37.973 23.8171 38.5204 23.1597C39.0733 22.5023 39.8316 21.9891 40.7951 21.6202C41.7586 21.2513 42.8534 21.0669 44.0797 21.0669C45.3279 21.0669 46.4173 21.2513 47.348 21.6202C48.2841 21.9891 49.0122 22.5023 49.5323 23.1597C50.0523 23.8171 50.3206 24.5785 50.337 25.444H46.9292Z"
          fill="#111827"
        />
        <path
          d="M9.10548 6.70925H8.14421C8.07262 6.42535 7.94309 6.16308 7.75561 5.92243C7.57154 5.67909 7.33974 5.46684 7.06022 5.28568C6.7807 5.10452 6.46369 4.96392 6.10918 4.86388C5.75467 4.76384 5.37118 4.71381 4.95872 4.71381C4.29742 4.71381 3.69407 4.85036 3.14867 5.12345C2.60668 5.39654 2.17206 5.79806 1.84482 6.32801C1.52099 6.85526 1.35907 7.50148 1.35907 8.26667C1.35907 9.03726 1.52099 9.68619 1.84482 10.2134C2.17206 10.7407 2.60668 11.1409 3.14867 11.4139C3.69407 11.6843 4.29742 11.8195 4.95872 11.8195C5.37118 11.8195 5.75467 11.7695 6.10918 11.6695C6.46369 11.5694 6.7807 11.4302 7.06022 11.2517C7.33974 11.0706 7.57154 10.8583 7.75561 10.615C7.94309 10.3716 8.07262 10.108 8.14421 9.82408H9.10548C9.02026 10.2053 8.86005 10.5609 8.62484 10.8907C8.39305 11.2179 8.09648 11.5045 7.73516 11.7506C7.37724 11.9966 6.96478 12.1886 6.49778 12.3265C6.03078 12.4644 5.51776 12.5333 4.95872 12.5333C4.07926 12.5333 3.29866 12.3589 2.6169 12.0101C1.93515 11.6586 1.39998 11.1638 1.01138 10.5257C0.626189 9.88762 0.433594 9.1346 0.433594 8.26667C0.433594 7.39873 0.626189 6.64571 1.01138 6.0076C1.39998 5.3695 1.93515 4.87605 2.6169 4.52725C3.29866 4.17575 4.07926 4 4.95872 4C5.51776 4 6.03078 4.06895 6.49778 4.20684C6.96478 4.34204 7.37724 4.53401 7.73516 4.78276C8.09648 5.02881 8.39305 5.31542 8.62484 5.64259C8.86005 5.96975 9.02026 6.32531 9.10548 6.70925Z"
          fill="#111827"
        />
        <path
          d="M11.2336 4.11356H12.3381L15.4417 8.05577H15.5338L18.6374 4.11356H19.7419L15.9633 8.82231V12.4198H15.0122V8.82231L11.2336 4.11356Z"
          fill="#111827"
        />
        <path
          d="M22.4018 12.4198V4.11356H25.935C26.6167 4.11356 27.1843 4.2082 27.6376 4.39747C28.0944 4.58673 28.437 4.84495 28.6654 5.17212C28.8938 5.49928 29.008 5.87106 29.008 6.28745C29.008 6.63354 28.9347 6.92962 28.7881 7.17567C28.6415 7.42172 28.4421 7.62045 28.1899 7.77186C27.9376 7.92328 27.6564 8.03278 27.3462 8.10038V8.16527C27.6837 8.1842 28.0109 8.27883 28.3279 8.44918C28.6449 8.61681 28.9057 8.85205 29.1102 9.15488C29.3147 9.45771 29.417 9.82003 29.417 10.2418C29.417 10.6609 29.2994 11.0354 29.0642 11.3653C28.829 11.6924 28.4677 11.9507 27.9802 12.1399C27.4962 12.3265 26.8792 12.4198 26.1293 12.4198H22.4018ZM23.3579 11.7384H26.1293C26.9371 11.7384 27.5337 11.5965 27.9189 11.3125C28.3075 11.0286 28.5018 10.6717 28.5018 10.2418C28.5018 9.92548 28.4046 9.63887 28.2103 9.382C28.0194 9.12243 27.7484 8.91694 27.3973 8.76553C27.0496 8.61411 26.6406 8.5384 26.1702 8.5384H23.3579V11.7384ZM23.3579 7.86515H26.0219C26.4343 7.86515 26.7957 7.79755 27.1059 7.66236C27.4161 7.52717 27.6581 7.3406 27.8319 7.10266C28.0058 6.86472 28.0927 6.59299 28.0927 6.28745C28.0927 5.84673 27.912 5.48847 27.5507 5.21267C27.1894 4.93418 26.6508 4.79493 25.935 4.79493H23.3579V7.86515Z"
          fill="#111827"
        />
        <path
          d="M32.5882 12.4198V4.11356H38.6626V4.79493H33.5444V7.92193H38.3405V8.6033H33.5444V11.7384H38.7649V12.4198H32.5882Z"
          fill="#111827"
        />
        <path
          d="M42.0013 12.4198V4.11356H45.3811C46.1242 4.11356 46.7446 4.22036 47.2423 4.43397C47.7434 4.64487 48.12 4.93823 48.3723 5.31407C48.6279 5.6872 48.7558 6.11576 48.7558 6.59975C48.7558 7.08373 48.6279 7.51094 48.3723 7.88137C48.12 8.2518 47.7451 8.54111 47.2474 8.7493C46.7497 8.9575 46.1327 9.0616 45.3964 9.0616H42.5177V8.36806H45.3709C45.9163 8.36806 46.3696 8.29641 46.731 8.15311C47.0957 8.0098 47.3667 7.80566 47.544 7.54068C47.7246 7.27571 47.815 6.96206 47.815 6.59975C47.815 6.23743 47.7246 5.92108 47.544 5.6507C47.3633 5.37761 47.0906 5.16671 46.7259 5.018C46.3645 4.86929 45.9078 4.79493 45.3555 4.79493H42.9575V12.4198H42.0013ZM46.6185 8.67224L49.1955 12.4198H48.0911L45.5396 8.67224H46.6185Z"
          fill="#111827"
        />
        <path
          d="M9.10548 6.70925H8.14421C8.07262 6.42535 7.94309 6.16308 7.75561 5.92243C7.57154 5.67909 7.33974 5.46684 7.06022 5.28568C6.7807 5.10452 6.46369 4.96392 6.10918 4.86388C5.75467 4.76384 5.37118 4.71381 4.95872 4.71381C4.29742 4.71381 3.69407 4.85036 3.14867 5.12345C2.60668 5.39654 2.17206 5.79806 1.84482 6.32801C1.52099 6.85526 1.35907 7.50148 1.35907 8.26667C1.35907 9.03726 1.52099 9.68619 1.84482 10.2134C2.17206 10.7407 2.60668 11.1409 3.14867 11.4139C3.69407 11.6843 4.29742 11.8195 4.95872 11.8195C5.37118 11.8195 5.75467 11.7695 6.10918 11.6695C6.46369 11.5694 6.7807 11.4302 7.06022 11.2517C7.33974 11.0706 7.57154 10.8583 7.75561 10.615C7.94309 10.3716 8.07262 10.108 8.14421 9.82408H9.10548C9.02026 10.2053 8.86005 10.5609 8.62484 10.8907C8.39305 11.2179 8.09648 11.5045 7.73516 11.7506C7.37724 11.9966 6.96478 12.1886 6.49778 12.3265C6.03078 12.4644 5.51776 12.5333 4.95872 12.5333C4.07926 12.5333 3.29866 12.3589 2.6169 12.0101C1.93515 11.6586 1.39998 11.1638 1.01138 10.5257C0.626189 9.88762 0.433594 9.1346 0.433594 8.26667C0.433594 7.39873 0.626189 6.64571 1.01138 6.0076C1.39998 5.3695 1.93515 4.87605 2.6169 4.52725C3.29866 4.17575 4.07926 4 4.95872 4C5.51776 4 6.03078 4.06895 6.49778 4.20684C6.96478 4.34204 7.37724 4.53401 7.73516 4.78276C8.09648 5.02881 8.39305 5.31542 8.62484 5.64259C8.86005 5.96975 9.02026 6.32531 9.10548 6.70925Z"
          stroke="#111827"
          strokeWidth="0.5"
        />
        <path
          d="M11.2336 4.11356H12.3381L15.4417 8.05577H15.5338L18.6374 4.11356H19.7419L15.9633 8.82231V12.4198H15.0122V8.82231L11.2336 4.11356Z"
          stroke="#111827"
          strokeWidth="0.5"
        />
        <path
          d="M22.4018 12.4198V4.11356H25.935C26.6167 4.11356 27.1843 4.2082 27.6376 4.39747C28.0944 4.58673 28.437 4.84495 28.6654 5.17212C28.8938 5.49928 29.008 5.87106 29.008 6.28745C29.008 6.63354 28.9347 6.92962 28.7881 7.17567C28.6415 7.42172 28.4421 7.62045 28.1899 7.77186C27.9376 7.92328 27.6564 8.03278 27.3462 8.10038V8.16527C27.6837 8.1842 28.0109 8.27883 28.3279 8.44918C28.6449 8.61681 28.9057 8.85205 29.1102 9.15488C29.3147 9.45771 29.417 9.82003 29.417 10.2418C29.417 10.6609 29.2994 11.0354 29.0642 11.3653C28.829 11.6924 28.4677 11.9507 27.9802 12.1399C27.4962 12.3265 26.8792 12.4198 26.1293 12.4198H22.4018ZM23.3579 11.7384H26.1293C26.9371 11.7384 27.5337 11.5965 27.9189 11.3125C28.3075 11.0286 28.5018 10.6717 28.5018 10.2418C28.5018 9.92548 28.4046 9.63887 28.2103 9.382C28.0194 9.12243 27.7484 8.91694 27.3973 8.76553C27.0496 8.61411 26.6406 8.5384 26.1702 8.5384H23.3579V11.7384ZM23.3579 7.86515H26.0219C26.4343 7.86515 26.7957 7.79755 27.1059 7.66236C27.4161 7.52717 27.6581 7.3406 27.8319 7.10266C28.0058 6.86472 28.0927 6.59299 28.0927 6.28745C28.0927 5.84673 27.912 5.48847 27.5507 5.21267C27.1894 4.93418 26.6508 4.79493 25.935 4.79493H23.3579V7.86515Z"
          stroke="#111827"
          strokeWidth="0.5"
        />
        <path
          d="M32.5882 12.4198V4.11356H38.6626V4.79493H33.5444V7.92193H38.3405V8.6033H33.5444V11.7384H38.7649V12.4198H32.5882Z"
          stroke="#111827"
          strokeWidth="0.5"
        />
        <path
          d="M42.0013 12.4198V4.11356H45.3811C46.1242 4.11356 46.7446 4.22036 47.2423 4.43397C47.7434 4.64487 48.12 4.93823 48.3723 5.31407C48.6279 5.6872 48.7558 6.11576 48.7558 6.59975C48.7558 7.08373 48.6279 7.51094 48.3723 7.88137C48.12 8.2518 47.7451 8.54111 47.2474 8.7493C46.7497 8.9575 46.1327 9.0616 45.3964 9.0616H42.5177V8.36806H45.3709C45.9163 8.36806 46.3696 8.29641 46.731 8.15311C47.0957 8.0098 47.3667 7.80566 47.544 7.54068C47.7246 7.27571 47.815 6.96206 47.815 6.59975C47.815 6.23743 47.7246 5.92108 47.544 5.6507C47.3633 5.37761 47.0906 5.16671 46.7259 5.018C46.3645 4.86929 45.9078 4.79493 45.3555 4.79493H42.9575V12.4198H42.0013ZM46.6185 8.67224L49.1955 12.4198H48.0911L45.5396 8.67224H46.6185Z"
          stroke="#111827"
          strokeWidth="0.5"
        />
      </svg>
    </div>
  );
};
