import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

import { EquipmentRes } from '@/logicLayers/domain';

import { EquipmentItemTypes } from './helpers';

interface StepsStateI {
  currentStep: number;
  totalSteps: number;
  equipmentItemType: string;
  selected?: EquipmentRes | undefined;
}

type ContextStateT = StepsStateI & {
  setState: Dispatch<SetStateAction<StepsStateI>>;
  handleSetPrevStep: () => void;
  handleSetEquipmentItemType: (type: string) => void;
};

export const ContextState = createContext<ContextStateT>({
  currentStep: 1,
  totalSteps: 2,
  equipmentItemType: '',
  setState: () => {},
  handleSetPrevStep: () => {},
  handleSetEquipmentItemType: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<StepsStateI>({
    currentStep: 1,
    totalSteps: 2,
    equipmentItemType: ''
  });

  const handleSetPrevStep = () => {
    setState((state: any) => ({ ...state, currentStep: state.currentStep - 1 }));
  };

  const handleSetEquipmentItemType = (type: string) => {
    setState((state: any) => ({
      ...state,
      equipmentItemType: type,
      totalSteps: type === EquipmentItemTypes.NEW ? 3 : 2
    }));
  };

  const contextValue = useMemo(() => {
    return {
      ...state,
      setState,
      handleSetPrevStep,
      handleSetEquipmentItemType
    };
  }, [state]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
