import { RenderUploaderButtonI, RenderUploaderButtonProps } from './helpers';

export interface UploaderButtonProps extends RenderUploaderButtonProps, RenderUploaderButtonI {}

export const UploaderButton = ({
  oldImage,
  imgAlt,
  sxBox,
  handleOpenModalPhoto,
  renderUploaderButton
}: UploaderButtonProps) => {
  return renderUploaderButton ? renderUploaderButton({ imgAlt, oldImage, sxBox, handleOpenModalPhoto }) : null;
};
