import { FC } from 'react';

import Box from '@mui/material/Box';

import { TextBlockControl } from '@/separatedModules/components';

import { Parameter } from '../../helpers';

import { StyledParameterPlate } from './style';

interface ParameterPlateProps {
  selectedId: number;
  parameter: Parameter;
  onClick: () => void;
}

export const ParameterPlate: FC<ParameterPlateProps> = ({ parameter, selectedId, onClick }) => {
  return (
    <StyledParameterPlate key={parameter.id} selected={selectedId === parameter.id} onClick={onClick}>
      <Box
        sx={{
          flex: '0 0 56px',
          maxWidth: '56px',
          display: 'flex',
          alignItems: 'center',
          margin: '0 16px 0 0'
        }}
      >
        <img src={parameter.logoPath} alt={'parameter-type'} />
      </Box>

      <TextBlockControl
        className={'m-0'}
        title={parameter.title}
        subTitle={parameter.description}
        sxTitle={{ marginBottom: '8px' }}
        sxSubTitle={{ marginBottom: 0 }}
      />
    </StyledParameterPlate>
  );
};
