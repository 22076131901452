import { ReactNode, useLayoutEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { AuthService } from '@/logicLayers/infrastructure/services';

import { Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { SignIn } from './components';

type AuthProviderProps = { children: ReactNode };

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuth();

  const handleLogOut = async () => {
    await auth.removeUser();
    await auth.signoutRedirect();
  };

  const handleSignOutSilent = () => {
    auth.signoutSilent();
  };

  useLayoutEffect(() => {
    AuthService.saveHandleLogOut(handleLogOut);
    AuthService.saveHandleSignOutSilent(handleSignOutSilent);
  }, []);

  return (
    <>
      {auth.isLoading && !auth.isAuthenticated ? (
        <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer />
      ) : !auth.isLoading && !auth.isAuthenticated ? (
        <SignIn />
      ) : (
        children
      )}
    </>
  );
};
