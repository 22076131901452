import IconButton from '@mui/material/IconButton';

import { DescriptionIcon, NotificationsFillIcon } from '@/separatedModules/components';

import { RightItemAvatar } from '../RightItemAvatar';

export const RightItem = () => {
  return (
    <div className={'header__right__i'}>
      <div className={'header__right__i__line'}></div>

      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#DCDFE4' }} aria-label={''}>
        <NotificationsFillIcon />
      </IconButton>

      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#DCDFE4' }} aria-label={''}>
        <DescriptionIcon />
      </IconButton>

      <RightItemAvatar />
    </div>
  );
};
