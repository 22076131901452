import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import { TaskColorBackgroundStatus, TaskColorStatus, TaskStatus } from '@/logicLayers/domain';

interface StyledChipProps {
  colorVariant: keyof typeof TaskStatus;
}

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop: string) => prop !== 'colorVariant'
})(({ colorVariant }: StyledChipProps) => ({
  background: TaskColorBackgroundStatus[colorVariant],
  color: TaskColorStatus[colorVariant]
}));
