import { styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view';

export const StyledTreeView = styled(TreeView)<{ test: boolean }>(({ isDisabled }) => ({
  '.MuiTreeItem-iconContainer': {
    width: '38px',
    justifyContent: 'flex-end',
    marginLeft: '4px',

    svg: {
      fontSize: '20px'
    }
  },

  '.node_action_button': {
    display: isDisabled && 'none !important'
  },

  '.MuiTreeItem-label': {
    paddingLeft: 0,
    fontSize: '14px',
    fontWeight: 500
  }
}));
