import { useRef } from 'react';
import classNames from 'classnames';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/Companies/components';

import { TimeoutT } from '@/subsidiaryBinders/types';

import { TableRowCells } from '../TableRowCells';

import { RowActionMenu } from './components/RowActionMenu';

export const TableBodyWrap = () => {
  const state = useContextState();

  const { handleClickRow, handleDoubleClickRow } = useContextUpdateState();

  // ToDo Remove Test Table Render
  // console.log('Render TableBodyWrap state.selectedItems - ', state.selectedItems);

  const timerId = useRef<TimeoutT>();

  const handleClickTimeout = (id: string, type: number) => {
    clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (type === 1) {
        handleClickRow(id);
      } else if (type === 2) {
        handleDoubleClickRow(id);
      }
    }, 200);

    return () => clearTimeout(timerId.current);
  };

  return (
    <TableBody>
      {state.bodyDataRows?.map((row) => {
        return (
          <TableRow
            key={row.id}
            className={classNames({
              'Mui-selected': state.selectedItems.includes(row.id as string)
            })}
            onClick={(event) => {
              if ((event.detail === 1 || event.detail === 2) && row.id) {
                handleClickTimeout(row.id, event.detail);
              }
            }}
          >
            <TableRowCells row={row} />
            <RowActionMenu row={row} />
          </TableRow>
        );
      })}
    </TableBody>
  );
};
