import { Text } from 'infinitytechnologies-ui';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SxProps, Theme } from '@mui/material/styles';

import { TextBlockControl } from '@/separatedModules/components';

import { ButtonList, ButtonListControlProps } from '../../ButtonsList';

import { StyledButtonListControl } from './style';

interface ButtonsListControlProps extends ButtonListControlProps {
  title?: string;
  subTitle?: string;
  sxTitle?: SxProps<Theme>;
  sxSubTitle?: SxProps<Theme>;
  sxSelect?: SxProps<Theme>;
  validation: {
    isValid: boolean;
    error: string;
  };
}

export const ButtonsListControl = ({
  title,
  subTitle,
  sxTitle,
  validation,
  sxSubTitle,
  ...rest
}: ButtonsListControlProps) => {
  return (
    <StyledButtonListControl>
      <TextBlockControl
        className={'control-autocomplete-tags__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
      />
      <ButtonList {...rest} />
      {!validation.isValid && (
        <Text
          sx={{
            color: '#BE0E2C',
            fontSize: '12px',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px'
          }}
        >
          <ErrorOutlineIcon
            fontSize="inherit"
            color="error"
            sx={{ marginRight: '4px', width: '16px', height: '16px' }}
          />
          {validation.error}
        </Text>
      )}
    </StyledButtonListControl>
  );
};
