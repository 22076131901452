import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function VoteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_25_12979)">
          <path
            d="M194.141 75.3125C190.905 75.3125 188.281 77.9359 188.281 81.1719V81.1785C188.281 84.8785 185.286 87.8648 181.595 87.8648H18.4117C14.7215 87.8648 11.7188 84.8621 11.7188 81.1719C11.7188 77.9359 9.09531 75.3125 5.85938 75.3125C2.62344 75.3125 0 77.9359 0 81.1719V106.276C0 116.378 8.1668 124.688 18.4117 124.688H181.588C191.667 124.688 200 116.544 200 106.276V81.1719C200 77.9359 197.377 75.3125 194.141 75.3125Z"
            fill="#A7F3D0"
          />
          <path
            d="M200 81.1719C200 77.9359 197.377 75.3125 194.141 75.3125C190.905 75.3125 188.281 77.9359 188.281 81.1719V81.1785C188.281 84.8785 185.286 87.8648 181.595 87.8648H100V124.688H181.588C191.667 124.688 200 116.544 200 106.276V81.1719Z"
            fill="#34D399"
          />
          <path
            d="M181.588 99.5836H18.4113C8.45508 99.5836 0 91.5527 0 81.1719V18.4117C0 8.03125 8.45586 0 18.4113 0H181.588C191.545 0 200 8.03047 200 18.4117V81.1719C200 91.5512 191.546 99.5836 181.588 99.5836Z"
            fill="#34D399"
          />
          <path
            d="M181.588 99.5836C191.545 99.5836 200 91.5512 200 81.1719V18.4117C200 8.03047 191.545 0 181.588 0H100V99.5836H181.588Z"
            fill="#10B981"
          />
          <path
            d="M54.7828 25.4047C51.7128 24.3816 48.3945 26.041 47.3711 29.1105L40.3777 50.0906L33.3847 29.1105C32.3613 26.041 29.0437 24.382 25.973 25.4047C22.9031 26.4281 21.2437 29.7461 22.2671 32.8164L34.8191 70.4726C35.6168 72.8652 37.8558 74.4793 40.3777 74.4793C42.8996 74.4793 45.1386 72.8652 45.9363 70.4726L58.4882 32.8164C59.5121 29.7461 57.8531 26.4281 54.7828 25.4047Z"
            fill="white"
          />
          <path
            d="M137.655 25.1045H115.689C112.454 25.1045 109.83 27.7279 109.83 30.9639C109.83 34.1998 112.454 36.8232 115.689 36.8232H120.813V68.6201C120.813 71.8561 123.436 74.4795 126.672 74.4795C129.908 74.4795 132.532 71.8561 132.532 68.6201V36.8232H137.655C140.891 36.8232 143.515 34.1998 143.515 30.9639C143.515 27.7279 140.892 25.1045 137.655 25.1045Z"
            fill="#FFF7F4"
          />
          <path
            d="M172.175 62.7607H162.344V55.6514H169.037C172.273 55.6514 174.896 53.0279 174.896 49.792C174.896 46.5561 172.273 43.9326 169.037 43.9326H162.344V36.8232H172.175C175.411 36.8232 178.034 34.1998 178.034 30.9639C178.034 27.7279 175.411 25.1045 172.175 25.1045H156.484C153.248 25.1045 150.625 27.7279 150.625 30.9639V68.6201C150.625 71.8561 153.248 74.4795 156.484 74.4795H172.175C175.411 74.4795 178.034 71.8561 178.034 68.6201C178.034 65.3842 175.411 62.7607 172.175 62.7607Z"
            fill="#FFF7F4"
          />
          <path
            d="M84.3094 25.1045C72.427 25.1045 62.7598 36.1795 62.7598 49.792C62.7598 63.4045 72.427 74.4795 84.3094 74.4795C96.1918 74.4795 105.859 63.4045 105.859 49.792C105.859 36.1795 96.1918 25.1045 84.3094 25.1045ZM84.3094 62.7607C78.9805 62.7607 74.4785 56.8217 74.4785 49.792C74.4785 42.7623 78.9805 36.8232 84.3094 36.8232C89.6383 36.8232 94.1402 42.7623 94.1402 49.792C94.1402 56.8217 89.6383 62.7607 84.3094 62.7607Z"
            fill="white"
          />
          <path
            d="M100 66.6885C103.629 62.2682 105.859 56.3244 105.859 49.792C105.859 43.2596 103.629 37.3158 100 32.8955V66.6885Z"
            fill="#FFF7F4"
          />
          <path
            d="M143.932 200H95.2451C88.6509 200 82.4517 197.432 77.7896 192.768L55.7459 170.724C50.256 165.238 48.6271 157.057 51.5974 149.881C54.5716 142.707 61.5076 138.073 69.2681 138.073C73.8236 138.073 78.1275 139.622 81.5873 142.469V81.1721C81.5873 71.0857 89.7392 62.7607 99.999 62.7607C110.151 62.7607 118.411 71.0201 118.411 81.1721V112.969H143.931C157.544 112.969 168.619 124.044 168.619 137.656V175.313C168.619 188.925 157.544 200 143.932 200Z"
            fill="#FFDBC8"
          />
          <path
            d="M143.932 200C157.545 200 168.62 188.925 168.62 175.313V137.656C168.62 124.044 157.545 112.969 143.932 112.969H118.412V81.1721C118.412 71.0201 110.152 62.7607 100 62.7607V200H143.932Z"
            fill="#FFC1B1"
          />
        </g>
        <defs>
          <clipPath id="clip0_25_12979">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
