import { useEffect } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { GET_COMPANY, GetCompanyQuery } from '@/logicLayers/domain';

import { EditPageWrap, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { CompanyEditProvider } from './components';

export const CompanyEditPage = () => {
  const [searchParams] = useSearchParams();
  const { companyId = '' } = useParams();

  const { t: tCompany } = useTranslation('company');
  const { t: tGlobal, i18n } = useTranslation('global');

  const {
    loading: loadingCompany,
    error: errorCompany,
    data: dataCompany
  } = useQuery<GetCompanyQuery>(GET_COMPANY, { fetchPolicy: 'no-cache', variables: { companyId } });

  useEffect(() => {
    // ToDo Add workflow for error
    // ToDo Remove
    if (errorCompany) console.log('CompanyEditPage errorCompany - ', errorCompany);
  }, [errorCompany]);

  const editValue = searchParams.get('field') ?? '';
  const isLoading = loadingCompany;
  const navigateToUrl = LINKS_PAGES.company + '/' + companyId + '/settings';

  const Component = dataCompany?.company
    ? CompanyEditProvider({
        ...dataCompany,
        /* custom fields */
        editType: editValue,
        navigateToUrl,
        tCompany,
        tGlobal,
        i18n
      })
    : null;

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        {isLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
        ) : (
          <EditPageWrap navigateTo={navigateToUrl}>
            {Component ? Component : <Navigate to={LINKS_PAGES.home} />}
          </EditPageWrap>
        )}
      </Grid>
    </Grid>
  );
};
