import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import { Text } from 'infinitytechnologies-ui';

import { Box, Fade, FormControl, InputAdornment, Menu, Stack } from '@mui/material';

import { ExpandLessIcon, ExpandMoreIcon, SearchIcon } from '@/separatedModules/components';

import { StyledButton, StyledCounter, StyledSearchInput } from './styles';

import { BaseTableDropdownProps } from './helpers';

export const BaseTableDropdown: FC<BaseTableDropdownProps> = ({
  anchorEl,
  id,
  title,
  counter,
  searchPlaceholder,
  searchValue = '',
  children,
  footer,
  hasSearch = false,
  disabled,
  onSearchChange,
  onDropdownClick,
  onClose,
  isRound
}) => {
  const open = Boolean(anchorEl);

  return (
    <>
      <StyledButton
        disabled={disabled}
        isActive={open}
        onClick={onDropdownClick}
        isRound={isRound}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        type="button"
      >
        <Stack direction={'row'} spacing={'8px'}>
          {counter ? <StyledCounter>{counter}</StyledCounter> : null}

          <Text
            component={'div'}
            variant={'labelMd'}
            sx={{ m: 0, color: disabled ? 'inherit' : '#090E16', lineHeight: '20px' }}
          >
            {title}
          </Text>

          {open ? (
            <ExpandMoreIcon sx={{ fill: disabled ? '#090E164D' : '#313849' }} />
          ) : (
            <ExpandLessIcon sx={{ fill: disabled ? '#090E164D' : '#313849' }} />
          )}
        </Stack>
      </StyledButton>

      <Menu
        id={id}
        anchorEl={anchorEl}
        PopoverClasses={{
          root: classNames('MuiPopover-root-filters', {
            'MuiPopover-root-filters--with-search': !footer && hasSearch,
            'MuiPopover-root-filters--with-footer': footer && !hasSearch,
            'MuiPopover-root-filters--with-both': hasSearch && footer,
            'MuiPopover-root-filters--with-round': isRound
          })
        }}
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        TransitionComponent={Fade}
        open={open}
        onClose={onClose}
      >
        {hasSearch ? (
          <FormControl className={'MuiList-root__filter__i__search'} variant={'outlined'}>
            <StyledSearchInput
              value={searchValue}
              placeholder={searchPlaceholder}
              startAdornment={
                <InputAdornment position={'start'}>
                  <SearchIcon />
                </InputAdornment>
              }
              autoFocus
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange?.(e.target.value)}
            />
          </FormControl>
        ) : null}

        {children ? <Box className={'MuiList-root__filter__i__children'}>{children}</Box> : null}

        {footer ? <Box className={'MuiList-root__filter__i__apply'}>{footer}</Box> : null}
      </Menu>
    </>
  );
};
