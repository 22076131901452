import { Text } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material/styles';

import { EditItemHover } from '@/separatedModules/components';

interface EditItemHoverParameterProps {
  value: string | undefined;
  isBold?: boolean;
  handleClick?: () => void;
  sx?: SxProps<Theme>;
  isDisabled?: boolean;
}

export const EditItemHoverParameter = ({ value, isBold, handleClick, sx, isDisabled }: EditItemHoverParameterProps) => {
  return (
    <EditItemHover onClick={handleClick} isDisabled={isDisabled}>
      <Text className={'m-0'} variant={'bodyLg'} sx={{ ...{ fontWeight: isBold ? '600' : 'normal' }, ...sx }}>
        {value}
      </Text>
    </EditItemHover>
  );
};
