import classNames from 'classnames';
import { Autocomplete, AutocompleteProps } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material/styles';

import { TextBlockControl } from '@/separatedModules/components';

import { StyledAutocompleteControlControl } from './style';

interface AutocompleteControlProps extends AutocompleteProps {
  title?: string;
  subTitle?: string;
  sxTitle?: SxProps<Theme>;
  sxSubTitle?: SxProps<Theme>;
  sxSelect?: SxProps<Theme>;
}

export const AutocompleteControl = ({
  title,
  subTitle,
  sxTitle,
  sxSubTitle,
  sxSelect,
  ...rest
}: AutocompleteControlProps) => {
  const isHasTitles = !title && !subTitle;

  return (
    <StyledAutocompleteControlControl
      className={classNames({
        'no-titles': isHasTitles
      })}
    >
      <TextBlockControl
        className={'control-autocomplete__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
      />

      <Autocomplete sx={sxSelect} {...rest} />
    </StyledAutocompleteControlControl>
  );
};
