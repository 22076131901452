import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
// import { Cell, createColumnHelper, flexRender, Header } from '@tanstack/react-table';
import { createColumnHelper, flexRender } from '@tanstack/react-table';
// import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

// import Chip from '@mui/material/Chip';
import { GET_USERS, GetUsersQuery, UserRes } from '@/logicLayers/domain';

// import { EMPTY_CELL_VALUE, ImageLazy, TooltipOverflowItem } from '@/separatedModules/components';
import { DomainStatusChip, EMPTY_CELL_VALUE, SystemStatusChip } from '@/separatedModules/components';
import {
  createHeaderCellItem,
  skeletonTableCellImage,
  skeletonTableCellRect
} from '@/separatedModules/components/Table';

export type UserResUpdated = UserRes & { className?: string };

const columnHelper = createColumnHelper<UserResUpdated>();

export const TableCellItem = ({ cell, isHeader = false }: { cell: any; isHeader?: boolean }) => {
  return (
    <TableCell
      sx={{
        width: cell?.column?.columnDef.size,
        minWidth: cell?.column?.columnDef.minSize
      }}
    >
      {isHeader
        ? flexRender(cell?.column.columnDef.header, cell?.getContext())
        : flexRender(cell?.column.columnDef.cell, cell?.getContext())}
    </TableCell>
  );
};

export const columns = [
  columnHelper.accessor('firstName', {
    id: 'name',
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {rowOriginal?.firstName ? (
            <Text variant={'titleSm'} sx={{ margin: '0' }}>
              {rowOriginal?.firstName}
            </Text>
          ) : (
            EMPTY_CELL_VALUE
          )}

          {rowOriginal?.lastName ? (
            <Text variant={'titleSm'} sx={{ margin: '0 0 0 4px' }}>
              {rowOriginal?.lastName}
            </Text>
          ) : null}
        </Box>
      );
    }
  }),
  columnHelper.accessor('email', {
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return rowOriginal?.email ? (
        <a className={'with-underline'} href={`mailto:${rowOriginal?.email}`}>
          {rowOriginal.email}
        </a>
      ) : (
        <span>{EMPTY_CELL_VALUE}</span>
      );
    }
  }),
  columnHelper.accessor('phoneNumber', {
    minSize: 200,
    size: 200,
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return rowOriginal?.phoneNumber ? (
        <a className={'with-underline'} href={`tel:${rowOriginal?.phoneNumber}`}>
          {rowOriginal.phoneNumber}
        </a>
      ) : (
        <span>{EMPTY_CELL_VALUE}</span>
      );
    }
  }),
  columnHelper.accessor('company', {
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return <div>{rowOriginal?.company?.[0]?.name || EMPTY_CELL_VALUE}</div>;
    }
  }),
  columnHelper.accessor('permissionTag', {
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return <div>{rowOriginal?.permissionTag}</div>;
    }
  }),
  columnHelper.accessor('domains', {
    minSize: 200,
    size: 200,
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return rowOriginal?.domains?.length
        ? rowOriginal?.domains.slice(0, 1).map((domain, index) => {
            return <DomainStatusChip key={index} color={domain?.color} label={domain?.name} />;
          })
        : EMPTY_CELL_VALUE;
    }
  }),
  columnHelper.accessor('status', {
    minSize: 150,
    size: 150,
    header: (props) => createHeaderCellItem(props.column.id),
    cell: ({ row: { original: rowOriginal } }) => {
      return rowOriginal?.status ? <SystemStatusChip status={rowOriginal?.status as any} /> : EMPTY_CELL_VALUE;
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDomains: [],
    sortByType: [],
    sortByTypeSelected: '',
    sortByDate: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<GetUsersQuery>(GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      query: state.filters.search.length ? state.filters.search[0] : undefined,
      // permissions: filterRoles,
      page: state.page[0],
      pageSize: state.rowsPerPage[0]
      /*
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        }
      }
      */
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByType = useCallback((value: string[], type: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByType: value,
          sortByTypeSelected: type
        }
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByType,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
