import { gql as gqlApollo } from '@apollo/client';

export const GET_IMAGE_URL = gqlApollo(`
  query GetImageUrl($fileKey: String!) {
    fileUrl(fileKey: $fileKey) {
      fileKey
      url
    }
  }
`);

export const SEARCH_UNITS = gqlApollo(`
  query SearchUnits($searchCriteria: SearchCriteriaInput) {
    dataItems: searchUnits(searchCriteria: $searchCriteria) {
      items {
        id
        name
      }
    }
  }
`);

export const SUBSCRIBE_TO_NOTIFICATIONS = gqlApollo(`
  subscription SubscribeToNotifications {
    subscribeToNotifications {
      id
      content
      type
      title
    }
  }
`);
