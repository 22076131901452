import { Dialog } from 'infinitytechnologies-ui';

import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    maxWidth: '840px'
  },
  '.MuiModal-box': {
    maxWidth: '840px !important'
  },
  '.MuiModal-content': {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  }
})) as typeof Dialog;
