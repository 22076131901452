import { useState } from 'react';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import { CssTransition } from '@mui/base/Transitions';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box } from '@mui/material';

import { EMPTY_CELL_VALUE, Flex } from '@/separatedModules/components';
import { DownloadIcon } from '@/separatedModules/components/Icon/svg-components/Download';
import { QrIcon } from '@/separatedModules/components/Icon/svg-components/Qr';

import { styles } from './styles';

interface PopoverLinkProps {
  id: string;
  systemName?: string;
}

export const PopoverLink = ({ id, systemName }: PopoverLinkProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const baseUrl = window.location.origin;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Flex alignItems="center" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <Link
        className={'m-0'}
        to={`/locations/equipment/${id}`}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          wordBreak: 'break-all',
          textDecoration: 'underline',
          marginRight: '2px'
        }}
      >
        {systemName || EMPTY_CELL_VALUE}
      </Link>
      <QrIcon />

      <style>{styles}</style>
      <BasePopup id={id} open={open} anchor={anchorEl} placement={'bottom-start'}>
        <CssTransition enterClassName="open" exitClassName="close" lastTransitionedPropertyOnExit="transform">
          <Box
            sx={{
              width: 204,
              height: 236,
              background: 'white',
              borderRadius: '4px',
              padding: '12px 18px',
              boxShadow:
                '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.04), 0px 3px 6px -4px rgba(0, 0, 0, 0.01)'
            }}
          >
            <Flex justifyContent="space-between" alignItems={'center'}>
              <Text sx={{ fontSize: '14px', fontWeight: 600 }}>{systemName}</Text>
              <DownloadIcon />
            </Flex>
            <Box
              sx={{
                marginTop: '10px',
                width: '100%',
                background: '#EFF1F4',
                borderRadius: '4px',
                padding: '12px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <QRCode
                size={156}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`${baseUrl}/locations/equipment/${id}`}
                viewBox={'0 0 256 256'}
                level="M"
              />
            </Box>
          </Box>
        </CssTransition>
      </BasePopup>
    </Flex>
  );
};
