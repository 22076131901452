import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function UserShieldSolidIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M12 3.75C9.075 3.75 6.75 6.075 6.75 9C6.75 10.8281 7.65747 12.4211 9.05273 13.3594C6.38251 14.5105 4.5 17.1702 4.5 20.25H6C6 16.95 8.7 14.25 12 14.25C12.2559 14.25 12.5045 14.2258 12.75 14.1914V17.625C12.75 21.525 17.8497 23.7756 18.0747 23.8506L18.375 24L18.6753 23.8506C18.9003 23.7756 24 21.525 24 17.625V13.5H23.3247C21.8997 13.5 21.0744 12.9756 20.3994 12.6006C19.7244 12.3006 19.125 12 18.375 12C17.625 12 17.025 12.3006 16.5 12.6006C16.1203 12.8115 15.6648 13.0667 15.0894 13.2524C16.4011 12.3031 17.25 10.7631 17.25 9C17.25 6.075 14.925 3.75 12 3.75ZM12 5.25C14.1 5.25 15.75 6.9 15.75 9C15.75 11.1 14.1 12.75 12 12.75C9.9 12.75 8.25 11.1 8.25 9C8.25 6.9 9.9 5.25 12 5.25ZM18.375 13.5C18.75 13.5 19.0494 13.6497 19.6494 13.9497L19.875 14.0244C20.475 14.3244 21.3 14.7753 22.5 14.9253V17.5503C22.5 20.0253 19.275 21.8244 18.375 22.2744C17.475 21.8244 14.25 20.0253 14.25 17.5503V14.9253C15.525 14.7753 16.3497 14.3244 16.9497 14.0244L17.1753 13.9497H17.25H17.3247C17.7747 13.5747 18 13.5 18.375 13.5Z" />
    </SvgIcon>
  );
}
