export const iconFolderColorCheck = (selectedFolderId: string, folderId: string, disabled: boolean): string => {
  switch (true) {
    case selectedFolderId === folderId: {
      return '#0C66E4';
    }
    case disabled: {
      return 'rgba(9, 14, 22, 0.30)';
    }
    default: {
      return '#313849';
    }
  }
};
