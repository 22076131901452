import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  EDIT_EQUIPMENT,
  EquipmentRes,
  GET_EQUIPMENT,
  GET_USER_PROFILE,
  GetProfileQuery,
  updateEquipmentItemModel
} from '@/logicLayers/domain';

import {
  AvatarImagePreviewButton,
  Breadcrumbs,
  ChevronRightIcon,
  DomainStatusChip,
  EditItemHover,
  EMPTY_CELL_VALUE,
  Flex,
  ImageLazy,
  ImageUploader,
  Preloader,
  PreloaderVariantsE,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_IMAGES, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { RowActionMenu } from '../../../Equipment/components/TableBody/components/RowActionMenu';

import { useQueryData } from './helpers';

export const TemplatesEquipmentItemPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');

  const { dataEquipmentTemplate, loadingEquipmentTemplate } = useQueryData({ templateId });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);
  const [updatedTemplatesEquipmentItem] = useMutation(EDIT_EQUIPMENT, {
    refetchQueries: [GET_EQUIPMENT]
  });

  const handleEditItem = (toPage: string) => {
    const link = isEquipmentTemplate ? LINKS_PAGES.templatesEquipmentItemEdit : LINKS_PAGES.equipmentModelEdit;

    navigateTo({
      pathname: link.replace(':templateId', templateId),
      search: `field=${toPage}`
    });
  };

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const handleEditName = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('name');
  const handleEditItemDescription = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('itemDescription');
  const handleEditTemplateDescription = () =>
    dataEquipmentTemplate?.equipment?.active && handleEditItem('templateDescription');
  const handleEditModelNumber = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('modelNumber');
  const handleEditManufacturer = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('manufacturer');
  const handleEditDomain = () => dataEquipmentTemplate?.equipment?.active && handleEditItem('domainId');

  const handleImageUpload = (imageKey: string) => {
    if (!dataEquipmentTemplate?.equipment?.id) return;

    updatedTemplatesEquipmentItem({
      variables: {
        id: dataEquipmentTemplate?.equipment.id,
        equipment: { ...updateEquipmentItemModel(dataEquipmentTemplate, { fileKey: imageKey }) }
      }
    });
  };

  const handleImageRemove = () => {
    if (!dataEquipmentTemplate?.equipment?.id) return;

    updatedTemplatesEquipmentItem({
      variables: {
        id: dataEquipmentTemplate?.equipment.id,
        equipment: { ...updateEquipmentItemModel(dataEquipmentTemplate, { fileKey: null }) }
      }
    });
  };

  const renderItemId = Boolean(dataEquipmentTemplate?.equipment?.id);
  const renderItemActive = Boolean(dataEquipmentTemplate?.equipment?.active);
  const renderItemTemplateDescription = dataEquipmentTemplate?.equipment?.templateDescription || EMPTY_CELL_VALUE;
  const renderItemItemDescription = dataEquipmentTemplate?.equipment?.itemDescription || EMPTY_CELL_VALUE;
  const renderItemDomain = dataEquipmentTemplate?.equipment?.domain;
  const renderItemImageUrl = dataEquipmentTemplate?.equipment?.fileUrl?.url || LINKS_IMAGES.panorama;
  const renderItemImageFileKey = dataEquipmentTemplate?.equipment?.fileUrl?.fileKey || '';
  const renderItemManufacturer = dataEquipmentTemplate?.equipment?.manufacturer || EMPTY_CELL_VALUE;
  const renderItemModelNumber = dataEquipmentTemplate?.equipment?.modelNumber || EMPTY_CELL_VALUE;
  const renderItemSystemName = dataEquipmentTemplate?.equipment?.systemName || EMPTY_CELL_VALUE;
  const renderItemName = dataEquipmentTemplate?.equipment?.name;

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { titleFallback: renderItemName }
  ];

  const breadcrumbsItemLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemAllLocations') },
    { titleFallback: renderItemName }
  ];

  const handleCopy = () => navigator.clipboard.writeText(renderItemSystemName);

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsLinks : breadcrumbsItemLinks;

  return loadingEquipmentTemplate ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <Grid justifyContent={'center'} container row>
      {!dataEquipmentTemplate && !loadingEquipmentTemplate ? <Navigate to={LINKS_PAGES.templatesEquipment} /> : null}

      <Grid xs={12} lg={10} xl={8} item>
        <Breadcrumbs
          links={breadcrumbs}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />

        <Text component={'h2'} variant={'titleXl'}>
          {tTemplates('equipmentItem.overview.pageTitle')}
        </Text>

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box>
            <ImageUploader
              path={'EQUIPMENT_PHOTO'}
              imgAlt={tTemplates('createEquipmentItem.imageUploader.alt')}
              oldImageUrl={renderItemImageUrl}
              oldImageFileKey={renderItemImageFileKey}
              modalTitle={tTemplates('createEquipmentItem.imageUploader.title')}
              modalTitleToDelete={tTemplates('createEquipmentItem.imageUploader.titleToDelete')}
              modalSubTitle={tTemplates('createEquipmentItem.imageUploader.subTitle')}
              btnUploadPhotoText={tGlobal('imageUploader.btnUploadPhoto')}
              allowedExtensionsText={''}
              minDimensionsText={'72px x 72px'}
              maxDimensionsText={''}
              maxFileSizeText={'5'}
              imageMinWidth={72}
              imageMinHeight={72}
              isDisabled={!isShowEditItem || !isUserCanCRUD}
              imageMaxSize={5242880} // Bites
              imageAllowedExtensions={['png', 'jpeg', 'jpg', 'svg']}
              imageRequired
              titleContent={
                <Text
                  className={'m-0'}
                  variant={'bodyMd'}
                  sx={{ color: 'var(--text-subtle, #505668)', textAlign: 'center' }}
                >
                  {tTemplates('createEquipmentItem.imageUploader.allowedExtensionsText')}{' '}
                  {tTemplates('createEquipmentItem.imageUploader.titleContent')}
                </Text>
              }
              modalContent={
                <AvatarImagePreviewButton
                  imgAlt={''}
                  sxBox={{
                    margin: '0 auto 24px'
                  }}
                  sxImageWrap={{ width: 180, height: 180, flex: '0 0 180px' }}
                  sxImage={{ fontSize: 96 }}
                />
              }
              renderUploaderButton={({ imgAlt, oldImage, handleOpenModalPhoto }) => {
                return (
                  <AvatarImagePreviewButton
                    imgAlt={imgAlt}
                    oldImage={oldImage || LINKS_IMAGES.panorama}
                    imageWidth={180}
                    imageHeight={180}
                    sxBox={{
                      margin: '0 auto 24px'
                    }}
                    sxImageWrap={{ cursor: isShowEditItem || isUserCanCRUD ? 'pointer' : 'auto' }}
                    sxImage={{ fontSize: 80 }}
                    onClick={handleOpenModalPhoto}
                  />
                );
              }}
              onImageUploadSuccess={handleImageUpload}
              onImageDelete={handleImageRemove}
              onImageUploadError={() => {
                // ToDo Remove
                console.log('ImageUploader handleSaveImage uploadedResult error');
              }}
            />
          </Box>

          <Box
            sx={{
              flex: '0 1 100%',
              maxWidth: {
                md: 'calc(100% - 204px)'
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 0 8px 0' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {renderItemName ? (
                  <EditItemHover
                    sxBoxWrap={{ padding: '0 8px 0 0' }}
                    isDisabled={!isShowEditItem || !renderItemActive}
                    onClick={handleEditName}
                  >
                    <Text className={'m-0'} variant={'titleMd'}>
                      {renderItemName}
                    </Text>
                  </EditItemHover>
                ) : null}

                {!isEquipmentTemplate && (
                  <Flex sx={{ marginRight: '16px', cursor: 'pointer' }} onClick={handleCopy}>
                    <Text sx={{ fontSize: '14px', fontWeight: '500' }}>UID:</Text>
                    <Text
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#505668',
                        marginLeft: '4px',
                        marginRight: '7px'
                      }}
                    >
                      {renderItemSystemName}
                    </Text>
                    <ContentCopyIcon sx={{ width: '18px', height: '18px', fill: '#63697C' }} />
                  </Flex>
                )}

                <SystemStatusChip
                  status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled}
                />
              </Box>

              {isShowEditItem && isUserCanCRUD && renderItemId ? (
                <RowActionMenu
                  {...(dataEquipmentTemplate?.equipment as EquipmentRes)}
                  isTableVariant={false}
                  isEquipmentItem={!isEquipmentTemplate}
                  renderModalContent={() => {
                    return (
                      <Flex
                        sx={{
                          borderRadius: 'var(--4, 4px)',
                          background: 'var(--background-neutral-hovered, #F7F8F9)',
                          padding: '8px',
                          margin: '24px 0 0',
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Flex alignItems="center">
                          {renderItemImageUrl ? (
                            <ImageLazy
                              src={renderItemImageUrl}
                              alt={`Equipment item parameter ${renderItemName} type preview`}
                              variant={'rectangular'}
                              width={32}
                              height={32}
                              sxStyle={{
                                margin: '0 8px 0 0'
                              }}
                            />
                          ) : null}

                          <Text
                            variant={'labelLg'}
                            m={0}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              wordBreak: 'break-all',
                              maxWidth: '340px'
                            }}
                          >
                            {renderItemName}
                          </Text>
                        </Flex>

                        {renderItemDomain ? (
                          <DomainStatusChip color={renderItemDomain?.color} label={renderItemDomain.name} />
                        ) : (
                          EMPTY_CELL_VALUE
                        )}
                      </Flex>
                    );
                  }}
                />
              ) : null}
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditItemDescription}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                  {renderItemItemDescription}
                </Text>
              </EditItemHover>
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.domain')}</Text>

              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditDomain}
              >
                {renderItemDomain ? (
                  <DomainStatusChip color={renderItemDomain?.color} label={renderItemDomain.name} />
                ) : (
                  EMPTY_CELL_VALUE
                )}
              </EditItemHover>
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.modelNumber')}</Text>

              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditModelNumber}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                  {renderItemModelNumber}
                </Text>
              </EditItemHover>
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.manufacturer')}</Text>

              <EditItemHover
                isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                onClick={handleEditManufacturer}
              >
                <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                  {renderItemManufacturer}
                </Text>
              </EditItemHover>
            </Box>

            {isEquipmentTemplate && (
              <Box sx={{ margin: '0 0 32px' }}>
                <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.templateDescription')}</Text>

                <EditItemHover
                  isDisabled={!isShowEditItem || !isUserCanCRUD || !renderItemActive}
                  onClick={handleEditTemplateDescription}
                >
                  <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                    {renderItemTemplateDescription}
                  </Text>
                </EditItemHover>
              </Box>
            )}

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'}>{tTemplates('equipmentItem.overview.emergencyContact')}</Text>

              <EditItemHover isDisabled>
                <img src={LINKS_IMAGES.emergencyContact} width={24} height={24} alt="" />

                <Text variant={'bodyLg'} sx={{ margin: '0 0 0 8px' }}>
                  {tTemplates('equipmentItem.overview.emergencyContactNotSet')}
                </Text>
              </EditItemHover>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
