import { CSSProperties, ReactNode } from 'react';

type MenuItemDividerColors = Record<MenuItemDividerState, CSSProperties>;

export type MenuItemDividerVariant = 'hasSpace' | 'hasNoSpace';
export type MenuItemDividerState = 'active' | 'disabled';

export interface BaseMenuOption {
  label: string;
  key: string;
  value: ReactNode;
  icon?: ReactNode;
}

export interface MenuOption extends BaseMenuOption {
  checked: boolean;
}

export interface VisibilityMenuOption {
  label: string;
  key: string[];
  value: ReactNode;
  icon?: ReactNode;
}

export interface VisibilityOption extends VisibilityMenuOption {
  checked: boolean;
}

export const MENU_ITEM_DIVIDER_STYLES: Record<MenuItemDividerVariant, MenuItemDividerColors> = {
  hasSpace: {
    active: {
      marginTop: '8px',
      marginBottom: '8px',
      color: '#090E1626'
    },
    disabled: {
      marginTop: '8px',
      marginBottom: '8px',
      color: '#090E160F'
    }
  },
  hasNoSpace: {
    active: {
      marginTop: 0,
      marginBottom: 0,
      color: '#090E1626'
    },
    disabled: {
      marginTop: 0,
      margin: 0,
      color: '#090E160F'
    }
  }
};
