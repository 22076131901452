import { memo, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import classNames from 'classnames';

import { base64ToFile } from '@/utils';

import { ImageUploaderStateI } from '../../helpers';

interface ImageReaderProps {
  minOutputHeight?: number;
  minOutputWidth?: number;
  state: ImageUploaderStateI;
  getImage: any;
}

export const ImageReader = memo(
  ({ state, getImage, minOutputHeight = 200, minOutputWidth = 200 }: ImageReaderProps) => {
    const cropperRef = useRef<ReactCropperElement>(null);

    const handleCropImage = async () => {
      if (state.file && typeof cropperRef.current?.cropper !== 'undefined') {
        const croppedImage = cropperRef.current?.cropper.getCroppedCanvas({
          minWidth: minOutputWidth,
          minHeight: minOutputHeight,
          maxWidth: 600,
          maxHeight: 600,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high'
        });

        const croppedBase64 = croppedImage.toDataURL('', 1);
        const croppedBlob = await base64ToFile({
          url: croppedBase64,
          fileName: state.file.name,
          // ToDo Old version for SVG
          // mimeType: state.file.type
          mimeType: 'image/png'
        });

        return croppedBlob;
      }
    };

    getImage.current = async () => {
      return await handleCropImage();
    };

    return (
      <Cropper
        className={classNames('cp__cropper')}
        src={state.file?.preview}
        viewMode={1}
        dragMode={'move'}
        initialAspectRatio={1}
        aspectRatio={1}
        minCropBoxWidth={200}
        minCropBoxHeight={200}
        background={false}
        responsive={true}
        autoCropArea={0.5}
        // movable={false}
        // zoomable={false}
        scalable={false}
        rotatable={false}
        cropBoxResizable={true}
        ref={cropperRef}
      />
    );
  }
);
