import { useState } from 'react';
import { Button } from 'infinitytechnologies-ui';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { AddFolderIcon, AddUserIcon, PlusIcon } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { useTranslation } from '@/i18n';

interface AddUserButtonProps {
  handleOpenModal: () => void;
}

export const AddUserButton = ({ handleOpenModal }: AddUserButtonProps) => {
  const { t: tUsers } = useTranslation('users');

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant={'contained'}
        aria-haspopup={'true'}
        aria-controls={open ? 'create user for company' : undefined}
        aria-expanded={open ? 'true' : undefined}
        startIcon={<PlusIcon />}
        disableElevation
        onClick={handleClickButton}
        disabled={!isUserCanCRUD}
      >
        {tUsers('addUser.btnAdd')}
      </Button>

      <Menu
        id={'user-management-add-user-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'user-management-add-user-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ margin: '12px 0 0 0' }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenModal();
          }}
        >
          <AddUserIcon />

          {tUsers('addUser.menuList.menuListItemInvite')}
        </MenuItem>
        <MenuItem onClick={handleClose} disabled>
          <AddFolderIcon />

          {tUsers('addUser.menuList.menuListItemFolder')}
        </MenuItem>
      </Menu>
    </>
  );
};
