import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';

import { GET_LOCATION_FOLDER } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import { PageTitle } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { i18n, useTranslation } from '@/i18n';

import { AddOrEditLocation, LocationActionTypeEnum } from '../AddOrEditLocation';
import { useContextState } from '../Providers';

import { getBreadcrumbs } from './helpers';

export const PageTopBox = () => {
  const { t: tLocations } = useTranslation('locations');

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { folderId } = useParams<{ folderId?: string }>();
  const { currentLocationFolderData } = useLocations();

  const { data: dataLocationFolder } = useQuery(GET_LOCATION_FOLDER, {
    variables: {
      id: folderId
    }
  });

  const breadcrumbsLinks = getBreadcrumbs(dataLocationFolder?.locationFolder);

  // TODO Refactor
  const isFolderActive = currentLocationFolderData.isActive || currentLocationFolderData.isActive === undefined;
  const isSameFolderId = folderId === currentLocationFolderData.folderId || folderId === undefined;

  const isDisabled = !isFolderActive || !isSameFolderId || !isUserCanCRUD;

  const state = useContextState();

  const count = state.filters.search.length ? state.resultSearchCount : state.resultTotalCount;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={folderId ? tLocations('pageTitle') : tLocations('pageTitleAllEquipment')}
        dataFoundTitle={
          !state.loadingEquipmentItems ? i18n.t('pageTitleFound', { value: count ?? 0, ns: 'global' }) : ''
        }
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
        breadcrumbsLinks={breadcrumbsLinks}
      />

      <AddOrEditLocation actionType={LocationActionTypeEnum.Create} isDisabled={isDisabled} />
    </Box>
  );
};
