import { FC } from 'react';

import { Step1 } from './Step1/Step1';
import { Step2 } from './Step2/Step2';
import { Step3 } from './Step3/Step3';
import { Step4 } from './Step4/Step4';
import { Step5 } from './Step5/Step5';

interface StepsProps {
  step: string;
}

const Component: { [step: string]: FC } = {
  step1: Step1,
  step2: Step2,
  step3: Step3,
  step4: Step4,
  step5: Step5
};

export const Steps = ({ step }: StepsProps) => {
  const SelectedComponent = Component[step];

  return <>{SelectedComponent ? <SelectedComponent /> : null}</>;
};
