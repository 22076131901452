import { Text } from 'infinitytechnologies-ui';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';

import { Breadcrumbs } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiRadioSwitchCheckbox, titleFallback: 'Radio, Switch, Checkbox' }
];

export const UiRadioSwitchCheckboxPage = () => {
  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <Breadcrumbs links={BreadcrumbsLinks} />
      </Grid>

      <Grid xs={12} md={6} lg={4} item>
        <Text className={'mb-10'} variant={'h5'} sx={{ marginBottom: '16px' }}>
          Radio example
        </Text>

        <Radio className={'mr-2'} disableRipple />
        <Radio disableRipple checked />

        <Radio className={'mr-2'} disableRipple disabled />
        <Radio disableRipple checked disabled />
      </Grid>

      <Grid xs={12} md={6} lg={4} item>
        <Text className={'mb-10'} variant={'h5'} sx={{ marginBottom: '16px' }}>
          Switch example
        </Text>

        <Switch className={'mr-2'} />
        <Switch disabled />

        <Switch className={'mr-2'} defaultChecked />
        <Switch defaultChecked disabled />
      </Grid>

      <Grid xs={12} md={6} lg={4} item>
        <Text className={'mb-10'} variant={'h5'} sx={{ marginBottom: '16px' }}>
          Checkbox example
        </Text>

        <Checkbox />
        <Checkbox disabled />
        <Checkbox checked />
        <Checkbox checked disabled />

        <Checkbox color={'error'} />
        <Checkbox color={'error'} disabled />
        <Checkbox color={'error'} checked />
        <Checkbox color={'error'} checked disabled />
      </Grid>
    </Grid>
  );
};
