import { useNavigate } from 'react-router-dom';

import { Box, Button, SvgIcon } from '@mui/material';

import { ArrowForwardIcon, DomainStatusChip, Flex, ImageLazy, TableAsideItem } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

interface UsersAsideContentProps {
  id?: string;
  userAvatar?: {
    url: string;
    alt?: string;
  };
  email?: string;
  phoneNumber?: string;
  role?: string;
  domain?: [
    {
      color: string;
      name: string;
    }
  ];
}

export const UsersAsideContent = (props: UsersAsideContentProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const handleClickDetails = () => {
    const { id } = props;

    id && navigateTo(LINKS_PAGES.userItem.replace(':userId', id));
  };

  return (
    <>
      {props?.userAvatar ? (
        <ImageLazy src={props?.userAvatar?.url} width={136} height={136} alt={props?.userAvatar?.url} />
      ) : (
        <Box
          sx={{
            width: '136px',
            height: '136px',
            marginRight: '8px',
            borderRadius: '50%',
            background: 'rgb(240, 240, 241)'
          }}
        />
      )}

      <TableAsideItem title={tGlobal('table.cells.email')} subTitle={props?.email} />
      <TableAsideItem title={tGlobal('table.cells.phoneNumber')} subTitle={props?.phoneNumber} />
      <TableAsideItem title={tGlobal('table.cells.role')} subTitle={props?.role} />
      <TableAsideItem
        title={tGlobal('table.cells.domain')}
        subTitle={
          <Flex flexWrap="wrap">
            {props?.domain?.map((domain, index) => (
              <Box key={index} sx={{ marginRight: '8px', marginBottom: '8px' }}>
                <DomainStatusChip color={domain?.color} label={domain?.name} />
              </Box>
            ))}
          </Flex>
        }
      />

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        onClick={handleClickDetails}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
      >
        {'Details'}
      </Button>
    </>
  );
};
