import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

interface StepsStateI {
  currentStep: number;
  totalSteps: number;
  isDataCreated: boolean;
}

type ContextStateT = StepsStateI & {
  setState: Dispatch<SetStateAction<StepsStateI>>;
  handleSetPrevStep: () => void;
};

export const ContextState = createContext<ContextStateT>({
  currentStep: 1,
  totalSteps: 2,
  isDataCreated: false,
  setState: () => {},
  handleSetPrevStep: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<StepsStateI>({
    currentStep: 1,
    totalSteps: 2,
    isDataCreated: false
  });

  const handleSetPrevStep = () => {
    setState((state) => ({ ...state, currentStep: state.currentStep - 1 }));
  };

  const contextValue = useMemo(() => {
    return {
      ...state,
      setState,
      handleSetPrevStep
    };
  }, [state]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
