import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ScheduledTasks(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M4.42048 9.01472C3.99953 9.01472 3.64323 8.86889 3.35156 8.57722C3.0599 8.28556 2.91406 7.92926 2.91406 7.50833V4.42341C2.91406 4.00247 3.0599 3.64616 3.35156 3.35449C3.64323 3.06283 3.99953 2.91699 4.42048 2.91699H15.5743C15.9952 2.91699 16.3515 3.06283 16.6432 3.35449C16.9349 3.64616 17.0807 4.00247 17.0807 4.42341V7.50833C17.0807 7.92926 16.9349 8.28556 16.6432 8.57722C16.3515 8.86889 15.9952 9.01472 15.5743 9.01472H4.42048ZM4.42048 7.76474H15.5743C15.6491 7.76474 15.7105 7.7407 15.7586 7.69262C15.8067 7.64455 15.8307 7.58312 15.8307 7.50833V4.42341C15.8307 4.34862 15.8067 4.28718 15.7586 4.2391C15.7105 4.19101 15.6491 4.16697 15.5743 4.16697H4.42048C4.34569 4.16697 4.28425 4.19101 4.23617 4.2391C4.18808 4.28718 4.16404 4.34862 4.16404 4.42341V7.50833C4.16404 7.58312 4.18808 7.64455 4.23617 7.69262C4.28425 7.7407 4.34569 7.76474 4.42048 7.76474ZM4.42048 17.0836C3.99953 17.0836 3.64323 16.9378 3.35156 16.6461C3.0599 16.3545 2.91406 15.9981 2.91406 15.5772V12.5003C2.91406 12.0794 3.0599 11.7231 3.35156 11.4314C3.64323 11.1397 3.99953 10.9939 4.42048 10.9939H15.5743C15.9952 10.9939 16.3515 11.1397 16.6432 11.4314C16.9349 11.7231 17.0807 12.0794 17.0807 12.5003V15.5772C17.0807 15.9981 16.9349 16.3545 16.6432 16.6461C16.3515 16.9378 15.9952 17.0836 15.5743 17.0836H4.42048ZM4.42048 15.8336H15.5743C15.6491 15.8336 15.7105 15.8096 15.7586 15.7615C15.8067 15.7134 15.8307 15.652 15.8307 15.5772V12.5003C15.8307 12.4255 15.8067 12.3641 15.7586 12.316C15.7105 12.2679 15.6491 12.2439 15.5743 12.2439H4.42048C4.34569 12.2439 4.28425 12.2679 4.23617 12.316C4.18808 12.3641 4.16404 12.4255 4.16404 12.5003V15.5772C4.16404 15.652 4.18808 15.7134 4.23617 15.7615C4.28425 15.8096 4.34569 15.8336 4.42048 15.8336Z" />
    </SvgIcon>
  );
}
