import { TextBlockControl } from '@/separatedModules/components';

import { TaskItem } from '../../../../../helpers';

import { StyledTaskItemPlate } from './style';

interface TaskItemPlateProps {
  selectedId: number;
  taskItem: TaskItem;
  onClick: () => void;
}

export const TaskItemPlate = ({ taskItem, selectedId, onClick }: TaskItemPlateProps) => {
  return (
    <StyledTaskItemPlate key={taskItem.id} selected={selectedId === taskItem.id} onClick={onClick}>
      <TextBlockControl
        className={'m-0'}
        title={taskItem.title}
        subTitle={taskItem.description}
        sxTitle={{ marginBottom: '8px' }}
        sxSubTitle={{ marginBottom: 0 }}
      />
    </StyledTaskItemPlate>
  );
};
