import { ReactNode, useState } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';

import { CompanyRes } from '@/logicLayers/domain';

import { SystemStatusChip, SystemStatusesEnum } from '@/separatedModules/components';
import { EMPTY_CELL_VALUE, skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { TranslationT } from '@/subsidiaryBinders/types';

import { i18n } from '@/i18n';

export interface TableCellI<Model = Record<string, any>> {
  order: number;
  columnName: string;
  columnClass: string;
  title: string;
  Render: (props: Model, className: string, index: number, t?: TranslationT) => ReactNode;
}

export interface ConfigTableI {
  cells: TableCellI<CompanyRes>[];
  pagination: {
    page: number[];
    rowsPerPage: number[];
    rowsPerPageOptions: number[];
  };
}

export const CONFIG_TABLE: ConfigTableI = {
  cells: [
    {
      order: 0,
      columnName: 'name',
      columnClass: 'MuiTableCell-name MuiTableCell-image MuiTableCell-image__companies',
      title: i18n.t('table.cells.companyName', { ns: 'companies' }),
      Render: ({ name, logo }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            {logo?.url ? (
              <img src={logo?.url} alt={`Company logo  - ${name}`} loading={'lazy'} />
            ) : (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  flex: '0 0 24px',
                  borderRadius: '50%',
                  margin: '0 8px 0 0',
                  background: 'rgba(9, 14, 22, 0.06)',
                  border: '1px solid var(--border-inverse, #F7F8F9)'
                }}
              />
            )}

            <Text
              className={'m-0'}
              variant={'titleSm'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {name}
            </Text>
          </TableCell>
        );
      }
    },
    {
      order: 1,
      columnName: 'address',
      columnClass: 'MuiTableCell-address',
      title: i18n.t('table.cells.companyAddress', { ns: 'companies' }),
      Render: ({ address, name }, className, index) => {
        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

        const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
          setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

        return (
          <TableCell key={name + index} className={className}>
            <Text
              className={'m-0'}
              variant={'bodyMd'}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup={'true'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical'
              }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {address || EMPTY_CELL_VALUE}
            </Text>

            <Popover
              id={address + 'mouse-over-popover'}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              sx={{
                pointerEvents: 'none',
                top: '4px'
              }}
              open={open}
              disableRestoreFocus
              onClose={handlePopoverClose}
            >
              <Text className={'m-0'} variant={'bodyMd'} sx={{ padding: '8px', maxWidth: '250px' }}>
                {address || EMPTY_CELL_VALUE}
              </Text>
            </Popover>
          </TableCell>
        );
      }
    },
    {
      order: 2,
      columnName: 'representative',
      columnClass: 'MuiTableCell-representative',
      title: i18n.t('table.cells.representative', { ns: 'companies' }),
      Render: ({ name, contactInfo: { representative } }, className, index) => {
        return (
          <TableCell key={name + index} className={className}>
            {representative || EMPTY_CELL_VALUE}
          </TableCell>
        );
      }
    },
    {
      order: 3,
      columnName: 'status',
      columnClass: 'MuiTableCell-status',
      title: i18n.t('table.cells.status', { ns: 'companies' }),
      Render: ({ active: companyIsActive, name }, className, index) => {
        const status = companyIsActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled;

        return (
          <TableCell key={name + index} className={className}>
            <SystemStatusChip status={status} />
          </TableCell>
        );
      }
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
