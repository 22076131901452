import { Dialog } from 'infinitytechnologies-ui';

import { styled } from '@mui/material/styles';

import { ModalSize } from './AddLocationModal';

interface StyledDialogProps {
  modalSize: ModalSize;
}

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop: string) => prop !== 'modalSize'
})(({ modalSize }: StyledDialogProps) => ({
  ...(modalSize === 'big' && {
    '.MuiPaper-root': {
      maxWidth: '840px'
    },
    '.MuiModal-box': {
      maxWidth: '840px !important'
    }
  })
}));
