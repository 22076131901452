import { useQuery } from '@apollo/client';

import { EquipmentQuery, GET_EQUIPMENT } from '@/logicLayers/domain';

export const ITEM_FIELDS = {
  NAME: 'name',
  TEMPLATE_DESCRIPTION: 'templateDescription',
  ITEM_DESCRIPTION: 'itemDescription',
  MANUFACTURER: 'manufacturer',
  MODEL_NUMBER: 'modelNumber',
  DOMAIN_ID: 'domainId',
  PARAMETER_TITLE: 'parameterTitle',
  NUMERIC_VALUE: 'numericValue',
  GUIDE: 'guide',
  UNIT: 'unit',
  FORMULA: 'formula'
};

export const useQueryData = ({ templateId }: { templateId: string }) => {
  const { data: dataEquipmentTemplate, loading: loadingEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: templateId || undefined
    }
  });

  return {
    dataEquipmentTemplate,
    loadingEquipmentTemplate
  };
};
