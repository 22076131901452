import { AmazonStorageApiServiceClass } from '@/logicLayers/infrastructure/services/http/amazonS3';
import { AuthServiceClass } from '@/logicLayers/infrastructure/services/http/auth';
import { HttpClient, HttpClientT } from '@/logicLayers/infrastructure/services/http/request';

interface ApiServiceClassI {
  request: HttpClientT;

  AmazonS3: InstanceType<typeof AmazonStorageApiServiceClass>;
  Auth: InstanceType<typeof AuthServiceClass>;
}

class ApiServiceClass implements ApiServiceClassI {
  request;

  AmazonS3;
  Auth;

  constructor(request: HttpClientT) {
    this.request = request;

    this.AmazonS3 = new AmazonStorageApiServiceClass(this.request);
    this.Auth = new AuthServiceClass(this.request);
  }
}

export const ApiService = new ApiServiceClass(HttpClient);
