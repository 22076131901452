import Box from '@mui/material/Box';

import { AllLocationsTab, FavoritesTab, RecentTab } from './components';

interface TabsContentProps {
  tabIndex: number;
}

export const TabsContent = ({ tabIndex }: TabsContentProps) => (
  <Box width={'100%'} minHeight={'240px'} height={'100%'} maxHeight={'240px'} sx={{ overflow: 'auto' }} py={'12px'}>
    {tabIndex === 0 ? <AllLocationsTab /> : null}
    {tabIndex === 1 ? <RecentTab /> : null}
    {tabIndex === 2 ? <FavoritesTab /> : null}
  </Box>
);
