import { FC } from 'react';

import { useContextState } from '../../../ContextProvider';

import { EquipmentItemFromTemplate, EquipmentItemNew } from './components';

import { EquipmentItemTypes } from '../../../helpers';

export const Step2: FC = () => {
  const { equipmentItemType } = useContextState();

  return equipmentItemType === EquipmentItemTypes.NEW ? <EquipmentItemNew /> : <EquipmentItemFromTemplate />;
};
