import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import { Text } from 'infinitytechnologies-ui';
import { TextField } from 'infinitytechnologies-ui';

import { Box, Fade, FormControl, InputAdornment, Menu } from '@mui/material';

import { ExpandLessIcon, ExpandMoreIcon, SearchIcon } from '@/separatedModules/components';

import { StyledButton, StyledCounter } from './styles';

import { TableDropdownProps } from '../../helpers';

export const TableDropdown: FC<TableDropdownProps> = ({
  id,
  title,
  counter,
  searchPlaceholder,
  searchValue = '',
  anchorEl,
  children,
  btnIcon,
  renderFooter = null,
  withCounter = false,
  withSearch = false,
  disabled,
  onChangeSearch,
  onOpen,
  onClose
}) => {
  const isOpen = Boolean(anchorEl);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => onChangeSearch?.(e.target.value);

  return (
    <>
      <StyledButton
        className={classNames({
          'Mui-selected': withCounter && counter
        })}
        aria-controls={isOpen ? id : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup={'true'}
        isActive={open}
        disabled={disabled}
        type="button"
        onClick={onOpen}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {withCounter && counter ? <StyledCounter>{counter}</StyledCounter> : null}

          {btnIcon ? btnIcon : null}

          <Text
            component={'span'}
            variant={'labelMd'}
            sx={{
              m: 0,
              lineHeight: '20px',
              color: disabled ? 'var(--text-disabled, rgba(9, 14, 22, 0.30))' : 'var(--text-default, #090E16)'
            }}
          >
            {title}
          </Text>

          {isOpen ? (
            <ExpandMoreIcon color={disabled ? 'disabled' : undefined} sx={{ margin: '0  0 0 4px' }} />
          ) : (
            <ExpandLessIcon color={disabled ? 'disabled' : undefined} sx={{ margin: '0  0 0 4px' }} />
          )}
        </Box>
      </StyledButton>

      <Menu
        id={id}
        anchorEl={anchorEl}
        PopoverClasses={{
          root: classNames('MuiPopover-root-filters', {
            'MuiPopover-root-filters--with-search': !renderFooter && withSearch,
            'MuiPopover-root-filters--with-footer': renderFooter && !withSearch,
            'MuiPopover-root-filters--with-both': renderFooter && withSearch
          })
        }}
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        TransitionComponent={Fade}
        open={isOpen}
        onClose={onClose}
      >
        {withSearch ? (
          <FormControl className={'MuiList-root__filter__i__search'} variant={'outlined'}>
            <TextField
              value={searchValue}
              placeholder={searchPlaceholder}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              sx={{
                '.MuiInputBase-root': {
                  minHeight: '32px',
                  height: '32px',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderRadius: '32px'
                  }
                }
              }}
              autoFocus
              onChange={handleChangeSearch}
            />
          </FormControl>
        ) : null}

        {children ? <Box className={'MuiList-root__filter__i__children'}>{children}</Box> : null}

        {renderFooter ? <Box className={'MuiList-root__filter__i__apply'}>{renderFooter()}</Box> : null}
      </Menu>
    </>
  );
};
