export const Project = {
  root: {}
};

export const Base = {
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    svg: {
      transform: 'rotate(0deg)'
    },
    '&.Mui-disabled': {},
    '&.Mui-expanded': {
      minHeight: 'auto',
      svg: {}
    },
    '.MuiAccordionSummary-content': {
      '&.Mui-expanded': {
        margin: '12px 0'
      }
    }
  }
};
