import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';

interface InputDynamicWidthProps {
  onChange: (event: string) => void;
  value?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  forwardedRef?: React.Ref<HTMLInputElement>;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const InputDynamicWidth = ({
  onChange,
  value,
  onClick,
  onFocus,
  onKeyDown,
  forwardedRef,
  onBlur
}: InputDynamicWidthProps) => {
  const [content, setContent] = useState(value);
  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.,]/g, '');
    const regex = /[,.]{2,}/g;
    const found = value.match(regex);
    const valueUpdated = found ? value.replace(regex, '.') : value;

    setContent(valueUpdated);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (!span?.current) return;

    setWidth(span.current.offsetWidth as any);
  }, [content]);

  return (
    <div>
      <span
        ref={span}
        style={{
          visibility: 'hidden',
          position: 'fixed',
          zIndex: '-9999',
          left: ' -9999px',
          padding: '4px 2px',
          minWidth: '12px !important',
          fontSize: '16px',
          lineHeight: '1'
        }}
      >
        {content}
      </span>

      <input
        type={'text'}
        value={content}
        style={{ background: '#fff', minWidth: '12px !important', width }}
        autoFocus
        onChange={changeHandler}
        onFocus={onFocus}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={forwardedRef}
        onBlur={onBlur}
      />
    </div>
  );
};
