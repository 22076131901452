import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { SEARCH_UNITS, SearchUnitsQuery } from '@/logicLayers/domain';

import { AutocompleteControl, RenderWhen, TextFieldControl } from '@/separatedModules/components';

import { PARAMETER_VALUE_REG_EXP, ROLE_DESCRIPTION_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { Formula } from '../Formula';

import {
  PARAMETER_DESCRIPTION,
  PARAMETER_TITLE,
  PARAMETER_UNIT_ID,
  PARAMETER_VALUE,
  ParameterTypeTyped
} from '../../../../../helpers';
import { MAX_GUIDE_LENGTH, MIN_GUIDE_LENGTH, UnitOption } from '../helpers';

interface NumericStepsProps {
  numericStep: string;
}

export const NumericSteps: FC<NumericStepsProps> = ({ numericStep }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const { data: unitsData } = useQuery<SearchUnitsQuery>(SEARCH_UNITS, {
    variables: {
      pageable: {
        pageSize: 1000
      }
    }
  });

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const unitOptions = useMemo<UnitOption[]>(() => {
    if (!unitsData?.dataItems?.items) {
      return [];
    }

    return unitsData?.dataItems?.items?.map((units) => ({
      label: units?.name ?? '',
      value: units?.id ?? ''
    }));
  }, [unitsData?.dataItems?.items]);

  return (
    <Grid justifyContent={'left'} container row mb={'77px'}>
      <Grid xs={12} sm={10} md={8} lg={10} item>
        <Controller
          name={PARAMETER_TITLE}
          defaultValue={''}
          control={control}
          rules={{
            required: {
              value: true,
              message: tGlobal('validation.required')
            },
            pattern: {
              value: ROLE_DESCRIPTION_REG_EXP,
              message: tGlobal('validation.descriptionLength', {
                value: 100
              })
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextFieldControl
                title={tTemplates('createParameterItem.parameter.title.title')}
                subTitle={tTemplates('createParameterItem.parameter.titleNumber.subTitle')}
                label={tTemplates('createParameterItem.parameter.title.titleLabel')}
                placeholder={tTemplates('createParameterItem.parameter.title.titlePlaceholder')}
                name={PARAMETER_TITLE}
                value={value}
                validation={{
                  isValid: !errors[PARAMETER_TITLE]?.message,
                  error: errors[PARAMETER_TITLE]?.message as string
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />

        <Controller
          name={PARAMETER_UNIT_ID}
          defaultValue={''}
          control={control}
          rules={{
            validate: (field) => {
              if (!field?.value) {
                return tGlobal('validation.required');
              }

              return true;
            }
          }}
          render={({ field: { onChange, onBlur } }) => {
            return (
              <AutocompleteControl
                title={tTemplates('createParameterItem.parameter.unit.title')}
                subTitle={tTemplates('createParameterItem.parameter.unit.subTitle')}
                label={tTemplates('createParameterItem.parameter.unit.titleLabel')}
                name={PARAMETER_UNIT_ID}
                options={unitOptions}
                validation={{
                  isValid: !errors[PARAMETER_UNIT_ID]?.message,
                  error: errors[PARAMETER_UNIT_ID]?.message as string
                }}
                getOptionKey={(option: any) => option.value as string}
                onChange={(e, value) => {
                  onChange(value);
                }}
                onBlur={onBlur}
              />
            );
          }}
        />

        <RenderWhen condition={numericStep === ParameterTypeTyped.NUMERIC_INPUT}>
          <Controller
            name={PARAMETER_DESCRIPTION}
            defaultValue={''}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: /^[\w\s`'.\-,#№()_]{3,400}$/,
                message: tGlobal('validation.textFieldMinMaxLength', {
                  valueMin: MIN_GUIDE_LENGTH,
                  valueMax: MAX_GUIDE_LENGTH
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  title={tTemplates('createParameterItem.parameter.description.title')}
                  subTitle={tTemplates('createParameterItem.parameter.description.subTitle')}
                  label={tTemplates('createParameterItem.parameter.description.titleLabel')}
                  placeholder={tTemplates('createParameterItem.parameter.description.titlePlaceholder')}
                  name={PARAMETER_DESCRIPTION}
                  rows={9}
                  maxContentLength={400}
                  value={value}
                  validation={{
                    isValid: !errors[PARAMETER_DESCRIPTION]?.message,
                    error: errors[PARAMETER_DESCRIPTION]?.message as string
                  }}
                  multiline
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </RenderWhen>

        <RenderWhen condition={numericStep === ParameterTypeTyped.NUMERIC_FIXED}>
          <Controller
            name={PARAMETER_VALUE}
            defaultValue={''}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: PARAMETER_VALUE_REG_EXP,
                message: tGlobal('validation.numberWithPoint', {
                  value: 2
                })
              },
              minLength: {
                value: 1,
                message: tGlobal('validation.numberLength', {
                  valueMin: 1,
                  valueMax: 10
                })
              },
              maxLength: {
                value: 10,
                message: tGlobal('validation.numberLength', {
                  valueMin: 1,
                  valueMax: 10
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  title={tTemplates('createParameterItem.parameter.numericValue.title')}
                  subTitle={`
                  <ul>
                    <li>Please enter a numeric value for using in formulas creation</li>
                    <li>Note that this parameter won’t be directly visible during equipment rounds</li>
                    <li>The field supports numbers with a dot as the decimal separator. You can enter up to 2 characters after the decimal point</li>
                  </ul>`}
                  label={tTemplates('createParameterItem.parameter.numericValue.titleLabel')}
                  placeholder={tTemplates('createParameterItem.parameter.numericValue.titlePlaceholder')}
                  name={PARAMETER_VALUE}
                  value={value}
                  validation={{
                    isValid: !errors[PARAMETER_VALUE]?.message,
                    error: errors[PARAMETER_VALUE]?.message as string
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </RenderWhen>

        <RenderWhen condition={numericStep === ParameterTypeTyped.NUMERIC_FORMULA}>
          <Formula />
        </RenderWhen>
      </Grid>
    </Grid>
  );
};
