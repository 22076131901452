import { FC } from 'react';

import { Step1 } from './Step1';
import { Step2 } from './Step2';

interface StepsProps {
  step: string;
}

const Component: { [step: string]: FC } = {
  step1: Step1,
  step2: Step2
};

export const Steps = ({ step }: StepsProps) => {
  const SelectedComponent = Component[step];

  return <>{SelectedComponent ? <SelectedComponent /> : null}</>;
};
