import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Button, Text } from 'infinitytechnologies-ui';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { GET_ROLE_TEMPLATES, GetRoleTemplatesQuery, SEARCH_DOMAINS, SearchDomainsQuery } from '@/logicLayers/domain';
import { ReduxState } from '@/logicLayers/infrastructure/redux';

import {
  AutocompleteOptionT,
  Flex,
  InfoIcon,
  MuiTablePagination,
  NotFoundScreens,
  PersonAddIcon,
  SkeletonTableLoader,
  TableAside,
  TableDropdownCheckbox,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useContextState } from '../../../../../ContextProvider';
import { UsersAsideContent } from '../UserAsideContent';

import { FIELD_TASK_SERVICE_STUFF, TaskTypes } from '../../../../../helpers';
import { columns, initState, skeletonTableLoaderCells, useTableData } from './helpers';

export const TaskCustomStep5 = () => {
  const { t: tTasks } = useTranslation('tasks');
  const { renderCount, renderItems, ...tableState } = useTableData(initState);
  const navigateTo = useNavigate();

  const profile = useSelector((state: ReduxState) => state.user.profile);

  const { taskType } = useContextState();

  const isUnplannedTask = taskType === TaskTypes.UNPLANNED;

  const [isSelferformance, setIsSelferformance] = useState(false);

  const { control, watch, setValue } = useFormContext();

  useEffect(() => {
    if (isSelferformance) {
      setValue(FIELD_TASK_SERVICE_STUFF, [profile?.id]);
    } else {
      setValue(FIELD_TASK_SERVICE_STUFF, []);
    }
  }, [isSelferformance]);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const { data: dataRoleTemplates } = useQuery<GetRoleTemplatesQuery>(GET_ROLE_TEMPLATES, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const rolesOptions = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return dataRoleTemplates?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.name as string,
      key: domain?.id as string,
      checked: false,
      disabled: false
    }));
  }, [dataRoleTemplates]);

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string,
      checked: false,
      disabled: false
    }));
  }, [domainsData]);

  const selectedServiceStuff = watch(FIELD_TASK_SERVICE_STUFF);

  const handleChangeSelfPerformance = () => {
    setIsSelferformance(!isSelferformance);
  };

  return (
    <Controller
      name={FIELD_TASK_SERVICE_STUFF}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <Stack minHeight={'70vh'}>
            <Grid justifyContent={'center'} container row sx={{ marginTop: '48px' }}>
              <Grid xs={12} item>
                <TablePageTopBox title={tTasks('createTask.step5.serviceStuff.title')} isStep>
                  <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                    {!isUnplannedTask && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Switch checked={isSelferformance} onChange={handleChangeSelfPerformance} size="small" />
                        <Text m={0} sx={{ fontWeight: 500 }} variant="bodySm">
                          {tTasks('createTask.step5.serviceStuff.selfPerformance')}
                        </Text>
                      </Box>
                    )}

                    <Button
                      size={'small'}
                      variant="outlined"
                      startIcon={<PersonAddIcon />}
                      onClick={() => {
                        navigateTo(LINKS_PAGES.users);
                      }}
                    >
                      {tTasks('createTask.step5.serviceStuff.inviteBtn')}
                    </Button>
                  </Box>
                </TablePageTopBox>
                <Text
                  sx={{
                    maxWidth: '664px',
                    fontSize: '14px',
                    color: 'var(--text-subtle, #505668)',
                    fontWeight: '400',
                    marginBottom: '24px'
                  }}
                >
                  {tTasks('createTask.step5.serviceStuff.subTitle')}
                </Text>
                {!isSelferformance && (
                  <>
                    {Boolean(!selectedServiceStuff.length) && (
                      <TableFiltersBox
                        searchPlaceholder={tTasks('createTask.step5.serviceStuff.table.searchPlaceholder')}
                        selectedItems={selectedServiceStuff}
                        handleToggleAside={tableState.handleToggleAside}
                        handleChangeBySearch={tableState.handleChangeBySearch}
                      >
                        <TableDropdownCheckbox
                          // ToDo Refactor
                          title={tTasks('createTask.step5.serviceStuff.table.domainsTitle')}
                          menuList={domainsOptions as any}
                          withSelectAll
                          withSearch
                          searchPlaceholder={tTasks('createTask.step5.serviceStuff.table.domainsSearchPlaceholder')}
                          selectAllTitle={tTasks('createTask.step5.serviceStuff.table.showAll')}
                          onChange={(value) => {
                            tableState.handleChangeByDomains(value);
                          }}
                        />

                        <TableDropdownCheckbox
                          // ToDo Refactor
                          title={tTasks('createTask.step5.serviceStuff.table.roleTitle')}
                          menuList={rolesOptions as any}
                          withSelectAll
                          withSearch
                          searchPlaceholder={tTasks('createTask.step5.serviceStuff.table.roleSearchPlaceholder')}
                          selectAllTitle={tTasks('createTask.step5.serviceStuff.table.showAll')}
                          onChange={(value) => {
                            tableState.handleChangeByRoles(value);
                          }}
                        />
                      </TableFiltersBox>
                    )}
                    {tableState.loading ? (
                      <SkeletonTableLoader cells={skeletonTableLoaderCells} />
                    ) : renderCount || tableState.state.filters?.search.length ? (
                      renderItems ? (
                        <Flex>
                          <Box width={'100%'}>
                            <Box sx={{ overflow: 'auto', maxHeight: '400px' }}>
                              <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                                {Boolean(selectedServiceStuff.length) && (
                                  <Flex
                                    sx={{
                                      width: '100%',
                                      height: '32px',
                                      background: 'var(--background-neutral-pressed, #F1F2F4)',
                                      borderRadius: '4px',
                                      padding: '0px 4px',
                                      marginBottom: '16px'
                                    }}
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Flex alignItems="center">
                                      <IconButton
                                        size={'medium'}
                                        className={'aside-table__btn__close'}
                                        onClick={() => {
                                          onChange([]);
                                          setValue(FIELD_TASK_SERVICE_STUFF, []);
                                          tableState.handleClearSelectedItems();
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                      <Text sx={{ fontSize: '14px', marginLeft: '16px' }}>
                                        {selectedServiceStuff.length} selected
                                      </Text>
                                    </Flex>
                                    <IconButton
                                      size={'medium'}
                                      sx={{ borderRadius: '100%' }}
                                      onClick={tableState.handleToggleAside}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </Flex>
                                )}
                                <Table className={'m-0 MuiTable-root__columns-3'}>
                                  <TableHead>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                      <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                          <TableCell key={header.id}>
                                            {header.isPlaceholder
                                              ? null
                                              : flexRender(header.column.columnDef.header, header.getContext())}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    ))}
                                  </TableHead>
                                  <TableBody>
                                    {table.getRowModel().rows.map((row) => {
                                      return (
                                        <TableRowWrap
                                          key={row.original.id}
                                          id={row.original.id as string}
                                          isSelected={selectedServiceStuff.some(
                                            (item: any) => item === row.original.id
                                          )}
                                          handleClickRow={(rowId) => {
                                            const inSelectedExist = selectedServiceStuff.includes(rowId);

                                            const updatedSelectedItems = inSelectedExist
                                              ? selectedServiceStuff.filter(
                                                  (selectedRowId: string) => selectedRowId !== rowId
                                                )
                                              : [rowId, ...selectedServiceStuff];

                                            if (!isUnplannedTask) {
                                              return onChange([rowId]);
                                            }

                                            onChange(updatedSelectedItems);
                                          }}
                                          handleDoubleClickRow={tableState.handleDoubleClickRow}
                                        >
                                          {row.getVisibleCells().map((cell) => {
                                            return (
                                              <TableCell key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRowWrap>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                            <MuiTablePagination
                              count={renderCount}
                              page={tableState.state.page[0]}
                              rowsPerPage={tableState.state.rowsPerPage[0]}
                              rowsPerPageOptions={[20, 50, 100]}
                              onChangePage={tableState.handleChangePagination}
                              onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                            />
                          </Box>
                          <TableAside
                            rows={table.getRowModel().rows}
                            selectedItems={tableState.state.selectedItems}
                            isAsideOpen={tableState.state.isAsideOpen}
                            handleToggleAside={tableState.handleToggleAside}
                            sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                          >
                            {(props: any) => {
                              return (
                                <UsersAsideContent
                                  {...props}
                                  handleClickDetails={() => {
                                    navigateTo(`/user/${tableState.state.selectedItems[0]}/visibility`);
                                  }}
                                />
                              );
                            }}
                          </TableAside>
                        </Flex>
                      ) : (
                        <Box sx={{ height: '59vh' }}>
                          <NotFoundScreens type={'search'} />
                        </Box>
                      )
                    ) : (
                      <NotFoundScreens
                        type={'companies'}
                        title={tTasks('createTask.step5.serviceStuff.table.emptyTitle')}
                        subTitle={tTasks('createTask.step5.serviceStuff.table.emptySubTitle')}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Stack>
        );
      }}
    />
  );
};
