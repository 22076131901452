import { API_GRAPHQL_URL } from '@/config';

import { HttpClient } from './../request';

/*
 * Example Names of Methods
 * Get request - getUserCoins
 * Post request - createUserCoins
 * Update/Put request - updateUserCoins
 * Delete request - deleteUserCoins
 */
export class AuthServiceClass {
  request: typeof HttpClient;

  constructor(request: typeof HttpClient) {
    this.request = request;
  }

  async getAuthClientSecret(email: string) {
    return await fetch(API_GRAPHQL_URL as string, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `query AvailableCompany($email: String!) {
          availableCompany(email: $email) {
            clientId
            id
            name
            secret
          }
        }`,
        variables: {
          email
        }
      })
    })
      .then((result) => result.json())
      .then((result) => {
        return { isSuccess: true, data: result.data };
      })
      .catch((error) => {
        // ToDo Remove
        console.log('AuthService getAuthClientSecret error - ', error);

        return { isSuccess: false, data: undefined };
      });
  }
}
