import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PhotoCameraIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'36'} height={'36'} viewBox={'0 0 36 36'} fill={'none'} sx={{ fill: '#090E16' }} {...props}>
      <path
        d="M18 25.673C19.7211 25.673 21.1802 25.0744 22.3773 23.8773C23.5744 22.6802 24.173 21.2211 24.173 19.5C24.173 17.7788 23.5744 16.3197 22.3773 15.1226C21.1802 13.9255 19.7211 13.3269 18 13.3269C16.2788 13.3269 14.8197 13.9255 13.6226 15.1226C12.4255 16.3197 11.8269 17.7788 11.8269 19.5C11.8269 21.2211 12.4255 22.6802 13.6226 23.8773C14.8197 25.0744 16.2788 25.673 18 25.673ZM18 23.4231C16.8923 23.4231 15.9615 23.0461 15.2076 22.2923C14.4538 21.5384 14.0769 20.6077 14.0769 19.5C14.0769 18.3923 14.4538 17.4615 15.2076 16.7076C15.9615 15.9538 16.8923 15.5769 18 15.5769C19.1077 15.5769 20.0384 15.9538 20.7923 16.7076C21.5461 17.4615 21.9231 18.3923 21.9231 19.5C21.9231 20.6077 21.5461 21.5384 20.7923 22.2923C20.0384 23.0461 19.1077 23.4231 18 23.4231ZM6.46155 30.7499C5.70385 30.7499 5.0625 30.4874 4.5375 29.9624C4.0125 29.4374 3.75 28.7961 3.75 28.0384V10.9616C3.75 10.2039 4.0125 9.5625 4.5375 9.0375C5.0625 8.5125 5.70385 8.25 6.46155 8.25H11.0423L13.0038 6.12405C13.25 5.8529 13.5476 5.63944 13.8966 5.48366C14.2457 5.32789 14.6134 5.25 15 5.25H21C21.3865 5.25 21.7543 5.32789 22.1033 5.48366C22.4523 5.63944 22.7499 5.8529 22.9961 6.12405L24.9576 8.25H29.5384C30.2961 8.25 30.9374 8.5125 31.4624 9.0375C31.9874 9.5625 32.2499 10.2039 32.2499 10.9616V28.0384C32.2499 28.7961 31.9874 29.4374 31.4624 29.9624C30.9374 30.4874 30.2961 30.7499 29.5384 30.7499H6.46155Z"
        fillOpacity="0.3"
      />
    </SvgIcon>
  );
}
