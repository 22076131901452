export const Project = {
  root: {}
};

export const Base = {
  root: {
    margin: '0 0 12px',
    '.MuiFormControlLabel-label': {
      color: 'red',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '20px',
      margin: '0 0 0 8px'
    },
    '&.MuiFormControlLabel-labelPlacementBottom': {
      '.MuiFormControlLabel-label': {
        margin: '12px 0 0 0'
      }
    },
    '&.MuiFormControlLabel-labelPlacementStart': {
      '.MuiFormControlLabel-label': {
        margin: '0 12px 0 0'
      }
    },
    '&.MuiFormControlLabel-labelPlacementTop': {
      '.MuiFormControlLabel-label': {
        margin: '0 0 12px 0'
      }
    },
    '&.MuiFormControlLabel-root-MuiSwitch': {
      '.MuiFormControlLabel-label': {
        color: 'darkcyan'
      }
    },
    '&.MuiFormControlLabel-root-MuiRadio': {
      margin: '0 16px 0 -11px',
      '.MuiFormControlLabel-label': {
        color: 'blueviolet'
      }
    },
    '&.MuiFormControlLabel-root-MuiCheckbox': {
      margin: '0 0 8px 0',
      '.MuiFormControlLabel-label': {
        // color: 'darkcyan'
      }
    }
  }
};
