import { FC, useCallback, useState } from 'react';

import { AddDomainButton, AddDomainModal, EditDomainButton, EditDomainModal } from './components';

import { DomainActionTypeEnum } from './helpers';

interface CreateOrEditDomainProps {
  id?: string;
  actionType: DomainActionTypeEnum;
}

interface StateProps {
  isOpen: boolean;
  actionType?: DomainActionTypeEnum;
}

export const CreateOrEditDomain: FC<CreateOrEditDomainProps> = ({ id, actionType }) => {
  const [modalState, setModalState] = useState<StateProps>({
    isOpen: false,
    actionType: undefined
  });

  const handleOpenModal = useCallback(() => {
    setModalState({
      isOpen: true,
      actionType: actionType
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalState({ isOpen: false, actionType: undefined });
  }, []);

  return (
    <>
      {actionType === DomainActionTypeEnum.Create ? (
        <AddDomainButton onOpenModal={handleOpenModal} />
      ) : (
        <EditDomainButton id={id} onOpenModal={handleOpenModal} />
      )}

      {modalState.isOpen ? (
        <>
          {modalState.actionType === DomainActionTypeEnum.Create ? (
            <AddDomainModal isModalOpen={modalState.isOpen} onCloseModal={handleCloseModal} />
          ) : null}

          {modalState.actionType === DomainActionTypeEnum.Edit ? (
            <EditDomainModal domainId={id} isModalOpen={modalState.isOpen} onCloseModal={handleCloseModal} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
