import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3358_133619)">
          <path
            d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
            fill="#EF4444"
          />
          <path
            d="M58.0449 145.11L111.988 199.053C157.414 193.691 193.376 158.044 199.054 112.618L144.796 58.3594L58.0449 145.11Z"
            fill="#B91C1C"
          />
          <path
            d="M147.319 122.082C151.735 126.498 151.735 134.069 147.319 138.486L138.486 147.319C134.069 151.735 126.498 151.735 122.082 147.319L52.6814 77.9181C48.265 73.5017 48.265 65.9307 52.6814 61.5143L61.8297 52.366C66.2461 47.9496 73.8171 47.9496 78.2335 52.366L147.319 122.082Z"
            fill="white"
          />
          <path
            d="M110.726 85.1733L85.1738 110.725L122.082 147.318C126.499 151.735 134.07 151.735 138.486 147.318L147.634 138.17C152.051 133.754 152.051 126.183 147.634 121.766L110.726 85.1733Z"
            fill="#E5E7EB"
          />
          <path
            d="M122.082 52.6814C126.498 48.265 134.069 48.265 138.486 52.6814L147.634 61.8297C152.051 66.2461 152.051 73.8171 147.634 78.2335L77.918 147.319C73.5016 151.735 65.9307 151.735 61.5142 147.319L52.6814 138.486C48.265 134.069 48.265 126.498 52.6814 122.082L122.082 52.6814Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3358_133619">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
