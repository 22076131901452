import { memo } from 'react';
import classNames from 'classnames';
import { Text } from 'infinitytechnologies-ui';

interface ChooseFlowItemProps {
  id: string;
  title: string;
  titleSub: string;
  imageSrc: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (id: string) => void;
}

export const ChooseFlowItem = memo(
  ({ id, title, titleSub, imageSrc, selected, disabled, onClick }: ChooseFlowItemProps) => {
    const handleClick = () => {
      onClick?.(id);
    };

    return (
      <div
        className={classNames('create-company__flow__i', {
          'Mui-selected': selected,
          'Mui-disabled': disabled
        })}
        onClick={handleClick}
      >
        {imageSrc ? <img className={'create-company__flow__i__img'} src={imageSrc} alt="" /> : null}

        {title ? (
          <Text className={'create-company__flow__i__title'} variant={'titleMd'}>
            {title}
          </Text>
        ) : null}

        {titleSub ? (
          <Text className={'create-company__flow__i__titleSub'} variant={'bodyMd'}>
            {titleSub}
          </Text>
        ) : null}
      </div>
    );
  }
);
