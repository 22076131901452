import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MailIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'200'} height={'200'} viewBox={'0 0 200 200'} fill={'none'} {...props}>
      <g clipPath="url(#clip0_25_3825)">
        <path d="M37.8906 45.5073L5.85938 69.2968L39.4145 111.719L37.8906 45.5073Z" fill="#68544F" />
        <path d="M162.11 45.5073L160.586 111.719L194.141 69.2968L162.11 45.5073Z" fill="#53433F" />
        <path
          d="M164.453 5.85938V149.805C164.453 153.086 161.875 155.664 158.594 155.664H41.4062C38.1246 155.664 35.5469 153.086 35.5469 149.805V5.85938C35.5469 2.57773 38.1246 0 41.4062 0H158.594C161.875 0 164.453 2.57773 164.453 5.85938Z"
          fill="#F7F0EB"
        />
        <path
          d="M164.453 5.85938V149.805C164.453 153.086 161.875 155.664 158.594 155.664H100V0H158.594C161.875 0 164.453 2.57773 164.453 5.85938Z"
          fill="#EFE2DD"
        />
        <path
          d="M141.016 41.4062C141.016 44.6879 138.438 47.2656 135.156 47.2656H64.8438C61.5621 47.2656 58.9844 44.6879 58.9844 41.4062C58.9844 38.1246 61.5621 35.5469 64.8438 35.5469H135.156C138.438 35.5469 141.016 38.1246 141.016 41.4062Z"
          fill="#E1D3CE"
        />
        <path
          d="M141.016 64.8438C141.016 68.1254 138.438 70.7031 135.156 70.7031H64.8438C61.5621 70.7031 58.9844 68.1254 58.9844 64.8438C58.9844 61.5621 61.5621 58.9844 64.8438 58.9844H135.156C138.438 58.9844 141.016 61.5621 141.016 64.8438Z"
          fill="#E1D3CE"
        />
        <path
          d="M105.859 88.2812C105.859 91.5629 103.282 94.1406 100 94.1406H64.8438C61.5621 94.1406 58.9844 91.5629 58.9844 88.2812C58.9844 84.9996 61.5621 82.4219 64.8438 82.4219H100C103.282 82.4219 105.859 84.9996 105.859 88.2812Z"
          fill="#E1D3CE"
        />
        <path
          d="M100 94.1406V82.4219C103.282 82.4219 105.859 84.9996 105.859 88.2812C105.859 91.5629 103.282 94.1406 100 94.1406Z"
          fill="#CDBFBA"
        />
        <path
          d="M135.156 70.7031H100V58.9844H135.156C138.438 58.9844 141.016 61.5621 141.016 64.8438C141.016 68.1254 138.438 70.7031 135.156 70.7031Z"
          fill="#CDBFBA"
        />
        <path
          d="M135.156 47.2656H100V35.5469H135.156C138.438 35.5469 141.016 38.1246 141.016 41.4062C141.016 44.6879 138.438 47.2656 135.156 47.2656Z"
          fill="#CDBFBA"
        />
        <path
          d="M197.891 72.1094C197.773 72.1094 194.141 69.2969 194.141 69.2969L184.414 76.5625L103.516 136.914C102.461 137.734 101.172 138.204 100 138.204C98.8281 138.204 97.5387 137.734 96.4844 136.914L15.5863 76.5625L5.85938 69.2969C5.85938 69.2969 2.22656 72.1094 2.10938 72.1094C0.820313 73.1645 0 74.8043 0 76.5625V182.422C0 192.149 7.85117 200 17.5781 200H182.422C192.149 200 200 192.149 200 182.422V76.5625C200 74.8043 199.18 73.1645 197.891 72.1094Z"
          fill="#FCA5A5"
        />
        <path
          d="M200 76.5625V182.422C200 192.149 192.149 200 182.422 200H100V138.203C101.171 138.203 102.461 137.734 103.516 136.914L184.414 76.5625L194.141 69.2969C194.141 69.2969 197.773 72.1094 197.891 72.1094C199.18 73.1645 200 74.8043 200 76.5625Z"
          fill="#F87171"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_3825">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
