import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Button, Text } from 'infinitytechnologies-ui';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';

import { GET_EQUIPMENT_ROUND_REGULATION, RoundRegulationQuery, TaskStatus } from '@/logicLayers/domain';

import { getBreadcrumbs } from '@/separatedModules/pages/Locations/components/PageTopBox/helpers';
import { ParameterItem } from '@/separatedModules/pages/Templates/pages/Equipment/pages/RoundRegulations/pages/Details/components';
import { ActionMenu } from '@/separatedModules/pages/Templates/pages/Equipment/pages/RoundRegulations/pages/Details/components/ActionMenu';

import { Breadcrumbs, ChevronRightIcon, EMPTY_CELL_VALUE, Flex, PreviewIcon } from '@/separatedModules/components';
import { EditIcon, EditItemHover, SystemStatusChip, SystemStatusesEnum } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

export const TaskDetails = () => {
  const { taskId = '' } = useParams();
  const { t: tTemplates } = useTranslation('templates');
  const navigateTo = useNavigate();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { data: dataEquipmentTemplateRoundRegulation, loading } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId: taskId || undefined,
        searchCriteria: {}
      }
    }
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImageUrl, setDialogImageUrl] = useState('');

  const handleOpenDialog = (url: string) => {
    setDialogImageUrl(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogImageUrl('');
  };

  const renderItemName = dataEquipmentTemplateRoundRegulation?.dataItems?.name;
  const renderItemActive = Boolean(dataEquipmentTemplateRoundRegulation?.dataItems?.active);
  const renderItemTemplateDescription = dataEquipmentTemplateRoundRegulation?.dataItems?.description;
  const renderItemTemplateStatus = dataEquipmentTemplateRoundRegulation?.dataItems.status;
  const renderItemCreatedDate = dataEquipmentTemplateRoundRegulation?.dataItems?.createdDate;
  const renderItemEquipment = dataEquipmentTemplateRoundRegulation?.dataItems.equipment;
  const renderItemServiceStuff = dataEquipmentTemplateRoundRegulation?.dataItems?.serviceStaff;

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.tasks, titleFallback: tTemplates('equipmentItem.roundRegulationsDetails.titleFallbackTask') },
    { titleFallback: renderItemName }
  ];

  if (loading) return null;

  const handleEditItem = (toPage: string) => {
    const templateId = renderItemEquipment?.[0].id as string;

    const pathname = LINKS_PAGES.templatesEquipmentEditItemRoundsTask
      .replace(':templateId', templateId)
      .replace(':roundRegulationId', taskId);

    navigateTo({
      pathname,
      search: `field=${toPage}`
    });
  };

  const handleEditTitle = () => handleEditItem('roundTitle');
  const handleEditDescription = () => handleEditItem('roundDescription');
  const handleEditStaff = () => handleEditItem('roundStaff');
  const handleEditParameters = () => handleEditItem('roundParameters');

  const equipmentBreadcrumbs = getBreadcrumbs(renderItemEquipment?.[0].location);

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} sm={10} md={10} item>
        <Box>
          <Breadcrumbs
            links={breadcrumbsLinks}
            separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
            sx={{ paddingTop: 0 }}
          />
          <Text component={'h2'} variant={'titleXl'}>
            {renderItemName}
          </Text>
        </Box>

        <Box
          sx={{
            flex: '0 1 100%',
            marginTop: '32px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderItemName ? (
                <EditItemHover onClick={handleEditTitle} isDisabled={!isUserCanCRUD}>
                  <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
                    {renderItemName}
                  </Text>
                </EditItemHover>
              ) : null}

              <SystemStatusChip status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled} />
            </Box>
            {isUserCanCRUD && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <Button variant="outlined" startIcon={<PreviewIcon />}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.roundFormBtn')}
                </Button>
                <ActionMenu
                  data={dataEquipmentTemplateRoundRegulation}
                  isRoundTask
                  renderModalContent={() => {
                    return (
                      <Box
                        sx={{
                          borderRadius: 'var(--4, 4px)',
                          background: 'var(--background-neutral-hovered, #F7F8F9)',
                          margin: '24px 0px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '8px'
                        }}
                      >
                        <Text variant={'labelLg'} m={0}>
                          {renderItemName}
                        </Text>
                      </Box>
                    );
                  }}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', marginTop: '8px' }}>
            <EditItemHover onClick={handleEditDescription} isDisabled={!isUserCanCRUD}>
              <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                {renderItemTemplateDescription?.length ? renderItemTemplateDescription : EMPTY_CELL_VALUE}
              </Text>
            </EditItemHover>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.status')}
            </Text>
            <Text className={'m-0'} variant={'bodyLg'}>
              <TaskStatusChip status={renderItemTemplateStatus as keyof typeof TaskStatus} />
            </Text>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.dateAndTime')}
            </Text>
            <Text className={'m-0'} variant={'bodyLg'}>
              {dayjs(renderItemCreatedDate).format('DD MMM YYYY, HH:mm A')}
            </Text>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.serviceStuff')}
            </Text>
            <EditItemHover onClick={handleEditStaff} isDisabled={!isUserCanCRUD}>
              <Box>
                {renderItemServiceStuff?.map((item, index) => {
                  return (
                    <Box key={index} sx={{ display: 'flex', marginBottom: '8px' }}>
                      {item?.userAvatar ? (
                        <img
                          src={item.userAvatar?.url}
                          alt=""
                          width={24}
                          height={24}
                          style={{
                            borderRadius: '50%',
                            border: '1px solid #F7F8F9',
                            position: 'relative',
                            margin: '0 8px 0 0'
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            flex: '0 0 24px',
                            borderRadius: '50%',
                            margin: '0 8px 0 0',
                            background: 'rgba(9, 14, 22, 0.06)',
                            border: '1px solid var(--border-inverse, #F7F8F9)'
                          }}
                        />
                      )}
                      <Text>
                        {!item?.firstName && !item?.lastName
                          ? EMPTY_CELL_VALUE
                          : `${item?.firstName} ${item?.lastName}`}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </EditItemHover>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.equipment')}
            </Text>
            <Flex alignItems="center">
              {renderItemEquipment?.[0].fileUrl ? (
                <img
                  src={renderItemEquipment[0].fileUrl.url}
                  width={32}
                  height={32}
                  alt={''}
                  onClick={() => handleOpenDialog(renderItemEquipment[0].fileUrl.url)}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <Box
                  sx={{
                    width: '32px',
                    height: '32px',
                    flex: '0 0 32px',
                    margin: '0',
                    background: 'rgba(9, 14, 22, 0.06)',
                    border: '1px solid var(--border-inverse, #F7F8F9)'
                  }}
                />
              )}

              <Box sx={{ margin: '0 0 0 8px' }}>
                {renderItemEquipment?.[0].name ? (
                  <Text
                    variant={'inherit'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-all',
                      fontSize: '16px',
                      marginBottom: '4px'
                    }}
                  >
                    {renderItemEquipment[0].name}
                  </Text>
                ) : (
                  EMPTY_CELL_VALUE
                )}

                <Text className={'m-0'} variant={'bodyLg'}>
                  {breadcrumbsLinks.length > 0 ? (
                    <Breadcrumbs
                      links={equipmentBreadcrumbs}
                      separator={<ChevronRightIcon sx={{ height: '10px', width: '16px' }} />}
                      sx={{ padding: 0, width: '160px' }}
                      fontSize="14px"
                      separatorStyle={{ padding: '0px', width: '16px' }}
                    />
                  ) : (
                    EMPTY_CELL_VALUE
                  )}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '48px',
              marginBottom: '24px',
              alignItems: 'center'
            }}
          >
            <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.parameters')}
            </Text>
            {isUserCanCRUD && (
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button size={'medium'} onClick={handleEditParameters} startIcon={<EditIcon sx={{ fontSize: 20 }} />}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.editBtn')}
                </Button>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {dataEquipmentTemplateRoundRegulation?.dataItems?.parameters.map((item) => {
              return <ParameterItem item={item} key={item.id} />;
            })}
          </Box>
        </Box>
      </Grid>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle sx={{ position: 'relative' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            sx={{ position: 'absolute', right: '15px', top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={dialogImageUrl} alt="Enlarged view" style={{ width: '950px', height: '632px' }} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
