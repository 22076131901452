import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { PermissionsSelectButton } from '@/separatedModules/pages/Templates/pages/Role/pages/CreateRole/components/PermissionsSelectButton';

import { createMockedMenuList } from '../helpers';

interface UserItemProps {
  name: string;
  id: string | undefined;
  userAvatar?: string | undefined;
  accessType: string | undefined;
  onMenuItemClick: (item: any) => void;
}

export const UserItem = ({ id, name, userAvatar, accessType, onMenuItemClick }: UserItemProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {userAvatar ? (
          <img
            width={24}
            height={24}
            src={userAvatar}
            style={{
              borderRadius: '16px',
              border: '1px solid #F7F8F9',
              position: 'relative',
              marginRight: '8px'
            }}
          />
        ) : (
          <Box
            sx={{
              width: '24px',
              height: '24px',
              flex: '0 0 24px',
              borderRadius: '50%',
              background: 'rgb(240, 240, 241)',
              border: '1px solid #F7F8F9',
              marginRight: '8px'
            }}
          />
        )}
        <Text m={0}>{name}</Text>
      </Box>
      <Box>
        <PermissionsSelectButton
          variant="base"
          menuList={createMockedMenuList(accessType ?? '', id ?? '')}
          onMenuItemClick={onMenuItemClick}
        />
      </Box>
    </Box>
  );
};
