export const Project = {
  root: {
    display: 'flex',
    padding: '4px',
    borderRadius: 'var(--4, 4px)',
    fontSize: '0',
    '.MuiTouchRipple-root': {
      '*': {
        borderRadius: 'var(--4, 4px)'
      }
    }
  },
  sizeSmall: {
    width: '24px',
    height: '24px',
    '.MuiSvgIcon-root': {
      fontSize: '18px'
    }
  },
  sizeMedium: {
    '.MuiSvgIcon-root': {
      fontSize: '24px'
    }
  },
  sizeLarge: {
    '.MuiSvgIcon-root': {
      fontSize: '24px'
    }
  },
  '&.Mui-disabled': {}
};

export const Base = {
  root: {}
};
