import { Text } from 'infinitytechnologies-ui';

interface TextBlockStepProps {
  title?: string;
  titleSubText?: string;
  titleSubLink?: string;
  link?: string;
}

export function TextBlockStep({ title, titleSubText, titleSubLink, link }: TextBlockStepProps) {
  return (
    <>
      {title ? (
        <Text className={'sign-in__step__title'} component={'h3'}>
          {title}
        </Text>
      ) : null}

      {titleSubText || titleSubLink ? (
        <div className={'sign-in__step__text'}>
          {titleSubText ? <Text>{titleSubText}</Text> : null}

          {titleSubLink ? <a href={link}>{titleSubLink}</a> : null}
        </div>
      ) : null}
    </>
  );
}
