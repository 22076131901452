import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Stack from '@mui/material/Stack';

import { GET_USER_DOMAINS_BY_ID, GetUserDomainsByIdQuery } from '@/logicLayers/domain';

import {
  BlockInfoWithEdit,
  DomainStatusChip,
  PageTitle,
  Preloader,
  PreloaderVariantsE,
  RenderWhen
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface DomainsBlockProps {}

export const DomainsBlock: FC<DomainsBlockProps> = () => {
  const { t: tUser } = useTranslation('user');

  const { userId = '' } = useParams();

  const { data: dataUserDomains, loading: loadingUserDomains } = useQuery<GetUserDomainsByIdQuery>(
    GET_USER_DOMAINS_BY_ID,
    {
      variables: { uid: userId },
      skip: !userId
    }
  );

  const domains = dataUserDomains?.user?.domains;
  const subTitle = tUser(
    `permissions.mainInfo.${domains?.length ? 'equipmentDomainsDescription' : 'equipmentDomainsEmptyDescription'}`
  );

  return loadingUserDomains ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <BlockInfoWithEdit>
      <PageTitle
        title={tUser('permissions.mainInfo.equipmentDomainsTitle')}
        subTitle={subTitle}
        sxTitle={{ fontSize: '16px', mb: '16px' }}
        sxSubTitle={{ fontSize: '14px', mb: '24px', color: '#505668', whiteSpace: 'pre-line' }}
      />
      <RenderWhen condition={!!domains?.length}>
        <Stack direction={'row'} spacing={'8px'}>
          {domains?.map((domain) => <DomainStatusChip key={domain?.id} color={domain?.color} label={domain?.name} />)}
        </Stack>
      </RenderWhen>
    </BlockInfoWithEdit>
  );
};
