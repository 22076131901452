import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import { MENU_ITEM_DIVIDER_STYLES, MenuItemDividerState, MenuItemDividerVariant } from '../../dropdown.config';

interface StyledMenuItemDividerProps {
  hasSpace: MenuItemDividerVariant;
  state: MenuItemDividerState;
}

export const StyledMenuItemDivider = styled(Divider, {
  shouldForwardProp: (prop: string) => prop !== 'hasSpace'
})(({ hasSpace, state }: StyledMenuItemDividerProps) => ({
  ...MENU_ITEM_DIVIDER_STYLES[hasSpace][state]
}));
