import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';

import {
  GET_USER_DETAILED_PERMISSIONS,
  GET_USER_PERMISSIONS_BY_ID,
  GetUserPermissionsQuery,
  PermissionRes,
  UserStatus
} from '@/logicLayers/domain';

import { i18n } from '@/i18n';

export enum USER_ITEM_FIELDS {
  LOGO = 'logo',
  NAME = 'name',
  PHONE_NUMBER = 'phone'
}

export const getUserStatusColor = (status: string | undefined) => {
  switch (true) {
    case status === UserStatus.Active:
      return 'success';
    case status === UserStatus.Inactive:
      return 'error';
    case status === UserStatus.Created:
      return 'secondary';
    default:
      return 'secondary';
  }
};

export interface UserPermissions {
  name: string;
  operation: string;
  resource: string;
}

export const canActivateUser = (status: string | undefined): boolean => {
  switch (true) {
    case status === UserStatus.Active:
      return false;
    case status === UserStatus.Inactive:
      return true;
    case status === UserStatus.Created:
      return false;
    default:
      return false;
  }
};

export const canDeleteUser = (status: string | undefined): boolean => {
  switch (true) {
    case status === UserStatus.Active:
      return false;
    case status === UserStatus.Inactive:
      return true;
    case status === UserStatus.Created:
      return false;
    default:
      return false;
  }
};

export enum PermissionEnum {
  VIEWING = 'VIEWING',
  UPDATING = 'UPDATING',
  DELETING = 'DELETING'
}

export interface PermissionsGroup {
  label: string;
  value: string;
  resource: string;
  operation: string;
}

export type GroupOfPermissions = { [groupName: string]: PermissionRes[] };

export const createMockedMenuList = (
  groupName: string,
  group: PermissionsGroup[],
  initialPermissionsGroup: GroupOfPermissions
) => {
  let canEditByDefault = false;
  let canViewByDefault = false;

  Object.entries(initialPermissionsGroup).forEach(([initGroupName, value]) => {
    if (initGroupName === groupName) {
      canEditByDefault = value.some((item) => item.operation === PermissionEnum.UPDATING);
      canViewByDefault = value.some((item) => item.operation === PermissionEnum.VIEWING);
    }
  });

  const onlyViewingItem = group
    .filter((filterItem) => filterItem.operation === PermissionEnum.VIEWING)
    ?.map((editingItem) => editingItem.value);
  const editingItems = group.map((editingItem) => editingItem.value);

  return [
    {
      id: 0,
      title: i18n.t('createRole.mainInfo.permissionsMenu.canView', { ns: 'templates' }),
      groupName,
      isDefaultValue: canViewByDefault && !canEditByDefault,
      value: onlyViewingItem
    },
    {
      id: 1,
      title: i18n.t('createRole.mainInfo.permissionsMenu.canEdit', { ns: 'templates' }),
      groupName,
      isDefaultValue: canEditByDefault,
      value: editingItems
    },
    {
      id: 2,
      title: i18n.t('createRole.mainInfo.permissionsMenu.restrict', { ns: 'templates' }),
      groupName,
      isDefaultValue: !canEditByDefault && !canViewByDefault,
      value: []
    }
  ];
};

type Group = Record<string, string[]>;

interface UseGroupPermissionsProps {
  userId: string | null;
}

export const useConstructUserGroupPermissions = ({ userId }: UseGroupPermissionsProps) => {
  const { data: dataUser, loading: loadingUser } = useQuery<GetUserPermissionsQuery>(GET_USER_PERMISSIONS_BY_ID, {
    variables: { uid: userId },
    skip: !userId?.length
  });

  const { data: detailedPermissionsData, loading: detailedPermissionsLoading } = useQuery(
    GET_USER_DETAILED_PERMISSIONS,
    { fetchPolicy: 'no-cache' }
  );

  const userPermissions = dataUser?.user?.permissions;
  const detailedPermissions = detailedPermissionsData?.permissions;

  const groupedPermissions = useMemo(() => {
    return groupBy(detailedPermissions, 'resource');
  }, [detailedPermissions, detailedPermissionsLoading]);

  const groupedInitialPermissions = useMemo(() => {
    if (userPermissions?.length) {
      return groupBy(userPermissions as unknown as UserPermissions[], 'resource');
    }

    return [];
  }, [userPermissions]);

  const matchedPermissionsList = useMemo<Group>(() => {
    const matchedList = Object.entries(groupedPermissions).map(([key, value]) => {
      return createMockedMenuList(key, value, groupedInitialPermissions as GroupOfPermissions);
    });

    const group: Group = {};

    if (matchedList.length) {
      matchedList.forEach((groupArray) => {
        groupArray.forEach((item) => {
          if (!group[item.title]) {
            group[item.title] = [];
          }
          if (item.isDefaultValue) {
            group[item.title].push(item.groupName);
          }
        });
      });
    }

    return group;
  }, [groupedPermissions, groupedInitialPermissions]);

  return {
    loadingUser,
    detailedPermissionsLoading,
    matchedPermissionsList
  };
};

interface useConstructRoleGroupPermissionsProps<T> {
  rolePermissions?: T[];
}

export const useConstructRoleGroupPermissions = <T>({ rolePermissions }: useConstructRoleGroupPermissionsProps<T>) => {
  const { data: detailedPermissionsData, loading: detailedPermissionsLoading } = useQuery(
    GET_USER_DETAILED_PERMISSIONS,
    { fetchPolicy: 'no-cache' }
  );

  const detailedPermissions = detailedPermissionsData?.permissions;

  const groupedPermissions = useMemo(() => {
    return groupBy(detailedPermissions, 'resource');
  }, [detailedPermissions, detailedPermissionsLoading]);

  const groupedInitialPermissions = useMemo(() => {
    if (rolePermissions?.length) {
      return groupBy(rolePermissions as unknown as UserPermissions[], 'resource');
    }

    return [];
  }, [rolePermissions]);

  const matchedRolePermissionsList = useMemo<Group>(() => {
    const matchedList = Object.entries(groupedPermissions).map(([key, value]) => {
      return createMockedMenuList(key, value, groupedInitialPermissions as GroupOfPermissions);
    });

    const group: Group = {};

    if (matchedList.length) {
      matchedList.forEach((groupArray) => {
        groupArray.forEach((item) => {
          if (!group[item.title]) {
            group[item.title] = [];
          }
          if (item.isDefaultValue) {
            group[item.title].push(item.groupName);
          }
        });
      });
    }

    return group;
  }, [groupedPermissions, groupedInitialPermissions]);

  return {
    detailedPermissionsLoading,
    matchedRolePermissionsList
  };
};
