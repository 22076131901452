import { Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

export const TemplatesStarredPage = () => (
  <Grid justifyContent={'space-between'} container row>
    <Grid xs={12} md={10} lg={8} item>
      <Text component={'h2'}>Templates Starred Page</Text>
    </Grid>
  </Grid>
);
