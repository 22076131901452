import { styled } from '@mui/material/styles';
import { TreeItem } from '@mui/x-tree-view';

interface StyledTreeItemProps {
  active?: boolean;
}

export const StyledTreeItem = styled(TreeItem)(({ active = true }: StyledTreeItemProps) => ({
  '.node_action_button': {
    display: 'none'
  },

  '.MuiTreeItem-label': {
    color: active ? '#090E16' : 'rgba(9, 14, 22, 0.30)'
  },

  '.MuiTreeItem-content': {
    height: '32px',
    padding: 0,
    paddingRight: '4px',
    borderRadius: '4px',
    marginBottom: '2px',

    ':hover': {
      background: '#E9F2FF',

      '.node_action_button': {
        display: 'inline-flex'
      }
    }
  },

  '& .Mui-selected': {
    background: '#E9F2FF',

    '.node_action_button': {
      display: 'inline-flex'
    }
  },

  '& .tree-item-disabled': {
    svg: {
      fill: '#A5ADB8'
    }
  }
}));
