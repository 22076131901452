import { useMemo } from 'react';

import { SelectsDropdown } from '@/separatedModules/components';

import { i18n, useTranslation } from '@/i18n';

import { useContextUpdateState } from '../../../Providers';

const createStatuses = () => {
  return [
    {
      checked: false,
      label: i18n.t('role.table.filters.sortBy.menu.newToOld', { ns: 'templates' }),
      key: i18n.t('role.table.filters.sortBy.menu.newToOld', { ns: 'templates' }),
      value: 'DESC'
    },
    {
      checked: false,
      label: i18n.t('role.table.filters.sortBy.menu.oldToNew', { ns: 'templates' }),
      key: i18n.t('role.table.filters.sortBy.menu.oldToNew', { ns: 'templates' }),
      value: 'ASC'
    }
  ];
};

export const SortBy = () => {
  const { t: tTemplates } = useTranslation('templates');

  const { handleChangeSortByDate } = useContextUpdateState();

  const menuList = useMemo(() => createStatuses(), []);

  return (
    <SelectsDropdown
      id={'roles-sort-by'}
      title={tTemplates('role.table.filters.sortBy.title')}
      menuList={menuList}
      onChange={handleChangeSortByDate}
    />
  );
};
