import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PaperPlaneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_119_13526)">
          <path
            d="M46.8746 132.51V194.137C46.8746 199.751 54.0543 202.144 57.4203 197.65L87.5238 157.521L96.7004 152.819L196.062 12.3319L197.91 1.40771L181.766 10.3292L46.8418 127.456L46.8746 132.51Z"
            fill="#3B82F6"
          />
          <path
            d="M191.889 0.467254C191.625 0.573113 3.48945 95.0321 3.23398 95.1602C-0.868755 97.2114 -1.1211 102.982 2.78203 105.391L46.8703 132.525L197.905 1.42272C196.37 0.0922541 194.165 -0.475715 191.889 0.467254Z"
            fill="#93C5FD"
          />
          <path
            d="M198.111 1.69521C198.026 1.61279 197.998 1.48506 197.909 1.40771L196.763 3.03271L87.5234 157.521L155.129 199.126C158.65 201.291 163.207 199.227 163.959 195.218L199.904 6.94092C200.286 4.95381 199.481 3.0292 198.111 1.69521Z"
            fill="#60A5FA"
          />
        </g>
        <defs>
          <clipPath id="clip0_119_13526">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
