import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function SpinnerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424 448">
        <path
          d="M212.5 7Q231 7 244 20.5T257 52t-13 31.5T212.5 97 181 83.5 168 52t13-31.5T212.5 7zM337 54q22 0 37.5 16t15.5 37.5-15.5 37.5-37.5 16-38-16-16-37.5T299 70t38-16zM91.5 64q16.5 0 28 12t11.5 28.5-11.5 28.5-28 12T63 133t-12-28.5T63 76t28.5-12zM34 198q14 0 24 10t10 24-10 24-24 10-24-10-10-24 10-24 24-10zm355 0q14 0 24 10t10 24-10 24-24 10-24-10-10-24 10-24 24-10zM85 325q14 0 24 10t10 24-10 24-24 10-24-10-10-24 10-24 24-10zm255 0q14 0 24 10t10 24-10 24-24 10-24-10-10-24 10-24 24-10zm-128 47q14 0 24.5 10.5T247 407t-10.5 24-24.5 10-24-10-10-24 10-24.5 24-10.5z"
          fill="#000"
        />
        <rect x="0" y="0" width="424" height="448" fill="rgba(0, 0, 0, 0)" />
      </svg>
    </SvgIcon>
  );
}
