import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Text, TextField } from 'infinitytechnologies-ui';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { VisibilityIcon, VisibilityOffIcon } from '@/separatedModules/components';

import { PASS_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

interface StepItemProps {
  name: string;
  title: string;
  subTitle: string;
  inputLabel: string;
  control: any;
  errors: any;
}

export const StepItem = ({ name, title, subTitle, inputLabel, control, errors }: StepItemProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Text className={'edit-user__content__title'} variant={'titleLg'}>
        {title}
      </Text>

      <Text className={'edit-user__content__subTitle'} variant={'titleMd'}>
        {subTitle}
      </Text>

      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: true,
            message: tGlobal('validation.passwordRequired')
          },
          pattern: {
            value: PASS_REG_EXP,
            message: tGlobal('validation.passwordValid')
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <TextField
              label={inputLabel}
              value={value}
              type={showPassword ? 'text' : 'password'}
              validation={{
                isValid: !errors[name]?.message,
                error: errors[name]?.message
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position={'end'}>
                      <IconButton
                        aria-label={'toggle password visibility'}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge={'end'}
                      >
                        {showPassword ? (
                          <VisibilityIcon sx={{ fill: '#969AA8' }} />
                        ) : (
                          <VisibilityOffIcon sx={{ color: '#969AA8' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </>
                )
              }}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />
    </>
  );
};
