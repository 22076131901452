import classNames from 'classnames';

import { SpinnerIcon } from '@/separatedModules/components';

import { ObjectStringValuesT } from '@/subsidiaryBinders/types';

export const SPINNER_VARIANT: ObjectStringValuesT = {
  DEFAULT: 'default'
};

interface SpinnerProps {
  className?: string;
  variant?: string;
  size?: number;
  fill?: string;
}

export const Spinner = ({
  className,
  variant = SPINNER_VARIANT.DEFAULT,
  size = 20
}: // fill = 'var(--black)'
SpinnerProps) => {
  return (
    <>
      <div className={classNames(`spinner-wrp spinner-wrp--${variant}`, className)}>
        <SpinnerIcon className={'animate-spin-slow-1-5'} sx={{ fontSize: size }} />
      </div>
      {/* ToDo change to styled css or emotion */}
      {/*
      <style jsx>{`
        .spinner-wrp {
          &:global(.MuiSvgIcon-root > path) {
            fill: ${fill};
          }
        }
      `}</style>
      */}
    </>
  );
};
