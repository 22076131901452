import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

import { StepsStateI } from './helpers';

type ContextStateT = StepsStateI & {
  setState: Dispatch<SetStateAction<StepsStateI>>;
  handleSetPrevStep: () => void;
  handleSetParameterType: (type: string) => void;
  handleChangeIsShowDate: () => void;
};

export const ContextState = createContext<ContextStateT>({
  currentStep: 1,
  totalSteps: 4,
  parameterType: '',
  isDataCreated: false,
  isShowDate: false,
  setState: () => {},
  handleSetPrevStep: () => {},
  handleSetParameterType: () => {},
  handleChangeIsShowDate: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<StepsStateI>({
    currentStep: 1,
    totalSteps: 4,
    isDataCreated: false,
    parameterType: '',
    isShowDate: false
  });

  const handleSetPrevStep = () => {
    setState((state) => ({ ...state, currentStep: state.currentStep - 1 }));
  };

  const handleSetParameterType = (type: string) => {
    setState((state) => ({ ...state, parameterType: type }));
  };

  const handleChangeIsShowDate = () => {
    setState((state) => ({ ...state, isShowDate: !state.isShowDate }));
  };

  const contextValue = useMemo(() => {
    return {
      ...state,
      setState,
      handleSetPrevStep,
      handleSetParameterType,
      handleChangeIsShowDate
    };
  }, [state]);

  // ToDo Remove
  console.log('ProviderState contextValue - ', contextValue);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
