import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { CloseIcon, TableAsideItemsIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../index';

import { EquipmentItem } from './components/EquipmentItem';

import { StyledAside } from './style';

export const Aside = () => {
  const { t: tGlobal } = useTranslation('global');

  const state = useContextState();
  const { handleToggleAside } = useContextUpdateState();

  const isNoItemSelected = state.selectedItems.length === 0;
  const isOneItemSelected = state.selectedItems.length === 1;
  const isMoreOneItemSelected = state.selectedItems.length > 1;

  const singlePreview = isOneItemSelected && state.bodyDataRows.find((row) => row.id === state.selectedItems[0]);
  const isOneItemExist = isOneItemSelected && singlePreview;

  return (
    <StyledAside>
      <Collapse in={state.isAsideOpen} orientation={'horizontal'}>
        <Box display={'flex'} alignItems={'center'} sx={{ margin: '0 0 20px' }}>
          {isOneItemExist ? (
            <Text className={'m-0'} variant={'titleMd'}>
              {singlePreview.name}
            </Text>
          ) : null}

          <IconButton
            size={'medium'}
            className={'aside-table__btn__close'}
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleToggleAside}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {!isOneItemSelected ? <TableAsideItemsIcon className={'aside-table__icon'} /> : null}

        {isNoItemSelected ? <div className={'aside-table__text'}>{tGlobal('table.aside.selectItem')}</div> : null}

        {isOneItemExist ? <EquipmentItem {...singlePreview} /> : null}

        {isMoreOneItemSelected ? (
          <div className={'aside-table__text'}>
            {tGlobal('table.aside.selectedItem', { value: state.selectedItems.length })}
          </div>
        ) : null}
      </Collapse>
    </StyledAside>
  );
};
