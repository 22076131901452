import { Dispatch, ReactNode, SetStateAction } from 'react';

import { EquipmentRes } from '@/logicLayers/domain';

import { ConfigTableI, MenuOption } from '@/separatedModules/components';

import { CONFIG_TABLE } from '../../helpers';

export const TABLE_STORAGE_NAME = 'table_templates_equipment_columns';

export interface FirstMountState {
  isFirstMount: boolean;
  initCount: number;
}

export type CompanyFilters = {
  search: string[];
  hiddenColumns: string[];
  sortByDomains: string[];
  sortByDate: MenuOption[];
  sortByStatus: MenuOption[];
};

export interface initStateI extends Pick<ConfigTableI, 'pagination'> {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: CompanyFilters;
  selectedItems: string[];
  bodyDataRows: EquipmentRes[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
}

export interface ContextStateI extends initStateI {
  loadingEquipmentTemplates: boolean;
  setState: Dispatch<SetStateAction<initStateI>>;
}

export interface ContextUpdateStateI {
  handleChangeBySearch: (a: string) => void;
  handleChangePagination: (a: number) => void;
  handleChangeRowsPerPage: (a: number) => void;
  handleChangeSortByDomains: (a: string[]) => void;
  handleChangeSortByStatus: () => void;
  handleChangeSortByDate: () => void;
  handleClickRow: (a: string) => void;
  handleDoubleClickRow: (a: string) => void;
  handleResetSelectedRows: () => void;
  handleShowAllColumns: () => void;
  handleToggleActions: () => void;
  handleToggleAside: () => void;
  handleToggleColumnVisibility: (a: string) => void;
}

export interface TableProviderProps {
  children: ReactNode;
}

// ToDo Refactor
const getHiddenColumns = localStorage.getItem(TABLE_STORAGE_NAME);

export const initState: initStateI = {
  filters: {
    search: [],
    // ToDo Refactor
    hiddenColumns: getHiddenColumns ? JSON.parse(getHiddenColumns) : [],
    sortByDomains: [],
    sortByDate: [],
    sortByStatus: []
  },
  actions: {},
  pagination: CONFIG_TABLE.pagination,
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  bodyDataRows: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};
