import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { CompanyRes, GET_COMPANIES, GetCompaniesQuery } from '@/logicLayers/domain';

import { checkTablePagination } from '@/separatedModules/components';

import { ContextStateI, FirstMountState, initState, initStateI, TableProviderProps } from './helpers';

export const ContextState = createContext<ContextStateI>({
  ...initState,
  loadingCompanies: false,
  setState: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ProviderState = ({ children }: TableProviderProps) => {
  const { folderId = '' } = useParams();

  const [state, setState] = useState<initStateI>(initState);

  const firstMountState = useRef<FirstMountState>({
    isFirstMount: false,
    initCount: 0
  });

  const { data: dataCompanies, loading: loadingCompanies } = useQuery<GetCompaniesQuery>(GET_COMPANIES, {
    fetchPolicy: 'no-cache',
    variables: {
      page: state.pagination.page[0],
      pageSize: state.pagination.rowsPerPage[0],
      active: state.filters.sortByStatus.length ? Boolean(state.filters.sortByStatus[0].value) : undefined,
      query: state.filters.search.length ? state.filters.search[0] : undefined,
      column: state.filters.sortByDate.length ? 'createdDate' : undefined,
      direction: state.filters.sortByDate.length ? state.filters.sortByDate[0].value : undefined,
      nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined,
      regionIds: folderId ? [folderId] : undefined
    }
  });

  const contextValue = useMemo(() => {
    return {
      ...state,
      loadingCompanies,
      setState
    };
  }, [state]);

  useEffect(() => {
    if (!dataCompanies) return;

    const { isVisiblePagination, rowsPerPageOptions } = checkTablePagination(
      dataCompanies?.dataItems.total || 0,
      state.pagination.rowsPerPageOptions
    );

    const bodyDataRows = (dataCompanies?.dataItems?.items || []) as CompanyRes[];
    const resultSearchCount = bodyDataRows?.length || 0;

    if (!firstMountState.current.isFirstMount) {
      firstMountState.current.initCount = dataCompanies?.dataItems.total;
      firstMountState.current.isFirstMount = true;
    }

    setState((state) => {
      return {
        ...state,
        isVisiblePagination,
        bodyDataRows,
        loadingCompanies,
        resultSearchCount,
        resultTotalCount: dataCompanies?.dataItems.total || 0,
        pagination: {
          ...state.pagination,
          rowsPerPageOptions
        }
      };
    });
  }, [dataCompanies, loadingCompanies]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
