import { useCallback, useState } from 'react';

import { RenderWhen } from '@/separatedModules/components';

import { isSuperAdmin } from '@/utils';

import { AddUserButton } from './components/AddUserButton';
import { AddUserModalAdmin, AddUserModalClient } from './components/AddUserModal';

export const AddUser = () => {
  const isSuperAdminRole = isSuperAdmin();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <AddUserButton handleOpenModal={handleOpenModal} />

      <RenderWhen condition={!isSuperAdminRole}>
        <AddUserModalClient isModalOpen={isModalOpen} onCloseModal={handleCloseModal} />
      </RenderWhen>

      <RenderWhen condition={isSuperAdminRole}>
        <AddUserModalAdmin isModalOpen={isModalOpen} onCloseModal={handleCloseModal} />
      </RenderWhen>
    </>
  );
};
