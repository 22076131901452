import classNames from 'classnames';
import { Button, ButtonProps } from 'infinitytechnologies-ui';

import { ButtonLoadingIcon } from '@/separatedModules/components';

interface ButtonLoadingProps extends ButtonProps {
  loading: boolean;
}

export const ButtonLoading = ({ className, variant = 'text', children, loading, ...rest }: ButtonLoadingProps) => {
  return (
    <Button
      {...rest}
      className={classNames(className, {
        'MuiButton-loading': loading
      })}
      variant={variant}
      startIcon={<ButtonLoadingIcon className={'animate-spin'} />}
    >
      {children}
    </Button>
  );
};
