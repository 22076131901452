import Grid from '@mui/material/Grid';

import { NotFoundScreens, PageTitle } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export const CompaniesStarredPage = () => {
  const { t: tCompanies } = useTranslation('companies');

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item sx={{ minHeight: '70vh' }}>
        {/* ToDo i18n */}
        <PageTitle title={'Companies Starred'} />

        <NotFoundScreens type={'companies'} title={tCompanies('notFound.title')} />
      </Grid>
    </Grid>
  );
};
