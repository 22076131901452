import { UserRes } from '@/logicLayers/domain';

import { i18nT, TranslationT } from '@/subsidiaryBinders/types';

export interface UserEditProviderProps extends Partial<UserRes> {
  navigateToUrl: string;
  editType: string;
  tGlobal: TranslationT;
  tUser: TranslationT;
  i18n: i18nT;
}

export const onSubmitFormUserName = (params: Partial<UserRes>): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { firstName, lastName } = getValues();

    updateModel({
      variables: { ...params, firstName, lastName, phoneNumber: params.phoneNumber ? params.phoneNumber : undefined }
    });
  };
};

export const onSubmitFormUserPhone = (params: Partial<UserRes>): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { phoneNumber } = getValues();

    updateModel({
      variables: {
        ...params,
        phoneNumber
      }
    });
  };
};
