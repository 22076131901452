import Grid from '@mui/material/Grid';

import { LeftPart, RightPart } from './components';

import { StyledHeader } from './style';

export const Header = () => {
  return (
    <StyledHeader>
      <Grid className={'header__container'} container>
        <Grid justifyContent={'space-between'} alignItems={'center'} container row>
          <LeftPart />

          <RightPart />
        </Grid>
      </Grid>
    </StyledHeader>
  );
};
