import { gql as gqlApollo } from '@apollo/client';

export const GET_USER_BY_ID = gqlApollo(`
  query GetUser($uid: String!) {
    user(uid: $uid) {
      id
      firstName
      lastName
      email
      phoneNumber
      permissionTag
      status
      createdDate
      company {
        name
        id
      }
    }
  }
`);

export const GET_USER_ROLE_BY_ID = gqlApollo(`
  query GetUserRoleById($uid: String!) {
    user(uid: $uid) {
      id
      permissionTag
      firstName
      lastName
    }
  }
`);

export const GET_USER_ROLE_TEMPLATES = gqlApollo(`
  query GetUserRoleTemplates($searchCriteria: SearchCriteriaInput!) {
    dataItems: roleTemplates(searchCriteria: $searchCriteria) {
      items {
        id
        name
        description
        permissions {
          name
          operation
          resource
          description
        }
      }
    }
  }
`);

export const UPDATE_USER_BY_ID = gqlApollo(`
  mutation EditUser($id: String!, $firstName: String, $lastName: String, $phoneNumber: String) {
    editUser(
      userId: $id,
      user: {
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
      }
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      permissionTag
      status
      createdDate
      company {
        name
      }
    }
  }
`);

export const GET_USER_PERMISSIONS_BY_ID = gqlApollo(`
  query GetUserPermissions($uid: String!) {
    user(uid: $uid) {
      permissions {
        name
        operation
        resource
      }
    }
  }
`);

export const GET_USER_PROFILE = gqlApollo(`
  query GetProfile {
    profile {
      email
      firstName
      id
      companyId
      lastName
      lastPasswordChangeDate
      phoneNumber
      createdDate
      permissionTag
      fileKey
      domains {
        id
        name
      }
      permissions {
        name
      }
      profileAvatar {
        url
      }
    }
  }
`);

export const UPDATE_USER_PASSWORD = gqlApollo(`
  mutation UpdatePassword($newPassword: String!) {
    updateProfilePassword(newPassword: $newPassword)
  }
`);

export const EDIT_USER_PROFILE = gqlApollo(`
  mutation EditProfile($firstName: String, $lastName: String, $phoneNumber: String, $fileKey: String) {
    editProfile(request: { firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, fileKey: $fileKey }) {
      id
      firstName
      lastName
      phoneNumber
      fileKey
    }
  }
`);

export const SWITCH_USER_STATUS = gqlApollo(`
  mutation SwitchUserState($userId: String!, $status: UserStatus!) {
    switchUserState(request: { userId: $userId, status: $status })
  }
`);

export const EDIT_USER_ACCESS = gqlApollo(`
  mutation EditUserAccess($companyId: String, $request: EditUserAccessReqInput!) {
    editUserAccess(companyId: $companyId, request: $request)
  }
`);

export const GET_USER_DOMAINS_BY_ID = gqlApollo(`
  query GetUserDomainsById($uid: String!) {
    user(uid: $uid) {
      domains {
        id
        name
        color
      }
    }
  }
`);
