import { i18n } from '@/i18n';

import { TabListProps } from './components/FoldersTabs';

export const FIELD_FOLDER_NAME = 'name';

export const TAB_LIST: TabListProps[] = [
  {
    id: 0,
    name: i18n.t('actions.modal.move.tabs.allLocations', { ns: 'locations' })
  },
  {
    id: 1,
    name: i18n.t('actions.modal.move.tabs.recent', { ns: 'locations' })
  },
  {
    id: 2,
    name: i18n.t('actions.modal.move.tabs.favorites', { ns: 'locations' })
  }
];

export const MAX_MOVING_LEVEL = 5;

export const DISABLED_FOR_FOLDER_CREATION = [TAB_LIST[1], TAB_LIST[2]];
