export const SELECT_DATA_EXAMPLE = [
  {
    value: '1',
    label: 'value 1'
  },
  {
    value: '2',
    label: 'value 2'
  },
  {
    value: '3',
    label: 'value 3'
  },
  {
    value: '4',
    label: 'value 4'
  },
  {
    value: '5',
    label: 'value 5'
  },
  {
    value: '6',
    label: 'value 6'
  },
  {
    value: '7',
    label: 'value 7'
  },
  {
    value: '8',
    label: 'value 8'
  },
  {
    value: '9',
    label: 'value 9'
  },
  {
    value: '10',
    label: 'value 10'
  },
  {
    value: '11',
    label: 'value 11'
  },
  {
    value: '12',
    label: 'value 12'
  },
  {
    value: '13',
    label: 'value 13'
  },
  {
    value: '14',
    label: 'value 14'
  },
  {
    value: '15',
    label: 'value 15'
  }
];
