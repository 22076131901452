export const Project = {
  root: {
    '&.MuiMenu-root-Mui-select > .MuiPaper-root': {
      maxHeight: '200px'
    },
    '&.MuiPopover-root-filters': {
      margin: '4px 0 0',
      '&--with-search': {
        '.MuiPaper-root': {
          maxHeight: '345px'
        }
      },
      '&--with-footer': {
        '.MuiPaper-root': {
          maxHeight: '350px'
        }
      },
      '&--with-round': {
        '.MuiPaper-root': {
          minWidth: '200px'
        }
      },
      '&--with-both': {
        '.MuiPaper-root': {
          maxHeight: '400px !important'
        },
        '.MuiList-root': {
          '&__filter': {
            '&__i': {
              '&__children': {
                padding: '4px 0',
                minHeight: '200px',
                maxHeight: '287px',
                overflow: 'auto'
              }
            }
          }
        }
      },
      '.MuiPaper-root': {
        padding: '8px 0',
        maxHeight: '300px',
        overflow: 'hidden'
      },
      '.MuiList-root': {
        padding: '0',
        '&__filter': {
          '&__i': {
            '&__search': {
              margin: '0',
              padding: '4px 12px 8px',
              borderBottom: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
              marginBottom: '4px'
            },
            '&__apply': {
              'border-top': '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
              padding: '12px 12px 5px',
              left: '0',
              right: '0',
              bottom: '0',
              background: 'var(--white)',
              display: 'flex',
              justifyContent: 'flex-end',
              '.MuiButton-root': {
                margin: '0',
                '&:nth-child(2)': {
                  margin: '0 0 0 8px'
                }
              }
            },
            '&__children': {}
          }
        }
      }
    }
  },
  paper: {
    border: 'none',
    background: 'var(--background-neutral-default, #FFF)',
    minWidth: '250px',
    maxWidth: '300px !important',
    maxHeight: '300px !important',
    'border-radius': 'var(--4, 4px)',
    'box-shadow': '0px 8px 16px 0px rgba(9, 30, 66, 0.12), 0px 0px 4px 0px rgba(9, 30, 66, 0.20)'
  },
  list: {
    padding: '8px 0'
  }
};

export const Base = {
  root: {}
};
