import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import {
  Flex,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownCheckbox,
  TableFiltersBox,
  TableManagement,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { AsideContent } from './components/AsideContent';

import { columns, initState, skeletonTableLoaderCells, StatusesTypesList, useTableData } from './helpers';

export const TasksCustomPage = () => {
  const { t: tTasks } = useTranslation('tasks');
  const navigateTo = useNavigate();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { profile } = useReduxSelector(selectUserData);

  const { renderCount, ...tableState } = useTableData(initState);

  const [checked, setChecked] = useState(false);

  const theData = useMemo(() => {
    if (Array.isArray(tableState.data?.tasks?.items)) {
      return tableState.data?.tasks?.items.map((item) => ({
        ...item,
        profileId: profile.id
      }));
    }
    return [];
  }, [tableState.data, profile.id]);

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  useEffect(() => {
    if (tableState.state.selectedItems.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [tableState.state.selectedItems]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <TablePageTopBox
          title={tTasks('tasksCustom.pageTopTitle')}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          btnText={tTasks('tasks.pageTopButton')}
          onClickBtn={() => navigateTo(LINKS_PAGES.tasksCreate)}
          isHideButton={!isUserCanCRUD}
        />
        <Collapse in={checked}>
          <TableManagement
            count={tableState.state.selectedItems.length}
            open={checked}
            onClose={tableState.handleResetSelectedRows}
            onClickInfo={tableState.handleToggleAside}
          />
        </Collapse>

        <Collapse in={!checked}>
          <TableFiltersBox
            searchPlaceholder={tTasks('tasksCustom.searchTask')}
            selectedItems={tableState.state.selectedItems}
            handleToggleAside={tableState.handleToggleAside}
            handleChangeBySearch={tableState.handleChangeBySearch}
          >
            <TableDropdownCheckbox
              // ToDo Refactor
              title={tTasks('tasksCustom.filterStatus')}
              menuList={StatusesTypesList}
              withSelectAll
              withSearch
              searchPlaceholder={tTasks('tasksCustom.searchFilterStatus')}
              selectAllTitle={tTasks('tasksCustom.searchFilterShowAll')}
              onChange={tableState.handleChangeByStatus}
            />
            <TableSortByOneItem
              menu={SORT_BY_DATE_CREATE}
              showIcon={true}
              onChange={tableState.handleChangeSortByDate}
            />
          </TableFiltersBox>
        </Collapse>
        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {renderCount ? (
              <Flex>
                <Box width={'100%'}>
                  <Box sx={{ overflow: 'auto' }}>
                    <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                      <Table className={'m-0 MuiTable-root__columns-5'}>
                        <TableHead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <TableCell key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>

                        <TableBody>
                          {table.getRowModel().rows.map((row) => {
                            return (
                              <TableRowWrap
                                key={row.original.id}
                                id={row.original.id as string}
                                isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                handleClickRow={tableState.handleClickRow}
                                handleDoubleClickRow={tableState.handleDoubleClickRow}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <TableCell key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                  );
                                })}
                              </TableRowWrap>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <MuiTablePagination
                    count={renderCount}
                    page={tableState.state.page[0]}
                    rowsPerPage={tableState.state.rowsPerPage[0]}
                    rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                    onChangePage={tableState.handleChangePagination}
                    onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                  />
                </Box>

                <TableAside
                  rows={table.getRowModel().rows}
                  selectedItems={tableState.state.selectedItems}
                  isAsideOpen={tableState.state.isAsideOpen}
                  handleToggleAside={tableState.handleToggleAside}
                  sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {(props: any) => (
                    <AsideContent
                      handleChange={() => {
                        navigateTo(`/tasks/custom/${tableState.state.selectedItems[0]}/task`);
                      }}
                      {...props}
                    />
                  )}
                </TableAside>
              </Flex>
            ) : tableState.state.filters.search[0] ? (
              <NotFoundScreens type={'search'} />
            ) : (
              <NotFoundScreens
                type={'companies'}
                title={tTasks('tasksCustom.notTasksYet')}
                subTitle={tTasks('tasksCustom.createTask')}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
