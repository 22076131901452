import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Divider from '@mui/material/Divider';

import { EquipmentQuery, GET_EQUIPMENT, GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';
import { SideBarGroupTitle } from '@/separatedModules/containers/Sidebar/style';

import { CardMediaShort } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import {
  LINKS_IMAGES,
  LINKS_SIDEBAR_EQUIPMENT_BOTTOM_MENU,
  LINKS_SIDEBAR_EQUIPMENT_TOP_MENU,
  LINKS_SIDEBAR_TEMPLATES_EQUIPMENT_BOTTOM_MENU,
  LINKS_SIDEBAR_TEMPLATES_EQUIPMENT_TOP_MENU
} from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n/config';

import { NavListSimple } from '../NavListSimple';
import { NavListSimpleWithBtn } from '../NavListSimpleWithBtn';

interface SideBarTemplatesEquipmentProps {
  templateId?: string;
}

export const SideBarTemplatesEquipment = ({ templateId }: SideBarTemplatesEquipmentProps) => {
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');

  const { data: dataEquipmentTemplate, loading: loadingEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: templateId || undefined
    }
  });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;

  const name = dataEquipmentTemplate?.equipment?.name || '';
  const templateDescription = dataEquipmentTemplate?.equipment?.templateDescription || '';
  const image = dataEquipmentTemplate?.equipment?.fileUrl?.url || LINKS_IMAGES.panorama;
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const topMenu = isEquipmentTemplate ? LINKS_SIDEBAR_TEMPLATES_EQUIPMENT_TOP_MENU : LINKS_SIDEBAR_EQUIPMENT_TOP_MENU;
  const bottomMenu = isEquipmentTemplate
    ? LINKS_SIDEBAR_TEMPLATES_EQUIPMENT_BOTTOM_MENU
    : LINKS_SIDEBAR_EQUIPMENT_BOTTOM_MENU;

  return (
    <Sidebar>
      <SideBarGroup>
        <CardMediaShort
          title={name}
          subTitle={templateDescription}
          imgSrc={image}
          isLoading={loadingEquipmentTemplate}
        />
      </SideBarGroup>

      <SideBarGroup>
        <NavListSimple menu={topMenu} insertValue={templateId} replaceValue={':templateId'} />
      </SideBarGroup>

      <Divider sx={{ mt: '8px', mb: '8px' }} />

      <SideBarGroup>
        <SideBarGroupTitle>{i18n.t('templates.equipmentItem.roundTitle', { ns: 'sidebar' })}</SideBarGroupTitle>

        <NavListSimpleWithBtn
          menu={bottomMenu}
          insertValue={templateId}
          replaceValue={':templateId'}
          btnLinkRedirectReplaceValue={':templateId'}
          isDisabledButton={!isShowEditItem || !isUserCanCRUD}
        />
      </SideBarGroup>
    </Sidebar>
  );
};
