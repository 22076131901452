import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DoNotDisturbOnIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'17'}
      height={'16'}
      viewBox={'0 0 17 16'}
      fill={'none'}
      sx={{ fill: '#BE0E2C', fontSize: '17px !important', ...sx }}
      {...restProps}
    >
      <path d="M5.90469 8.50029H11.238C11.3797 8.50029 11.4984 8.45236 11.5943 8.35649C11.6901 8.26061 11.738 8.14181 11.738 8.00009C11.738 7.85836 11.6901 7.73963 11.5943 7.64391C11.4984 7.54819 11.3797 7.50033 11.238 7.50033H5.90469C5.76302 7.50033 5.64428 7.54826 5.54845 7.64413C5.45262 7.74 5.4047 7.8588 5.4047 8.00053C5.4047 8.14226 5.45262 8.26099 5.54845 8.35671C5.64428 8.45243 5.76302 8.50029 5.90469 8.50029ZM8.57247 14.3336C7.69651 14.3336 6.87316 14.1674 6.1024 13.835C5.33164 13.5025 4.66118 13.0513 4.09104 12.4815C3.52088 11.9116 3.06951 11.2414 2.73692 10.471C2.40433 9.70056 2.23804 8.87738 2.23804 8.00143C2.23804 7.12547 2.40426 6.30211 2.7367 5.53136C3.06915 4.76059 3.52031 4.09014 4.0902 3.51999C4.6601 2.94984 5.33026 2.49847 6.10069 2.16588C6.8711 1.83329 7.69428 1.66699 8.57024 1.66699C9.44619 1.66699 10.2695 1.83322 11.0403 2.16566C11.8111 2.4981 12.4815 2.94927 13.0517 3.51916C13.6218 4.08906 14.0732 4.75922 14.4058 5.52964C14.7384 6.30005 14.9047 7.12324 14.9047 7.99919C14.9047 8.87515 14.7384 9.6985 14.406 10.4693C14.0736 11.24 13.6224 11.9105 13.0525 12.4806C12.4826 13.0508 11.8124 13.5022 11.042 13.8347C10.2716 14.1673 9.44843 14.3336 8.57247 14.3336Z" />
    </SvgIcon>
  );
}
