import { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { AlertColor, AlertProps } from '@mui/material/Alert';

export interface AlertItemProps extends Pick<AlertProps, 'variant'> {
  id?: string;
  severity: AlertColor;
  action?: ReactNode | string;
  title?: ReactNode | string;
  content?: ReactNode | string;
  autoHideDuration?: number;
  isHideCloseBtn?: boolean;
  autoHide?: boolean;
  onCloseAction?: () => void;
  onFooterAction?: () => void;
}

type ObserverT = (val: AlertItemProps) => void;

type ObserversListT = {
  id: string;
  observer: ObserverT;
};

const DEFAULT_ALERT_PROPS: AlertItemProps = {
  // lightScheme: true,
  // type: NotificationAlertTypesEnum.Toast,
  severity: 'success',
  autoHideDuration: 2000,
  autoHide: true,
  isHideCloseBtn: true
};

class AlertObservable {
  #observers: ObserversListT[] = [];

  constructor() {
    this.#observers = [];
  }

  subscribe(observer: ObserverT) {
    const id = uuid();

    this.#observers = [...this.#observers, { observer, id }];
  }

  unsubscribe(observerId: string) {
    this.#observers = this.#observers.filter(({ id }) => id !== observerId);
  }

  notify(alerts: any) {
    this.#observers.forEach(({ observer }) => observer(alerts));
  }
}

export const AlertObservableService = new AlertObservable();

class AlertManager {
  #alerts: Record<string, any>[] = [];

  showAlert(alertProps: AlertItemProps) {
    const id = uuid();
    const alert = { ...DEFAULT_ALERT_PROPS, ...alertProps, id };

    this.#alerts = [...this.#alerts, alert];

    AlertObservableService.notify(this.#alerts);

    if (alert.autoHide) setTimeout(() => this.hideAlert(id), alert.autoHideDuration);
  }

  hideAlert(alertId: string) {
    this.#alerts = this.#alerts.filter(({ id }) => id !== alertId);

    AlertObservableService.notify(this.#alerts);
  }
}

export const AlertService = new AlertManager();
