import { useState } from 'react';
import { Button, Dialog, Modal, Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

import { BellIcon, Breadcrumbs } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiModals, titleFallback: 'Modals' }
];

export function UiModalsPage() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalSecond, setOpenModalSecond] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModalSecond = () => {
    setOpenModalSecond(false);
  };

  const handleOpenModalSecond = () => {
    setOpenModalSecond(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <Breadcrumbs links={BreadcrumbsLinks} />
      </Grid>
      <Grid xs={12} item>
        <Grid justifyContent={'center'} container row>
          <Grid xs={12} md={4} item>
            <Button onClick={handleOpenModal}>Open SimpleModal v1</Button>

            <Modal
              headerTitle={<Text variant={'h2'}>Add Skills & Techs</Text>}
              contentFooter={<div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, quisquam.</div>}
              open={openModal}
              onClose={handleCloseModal}
            >
              <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, quisquam.</div>
            </Modal>
          </Grid>

          <Grid xs={12} md={4} item>
            <Button onClick={handleOpenModalSecond}>Open SimpleModal v2</Button>

            <Modal
              contentFooter={<Button className={'mx-auto'}>Got it</Button>}
              open={openModalSecond}
              hideFooterBorder
              hideHeaderBorder
              isContentCenter
              onClose={handleCloseModalSecond}
            >
              <>
                <BellIcon sx={{ fontSize: 80 }} />
                <Text variant={'h2'}>Add Skills & Techs</Text>
                <div>Lorem ipsum</div>
              </>
            </Modal>
          </Grid>

          <Grid xs={12} md={4} item>
            <Button onClick={handleOpenDialog}>Open SimpleDialog</Button>

            <Dialog
              headerTitle={<Text variant={'h2'}>Add Skills & Techs</Text>}
              open={openDialog}
              onClose={handleCloseDialog}
            >
              <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, quisquam.</div>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
