import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';

import { BaseMenuItem, ExpandLessIcon, ExpandMoreIcon } from '@/separatedModules/components';

import { MenuItemProps, PermissionItemVariant } from '../helpers';

import { ReadonlyChip } from './style';

interface PermissionsSelectButtonProps {
  variant?: PermissionItemVariant;
  menuList: MenuItemProps[];
  onMenuItemClick: (item: MenuItemProps) => void;
}

export const PermissionsSelectButton: FC<PermissionsSelectButtonProps> = ({ variant, menuList, onMenuItemClick }) => {
  const initMount = useRef<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState(menuList[2].title);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!initMount.current) {
      setSelectedItem(menuList?.find((item) => item?.isDefaultValue)?.title ?? '');
    }

    if (open) {
      initMount.current = true;
    }
  }, [menuList, open]);

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (item: MenuItemProps) => {
    setSelectedItem(item.title);
    onMenuItemClick(item);
    handleClose();
  };

  return (
    <>
      {variant === 'base' ? (
        <Button variant={'text'} sx={{ fontSize: '14px !important' }} onClick={handleClickButton} disableElevation>
          <Stack direction={'row'} spacing={'5px'}>
            <Text variant={'labelMd'}>{selectedItem}</Text>
            <span>{open ? <ExpandMoreIcon sx={{ fontSize: 20 }} /> : <ExpandLessIcon sx={{ fontSize: 20 }} />}</span>
          </Stack>
        </Button>
      ) : (
        <ReadonlyChip label={selectedItem} />
      )}
      <Menu
        id={'role-management-add-role-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'role-management-add-role-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ margin: '12px 0 0 0' }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {menuList.map((item, index) => (
          <BaseMenuItem
            key={item.id}
            onClick={() => handleSelectItem(item)}
            hasDivider={index === 1}
            selected={item.title === selectedItem}
            checked={item.title === selectedItem}
            sxMenuItem={{ justifyContent: 'space-between' }}
          >
            {item.title}
          </BaseMenuItem>
        ))}
      </Menu>
    </>
  );
};
