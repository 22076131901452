import { i18n } from '@/i18n/config';

export const getBreadcrumbs = (data: any, folderId: string | undefined) => {
  const result: any[] = [];

  const processItems = (items: any[]) => {
    items?.forEach((item: any) => {
      const newItem = { ...item };

      if (!newItem.breadcrumbs) {
        newItem.breadcrumbs = [{ link: '/companies', titleFallback: i18n.t('pageTitle', { ns: 'company' }) }];
      }

      if (newItem.groupItems?.items?.length) {
        newItem.groupItems = newItem.groupItems.items.map((i: any) => ({
          ...i,
          breadcrumbs: [...newItem.breadcrumbs, { link: `/companies/${newItem.id}`, titleFallback: newItem.name }]
        }));

        processItems(newItem.groupItems);
      }

      result.push(newItem);
    });
  };

  processItems(data);

  const resultItem = result.filter((item: any) => item.id === folderId)?.[0];

  const resultItemBreadcrumbs = resultItem?.breadcrumbs;

  resultItemBreadcrumbs?.push({ link: `/companies/${resultItem.id}`, titleFallback: resultItem.name });

  return resultItemBreadcrumbs;
};
