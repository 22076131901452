export const Project = {
  root: {
    color: 'var(--text-default, #090E16)'
  },
  titleXl: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: '0 0 20px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '600'
  },
  titleLg: {
    fontSize: '18px',
    lineHeight: '28px',
    margin: '0 0 20px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '600'
  },
  titleMd: {
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0 0 16px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '600'
  },
  titleSm: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 12px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '600'
  },
  bodyLg: {
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0 0 16px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '400'
  },
  bodyMd: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 16px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '400'
  },
  bodySm: {
    fontSize: '12px',
    lineHeight: '16px',
    margin: '0 0 12px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '400'
  },
  labelLg: {
    fontSize: '16px',
    lineHeight: '20px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '500',
    margin: '0 0 12px'
  },
  labelMd: {
    fontSize: '14px',
    lineHeight: '18px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '500',
    margin: '0 0 12px'
  },
  labelSm: {
    fontSize: '11px',
    lineHeight: '14px',
    color: 'var(--text-default, #090E16)',
    fontWeight: '500',
    margin: '0 0 12px'
  },
  /* Cyber BMS/detail/detail */
  detail: {
    color: 'var(--text-subtle, #505668)',
    fontSize: '11px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    margin: '0 0 12px'
  }
};

export const Base = {
  root: {}
};
