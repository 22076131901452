import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Button } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

import { GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import {
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  PageTitle,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

export const SecurityPage = () => {
  const { t } = useTranslation('security');

  const navigateTo = useNavigate();

  const { data: dataUserProfile, loading: loadingUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const handleEditPassword = useCallback(() => {
    navigateTo(LINKS_PAGES.profileEditPassword);
  }, []);

  const userLastPasswordChangeDate = dataUserProfile?.profile?.lastPasswordChangeDate
    ? dayjs(dataUserProfile?.profile?.lastPasswordChangeDate).format('MMM DD, YYYY')
    : null;

  return loadingUserProfile ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
  ) : (
    <Grid className={'row'} justifyContent={'center'} container row>
      <Grid xs={12} md={10} xl={8} item>
        <PageTitle title={t('title')} subTitle={t('subTitle')} />

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={t('info.title')}
            label={t('info.label')}
            description={userLastPasswordChangeDate ? t('info.lastEdit', { value: userLastPasswordChangeDate }) : null}
            Edit={
              <Button sx={{ whiteSpace: 'nowrap' }} onClick={handleEditPassword}>
                {t('info.btn')}
              </Button>
            }
          />
        </BlockInfoWithEdit>
      </Grid>
    </Grid>
  );
};
