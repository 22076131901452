import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/Templates/pages/Domains/components';

import { CloseIcon, TableAsideItemsIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { StyledAside } from './style';

export const Aside = () => {
  const { t: tGlobal } = useTranslation('global');

  const state = useContextState();
  const { handleToggleAside } = useContextUpdateState();

  const isNoItemSelected = state.selectedItems.length === 0;
  const isMoreOneItemSelected = state.selectedItems.length > 1;

  return (
    <StyledAside>
      <Collapse in={state.isAsideOpen} orientation={'horizontal'}>
        <IconButton size={'medium'} className={'aside-table__btn__close'} onClick={handleToggleAside}>
          <CloseIcon />
        </IconButton>

        <TableAsideItemsIcon className={'aside-table__icon'} />

        {isNoItemSelected ? <div className={'aside-table__text'}>{tGlobal('table.aside.selectItem')}</div> : null}

        {isMoreOneItemSelected ? (
          <div className={'aside-table__text'}>
            {tGlobal('table.aside.selectedItem', { value: state.selectedItems.length })}
          </div>
        ) : null}
      </Collapse>
    </StyledAside>
  );
};
