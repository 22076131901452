import { useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import { SUBSCRIBE_TO_NOTIFICATIONS } from '@/logicLayers/domain';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { NotificationItem } from './components/NotificationItem';

import { Notification } from './helpers';

interface NotificationsPopoverProps {
  idPopover: string | undefined;
  anchorEl: HTMLElement | null;
  isOpenPopover: boolean;
  handleClosePopover: () => void;
}

export const NotificationsPopover = ({
  idPopover,
  anchorEl,
  isOpenPopover,
  handleClosePopover
}: NotificationsPopoverProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const { data: newNotification } = useSubscription(SUBSCRIBE_TO_NOTIFICATIONS);

  useEffect(() => {
    if (newNotification) {
      setNotifications((prev) => [
        { ...newNotification.subscribeToNotifications, isRead: false, date: new Date() },
        ...prev
      ]);
    }
  }, [newNotification]);

  const handleMarkAsRead = (id: string) => {
    setNotifications((prev) =>
      prev.map((notification) => (notification.id === id ? { ...notification, isRead: true } : notification))
    );
  };

  const handleMarkAllAsRead = () => {
    setNotifications((prev) => prev.map((notification) => ({ ...notification, isRead: true })));
  };

  const handleRemove = (id: string) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  return (
    <Popover
      id={idPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={isOpenPopover}
      onClose={handleClosePopover}
    >
      <Box sx={{ padding: '4px 0', margin: '0 0 8px', width: '450px', height: '600px', overflowY: 'scroll' }}>
        <Box
          sx={{
            height: '56px',
            padding: '12px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(41, 49, 62, 0.15)',
            position: 'sticky',
            top: '-4px',
            zIndex: '1',
            background: '#FFFFFF',
            marginTop: '-8px'
          }}
        >
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{tGlobal('notifications.title')}</Text>
          <Button variant={'text'} sx={{ padding: '0', color: '#265299 !important' }} onClick={handleMarkAllAsRead}>
            {tGlobal('notifications.btnMarkAllAsRead')}
          </Button>
        </Box>
        <Box>
          {notifications.length ? (
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleMarkAsRead={handleMarkAsRead}
                handleRemove={handleRemove}
              />
            ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '544px'
              }}
            >
              <img src={LINKS_IMAGES.notFoundSearch} alt="No notifications" />
              <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>
                {tGlobal('notifications.noNotifications')}
              </Text>
              <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
                {tGlobal('notifications.noNotificationsDescription')}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
