import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import { GET_USER_BY_ID, SWITCH_USER_STATUS, UserStatus } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { canActivateUser, canDeleteUser, getUserStatusColor } from '@/separatedModules/pages/User/helpers';

import {
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  Breadcrumbs,
  ChevronRightIcon,
  EMPTY_CELL_VALUE,
  PageTitle,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { UserActionsModal } from './components';

export const UserManagementPage: FC = () => {
  const { t: tUser } = useTranslation('user');
  const { t: tGlobal } = useTranslation('global');
  const { userId = '' } = useParams();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const navigateTo = useNavigate();

  const [action, setAction] = useState<UserStatus>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: userData,
    loading: userLoading,
    error: userError
  } = useQuery(GET_USER_BY_ID, { variables: { uid: userId } });
  const [SwitchUserStatus] = useMutation(SWITCH_USER_STATUS, {
    refetchQueries: [GET_USER_BY_ID, 'GetUser']
  });

  const firstName = userData?.user.firstName ?? '';
  const lastName = userData?.user.lastName ?? '';
  const status = userData?.user.status;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAction(undefined);
  };

  const handleChangeUserStatus = () => {
    setAction(canActivateUser(status) ? UserStatus.Active : UserStatus.Inactive);
    handleOpenModal();
  };

  const handleDeleteUser = () => {
    setAction(UserStatus.Deleted);
    handleOpenModal();
  };

  const handleConfirmChangeUserStatus = () => {
    SwitchUserStatus({
      variables: {
        userId,
        status: action as unknown as UserStatus
      }
    }).then(() => {
      AlertService.showAlert({
        title: tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });
      setIsModalOpen(false);
      if (action === UserStatus.Deleted) {
        navigateTo(LINKS_PAGES.users);
      }
      setAction(undefined);
    });
  };

  const name = firstName.length && lastName.length ? `${firstName}${lastName}` : EMPTY_CELL_VALUE;

  if (userError) {
    AlertService.showAlert({
      title: tGlobal('alertMessages.errors.base'),
      severity: 'error'
    });
  }

  const breadcrumbsLinks = [
    { link: LINKS_PAGES.users, titleFallback: tGlobal('header.menu.users') },
    { link: LINKS_PAGES.users, titleFallback: tUser('settings.usersBreadcrumbs') },
    { link: LINKS_PAGES.userItemPermissions.replace(':userId', userId), titleFallback: name },
    { link: LINKS_PAGES.userItemPermissions.replace(':userId', userId), titleFallback: tUser('management.pageTitle') }
  ];

  return userLoading ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} md={10} xl={8} item>
        <Breadcrumbs
          links={breadcrumbsLinks}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />
        <PageTitle title={tUser('management.pageTitle')} subTitle={tUser('management.pageSubTitle')} />

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={tUser('management.mainInfo.statusLabel')}
            label={
              <Chip
                sx={{ mb: '16px' }}
                variant={'filled'}
                size={'small'}
                color={getUserStatusColor(status)}
                label={tUser(`overview.status.type.${status}`)}
              />
            }
            description={tUser(`management.mainInfo.${status?.toLowerCase()}Description`)}
            sxDescription={{ maxWidth: '420px' }}
            Edit={
              <Button
                disabled={status === UserStatus.Deleted || !isUserCanCRUD}
                className={'m-0'}
                variant={'text'}
                sx={{ whiteSpace: 'nowrap' }}
                onClick={handleChangeUserStatus}
              >
                {tUser(`management.mainInfo.${canActivateUser(status) ? 'btnActivate' : 'btnDeactivate'}`)}
              </Button>
            }
          />
        </BlockInfoWithEdit>

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={tUser('management.mainInfo.deleteLabel')}
            label={''}
            description={tUser('management.mainInfo.deleteDescription')}
            sxDescription={{ maxWidth: '420px', pt: '16px' }}
            Edit={
              <Button
                disabled={!canDeleteUser(status) || !isUserCanCRUD}
                onClick={handleDeleteUser}
                className={'m-0'}
                variant={'text'}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {tUser('management.mainInfo.btnDelete')}
              </Button>
            }
          />
        </BlockInfoWithEdit>

        <UserActionsModal
          open={isModalOpen}
          firstName={firstName}
          lastName={lastName}
          action={action}
          canActivate={canActivateUser(status)}
          onClose={handleCloseModal}
          onConfirm={handleConfirmChangeUserStatus}
        />
      </Grid>
    </Grid>
  );
};
