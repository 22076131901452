import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Flex } from '@/separatedModules/components';

import {
  ActionsContainer,
  Aside,
  FiltersContainer,
  PageTopBox,
  ProviderState,
  ProviderUpdateState,
  TableWrap
} from './components';

export const TemplatesEquipmentPage = () => {
  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item sx={{ minHeight: '70vh' }}>
        <ProviderState>
          <ProviderUpdateState>
            <PageTopBox />

            <Flex>
              <Box width={'100%'}>
                <ActionsContainer />

                <FiltersContainer />

                <TableWrap />
              </Box>

              <Aside />
            </Flex>
          </ProviderUpdateState>
        </ProviderState>
      </Grid>
    </Grid>
  );
};
