import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { Text } from 'infinitytechnologies-ui';

import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';

import { GET_COMPANY, GetCompanyQuery } from '@/logicLayers/domain';

import { ImageLazy } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { StyledCompanyDetails } from './style';

interface CompanyDetailsProps {
  className?: string;
  sx?: SxProps<Theme>;
}

export const CompanyDetails = ({ className, sx }: CompanyDetailsProps) => {
  const { companyId = '' } = useParams();
  const { t: tCompany } = useTranslation('company');

  const {
    loading,
    error: errorCompany,
    data: dataCompany
  } = useQuery<GetCompanyQuery>(GET_COMPANY, { variables: { companyId } });

  const companyName = dataCompany?.company?.name;
  const companyIsActive = dataCompany?.company?.active ?? true;
  const companyAddress = dataCompany?.company?.address ?? false;
  const companyLogo = dataCompany?.company?.logo?.url;

  useEffect(() => {
    // ToDo Add workflow for error
    // ToDo Remove
    if (errorCompany) console.log('CompanyDetails errorCompany - ', errorCompany);
  }, [errorCompany]);

  return (
    <StyledCompanyDetails className={classNames('company-details', className)} sx={sx}>
      {loading ? (
        <Skeleton className={'m-0'} variant={'rectangular'} animation={'wave'} sx={{ maxWidth: 600, height: 136 }} />
      ) : (
        <div className={'company-details'}>
          <div className={'company-details__info'}>
            {companyLogo ? (
              <ImageLazy
                className={'company-details__logo'}
                src={companyLogo}
                alt={`Logo ${companyName} company`}
                width={136}
                height={136}
              />
            ) : null}

            <div className={'company-details__right'}>
              {companyName ? (
                <Text className={'company-details__name'} variant={'titleMd'}>
                  {companyName}
                </Text>
              ) : null}

              <Chip
                className={'company-details__status'}
                variant={'filled'}
                size={'small'}
                color={companyIsActive ? 'success' : 'error'}
                label={companyIsActive ? tCompany('overview.statusEnabled') : tCompany('overview.statusDisabled')}
              />

              {companyAddress ? <div className={'company-details__address'} /> : null}
            </div>
          </div>
        </div>
      )}
    </StyledCompanyDetails>
  );
};
