import { useCallback, useState } from 'react';

import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';

import { AlertItemProps } from '@/logicLayers/infrastructure/services';

import { Alert } from '@/separatedModules/components';

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionDown = (props: TransitionProps) => {
  return <Slide {...props} direction={'right'} timeout={300} />;
};

interface SnackbarItemProps extends AlertItemProps {}

export const SnackbarItem = function SnackbarItem({
  severity,
  action,
  title,
  autoHideDuration,
  content,
  isHideCloseBtn,
  onCloseAction,
  onFooterAction
}: SnackbarItemProps) {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseAction = useCallback(() => {
    setIsOpen(false);

    onCloseAction?.();
  }, []);

  const handleFooterAction = useCallback(() => {
    onFooterAction?.();
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{ position: 'static' }}
      open={isOpen}
      TransitionComponent={TransitionDown}
      autoHideDuration={autoHideDuration}
    >
      <div>
        <Alert
          variant={'notice'}
          severity={severity}
          title={title}
          content={content}
          action={action}
          isHideCloseBtn={isHideCloseBtn}
          onCloseAction={handleCloseAction}
          onFooterAction={handleFooterAction}
        />
      </div>
    </Snackbar>
  );
};
