import { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import {
  CREATE_REGION,
  GET_REGION,
  GET_REGIONS,
  GET_REGIONS_SIDEBAR,
  MutationCreateLocationFolderArgs
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { CloseIcon, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_FOLDER_NAME } from '../../helpers';

import { StyledDialog } from './style';

interface FormProps {
  [FIELD_FOLDER_NAME]: string;
}

export type ModalSize = 'big' | 'base';

interface AddLocationModalProps {
  isModalOpen: boolean;
  folderId: string;
  modalSize?: ModalSize;
  onCloseModal: () => void;
}

export const AddRegionModal = ({ isModalOpen, folderId, modalSize = 'base', onCloseModal }: AddLocationModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  const inputRef = useRef<HTMLInputElement>(null);

  const [CreateFolder] = useMutation<MutationCreateLocationFolderArgs>(CREATE_REGION, {
    variables: {
      parentId: folderId?.length && folderId !== 'root' ? folderId : null
    },
    refetchQueries: [GET_REGIONS_SIDEBAR, GET_REGIONS, GET_REGION]
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_FOLDER_NAME]: ''
    }
  });

  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    CreateFolder({
      variables: { name: data.name },
      onCompleted: () => {
        handleCloseModal();
        // TODO: here some response issue with the parent id
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.folderCreated'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <StyledDialog modalSize={modalSize} open={isModalOpen} onClose={handleCloseModal}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 12px' }}>
        <Text variant="titleLg" sx={{ fontSize: 20 }}>
          {tLocations('table.create.modal.title')}
        </Text>
        <CloseIcon onClick={handleCloseModal} />
      </Box>
      <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
      <Box sx={{ padding: '32px 24px 20px 24px' }}>
        <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
          <Controller
            name={FIELD_FOLDER_NAME}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: ROLE_NAME_REG_EXP,
                message: tGlobal('validation.textFieldLength')
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  label={tLocations('actions.modal.create.locationInputLabel')}
                  placeholder={tLocations('actions.modal.create.locationInputPlaceholder')}
                  name={FIELD_FOLDER_NAME}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_FOLDER_NAME]?.message,
                    error: errors[FIELD_FOLDER_NAME]?.message
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={inputRef}
                  autoFocus
                />
              );
            }}
          />
        </form>
      </Box>
      <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))' }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 12px' }}>
        <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
          {tLocations('actions.modal.create.btnCancel')}
        </Button>
        <Button
          variant={'contained'}
          disabled={!!errors[FIELD_FOLDER_NAME]}
          sx={{ margin: '0 24px 0 0 !important' }}
          onClick={handleSubmitForm}
        >
          {tLocations('actions.modal.create.btnSave')}
        </Button>
      </Box>
    </StyledDialog>
  );
};
