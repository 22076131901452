import { createBrowserRouter } from 'react-router-dom';

import { BaseLayout, SimpleLayout } from '@/separatedModules/layouts';
import { QRScanner } from '@/separatedModules/layouts/Base/components/Scanner';
import {
  CompaniesArchivePage,
  CompaniesPage,
  CompaniesRecentPage,
  CompaniesStarredPage,
  CompanyCreatePage,
  CompanyEditPage,
  CompanyPage,
  CompanySettingsPage,
  CreateRolePage,
  DomainsPage,
  EquipmentItemCreatePage,
  EquipmentItemTemplateCreatePage,
  HomePage,
  LocationsPage,
  NotFoundPage,
  OverviewPage,
  ProfileEditPage,
  ProfilePage,
  ReportPage,
  RolePage,
  SecurityPage,
  TaskByRoundCreate,
  TaskCreatePage,
  TaskCustomDetailsPage,
  TasksPage,
  TemplatesArchivePage,
  TemplatesEquipmentItemDetailsPage,
  TemplatesEquipmentItemDocumentsPage,
  TemplatesEquipmentItemEditPage,
  TemplatesEquipmentItemItemsPage,
  TemplatesEquipmentItemPage,
  TemplatesEquipmentItemParametersPage,
  TemplatesEquipmentItemRoundsRoundRegulationPage,
  TemplatesEquipmentItemRoundsRoundRegulationsPage,
  TemplatesEquipmentPage,
  TemplatesParametersCreatePage,
  TemplatesRecentPage,
  TemplatesRolesRoleDetailsEditPage,
  TemplatesRolesRoleDetailsPage,
  TemplatesRolesRoleEditPage,
  TemplatesRolesRolePermissionsPage,
  TemplatesStarredPage,
  UiButtonsPage,
  UiIconsPage,
  UiModalsPage,
  UiOtherPage,
  UiPage,
  UiRadioSwitchCheckboxPage,
  UiTextEditorsPage,
  UiTextFieldsPage,
  UserEditPage,
  UserManagementPage,
  UserPage,
  UserPermissionsPage,
  UserRoleManagementPage,
  UserSecurityPage,
  UsersPage,
  UsersPage2
} from '@/separatedModules/pages';
import { TaskDetails } from '@/separatedModules/pages/Tasks/pages/TaskDetails';
import { TasksCustomPage } from '@/separatedModules/pages/Tasks/pages/TasksCustom/TasksCustom';
import { EditParameter } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/EditParameter';
import { TemplatesEquipmentItemParameterPage } from '@/separatedModules/pages/Templates/pages/Equipment/pages/EquipmentItem/pages/Parameter';
import { EditRound } from '@/separatedModules/pages/Templates/pages/Equipment/pages/RoundRegulations/pages/EditRound';
import { RoundRegulationItemCreate } from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate';
import { RoleUsersPage } from '@/separatedModules/pages/Templates/RoleUsers';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { ErrorBoundary } from '../ErrorBoundary';

// https://reactrouter.com/en/main/route/route
export const AppRouterHistory = createBrowserRouter([
  /* BaseLayout */
  {
    path: LINKS_PAGES.home,
    element: <BaseLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: LINKS_PAGES.companies,
        element: <CompaniesPage />
      },
      {
        path: LINKS_PAGES.companiesItem,
        element: <CompaniesPage />
      },
      {
        path: LINKS_PAGES.companiesRecent,
        element: <CompaniesRecentPage />
      },
      {
        path: LINKS_PAGES.companiesStarred,
        element: <CompaniesStarredPage />
      },
      {
        path: LINKS_PAGES.companiesArchive,
        element: <CompaniesArchivePage />
      },
      {
        path: LINKS_PAGES.companyItem,
        element: <CompanyPage />
      },
      {
        path: LINKS_PAGES.companyItemSettings,
        element: <CompanySettingsPage />
      },
      {
        path: LINKS_PAGES.overview,
        element: <OverviewPage />
      },
      {
        // ToDo Refactor
        // ToDo Refactor
        path: LINKS_PAGES.role,
        element: <RolePage />
      },
      {
        // ToDo Refactor
        // ToDo Refactor
        path: LINKS_PAGES.users,
        element: <UsersPage />
      },
      {
        // ToDo Refactor
        // ToDo Refactor
        path: LINKS_PAGES.users2,
        element: <UsersPage2 />
      },
      {
        path: LINKS_PAGES.userItem,
        element: <UserPage />
      },
      {
        path: LINKS_PAGES.userItemPermissions,
        element: <UserPermissionsPage />
      },
      {
        path: LINKS_PAGES.userItemManagement,
        element: <UserManagementPage />
      },
      {
        path: LINKS_PAGES.userItemSecurity,
        element: <UserSecurityPage />
      },
      {
        path: LINKS_PAGES.templatesEquipment,
        element: <TemplatesEquipmentPage />
      },
      {
        // ToDo
        path: LINKS_PAGES.templatesRoles,
        element: <RolePage />
      },
      {
        // ToDo
        path: LINKS_PAGES.usersRole,
        element: <RoleUsersPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemParameters,
        element: <TemplatesEquipmentItemParametersPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemParameter,
        element: <TemplatesEquipmentItemParameterPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemRoundsRoundRegulations,
        element: <TemplatesEquipmentItemRoundsRoundRegulationsPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemRoundsRoundRegulation,
        element: <TemplatesEquipmentItemRoundsRoundRegulationPage />
      },
      {
        path: LINKS_PAGES.templatesRolesRoleDetails,
        element: <TemplatesRolesRoleDetailsPage />
      },
      {
        path: LINKS_PAGES.templatesRolesRolePermissions,
        element: <TemplatesRolesRolePermissionsPage />
      },
      {
        // ToDo
        path: LINKS_PAGES.templatesReports,
        element: <ReportPage />
      },
      {
        // ToDo
        path: LINKS_PAGES.templatesDomains,
        element: <DomainsPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItem,
        element: <TemplatesEquipmentItemPage />
      },
      {
        path: LINKS_PAGES.equipmentModel,
        element: <TemplatesEquipmentItemPage />
      },
      {
        path: LINKS_PAGES.equipmentItemParameters,
        element: <TemplatesEquipmentItemParametersPage />
      },
      {
        path: LINKS_PAGES.equipmentItemParameter,
        element: <TemplatesEquipmentItemParameterPage />
      },
      {
        path: LINKS_PAGES.equipmentItemRoundRegulations,
        element: <TemplatesEquipmentItemRoundsRoundRegulationsPage />
      },
      {
        path: LINKS_PAGES.equipmentItemRoundRegulation,
        element: <TemplatesEquipmentItemRoundsRoundRegulationPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemDetails,
        element: <TemplatesEquipmentItemDetailsPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentModelDetails,
        element: <TemplatesEquipmentItemDetailsPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemItems,
        element: <TemplatesEquipmentItemItemsPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemDocuments,
        element: <TemplatesEquipmentItemDocumentsPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemDocuments,
        element: <TemplatesEquipmentItemDocumentsPage />
      },
      {
        path: LINKS_PAGES.templatesRecent,
        element: <TemplatesRecentPage />
      },
      {
        path: LINKS_PAGES.templatesStarred,
        element: <TemplatesStarredPage />
      },
      {
        path: LINKS_PAGES.templatesArchive,
        element: <TemplatesArchivePage />
      },
      // {
      //   path: LINKS_PAGES.locationsAllEquipment,
      //   element: <div>hi</div>
      // },
      {
        // ToDo
        path: LINKS_PAGES.locations,
        element: <LocationsPage />
      },
      {
        // ToDo
        path: LINKS_PAGES.locationsItem,
        element: <LocationsPage />
      },
      {
        path: LINKS_PAGES.profile,
        element: <ProfilePage />
      },
      {
        // ToDo
        path: LINKS_PAGES.profileSecurity,
        element: <SecurityPage />
      },
      {
        path: LINKS_PAGES.tasks,
        element: <TasksPage />
      },
      {
        path: LINKS_PAGES.tasksCustom,
        element: <TasksCustomPage />
      },
      {
        path: LINKS_PAGES.tasksAccomplished,
        element: <TasksPage />
      },
      {
        path: LINKS_PAGES.tasksCustomDetails,
        element: <TaskCustomDetailsPage />
      },
      {
        path: LINKS_PAGES.tasksDetails,
        element: <TaskDetails />
      },
      /* ToDo Hide later */
      /* ToDo Hide later */
      /* ToDo Hide later */
      {
        path: LINKS_PAGES.ui,
        element: <UiPage />
      },
      {
        path: LINKS_PAGES.uiButtons,
        element: <UiButtonsPage />
      },
      {
        path: LINKS_PAGES.uiIcons,
        element: <UiIconsPage />
      },
      {
        path: LINKS_PAGES.uiModals,
        element: <UiModalsPage />
      },
      {
        path: LINKS_PAGES.uiInputs,
        element: <UiTextFieldsPage />
      },
      {
        path: LINKS_PAGES.uiRadioSwitchCheckbox,
        element: <UiRadioSwitchCheckboxPage />
      },
      {
        path: LINKS_PAGES.uiOther,
        element: <UiOtherPage />
      },
      {
        path: LINKS_PAGES.uiTextEditors,
        element: <UiTextEditorsPage />
      }
    ]
  },
  /* SimpleLayout */
  {
    element: <SimpleLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        // ToDo
        path: LINKS_PAGES.userItemPermissionsManagement,
        element: <UserRoleManagementPage />
      },
      {
        // ToDo
        path: LINKS_PAGES.templatesRolesCreate,
        element: <CreateRolePage />
      },
      {
        path: LINKS_PAGES.userItemManagementVisibility,
        element: <UserManagementPage />
      },
      {
        path: LINKS_PAGES.scanner,
        element: <QRScanner />
      },
      {
        // ToDo
        path: LINKS_PAGES.templatesEquipmentCreate,
        element: <EquipmentItemTemplateCreatePage />
      },
      {
        path: LINKS_PAGES.templatesParametersCreate,
        element: <TemplatesParametersCreatePage />
      },
      {
        path: LINKS_PAGES.templatesRoundRegulationCreate,
        element: <RoundRegulationItemCreate />
      },
      {
        path: LINKS_PAGES.templatesTaskByRoundCreate,
        element: <TaskByRoundCreate />
      },
      {
        path: LINKS_PAGES.equipmentItemCreate,
        element: <EquipmentItemCreatePage />
      },
      {
        path: LINKS_PAGES.userItemVisibility,
        element: <UserPage />
      },
      {
        path: LINKS_PAGES.userItemPermissionsVisibility,
        element: <UserPermissionsPage />
      },
      {
        path: LINKS_PAGES.templatesRolesRoleEdit,
        element: <TemplatesRolesRoleEditPage />
      },
      {
        path: LINKS_PAGES.templatesRolesRoleDetailsEdit,
        element: <TemplatesRolesRoleDetailsEditPage />
      },
      {
        path: LINKS_PAGES.profileEdit,
        element: <ProfileEditPage />
      },
      {
        path: LINKS_PAGES.companyCreate,
        element: <CompanyCreatePage />
      },
      {
        path: LINKS_PAGES.companyItemEdit,
        element: <CompanyEditPage />
      },
      {
        path: LINKS_PAGES.userItemEdit,
        element: <UserEditPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemEdit,
        element: <TemplatesEquipmentItemEditPage />
      },
      {
        path: LINKS_PAGES.equipmentModelEdit,
        element: <TemplatesEquipmentItemEditPage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentItemParameterEdit,
        element: <EditParameter />
      },
      {
        path: LINKS_PAGES.templatesEquipmentEditItemRoundsRoundRegulations,
        element: <EditRound />
      },
      {
        path: LINKS_PAGES.tasksCreate,
        element: <TaskCreatePage />
      },
      {
        path: LINKS_PAGES.templatesEquipmentEditItemRoundsTask,
        element: <EditRound />
      },
      {
        path: LINKS_PAGES.profileMobile,
        element: <ProfilePage />
      }
    ]
  },
  /* NotFoundPage */
  {
    path: LINKS_PAGES.notFound,
    element: <NotFoundPage />,
    errorElement: <ErrorBoundary />
  }
]);
