import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Grid } from '@mui/material';

import { CREATE_EQUIPMENT_ROUND } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  DomainStatusChip,
  Flex,
  ImageLazy,
  ModalDiscardAllChanges,
  MultiStepFooter,
  MultiStepHeader,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { Steps } from './components/Steps';
import { useContextState } from './ContextProvider';

import { DEFAULT_VALUES, FORM_ID, FormProps, ROUND_PARAMETERS, useData } from './helpers';

export const ContextConsumer = () => {
  const { templateId = '' } = useParams();
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [CreateEquipmentRound, { loading }] = useMutation(CREATE_EQUIPMENT_ROUND);
  const { equipmentItemNameData, equipmentItemNameLoading } = useData({ templateId });

  const { setState, handleSetPrevStep, ...state } = useContextState();

  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleSuccessRedirect = () => {
    navigateTo(-1);
  };

  const handleSuccess = () => {
    setState((state) => ({ ...state, isDataCreated: true }));

    AlertService.showAlert({
      title: tGlobal('alertMessages.success.templates.round.created'),
      severity: 'success'
    });

    setTimeout(() => {
      setState((state) => ({ ...state, isDataCreated: false }));

      handleSuccessRedirect();
    }, 2500);
  };

  const handleCreateRound = (data: FormProps) => {
    const roundDate = data.roundDate?.date?.format('YYYY-MM-DD') || '';
    const roundTime = data.roundDate?.time?.format('HH:mm:ss') || '';

    const roundCorrectDate = `${roundDate}T${roundTime}`;

    CreateEquipmentRound({
      variables: {
        createRequest: {
          name: data.title,
          description: data.description,
          activationTargetDate: roundCorrectDate,
          equipmentId: equipmentItemNameData?.equipment.id,
          frequency: data.round,
          serviceStaff: data.users,
          parameters: data.roundSelectedParameters
        }
      },
      onCompleted(data) {
        if (data?.createRoundRegulation?.id) {
          handleSuccess();
        }
      }
    });
  };

  const methods = useForm<FormProps>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const handleSubmitForm = methods.handleSubmit((data) => {
    if (state.currentStep < state.totalSteps) {
      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else {
      handleCreateRound(data);
    }
  });

  const watchedFields = useWatch({ control: methods.control });

  useEffect(() => {
    if (!isInitialRender) {
      const fields = {
        title: watchedFields.title || '',
        round: watchedFields.round || '',
        roundParameters: JSON.stringify(watchedFields.roundParameters) || '',
        roundSelectedParameters: JSON.stringify(watchedFields.roundSelectedParameters) || '',
        roundDate:
          watchedFields.roundDate?.date && typeof watchedFields.roundDate.date.unix === 'function'
            ? watchedFields.roundDate.date.unix()
            : '',
        roundTime:
          watchedFields.roundDate?.time && typeof watchedFields.roundDate.time.unix === 'function'
            ? watchedFields.roundDate.time.unix()
            : '',
        users: watchedFields.users?.join(',') || '',
        isShowDate: String(state.isShowDate),
        currentStep: String(state.currentStep)
      };

      const params = new URLSearchParams(fields);
      navigateTo(`?${params.toString()}`, { replace: true });
    } else {
      setIsInitialRender(false);
    }
  }, [watchedFields, navigateTo, state.currentStep, state.isShowDate, isInitialRender]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    methods.setValue('title', searchParams.get('title') || '');
    methods.setValue('round', searchParams.get('round') || '');
    methods.setValue('roundParameters', JSON.parse(searchParams.get('roundParameters') || '[]'));
    methods.setValue('roundSelectedParameters', JSON.parse(searchParams.get('roundSelectedParameters') || '[]'));
    methods.setValue('users', searchParams.get('users')?.split(',') || []);
    methods.setValue('roundDate', {
      date: searchParams.get('roundDate')?.length ? dayjs(Number(searchParams.get('roundDate')) * 1000) : dayjs(),
      time: searchParams.get('roundTime')?.length ? dayjs(Number(searchParams.get('roundTime')) * 1000) : dayjs()
    });

    setState((state) => ({
      ...state,
      currentStep: searchParams.get('currentStep') ? Number(searchParams.get('currentStep')) : 1,
      isShowDate: searchParams.get('isShowDate') === 'true'
    }));
  }, []);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={10} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            {equipmentItemNameLoading ? (
              <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '300px !important' }} isContainer />
            ) : (
              <>
                <ModalDiscardAllChanges navigateToUrl={-1 as any} sxBox={{ margin: '0 0 32px' }} />

                <MultiStepHeader
                  title={'Create round regulation'}
                  titleStep={tGlobal('multiStep.titleStep', {
                    currentStep: state.currentStep,
                    totalSteps: state.totalSteps
                  })}
                  description={
                    <>
                      <Trans
                        i18nKey={
                          'Define maintenance guidelines and establish regulations for conducting equipment inspection rounds'
                        }
                        values={{ equipmentName: equipmentItemNameData?.equipment.name }}
                        components={{ span: <span style={{ fontWeight: 600 }} /> }}
                      />
                      <Flex
                        sx={{
                          marginTop: '16px',
                          borderRadius: '4px',
                          background: '#F7F8F9',
                          width: '100%',
                          maxWidth: '380px',
                          height: '48px',
                          alignItems: 'center',
                          paddingLeft: '4px',
                          paddingRight: '8px',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Flex sx={{ alignItems: 'center' }}>
                          <ImageLazy
                            width={40}
                            height={40}
                            sxStyle={{ borderRadius: '2px' }}
                            src={
                              equipmentItemNameData?.equipment.fileUrl
                                ? equipmentItemNameData?.equipment.fileUrl.url
                                : ''
                            }
                            alt={
                              equipmentItemNameData?.equipment.fileUrl
                                ? equipmentItemNameData?.equipment.fileUrl.fileKey
                                : ''
                            }
                          />
                          <Text
                            sx={{
                              marginLeft: '8px',
                              fontWeight: '500',
                              marginRight: '16px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              maxWidth: '300px'
                            }}
                          >
                            {equipmentItemNameData?.equipment.name}
                          </Text>
                        </Flex>
                        <DomainStatusChip
                          color={equipmentItemNameData?.equipment.domain.color}
                          label={equipmentItemNameData?.equipment.domain.name}
                        />
                      </Flex>
                    </>
                  }
                  currentStep={state.currentStep}
                  totalStep={state.totalSteps}
                  sxDescription={{ maxWidth: 660 }}
                />
              </>
            )}
            <Steps step={`step${state.currentStep}`} />

            <MultiStepFooter
              btnNextStepText={state.currentStep === 3 ? 'Select & Continue' : tGlobal('multiStep.btnNextStep')}
              btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
              btnCreateTemplateText={'Create round regulation'}
              isLoadingBtnCreate={loading}
              isDisabledBtnCreate={state.isDataCreated}
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
              isRound
              handleSetPrevStep={handleSetPrevStep}
              isDisabled={state.currentStep === 3 && !watchedFields[ROUND_PARAMETERS]?.length}
            />
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
