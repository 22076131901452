import { FC, ReactNode, useMemo } from 'react';

export interface RenderWhenProps {
  condition?: boolean;
  children: ReactNode;
}

export const RenderWhen: FC<RenderWhenProps> = ({ condition, children }) => {
  const shouldRender = useMemo(() => {
    if (condition === undefined) {
      console.error('RenderWhen: condition or breakpoint must be defined');
      return false;
    }

    let result = true;
    if (condition !== undefined) {
      result = condition;
    }

    return result;
  }, [condition]);

  return shouldRender ? <>{children}</> : null;
};
