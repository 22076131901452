import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import TableBody from '@mui/material/TableBody';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../Providers';

import { CONFIG_TABLE } from '../../helpers';

import { StyledTableRow } from './style';

export const TableBodyWrap = () => {
  const location = useLocation();
  const { t: tTemplates } = useTranslation('templates');

  const state = useContextState();

  const { handleSelectRow, handleToggleAside } = useContextUpdateState();

  return (
    <TableBody>
      {state.bodyDataRows?.map((row) => {
        return (
          <StyledTableRow
            key={row.id}
            className={classNames({
              'Mui-selected': state.selectedItems.includes(row.id as string)
            })}
            onClick={() => {
              row.id && handleSelectRow(row.id);
            }}
            onDoubleClick={() => {
              handleToggleAside();
              row.id && handleSelectRow(row.id);
            }}
          >
            <>
              {CONFIG_TABLE.cells.map((cell, index) => {
                const className = `${CONFIG_TABLE.cells[index].columnClass}`;

                const isMainRole = location.pathname === '/role';

                if (!isMainRole && cell.columnName === 'createdBy') {
                  return null;
                }

                return cell.Render(row, className, index, tTemplates, location);
              })}
            </>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
