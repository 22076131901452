import { MouseEvent, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { Avatar, Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AuthService } from '@/logicLayers/infrastructure/services';

import { LogOutIcon, UserDetails } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

export const RightItemAvatar = () => {
  const auth = useAuth();

  const { t: tGlobal } = useTranslation('global');

  const { profile } = useReduxSelector(selectUserData);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    AuthService.handleLogOut();
  };

  const { isAuthenticated } = auth;
  const isOpenPopover = Boolean(anchorEl);
  const idPopover = isOpenPopover ? 'simple-popover' : undefined;

  const userFirstName = profile?.firstName || '';
  const userLastName = profile?.lastName || '';
  const userLogo = profile?.profileAvatar?.url || '';
  const userEmail = profile.email || '';

  return (
    <>
      <div onClick={handleOpenPopover}>
        <Avatar
          className={'border-0'}
          size={'32'}
          sx={{ width: '40px', height: '40px', border: 'none', margin: '0 0 0 12px' }}
          src={userLogo}
        >
          {userFirstName?.slice(0, 1)}
          {userLastName?.slice(0, 1)}
        </Avatar>
      </div>

      <Popover
        id={idPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          top: '16px',
          '.MuiPopover-paper': {
            width: '300px'
          }
        }}
        open={isOpenPopover}
        onClose={handleClosePopover}
      >
        <Box sx={{ padding: '4px 0', margin: '0 0 8px' }}>
          <UserDetails
            className={'m-0'}
            variant={'short'}
            firstName={userFirstName}
            lastName={userLastName}
            email={userEmail}
            logo={userLogo}
            isShowEmail
          />

          <Link
            to={LINKS_PAGES.profile}
            css={{ padding: '0 0 0 72px', display: 'inline-flex' }}
            onClick={handleClosePopover}
          >
            <Text className={'mb-0'} variant={'labelMd'}>
              {tGlobal('header.btn.goToProfile')}
            </Text>
          </Link>
        </Box>

        {isAuthenticated ? (
          <Button
            className={'m-0'}
            sx={{
              background: 'var(--white) !important',
              width: '100%',
              justifyContent: 'flex-start',
              borderRadius: 'var(--4, 4px) !important',
              boxShadow: '0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)',
              fontSize: '14px !important'
            }}
            startIcon={<LogOutIcon />}
            onClick={handleLogOut}
          >
            {tGlobal('header.btn.signOut')}
          </Button>
        ) : null}
      </Popover>
    </>
  );
};
