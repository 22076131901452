import { i18n } from '@/i18n';

export enum AssessTypeEnum {
  canView = 'READ',
  canEdit = 'CREATE_UPDATE',
  restrictAccess = 'DENY'
}

export const permissionOptions = [
  {
    label: i18n.t('actions.modal.manageAccess.permissionsMenu.read', { ns: 'company' }),
    value: AssessTypeEnum.canView
  },
  {
    label: i18n.t('actions.modal.manageAccess.permissionsMenu.create_update', { ns: 'company' }),
    value: AssessTypeEnum.canEdit
  },
  {
    label: i18n.t('actions.modal.manageAccess.permissionsMenu.deny', { ns: 'company' }),
    value: AssessTypeEnum.restrictAccess
  }
];

export const createMockedMenuList = (accessType: string, id: string) => [
  {
    id: 0,
    title: i18n.t('actions.modal.manageAccess.permissionsMenu.read', { ns: 'company' }),
    isDefaultValue: accessType === AssessTypeEnum.canView,
    userId: id,
    value: [AssessTypeEnum.canView]
  },
  {
    id: 1,
    title: i18n.t('actions.modal.manageAccess.permissionsMenu.create_update', { ns: 'company' }),
    isDefaultValue: accessType === AssessTypeEnum.canEdit,
    userId: id,
    value: [AssessTypeEnum.canEdit]
  },
  {
    id: 2,
    title: i18n.t('actions.modal.manageAccess.permissionsMenu.deny', { ns: 'company' }),
    isDefaultValue: accessType === AssessTypeEnum.restrictAccess,
    userId: id,
    value: [AssessTypeEnum.restrictAccess]
  }
];
