const isConsoleDevMode = localStorage.getItem('is_dev_mode');

export const hideConsoleWarningDefaultProps = () => {
  const error = console.error;

  console.error = (...args: any) => {
    if (/defaultProps/.test(args[0])) return;
    if (/{value.toString()/.test(args[0])) return;
    if (/cannot appear as a child/.test(args[0]) && /table/.test(args[2])) return;
    if (
      /You have illegal escape sequence in your template literal, most likely inside content's property value/.test(
        args[0]
      )
    )
      return;
    error(...args);
  };
};

hideConsoleWarningDefaultProps();

export const hideConsoleLogFromProdStage = () => {
  const log = console.log;

  console.log = (...args: any) => {
    if (isConsoleDevMode !== 'true') return;

    log(...args);
  };
};

hideConsoleLogFromProdStage();
