import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const FiltersContainerWrp = styled(Box)(({ withSearch, theme }) => ({
  marginBottom: '16px',
  '.MuiCollapse-root-filters': {
    '.MuiCollapse-wrapperInner-filters': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap'
      },
      '& > .MuiBox-root': {
        flex: '0 1 100%',
        [theme.breakpoints.down('lg')]: {
          maxWidth: '100% !important'
        },
        '& > *': {
          alignSelf: 'flex-start'
        },
        '&:first-child': {
          maxWidth: withSearch ? '60%' : '100%',
          display: 'flex',
          flexWrap: 'wrap',
          button: {
            margin: '0 8px 8px 0'
          },
          [theme.breakpoints.down('lg')]: {
            order: 2,
            margin: '8px 0 0 0'
          }
        },
        '&:nth-child(2)': {
          display: 'flex',
          maxWidth: withSearch ? '40%' : '10%',
          '& > *:not(:last-child)': {
            margin: '0 8px 8px 0'
          }
        }
      }
    }
  }
}));
