import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AlertCheckNoticeIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#059669', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M8.81721 11.7885L6.88129 9.8526C6.76592 9.73722 6.62089 9.67819 6.44621 9.67552C6.27153 9.67284 6.12383 9.73187 6.00311 9.8526C5.88238 9.97333 5.82202 10.1197 5.82202 10.2917C5.82202 10.4637 5.88238 10.61 6.00311 10.7308L8.28996 13.0176C8.4406 13.1683 8.61635 13.2436 8.81721 13.2436C9.01806 13.2436 9.1938 13.1683 9.34444 13.0176L13.9806 8.38142C14.096 8.26604 14.1551 8.12101 14.1577 7.94633C14.1604 7.77165 14.1014 7.62395 13.9806 7.50323C13.8599 7.38251 13.7136 7.32215 13.5416 7.32215C13.3696 7.32215 13.2232 7.38251 13.1025 7.50323L8.81721 11.7885ZM10.0013 17.9167C8.90635 17.9167 7.87715 17.7089 6.91371 17.2933C5.95025 16.8778 5.11218 16.3138 4.3995 15.6015C3.68681 14.8891 3.12259 14.0514 2.70686 13.0884C2.29112 12.1253 2.08325 11.0964 2.08325 10.0014C2.08325 8.90647 2.29103 7.87728 2.70659 6.91383C3.12214 5.95037 3.6861 5.11231 4.39846 4.39963C5.11084 3.68693 5.94854 3.12272 6.91156 2.70698C7.87458 2.29124 8.90356 2.08337 9.9985 2.08337C11.0934 2.08337 12.1226 2.29115 13.0861 2.70671C14.0495 3.12226 14.8876 3.68622 15.6003 4.39858C16.313 5.11096 16.8772 5.94866 17.2929 6.91169C17.7087 7.8747 17.9165 8.90368 17.9165 9.99863C17.9165 11.0936 17.7088 12.1228 17.2932 13.0862C16.8777 14.0497 16.3137 14.8877 15.6013 15.6004C14.889 16.3131 14.0513 16.8773 13.0882 17.2931C12.1252 17.7088 11.0962 17.9167 10.0013 17.9167Z" />
    </SvgIcon>
  );
}
