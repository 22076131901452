import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, Grid } from '@mui/material';

import {
  EquipmentQuery,
  GET_EQUIPMENT,
  GET_EQUIPMENT_ROUND_REGULATION,
  GET_USER_PROFILE,
  GetProfileQuery,
  RoundRegulationQuery
} from '@/logicLayers/domain';

import {
  Breadcrumbs,
  ChevronRightIcon,
  EMPTY_CELL_VALUE,
  LowPriorityIcon,
  PlusIcon,
  PreviewIcon
} from '@/separatedModules/components';
import { EditIcon, EditItemHover, SystemStatusChip, SystemStatusesEnum } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ParameterItem } from './components';
import { ActionMenu } from './components';

export const TemplatesEquipmentItemRoundsRoundRegulationPage = () => {
  const { templateId = '', parameterId = '' } = useParams();
  const { t: tTemplates } = useTranslation('templates');
  const navigateTo = useNavigate();
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');

  const TABLE_SAVED_COLUMNS_ID = `/templates/equipment/item/${templateId}/rounds/round-regulations`;

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { data: dataEquipmentTemplateRoundRegulation, loading } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId: parameterId || undefined
      }
    }
  );

  const handleEditItem = (toPage: string) => {
    const pathname = LINKS_PAGES.templatesEquipmentEditItemRoundsRoundRegulations
      .replace(':templateId', templateId)
      .replace(':roundRegulationId', parameterId);

    navigateTo({
      pathname,
      search: `field=${toPage}`
    });
  };

  const handleEditTitle = () => handleEditItem('roundTitle');
  const handleEditFrequency = () => handleEditItem('roundFrequency');
  const handleEditDate = () => handleEditItem('roundDate');
  const handleEditDescription = () => handleEditItem('roundDescription');
  const handleEditStaff = () => handleEditItem('roundStaff');
  const handleEditParameters = () => handleEditItem('roundParameters');

  const renderItemName = dataEquipmentTemplateRoundRegulation?.dataItems?.name;
  const renderItemActive = Boolean(dataEquipmentTemplateRoundRegulation?.dataItems?.active);
  const renderItemTemplateDescription = dataEquipmentTemplateRoundRegulation?.dataItems?.description;
  const renderItemCreatedDate = dataEquipmentTemplateRoundRegulation?.dataItems?.createdDate;
  const renderItemActivationTargetDate = dataEquipmentTemplateRoundRegulation?.dataItems?.activationTargetDate;
  const renderItemFrequency = dataEquipmentTemplateRoundRegulation?.dataItems?.frequency?.frequency;
  const renderItemServiceStuff = dataEquipmentTemplateRoundRegulation?.dataItems?.serviceStaff;

  const breadcrumbsTemplateLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    {
      link: TABLE_SAVED_COLUMNS_ID.replace('/rounds/round-regulations', ''),
      titleFallback: dataEquipmentTemplate?.equipment.name
    },
    { link: TABLE_SAVED_COLUMNS_ID, titleFallback: 'Round regulations' },
    { titleFallback: renderItemName }
  ];

  const breadcrumbsEquipmentLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemAllLocations') },
    {
      link: LINKS_PAGES.equipmentModel.replace(':templateId', templateId),
      titleFallback: dataEquipmentTemplate?.equipment?.name
    },
    {
      link: LINKS_PAGES.equipmentItemRoundRegulations.replace(':templateId', templateId),
      titleFallback: tTemplates('equipment.pageItemRoundRegulations')
    },
    { titleFallback: renderItemName }
  ];

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsTemplateLinks : breadcrumbsEquipmentLinks;

  if (loading) return null;

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} sm={10} md={10} item>
        <Box>
          <Breadcrumbs
            links={breadcrumbs}
            separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
            sx={{ paddingTop: 0 }}
          />
          <Text component={'h2'} variant={'titleXl'}>
            {renderItemName}
          </Text>
        </Box>

        <Box
          sx={{
            flex: '0 1 100%',
            marginTop: '32px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderItemName ? (
                <EditItemHover isDisabled={!isShowEditItem || !isUserCanCRUD} onClick={handleEditTitle}>
                  <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
                    {renderItemName}
                  </Text>
                </EditItemHover>
              ) : null}

              <SystemStatusChip status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              {!isEquipmentTemplate && (
                <>
                  <Button variant="outlined" startIcon={<PreviewIcon />}>
                    {tTemplates('equipmentItem.roundRegulationsDetails.roundFormBtn')}
                  </Button>
                  <Button
                    variant={'contained'}
                    startIcon={<PlusIcon />}
                    disabled={!isUserCanCRUD}
                    onClick={() =>
                      navigateTo(
                        LINKS_PAGES.templatesTaskByRoundCreate
                          .replace(':templateId', templateId)
                          .replace(':roundId', parameterId)
                      )
                    }
                  >
                    {tTemplates('equipmentItem.roundRegulationsDetails.createTaskBtn')}
                  </Button>
                </>
              )}
              {isShowEditItem && isUserCanCRUD && (
                <ActionMenu
                  data={dataEquipmentTemplateRoundRegulation}
                  renderModalContent={() => {
                    return (
                      <Box
                        sx={{
                          borderRadius: 'var(--4, 4px)',
                          background: 'var(--background-neutral-hovered, #F7F8F9)',
                          margin: '24px 0px',
                          height: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '8px'
                        }}
                      >
                        <Text variant={'labelLg'} m={0}>
                          {renderItemName}
                        </Text>
                      </Box>
                    );
                  }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', marginTop: '8px' }}>
            <EditItemHover isDisabled={!isShowEditItem || !isUserCanCRUD} onClick={handleEditDescription}>
              <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
                {renderItemTemplateDescription?.length ? renderItemTemplateDescription : EMPTY_CELL_VALUE}
              </Text>
            </EditItemHover>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.startDateTime')}
            </Text>
            <EditItemHover isDisabled={!isShowEditItem || !isUserCanCRUD} onClick={handleEditDate}>
              <Text className={'m-0'} variant={'bodyLg'}>
                {dayjs(renderItemActivationTargetDate).format('DD MMM YYYY, HH:mm A')}
              </Text>
            </EditItemHover>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.frequency')}
            </Text>
            <EditItemHover isDisabled={!isShowEditItem || !isUserCanCRUD} onClick={handleEditFrequency}>
              <Text className={'m-0'} variant={'bodyLg'}>
                {renderItemFrequency ? renderItemFrequency : EMPTY_CELL_VALUE}
              </Text>
            </EditItemHover>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.serviceStuff')}
            </Text>
            <EditItemHover isDisabled={!isShowEditItem || !isUserCanCRUD} onClick={handleEditStaff}>
              <Box>
                {renderItemServiceStuff?.map((item, index) => {
                  return (
                    <Box key={index} sx={{ display: 'flex', marginBottom: '8px' }}>
                      {item?.userAvatar ? (
                        <img
                          src={item.userAvatar?.url}
                          alt=""
                          width={24}
                          height={24}
                          style={{
                            borderRadius: '50%',
                            border: '1px solid #F7F8F9',
                            position: 'relative',
                            margin: '0 8px 0 0'
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            flex: '0 0 24px',
                            borderRadius: '50%',
                            margin: '0 8px 0 0',
                            background: 'rgba(9, 14, 22, 0.06)',
                            border: '1px solid var(--border-inverse, #F7F8F9)'
                          }}
                        />
                      )}
                      <Text>
                        {!item?.firstName && !item?.lastName
                          ? EMPTY_CELL_VALUE
                          : `${item?.firstName} ${item?.lastName}`}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </EditItemHover>
          </Box>
          <Box sx={{ marginTop: '32px' }}>
            <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.creationDate')}
            </Text>
            <Text className={'m-0'} variant={'bodyLg'}>
              {dayjs(renderItemCreatedDate).format('DD MMM YYYY, HH:mm A')}
            </Text>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '48px',
              marginBottom: '24px',
              alignItems: 'center'
            }}
          >
            <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.parameters')}
            </Text>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Button size={'medium'} startIcon={<LowPriorityIcon sx={{ fontSize: 20 }} />}>
                {tTemplates('equipmentItem.roundRegulationsDetails.manageOrderBtn')}
              </Button>
              {isUserCanCRUD && (
                <Button size={'medium'} onClick={handleEditParameters} startIcon={<EditIcon sx={{ fontSize: 20 }} />}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.editBtn')}
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {dataEquipmentTemplateRoundRegulation?.dataItems?.parameters.map((item) => {
              return <ParameterItem item={item} key={item.id} />;
            })}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
