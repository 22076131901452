import { Text } from 'infinitytechnologies-ui';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { CONFIG_TABLE } from '@/separatedModules/pages/Templates/pages/Domains/helpers';

export const TableHeader = () => (
  <TableHead>
    <TableRow>
      {CONFIG_TABLE.cells.map(({ columnName, title }, index) => {
        return (
          <TableCell key={columnName} className={CONFIG_TABLE.cells[index].columnClass}>
            <Text variant={'labelMd'}>{title}</Text>
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);
