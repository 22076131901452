import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import Stack from '@mui/material/Stack';

import { GetPresignedUploadUrlQuery } from '@/logicLayers/domain';
import { GET_IMAGE_URL } from '@/logicLayers/domain/global';

import {
  AvatarImagePreviewButton,
  ImageUploader,
  TextFieldControl,
  UploaderButtonLg
} from '@/separatedModules/components';

import { TEXT_FIELD_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_EQUIPMENT_ITEM_DESCRIPTION, FIELD_EQUIPMENT_MANUFACTURER } from '../../../helpers';

export const Step3: FC = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tEquipment } = useTranslation('equipment');

  const [state, setState] = useState({
    fileKey: '',
    fileUrl: ''
  });

  const { refetch } = useQuery<GetPresignedUploadUrlQuery>(GET_IMAGE_URL, {
    fetchPolicy: 'no-cache',
    skip: true
  });

  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const handleSetImage = async (fileKey: string) => {
    setValue('fileKey', fileKey);

    const result = await refetch({ fileKey: fileKey }).then(({ data }) => data);

    // @ts-ignore
    if (result?.fileUrl?.fileKey) {
      // @ts-ignore
      setState((state) => ({ ...state, fileUrl: result?.fileUrl?.url, fileKey: result?.fileUrl?.fileKey }));
    }
  };

  return (
    <Stack minHeight={'60vh'}>
      <Controller
        name={FIELD_EQUIPMENT_MANUFACTURER}
        control={control}
        rules={{
          pattern: {
            value: TEXT_FIELD_REG_EXP,
            message: tGlobal('validation.textFieldLength')
          },
          minLength: {
            value: 3,
            message: tGlobal('validation.textFieldLength')
          },
          maxLength: {
            value: 64,
            message: tGlobal('validation.textFieldLength')
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <TextFieldControl
              name={FIELD_EQUIPMENT_MANUFACTURER}
              title={tEquipment('createEquipmentItem.step3.manufacturerTitle')}
              subTitle={tEquipment('createEquipmentItem.step3.manufacturerSubTitle')}
              label={tEquipment('createEquipmentItem.step3.manufacturerLabel')}
              placeholder={tEquipment('createEquipmentItem.step3.manufacturerPlaceholder')}
              value={value}
              validation={{
                isValid: !errors[FIELD_EQUIPMENT_MANUFACTURER]?.message,
                error: String(errors[FIELD_EQUIPMENT_MANUFACTURER]?.message)
              }}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />

      <Controller
        name={FIELD_EQUIPMENT_ITEM_DESCRIPTION}
        control={control}
        rules={{
          pattern: {
            value: /^[\w\s`'.\-,#№()_]{3,200}$/,
            message: tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <TextFieldControl
              name={FIELD_EQUIPMENT_ITEM_DESCRIPTION}
              title={tEquipment('createEquipmentItem.step3.itemDescriptionTitle')}
              subTitle={tEquipment('createEquipmentItem.step3.itemDescriptionSubTitle')}
              label={tEquipment('createEquipmentItem.step3.itemDescriptionLabel')}
              placeholder={tEquipment('createEquipmentItem.step3.itemDescriptionPlaceholder')}
              rows={3}
              maxContentLength={200}
              value={value}
              validation={{
                isValid: !errors[FIELD_EQUIPMENT_ITEM_DESCRIPTION]?.message,
                error: String(errors[FIELD_EQUIPMENT_ITEM_DESCRIPTION]?.message)
              }}
              multiline
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />

      <ImageUploader
        path={'EQUIPMENT_PHOTO'}
        imgAlt={tEquipment('createEquipmentItem.step3.imageUploader.alt')}
        oldImageUrl={state.fileUrl}
        oldImageFileKey={state.fileKey}
        modalTitle={tEquipment('createEquipmentItem.step3.imageUploader.title')}
        modalTitleToDelete={tEquipment('createEquipmentItem.step3.imageUploader.titleToDelete')}
        modalSubTitle={tEquipment('createEquipmentItem.step3.imageUploader.subTitle')}
        btnUploadPhotoText={tGlobal('imageUploader.btnUploadPhoto')}
        allowedExtensionsText={''}
        minDimensionsText={'72px x 72px'}
        maxDimensionsText={''}
        maxFileSizeText={'5'}
        imageMinWidth={72}
        imageMinHeight={72}
        imageMaxSize={5242880} // Bites
        imageAllowedExtensions={['png', 'jpeg', 'jpg', 'svg']}
        imageRequired
        withPreview
        isVisibleDeleteBtn={false}
        titleContent={
          <Text className={'m-0'} variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)', textAlign: 'center' }}>
            {tEquipment('createEquipmentItem.step3.imageUploader.allowedExtensionsText')}{' '}
            {tEquipment('createEquipmentItem.step3.imageUploader.titleContent')}
          </Text>
        }
        modalContent={
          <AvatarImagePreviewButton
            imgAlt={''}
            sxBox={{
              margin: '0 auto 24px'
            }}
            sxImageWrap={{ width: 180, height: 180, flex: '0 0 180px', maxWidth: 180 }}
            sxImage={{ fontSize: 96 }}
          />
        }
        renderUploaderButton={({ imgAlt, oldImage, handleOpenModalPhoto }) => {
          return (
            <UploaderButtonLg
              title={tEquipment('createEquipmentItem.step3.imageUploader.uploaderButton.title')}
              subTitle={
                <ul style={{ color: 'var(--text-subtle, #505668)' }}>
                  <li>{tEquipment('createEquipmentItem.step3.imageUploader.uploaderButton.liFirst')}</li>
                  <li>{tEquipment('createEquipmentItem.step3.imageUploader.uploaderButton.liSecond')}</li>
                </ul>
              }
              btnUploadPhotoText={tGlobal('imageUploader.btnUploadPhoto')}
              imgAlt={imgAlt}
              oldImage={oldImage}
              handleOpenModalPhoto={handleOpenModalPhoto}
            />
          );
        }}
        onImageUploadSuccess={(imageKey: string) => {
          handleSetImage(imageKey);
        }}
        onImageDelete={() => {}}
        onImageUploadError={() => {
          // ToDo Remove
          console.log('ImageUploader handleSaveImage uploadedResult error');
        }}
      />
    </Stack>
  );
};
