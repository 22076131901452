import { FC, MouseEvent, ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import { ArchiveIcon, BaseMenuItem, EditIcon, MoreVertIcon, StarOutlineIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { EDIT_BUTTON_CLASS, EditDomainMenuListNamesEnum, menuList } from './helpers';

interface EditMenuProps {
  id?: string;
  onOpenModal: () => void;
}

export const EditDomainButton: FC<EditMenuProps> = ({ id, onOpenModal }) => {
  const { t: tTemplates } = useTranslation('templates');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuItemClick = (itemName: string) => {
    // ToDo Remove
    console.log('domain id', id);

    switch (true) {
      case EditDomainMenuListNamesEnum.EDIT === itemName:
        onOpenModal();
    }
  };

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuIconChecker = (name: string): ReactNode => {
    switch (true) {
      case name === EditDomainMenuListNamesEnum.EDIT:
        return <EditIcon />;
      case name === EditDomainMenuListNamesEnum.FAVORITES:
        return <StarOutlineIcon />;
      case name === EditDomainMenuListNamesEnum.ARCHIVE:
        return <ArchiveIcon />;
      default:
        return <></>;
    }
  };

  return (
    <Box
      component={'td'}
      display={'flex'}
      alignItems={'center'}
      mr={'8px'}
      position={'absolute'}
      right={0}
      top={'50%'}
      sx={{ transform: 'translateY(-50%)' }}
    >
      <IconButton
        className={EDIT_BUTTON_CLASS}
        size={'small'}
        sx={{ color: '#313849', visibility: open ? 'visible' : 'hidden' }}
        onClick={handleClickButton}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'role-management-edit-domain-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'role-management-edit-domain-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ margin: '12px 0 0 0' }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {menuList.map((item, index) => (
          <BaseMenuItem
            disabled={item.disabled}
            key={item.id}
            hasDivider={menuList.length - 2 === index}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
              handleMenuItemClick(item.name);
            }}
          >
            {menuIconChecker(item.name)}

            {tTemplates(`domains.table.edit.menuList.${item.name}`)}
          </BaseMenuItem>
        ))}
      </Menu>
    </Box>
  );
};
