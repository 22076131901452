import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ScheduleIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M10.6246 9.74669V6.45821C10.6246 6.28114 10.5647 6.13271 10.4449 6.01292C10.325 5.89313 10.1765 5.83323 9.99938 5.83323C9.82222 5.83323 9.67381 5.89313 9.55415 6.01292C9.4345 6.13271 9.37468 6.28114 9.37468 6.45821V9.939C9.37468 10.0371 9.39283 10.1321 9.42915 10.2241C9.46547 10.3161 9.52263 10.4011 9.60063 10.4791L12.4548 13.3332C12.5702 13.4486 12.7152 13.5076 12.8899 13.5103C13.0646 13.513 13.2123 13.454 13.333 13.3332C13.4537 13.2125 13.5141 13.0661 13.5141 12.8941C13.5141 12.7221 13.4537 12.5758 13.333 12.455L10.6246 9.74669ZM10.001 17.9165C8.9061 17.9165 7.87691 17.7088 6.91347 17.2932C5.95001 16.8777 5.11194 16.3137 4.39926 15.6013C3.68656 14.889 3.12235 14.0513 2.70661 13.0882C2.29088 12.1252 2.08301 11.0962 2.08301 10.0013C2.08301 8.90635 2.29079 7.87716 2.70634 6.91371C3.1219 5.95025 3.68586 5.11218 4.39822 4.3995C5.11059 3.68681 5.94829 3.12259 6.91132 2.70686C7.87433 2.29112 8.90331 2.08325 9.99826 2.08325C11.0932 2.08325 12.1224 2.29103 13.0858 2.70659C14.0493 3.12214 14.8874 3.6861 15.6 4.39846C16.3127 5.11084 16.877 5.94854 17.2927 6.91157C17.7084 7.87458 17.9163 8.90356 17.9163 9.9985C17.9163 11.0934 17.7085 12.1226 17.293 13.0861C16.8774 14.0495 16.3135 14.8876 15.6011 15.6003C14.8887 16.313 14.051 16.8772 13.088 17.2929C12.125 17.7087 11.096 17.9165 10.001 17.9165ZM9.99965 16.6666C11.8469 16.6666 13.4198 16.0173 14.7184 14.7186C16.017 13.42 16.6663 11.8471 16.6663 9.9999C16.6663 8.15268 16.017 6.57976 14.7184 5.28115C13.4198 3.98254 11.8469 3.33323 9.99965 3.33323C8.15243 3.33323 6.57951 3.98254 5.2809 5.28115C3.98229 6.57976 3.33299 8.15268 3.33299 9.9999C3.33299 11.8471 3.98229 13.42 5.2809 14.7186C6.57951 16.0173 8.15243 16.6666 9.99965 16.6666Z" />
    </SvgIcon>
  );
}
