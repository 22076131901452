import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AlertCloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'24'} height={'24'} viewBox={'0 0 24 24'} {...props}>
      <path
        d="M12 12.8305L8.0018 16.8287C7.89268 16.9379 7.75552 16.9937 7.59032 16.9962C7.42513 16.9987 7.28545 16.9429 7.17128 16.8287C7.05709 16.7146 7 16.5761 7 16.4135C7 16.2508 7.05709 16.1124 7.17128 15.9982L11.1695 12L7.17128 8.0018C7.06215 7.89268 7.00632 7.75552 7.0038 7.59032C7.00127 7.42513 7.05709 7.28545 7.17128 7.17128C7.28545 7.05709 7.42387 7 7.58654 7C7.74921 7 7.88762 7.05709 8.0018 7.17128L12 11.1695L15.9982 7.17128C16.1073 7.06215 16.2445 7.00632 16.4097 7.0038C16.5749 7.00127 16.7146 7.05709 16.8287 7.17128C16.9429 7.28545 17 7.42387 17 7.58654C17 7.7492 16.9429 7.88762 16.8287 8.0018L12.8305 12L16.8287 15.9982C16.9379 16.1073 16.9937 16.2445 16.9962 16.4097C16.9987 16.5749 16.9429 16.7146 16.8287 16.8287C16.7146 16.9429 16.5761 17 16.4135 17C16.2508 17 16.1124 16.9429 15.9982 16.8287L12 12.8305Z"
        fill="#313849"
      />
    </SvgIcon>
  );
}
