import { PermissionRes } from '@/logicLayers/domain';

import { i18n } from '@/i18n';

export enum PermissionEnum {
  VIEWING = 'VIEWING',
  UPDATING = 'UPDATING',
  DELETING = 'DELETING'
}

export interface PermissionsGroup {
  label: string;
  value: string;
  resource: string;
  operation: string;
}

export type GroupOfPermissions = { [groupName: string]: PermissionRes[] };

export const createMockedMenuList = (
  groupName: string,
  group: PermissionsGroup[],
  initialPermissionsGroup: GroupOfPermissions
) => {
  let canEditByDefault = false;
  let canViewByDefault = false;

  Object.entries(initialPermissionsGroup).forEach(([initGroupName, value]) => {
    if (initGroupName === groupName) {
      canEditByDefault = value.some((item) => item.operation === PermissionEnum.UPDATING);
      canViewByDefault = value.some((item) => item.operation === PermissionEnum.VIEWING);
    }
  });

  const onlyViewingItem = group
    .filter((filterItem) => filterItem.operation === PermissionEnum.VIEWING)
    ?.map((editingItem) => editingItem.value);
  const editingItems = group.map((editingItem) => editingItem.value);

  return [
    {
      id: 0,
      title: i18n.t('createRole.mainInfo.permissionsMenu.canView', { ns: 'templates' }),
      groupName,
      isDefaultValue: canViewByDefault && !canEditByDefault,
      value: onlyViewingItem
    },
    {
      id: 1,
      title: i18n.t('createRole.mainInfo.permissionsMenu.canEdit', { ns: 'templates' }),
      groupName,
      isDefaultValue: canEditByDefault,
      value: editingItems
    },
    {
      id: 2,
      title: i18n.t('createRole.mainInfo.permissionsMenu.restrict', { ns: 'templates' }),
      groupName,
      isDefaultValue: !canEditByDefault && !canViewByDefault,
      value: []
    }
  ];
};

export const FIELD_ROLE = 'name';
export const FIELD_DESCRIPTION = 'description';
export const FIELD_DOMAIN_IDS = 'domainIds';
export const FIELD_PERMISSIONS = 'permissions';
