import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { EditDetails, EditRoleActionType } from '../pages';

const editTypes = [EditRoleActionType.Name, EditRoleActionType.Description];

export const TemplatesRolesRoleDetailsEditPage: FC = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const navigateTo = useNavigate();

  const type = searchParams.get('q') as EditRoleActionType;

  useEffect(() => {
    if (!type || !editTypes.includes(type)) {
      navigateTo(-1);
    } else {
      setIsLoading(false);
    }
  }, []);

  return isLoading || !type ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
  ) : (
    <Grid className={'row'} justifyContent={'center'} container row>
      <Grid xs={12} sm={8} lg={6} item>
        <EditDetails type={type} />
      </Grid>
    </Grid>
  );
};
