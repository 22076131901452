import { ReactNode } from 'react';

import {
  ArchiveIcon,
  BadgeIcon,
  CheckListIcon,
  DescriptionIcon,
  DomainIcon,
  EquipmentWheelsIcon,
  FolderIcon,
  FolderOpenIcon,
  FormatListBulletedIcon,
  HistoryIcon,
  HomeIcon,
  ListAltIcon,
  LockIcon,
  ManageAccountsIcon,
  PersonCheckIcon,
  PlusIcon,
  RoundIcon,
  ScheduleIcon,
  SettingsIcon,
  StarOutlineIcon
} from '@/separatedModules/components';
import { AccomplishedTasks } from '@/separatedModules/components/Icon/svg-mui-theme/AccomplishedTasks';
import { ScheduledTasks } from '@/separatedModules/components/Icon/svg-mui-theme/ScheduledTasks';

import { LINKS_PAGES } from './links';

export type LinksSideBarT = {
  order: number;
  link: string;
  title: string;
  isHidden: boolean;
  isDisabled: boolean;
  icon: ReactNode;
};

export type LinksSideBarWithButtonT = LinksSideBarT & {
  btnIcon?: ReactNode;
  btnLinkRedirect?: string;
  btnTooltip?: string;
};

export const LINKS_SIDEBAR_TEMPLATES_TOP_MENU = (
  isUserSuperAdmin: boolean,
  isUserCompanyAdmin: boolean
): LinksSideBarWithButtonT[] => [
  {
    order: 101,
    link: LINKS_PAGES.templatesEquipment,
    title: 'templates.equipment.title',
    icon: <EquipmentWheelsIcon />,
    btnIcon: <PlusIcon />,
    btnLinkRedirect: LINKS_PAGES.templatesEquipmentCreate,
    btnTooltip: 'templates.equipment.btnTooltip',
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.templatesRoles,
    title: 'templates.role',
    icon: <BadgeIcon />,
    isHidden: isUserSuperAdmin || isUserCompanyAdmin,
    isDisabled: false
  },
  {
    order: 103,
    link: LINKS_PAGES.templatesReports,
    title: 'templates.report',
    icon: <DescriptionIcon />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 104,
    link: LINKS_PAGES.templatesDomains,
    title: 'templates.domain',
    icon: <DomainIcon />,
    isHidden: false,
    isDisabled: false
  }
];

export const LINKS_SIDEBAR_TASKS_TOP_MENU: LinksSideBarWithButtonT[] = [
  {
    order: 101,
    link: LINKS_PAGES.tasks,
    title: 'tasks.scheduledTasks.title',
    icon: <ScheduledTasks />,
    btnIcon: <PlusIcon />,
    btnLinkRedirect: LINKS_PAGES.tasksCreate,
    btnTooltip: 'tasks.scheduledTasks.btnTooltip',
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.tasksAccomplished,
    title: 'tasks.accomplishedTasksTitle',
    icon: <AccomplishedTasks />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 103,
    link: LINKS_PAGES.tasksCustom,
    title: 'tasks.customTasks.title',
    icon: <FolderIcon />,
    btnIcon: <PlusIcon />,
    btnLinkRedirect: LINKS_PAGES.tasksCreate,
    btnTooltip: 'tasks.customTasks.btnTooltip',
    isHidden: false,
    isDisabled: false
  }
];

export const LINKS_SIDEBAR_TEMPLATES_EQUIPMENT_TOP_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.templatesEquipmentItem,
    title: 'templates.equipmentItem.overview',
    icon: <HomeIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.templatesEquipmentItemDetails,
    title: 'templates.equipmentItem.templateDetails',
    icon: <ListAltIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 103,
    link: LINKS_PAGES.templatesEquipmentItemItems,
    title: 'templates.equipmentItem.items',
    icon: <FormatListBulletedIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 104,
    link: LINKS_PAGES.templatesEquipmentItemDocuments,
    title: 'templates.equipmentItem.documents',
    icon: <FolderOpenIcon />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_EQUIPMENT_TOP_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.equipmentModel,
    title: 'templates.equipmentItem.overview',
    icon: <HomeIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.templatesEquipmentModelDetails,
    title: 'templates.equipmentItem.itemDetails',
    icon: <ListAltIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 103,
    link: LINKS_PAGES.templatesEquipmentItemDocuments,
    title: 'templates.equipmentItem.documents',
    icon: <FolderOpenIcon />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_TEMPLATES_EQUIPMENT_BOTTOM_MENU: LinksSideBarWithButtonT[] = [
  {
    order: 101,
    link: LINKS_PAGES.templatesEquipmentItemParameters,
    title: 'templates.equipmentItem.roundParameters',
    icon: <CheckListIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.templatesEquipmentItemRoundsRoundRegulations,
    title: 'templates.equipmentItem.roundRegulations',
    icon: <RoundIcon />,
    isHidden: false,
    isDisabled: false,
    btnIcon: <PlusIcon />,
    btnLinkRedirect: LINKS_PAGES.templatesRoundRegulationCreate,
    btnTooltip: 'Create round regulation template'
  }
];

export const LINKS_SIDEBAR_EQUIPMENT_BOTTOM_MENU: LinksSideBarWithButtonT[] = [
  {
    order: 101,
    link: LINKS_PAGES.equipmentItemParameters,
    title: 'equipment.roundParameters',
    icon: <CheckListIcon />,
    isHidden: false,
    isDisabled: false,
    btnIcon: <PlusIcon />,
    btnLinkRedirect: LINKS_PAGES.templatesParametersCreate,
    btnTooltip: 'Create parameter'
  },
  {
    order: 102,
    link: LINKS_PAGES.equipmentItemRoundRegulations,
    title: 'equipment.roundRegulations',
    icon: <RoundIcon />,
    isHidden: false,
    isDisabled: false,
    btnIcon: <PlusIcon />,
    btnLinkRedirect: LINKS_PAGES.templatesRoundRegulationCreate,
    btnTooltip: 'Create round regulation'
  },
  {
    order: 103,
    link: LINKS_PAGES.equipmentItemTasks,
    title: 'equipment.tasks',
    icon: <HistoryIcon />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_TEMPLATES_BOTTOM_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.templatesRecent,
    title: 'templates.recent',
    icon: <ScheduleIcon />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 102,
    link: LINKS_PAGES.templatesStarred,
    title: 'templates.favorites',
    icon: <StarOutlineIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 103,
    link: LINKS_PAGES.templatesArchive,
    title: 'templates.archive',
    icon: <ArchiveIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_TEMPLATE_DETAILS_MENU = (uid: string | null): LinksSideBarT[] => [
  {
    order: 101,
    link: LINKS_PAGES.templatesRolesRoleDetails + '?uid=' + uid,
    title: 'templates.details.details',
    icon: <ListAltIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.templatesRolesRolePermissions + '?uid=' + uid,
    title: 'templates.details.permissions',
    icon: <ManageAccountsIcon />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_PROFILE_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.profile,
    title: 'profile.overview',
    icon: <BadgeIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.profileSecurity,
    title: 'profile.profile',
    icon: <LockIcon />,
    isHidden: false,
    isDisabled: false
  }
];

export const LINKS_SIDEBAR_USER_PROFILE_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.userItem,
    title: 'user.profile',
    icon: <BadgeIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.userItemPermissions,
    title: 'user.permissions',
    icon: <ManageAccountsIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 103,
    link: LINKS_PAGES.userItemManagement,
    title: 'user.management',
    icon: <PersonCheckIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 104,
    link: LINKS_PAGES.userItemSecurity,
    title: 'user.security',
    icon: <LockIcon />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_COMPANY_I_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.companyItem,
    title: 'companyItem.overview',
    icon: <HomeIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.companyItemSettings,
    title: 'companyItem.settings',
    icon: <SettingsIcon />,
    isHidden: false,
    isDisabled: false
  }
];

export const LINKS_SIDEBAR_USERS_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.users,
    title: 'users.users',
    icon: <FormatListBulletedIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.usersRecent,
    title: 'users.recent',
    icon: <ScheduleIcon />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 103,
    link: LINKS_PAGES.usersStarred,
    title: 'users.starred',
    icon: <StarOutlineIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 104,
    link: LINKS_PAGES.usersArchive,
    title: 'users.archive',
    icon: <ArchiveIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_COMPANIES_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.companies,
    title: 'companies.companies',
    icon: <FormatListBulletedIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.companiesRecent,
    title: 'companies.recent',
    icon: <ScheduleIcon />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 103,
    link: LINKS_PAGES.companiesStarred,
    title: 'companies.starred',
    icon: <StarOutlineIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 104,
    link: LINKS_PAGES.companiesArchive,
    title: 'companies.archive',
    icon: <ArchiveIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  }
];

export const LINKS_SIDEBAR_LOCATIONS_MENU: LinksSideBarT[] = [
  {
    order: 101,
    link: LINKS_PAGES.locations,
    title: 'locations.equipment',
    icon: <FormatListBulletedIcon />,
    isHidden: false,
    isDisabled: false
  },
  {
    order: 102,
    link: LINKS_PAGES.locationsRecent,
    title: 'locations.recent',
    icon: <ScheduleIcon />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 103,
    link: LINKS_PAGES.locationsStarred,
    title: 'locations.starred',
    icon: <StarOutlineIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  },
  {
    order: 104,
    link: LINKS_PAGES.locationsArchive,
    title: 'locations.archive',
    icon: <ArchiveIcon sx={{ fontSize: 20 }} />,
    isHidden: false,
    isDisabled: true
  }
];
