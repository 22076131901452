import { gql as gqlApollo } from '@apollo/client';

export const GET_USER_DETAILED_PERMISSIONS = gqlApollo(`
  query GetDetailedPermissions {
    permissions {
      label: name
      value: name
      resource
      operation
    }
  }
`);
