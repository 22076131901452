import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Autocomplete, Button, Dialog } from 'infinitytechnologies-ui';

import MenuItem from '@mui/material/MenuItem';

import {
  CREATE_USER_FOR_COMPANY,
  CreateUserMutation,
  DomainDto,
  GET_COMPANIES,
  GET_ROLE_TEMPLATES,
  GET_USERS,
  GetCompaniesQuery,
  GetRoleTemplatesQuery,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteOptionT,
  AutocompleteTagsControl,
  ButtonLoading,
  Preloader,
  PreloaderVariantsE,
  SelectControl,
  TextFieldControl
} from '@/separatedModules/components';

import { EMAIL_REG_EXP } from '@/utils';

import { NullableT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

import {
  AddUserModalProps,
  FIELD_COMPANY_ID,
  FIELD_COMPANY_ROLE,
  FIELD_DOMAIN_IDS,
  FIELD_USER_EMAIL,
  formatSelectOptions
} from './helpers';

interface FormProps {
  [FIELD_USER_EMAIL]: string;
  [FIELD_COMPANY_ID]: { value: string };
  [FIELD_COMPANY_ROLE]: string;
  [FIELD_DOMAIN_IDS]: string[];
}

const FORM_ID = 'add-user-form';

export const AddUserModalAdmin = ({ isModalOpen, onCloseModal }: AddUserModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tErrors } = useTranslation('errors');
  const { t: tUsers } = useTranslation('users');
  const { t: tTemplates } = useTranslation('templates');
  const { t: tCompany } = useTranslation('company');

  const { profile, company } = useReduxSelector(selectUserData);

  const companyId = profile.companyId;
  const domainsCompany = company?.domains;

  const [selectedCompany, setSelectedCompany] = useState<NullableT<{ value: string }>>(null);

  const [selectedRoleCompanyId, setSelectedRoleCompanyId] = useState('');
  const [selectedRoleDomains, setSelectedRoleDomains] = useState<DomainDto[]>([]);

  const [resetKey, setResetKey] = useState(0);

  const { loading: loadingCompanies, data: dataCompanies } = useQuery<GetCompaniesQuery>(GET_COMPANIES, {
    fetchPolicy: 'no-cache',
    variables: {
      page: 0,
      pageSize: 1000,
      active: true
    }
  });

  const { data: dataRoleTemplates, loading: loadingRoleTemplates } = useQuery<GetRoleTemplatesQuery>(
    GET_ROLE_TEMPLATES,
    {
      skip: !companyId,
      fetchPolicy: 'no-cache',
      variables: {
        searchCriteria: {
          pageable: {
            page: 0,
            pageSize: 1000
          },
          companyId: selectedCompany?.value || undefined
        }
      }
    }
  );

  const {
    data: domainsData,
    refetch: refetchDomains,
    loading: loadingDomains
  } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    skip: !companyId,
    variables: {
      searchCriteria: {
        pageable: {
          page: 0,
          pageSize: 1000
        },
        companyId: selectedCompany?.value || undefined
      }
    },
    fetchPolicy: 'no-cache'
  });

  const [CreateUser, { loading: loadingCreateUser, data: dataCreateUser, reset: resetDataCreateUser }] =
    useMutation<CreateUserMutation>(CREATE_USER_FOR_COMPANY, {
      refetchQueries: [GET_USERS]
    });

  const {
    resetField,
    setValue,
    setError,
    reset,
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_USER_EMAIL]: '',
      [FIELD_COMPANY_ID]: { value: '' },
      [FIELD_COMPANY_ROLE]: '',
      [FIELD_DOMAIN_IDS]: []
    }
  });

  const userEmails = watch(FIELD_USER_EMAIL);
  const userRole = watch(FIELD_COMPANY_ROLE);
  const isEmailEntered = userEmails?.length > 0 && !errors[FIELD_USER_EMAIL];

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const companiesList = useMemo(() => {
    if (!dataCompanies?.dataItems?.items.length) return [];
    return formatSelectOptions(dataCompanies.dataItems.items, 'id', 'name');
  }, [dataCompanies?.dataItems?.items]);

  const rolesList = useMemo(() => {
    if (!dataRoleTemplates?.dataItems.items.length) return [];
    return formatSelectOptions(dataRoleTemplates.dataItems.items, 'id', 'name');
  }, [dataRoleTemplates?.dataItems.items]);

  const isSelectedRoleSuperAdmin =
    rolesList.filter((role) => role.value === userRole)[0]?.label?.toLowerCase() === 'super admin';
  // CBS Admin
  // У нас есть поле выбора компании, выбираем компанию, далее роль,
  // после выбора роли мы сравниваем сompanyId(роли) и сompanyId(компании которую мы выбрали),
  // если === отображаем домены которые определены в шаблоне ролей,
  // если !== это шаблон CBS Admin и показываем все домены которые доступны компании которую мы выбрали.

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    const selectedCompanyData = dataCompanies?.dataItems.items.find(
      (company: any) => company.id === selectedCompany?.value
    );

    return selectedCompanyData?.domains?.map((domain) => ({
      value: domain?.id as string,
      label: domain?.name as string,
      key: domain?.id
    })) as AutocompleteOptionT[];
  }, [selectedRoleDomains, selectedCompany, selectedRoleCompanyId, domainsCompany]);

  const handleSubmitForm = handleSubmit(() => {
    const { userEmail, companyId, companyRole, domainIds } = getValues();

    if (companyId) {
      CreateUser({
        variables: {
          companyUid: selectedCompany?.value,
          user: {
            email: userEmail,
            roleTemplateId: companyRole,
            domainIds: domainIds.length ? domainIds : []
          }
        },

        onCompleted: ({ createUser }) => {
          handleCloseModal();

          if (createUser.id) {
            /* Clear data from mutation */
            resetDataCreateUser();

            setTimeout(() => {
              AlertService.showAlert({
                title: tGlobal('alertMessages.success.userCreated'),
                severity: 'success'
              });
            }, 500);
          }
        },
        onError: (error) => {
          const errorMessage = JSON.parse(error.message);

          if (errorMessage?.errorCode) {
            setError(FIELD_USER_EMAIL, {
              message: tErrors(`company.create.${errorMessage.errorCode.replace('error.', '')}`)
            });
          }
        }
      });
    }
  });

  const isLoading = loadingCompanies;
  const isBtnSubmitDisabled = Boolean(dataCreateUser);
  const isRoleTemplatesExist = Boolean(dataRoleTemplates?.dataItems.items.length);

  const handleRoleChange = (selectedRoleId: any) => {
    const selectedRole = dataRoleTemplates?.dataItems.items.find((role: any) => role.id === selectedRoleId);

    setSelectedRoleCompanyId(selectedRole?.companyId || '');
    setSelectedRoleDomains(selectedRole?.domains as DomainDto[]);

    setValue(FIELD_DOMAIN_IDS, []);
    setResetKey((prevKey) => prevKey + 1);
  };

  const onChangeCompany = (company: any) => {
    setSelectedCompany(company);

    resetField(FIELD_COMPANY_ROLE);

    if (!company) setValue(FIELD_COMPANY_ROLE, '');
    setValue(FIELD_DOMAIN_IDS, []);
    setResetKey((prevKey) => prevKey + 1);
  };
  const handleDomainsChange = (items: string[]) => {
    // TODO use value instead label for Autocomplete
    const domainIds = domainsOptions?.filter((domain) => items.includes(domain?.label))?.map((domain) => domain.key);

    setValue(FIELD_DOMAIN_IDS, domainIds as string[]);
  };

  useEffect(() => {
    if (selectedCompany?.value && selectedRoleCompanyId) {
      const roleDetails = dataRoleTemplates?.dataItems.items.find((role: any) => role.id === selectedRoleCompanyId);
      if (roleDetails?.companyId === selectedCompany?.value) {
        setSelectedRoleDomains(roleDetails?.domains as DomainDto[]);
      } else {
        refetchDomains({ companyId: selectedCompany.value });
      }
    }
  }, [selectedCompany, selectedRoleCompanyId, dataRoleTemplates, refetchDomains]);

  // After refetch reload Domains
  useEffect(() => {
    if (domainsData && domainsData.dataItems && selectedCompany && selectedRoleCompanyId !== selectedCompany.value) {
      const updatedDomains = domainsData.dataItems.items.map((domain) => ({
        key: domain?.id as string,
        value: domain?.id as string,
        label: domain?.name as string
      }));
      setSelectedRoleDomains(updatedDomains as any[]);
    }
  }, [domainsData, selectedCompany, selectedRoleCompanyId]);

  return (
    <Dialog
      headerTitle={tUsers('addUser.modal.title')}
      headerSubTitle={tUsers('addUser.modal.subTitle')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tUsers('addUser.btnCancel')}
          </Button>

          <ButtonLoading
            form={FORM_ID}
            type={'submit'}
            variant={'contained'}
            sx={{ margin: '0 0 0 0 !important' }}
            disabled={isBtnSubmitDisabled}
            loading={loadingCreateUser}
          >
            {tUsers('addUser.btnInvite')}
          </ButtonLoading>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        {isLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '300px !important' }} isContainer />
        ) : (
          <>
            <Controller
              name={FIELD_USER_EMAIL}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: EMAIL_REG_EXP,
                  message: tGlobal('validation.emailValid')
                }
              }}
              render={({ field: { onBlur, value } }) => {
                return (
                  <TextFieldControl
                    name={FIELD_USER_EMAIL}
                    subTitle={tCompany('create.blank.inviteUserSubTitle')}
                    label={tUsers('addUser.modal.inviteUserLabel')}
                    placeholder={tUsers('addUser.modal.inviteUserPlaceholder')}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_USER_EMAIL]?.message,
                      error: errors[FIELD_USER_EMAIL]?.message
                    }}
                    sxInputBox={{
                      marginTop: 0
                    }}
                    onChange={(e: any) => {
                      setValue(FIELD_USER_EMAIL, e.target.value?.trim());
                    }}
                    onBlur={onBlur}
                  />
                );
              }}
            />

            <Controller
              name={FIELD_COMPANY_ID}
              control={control}
              rules={{
                validate: (field) => {
                  if (!field?.value) return tGlobal('validation.required');

                  return true;
                }
              }}
              render={({ field: { onChange, onBlur } }) => {
                return (
                  <Autocomplete
                    name={FIELD_COMPANY_ID}
                    label={tUsers('addUser.modal.inviteCompanyLabel')}
                    options={companiesList}
                    validation={{
                      isValid: !errors[FIELD_COMPANY_ID]?.message,
                      error: errors[FIELD_COMPANY_ID]?.message
                    }}
                    getOptionKey={(option: any) => option.value as string}
                    onChange={(e, value) => {
                      onChange(value);
                      onChangeCompany(value);
                    }}
                    onBlur={onBlur}
                  />
                );
              }}
            />

            <Controller
              name={FIELD_COMPANY_ROLE}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                const label = selectedCompany
                  ? isRoleTemplatesExist
                    ? tUsers('addUser.modal.inviteAsLabel')
                    : tUsers('addUser.modal.inviteAsLabelRolesEmpty')
                  : tUsers('addUser.modal.inviteAsLabelWhenCompanyNotEmpty');

                return (
                  <SelectControl
                    options={rolesList}
                    label={label}
                    placeholder={tUsers('addUser.modal.inviteUserPlaceholder')}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_COMPANY_ROLE]?.message,
                      error: errors[FIELD_COMPANY_ROLE]?.message
                    }}
                    MenuProps={{
                      className: 'MuiMenu-root-Mui-select'
                    }}
                    loading={loadingRoleTemplates}
                    disabled={!selectedCompany || !isEmailEntered}
                    onChange={(e) => {
                      onChange(e);
                      handleRoleChange(e.target.value);
                    }}
                    onBlur={onBlur}
                  >
                    {selectedCompany &&
                      dataRoleTemplates?.dataItems.items.map((item) => {
                        return item?.name ? (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item.name}
                          </MenuItem>
                        ) : null;
                      })}
                  </SelectControl>
                );
              }}
            />
            <Controller
              name={FIELD_DOMAIN_IDS}
              control={control}
              rules={{
                required: {
                  value: !isSelectedRoleSuperAdmin,
                  message: tGlobal('validation.required')
                }
              }}
              render={({ field }) => {
                return (
                  <AutocompleteTagsControl
                    name={FIELD_DOMAIN_IDS}
                    key={`domain-select-${resetKey}`}
                    value={field.value}
                    label={tTemplates('createRole.mainInfo.selectDomainLabel')}
                    options={domainsOptions as AutocompleteOptionT[]}
                    validation={{
                      isValid: !errors[FIELD_DOMAIN_IDS]?.message,
                      error: errors[FIELD_DOMAIN_IDS]?.message
                    }}
                    loading={loadingDomains || loadingCompanies || loadingRoleTemplates}
                    onChange={(_, value: any) => handleDomainsChange(value)}
                    onBlur={field.onBlur}
                  />
                );
              }}
            />
          </>
        )}
      </form>
    </Dialog>
  );
};
