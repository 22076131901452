export const Project = {
  root: {
    color: 'var(--default-gray-800)',
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      border: '1px solid var(--custom-styles-grey-20, rgba(17, 24, 39, 0.20))'
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))'
      }
    },
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--Custom-styles-Main-Error, #BD1537)'
      }
    },
    '&.Mui-disabled, &.Mui-loading': {
      pointerEvents: 'none',
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--custom-styles-grey-10, rgba(17, 24, 39, 0.10)) !important'
      }
    },
    '&.Mui-loading': {
      '.MuiSvgIcon-root': {
        opacity: '0'
      },
      '.MuiCircularProgress-svg': {
        color: 'var(--background-neutral-bold-default, #020204)'
      }
    },
    '.MuiCircularProgress-root': {
      top: 'calc(50% - 12px)'
    }
  },
  select: {
    display: 'flex'
  }
};

export const Base = {
  root: {
    height: '56px',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'var(--default-gray-800)',
    '&.MuiInputBase-adornedStart': {
      paddingLeft: '12px'
    },
    '&.MuiInputBase-adornedEnd': {
      paddingRight: '12px'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '1px solid var(--default-gray-300)',
      transition: 'all 0.2s ease-in-out'
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--primary-800)'
      }
    },
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--extended-red-700) !important'
      }
    },
    '&.Mui-disabled': {
      '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--default-gray-200) !important'
      }
    },
    '& ~ .MuiFormHelperText-root': {
      paddingLeft: '16px',
      '&.Mui-disabled': {
        opacity: '0.4',
        '.MuiFormHelperText-root__text': {
          color: 'var(--default-gray-800) !important'
        }
      }
    }
  }
};
