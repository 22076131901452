import { FC, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import {
  GET_LOCATION_FOLDER_WITH_STATUSES,
  GET_LOCATIONS_SIDEBAR,
  LocationFolderWithStatusesQuery,
  SWITCH_LOCATIONS_ARCHIVE_STATE
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { useTranslation } from '@/i18n';

interface ArchiveLocationModalProps {
  isModalOpen: boolean;
  folderId: string;
  locationName?: string;
  onCloseModal: () => void;
}

export const ArchiveLocationModal: FC<ArchiveLocationModalProps> = ({
  isModalOpen,
  folderId,
  locationName,
  onCloseModal
}) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  const { data } = useQuery<LocationFolderWithStatusesQuery>(GET_LOCATION_FOLDER_WITH_STATUSES, {
    variables: {
      id: folderId
    },
    skip: !folderId,
    fetchPolicy: 'no-cache'
  });

  const isArchived = data?.locationFolder.archived;

  const [SwitchLocationsArchiveState] = useMutation(SWITCH_LOCATIONS_ARCHIVE_STATE, {
    variables: {
      id: folderId?.length ? folderId : undefined,
      locationIds: [folderId],
      archived: !isArchived
    },
    refetchQueries: [GET_LOCATIONS_SIDEBAR, 'locationFolders']
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();
  }, []);

  const handleSwitchLocationsArchiveState = () => {
    SwitchLocationsArchiveState({
      onCompleted: () => {
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.folderArchiveStatusUpdated'),
            severity: 'success'
          });
        }, 500);
        handleCloseModal();
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  return (
    <Dialog
      headerTitle={tLocations('actions.modal.archive.title')}
      hideHeaderBorder
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tLocations('actions.modal.archive.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSwitchLocationsArchiveState}
          >
            {tLocations('actions.modal.archive.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <Text variant={'bodyLg'} sx={{ color: '#090E16', maxWidth: '500px' }}>
        <Trans
          i18nKey={tLocations('actions.modal.archive.description')}
          values={{ name: locationName }}
          components={{ span: <span style={{ fontWeight: 600 }} /> }}
        />
      </Text>
    </Dialog>
  );
};
