import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LocationListItem {
  id: string;
  locationName: string;
}

interface DestinationData {
  destinationLocationId: string | null;
  destinationLocationName: string | null;
}

export interface CurrentLocationFolderData {
  canCreateFolder: boolean;
  folderId: string | null;
  isActive?: boolean;
  depth?: number;
}
interface MoveLocationTabState {
  isStartMoving: boolean;
  destinationData: DestinationData;
  progressList: LocationListItem[];
  currentLocationFolderData: CurrentLocationFolderData;
}

const initialState: MoveLocationTabState = {
  isStartMoving: false,
  destinationData: {
    destinationLocationId: null, // folder id where we will move our location
    destinationLocationName: null
  },
  progressList: [],
  currentLocationFolderData: {
    folderId: null,
    canCreateFolder: true,
    isActive: true,
    depth: 0
  }
};

const moveLocationTabSlice = createSlice({
  name: 'move-location',
  initialState,
  reducers: {
    startMoving(state) {
      state.isStartMoving = true;
    },
    cancelMoving(state) {
      state.isStartMoving = false;
      state.destinationData = {
        destinationLocationId: null,
        destinationLocationName: null
      };
      state.progressList = [];
    },
    setDestinationData(state, { payload }: PayloadAction<LocationListItem>) {
      state.destinationData = {
        destinationLocationId: payload.id,
        destinationLocationName: payload.locationName
      };
    },
    removeLocationFromProgressList(state, { payload }: PayloadAction<LocationListItem>) {
      if (state.progressList.length <= 1) {
        state.isStartMoving = false;
      }

      state.progressList = state.progressList.filter((item) => item.id !== payload.id);
    },
    setLocationToProgressListWithNextStep(state, { payload }: PayloadAction<LocationListItem>) {
      state.destinationData = {
        destinationLocationId: payload.id,
        destinationLocationName: payload.locationName
      };

      if (state.progressList.some((item) => item.id === payload.id)) {
        state.progressList.filter((item) => item.id !== payload.id);
        return;
      }

      state.progressList.push(payload);
    },
    setCurrentLocationFolderData(state, { payload }: PayloadAction<CurrentLocationFolderData>) {
      state.currentLocationFolderData = payload;
    }
  }
});

export const MoveLocationTabActions = moveLocationTabSlice.actions;
export const { reducer: MoveLocationTabReducer } = moveLocationTabSlice;
