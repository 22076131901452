export const Project = {
  root: {
    height: '4px',
    '&.Mui-table': {
      height: '4px',
      background: 'var(--background-selected-hovered, #E9F2FF)',
      '.MuiLinearProgress-bar1Indeterminate, .MuiLinearProgress-bar2Indeterminate': {
        background: 'var(--Base-tokens-Blue-Blue500, #388BFF)'
      }
    }
  }
};

export const Base = {
  root: {}
};
