import { MouseEvent, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { DotIcon, MoreVertIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export const ActionMenu = () => {
  const { t: tTasks } = useTranslation('tasks');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box component={'td'} className={'table-edit-button'}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={'custom-task-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'custom-task-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {}}>
          <DotIcon />
          {tTasks('tasksCustom.status.pending')}
        </MenuItem>

        <MenuItem onClick={() => {}}>
          <DotIcon fill={'#06845B'} />
          {tTasks('tasksCustom.status.inProgress')}
        </MenuItem>

        <MenuItem onClick={() => {}}>
          <DotIcon fill={'#265299'} />
          {tTasks('tasksCustom.status.completed')}
        </MenuItem>

        <MenuItem onClick={() => {}}>
          <DotIcon fill={'#BE0E2C'} />
          {tTasks('tasksCustom.status.canceled')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
