import { Autocomplete, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(() => ({
  '.MuiAutocomplete-popper': {
    display: 'none !important'
  }
})) as typeof Box;

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  '.MuiInputBase-root': {
    height: '32px',
    padding: '0',
    paddingLeft: '12px',
    paddingRight: '12px !important',

    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: '15px'
    }
  },

  '.MuiAutocomplete-input': {
    padding: '0 !important',
    fontSize: '14px'
  },

  '.MuiOutlinedInput-root': {
    paddingRight: '0px'
  }
})) as typeof Autocomplete;

export const StyledIconButton = styled(IconButton)(() => ({
  width: '24px',
  height: '24px'
})) as typeof IconButton;
