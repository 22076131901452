import { useTranslation } from 'react-i18next';
import { Text } from 'infinitytechnologies-ui';

import { Box, Button, SvgIcon } from '@mui/material';

import { ArrowForwardIcon, Flex, ImageLazy, InfoIcon, TableAsideItem } from '@/separatedModules/components';

interface UsersAsideContentProps {
  userAvatar: { url: string };
  email: string;
  phoneNumber: string;
  handleClickDetails: () => void;
  isHidden: boolean;
}

export const UsersAsideContent = ({
  isHidden,
  userAvatar,
  email,
  phoneNumber,
  handleClickDetails
}: UsersAsideContentProps) => {
  const { t: tTemplates } = useTranslation('templates');

  return (
    <>
      {userAvatar ? (
        <ImageLazy src={userAvatar?.url} width={136} height={136} alt={userAvatar?.url} />
      ) : (
        <Box
          sx={{
            width: '136px',
            height: '136px',
            marginRight: '8px',
            borderRadius: '50%',
            background: 'rgb(240, 240, 241)'
          }}
        />
      )}

      {!isHidden ? (
        <>
          <TableAsideItem title={tTemplates('equipmentItem.round.taskByRound.step2.aside.email')} subTitle={email} />
          <TableAsideItem
            title={tTemplates('equipmentItem.round.taskByRound.step2.aside.phone')}
            subTitle={phoneNumber}
          />

          <Button
            variant={'text'}
            size={'small'}
            sx={{
              margin: '0 0 0 auto !important',
              '&.MuiButton-text': {
                '&:not(:active, :hover)': {
                  background: 'transparent'
                }
              }
            }}
            onClick={handleClickDetails}
            endIcon={<SvgIcon component={ArrowForwardIcon} />}
          >
            {tTemplates('equipmentItem.round.taskByRound.step2.aside.detailsBtn')}
          </Button>
        </>
      ) : (
        <Flex sx={{ marginTop: '20px' }}>
          <InfoIcon fontSize="inherit" sx={{ fill: '#0C66E4' }} />
          <Box sx={{ marginLeft: '12px' }}>
            <Text sx={{ fontSize: '14px', fontWeight: '600' }}>You can’t select this user</Text>
            <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
              {tTemplates('equipmentItem.round.taskByRound.step2.aside.cantSelectUser')}
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};
