import { createContext, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { LocalStorageServiceClass } from '@/logicLayers/infrastructure/services';

import { MenuOption } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useContextState } from './ContextState';

import { CONFIG_TABLE } from '../../helpers';
import { ContextUpdateStateI, initState, TABLE_STORAGE_NAME, TableProviderProps } from './helpers';

export const ContextUpdateState = createContext<ContextUpdateStateI>({
  handleChangeBySearch: () => {},
  handleChangePagination: () => {},
  handleChangeRowsPerPage: () => {},
  handleChangeSortByDomains: () => {},
  handleChangeSortByStatus: () => {},
  handleChangeSortByDate: () => {},
  handleClickRow: () => {},
  handleDoubleClickRow: () => {},
  handleResetSelectedRows: () => {},
  handleShowAllColumns: () => {},
  handleToggleActions: () => {},
  handleToggleAside: () => {},
  handleToggleColumnVisibility: () => {}
});

export const useContextUpdateState = () => useContext(ContextUpdateState);

export const ProviderUpdateState = ({ children }: TableProviderProps) => {
  const LocalStorageService = new LocalStorageServiceClass();

  const location = useLocation();

  const { setState } = useContextState();

  const handleSaveColumnsToStorage = (hiddenColumns: string[]) => {
    if (LINKS_PAGES.templatesEquipment === location.pathname) {
      LocalStorageService.set(TABLE_STORAGE_NAME, hiddenColumns);
    }
  };

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: [newPage]
        }
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: initState.pagination.page,
          rowsPerPage: [newRowsPerPage]
        }
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleChangeSortByDomains = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDomains: options?.length ? [...options] : []
        },
        pagination: {
          ...state.pagination,
          page: CONFIG_TABLE.pagination.page,
          rowsPerPage: CONFIG_TABLE.pagination.rowsPerPage
        }
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: MenuOption[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeSortByStatus = useCallback((options?: MenuOption[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options?.length ? [...options] : []
        },
        pagination: {
          ...state.pagination,
          page: CONFIG_TABLE.pagination.page,
          rowsPerPage: CONFIG_TABLE.pagination.rowsPerPage
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleToggleActions = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        isVisibleActions: !state.isVisibleActions
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleToggleColumnVisibility = useCallback((column: string) => {
    setState((state) => {
      const inColumnHidden = state.filters.hiddenColumns.includes(column);

      const hiddenColumnsUpdated = inColumnHidden
        ? state.filters.hiddenColumns.filter((columnItem) => columnItem !== column)
        : [column, ...state.filters.hiddenColumns];

      handleSaveColumnsToStorage(hiddenColumnsUpdated);

      return {
        ...state,
        filters: {
          ...state.filters,
          hiddenColumns: hiddenColumnsUpdated
        }
      };
    });
  }, []);

  const handleShowAllColumns = useCallback(() => {
    // ToDo Refactor
    LocalStorageService.set(TABLE_STORAGE_NAME, []);

    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          hiddenColumns: []
        }
      };
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      handleChangeBySearch,
      handleChangePagination,
      handleChangeRowsPerPage,
      handleChangeSortByDomains,
      handleChangeSortByDate,
      handleChangeSortByStatus,
      handleClickRow,
      handleDoubleClickRow,
      handleResetSelectedRows,
      handleShowAllColumns,
      handleToggleActions,
      handleToggleAside,
      handleToggleColumnVisibility
    }),
    []
  );

  return <ContextUpdateState.Provider value={contextValue}>{children}</ContextUpdateState.Provider>;
};
