import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Text } from 'infinitytechnologies-ui';
import { omit } from 'lodash';
import groupBy from 'lodash/groupBy';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  CREATE_ROLE_TEMPLATE,
  GET_USER_DETAILED_PERMISSIONS,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteOptionT,
  AutocompleteTagsControl,
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  EditPageWrap,
  PageTitle,
  TextFieldControl
} from '@/separatedModules/components';

import { isSuperAdmin, ROLE_DESCRIPTION_REG_EXP, ROLE_NAME_REG_EXP, useIsUserCompanyAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { PermissionItem } from './components';

import { MenuItemProps } from '../CreateRole/components/helpers';

const createMockedMenuList = (
  group: { label: string; value: string; resource: string; operation: string }[],
  operationName: string
) => {
  const onlyViewingItem = group
    .filter((filterItem) => filterItem.operation === 'VIEWING')
    ?.map((editingItem) => editingItem.value);
  const editingItems = group.map((editingItem) => editingItem.value);

  return [
    {
      id: 0,
      title: i18n.t('createRole.mainInfo.permissionsMenu.canView', { ns: 'templates' }),
      operationName,
      isDefaultValue: false,
      value: onlyViewingItem
    },
    {
      id: 1,
      title: i18n.t('createRole.mainInfo.permissionsMenu.canEdit', { ns: 'templates' }),
      operationName,
      isDefaultValue: false,
      value: editingItems
    },
    {
      id: 2,
      title: i18n.t('createRole.mainInfo.permissionsMenu.restrict', { ns: 'templates' }),
      operationName,
      isDefaultValue: true,
      value: []
    }
  ];
};

const FIELD_ROLE = 'name';
const FIELD_DESCRIPTION = 'description';
const FIELD_DOMAIN_IDS = 'domainIds';
const FIELD_PERMISSIONS = 'permissions';

interface FormProps {
  [FIELD_ROLE]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_PERMISSIONS]: string[];
  [FIELD_DOMAIN_IDS]: string[];
}

export const CreateRolePage: FC = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const location = useLocation();
  const isMainRole = location.state?.isMainRolePage;
  const isUserCompanyAdmin = useIsUserCompanyAdmin();

  const navigateTo = useNavigate();

  const { data: detailedPermissionsData, loading: detailedPermissionsLoading } =
    useQuery(GET_USER_DETAILED_PERMISSIONS);
  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });
  const [CreateRole] = useMutation(CREATE_ROLE_TEMPLATE);

  const domains = domainsData?.dataItems.items;
  const shouldShowDomains = !isSuperAdmin();

  const {
    setValue,
    setError,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_ROLE]: '',
      [FIELD_DESCRIPTION]: '',
      [FIELD_PERMISSIONS]: [],
      [FIELD_DOMAIN_IDS]: []
    }
  });

  const formPermissions = watch(FIELD_PERMISSIONS);

  const handleSubmitForm = handleSubmit((data) => {
    CreateRole({
      variables: {
        name: data.name,
        description: data.description,
        permissions: Array.from(new Set(data.permissions)),
        domainIds: data.domainIds
      }
    })
      .then(() => {
        // navigateTo(LINKS_PAGES.templatesRoles);
        navigateTo(-1);

        AlertService.showAlert({
          title: tGlobal('alertMessages.success.roleCreated'),
          severity: 'success'
        });
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.message);

        // TODO: Update error message when BE will update
        if (errorMessage?.errorCode === 'error.unknown') {
          setError(FIELD_ROLE, { message: 'Role template already exists with this name' });
        } else {
          // TODO: Update error message when BE will update
          setError(FIELD_ROLE, { message: 'Role template already exists with this name' });
        }
      });
  });

  const handlePermissionClick = (item: MenuItemProps) => {
    setValue(FIELD_PERMISSIONS, [...formPermissions, ...item.value]);
  };

  const handleDomainsChange = (items: string[]) => {
    if (!domains?.length) return;

    const domainIds = domains.filter((domain) => items.includes(domain?.name as string))?.map((domain) => domain?.id);
    setValue(FIELD_DOMAIN_IDS, domainIds as string[]);
  };

  const groupedPermissions = useMemo(() => {
    const grouped = groupBy(detailedPermissionsData?.permissions, 'resource');

    return isUserCompanyAdmin ? omit(grouped, 'COMPANIES') : grouped;
  }, [detailedPermissionsData?.permissions, detailedPermissionsLoading, isUserCompanyAdmin]);

  const domainsOptions = useMemo(() => {
    if (!domains) {
      return [];
    }

    return domains?.map((domain) => ({
      label: domain?.name,
      value: domain?.id,
      key: domain?.id
    }));
  }, [domains]);

  return (
    <form onSubmit={handleSubmitForm}>
      <Grid justifyContent={'center'} container row>
        <Grid xs={12} sm={10} md={8} lg={6} item>
          <EditPageWrap navigateTo={isMainRole ? LINKS_PAGES.role : LINKS_PAGES.templatesRoles}>
            <PageTitle title={tTemplates('createRole.pageTitle')} sxTitle={{ mt: '32px' }} />

            <Controller
              name={FIELD_ROLE}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: ROLE_NAME_REG_EXP,
                  message: tGlobal('validation.textFieldLength')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextFieldControl
                    label={tTemplates('createRole.mainInfo.roleInputLabel')}
                    name={FIELD_ROLE}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_ROLE]?.message,
                      error: errors[FIELD_ROLE]?.message
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />

            <Text variant={'titleMd'} sx={{ mt: '44px' }}>
              {tTemplates('createRole.mainInfo.descriptionLabel')}
            </Text>

            <Text variant={'bodyMd'} sx={{ color: '#505668', mt: 0 }}>
              {tTemplates('createRole.mainInfo.descriptionDescription')}
            </Text>

            <Controller
              name={FIELD_DESCRIPTION}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: ROLE_DESCRIPTION_REG_EXP,
                  message: tGlobal('validation.descriptionLength', {
                    value: 100
                  })
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextFieldControl
                    label={tTemplates('createRole.mainInfo.descriptionInputLabel')}
                    name={FIELD_DESCRIPTION}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_DESCRIPTION]?.message,
                      error: errors[FIELD_DESCRIPTION]?.message
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />

            <BlockInfoWithEdit
              sx={{
                border: 'none',
                p: 0,
                pt: '24px',
                '&.Mui-disabled': {
                  border: 'none !important'
                }
              }}
              disabled
            >
              <BlockInfoWithEditItem
                title={tTemplates('createRole.mainInfo.imageLabel')}
                label={tTemplates('createRole.mainInfo.imageDescription')}
                sxDescription={{ maxWidth: '520px' }}
                Edit={<Avatar size={'64'} />}
              />
            </BlockInfoWithEdit>

            <BlockInfoWithEdit sx={{ border: 'none', p: 0, my: '24px' }}>
              <BlockInfoWithEditItem
                title={tTemplates('createRole.mainInfo.managePermissionsLabel')}
                label={tTemplates('createRole.mainInfo.managePermissionsDescription')}
              />
            </BlockInfoWithEdit>

            <Stack spacing={'24px'}>
              {Object.entries(groupedPermissions).map(([key, value]) => (
                <PermissionItem
                  key={key}
                  title={tTemplates(`createRole.mainInfo.${key.toLowerCase()}Label`)}
                  description={tTemplates(`createRole.mainInfo.${key.toLowerCase()}Description`)}
                  menuList={createMockedMenuList(value, key)}
                  onMenuItemClick={handlePermissionClick}
                />
              ))}
            </Stack>

            {shouldShowDomains ? (
              <>
                <BlockInfoWithEdit sx={{ border: 'none', p: 0 }}>
                  <BlockInfoWithEditItem
                    title={tTemplates('createRole.mainInfo.equipmentDomainsLabel')}
                    label={tTemplates('createRole.mainInfo.equipmentDomainsDescription')}
                  />
                </BlockInfoWithEdit>

                <Box mb={'108px'}>
                  <AutocompleteTagsControl
                    name={FIELD_DOMAIN_IDS}
                    label={tTemplates('createRole.mainInfo.selectDomainLabel')}
                    options={domainsOptions as AutocompleteOptionT[]}
                    onChange={(_, value: any) => handleDomainsChange(value)}
                  />
                </Box>
              </>
            ) : null}

            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'flex-end'}
              position={'sticky'}
              bottom={0}
              pt={'24px'}
              pb={'40px'}
              sx={{ background: 'white' }}
            >
              <Button variant={'contained'} type={'submit'} disabled={!!errors[FIELD_ROLE]?.message}>
                {tTemplates('createRole.mainInfo.btnSubmit')}
              </Button>
            </Box>
          </EditPageWrap>
        </Grid>
      </Grid>
    </form>
  );
};
