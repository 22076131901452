import { Fragment, MouseEvent } from 'react';
import { Text } from 'infinitytechnologies-ui';
import isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { useConstructRoleGroupPermissions } from '@/separatedModules/pages/User/helpers';

import { ArrowOutwardIcon, Flex, RenderWhen, TooltipOverflowItem } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { DetailsBtn, PlateWrapper, StyledText } from './style';

interface RolePlateProps<T> {
  name?: string;
  description?: string | null;
  permissions?: T[];
  isSelected?: boolean;
  onClick: () => void;
  onDetailsClick: () => void;
}

export const RolePlate = <T,>({
  name = '',
  description = '',
  permissions,
  isSelected,
  onClick,
  onDetailsClick
}: RolePlateProps<T>) => {
  const { t: tTemplates } = useTranslation('templates');
  const { t: tUser } = useTranslation('user');

  const { matchedRolePermissionsList } = useConstructRoleGroupPermissions<T>({
    rolePermissions: permissions
  });

  const handleDetailsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDetailsClick();
  };

  return (
    <PlateWrapper isSelected={isSelected} onClick={onClick}>
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={'3px'}>
        <Text className={'m-0'} variant={'titleMd'}>
          {name}
        </Text>
        <DetailsBtn size={'small'} variant={'text'} endIcon={<ArrowOutwardIcon />} onClick={handleDetailsClick}>
          {tUser('roleManagement.btnDetails')}
        </DetailsBtn>
      </Flex>
      <Text className={'m-0'} variant={'subtitle2'} fontWeight={400} color={'#505668'}>
        <TooltipOverflowItem>{description}</TooltipOverflowItem>
      </Text>

      <Divider sx={{ mb: '20px', mt: description ? '20px' : '42px' }} />

      <Stack direction={'column'} spacing={'20px'}>
        {!isEmpty(matchedRolePermissionsList)
          ? Object.entries(matchedRolePermissionsList).map(([key, value]) => (
              <Fragment key={key}>
                <RenderWhen condition={!!value.length}>
                  <Flex flexDirection={'column'}>
                    <StyledText>{key.toLowerCase()}:</StyledText>

                    <RenderWhen condition={!!value.length}>
                      <Text className={'m-0'} variant={'bodyMd'}>
                        <TooltipOverflowItem>
                          {value.map((item) => ' ' + tTemplates(`createRole.mainInfo.${item.toLowerCase()}Label`))}
                        </TooltipOverflowItem>
                      </Text>
                    </RenderWhen>
                  </Flex>
                </RenderWhen>
              </Fragment>
            ))
          : null}
      </Stack>
    </PlateWrapper>
  );
};
