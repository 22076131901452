import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { SEARCH_DOMAINS, SearchDomainsQuery } from '@/logicLayers/domain';

import { BaseMenuOption, CheckboxesDropdown, MenuOption } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextUpdateState } from '../../../Providers';

export const SortByDomain = () => {
  const { t: tTemplates } = useTranslation('templates');

  const { handleChangeSortByDomains } = useContextUpdateState();

  const { data: domainsData, loading: domainsDataLoading } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const onChangeDomain = (options: (BaseMenuOption | MenuOption)[]) => {
    const selected = options.map((finalOption) => finalOption.key);

    handleChangeSortByDomains(selected);
  };

  const menuList = useMemo(() => {
    if (!domainsData?.dataItems?.items) return [];

    return domainsData.dataItems.items.map((item) => ({
      label: item?.name,
      key: item?.id,
      value: item?.id
    }));
  }, [domainsData]);

  const isDisabled = !menuList.length || domainsDataLoading;

  return (
    <CheckboxesDropdown
      id={'equipment-items-sort-by-domain'}
      title={tTemplates('equipment.table.filters.sortByDomain.title')}
      menuList={menuList as BaseMenuOption[]}
      hasSelectAll
      disabled={isDisabled}
      onChange={onChangeDomain}
    />
  );
};
