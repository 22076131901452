import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Grid } from '@mui/material';

import { EquipmentParameterQuery, GET_EQUIPMENT_PARAMETER } from '@/logicLayers/domain';

import { EditPageWrap, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { EditProvider } from '../Edit/components';

export const EditParameter = () => {
  const [searchParams] = useSearchParams();

  const editValue = searchParams.get('field') ?? '';
  const { templateId = '', parameterId = '' } = useParams();
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');

  const { data: dataEquipmentTemplateParameter, loading: isLoading } = useQuery<EquipmentParameterQuery>(
    GET_EQUIPMENT_PARAMETER,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: parameterId || undefined
      }
    }
  );

  const navigateToUrl = LINKS_PAGES.templatesEquipmentItemParameter
    .replace(':templateId', templateId)
    .replace(':parameterId', parameterId);

  const Component = EditProvider({
    ...dataEquipmentTemplateParameter,
    /* custom fields */
    editType: editValue,
    navigateToUrl,
    tModel: tTemplates,
    tGlobal,
    equipment: {} as any,
    templateId
  });

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        {isLoading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
        ) : (
          <EditPageWrap navigateTo={navigateToUrl}>{Component}</EditPageWrap>
        )}
      </Grid>
    </Grid>
  );
};
