import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_USER_BY_ID } from '@/logicLayers/domain';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';

import { UserDetails } from '@/separatedModules/components';

import { LINKS_SIDEBAR_TEMPLATE_DETAILS_MENU } from '@/subsidiaryBinders/constants';

import { NavListSimple } from '../NavListSimple';

export const SideBarRoleTemplateDetails = () => {
  const [searchParams] = useSearchParams();
  const { templateId = '' } = useParams();

  const uid = searchParams.get('uid');

  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(GET_USER_BY_ID, { variables: { uid: uid } });

  return (
    <Sidebar>
      <SideBarGroup>
        <UserDetails
          variant={'short'}
          firstName={dataUser?.user.firstName ?? ''}
          lastName={dataUser?.user.lastName ?? ''}
          email={dataUser?.user.email ?? ''}
          loading={loadingUser}
          errorUser={errorUser}
        />
      </SideBarGroup>

      <SideBarGroup>
        <NavListSimple
          menu={LINKS_SIDEBAR_TEMPLATE_DETAILS_MENU(uid)}
          insertValue={templateId}
          replaceValue={':templateId'}
        />
      </SideBarGroup>
    </Sidebar>
  );
};
