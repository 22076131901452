export const Project = {
  root: {
    color: 'var(--text-default, #090E16)',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    margin: '8px 0'
  }
};

export const Base = {
  root: {}
};
