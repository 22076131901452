import { memo, ReactNode } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { AddPhotoIcon, AvatarImagePreviewButton } from '@/separatedModules/components';

import { RenderUploaderButtonProps } from '../../helpers';

interface RenderUploaderButtonLgProps extends RenderUploaderButtonProps {
  title: string;
  subTitle?: ReactNode;
  btnUploadPhotoText?: string;
}

export const UploaderButtonLg = memo(
  ({ imgAlt, oldImage, title, subTitle, btnUploadPhotoText, handleOpenModalPhoto }: RenderUploaderButtonLgProps) => {
    return (
      <AvatarImagePreviewButton imgAlt={imgAlt} oldImage={oldImage}>
        <Box>
          <Text variant={'titleMd'}>{title}</Text>

          {subTitle ? (
            <Text variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
              {subTitle}
            </Text>
          ) : null}

          {btnUploadPhotoText ? (
            <Button
              variant={'outlined'}
              className={'single-iu__area__btn__upload'}
              startIcon={<AddPhotoIcon />}
              sx={{
                border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))'
              }}
              onClick={handleOpenModalPhoto}
            >
              {btnUploadPhotoText}
            </Button>
          ) : null}
        </Box>
      </AvatarImagePreviewButton>
    );
  }
);
