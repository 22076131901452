import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

interface TypeList {
  [key: string]: string;
}

export const TYPE_LIST: TypeList = {
  NUMERIC_INPUT: LINKS_IMAGES.parameterTypeNumericInput,
  NUMERIC_FORMULA: LINKS_IMAGES.parameterTypeNumericFormula,
  NUMERIC_FIXED: LINKS_IMAGES.parameterTypeNumericFixed,
  BOOLEAN: LINKS_IMAGES.parameterTypeBoolean,
  STRING: LINKS_IMAGES.parameterTypeString,
  DATA_TIME: LINKS_IMAGES.parameterTypeDateTime
};

export const PARAMETER_TYPE: TypeList = {
  DATA_TIME: 'Date & Time',
  STRING: 'String',
  BOOLEAN: 'Boolean',
  NUMERIC_INPUT: 'Data input',
  NUMERIC_FORMULA: 'Set formula',
  NUMERIC_FIXED: 'Fixed value'
};
