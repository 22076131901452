import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, Checkbox as MuiCheckbox } from '@mui/material';

import { BaseMenuOption, MenuOption } from '@/separatedModules/components/Dropdown/dropdown.config';
import { BaseTableDropdown } from '@/separatedModules/components/Dropdown/TableDropdown/BaseTableDropdown';

import { useTranslation } from '@/i18n';

import { BaseMenuItem } from '../..';
import { DropdownProps } from '../BaseTableDropdown';

interface CheckboxesDropdownProps extends DropdownProps {
  hasSelectAll?: boolean;
  defaultChecked?: boolean;
  menuList: BaseMenuOption[];
}

export const CheckboxesDropdown: FC<CheckboxesDropdownProps> = ({
  id,
  title,
  hasSearch = false,
  searchPlaceholder,
  hasSelectAll = false,
  defaultChecked = false,
  menuList,
  disabled = false,
  onChange
}) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [searchFilter, setSearchFilter] = useState('');
  const [filterCount, setFilterCount] = useState(0);

  const [stagedSelectedItems, setStagedSelectedItems] = useState<MenuOption[]>([]);
  const [selectedItems, setSelectedItems] = useState<MenuOption[]>([]);

  useEffect(() => {
    setStagedSelectedItems(menuList.map((item) => ({ ...item, checked: defaultChecked })));
  }, [menuList]);

  const areAllChecked = stagedSelectedItems.every((option) => option.checked);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchFilter('');
  };

  const handleItemClick = (option: MenuOption) => {
    setStagedSelectedItems((prev) =>
      prev.map((prevColumn) => {
        if (prevColumn.key === option.key) {
          return { ...prevColumn, checked: !option.checked };
        }

        return prevColumn;
      })
    );
  };

  const handleSelectAllClick = () => {
    setStagedSelectedItems((prev) => prev.map((prevColumn) => ({ ...prevColumn, checked: !areAllChecked })));
  };

  const filteredMenuList = useMemo(() => {
    return stagedSelectedItems.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [stagedSelectedItems, searchFilter]);

  const handleApplyClick = () => {
    const finalSelectedItems = stagedSelectedItems.filter((option) => option.checked !== defaultChecked);

    onChange?.(finalSelectedItems);
    setFilterCount(finalSelectedItems.length);
    handleClose();
    setSelectedItems(stagedSelectedItems);
    setStagedSelectedItems(stagedSelectedItems);
  };

  const handleCancelClick = () => {
    setStagedSelectedItems(selectedItems);
    handleClose();
  };

  return (
    <BaseTableDropdown
      id={id}
      title={title}
      counter={filterCount}
      disabled={disabled}
      hasSearch={hasSearch}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchFilter}
      onSearchChange={setSearchFilter}
      anchorEl={anchorEl}
      onDropdownClick={handleClick}
      onClose={handleClose}
      footer={
        <>
          <Button size={'small'} onClick={handleCancelClick}>
            {tGlobal('filterDropdown.btnCancel')}
          </Button>

          <Button size={'small'} variant={'contained'} onClick={handleApplyClick}>
            {tGlobal('filterDropdown.btnApply')}
          </Button>
        </>
      }
    >
      {hasSelectAll ? (
        <BaseMenuItem hasDivider sxMenuItem={{ padding: '8px 12px', height: '40px' }} onClick={handleSelectAllClick}>
          <Box display={'flex'} alignItems={'center'}>
            <MuiCheckbox checked={areAllChecked} inputProps={{ 'aria-label': 'controlled' }} />

            <Text variant={'bodyMd'} color={'#090E16'} m={0}>
              {tGlobal('filterDropdown.selectAll')}
            </Text>
          </Box>
        </BaseMenuItem>
      ) : null}

      <Box sx={{ maxHeight: '175px', overflow: 'auto' }}>
        {filteredMenuList.map((option) => (
          <BaseMenuItem
            key={option.key + option.label}
            sxMenuItem={{ padding: '8px 12px', height: '40px' }}
            onClick={() => handleItemClick(option)}
          >
            <MuiCheckbox checked={option.checked} inputProps={{ 'aria-label': 'controlled' }} />

            <Text component={'div'} variant={'bodyMd'} color={'#090E16'} m={0}>
              {option.label}
            </Text>
          </BaseMenuItem>
        ))}
      </Box>
    </BaseTableDropdown>
  );
};
