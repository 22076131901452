import { gql as gqlApollo } from '@apollo/client';

export const CONTACT_INFO_FRAGMENT = gqlApollo(`
  fragment contactInfoFragment on CompanyContactInfoRes {
    phoneNumber
    representative
  }
`);

export const GET_COMPANIES = gqlApollo`
  ${CONTACT_INFO_FRAGMENT}
  query GetCompanies($page: Int, $pageSize: Int, $regionIds: [String], $active: Boolean, $query: String, $column: String, $direction: Direction, $nullPrecedence: NullPrecedence) {
    dataItems: companies(page: $page, pageSize: $pageSize, regionIds: $regionIds, active: $active, query: $query, sort: { direction: $direction, column: $column, nullPrecedence: $nullPrecedence }) {
      items {
        id
        active
        address
        name
        domains {
          id
          name
        }
        contactInfo {
          ...contactInfoFragment
        }
        logo {
          url
        }
      }
      total
    }
  }
`;

export const CREATE_COMPANY = gqlApollo(`
  mutation CreateCompany($name: String!, $fileKey: String, $regionId: String, $domainIds: [String]!, $roleTemplateIds: [String]) {
    createCompany(company: { name: $name, fileKey: $fileKey, regionId: $regionId, domainIds: $domainIds, roleTemplateIds: $roleTemplateIds }) {
      id
      name
    }
  }
`);

export const CREATE_USER_FOR_COMPANY = gqlApollo(`
  mutation CreateUser($companyUid: String, $user: CreateUserReqInput!) {
    createUser(companyUid: $companyUid, user: $user) {
      email
      id
    }
  }
`);

export const GET_COMPANY = gqlApollo(`
  query GetCompany($companyId: String!) {
    company(id: $companyId) {
      active
      address
      id
      name
      language
      domains {
        id
        name
      }
      contactInfo {
        email
        phoneNumber
        representative
      }
      logo {
        url
        fileKey
      }
      users {
        items {
          email
          firstName
          id
          lastName
          permissionTag
        }
        total
      }
    }
  }
`);

export const UPDATE_COMPANY = gqlApollo(`
  mutation UpdateCompany($id: String!, $name: String, $address: String, $language: String, $fileKey: String, $contactInfo: UpdateCompanyContactInfoReqInput!) {
    updateCompany(
      id: $id,
      company: {
        name: $name
        address: $address
        language: $language
        fileKey: $fileKey
        contactInfo: $contactInfo
      }
    ) {
      active
      id
      name
    }
  }
`);

export const SWITCH_COMPANY_STATE = gqlApollo(`
  mutation SwitchCompanyState($companyId: String!, $isActive: Boolean!) {
    switchCompanyState(id: $companyId, isActive: $isActive)
  }
`);

export const MOVE_COMPANY_TO_REGION = gqlApollo(`
  mutation MoveCompanyToRegion($request: MoveCompanyReqInput!) {
    moveCompany(request: $request)
  }
`);
