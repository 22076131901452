import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  CREATE_REGION_RESOURCE_PERMISSION,
  GET_USERS,
  GET_USERS_WITH_ACCESS,
  GetUsersQuery,
  GetUsersWithAccessQuery,
  UPDATE_REGION_RESOURCE_PERMISSION
} from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteTags,
  ChevronLeftIcon,
  EMPTY_CELL_VALUE,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { UserItem } from './components/UserItem';

import { AssessTypeEnum, permissionOptions } from './helpers';

interface ManageAccessModalProps {
  folderId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  folderName?: string;
}

export const ManageAccessModal = ({ folderId, isModalOpen, folderName, onCloseModal }: ManageAccessModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tCompany } = useTranslation('company');
  const { company } = useReduxSelector(selectUserData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedPermission, setSelectedPermission] = useState<string>(AssessTypeEnum.canView);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleSelectedUsersChange = (value: string[]) => {
    setSelectedUsers(value);
  };

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const { data: dataUsers, loading: dataUsersLoading } = useQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      searchCriteria: {
        companyId: company?.id
      }
    }
  });

  const { data: dataUsersWithAccess, loading: dataUsersWithAccessLoading } = useQuery<GetUsersWithAccessQuery>(
    GET_USERS_WITH_ACCESS,
    {
      variables: {
        resourceId: folderId
      }
    }
  );

  const users = dataUsers?.dataItems.items;
  const usersWithAccess = dataUsersWithAccess?.dataItems;

  const [CreateUserPermission] = useMutation(CREATE_REGION_RESOURCE_PERMISSION, {
    variables: {
      resourceId: folderId?.length ? folderId : undefined
    },
    refetchQueries: [GET_USERS_WITH_ACCESS]
  });

  const [EditUserPermission] = useMutation(UPDATE_REGION_RESOURCE_PERMISSION, {
    variables: {
      resourceId: folderId?.length ? folderId : undefined
    },
    refetchQueries: [GET_USERS_WITH_ACCESS]
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();
  }, []);

  const handleChangeUserPermission = (item: {
    id: number;
    isDefaultValue: boolean;
    title: string;
    userId: string;
    value: string[];
  }) => {
    EditUserPermission({
      variables: {
        userId: item?.userId,
        access: item?.value[0]
      },
      onCompleted: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.success.userPermissionChange'),
          severity: 'success'
        });
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const handleSubmit = () => {
    CreateUserPermission({
      variables: {
        userIds: users?.filter((user) => selectedUsers.some((item) => item === user?.email)).map((user) => user?.id),
        access: selectedPermission
      },
      onCompleted: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.success.userPermissionChange'),
          severity: 'success'
        });

        handleCloseModal();
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const usersOptions = useMemo(() => {
    if (!users) {
      return [];
    }

    return users
      ?.filter((user) => !usersWithAccess?.some((item) => item?.user.id === user?.id))
      .map((user) => ({
        label: user?.email || '',
        value: user?.id || '',
        key: user?.id || ''
      }));
  }, [users]);

  return (
    <Dialog
      headerTitle={tCompany('actions.modal.manageAccess.title')}
      headerSubTitle={tCompany('actions.modal.manageAccess.subTitle', { value: folderName })}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tCompany('actions.modal.rename.btnCancel')}
          </Button>
          <Button variant={'contained'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleSubmit}>
            {tCompany('actions.modal.rename.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      {dataUsersLoading || dataUsersWithAccessLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', width: '100%' }}>
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '150px !important' }} isContainer />
        </Box>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <AutocompleteTags
              name="users"
              label={tCompany('actions.modal.manageAccess.autocompleteLabel')}
              placeholder={tCompany('actions.modal.manageAccess.autocompletePlaceholder')}
              options={usersOptions}
              multiple
              onChange={(_, value: any) => handleSelectedUsersChange(value)}
              popupIcon={
                <Box
                  onClick={handleClickButton}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: 'fit-content',
                    cursor: 'pointer'
                  }}
                >
                  <Box sx={{ display: 'inline-flex' }}>
                    <Text>
                      {tCompany(`actions.modal.manageAccess.permissionsMenu.${selectedPermission.toLowerCase()}`)}
                    </Text>
                    {open ? (
                      <ChevronLeftIcon sx={{ rotate: '90deg' }} />
                    ) : (
                      <ChevronLeftIcon sx={{ rotate: '-90deg' }} />
                    )}
                  </Box>
                  <Menu
                    id={'custom-task-item-menu'}
                    elevation={0}
                    anchorEl={anchorEl}
                    MenuListProps={{
                      'aria-labelledby': 'custom-task-item-menu-button'
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    sx={{
                      margin: '12px 0 0 0',
                      '.MuiMenu-paper': {
                        width: '200px',
                        minWidth: '200px'
                      }
                    }}
                    autoFocus={false}
                    open={open}
                    onClose={handleClose}
                  >
                    {permissionOptions.map((option, index) => (
                      <Box key={option.value}>
                        {index === permissionOptions.length - 1 && <Divider />}
                        <MenuItem
                          onClick={() => setSelectedPermission(option.value)}
                          selected={selectedPermission === option.value}
                        >
                          <Text m={0} variant={'bodyMd'}>
                            {option.label}
                          </Text>
                        </MenuItem>
                      </Box>
                    ))}
                  </Menu>
                </Box>
              }
            />
            <Box sx={{ marginTop: '28px' }}>
              <Text variant="bodyLg" sx={{ fontWeight: '600' }}>
                {tCompany('actions.modal.manageAccess.peopleTitle')}
              </Text>
              <Box
                sx={{
                  marginTop: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '10rem',
                  overflowY: 'auto'
                }}
              >
                {usersWithAccess?.map((item) => (
                  <UserItem
                    key={item?.user.id}
                    id={item?.user.id}
                    name={
                      item?.user?.firstName && item?.user?.lastName
                        ? `${item?.user?.firstName} ${item?.user?.lastName}`
                        : EMPTY_CELL_VALUE
                    }
                    userAvatar={item?.user?.userAvatar?.url}
                    accessType={item?.accessType}
                    onMenuItemClick={handleChangeUserPermission}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  );
};
