import Divider from '@mui/material/Divider';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';
import { SideBarGroupTitle } from '@/separatedModules/containers/Sidebar/style';

import { useIsUserCanCRUD, useIsUserCompanyAdmin, useIsUserSuperAdmin } from '@/utils';

import { LINKS_SIDEBAR_TEMPLATES_BOTTOM_MENU, LINKS_SIDEBAR_TEMPLATES_TOP_MENU } from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n/config';

import { NavListSimple } from '../NavListSimple';
import { NavListSimpleWithBtn } from '../NavListSimpleWithBtn';

export const SideBarTemplates = () => {
  const isUserSuperAdmin = useIsUserSuperAdmin();
  const isUserCompanyAdmin = useIsUserCompanyAdmin();

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const menuLinks = LINKS_SIDEBAR_TEMPLATES_TOP_MENU(isUserSuperAdmin, isUserCompanyAdmin).map((value) => {
    if (value.title === 'templates.equipment.title' && !isUserCanCRUD) {
      return {
        ...value,
        btnLinkRedirect: ''
      };
    }

    return value;
  });

  return (
    <Sidebar>
      <SideBarGroup>
        <SideBarGroupTitle>{i18n.t('templates.sidebarTitle', { ns: 'sidebar' })}</SideBarGroupTitle>

        <NavListSimpleWithBtn menu={menuLinks} insertValue={undefined} replaceValue={undefined} />
      </SideBarGroup>

      <Divider sx={{ mt: '8px', mb: '8px' }} />

      <SideBarGroup>
        <NavListSimple menu={LINKS_SIDEBAR_TEMPLATES_BOTTOM_MENU} insertValue={undefined} replaceValue={undefined} />
      </SideBarGroup>
    </Sidebar>
  );
};
