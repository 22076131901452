import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { SideBarProvider } from '../components';

import { Header } from './components/Header';

import { StyledSimpleLayout } from './style';

export const SimpleLayout = () => {
  const location = useLocation();
  const isShowSidebar = location.pathname.includes('/visibility');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  return isSmallScreen || isTablet ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        justifyContent: 'center'
      }}
    >
      <Outlet />
    </Box>
  ) : (
    <Suspense fallback={<Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isContainer isFullScreen />}>
      <Header />

      <StyledSimpleLayout>
        <div className={'layout-simple__container'}>
          {isShowSidebar && (
            <div className={'layout-base__left'}>
              <SideBarProvider />
            </div>
          )}
          <div className={'layout-simple__right'}>
            <Grid justifyContent={'center'} container>
              <Outlet />
            </Grid>
          </div>
        </div>
      </StyledSimpleLayout>
    </Suspense>
  );
};
