import { isBrowser } from '@/utils';

import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '@/subsidiaryBinders/constants';

export class LocalStorageServiceClass {
  getStorage() {
    return localStorage;
  }

  get(key: string) {
    const data = isBrowser() && localStorage.getItem(key);

    return isBrowser() && data ? JSON.parse(data) : null;
  }

  set(key: any, data: any) {
    if (key && data && isBrowser()) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  remove(key: string) {
    if (key && isBrowser()) localStorage.removeItem(key);
  }

  clearAuth() {
    if (isBrowser()) {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem(REFRESH_TOKEN_NAME);
    }
  }
}

export const LocalStorageService = new LocalStorageServiceClass();
