import { forwardRef, ReactNode } from 'react';

import { MenuItem as MenuItemMui, MenuItemProps } from '@mui/material';
import Box from '@mui/material/Box';

import { CustomCheckIcon } from '@/separatedModules/components';

interface BaseMenuItemProps extends MenuItemProps {
  children: ReactNode;
  // hasDivider?: boolean;
  checked?: boolean;
}

export const TableMenuItem = forwardRef<HTMLLIElement, BaseMenuItemProps>(
  ({ children = false, sx, checked, ...restProps }, ref) => {
    return (
      <MenuItemMui ref={ref} sx={{ display: 'flex', flexWrap: 'wrap', ...sx }} selected={checked} {...restProps}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>

        {checked && <CustomCheckIcon className={'m-0'} />}
      </MenuItemMui>
    );
  }
);
