import { TableColumn } from '@/separatedModules/pages/Users/components';

import { skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n/config';

export const tableColumns: TableColumn[] = [
  { key: 'name', title: i18n.t('table.cells.name', { ns: 'users' }) },
  { key: 'email', title: i18n.t('table.cells.email', { ns: 'users' }) },
  { key: 'phoneNumber', title: i18n.t('table.cells.phoneNumber', { ns: 'users' }) },
  { key: 'role', title: i18n.t('table.cells.role', { ns: 'users' }) },
  { key: 'domain', title: i18n.t('table.cells.domain', { ns: 'users' }) }
];

export const skeletonTableLoaderCells = [
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
