export const Project = {
  root: {}
};

export const Base = {
  root: {
    color: '#000',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    margin: '0 0 12px'
  }
};
