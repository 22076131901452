import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Grid } from '@mui/material';

import {
  EquipmentParameterQuery,
  EquipmentQuery,
  GET_EQUIPMENT,
  GET_EQUIPMENT_PARAMETER,
  GET_USER_PROFILE,
  GetProfileQuery
} from '@/logicLayers/domain';

import {
  Breadcrumbs,
  ChevronRightIcon,
  ImageLazy,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { RowActionMenu } from '../Parameters/components';

import { EditItemHoverParameter } from './components/EditItemHoverParameter';
import { ParameterTypeBox } from './components/ParameterTypeBox';

import { checkIsFormulaOrFixed, getParamItemConfig } from '../Parameters/helpers';
import { PARAMETER_TYPE, TYPE_LIST } from './helpers';

export const TemplatesEquipmentItemParameterPage = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '', parameterId = '' } = useParams();
  const TABLE_SAVED_COLUMNS_ID = `/templates/equipment/item/${templateId}/parameters`;
  const navigateTo = useNavigate();
  const location = useLocation();
  const isEquipmentTemplate = location.pathname.includes('item');

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });
  const { data: dataUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const isShowEditItem = dataUserProfile?.profile.companyId === dataEquipmentTemplate?.equipment.companyId;
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { data: dataEquipmentTemplateParameter, loading } = useQuery<EquipmentParameterQuery>(GET_EQUIPMENT_PARAMETER, {
    fetchPolicy: 'no-cache',
    variables: {
      id: parameterId || undefined
    }
  });

  const renderItemName = dataEquipmentTemplateParameter?.dataItems?.title;
  const renderParameterType = dataEquipmentTemplateParameter?.dataItems.type || '';
  const renderItemActive = Boolean(dataEquipmentTemplateParameter?.dataItems?.active);
  const renderItemTemplateDescription = dataEquipmentTemplateParameter?.dataItems?.description;
  const renderItemCreatedDate = dataEquipmentTemplateParameter?.dataItems.createdDate;
  const renderItemValue = dataEquipmentTemplateParameter?.dataItems.value;
  const renderItemFormula = dataEquipmentTemplateParameter?.dataItems.readableFormula;
  const renderItemUnitName = dataEquipmentTemplateParameter?.dataItems.unit?.name;
  const isNumeric = renderParameterType.includes('NUMERIC');

  const breadcrumbsTemplateLinks = [
    { link: LINKS_PAGES.templatesEquipment, titleFallback: tTemplates('equipment.pageTitle') },
    { link: TABLE_SAVED_COLUMNS_ID.replace('/parameters', ''), titleFallback: dataEquipmentTemplate?.equipment.name },
    { link: TABLE_SAVED_COLUMNS_ID, titleFallback: 'Parameters' },
    { titleFallback: renderItemName || '' }
  ];

  const breadcrumbsItemLinks = [
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemTitle') },
    { link: LINKS_PAGES.locations, titleFallback: tTemplates('equipment.pageItemAllLocations') },
    {
      link: LINKS_PAGES.equipmentModel.replace(':templateId', templateId),
      titleFallback: dataEquipmentTemplate?.equipment?.name
    },
    {
      link: LINKS_PAGES.equipmentItemParameters.replace(':templateId', templateId),
      titleFallback: tTemplates('equipment.pageItemParameters')
    },
    { titleFallback: renderItemName || '' }
  ];

  const breadcrumbs = isEquipmentTemplate ? breadcrumbsTemplateLinks : breadcrumbsItemLinks;

  const handleEditItem = (toPage: string) => {
    const pathname = LINKS_PAGES.templatesEquipmentItemParameterEdit
      .replace(':templateId', templateId)
      .replace(':parameterId', parameterId);

    navigateTo({
      pathname,
      search: `field=${toPage}`
    });
  };

  const handleEditName = () => handleEditItem('parameterTitle');
  const handleEditGuide = () => handleEditItem('guide');
  const handleEditUnit = () => handleEditItem('unit');
  const handleEditFormula = () => handleEditItem('formula');
  const handleEditNumericValue = () => handleEditItem('numericValue');

  const imageUrl = TYPE_LIST[renderParameterType];

  if (loading) return null;

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} lg={10} xl={8} item>
        <Breadcrumbs
          links={breadcrumbs}
          separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
          sx={{ paddingTop: 0 }}
        />
        <Text component={'h2'} variant={'titleXl'}>
          {renderItemName}
        </Text>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '32px' }}>
          <Box
            sx={{
              width: '80px',
              height: '80px',
              display: 'flex',
              alignItems: 'center',
              margin: '8px 24px 0 0'
            }}
          >
            <img src={imageUrl} alt={'parameter-type'} width="100%" />
          </Box>
          <Box
            sx={{
              flex: '0 1 100%',
              maxWidth: {
                md: 'calc(100% - 204px)'
              }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: !renderItemTemplateDescription?.length ? '0 0 32px 0' : '0 0 8px 0'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {renderItemName ? (
                  <EditItemHoverParameter
                    value={renderItemName}
                    isBold
                    handleClick={handleEditName}
                    isDisabled={!isShowEditItem || !isUserCanCRUD}
                  />
                ) : null}

                <SystemStatusChip
                  status={renderItemActive ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled}
                />
              </Box>

              {isShowEditItem && isUserCanCRUD && (
                <RowActionMenu
                  {...(dataEquipmentTemplateParameter?.dataItems as any)}
                  isTableVariant={false}
                  isParameter
                  renderModalContent={() => {
                    const paramItemConfig = getParamItemConfig({
                      original: {
                        type: renderParameterType
                      }
                    });

                    return (
                      <Box
                        sx={{
                          borderRadius: 'var(--4, 4px)',
                          background: 'var(--background-neutral-hovered, #F7F8F9)',
                          padding: '8px',
                          margin: '24px 0 0',
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {paramItemConfig?.logoPath ? (
                            <ImageLazy
                              src={paramItemConfig?.logoPath}
                              alt={`Equipment item parameter ${renderItemName} type preview`}
                              variant={'rectangular'}
                              width={32}
                              height={32}
                              sxStyle={{
                                margin: '0 8px 0 0',
                                display: 'flex'
                              }}
                            />
                          ) : null}

                          <Text variant={'labelLg'} m={0}>
                            {renderItemName}
                          </Text>
                        </Box>

                        <Box>
                          <ParameterTypeBox title={isNumeric ? 'Numeric data' : PARAMETER_TYPE[renderParameterType]} />

                          {isNumeric ? <ParameterTypeBox title={PARAMETER_TYPE[renderParameterType]} /> : null}
                        </Box>
                      </Box>
                    );
                  }}
                />
              )}
            </Box>

            {renderItemTemplateDescription?.length ? (
              <Box sx={{ margin: '0 0 32px' }}>
                <EditItemHoverParameter
                  value={renderItemTemplateDescription}
                  handleClick={handleEditGuide}
                  sx={{ maxWidth: '630px' }}
                  isDisabled={!isShowEditItem || !isUserCanCRUD}
                />
              </Box>
            ) : null}

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {'Type'}
              </Text>

              <ParameterTypeBox title={isNumeric ? 'Numeric data' : PARAMETER_TYPE[renderParameterType]} />

              {isNumeric ? <ParameterTypeBox title={PARAMETER_TYPE[renderParameterType]} /> : null}
            </Box>

            {isNumeric ? (
              <Box sx={{ margin: '0 0 32px' }}>
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                  {'Unit'}
                </Text>

                <EditItemHoverParameter
                  value={renderItemUnitName}
                  handleClick={handleEditUnit}
                  isDisabled={!isShowEditItem || !isUserCanCRUD}
                />
              </Box>
            ) : null}

            {renderItemValue ? (
              <Box sx={{ margin: '0 0 32px' }}>
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                  {renderParameterType === 'NUMERIC_FORMULA' ? 'Formula' : 'Numeric value'}
                </Text>

                <EditItemHoverParameter
                  value={renderParameterType === 'NUMERIC_FORMULA' ? renderItemFormula : renderItemValue}
                  handleClick={() =>
                    renderParameterType === 'NUMERIC_FORMULA' ? handleEditFormula() : handleEditNumericValue()
                  }
                  isDisabled={!isShowEditItem || !isUserCanCRUD}
                />
              </Box>
            ) : null}

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {'Visibility in round form'}
              </Text>

              {checkIsFormulaOrFixed({
                original: {
                  type: renderParameterType
                }
              })}
            </Box>

            <Box sx={{ margin: '0 0 32px' }}>
              <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
                {'Creation date'}
              </Text>

              <Text className={'m-0'} variant={'bodyLg'}>
                {dayjs(renderItemCreatedDate).format('DD MMM YYYY, HH:mm A')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
