import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function MagnifyingGlassIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_112_8428)">
          <path
            d="M5.16289 194.841C1.8332 191.512 0 187.086 0 182.377C0 177.669 1.8332 173.243 5.16289 169.913L134.015 41.0613C136.303 38.773 140.013 38.7734 142.301 41.0613L158.943 57.7031C160.042 58.8019 160.66 60.2921 160.66 61.846C160.66 63.4 160.043 64.8902 158.943 65.989L30.091 194.841C26.6547 198.278 22.1406 199.996 17.627 199.996C13.1133 199.996 8.59922 198.278 5.16289 194.841Z"
            fill="#374151"
          />
          <path
            d="M158.943 57.7034C160.041 58.8022 160.659 60.2925 160.659 61.8464C160.659 63.4003 160.042 64.8905 158.943 65.9894L30.0902 194.842C26.6539 198.278 22.1398 199.996 17.6262 199.996C13.1125 199.996 8.59844 198.278 5.16211 194.842L150.621 49.3823L158.943 57.7034Z"
            fill="#1F2937"
          />
          <path
            d="M123.535 152.934C165.766 152.934 200 118.7 200 76.4692C200 34.2389 165.766 0.00439453 123.535 0.00439453C81.3048 0.00439453 47.0703 34.2389 47.0703 76.4692C47.0703 118.7 81.3048 152.934 123.535 152.934Z"
            fill="#BB69CC"
          />
          <path
            d="M177.579 22.4253C191.425 36.2714 200 55.3874 200 76.469C200 118.632 165.698 152.934 123.536 152.934C102.454 152.934 83.3379 144.358 69.4922 130.512L177.579 22.4253Z"
            fill="#6B1C9F"
          />
          <path
            d="M123.535 129.399C152.767 129.399 176.465 105.701 176.465 76.4692C176.465 47.237 152.767 23.5396 123.535 23.5396C94.3029 23.5396 70.6055 47.237 70.6055 76.4692C70.6055 105.701 94.3029 129.399 123.535 129.399Z"
            fill="#FEF8FF"
          />
          <path
            d="M123.534 129.399C108.942 129.399 95.7094 123.463 86.125 113.879L160.944 39.0601C170.528 48.6444 176.464 61.8769 176.464 76.4694C176.464 105.655 152.72 129.399 123.534 129.399Z"
            fill="#F3E5F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_112_8428">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
