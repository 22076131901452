import { useNavigate } from 'react-router-dom';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { PageTitle, PlusIcon } from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { useContextState } from '../Providers';

export const PageTopBox = () => {
  const { t: tTemplates } = useTranslation('templates');
  const navigateTo = useNavigate();

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const state = useContextState();

  const handleClick = () => {
    navigateTo(LINKS_PAGES.templatesEquipmentCreate);
  };

  const count = state.filters.search.length ? state.resultSearchCount : state.resultTotalCount;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={tTemplates('equipment.pageTitle')}
        dataFoundTitle={
          !state.loadingEquipmentTemplates ? i18n.t('pageTitleFound', { value: count ?? 0, ns: 'global' }) : ''
        }
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
      />

      <Button
        className={'m-0'}
        variant={'contained'}
        startIcon={<PlusIcon />}
        disabled={!isUserCanCRUD}
        onClick={handleClick}
      >
        {tTemplates('equipment.btnCreateEquipment')}
      </Button>
    </Box>
  );
};
