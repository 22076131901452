import { FC } from 'react';

import { ModalDiscardAllChanges } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { EditName, EditPassword, EditPhone } from './components';

import { StyledEdit } from './style';

interface EditProps {
  type: string;
}

const Component: { [key: string]: FC } = {
  password: EditPassword,
  name: EditName,
  phone: EditPhone
};

export const Edit = ({ type }: EditProps) => {
  const SelectedComponent = Component[type];

  return (
    <StyledEdit>
      <ModalDiscardAllChanges navigateToUrl={LINKS_PAGES.profile} />

      {SelectedComponent ? <SelectedComponent /> : null}
    </StyledEdit>
  );
};
