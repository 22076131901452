export const Project = {
  root: {}
};

export const Base = {
  root: {
    '&.Mui-disabled': {
      fill: '#090E16',
      color: '#090E16',
      opacity: '0.3'
    }
  },
  colorDisabled: {
    fill: '#090E16',
    color: '#090E16',
    opacity: '0.3'
  }
};
