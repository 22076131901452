export const COMPANY_ITEM_FIELDS = {
  // ToDo
  LOGO: 'logo',
  NAME: 'name',
  ADDRESS: 'address',
  REPRESENTATIVE: 'representative',
  PHONE_NUMBER: 'phone',
  EMAIL: 'email',
  // ToDo
  LANGUAGE: 'language'
};
