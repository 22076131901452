import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function WelcomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M169.239 49.0805H142.56C138.35 49.0805 134.938 45.6676 134.938 41.4578V32.1578C134.938 20.5992 144.341 11.1953 155.9 11.1953C167.459 11.1953 176.863 20.5992 176.863 32.1578V41.4574C176.862 45.6676 173.45 49.0805 169.239 49.0805Z"
          fill="#6EE7B7"
        />
        <path
          d="M169.239 188.805H142.56C138.35 188.805 134.938 185.392 134.938 181.182V134.069C134.938 129.859 138.35 126.446 142.56 126.446H169.239C173.449 126.446 176.862 129.859 176.862 134.069V181.182C176.862 185.392 173.45 188.805 169.239 188.805Z"
          fill="#6EE7B7"
        />
        <path
          d="M57.4387 49.0805H30.7594C26.5496 49.0805 23.1367 45.6676 23.1367 41.4578V32.1578C23.1367 20.5992 32.5406 11.1953 44.0992 11.1953C55.6578 11.1953 65.0617 20.5992 65.0617 32.1578V41.4574C65.0617 45.6676 61.6484 49.0805 57.4387 49.0805Z"
          fill="#6EE7B7"
        />
        <path
          d="M57.4387 188.805H30.7594C26.5496 188.805 23.1367 185.392 23.1367 181.182V134.069C23.1367 129.859 26.5496 126.446 30.7594 126.446H57.4387C61.6484 126.446 65.0613 129.859 65.0613 134.069V181.182C65.0617 185.392 61.6484 188.805 57.4387 188.805Z"
          fill="#6EE7B7"
        />
        <path
          d="M182.214 141.691H17.7863C7.97891 141.691 0 133.712 0 123.905V51.6213C0 41.8139 7.97891 33.835 17.7863 33.835H182.214C192.021 33.835 200 41.8139 200 51.6213V123.905C200 133.712 192.021 141.691 182.214 141.691Z"
          fill="#34D399"
        />
        <path
          d="M182.214 33.835H100V141.691H182.213C192.021 141.691 200 133.712 200 123.905V51.6213C200 41.8139 192.021 33.835 182.214 33.835Z"
          fill="#10B981"
        />
        <path
          d="M45.5194 96.3381C44.7241 98.6565 39.4417 98.4831 38.9511 96.3381L37.2773 88.7077L35.6027 96.3381C35.105 98.5135 29.753 98.6249 29.0351 96.3381L22.628 76.6991C21.9648 74.7143 26.8609 73.2452 27.4253 75.2182L32.3191 91.7346L34.8624 80.8526C35.2523 79.0975 39.3023 79.0987 39.6917 80.8526L42.2351 91.7346L47.1288 75.2182C47.6909 73.2495 52.5819 74.7065 51.9261 76.6991L45.5194 96.3381Z"
          fill="white"
        />
        <path
          d="M61.427 84.1037H66.2563C68.2418 84.1037 68.3031 87.9674 66.2563 87.9674H61.427V93.4404H70.0555C72.0461 93.4404 72.1082 97.8189 70.0555 97.8189H58.5941C57.4996 97.8189 56.4043 97.3037 56.4043 96.2736V75.8295C56.4043 74.7994 57.4988 74.2842 58.5941 74.2842H70.0555C72.1094 74.2842 72.0449 78.6627 70.0555 78.6627H61.427V84.1037Z"
          fill="white"
        />
        <path
          d="M78.5238 97.8189C77.4293 97.8189 76.334 97.3037 76.334 96.2736V75.7971C76.334 74.7346 77.5898 74.2842 78.8449 74.2842C80.1012 74.2842 81.3562 74.735 81.3562 75.7971V93.44H88.6965C90.6285 93.44 90.6285 97.8186 88.6965 97.8186H78.5238V97.8189Z"
          fill="white"
        />
        <path
          d="M102.061 78.6626C100.225 78.6626 99.1629 79.6927 99.1629 81.8825V90.4145C99.1629 92.6036 100.225 93.6337 102.093 93.6337C104.668 93.6337 104.83 91.6696 104.926 90.4145C105.022 89.2235 106.118 88.9013 107.405 88.9013C109.144 88.9013 109.948 89.352 109.948 91.2841C109.948 99.7899 94.1406 101.244 94.1406 90.4145V81.8825C94.1406 71.0298 109.948 72.6419 109.948 80.6915C109.948 82.6231 109.143 83.0739 107.438 83.0739C106.085 83.0739 104.99 82.7196 104.926 81.5606C104.893 80.7231 104.797 78.6626 102.061 78.6626Z"
          fill="white"
        />
        <path
          d="M114.811 90.4142V81.8821C114.811 76.3446 118.255 74.2837 122.699 74.2837C127.142 74.2837 130.619 76.3442 130.619 81.8821V90.4142C130.619 95.9517 127.142 98.0126 122.699 98.0126C118.255 98.0122 114.811 95.9521 114.811 90.4142ZM125.595 81.8825C125.595 79.6606 124.501 78.6626 122.698 78.6626C120.895 78.6626 119.832 79.6606 119.832 81.8825V90.4146C119.832 92.6356 120.895 93.6337 122.698 93.6337C124.501 93.6337 125.595 92.6356 125.595 90.4146V81.8825Z"
          fill="white"
        />
        <path
          d="M147.748 89.4808C147.029 90.6979 145.173 90.7776 144.497 89.4808L141.439 83.8784V96.2741C141.439 98.3347 136.416 98.3347 136.416 96.2741V76.7956C136.416 74.9604 137.672 74.2847 138.927 74.2847C140.73 74.2847 141.503 74.4776 142.823 76.7956L146.139 82.5909L149.455 76.7956C150.775 74.4772 151.547 74.2847 153.351 74.2847C154.638 74.2847 155.862 74.9604 155.862 76.7956V96.2741C155.862 98.3347 150.839 98.3347 150.839 96.2741V84.2007L147.748 89.4808Z"
          fill="white"
        />
        <path
          d="M166.939 84.1037H171.768C173.754 84.1037 173.815 87.9674 171.768 87.9674H166.939V93.4404H175.567C177.558 93.4404 177.62 97.8189 175.567 97.8189H164.106C163.011 97.8189 161.916 97.3037 161.916 96.2736V75.8295C161.916 74.7994 163.011 74.2842 164.106 74.2842H175.567C177.62 74.2842 177.557 78.6627 175.567 78.6627H166.939V84.1037Z"
          fill="white"
        />
        <path
          d="M107.404 88.9019C106.119 88.9019 105.021 89.2222 104.924 90.4163C104.828 91.6714 104.67 93.6331 102.094 93.6331C101.204 93.6331 100.498 93.3995 100 92.9116V97.9171C104.502 98.8421 109.95 96.2808 109.95 91.2854C109.95 89.3534 109.142 88.9019 107.404 88.9019ZM100 74.3878V79.373C100.488 78.8948 101.184 78.6616 102.058 78.6616C104.797 78.6616 104.894 80.7249 104.924 81.5581C104.99 82.7167 106.083 83.0726 107.435 83.0726C109.142 83.0726 109.95 82.6202 109.95 80.6894C109.95 75.9628 104.498 73.4577 100 74.3878Z"
          fill="white"
        />
        <path
          d="M114.812 90.4142V81.8821C114.812 76.3446 118.257 74.2837 122.701 74.2837C127.144 74.2837 130.621 76.3442 130.621 81.8821V90.4142C130.621 95.9517 127.144 98.0126 122.701 98.0126C118.257 98.0122 114.812 95.9521 114.812 90.4142ZM125.597 81.8825C125.597 79.6606 124.503 78.6626 122.7 78.6626C120.897 78.6626 119.834 79.6606 119.834 81.8825V90.4146C119.834 92.6356 120.897 93.6337 122.7 93.6337C124.503 93.6337 125.597 92.6356 125.597 90.4146V81.8825Z"
          fill="white"
        />
        <path
          d="M147.748 89.4808C147.029 90.6979 145.173 90.7776 144.497 89.4808L141.439 83.8784V96.2741C141.439 98.3347 136.416 98.3347 136.416 96.2741V76.7956C136.416 74.9604 137.672 74.2847 138.927 74.2847C140.73 74.2847 141.503 74.4776 142.823 76.7956L146.139 82.5909L149.455 76.7956C150.775 74.4772 151.547 74.2847 153.351 74.2847C154.638 74.2847 155.862 74.9604 155.862 76.7956V96.2741C155.862 98.3347 150.839 98.3347 150.839 96.2741V84.2007L147.748 89.4808Z"
          fill="white"
        />
        <path
          d="M166.939 84.1032H171.768C173.754 84.1032 173.815 87.9669 171.768 87.9669H166.939V93.4399H175.567C177.558 93.4399 177.62 97.8185 175.567 97.8185H164.106C163.011 97.8185 161.916 97.3032 161.916 96.2731V75.829C161.916 74.7989 163.011 74.2837 164.106 74.2837H175.567C177.62 74.2837 177.557 78.6622 175.567 78.6622H166.939V84.1032Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
