import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CompanyIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M4.13477 3.54175H15.8656C16.0426 3.54175 16.1911 3.60167 16.3109 3.7215C16.4306 3.84135 16.4905 3.98985 16.4905 4.167C16.4905 4.34417 16.4306 4.49257 16.3109 4.61223C16.1911 4.73188 16.0426 4.79171 15.8656 4.79171H4.13477C3.9577 4.79171 3.80927 4.73179 3.68948 4.61196C3.56969 4.49211 3.50979 4.34361 3.50979 4.16646C3.50979 3.98929 3.56969 3.84088 3.68948 3.72123C3.80927 3.60158 3.9577 3.54175 4.13477 3.54175ZM4.29504 16.4584C4.08164 16.4584 3.90276 16.3862 3.7584 16.2418C3.61404 16.0975 3.54186 15.9186 3.54186 15.7052V11.4584H3.28384C3.04666 11.4584 2.85141 11.3657 2.69811 11.1803C2.54479 10.995 2.49591 10.7837 2.55146 10.5465L3.38479 6.63629C3.42277 6.46287 3.51136 6.32043 3.65057 6.20896C3.78979 6.09748 3.94799 6.04175 4.12517 6.04175H15.8752C16.0524 6.04175 16.2106 6.09748 16.3498 6.20896C16.489 6.32043 16.5776 6.46287 16.6155 6.63629L17.4489 10.5465C17.5044 10.7837 17.4556 10.995 17.3022 11.1803C17.1489 11.3657 16.9537 11.4584 16.7165 11.4584H16.4585V15.8334C16.4585 16.0105 16.3986 16.1589 16.2787 16.2787C16.1589 16.3985 16.0104 16.4584 15.8332 16.4584C15.6561 16.4584 15.5077 16.3985 15.388 16.2787C15.2684 16.1589 15.2085 16.0105 15.2085 15.8334V11.4584H11.4585V15.7052C11.4585 15.9186 11.3863 16.0975 11.2419 16.2418C11.0976 16.3862 10.9187 16.4584 10.7053 16.4584H4.29504ZM4.79182 15.2084H10.2085V11.4584H4.79182V15.2084ZM3.88796 10.2084H16.1124L15.4761 7.29171H4.52419L3.88796 10.2084Z" />
    </SvgIcon>
  );
}
