import { createContext, useCallback, useContext, useMemo } from 'react';

import { MenuOption } from '@/separatedModules/components';

import { useContextState } from './ContextState';

import { CONFIG_TABLE } from '../../helpers';
import { ContextUpdateStateI, initState, TableProviderProps } from './helpers';

export const ContextUpdateState = createContext<ContextUpdateStateI>({
  handleChangeBySearch: () => {},
  handleChangePagination: () => {},
  handleChangeRowsPerPage: () => {},
  handleChangeSortByDomains: () => {},
  handleChangeSortByDate: () => {},
  handleClickRow: () => {},
  handleDoubleClickRow: () => {},
  handleResetSelectedRows: () => {},
  handleToggleActions: () => {},
  handleToggleAside: () => {}
});

export const useContextUpdateState: any = () => useContext(ContextUpdateState);

export const ProviderUpdateState = ({ children }: TableProviderProps) => {
  const { setState } = useContextState();
  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: [newPage]
        }
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: initState.pagination.page,
          rowsPerPage: [newRowsPerPage]
        }
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist ? [] : [rowId];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleChangeSortByDomains = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDomains: options?.length ? [...options] : []
        },
        pagination: {
          ...state.pagination,
          page: CONFIG_TABLE.pagination.page,
          rowsPerPage: CONFIG_TABLE.pagination.rowsPerPage
        }
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: MenuOption[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleToggleActions = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        isVisibleActions: !state.isVisibleActions
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const contextValue = useMemo(
    () => ({
      handleChangeBySearch,
      handleChangePagination,
      handleChangeRowsPerPage,
      handleChangeSortByDomains,
      handleChangeSortByDate,
      handleClickRow,
      handleDoubleClickRow,
      handleResetSelectedRows,
      handleToggleActions,
      handleToggleAside
    }),
    []
  );

  return <ContextUpdateState.Provider value={contextValue}>{children}</ContextUpdateState.Provider>;
};
