import { FC, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';

import { ParameterType } from '@/logicLayers/domain';

import { Flex, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from '../../../ContextProvider';
import { ParameterPlate } from '../../ParameterPlate';

import { PARAMETER_LIST } from './helpers';

export const Step1: FC = () => {
  const { t: tTemplates } = useTranslation('templates');

  const { handleSetParameterType } = useContextState();

  const [selectedId, setSelectedId] = useState(0);

  const selectParameterHandled = (id: number, type: ParameterType) => {
    setSelectedId(id);
    handleSetParameterType(type);
  };

  useEffect(() => {
    handleSetParameterType(PARAMETER_LIST[0].parameterType);
  }, []);

  return (
    <Stack minHeight={'70vh'}>
      <TextBlockControl
        title={tTemplates('createParameterItem.step1.title')}
        subTitle={tTemplates('createParameterItem.step1.description')}
        sxBox={{ marginTop: '24px' }}
        sxSubTitle={{ marginBottom: 0, maxWidth: 660 }}
      />

      <Flex flexWrap={'wrap'} gap={'24px'}>
        {PARAMETER_LIST.map((parameter) => (
          <ParameterPlate
            key={parameter.id}
            parameter={parameter}
            selectedId={selectedId}
            onClick={() => selectParameterHandled(parameter.id, parameter.parameterType as ParameterType)}
          />
        ))}
      </Flex>
    </Stack>
  );
};
