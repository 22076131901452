import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { CompanyLogoName, CreateButton, HeaderMenu } from './components';

export const LeftPart = () => {
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  return (
    <Grid className={'header__left'} xl={7} item>
      <Link className={'d-inline-block'} to={LINKS_PAGES.home}>
        <CompanyLogoName />
      </Link>

      <HeaderMenu />

      {isUserCanCRUD && <CreateButton />}
    </Grid>
  );
};
