import { MouseEvent } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { ChevronRightIcon, FolderIcon } from '@/separatedModules/components';

import { iconFolderColorCheck } from './helpers';

import { FolderItem } from './style';

interface ClickableItemProps {
  folderId: string;
  folderName: string;
  selectedFolderId: string;
  disabled?: boolean;
  onSelectLocation: (folderId: string, folderName: string) => void;
  onClickNextLocation: (folderId: string, folderName: string) => void;
}

export const ClickableItem = ({
  folderId,
  folderName,
  disabled = false,
  selectedFolderId,
  onSelectLocation,
  onClickNextLocation
}: ClickableItemProps) => {
  const handleClickNextLocation = (e: MouseEvent<HTMLButtonElement>, id: string, name: string) => {
    e.stopPropagation();
    onClickNextLocation(id, name);
  };

  const handleClickFolderItem = () => {
    onSelectLocation(folderId, folderName);
  };

  return (
    <FolderItem disabled={disabled} selected={selectedFolderId === folderId} onClick={handleClickFolderItem}>
      <Box display={'flex'} alignItems={'center'}>
        <FolderIcon
          aria-disabled={true}
          sx={{
            mr: '8px',
            color: iconFolderColorCheck(selectedFolderId, folderId, disabled),
            fill: disabled ? 'rgba(9, 14, 22, 0.30)' : '#313849'
          }}
        />
        <Text variant={'labelLg'} m={0} color={disabled ? 'rgba(9, 14, 22, 0.30)' : '#090E16'}>
          {folderName}
        </Text>
      </Box>

      <IconButton
        className={'clickableItem'}
        sx={{ width: '24px', height: '24px', display: selectedFolderId === folderId ? 'flex' : 'none' }}
        onClick={(e) => handleClickNextLocation(e, folderId, folderName)}
      >
        <ChevronRightIcon />
      </IconButton>
    </FolderItem>
  );
};
