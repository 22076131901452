import { SxProps, Theme } from '@mui/material/styles';

interface SkeletonLoaderCellI {
  variant: 'text' | 'rectangular' | 'rounded' | 'circular';
  sx?: SxProps<Theme>;
}

export const skeletonTableCellImage: SkeletonLoaderCellI = {
  variant: 'circular',
  sx: { flex: '0 0 24px', width: '24px', height: '24px' }
};

export const skeletonTableCellRect: SkeletonLoaderCellI = {
  variant: 'rounded',
  sx: { flex: '0 1 100%', height: '24px', width: '100%' }
};

// For Example
/*
export const cellsTest = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
*/

export interface SkeletonTableLoaderProps {
  rows?: number;
  cells: SkeletonLoaderCellI[][];
}
