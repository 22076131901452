import { AutocompleteOptionT } from '@/separatedModules/components';

// type Keys = keyof typeof FIELDS;
// type Values = (typeof FIELDS)[Keys];

export const FORM_ID = 'create-equipment-item-template';

export const FIELD_EQUIPMENT_TITLE = 'name';
export const FIELD_EQUIPMENT_MODEL_NUMBER = 'modelNumber';
export const FIELD_EQUIPMENT_MANUFACTURER = 'manufacturer';
export const FIELD_EQUIPMENT_ITEM_DESCRIPTION = 'itemDescription';
export const FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION = 'templateDescription';
export const FIELD_EQUIPMENT_SPECIFICATION = 'specifications';
export const FIELD_EQUIPMENT_FILE_KEY = 'fileKey';
export const FIELD_EQUIPMENT_DOMAIN_ID = 'domainId';

export interface FormProps {
  [FIELD_EQUIPMENT_TITLE]: string;
  [FIELD_EQUIPMENT_MODEL_NUMBER]: string;
  [FIELD_EQUIPMENT_MANUFACTURER]: string;
  [FIELD_EQUIPMENT_ITEM_DESCRIPTION]: string;
  [FIELD_EQUIPMENT_TEMPLATE_DESCRIPTION]: string;
  [FIELD_EQUIPMENT_SPECIFICATION]: string;
  [FIELD_EQUIPMENT_FILE_KEY]: string;
  [FIELD_EQUIPMENT_DOMAIN_ID]: AutocompleteOptionT[];
}

export const DEFAULT_VALUES: FormProps = {
  name: '',
  modelNumber: '',
  manufacturer: '',
  itemDescription: '',
  templateDescription: '',
  specifications: '',
  fileKey: '',
  domainId: []
};

export interface StateI {
  currentStep: number;
  totalSteps: number;
  fileKey: string;
  fileUrl: string;
  isLoading: boolean;
}
