import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { LogoIcon } from '@/separatedModules/components';

import { useIsUserSuperAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { HeaderMenu } from './components/HeaderMenu';

export const LeftPart = () => {
  const isUserSuperAdmin = useIsUserSuperAdmin();

  return (
    <Grid className={'header__left'} lg={6} item>
      <Link className={'d-inline-block'} to={LINKS_PAGES[isUserSuperAdmin ? 'users' : 'home']}>
        <LogoIcon sx={{ color: 'var(--white)', width: '50px', height: '40px' }} />
      </Link>

      <HeaderMenu />
    </Grid>
  );
};
