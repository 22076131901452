import { FC } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { RoleCreationPlateWrapper } from '@/separatedModules/pages/User/pages/UserRoleManagement/components/RolePlate/style';

import { Flex, GroupIllustrationIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface RoleCreationPlateProps {
  onClick: () => void;
}

export const RoleCreationPlate: FC<RoleCreationPlateProps> = ({ onClick }) => {
  const { t: tUser } = useTranslation('user');

  return (
    <RoleCreationPlateWrapper onClick={onClick}>
      <GroupIllustrationIcon sx={{ fontSize: 48 }} />
      <Flex flexDirection={'column'} pl={'16px'}>
        <Text className={'m-0'} variant={'titleMd'}>
          {tUser('roleManagement.createRole.title')}
        </Text>
        <Text className={'m-0'} variant={'bodyMd'} color={'#505668'}>
          {tUser('roleManagement.createRole.description')}
        </Text>
      </Flex>
    </RoleCreationPlateWrapper>
  );
};
