import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DeleteTrashIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M7.3077 20.4998C6.80898 20.4998 6.38302 20.3232 6.02982 19.97C5.67661 19.6168 5.5 19.1908 5.5 18.6921V5.99981H5.25C5.03718 5.99981 4.85898 5.92802 4.7154 5.78443C4.5718 5.64085 4.5 5.46265 4.5 5.24983C4.5 5.03702 4.5718 4.85882 4.7154 4.71523C4.85898 4.57163 5.03718 4.49983 5.25 4.49983H8.99997C8.99997 4.25497 9.08619 4.04632 9.25863 3.87388C9.43106 3.70145 9.63971 3.61523 9.88457 3.61523H14.1154C14.3602 3.61523 14.5689 3.70145 14.7413 3.87388C14.9138 4.04632 15 4.25497 15 4.49983H18.75C18.9628 4.49983 19.141 4.57163 19.2845 4.71523C19.4281 4.85882 19.5 5.03702 19.5 5.24983C19.5 5.46265 19.4281 5.64085 19.2845 5.78443C19.141 5.92802 18.9628 5.99981 18.75 5.99981H18.5V18.6921C18.5 19.1908 18.3233 19.6168 17.9701 19.97C17.6169 20.3232 17.191 20.4998 16.6922 20.4998H7.3077ZM17 5.99981H6.99997V18.6921C6.99997 18.7818 7.02882 18.8556 7.08652 18.9133C7.14422 18.971 7.21795 18.9998 7.3077 18.9998H16.6922C16.782 18.9998 16.8557 18.971 16.9134 18.9133C16.9711 18.8556 17 18.7818 17 18.6921V5.99981ZM9.40385 16.9998H10.9038V7.99981H9.40385V16.9998ZM13.0961 16.9998H14.5961V7.99981H13.0961V16.9998Z" />
    </SvgIcon>
  );
}
