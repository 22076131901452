import { useMemo } from 'react';

import { Box, Stack } from '@mui/material';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/Templates/pages/Role/components';

import { ColumnIcon, ToggleColumnsVisibility } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { CONFIG_TABLE } from '../../../../helpers';

export const ToggleColumns = () => {
  const { t: tTemplates } = useTranslation('templates');

  const state = useContextState();

  const { handleToggleColumnVisibility, handleShowAllColumns } = useContextUpdateState();

  const Title = useMemo(
    () => (
      <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
        <ColumnIcon />

        <Box>
          {tTemplates('role.table.filters.hiddenColumns.title', {
            value: state.filters.hiddenColumns.length
          })}
        </Box>
      </Stack>
    ),
    [state.filters.hiddenColumns]
  );

  const columns = CONFIG_TABLE.cells.map((column) => {
    return {
      label: column.title,
      key: column.columnName,
      value: column.columnName,
      checked: state.filters.hiddenColumns.includes(column.columnName)
    };
  });

  return (
    <ToggleColumnsVisibility
      id={'User-management-hidden-columns'}
      title={Title}
      searchPlaceholder={'table.filters.hiddenColumns.placeholder'}
      menuList={columns}
      hasSearch={false}
      onSwitchClick={(option) => {
        handleToggleColumnVisibility(option.key);
      }}
      onChange={handleShowAllColumns}
    />
  );
};
