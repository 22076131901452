import { useEffect, useState } from 'react';

import Collapse from '@mui/material/Collapse';

import { TableManagement } from '@/separatedModules/components';

import { useContextState } from '../../../../../../../ContextProvider';
import { useContextState as useTableState, useContextUpdateState } from '../index';

export const ActionsContainer = () => {
  const state = useTableState();
  const { setState } = useContextState();

  const { handleResetSelectedRows, handleToggleAside } = useContextUpdateState();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (state.selectedItems.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [state.selectedItems]);

  return (
    <Collapse in={checked}>
      <TableManagement
        count={state.selectedItems.length}
        open={checked}
        onClose={() => {
          handleResetSelectedRows();
          setState((state) => ({ ...state, selected: undefined }));
        }}
        onClickInfo={handleToggleAside}
      />
    </Collapse>
  );
};
