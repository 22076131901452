import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useMutation } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import { UPDATE_USER_PASSWORD, UpdatePasswordMutation } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { useTranslation } from '@/i18n';

import { StepItem } from './components';

enum StepsE {
  FIRST = '1',
  SECOND = '2'
}

export const EditPassword = () => {
  const auth = useAuth();

  const { t: tProfile } = useTranslation('profile');
  const { t: tGlobal } = useTranslation('global');

  const [UpdatePassword, { data: editedUserData, error: errorEditedUserData }] =
    useMutation<UpdatePasswordMutation>(UPDATE_USER_PASSWORD);

  const [step, setStep] = useState(StepsE.FIRST);

  const {
    setError,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      newPass: '',
      confirmPass: ''
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    if (step === StepsE.FIRST && !errors.newPass) setStep(StepsE.SECOND);

    if (step === StepsE.SECOND) {
      const { newPass, confirmPass } = getValues();

      if (newPass !== confirmPass) {
        setError('confirmPass', { message: tGlobal('validation.passwordDontMatch') });
      } else {
        UpdatePassword({ variables: { newPassword: confirmPass } });
      }
    }
  });

  useEffect(() => {
    if (editedUserData?.updateProfilePassword) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.success.profile.editPassword'),
        severity: 'success'
      });

      setTimeout(() => {
        auth.removeUser();
      }, 2000);
    }
    if (errorEditedUserData) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [editedUserData, errorEditedUserData]);

  return (
    <form onSubmit={handleSubmitForm}>
      {step === StepsE.FIRST ? (
        <>
          <StepItem
            name={'newPass'}
            title={tProfile('edit.password.title')}
            subTitle={tProfile('edit.password.inputEnterTitle')}
            inputLabel={tProfile('edit.password.inputLabel')}
            control={control}
            errors={errors}
          />

          <Button className={'edit-user__content__btn'} variant={'contained'} type={'submit'}>
            {tProfile('edit.password.btnContinue')}
          </Button>
        </>
      ) : null}

      {step === StepsE.SECOND ? (
        <>
          <StepItem
            name={'confirmPass'}
            title={tProfile('edit.password.title')}
            subTitle={tProfile('edit.password.inputConfirmTitle')}
            inputLabel={tProfile('edit.password.inputLabel')}
            control={control}
            errors={errors}
          />

          <Button
            className={'edit-user__content__btn'}
            variant={'contained'}
            type={'submit'}
            disabled={Boolean(editedUserData?.updateProfilePassword)}
          >
            {tProfile('edit.password.btnChange')}
          </Button>
        </>
      ) : null}
    </form>
  );
};
