import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from '@/separatedModules/layouts';

export const SidebarContainer = styled(Box)(() => ({
  display: 'flex',
  position: 'sticky',
  justifyContent: 'space-between',
  top: 0,
  height: `calc(100vh - ${HEADER_HEIGHT}px)`
})) as typeof Box;

export const StyledSideBarContent = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'hideContent'
})(({ hideContent }: { hideContent: boolean }) => ({
  overflowY: 'auto',
  maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  opacity: hideContent ? 0 : 1,
  transition: '0.25s ease',
  padding: '16px 0'
}));

export const StyledHandleWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isCollapsed'
})(({ isCollapsed }: { isCollapsed: boolean }) => ({
  display: 'flex',
  position: 'relative',

  ...(isCollapsed && {
    button: {
      display: 'flex'
    }
  }),

  ':hover': {
    button: {
      display: 'flex'
    }
  }
}));

export const StyledSideBarHandle = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isActive'
})(({ isActive }: { isActive: boolean }) => ({
  width: 15,
  borderLeft: `1px solid ${isActive ? '#388BFF' : '#090E1626'}`,
  whiteSpace: 'nowrap',

  ':hover': {
    background: 'transparent',
    borderLeft: '1px solid #388BFF',

    transition: '0.25s ease',
    userSelect: 'none',
    cursor: 'ew-resize'
  }
}));

export const StyledIconButton = styled(IconButton)(() => ({
  background: '#FFFFFF',
  padding: 0,
  display: 'none',

  position: 'absolute',
  top: '40px',
  left: '-12px',
  border: '1px solid #090E1626',

  ':hover': {
    background: '#FFFFFF',
    padding: 0
  }
}));

export const SideBarGroup = styled(Box)(() => ({
  padding: '0 12px'
}));

export const SideBarGroupTitle = styled(Text)(() => ({
  color: '#969AA8',
  fontSize: '11px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  padding: '12px'
})) as typeof Text;
