import { Text } from 'infinitytechnologies-ui';

import { Box, Button, SvgIcon } from '@mui/material';

import { ArrowForwardIcon, Flex, ImageLazy, InfoIcon, TableAsideItem } from '@/separatedModules/components';

export const UsersAsideContent = (props: any) => {
  return (
    <>
      {props?.userAvatar ? (
        <ImageLazy src={props?.userAvatar?.url} width={136} height={136} alt={props?.userAvatar?.url} />
      ) : (
        <Box
          sx={{
            width: '136px',
            height: '136px',
            marginRight: '8px',
            borderRadius: '50%',
            background: 'rgb(240, 240, 241)'
          }}
        />
      )}

      {props?.isHidden ? (
        <>
          <TableAsideItem title={'Email'} subTitle={props?.email} />
          <TableAsideItem title={'Phone number'} subTitle={props?.phoneNumber} />

          <Button
            variant={'text'}
            size={'small'}
            sx={{
              margin: '0 0 0 auto !important',
              '&.MuiButton-text': {
                '&:not(:active, :hover)': {
                  background: 'transparent'
                }
              }
            }}
            onClick={props?.handleChangeTest}
            endIcon={<SvgIcon component={ArrowForwardIcon} />}
          >
            {'Details'}
          </Button>
        </>
      ) : (
        <Flex sx={{ marginTop: '20px' }}>
          <InfoIcon fontSize="inherit" sx={{ fill: '#0C66E4' }} />
          <Box sx={{ marginLeft: '12px' }}>
            <Text sx={{ fontSize: '14px', fontWeight: '600' }}>You can’t select this user</Text>
            <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
              This user doesn’t have access to the domain that corresponds to the current equipment template domain. You
              can edit equipment domains which this user is accessed to on his profile
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};
