import { SxProps, Theme } from '@mui/material/styles';

import {
  ActivateRound,
  ActivateRoundProps
} from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate/components/ActivateRound';

import { TextBlockControl } from '@/separatedModules/components';

import { StyledActiveRoundControl } from './style';

interface ButtonsListControlProps extends ActivateRoundProps {
  title?: string;
  subTitle?: string;
  sxTitle?: SxProps<Theme>;
  sxSubTitle?: SxProps<Theme>;
  sxSelect?: SxProps<Theme>;
}

export const ActiveRoundControl = ({ title, subTitle, sxTitle, sxSubTitle, ...rest }: ButtonsListControlProps) => {
  return (
    <StyledActiveRoundControl>
      <TextBlockControl
        className={'control-autocomplete-tags__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
      />

      <ActivateRound {...rest} />
    </StyledActiveRoundControl>
  );
};
