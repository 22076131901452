import Grid from '@mui/material/Grid';

import { RightItemAvatar } from '@/separatedModules/layouts/Base/components/Header/components/RightPart/components';

export const RightPart = () => {
  return (
    <Grid className={'header__right'} lg={5} alignItems={'center'} justifyContent={'end'} sx={{ display: 'flex' }} item>
      <RightItemAvatar />
    </Grid>
  );
};
