import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import classNames from 'classnames';

import { SxProps, Theme } from '@mui/material/styles';

import { UserDetailsFull, UserDetailsShort } from './components';

import { StyledUserDetails } from './style';

export interface UserDetailsProps {
  variant?: 'short' | 'full';
  className?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  logo?: string;
  errorUser?: ApolloError;
  sx?: SxProps<Theme>;
  loading?: boolean;
  isShowEmail?: boolean;
}

export const UserDetails = ({
  variant = 'full',
  className,
  firstName = '',
  lastName = '',
  email = '',
  logo = '',
  errorUser,
  sx,
  loading = false,
  isShowEmail
}: UserDetailsProps) => {
  const isVariantFull = variant === 'full';
  const isVariantShort = variant === 'short';

  useEffect(() => {
    // ToDo Add workflow for error
    // ToDo Remove
    if (errorUser) console.log('UserDetails errorUser - ', errorUser);
  }, [errorUser]);

  if (!firstName && !lastName) {
    return null;
  }

  return (
    <StyledUserDetails
      className={classNames('user-details', className, {
        'user-details--full': isVariantFull,
        'user-details--short': isVariantShort
      })}
      sx={sx}
    >
      {variant === 'full' ? (
        <UserDetailsFull firstName={firstName} lastName={lastName} email={email} logo={logo} loading={loading} />
      ) : (
        <UserDetailsShort
          firstName={firstName}
          lastName={lastName}
          email={isShowEmail ? email : ''}
          logo={logo}
          loading={loading}
        />
      )}
    </StyledUserDetails>
  );
};
