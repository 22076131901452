import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function GroupIllustrationIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'48'} height={'48'} viewBox={'0 0 48 48'} fill={'none'} {...props}>
      <path
        d="M40.5291 47.4382L34.3576 48H6.76059C3.02681 48 0 44.9732 0 41.2394V6.76059C0 3.02681 3.02681 0 6.76059 0H34.5758L40.3591 0.575531C44.0929 0.575531 47.1197 3.60234 47.1197 7.33612L47.2897 40.6776C47.2897 44.4113 44.2628 47.4382 40.5291 47.4382Z"
        fill="white"
      />
      <path
        d="M47.9995 6.76059V41.2395C47.9995 44.9732 44.9727 48 41.2389 48H33.0576C36.7914 48 39.8182 44.9732 39.8182 41.2394V6.76059C39.8182 3.02681 36.7915 0 33.0577 0H41.239C44.9727 0 47.9995 3.02681 47.9995 6.76059Z"
        fill="white"
      />
      <path
        d="M4.8291 41.2393V6.76048C4.8291 5.6937 5.69395 4.82886 6.76073 4.82886H39.5388L40.7888 5.26808C41.8556 5.26808 42.7204 6.13292 42.7204 7.1997L42.3209 40.8593C42.3209 41.9261 41.456 42.791 40.3892 42.791L39.5388 43.171H6.76073C5.69395 43.1709 4.8291 42.3061 4.8291 41.2393Z"
        fill="#DCDFE4"
      />
      <path
        d="M43.1714 6.76073V41.2396C43.1714 42.3069 42.307 43.1713 41.2398 43.1713H39.5391C39.7216 42.559 39.8191 41.9109 39.8191 41.2396V6.76073C39.8191 6.08948 39.7216 5.44148 39.5391 4.8291H41.2398C42.307 4.8291 43.1714 5.69348 43.1714 6.76073Z"
        fill="#DCDFE4"
      />
      <path
        d="M35.4672 21.8269H26.3666C26.2599 21.8269 26.1735 21.7405 26.1735 21.6338V12.5332C26.1735 12.2665 25.9573 12.0503 25.6906 12.0503H22.3103C22.0436 12.0503 21.8274 12.2665 21.8274 12.5332V21.6338C21.8274 21.7405 21.741 21.8269 21.6343 21.8269H12.5337C12.267 21.8269 12.0508 22.0431 12.0508 22.3098V25.6901C12.0508 25.9568 12.267 26.173 12.5337 26.173H21.6343C21.741 26.173 21.8274 26.2594 21.8274 26.3661V35.4667C21.8274 35.7334 22.0436 35.9496 22.3103 35.9496H25.6906C25.9573 35.9496 26.1735 35.7334 26.1735 35.4667V26.3661C26.1735 26.2594 26.2599 26.173 26.3666 26.173H35.4672C35.7339 26.173 35.9501 25.9568 35.9501 25.6901V22.3098C35.9501 22.0431 35.7339 21.8269 35.4672 21.8269Z"
        fill="white"
      />
    </SvgIcon>
  );
}
