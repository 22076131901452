import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function QuestionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_89_4793)">
          <path
            d="M100 0C45.0391 0 0 45.0391 0 100C0 154.961 45.0391 200 100 200C154.961 200 200 154.961 200 100C200 45.0391 154.961 0 100 0Z"
            fill="#60A5FA"
          />
          <path d="M200 100C200 154.961 154.961 200 100 200V0C154.961 0 200 45.0391 200 100Z" fill="#3B82F6" />
          <path
            d="M100 170.312C93.5277 170.312 88.2812 165.066 88.2812 158.594C88.2812 152.121 93.5277 146.875 100 146.875C106.472 146.875 111.719 152.121 111.719 158.594C111.719 165.066 106.472 170.312 100 170.312ZM120.765 93.2023C117.389 95.6855 111.719 100.984 111.719 108.634V111.719C111.719 118.19 106.471 123.438 100 123.438C93.5285 123.438 88.2812 118.19 88.2812 111.719V108.634C88.2812 95.5023 95.0676 82.9883 106.901 74.3078C109.962 72.0594 111.719 68.6145 111.719 64.8438C111.719 58.3836 106.46 53.125 100 53.125C93.5398 53.125 88.2812 58.3836 88.2812 64.8438C88.2812 71.3152 83.034 76.5625 76.5625 76.5625C70.091 76.5625 64.8438 71.3152 64.8438 64.8438C64.8438 45.4574 80.6137 29.6875 100 29.6875C119.386 29.6875 135.156 45.4574 135.156 64.8438C135.156 75.9902 129.777 86.5934 120.765 93.2023Z"
            fill="white"
          />
          <path
            d="M111.719 64.8438C111.719 68.6145 109.962 72.0594 106.901 74.3082C104.317 76.2035 102.084 78.3508 100 80.5781V123.438C106.471 123.438 111.719 118.19 111.719 111.719V108.634C111.719 100.984 117.389 95.6855 120.765 93.202C129.777 86.5934 135.156 75.9902 135.156 64.8438C135.156 45.4574 119.386 29.6875 100 29.6875V53.125C106.46 53.125 111.719 58.3836 111.719 64.8438Z"
            fill="#BFDBFE"
          />
          <path
            d="M111.719 158.594C111.719 152.121 106.472 146.875 100 146.875V170.312C106.472 170.312 111.719 165.066 111.719 158.594Z"
            fill="#BFDBFE"
          />
        </g>
        <defs>
          <clipPath id="clip0_89_4793">
            <rect width="200" height="200" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
