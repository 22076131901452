import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ExpandLessIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'18'}
      height={'18'}
      viewBox={'0 0 18 18'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 18, ...sx }}
      {...restProps}
    >
      <path d="M9.00072 10.9961C8.91034 10.9961 8.8262 10.9816 8.74833 10.9528C8.67044 10.9239 8.5964 10.8744 8.52622 10.8042L5.15555 7.43357C5.0517 7.32973 4.99857 7.19921 4.99617 7.04199C4.99376 6.88478 5.04688 6.75185 5.15555 6.6432C5.2642 6.53455 5.39592 6.48022 5.55072 6.48022C5.70552 6.48022 5.83725 6.53455 5.9459 6.6432L9.00072 9.69802L12.0555 6.6432C12.1594 6.53936 12.2899 6.48624 12.4471 6.48382C12.6043 6.48142 12.7372 6.53455 12.8459 6.6432C12.9546 6.75185 13.0089 6.88358 13.0089 7.03839C13.0089 7.19319 12.9546 7.32492 12.8459 7.43357L9.47523 10.8042C9.40504 10.8744 9.331 10.9239 9.25312 10.9528C9.17524 10.9816 9.09111 10.9961 9.00072 10.9961Z" />
    </SvgIcon>
  );
}
