import { useMemo } from 'react';
import groupBy from 'lodash/groupBy';

import Stack from '@mui/material/Stack';

import { PermissionItem } from '@/separatedModules/pages/Templates/pages/Role/pages/CreateRole/components';

import { useTranslation } from '@/i18n';

import { createMockedMenuList, GroupOfPermissions, PermissionsGroup, UserPermissions } from '../helpers';

interface PermissionsBlockProps<T> {
  userPermissions?: T[];
  detailedPermissions: PermissionsGroup[];
  permissionsLoading: boolean;
}

export const PermissionsBlock = <T,>({
  userPermissions,
  detailedPermissions,
  permissionsLoading
}: PermissionsBlockProps<T>) => {
  const { t: tTemplates } = useTranslation('templates');

  const groupedPermissions = useMemo(() => {
    return groupBy(detailedPermissions, 'resource');
  }, [detailedPermissions, permissionsLoading]);

  const groupedInitialPermissions = useMemo(() => {
    if (userPermissions?.length) {
      return groupBy(userPermissions as unknown as UserPermissions[], 'resource');
    }

    return [];
  }, [userPermissions]);

  return (
    <Stack spacing={'24px'}>
      {Object.entries(groupedPermissions).map(([key, value]) => (
        <PermissionItem
          key={key}
          title={tTemplates(`createRole.mainInfo.${key.toLowerCase()}Label`)}
          description={tTemplates(`createRole.mainInfo.${key.toLowerCase()}Description`)}
          sxTitle={{ fontSize: '14px', color: '#505668' }}
          sxDescription={{ fontSize: '16px', color: '#090E16' }}
          variant={'readonly'}
          menuList={createMockedMenuList(key, value, groupedInitialPermissions as GroupOfPermissions)}
          onMenuItemClick={() => null}
        />
      ))}
    </Stack>
  );
};
