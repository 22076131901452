import { NavLink } from 'react-router-dom';

import { LinksSideBarT } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { StyledNavListSimple } from './style';

interface NavListSimpleProps {
  insertValue?: string;
  replaceValue?: string;
  menu: LinksSideBarT[];
}

export const NavListSimple = ({ insertValue, replaceValue, menu }: NavListSimpleProps) => {
  const { t } = useTranslation('sidebar');

  const sorted = menu.sort(({ order: orderA }, { order: orderB }) => {
    return orderA - orderB;
  });

  return (
    <StyledNavListSimple>
      {sorted.map(({ title, icon, link, isDisabled }) => {
        if (!link) return null;

        return (
          <NavLink
            key={title}
            to={insertValue && replaceValue ? link.replace(replaceValue, insertValue) : link}
            className={({ isActive, isPending }) => {
              return [
                isPending ? 'pending' : '',
                isActive ? 'nav-simple__i--active' : '',
                isDisabled ? 'nav-simple__i--disabled' : '',
                'nav-simple__i'
              ]
                .join(' ')
                .trim();
            }}
            end
          >
            {icon}

            <span className={'nav-simple__i__text'}>{t(title)}</span>
          </NavLink>
        );
      })}
    </StyledNavListSimple>
  );
};
