import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DisabledByDefaultIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M10.0003 10.87L12.5692 13.4389C12.6846 13.5543 12.8296 13.6107 13.0043 13.608C13.179 13.6053 13.324 13.5463 13.4394 13.4309C13.5548 13.3155 13.6125 13.1705 13.6125 12.9958C13.6125 12.8211 13.5548 12.6761 13.4394 12.5607L10.8705 9.99982L13.4394 7.4309C13.5548 7.31551 13.6125 7.17048 13.6125 6.99582C13.6125 6.82114 13.5548 6.67611 13.4394 6.56073C13.324 6.44534 13.179 6.38765 13.0043 6.38765C12.8296 6.38765 12.6846 6.44534 12.5692 6.56073L10.0003 9.12965L7.43139 6.56073C7.316 6.44534 7.17231 6.38765 7.00031 6.38765C6.82831 6.38765 6.68461 6.44534 6.56922 6.56073C6.45385 6.67611 6.39616 6.82114 6.39616 6.99582C6.39616 7.17048 6.45385 7.31551 6.56922 7.4309L9.13014 9.99982L6.56122 12.5687C6.44583 12.6841 6.38947 12.8278 6.39214 12.9998C6.39482 13.1718 6.45385 13.3155 6.56922 13.4309C6.68461 13.5463 6.82964 13.604 7.0043 13.604C7.17899 13.604 7.32401 13.5463 7.43939 13.4309L10.0003 10.87ZM4.42341 17.0831C4.00246 17.0831 3.64616 16.9373 3.35449 16.6456C3.06283 16.354 2.91699 15.9977 2.91699 15.5767V4.42292C2.91699 4.00198 3.06283 3.64567 3.35449 3.354C3.64616 3.06234 4.00246 2.9165 4.42341 2.9165H15.5772C15.9981 2.9165 16.3545 3.06234 16.6461 3.354C16.9378 3.64567 17.0836 4.00198 17.0836 4.42292V15.5767C17.0836 15.9977 16.9378 16.354 16.6461 16.6456C16.3545 16.9373 15.9981 17.0831 15.5772 17.0831H4.42341ZM4.42341 15.8331H15.5772C15.6413 15.8331 15.7001 15.8064 15.7535 15.753C15.8069 15.6996 15.8336 15.6408 15.8336 15.5767V4.42292C15.8336 4.35881 15.8069 4.30004 15.7535 4.24661C15.7001 4.19319 15.6413 4.16648 15.5772 4.16648H4.42341C4.3593 4.16648 4.30053 4.19319 4.2471 4.24661C4.19368 4.30004 4.16697 4.35881 4.16697 4.42292V15.5767C4.16697 15.6408 4.19368 15.6996 4.2471 15.753C4.30053 15.8064 4.3593 15.8331 4.42341 15.8331Z" />
    </SvgIcon>
  );
}
