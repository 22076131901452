import { ReactNode } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { PlusIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';
export interface NotFoundSearchProps {
  type: string;
  imageUrl: string;
  title?: string;
  subTitle?: string;
  buttonText?: string;
  onClick?: () => void;
  afterContent?: ReactNode;
}

export const NotFoundSearch = ({
  imageUrl,
  title,
  subTitle,
  buttonText,
  onClick,
  afterContent
}: NotFoundSearchProps) => {
  const { t: tGlobal } = useTranslation('global');

  const renderTitle = title || tGlobal('notFound.search.title');
  const renderSubTitle = subTitle || tGlobal('notFound.search.subTitle');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {imageUrl ? (
        <img src={imageUrl} className={'not-found-screens__img img-fluid'} alt={'Not found search image'} />
      ) : null}

      {renderTitle ? (
        <Text className={'not-found-screens__title'} variant={'titleLg'}>
          {renderTitle}
        </Text>
      ) : null}

      {renderSubTitle ? (
        <Text className={'not-found-screens__subTitle'} variant={'bodyLg'}>
          {renderSubTitle}
        </Text>
      ) : null}

      {buttonText ? (
        <Button
          variant={'text'}
          size={'small'}
          sx={{
            margin: '0 !important',
            '&.MuiButton-text': {
              '&:not(:active, :hover)': {
                background: 'transparent'
              }
            }
          }}
          startIcon={<PlusIcon />}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      ) : null}

      {afterContent}
    </Box>
  );
};
