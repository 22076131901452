import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n/config';

import { Parameter, ParameterTypeTyped } from '../../../../helpers';

interface NumericType extends Parameter {}

export const NUMERIC_TYPE_LIST: NumericType[] = [
  {
    id: 0,
    title: i18n.t('createParameterItem.parameter.type.numeric.type.dataInput.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.numeric.type.dataInput.description', { ns: 'templates' }),
    logoPath: LINKS_IMAGES.parameterTypeNumericInput,
    parameterType: ParameterTypeTyped.NUMERIC_INPUT
  },
  {
    id: 1,
    title: i18n.t('createParameterItem.parameter.type.numeric.type.formula.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.numeric.type.formula.description', { ns: 'templates' }),
    logoPath: LINKS_IMAGES.parameterTypeNumericFormula,
    parameterType: ParameterTypeTyped.NUMERIC_FORMULA
  },
  {
    id: 2,
    title: i18n.t('createParameterItem.parameter.type.numeric.type.fixedNumericValue.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.numeric.type.fixedNumericValue.description', {
      ns: 'templates'
    }),
    logoPath: LINKS_IMAGES.parameterTypeNumericFixed,
    parameterType: ParameterTypeTyped.NUMERIC_FIXED
  }
];

export interface UnitOption {
  label: string;
  value: string;
}

export const MIN_GUIDE_LENGTH = 3;
export const MAX_GUIDE_LENGTH = 400;

export const checkParameterTypeText = (parameterType: string): string => {
  switch (true) {
    case parameterType === ParameterTypeTyped.BOOLEAN: {
      return 'Boolean';
    }
    case parameterType === ParameterTypeTyped.STRING: {
      return 'String';
    }
    case parameterType === ParameterTypeTyped.DATA_TIME: {
      return 'Date & Time';
    }
    default: {
      return 'Boolean';
    }
  }
};
