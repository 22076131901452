import { NavLink, useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { LinksSideBarWithButtonT } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { StyledNavListSimple } from '../NavListSimple';

interface NavListSimpleProps {
  insertValue?: string;
  replaceValue?: string;
  btnLinkRedirectReplaceValue?: string;
  menu: LinksSideBarWithButtonT[];
  isDisabledButton?: boolean;
  activeLinkOverride?: boolean;
}

export const NavListSimpleWithBtn = ({
  insertValue,
  replaceValue,
  menu,
  btnLinkRedirectReplaceValue,
  isDisabledButton,
  activeLinkOverride
}: NavListSimpleProps) => {
  const { t: tSidebar } = useTranslation('sidebar');
  const navigateTo = useNavigate();

  const sorted = menu.sort(({ order: orderA }, { order: orderB }) => {
    return orderA - orderB;
  });

  return (
    <StyledNavListSimple>
      {sorted.map(({ title, icon, link, isDisabled, btnIcon, btnLinkRedirect, btnTooltip, isHidden }) => {
        if (!link || isHidden) return null;

        return (
          <div key={title} className={'nav-simple__i__wrp'}>
            <NavLink
              to={insertValue && replaceValue ? link.replace(replaceValue, insertValue) : link}
              className={({ isActive, isPending }) => {
                return [
                  isPending ? 'pending' : '',
                  isActive || activeLinkOverride ? 'nav-simple__i--active' : '',
                  isDisabled ? 'nav-simple__i--disabled' : '',
                  'nav-simple__i'
                ]
                  .join(' ')
                  .trim();
              }}
              end
            >
              {icon}

              <span className={'nav-simple__i__text'}>{tSidebar(title)}</span>
            </NavLink>

            {btnIcon && btnLinkRedirect && !isDisabledButton ? (
              <Tooltip title={btnTooltip ? tSidebar(btnTooltip) : ''}>
                <IconButton
                  className={'nav-simple__i__btn-redirect'}
                  size={'small'}
                  onClick={() => {
                    navigateTo(
                      insertValue && btnLinkRedirectReplaceValue
                        ? btnLinkRedirect.replace(replaceValue || '', insertValue)
                        : btnLinkRedirect
                    );
                  }}
                >
                  {btnIcon}
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      })}
    </StyledNavListSimple>
  );
};
