import { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ButtonLoading } from '@/separatedModules/components';

const isSxNotFixed = {
  padding: '24px 0 40px',
  background: 'var(--background-neutral-default, #FFF)',
  bottom: '0',
  right: '0',
  left: '0',
  '.MuiGrid-root': {
    flex: '0 1 100%',
    maxWidth: '100%'
  }
};

const isSxFixed = {
  padding: '24px 0 40px',
  background: 'var(--background-neutral-default, #FFF)',
  bottom: '0',
  right: '0',
  left: '0',
  borderTop: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
  position: 'fixed',
  zIndex: 5
};

interface MultiStepFooterProps {
  currentStep: number;
  totalSteps: number;
  btnPrevStepText: string;
  btnNextStepText: string;
  btnCreateTemplateText: string;
  isLoadingBtnCreate: boolean;
  isDisabledBtnCreate: boolean;
  handleSetPrevStep: () => void;
  isRound?: boolean;
  isDisabled?: boolean;
  isDisablePrevButton?: boolean;
}

export const MultiStepFooter = memo(
  ({
    currentStep,
    totalSteps,
    btnPrevStepText,
    btnNextStepText,
    btnCreateTemplateText,
    isLoadingBtnCreate = false,
    isDisabledBtnCreate = false,
    handleSetPrevStep,
    isRound,
    isDisabled,
    isDisablePrevButton
  }: MultiStepFooterProps) => {
    const { ref, inView } = useInView({
      initialInView: true,
      threshold: 0
    });

    const ButtonContainer = () => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: currentStep > 1 && !isDisablePrevButton ? 'space-between' : 'flex-end'
          }}
        >
          {currentStep > 1 && !isDisablePrevButton ? (
            <Button className={'my-0'} variant={'text'} onClick={handleSetPrevStep}>
              {btnPrevStepText}
            </Button>
          ) : null}

          {currentStep < totalSteps ? (
            <Button className={'my-0'} variant={'contained'} type={'submit'} disabled={isDisabled}>
              {btnNextStepText}
            </Button>
          ) : null}

          {currentStep === totalSteps ? (
            <ButtonLoading
              className={'my-0'}
              variant={'contained'}
              type={'submit'}
              loading={isLoadingBtnCreate}
              disabled={isDisabledBtnCreate}
            >
              {btnCreateTemplateText}
            </ButtonLoading>
          ) : null}
        </Box>
      );
    };

    return (
      <Box ref={ref}>
        <Box sx={inView ? isSxNotFixed : isSxFixed}>
          {inView ? (
            <ButtonContainer />
          ) : (
            <Grid className={'MuiGrid-container-main'} justifyContent={'center'} container>
              <Grid justifyContent={'center'} container row>
                <Grid xs={12} sm={10} md={!isRound ? 8 : 10} item className="p-0">
                  <ButtonContainer />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    );
  }
);
