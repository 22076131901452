import { useQuery } from '@apollo/client';

import { GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';

import { UserDetails } from '@/separatedModules/components';

import { LINKS_SIDEBAR_PROFILE_MENU } from '@/subsidiaryBinders/constants';

import { NavListSimple } from '../NavListSimple';

export const SideBarProfile = () => {
  const {
    data: dataUserProfile,
    loading: loadingUserProfile,
    error: errorUserProfile
  } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  return (
    <Sidebar>
      <SideBarGroup>
        <UserDetails
          variant={'short'}
          firstName={dataUserProfile?.profile.firstName ?? ''}
          lastName={dataUserProfile?.profile.lastName ?? ''}
          email={dataUserProfile?.profile.email}
          logo={dataUserProfile?.profile.profileAvatar?.url}
          loading={loadingUserProfile}
          errorUser={errorUserProfile}
        />

        <NavListSimple menu={LINKS_SIDEBAR_PROFILE_MENU} />
      </SideBarGroup>
    </Sidebar>
  );
};
