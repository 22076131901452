import { FC, SyntheticEvent, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export interface TabListProps {
  id: number;
  name: string;
  disabled?: boolean;
}

interface FoldersTabsProps {
  tabs: TabListProps[];
  onChange: (tabIndex: number) => void;
}

export const FoldersTabs: FC<FoldersTabsProps> = ({ tabs, onChange }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    onChange(newValue);
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} aria-label={'folders tabs'} sx={{ m: 0 }} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              sx={{
                background: 'white',
                fontSize: 16,
                textTransform: 'capitalize',
                color: '#505668',
                fontWeight: '400',
                '&.Mui-selected': { color: '#090E16 !important', fontWeight: '500' }
              }}
              disabled={tab.disabled}
              label={tab.name}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};
