import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n/config';

import { EquipmentItemTypes } from '../../../helpers';

export const EQUIPMENT_ITEM_TYPES_LIST = [
  {
    id: 0,
    title: i18n.t('createEquipmentItem.equipmentItem.type.new.title', { ns: 'equipment' }),
    description: i18n.t('createEquipmentItem.equipmentItem.type.new.description', { ns: 'equipment' }),
    logoPath: LINKS_IMAGES.plus,
    equipmentType: EquipmentItemTypes.NEW
  },
  {
    id: 1,
    title: i18n.t('createEquipmentItem.equipmentItem.type.template.title', { ns: 'equipment' }),
    description: i18n.t('createEquipmentItem.equipmentItem.type.template.description', { ns: 'equipment' }),
    logoPath: LINKS_IMAGES.userInterface,
    equipmentType: EquipmentItemTypes.TEMPLATE
  }
];
