import Grid from '@mui/material/Grid';

import { LeftItem, RightItem } from './components';

export const RightPart = () => {
  return (
    <Grid className={'header__right'} lg={5} alignItems={'center'} justifyContent={'end'} sx={{ display: 'flex' }} item>
      <LeftItem />

      <RightItem />
    </Grid>
  );
};
