import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Grid } from '@mui/material';

import { CustomTaskRes, GET_CUSTOM_TASK, TaskStatus } from '@/logicLayers/domain';
import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import { Breadcrumbs, ChevronRightIcon, EMPTY_CELL_VALUE } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ActionMenu } from './components/ActionMenu/ActionMenu';

export const TaskCustomDetailsPage = () => {
  const { t: tTasks } = useTranslation('tasks');
  const { taskId = '' } = useParams();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { profile } = useReduxSelector(selectUserData);

  const { data: dataCustomTask } = useQuery<CustomTaskRes>(GET_CUSTOM_TASK, {
    fetchPolicy: 'no-cache',
    variables: {
      taskId: taskId || undefined,
      searchCriteria: {}
    }
  });

  const renderItemName = dataCustomTask?.task?.name;
  const renderItemDescription = dataCustomTask?.task?.description;
  const renderItemTemplateStatus = dataCustomTask?.task?.status;
  const renderItemStartDate = dataCustomTask?.task?.startDate;
  const renderItemEndDate = dataCustomTask?.task?.endDate;
  const renderItemPerformer = dataCustomTask?.task?.performer;

  const breadcrumbsLinks = [
    {
      link: LINKS_PAGES.tasksCustom,
      titleFallback: tTasks('tasksCustom.pageTopTitle')
    },
    { titleFallback: renderItemName }
  ];

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} sm={10} md={10} item>
        <Box>
          <Breadcrumbs
            links={breadcrumbsLinks}
            separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
            sx={{ paddingTop: 0 }}
          />
          <Text component={'h2'} variant={'titleXl'}>
            {renderItemName}
          </Text>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '32px' }}>
          <Box>
            {renderItemName ? (
              <Text className={'m-0'} variant={'bodyLg'} sx={{ fontWeight: '600' }}>
                {renderItemName}
              </Text>
            ) : null}
          </Box>
          <Box>{isUserCanCRUD && <ActionMenu />}</Box>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '8px' }}>
          <Text className={'m-0'} variant={'bodyLg'} sx={{ maxWidth: '630px' }}>
            {renderItemDescription?.length ? renderItemDescription : EMPTY_CELL_VALUE}
          </Text>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
            {tTasks('tasksCustom.table.status')}
          </Text>
          <Text className={'m-0'} variant={'bodyLg'}>
            <TaskStatusChip status={renderItemTemplateStatus as keyof typeof TaskStatus} />
          </Text>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
            {tTasks('tasksCustom.table.startDate')}
          </Text>
          <Text className={'m-0'} variant={'bodyLg'}>
            {dayjs(renderItemStartDate).format('DD MMM YYYY, HH:mm A')}
          </Text>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
            {tTasks('tasksCustom.table.endDate')}
          </Text>
          <Text className={'m-0'} variant={'bodyLg'}>
            {dayjs(renderItemEndDate).format('DD MMM YYYY, HH:mm A')}
          </Text>
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', marginBottom: '12px' }}>
            {tTasks('tasksCustom.table.performer')}
          </Text>
          <Box sx={{ display: 'flex', marginBottom: '8px' }}>
            {renderItemPerformer?.userAvatar ? (
              <img
                src={renderItemPerformer.userAvatar?.url}
                alt=""
                width={24}
                height={24}
                style={{
                  borderRadius: '50%',
                  border: '1px solid #F7F8F9',
                  position: 'relative',
                  margin: '0 8px 0 0'
                }}
              />
            ) : (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  flex: '0 0 24px',
                  borderRadius: '50%',
                  margin: '0 8px 0 0',
                  background: 'rgba(9, 14, 22, 0.06)',
                  border: '1px solid var(--border-inverse, #F7F8F9)'
                }}
              />
            )}
            <Text>
              {!renderItemPerformer?.firstName && !renderItemPerformer?.lastName
                ? EMPTY_CELL_VALUE
                : `${renderItemPerformer?.firstName} ${renderItemPerformer?.lastName}`}
              {profile?.id === renderItemPerformer?.id ? ` (${tTasks('tasksCustom.table.selfPerformance')})` : ''}
            </Text>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
