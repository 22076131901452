import { useContextState } from '../../../ContextProvider';

import { TaskUnplannedStep2 } from './components/TaskUnplannedStep2';
import { TaskCustomStep2 } from './components';

import { TaskTypes } from '../../../helpers';

export const Step2 = () => {
  const { taskType } = useContextState();

  return taskType === TaskTypes.CUSTOM ? <TaskCustomStep2 /> : <TaskUnplannedStep2 />;
};
