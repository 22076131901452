import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { IconButtonProps } from '@mui/material/IconButton';

import { LocalStorageServiceClass } from '@/logicLayers/infrastructure/services';

import { ChevronLeftIcon, ChevronRightIcon } from '@/separatedModules/components';

import { DEFAULT_WIDTH, MAX_WIDTH, MIN_WIDTH, SIDEBAR_STORAGE_KEY, SidebarState, THRESHOLD_WIDTH } from './helpers';

import {
  SidebarContainer,
  StyledHandleWrapper,
  StyledIconButton,
  StyledSideBarContent,
  StyledSideBarHandle
} from './style';

interface SidebarProps {
  children: ReactNode;
}

export const Sidebar: FC<SidebarProps> = ({ children }) => {
  const LocalStorageService = new LocalStorageServiceClass();

  const isSideBarInitialCollapsed = LocalStorageService.get(SIDEBAR_STORAGE_KEY) === SidebarState.Collapsed;
  const isResized = useRef(false);

  const [width, setWidth] = useState(isSideBarInitialCollapsed ? MIN_WIDTH : DEFAULT_WIDTH);
  const [isCollapsed, setIsCollapsed] = useState(isSideBarInitialCollapsed);
  const [isActive, setIsActive] = useState(false);

  const setSidebarStateStateToLocalStorage = (state: boolean) => {
    const resultState = state ? SidebarState.Collapsed : SidebarState.Uncollapsed;
    LocalStorageService.set(SIDEBAR_STORAGE_KEY, resultState);
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResized.current) {
        return;
      }

      const newMaxWidth = Math.max(MIN_WIDTH, width + e.movementX);
      const newWidth = Math.min(newMaxWidth, MAX_WIDTH);

      setWidth(newWidth);
    };

    const handleMouseUp = () => {
      isResized.current = false;
      setIsActive(false);

      if (isCollapsed) {
        if (width < THRESHOLD_WIDTH) {
          setIsCollapsed(true);
        }
      } else {
        if (width < THRESHOLD_WIDTH) {
          setWidth(MIN_WIDTH);
          setIsCollapsed(true);
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [width, MIN_WIDTH, MAX_WIDTH, THRESHOLD_WIDTH, isCollapsed]);

  const handleSideBarHandleMouseDown = () => {
    isResized.current = true;

    setIsActive(true);
    setIsCollapsed(false);
  };

  const handleSideBarContentMouseEnter = () => {
    if (isCollapsed) setWidth(DEFAULT_WIDTH);
  };

  const handleSideBarContentMouseLeave = () => {
    if (isCollapsed) setWidth(MIN_WIDTH);
  };

  const handleStyledHandleWrapperClick = (e: MouseEvent) => {
    e.stopPropagation();
    setSidebarStateStateToLocalStorage(!isCollapsed);

    setIsCollapsed(!isCollapsed);
    setWidth(width === MIN_WIDTH ? DEFAULT_WIDTH : MIN_WIDTH);
  };

  return (
    <SidebarContainer className={'sidebar'}>
      <StyledSideBarContent
        width={width}
        hideContent={isCollapsed && width <= MIN_WIDTH}
        onMouseEnter={handleSideBarContentMouseEnter}
        onMouseLeave={handleSideBarContentMouseLeave}
      >
        {children}
      </StyledSideBarContent>

      {/* Handle area */}
      <StyledHandleWrapper isCollapsed={isCollapsed}>
        <StyledIconButton<IconButtonProps> size={'small'} onClick={handleStyledHandleWrapperClick}>
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </StyledIconButton>

        <StyledSideBarHandle isActive={isActive} onMouseDown={handleSideBarHandleMouseDown} />
      </StyledHandleWrapper>
    </SidebarContainer>
  );
};
