import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function QRCodeIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'40'}
      height={'40'}
      viewBox={'0 0 40 40'}
      fill={'none'}
      sx={{ fill: '#FFFFFF', fontSize: 40, ...sx }}
      {...restProps}
    >
      <path d="M5.83325 16.8269V7.33979C5.83325 6.91299 5.97763 6.55522 6.26638 6.2665C6.5551 5.97775 6.91286 5.83337 7.33967 5.83337H16.8268C17.2536 5.83337 17.6114 5.97775 17.9001 6.2665C18.1888 6.55522 18.3332 6.91299 18.3332 7.33979V16.8269C18.3332 17.2538 18.1888 17.6115 17.9001 17.9003C17.6114 18.189 17.2536 18.3333 16.8268 18.3333H7.33967C6.91286 18.3333 6.5551 18.189 6.26638 17.9003C5.97763 17.6115 5.83325 17.2538 5.83325 16.8269ZM8.33321 15.8334H15.8333V8.33333H8.33321V15.8334ZM5.83325 32.6602V23.1731C5.83325 22.7463 5.97763 22.3885 6.26638 22.0998C6.5551 21.811 6.91286 21.6667 7.33967 21.6667H16.8268C17.2536 21.6667 17.6114 21.811 17.9001 22.0998C18.1888 22.3885 18.3332 22.7463 18.3332 23.1731V32.6602C18.3332 33.087 18.1888 33.4448 17.9001 33.7335C17.6114 34.0223 17.2536 34.1666 16.8268 34.1666H7.33967C6.91286 34.1666 6.5551 34.0223 6.26638 33.7335C5.97763 33.4448 5.83325 33.087 5.83325 32.6602ZM8.33321 31.6667H15.8333V24.1666H8.33321V31.6667ZM21.6665 16.8269V7.33979C21.6665 6.91299 21.8109 6.55522 22.0996 6.2665C22.3883 5.97775 22.7461 5.83337 23.173 5.83337H32.6601C33.0869 5.83337 33.4447 5.97775 33.7334 6.2665C34.0221 6.55522 34.1665 6.91299 34.1665 7.33979V16.8269C34.1665 17.2538 34.0221 17.6115 33.7334 17.9003C33.4447 18.189 33.0869 18.3333 32.6601 18.3333H23.173C22.7461 18.3333 22.3883 18.189 22.0996 17.9003C21.8109 17.6115 21.6665 17.2538 21.6665 16.8269ZM24.1665 15.8334H31.6665V8.33333H24.1665V15.8334ZM31.0415 34.1666V31.0416H34.1665V34.1666H31.0415ZM21.6665 24.7917V21.6667H24.7915V24.7917H21.6665ZM24.7915 27.9166V24.7917H27.9165V27.9166H24.7915ZM21.6665 31.0416V27.9166H24.7915V31.0416H21.6665ZM24.7915 34.1666V31.0416H27.9165V34.1666H24.7915ZM27.9165 31.0416V27.9166H31.0415V31.0416H27.9165ZM27.9165 24.7917V21.6667H31.0415V24.7917H27.9165ZM31.0415 27.9166V24.7917H34.1665V27.9166H31.0415Z" />
    </SvgIcon>
  );
}
