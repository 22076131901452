import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function BuildingIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M3.88441 20.5771C3.46263 20.5771 3.10398 20.4294 2.80848 20.1338C2.51298 19.8383 2.36523 19.4797 2.36523 19.0579V4.59635C2.36523 4.17456 2.51298 3.81591 2.80848 3.5204C3.10398 3.2249 3.46263 3.07715 3.88441 3.07715H10.346C10.7678 3.07715 11.1264 3.2249 11.4219 3.5204C11.7174 3.81591 11.8652 4.17456 11.8652 4.59635V7.07715H20.1152C20.537 7.07715 20.8956 7.2249 21.1911 7.5204C21.4867 7.81591 21.6344 8.17456 21.6344 8.59635V19.0579C21.6344 19.4797 21.4867 19.8383 21.1911 20.1338C20.8956 20.4294 20.537 20.5771 20.1152 20.5771H3.88441ZM3.86518 19.0772H10.3652V16.5771H3.86518V19.0772ZM3.86518 15.0771H10.3652V12.5771H3.86518V15.0771ZM3.86518 11.0771H10.3652V8.57712H3.86518V11.0771ZM3.86518 7.07715H10.3652V4.57712H3.86518V7.07715ZM11.8652 19.0772H20.1344V8.57712H11.8652V19.0772ZM14.8075 12.5771C14.595 12.5771 14.4169 12.5052 14.2731 12.3614C14.1294 12.2176 14.0575 12.0394 14.0575 11.8268C14.0575 11.6142 14.1294 11.4361 14.2731 11.2925C14.4169 11.1489 14.595 11.0771 14.8075 11.0771H16.9998C17.2123 11.0771 17.3904 11.1491 17.5342 11.2929C17.6779 11.4367 17.7498 11.6149 17.7498 11.8275C17.7498 12.0401 17.6779 12.2181 17.5342 12.3617C17.3904 12.5053 17.2123 12.5771 16.9998 12.5771H14.8075ZM14.8075 16.5771C14.595 16.5771 14.4169 16.5052 14.2731 16.3614C14.1294 16.2176 14.0575 16.0394 14.0575 15.8268C14.0575 15.6142 14.1294 15.4361 14.2731 15.2925C14.4169 15.1489 14.595 15.0771 14.8075 15.0771H16.9998C17.2123 15.0771 17.3904 15.1491 17.5342 15.2929C17.6779 15.4367 17.7498 15.6149 17.7498 15.8275C17.7498 16.0401 17.6779 16.2181 17.5342 16.3617C17.3904 16.5053 17.2123 16.5771 16.9998 16.5771H14.8075Z" />
    </SvgIcon>
  );
}
