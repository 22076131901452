import { ImgHTMLAttributes, SyntheticEvent, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';

import { StyledImage } from './style';

// https://www.npmjs.com/package/react-lazy-load-image-component
// import { InView } from 'react-intersection-observer';
interface ImageLazyProps extends Pick<ImgHTMLAttributes<null>, 'loading'> {
  className?: string;
  src: string;
  alt: string;
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
  width: number | string;
  height: number | string;
  minHeight?: number | string;
  withProgress?: boolean;
  sxStyle?: SxProps<Theme>;
}

export const ImageLazy = ({
  className,
  variant = 'circular',
  src,
  alt,
  loading = 'lazy',
  width,
  minHeight,
  height,
  withProgress,
  sxStyle
}: ImageLazyProps) => {
  const [status, setStatus] = useState('loading');

  const handleLoad = () => {
    setStatus('loaded');
  };

  const handleError = (e: SyntheticEvent<HTMLImageElement>) => {
    console.log('ImageLazy handleError error - ', e);

    setStatus('failed');
  };

  return (
    <>
      {src ? (
        <StyledImage
          className={className}
          variant={variant}
          sx={{
            position: 'relative',
            width,
            height: height === 'auto' ? minHeight : height,
            flex: `0 0 ${width}px`,
            maxWidth: '100%',
            ...sxStyle
          }}
        >
          {status === 'loading' ? (
            <>
              <Skeleton
                className={'m-0'}
                variant={variant}
                animation={'wave'}
                sx={{ position: 'absolute', left: '0', right: '0', top: '0', bottom: '0', height: '100%' }}
              ></Skeleton>

              {withProgress ? (
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress color={'inherit'} />
                </Box>
              ) : null}
            </>
          ) : null}

          <img
            src={src}
            alt={alt}
            loading={loading}
            width={width}
            height={height}
            onLoad={handleLoad}
            onError={handleError}
          />
        </StyledImage>
      ) : null}
    </>
  );
};
