import { ChipProps } from '@mui/material/Chip';

import { TaskStatus } from '@/logicLayers/domain';

import { StyledChip } from './styles';

type TaskStatusChipProps = ChipProps & {
  status: keyof typeof TaskStatus;
};

export const TaskStatusChip = ({ status }: TaskStatusChipProps) => {
  const taskLabel = TaskStatus[status];

  return <StyledChip variant={'filled'} colorVariant={status} size={'small'} label={taskLabel} />;
};
