import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { VisibilityIcon } from '../../../../../../../../../../components/Icon/svg-mui-theme/Visibility';
import { VisibilityOffIcon } from '../../../../../../../../../../components/Icon/svg-mui-theme/VisibilityOff';
import { SelectedParametersListProps } from '../../SelectedParametersList';

import { isUnVisibleItemParams, isVisibleItemParams } from '../../../../../Step3/helpers';

export const ParametersVisibilityList = [
  {
    checked: false,
    label: 'This parameter will be visible in the round form',
    value: isVisibleItemParams,
    icon: <VisibilityIcon sx={{ fontSize: 20, fill: '#63697C' }} fill="#63697C" />
  },
  {
    checked: false,
    label: "This parameter won't be visible in the round form",
    value: isUnVisibleItemParams,
    icon: <VisibilityOffIcon sx={{ fontSize: 20, fill: '#63697C' }} />
  }
];

export const SelectedParametersVisibility = ({ parameter }: SelectedParametersListProps) => {
  const isFormulaOrFixed = isUnVisibleItemParams.includes(parameter.type.toUpperCase());

  return (
    <Box sx={{ marginTop: '14px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isFormulaOrFixed ? (
          <>
            {ParametersVisibilityList[1].icon}
            <Text variant={'bodyMd'} sx={{ margin: '0 0 0 8px', fontSize: '14px', color: '#505668' }}>
              {ParametersVisibilityList[1].label}
            </Text>
          </>
        ) : (
          <>
            {ParametersVisibilityList[0].icon}
            <Text variant={'bodyMd'} sx={{ margin: '0 0 0 8px', fontSize: '14px', color: '#505668' }}>
              {ParametersVisibilityList[0].label}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
};
