import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CreateNewFolderIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M3.58991 16.25C3.16897 16.25 2.81266 16.1041 2.521 15.8125C2.22933 15.5208 2.0835 15.1645 2.0835 14.7435V5.25642C2.0835 4.83547 2.22933 4.47917 2.521 4.1875C2.81266 3.89583 3.16897 3.75 3.58991 3.75H7.54181C7.74266 3.75 7.93576 3.789 8.12112 3.867C8.30648 3.94499 8.46754 4.05235 8.60429 4.1891L9.83185 5.41667H16.4104C16.8313 5.41667 17.1876 5.5625 17.4793 5.85417C17.771 6.14583 17.9168 6.50214 17.9168 6.92308V14.7435C17.9168 15.1645 17.771 15.5208 17.4793 15.8125C17.1876 16.1041 16.8313 16.25 16.4104 16.25H3.58991ZM3.58991 15H16.4104C16.4852 15 16.5466 14.9759 16.5947 14.9279C16.6428 14.8798 16.6668 14.8183 16.6668 14.7435V6.92308C16.6668 6.84829 16.6428 6.78685 16.5947 6.73877C16.5466 6.69069 16.4852 6.66665 16.4104 6.66665H9.32066L7.7261 5.0721C7.69939 5.0454 7.67135 5.02669 7.64198 5.016C7.61259 5.00532 7.58186 4.99998 7.54981 4.99998H3.58991C3.51512 4.99998 3.45368 5.02402 3.4056 5.0721C3.35752 5.12019 3.33348 5.18163 3.33348 5.25642V14.7435C3.33348 14.8183 3.35752 14.8798 3.4056 14.9279C3.45368 14.9759 3.51512 15 3.58991 15ZM11.8752 11.4583V12.5C11.8752 12.6773 11.935 12.8258 12.0546 12.9455C12.1743 13.0651 12.3228 13.125 12.5001 13.125C12.6775 13.125 12.826 13.0651 12.9456 12.9455C13.0653 12.8258 13.1251 12.6773 13.1251 12.5V11.4583H14.1668C14.3442 11.4583 14.4927 11.3985 14.6123 11.2788C14.732 11.1592 14.7918 11.0107 14.7918 10.8333C14.7918 10.656 14.732 10.5075 14.6123 10.3878C14.4927 10.2682 14.3442 10.2083 14.1668 10.2083H13.1251V9.16665C13.1251 8.9893 13.0653 8.8408 12.9456 8.72115C12.826 8.60149 12.6775 8.54167 12.5001 8.54167C12.3228 8.54167 12.1743 8.60149 12.0546 8.72115C11.935 8.8408 11.8752 8.9893 11.8752 9.16665V10.2083H10.8335C10.6561 10.2083 10.5076 10.2682 10.388 10.3878C10.2683 10.5075 10.2085 10.656 10.2085 10.8333C10.2085 11.0107 10.2683 11.1592 10.388 11.2788C10.5076 11.3985 10.6561 11.4583 10.8335 11.4583H11.8752Z" />
    </SvgIcon>
  );
}
