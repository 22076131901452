import { useState } from 'react';
import { Select, SelectProps, Text } from 'infinitytechnologies-ui';

import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';

import { SELECT_DATA_EXAMPLE } from './select-example-mock';

interface SelectExampleProps extends SelectProps {
  exampleType: string;
}

export function SelectExample({ exampleType, helperText, value = '', validation, sx, ...props }: SelectExampleProps) {
  const [state, setState] = useState<string | string[]>(value);

  const handleChange = (event: SelectChangeEvent<typeof state>) => {
    const {
      target: { value }
    } = event;

    setState(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div className={'mb-5'}>
      <Text className={'mt-2 mb-3'} component={'h5'} sx={{ fontSize: 16, weight: 600 }}>
        {exampleType}
      </Text>

      <Select {...props} helperText={helperText} value={state} validation={validation} sx={sx} onChange={handleChange}>
        {SELECT_DATA_EXAMPLE.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
