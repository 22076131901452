import { useParams } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

export const TemplatesEquipmentItemDocumentsPage = () => {
  const { templateId = '' } = useParams();

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} md={10} lg={8} item>
        <Text component={'h2'} variant={'titleLg'}>
          Templates Equipment Item Documents Page
        </Text>

        <p>{templateId}</p>
      </Grid>
    </Grid>
  );
};
