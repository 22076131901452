import dayjs from 'dayjs';

import { Button, SvgIcon } from '@mui/material';
import Chip from '@mui/material/Chip';

import { ArrowForwardIcon, TableAsideItem } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { checkIsFormulaOrFixed, getParamItemConfig } from '../../helpers';

export const AsideContent = (props: any) => {
  const { t: tGlobal } = useTranslation('global');

  const paramItemConfig = getParamItemConfig({ original: { type: props?.type } });

  return (
    <>
      <TableAsideItem title={tGlobal('table.aside.description')} subTitle={props?.description} />

      <TableAsideItem
        title={tGlobal('table.aside.visibilityInRoundForm')}
        subTitle={checkIsFormulaOrFixed({
          original: props
        })}
      />

      <TableAsideItem
        title={tGlobal('table.aside.type')}
        subTitle={
          <Chip
            variant={'filled'}
            size={'small'}
            label={paramItemConfig?.title}
            color={'secondary'}
            sx={{ margin: '0 0 0 8px' }}
          />
        }
      />

      <TableAsideItem
        title={tGlobal('table.aside.creationDate')}
        subTitle={dayjs(props?.createdDate).format('DD MMM YYYY, HH:mm A')}
      />

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        onClick={props?.handleChangeTest}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
      >
        {'Details'}
      </Button>
    </>
  );
};
