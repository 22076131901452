import IconButton from '@mui/material/IconButton';

import { HelpOutlineIcon, SettingsIcon } from '@/separatedModules/components';

export const LeftItem = () => {
  return (
    <div className={'header__right__i'}>
      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#DCDFE4' }} aria-label={''}>
        <HelpOutlineIcon />
      </IconButton>

      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#DCDFE4' }} aria-label={''}>
        <SettingsIcon />
      </IconButton>
    </div>
  );
};
