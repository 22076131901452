import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from '@/separatedModules/layouts';

export const StyledHeader = styled('header')(() => ({
  borderBottom: '1px solid rgba(41, 49, 62, 0.15)',
  padding: '12px 0',
  minHeight: `${HEADER_HEIGHT}px`,
  '.header': {
    '&__container': {
      maxWidth: '1920px',
      margin: '0 auto'
    },
    '&__left': {
      display: 'flex',
      alignItems: 'center'
    },
    '&__right': {
      display: 'flex',
      alignItems: 'center',
      '&__i': {
        display: 'flex',
        '&__line': {
          margin: '0 12px',
          flex: '0 0 1px',
          width: '1px',
          background: 'var(--white-30, rgba(255, 255, 255, 0.30))'
        }
      }
    }
  }
}));
