export const base64ToFile = ({ url, fileName, mimeType }: { url: string; fileName: string; mimeType: string }) => {
  const MIME_TYPE = mimeType || (url.match(/^data:([^;]+);/) || '')[1];

  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: MIME_TYPE });
    });
};

export const blobFromUrl = async (url: string) => {
  return fetch(url).then((response) => response.blob());
};

export const readFileAsBase64 = (file: Blob, onLoad: (reader: any) => void) => {
  const reader = new FileReader();

  reader.addEventListener('load', () => {
    onLoad(reader.result);
  });

  reader.readAsDataURL(file);
};
