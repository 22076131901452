import { FC } from 'react';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { GET_USER_BY_ID } from '@/logicLayers/domain';

import { EditPageWrap, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { UserEditProvider } from './components/UserEditProvider';

export const UserEditPage: FC = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { userId = '' } = useParams();
  const { t: tUser } = useTranslation('user');
  const { t: tGlobal, i18n } = useTranslation('global');

  const { data: dataUser, loading: loadingUser } = useQuery(GET_USER_BY_ID, { variables: { uid: userId } });

  const editValue = searchParams.get('field') ?? '';
  const isVisibility = location.search.includes('visibility');

  const navigateToUrl = LINKS_PAGES.user + '/' + userId + `${isVisibility ? '/visibility' : ''}`;

  const Component = UserEditProvider({
    id: userId,
    firstName: dataUser?.user.firstName || '',
    lastName: dataUser?.user.lastName || '',
    phoneNumber: dataUser?.user.phoneNumber || '',
    /* custom fields */
    editType: editValue,
    navigateToUrl,
    tUser,
    tGlobal,
    // ToDo Type
    i18n
  });

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        {loadingUser ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
        ) : (
          <EditPageWrap navigateTo={navigateToUrl}>
            {Component ? Component : <Navigate to={LINKS_PAGES.home} />}
          </EditPageWrap>
        )}
      </Grid>
    </Grid>
  );
};
