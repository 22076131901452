import { FC } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { PermissionsSelectButton } from '@/separatedModules/pages/Templates/pages/Role/pages/CreateRole/components/PermissionsSelectButton';

import { MenuItemProps, PermissionItemVariant } from '../helpers';

interface PermissionItemProps {
  title: string;
  description: string;
  sxTitle?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  variant?: PermissionItemVariant;
  menuList: MenuItemProps[];
  onMenuItemClick: (item: MenuItemProps) => void;
}

export const PermissionItem: FC<PermissionItemProps> = ({
  title,
  description,
  sxTitle,
  sxDescription,
  variant = 'base',
  menuList,
  onMenuItemClick
}) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Box>
        <Text variant={'bodyLg'} sx={{ mb: '12px', color: '#090E16', ...sxTitle }}>
          {title}
        </Text>
        <Text variant={'bodyMd'} maxWidth={'440px'} sx={sxDescription}>
          {description}
        </Text>
      </Box>

      <span style={{ display: 'block', whiteSpace: 'nowrap', fontSize: 14 }}>
        <PermissionsSelectButton variant={variant} menuList={menuList} onMenuItemClick={onMenuItemClick} />
      </span>
    </Box>
  );
};
