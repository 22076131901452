import { useMemo, useState } from 'react';
import { getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AddUser } from '@/separatedModules/pages/Users/components';

import {
  Flex,
  handleGetSavedColumns,
  handleSetSavedColumns,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownColumnsVisibility,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';

import { i18n, useTranslation } from '@/i18n';

import { TableFiltersBoxProvider } from './components';

import { columns, initState, skeletonTableLoaderCells, TableCellItem, useTableData } from './helpers';

export const UsersPage2 = () => {
  const { t: tTemplates } = useTranslation('templates');
  const TABLE_SAVED_COLUMNS_ID = '/users';

  const { renderCount, renderItems, ...tableState } = useTableData(initState);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    defaultColumn: {
      size: 300,
      minSize: 300,
      maxSize: 300
    }
  });

  const columnsVisibility = useMemo(() => {
    const columnsVisibilityStatus = table.getAllLeafColumns().reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.id]: currentValue.getIsVisible() };
    }, {});

    handleSetSavedColumns(TABLE_SAVED_COLUMNS_ID, columnsVisibilityStatus);

    return table.getAllLeafColumns().map((column) => {
      return {
        value: column.id,
        label: i18n.t(`table.cells.${column.id}`, { ns: 'global' }),
        checked: column.getIsVisible(),
        onToggleVisibility: column.toggleVisibility
      };
    });
  }, [columnVisibility]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <TablePageTopBox
          title={i18n.t('pageTitle', { ns: 'users' })}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          btnText={tTemplates('equipmentItem.parameters.btnCreateParameter')}
        >
          <AddUser />
        </TablePageTopBox>

        <TableFiltersBox
          searchPlaceholder={i18n.t('table.filters.searchBy.placeholder', { ns: 'users' })}
          selectedItems={tableState.state.selectedItems}
          handleToggleAside={tableState.handleToggleAside}
          handleChangeBySearch={tableState.handleChangeBySearch}
        >
          <TableDropdownColumnsVisibility
            menu={columnsVisibility}
            toggleAllColumnsVisible={table.toggleAllColumnsVisible}
          />

          <TableFiltersBoxProvider tableState={tableState} users={theData} />

          <TableSortByOneItem menu={SORT_BY_DATE_CREATE} showIcon={true} onChange={tableState.handleChangeSortByDate} />
        </TableFiltersBox>

        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {renderCount ? (
              renderItems ? (
                <Flex>
                  <Box width={'100%'}>
                    <Box sx={{ overflow: 'auto' }}>
                      <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                        <Table className={'m-0'}>
                          <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return <TableCellItem key={header.id} cell={header} isHeader />;
                                })}
                              </TableRow>
                            ))}
                          </TableHead>

                          <TableBody>
                            {table.getRowModel().rows.map((row) => {
                              return (
                                <TableRowWrap
                                  key={row.original.id}
                                  id={row.original.id as string}
                                  isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                  handleClickRow={tableState.handleClickRow}
                                  handleDoubleClickRow={tableState.handleDoubleClickRow}
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return <TableCellItem key={cell.id} cell={cell} />;
                                  })}
                                </TableRowWrap>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <MuiTablePagination
                      count={renderCount}
                      page={tableState.state.page[0]}
                      rowsPerPage={tableState.state.rowsPerPage[0]}
                      rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                      onChangePage={tableState.handleChangePagination}
                      onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                    />
                  </Box>

                  <TableAside
                    rows={table.getRowModel().rows}
                    selectedItems={tableState.state.selectedItems}
                    isAsideOpen={tableState.state.isAsideOpen}
                    handleToggleAside={tableState.handleToggleAside}
                  >
                    {/* {(props: any) => <AsideContent {...props} />}*/}
                    {(props: any) => {
                      console.log('TableAside props  -', props);

                      return null;
                    }}
                  </TableAside>
                </Flex>
              ) : (
                <NotFoundScreens type={'search'} />
              )
            ) : (
              <NotFoundScreens
                type={'companies'}
                title={tTemplates('equipmentItem.parameters.notFound.title')}
                subTitle={tTemplates('equipmentItem.parameters.notFound.subTitle')}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
