import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { SxProps, Theme } from '@mui/material';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { FormulaMainContent } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps/Step2/components/Formula/FormulaMainContent';
import { useData } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps/Step2/components/Formula/helpers';
import { PARAMETER_VALUE } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/helpers';

import { ButtonLoading, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export interface EditInputItemProps {
  editFieldType?: string;
  editFieldName: string;
  editFieldValue: string;
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  patternMessage: string;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  inputLabel: string;
  inputPlaceholder?: string;
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  regExpMinLength?: number;
  regExpMaxLength?: number;
  maxContentLength?: number;
  name?: string;
  rows?: number;
  regExp: RegExp;
  ContentAfterTitle?: ReactNode;
  multiline?: boolean;
  onSubmitForm: (getValues: any, updateModel: any) => void;
  templateId: string;
  sxTitle?: SxProps<Theme>;
}

const styles = [
  'color: #090E16',
  'background: #E6F6EF',
  'font-size: 10px',
  'border: 1px solid #06845B',
  'padding: 2px'
].join(';');

export const EditFormula = ({
  editFieldName,
  editFieldValue,
  navigateToUrl,
  mutationQuery,
  successAlertMessage,
  errorAlertMessage,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  ContentAfterTitle,
  onSubmitForm,
  templateId,
  name,
  sxTitle
}: EditInputItemProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data, error }] = useMutation(mutationQuery);
  const { equipmentParametersData } = useData({ templateId });

  const {
    getValues,
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [editFieldName]: editFieldValue
    }
  });

  const isValidError = errors[PARAMETER_VALUE]?.message;

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm(getValues, updatedData);
  });

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      const errorObj = JSON.parse(error.message);
      const isInvalidFormula = errorObj.reason === "Invalid formula, reason: 'null'";

      AlertService.showAlert({
        title: (isInvalidFormula && 'Invalid Formula') || errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  // ToDo Remove
  console.log('%c' + `editFieldName, editFieldValue - ${editFieldName}, ${editFieldValue}`, styles);

  return (
    <form onSubmit={handleSubmitForm}>
      <TextBlockControl
        title={pageTitle}
        subTitle={pageSubTitle}
        ContentAfterTitle={ContentAfterTitle}
        isTitleLg
        isBodyLg
        sxTitle={sxTitle}
      />

      <Controller
        name={editFieldName}
        control={control}
        render={({ field }) => {
          const { onChange } = field;

          return (
            <FormulaMainContent
              setValue={(_, formula) => onChange(formula)}
              equipmentParametersData={equipmentParametersData}
              value={editFieldValue}
              title={name}
              setError={setError}
              isValidError={isValidError}
            />
          );
        }}
      />

      <ButtonLoading
        className={'edit__content__btn'}
        variant={'contained'}
        type={'submit'}
        loading={loading}
        disabled={Boolean(data)}
      >
        {SUBMIT_BTN_TEXT}
      </ButtonLoading>
    </form>
  );
};
