import { FC, useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { GET_LOCATIONS_TAB, GetLocationsTabQuery } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';

import { ClickableItem, LocationFolders } from '../index';

interface AllLocationsTabProps {
  sourceLocationId?: string;
}

export const AllLocationsTab: FC<AllLocationsTabProps> = ({ sourceLocationId }) => {
  const { startMoving, progressList, setDestinationData, setLocationToProgressListWithNextStep } = useLocations();

  const [selectedFolder, setSelectedFolder] = useState('');

  const { data: dataLocations, loading: loadingLocations } = useQuery<GetLocationsTabQuery>(GET_LOCATIONS_TAB, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 100,
          page: 0
        },
        isArchive: false
      }
    },
    fetchPolicy: 'no-cache'
  });

  const handleClickLocation = (id: string, name: string) => {
    if (id) {
      setSelectedFolder(id);
      setDestinationData({
        id,
        locationName: name
      });
    }
  };

  const handleClickNextLocation = (id: string, name: string) => {
    setLocationToProgressListWithNextStep({ id: id, locationName: name });
    setSelectedFolder(id);
    startMoving();
  };

  const folders = dataLocations?.dataItems.items;

  return (
    <>
      {loadingLocations ? (
        <Skeleton className={'m-0'} variant={'rectangular'} width={'100%'} height={210} animation={'wave'} />
      ) : (
        <>
          {progressList.length === 0 &&
            folders?.map((folder) => (
              <Box key={folder?.id}>
                {folder?.id && folder?.name ? (
                  <ClickableItem
                    disabled={folder.id === sourceLocationId || !folder.active}
                    folderId={folder.id}
                    folderName={folder.name}
                    selectedFolderId={selectedFolder}
                    onSelectLocation={handleClickLocation}
                    onClickNextLocation={handleClickNextLocation}
                  />
                ) : null}
              </Box>
            ))}
        </>
      )}

      <>{progressList.length ? <LocationFolders sourceLocationId={sourceLocationId} /> : null}</>
    </>
  );
};
