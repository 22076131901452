import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';

import { UserDetails } from '@/separatedModules/components';

export const OverviewPage = () => {
  const {
    data: dataUserProfile,
    loading: loadingUserProfile,
    error: errorUserProfile
  } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  return (
    <Grid xs={12} md={10} item>
      <UserDetails
        variant={'full'}
        firstName={dataUserProfile?.profile.firstName ?? ''}
        lastName={dataUserProfile?.profile.lastName ?? ''}
        email={dataUserProfile?.profile.email}
        errorUser={errorUserProfile}
        loading={loadingUserProfile}
      />
    </Grid>
  );
};
