import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { EDIT_USER_ACCESS, EditUserAccessMutation, GET_USER_BY_ID } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { EditPageWrap, PageTitle, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { RolePlates } from './components';

export const UserRoleManagementPage: FC = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tUser } = useTranslation('user');

  const location = useLocation();
  const isShowVisibility = location.search.includes('visibility');
  const navigateTo = useNavigate();

  const { userId = '' } = useParams();

  const { data: dataUser, loading: loadingUser } = useQuery(GET_USER_BY_ID, {
    variables: { uid: userId },
    skip: !userId
  });

  const [EditUserAccess] = useMutation<EditUserAccessMutation>(EDIT_USER_ACCESS, {
    refetchQueries: [GET_USER_BY_ID, 'GetUser']
  });

  const [selectedRoleItem, setSelectedRoleItem] = useState('');

  const USER_PERMISSIONS_PAGE_LINK = `${LINKS_PAGES.user}/${userId}/permissions${isShowVisibility ? '/visibility' : ''}`;
  const firstName = dataUser?.user?.firstName;
  const lastName = dataUser?.user?.lastName;
  const userCompanyId = dataUser?.user?.company?.id;

  const handleSelectRoleClick = (id: string | undefined) => {
    if (id) {
      setSelectedRoleItem(id);
    }
  };

  const handleConfirmChangeUserRole = () => {
    EditUserAccess({
      variables: {
        companyId: userCompanyId,
        request: {
          userId: userId,
          templateId: selectedRoleItem
        }
      }
    })
      .then(() => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.success.edits'),
          severity: 'success'
        });

        navigateTo(USER_PERMISSIONS_PAGE_LINK);
      })
      .catch((error) => {
        const errorMessage = JSON.parse(error.message);

        AlertService.showAlert({
          title: tGlobal(errorMessage.reason ?? tGlobal('alertMessages.errors.base')),
          severity: 'error'
        });
      });
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} sx={{ minHeight: '70vh' }} item>
        <EditPageWrap navigateTo={USER_PERMISSIONS_PAGE_LINK}>
          <PageTitle
            title={tUser('roleManagement.pageTitle')}
            sxTitle={{ mt: '32px', mb: '16px' }}
            subTitle={
              <Trans
                i18nKey={tUser('roleManagement.pageSubTitle')}
                values={{ firstName: firstName?.length ? firstName : '-', lastName: lastName?.length ? lastName : '-' }}
                components={{ span: <span style={{ fontWeight: 600 }} /> }}
              />
            }
            sxSubTitle={{ color: '#505668', mb: '48px' }}
          />

          {loadingUser ? (
            <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
          ) : (
            <RolePlates
              userId={userId}
              userCompanyId={userCompanyId}
              selectedRoleItem={selectedRoleItem}
              onChange={handleSelectRoleClick}
            />
          )}

          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            position={'sticky'}
            bottom={0}
            pt={'24px'}
            pb={'40px'}
            sx={{ background: 'white' }}
          >
            <Button variant={'contained'} onClick={handleConfirmChangeUserRole} disabled={!selectedRoleItem.length}>
              {tUser('roleManagement.btnSubmit')}
            </Button>
          </Box>
        </EditPageWrap>
      </Grid>
    </Grid>
  );
};
