import { Avatar, Text } from 'infinitytechnologies-ui';

import { EmailIcon, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { UserDetailsProps } from '../../UserDetails';

interface UserDetailsFullProps
  extends Pick<UserDetailsProps, 'firstName' | 'lastName' | 'email' | 'loading' | 'logo'> {}

export const UserDetailsFull = ({ firstName, lastName, email, logo, loading }: UserDetailsFullProps) => {
  const { t } = useTranslation('overview');

  return loading ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
  ) : (
    <>
      <Text variant={'titleLg'}>
        {t('greetings')}

        {firstName}
      </Text>

      <Text variant={'bodyLg'}>{t('title')}</Text>

      {firstName && lastName ? (
        <div className={'user-details__info'}>
          <Avatar size={'136'} src={logo}>
            {firstName.at(0)}
            {lastName.at(0)}
          </Avatar>

          <div className={'user-details__info__detail'}>
            {firstName && lastName ? (
              <Text variant={'titleMd'}>
                {firstName} {lastName}
              </Text>
            ) : null}

            <div className={'user-details__info__role'} />

            {email ? (
              <div className={'user-details__info__email'}>
                <EmailIcon sx={{ margin: '0 8px 0 0' }} />

                <Text className={'m-0'} variant={'bodyMd'} sx={{ wordBreak: 'break-all' }}>
                  {email}
                </Text>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};
