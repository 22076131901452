import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Button, SvgIcon } from '@mui/material';

import { TaskStatus } from '@/logicLayers/domain';

import { ArrowForwardIcon, EMPTY_CELL_VALUE, Flex, ImageLazy, TableAsideItem } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useTranslation } from '@/i18n';

type AsideContentProps = {
  description: string;
  status: keyof typeof TaskStatus;
  startDate: string;
  endDate: string;
  performer: {
    userAvatar: {
      url: string;
    };
    firstName: string;
    lastName: string;
    id: string;
  };
  profileId: string;
  handleChange: () => void;
};

export const AsideContent = ({
  description,
  status,
  startDate,
  endDate,
  performer,
  handleChange,
  profileId
}: AsideContentProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTasks } = useTranslation('tasks');

  return (
    <Box>
      <TableAsideItem title={tGlobal('table.aside.description')} subTitle={description} />
      <TableAsideItem title={tTasks('tasksCustom.table.status')} subTitle={<TaskStatusChip status={status} />} />
      <TableAsideItem
        title={tTasks('tasksCustom.table.startDate')}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {startDate ? dayjs(startDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
          </Text>
        }
      />
      <TableAsideItem
        title={tTasks('tasksCustom.table.endDate')}
        subTitle={
          <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
            {endDate ? dayjs(endDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
          </Text>
        }
      />
      <TableAsideItem
        title={tTasks('tasksCustom.table.performer')}
        subTitle={
          <Flex sx={{ alignItems: 'center', marginTop: '12px' }}>
            <>
              {performer?.userAvatar?.url ? (
                <ImageLazy
                  width={24}
                  height={24}
                  src={performer?.userAvatar?.url}
                  sxStyle={{
                    borderRadius: '16px',
                    border: '1px solid #F7F8F9',
                    position: 'relative'
                  }}
                  alt={''}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    background: 'rgb(240, 240, 241)',
                    border: '1px solid #F7F8F9'
                  }}
                />
              )}
            </>
            <Text component={'span'} variant={'bodyMd'} sx={{ margin: '0px', marginLeft: '8px', fontSize: '16px' }}>
              {profileId === performer?.id
                ? tTasks('tasksCustom.table.selfPerformance')
                : performer?.firstName.length && performer?.lastName.length
                  ? `${performer.firstName} ${performer.lastName}`
                  : EMPTY_CELL_VALUE}
            </Text>
          </Flex>
        }
      />
      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        onClick={handleChange}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
      >
        {tGlobal('table.aside.detailsBtn')}
      </Button>
    </Box>
  );
};
