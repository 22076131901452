import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Button, Text } from 'infinitytechnologies-ui';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import {
  EquipmentQuery,
  GET_EQUIPMENT,
  GET_EQUIPMENT_ROUND_REGULATION,
  GET_ROLE_TEMPLATES,
  GetRoleTemplatesQuery,
  RoundRegulationQuery,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';

import {
  AutocompleteOptionT,
  Flex,
  InfoIcon,
  MuiTablePagination,
  NotFoundScreens,
  PersonAddIcon,
  SkeletonTableLoader,
  TableAside,
  TableDropdownCheckbox,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { UsersAsideContent } from './components';

import { TASK_BY_ROUND_SERVICE_STUFF } from '../../helpers';
import { columns, initState, skeletonTableLoaderCells, useTableData } from './helpers';

export const Step2 = () => {
  const { t: tTemplates } = useTranslation('templates');
  const { templateId = '', roundId = '' } = useParams();
  const { renderCount, renderItems, ...tableState } = useTableData(initState);
  const navigateTo = useNavigate();

  const { control, watch, setValue } = useFormContext();

  const { data: dataEquipmentTemplateRoundRegulation } = useQuery<RoundRegulationQuery>(
    GET_EQUIPMENT_ROUND_REGULATION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        roundId
      }
    }
  );

  useEffect(() => {
    const selectedItems = dataEquipmentTemplateRoundRegulation?.dataItems.serviceStaff || [];

    setValue(TASK_BY_ROUND_SERVICE_STUFF, selectedItems);
  }, [dataEquipmentTemplateRoundRegulation]);

  const { data: dataEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    variables: {
      id: templateId || undefined
    }
  });

  const equipmentDomainName = dataEquipmentTemplate?.equipment.domain.name;

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const { data: dataRoleTemplates } = useQuery<GetRoleTemplatesQuery>(GET_ROLE_TEMPLATES, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const rolesOptions = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return dataRoleTemplates?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.name as string,
      key: domain?.id as string,
      checked: false,
      disabled: false
    }));
  }, [dataRoleTemplates]);

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string,
      checked: false,
      disabled: false
    }));
  }, [domainsData]);

  const selectedServiceStuff = watch(TASK_BY_ROUND_SERVICE_STUFF);

  return (
    <Controller
      name={TASK_BY_ROUND_SERVICE_STUFF}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <Stack minHeight={'60vh'}>
            <Grid justifyContent={'center'} container row sx={{ marginTop: '48px' }}>
              <Grid xs={12} item>
                <TablePageTopBox title={tTemplates('equipmentItem.round.taskByRound.step2.title')} isStep>
                  <Button
                    size={'small'}
                    variant="outlined"
                    startIcon={<PersonAddIcon />}
                    onClick={() => {
                      navigateTo(LINKS_PAGES.users);
                    }}
                  >
                    {tTemplates('equipmentItem.round.taskByRound.step2.inviteBtn')}
                  </Button>
                </TablePageTopBox>
                <Text
                  sx={{
                    maxWidth: '664px',
                    fontSize: '14px',
                    color: 'var(--text-subtle, #505668)',
                    fontWeight: '400',
                    marginBottom: '24px'
                  }}
                >
                  {tTemplates('equipmentItem.round.taskByRound.step2.subTitle')}
                </Text>

                {Boolean(!selectedServiceStuff.length) && (
                  <TableFiltersBox
                    searchPlaceholder={tTemplates('equipmentItem.round.taskByRound.step2.table.searchPlaceholder')}
                    selectedItems={selectedServiceStuff}
                    handleToggleAside={tableState.handleToggleAside}
                    handleChangeBySearch={tableState.handleChangeBySearch}
                  >
                    <TableDropdownCheckbox
                      // ToDo Refactor
                      title={tTemplates('equipmentItem.round.taskByRound.step2.table.domainsTitle')}
                      menuList={domainsOptions as any}
                      withSelectAll
                      withSearch
                      searchPlaceholder={tTemplates(
                        'equipmentItem.round.taskByRound.step2.table.domainsSearchPlaceholder'
                      )}
                      selectAllTitle={tTemplates('equipmentItem.round.taskByRound.step2.table.showAll')}
                      onChange={(value) => {
                        tableState.handleChangeByDomains(value);
                      }}
                    />

                    <TableDropdownCheckbox
                      // ToDo Refactor
                      title={tTemplates('equipmentItem.round.taskByRound.step2.table.roleTitle')}
                      menuList={rolesOptions as any}
                      withSelectAll
                      withSearch
                      searchPlaceholder={tTemplates(
                        'equipmentItem.round.taskByRound.step2.table.roleSearchPlaceholder'
                      )}
                      selectAllTitle={tTemplates('equipmentItem.round.taskByRound.step2.table.showAll')}
                      onChange={(value) => {
                        tableState.handleChangeByRoles(value);
                      }}
                    />
                  </TableFiltersBox>
                )}
                {tableState.loading ? (
                  <SkeletonTableLoader cells={skeletonTableLoaderCells} />
                ) : renderCount || tableState.state.filters?.search.length ? (
                  renderItems ? (
                    <Flex>
                      <Box width={'100%'}>
                        <Box sx={{ overflow: 'auto', maxHeight: '400px' }}>
                          <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                            {Boolean(selectedServiceStuff.length) && (
                              <Flex
                                sx={{
                                  width: '100%',
                                  height: '32px',
                                  background: 'var(--background-neutral-pressed, #F1F2F4)',
                                  borderRadius: '4px',
                                  padding: '0px 4px',
                                  marginBottom: '16px'
                                }}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Flex alignItems="center">
                                  <IconButton
                                    size={'medium'}
                                    className={'aside-table__btn__close'}
                                    onClick={() => {
                                      onChange([]);
                                      setValue(TASK_BY_ROUND_SERVICE_STUFF, []);
                                      tableState.handleClearSelectedItems();
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                  <Text sx={{ fontSize: '14px', marginLeft: '16px' }}>
                                    {selectedServiceStuff.length} selected
                                  </Text>
                                </Flex>
                                <IconButton
                                  size={'medium'}
                                  sx={{ borderRadius: '100%' }}
                                  onClick={tableState.handleToggleAside}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </Flex>
                            )}
                            <Table className={'m-0 MuiTable-root__columns-3'}>
                              <TableHead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                  <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                      <TableCell key={header.id}>
                                        {header.isPlaceholder
                                          ? null
                                          : flexRender(header.column.columnDef.header, header.getContext())}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableHead>
                              <TableBody>
                                {table.getRowModel().rows.map((row) => {
                                  const isHidden = Boolean(
                                    !row.original.domains.filter((domain) => domain.name === equipmentDomainName).length
                                  );

                                  return (
                                    <TableRowWrap
                                      key={row.original.id}
                                      id={row.original.id as string}
                                      isSelected={selectedServiceStuff.some((item: any) => item.id === row.original.id)}
                                      handleClickRow={(rowId) => {
                                        const serviceStuff = theData.find((data) => data?.id === rowId);

                                        const inSelectedExist = selectedServiceStuff.some(
                                          (item: { id: string }) => item.id === rowId
                                        );

                                        const updatedSelectedItems = inSelectedExist
                                          ? selectedServiceStuff.filter(
                                              (selectedServiceStuff: { id: string }) =>
                                                selectedServiceStuff.id !== rowId
                                            )
                                          : [serviceStuff, ...selectedServiceStuff];

                                        onChange(updatedSelectedItems);
                                      }}
                                      handleDoubleClickRow={tableState.handleDoubleClickRow}
                                      style={{ opacity: isHidden ? '0.5' : 1 }}
                                    >
                                      {row.getVisibleCells().map((cell) => {
                                        return (
                                          <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRowWrap>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <MuiTablePagination
                          count={renderCount}
                          page={tableState.state.page[0]}
                          rowsPerPage={tableState.state.rowsPerPage[0]}
                          rowsPerPageOptions={[20, 50, 100]}
                          onChangePage={tableState.handleChangePagination}
                          onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                        />
                      </Box>
                      <TableAside
                        rows={table.getRowModel().rows}
                        selectedItems={tableState.state.selectedItems}
                        isAsideOpen={tableState.state.isAsideOpen}
                        handleToggleAside={tableState.handleToggleAside}
                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                      >
                        {(props: any) => {
                          const isHidden = !props.domains.filter(
                            (domain: { name: string | undefined }) => domain.name === equipmentDomainName
                          ).length;

                          return (
                            <UsersAsideContent
                              {...props}
                              isHidden={isHidden}
                              handleClickDetails={() => {
                                navigateTo(`/user/${tableState.state.selectedItems[0]}`);
                              }}
                            />
                          );
                        }}
                      </TableAside>
                    </Flex>
                  ) : (
                    <Box sx={{ height: '59vh' }}>
                      <NotFoundScreens type={'search'} />
                    </Box>
                  )
                ) : (
                  <NotFoundScreens
                    type={'companies'}
                    title={tTemplates('equipmentItem.round.taskByRound.step2.emptyTitle')}
                    subTitle={tTemplates('equipmentItem.round.taskByRound.step2.emptySubTitle')}
                  />
                )}
              </Grid>
            </Grid>
          </Stack>
        );
      }}
    />
  );
};
