import { Text } from 'infinitytechnologies-ui';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTranslation } from '@/i18n';

import { CONFIG_TABLE } from '../../helpers';

export const TableHeader = () => {
  const { t: tCompanies } = useTranslation('companies');

  return (
    <TableHead>
      <TableRow>
        {CONFIG_TABLE.cells.map(({ columnName, title }, index) => {
          return (
            <TableCell key={columnName} className={CONFIG_TABLE.cells[index].columnClass}>
              <Text variant={'labelMd'}>{tCompanies(title)}</Text>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
