import { i18n } from '@/i18n/config';

import { TaskTypes } from '../../../helpers';

export const TASK_TYPES_LIST = [
  {
    id: 0,
    title: i18n.t('createTask.step1.taskType.unplanned.title', { ns: 'tasks' }),
    description: i18n.t('createTask.step1.taskType.unplanned.description', { ns: 'tasks' }),
    taskType: TaskTypes.UNPLANNED
  },
  {
    id: 1,
    title: i18n.t('createTask.step1.taskType.custom.title', { ns: 'tasks' }),
    description: i18n.t('createTask.step1.taskType.custom.description', { ns: 'tasks' }),
    taskType: TaskTypes.CUSTOM
  }
];
