import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';

import { useLocations } from '@/logicLayers/infrastructure/redux';

import { BaseMenuItem } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import {
  checkAndSetActionIcon,
  CompaniesActionTypeEnum,
  locationActionsOptions,
  locationRootActionsOptions
} from '../../helpers';

interface CompaniesActionsMenuProps {
  anchorEl: null | HTMLElement;
  folderId?: string;
  onModalOpen: (action: CompaniesActionTypeEnum) => void;
  onClose: () => void;
}

export const CompaniesActionsMenu = ({
  anchorEl = null,
  folderId,
  onModalOpen,
  onClose
}: CompaniesActionsMenuProps) => {
  const { currentLocationFolderData } = useLocations();
  const navigateTo = useNavigate();

  const open = !!anchorEl;
  const isRoot = folderId === 'root';
  const allowCreateInRoot = isRoot || currentLocationFolderData.canCreateFolder;

  const locationOptions = isRoot ? locationRootActionsOptions : locationActionsOptions;

  return (
    <Menu
      id={'edit-location-menu'}
      elevation={0}
      anchorEl={anchorEl}
      keepMounted
      MenuListProps={{ 'aria-labelledby': 'location-menu-button' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={{ margin: '12px 0 0 0' }}
      autoFocus={false}
      open={open}
      onClose={onClose}
    >
      {locationOptions().map((option, index, originArr) => {
        const isCreateLocationOption = option.value === CompaniesActionTypeEnum.CreateFolder;
        const disableCreateLocationOption = isCreateLocationOption && !allowCreateInRoot;
        const isOptionDisabled = (isCreateLocationOption && disableCreateLocationOption) || option.disabled;

        return (
          <BaseMenuItem
            key={index}
            disabled={isOptionDisabled}
            hasDivider={(index === 2 && index !== originArr.length - 1) || (index === originArr.length - 3 && !isRoot)}
            onClick={
              option.value === CompaniesActionTypeEnum.CreateCompany
                ? () => navigateTo(LINKS_PAGES.equipmentItemCreate.replace(':locationId', folderId || 'root'))
                : () => onModalOpen(option.value)
            }
          >
            {checkAndSetActionIcon(option.value)}
            {option.label}
          </BaseMenuItem>
        );
      })}
    </Menu>
  );
};
