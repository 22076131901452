import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { LogoIcon } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

export const LeftPart = () => {
  return (
    <Grid className={'header__left'} lg={5} item>
      <Link className={'d-inline-block'} to={LINKS_PAGES.home}>
        <LogoIcon sx={{ color: 'var(--white)', width: '50px', height: '40px' }} />
      </Link>
    </Grid>
  );
};
