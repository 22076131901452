import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function AlertErrorNoticeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'20'} height={'20'} viewBox={'0 0 20 20'} {...props}>
      <path
        d="M9.9999 13.9423C10.1906 13.9423 10.3505 13.8778 10.4795 13.7488C10.6085 13.6198 10.673 13.4599 10.673 13.2692C10.673 13.0785 10.6085 12.9187 10.4795 12.7897C10.3505 12.6607 10.1906 12.5962 9.9999 12.5962C9.80919 12.5962 9.64934 12.6607 9.52034 12.7897C9.39134 12.9187 9.32684 13.0785 9.32684 13.2692C9.32684 13.4599 9.39134 13.6198 9.52034 13.7488C9.64934 13.8778 9.80919 13.9423 9.9999 13.9423ZM10.0002 10.8975C10.1773 10.8975 10.3257 10.8376 10.4454 10.7178C10.5651 10.598 10.6249 10.4496 10.6249 10.2725V6.52244C10.6249 6.34537 10.565 6.19694 10.4451 6.07715C10.3253 5.95735 10.1768 5.89746 9.99963 5.89746C9.82246 5.89746 9.67405 5.95735 9.5544 6.07715C9.43475 6.19694 9.37492 6.34537 9.37492 6.52244V10.2725C9.37492 10.4496 9.43484 10.598 9.55467 10.7178C9.67452 10.8376 9.82302 10.8975 10.0002 10.8975ZM10.0013 17.9167C8.90635 17.9167 7.87715 17.7089 6.91371 17.2933C5.95025 16.8778 5.11218 16.3138 4.3995 15.6015C3.68681 14.8891 3.12259 14.0514 2.70686 13.0884C2.29112 12.1253 2.08325 11.0964 2.08325 10.0014C2.08325 8.90647 2.29103 7.87728 2.70659 6.91383C3.12214 5.95037 3.6861 5.11231 4.39846 4.39963C5.11084 3.68693 5.94854 3.12272 6.91156 2.70698C7.87458 2.29124 8.90356 2.08337 9.9985 2.08337C11.0934 2.08337 12.1226 2.29115 13.0861 2.70671C14.0495 3.12226 14.8876 3.68622 15.6003 4.39858C16.313 5.11096 16.8772 5.94866 17.2929 6.91169C17.7087 7.8747 17.9165 8.90368 17.9165 9.99863C17.9165 11.0936 17.7088 12.1228 17.2932 13.0862C16.8777 14.0497 16.3137 14.8877 15.6013 15.6004C14.889 16.3131 14.0513 16.8773 13.0882 17.2931C12.1252 17.7088 11.0962 17.9167 10.0013 17.9167Z"
        fill="#BE0E2C"
      />
    </SvgIcon>
  );
}
