import classNames from 'classnames';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import { MuiTablePagination, NotFoundScreens, SkeletonTableLoader } from '@/separatedModules/components';

import { useContextState, useContextUpdateState } from '../Providers';
import { TableBodyWrap } from '../TableBody';
import { TableHeader } from '../TableHeader';

import { CONFIG_TABLE, skeletonTableLoaderCells } from '../../helpers';

export const TableRoles = () => {
  const state = useContextState();
  const { handleChangePagination, handleChangeRowsPerPage } = useContextUpdateState();

  const getHiddenColumnsClasses = () => {
    const sx: any = {};

    state.filters.hiddenColumns.forEach((i) => {
      sx[`.MuiTableCell-${i}`] = {
        display: 'none'
      };
    });

    return sx;
  };

  const visibleColumns = CONFIG_TABLE.cells.length - (Object.keys(getHiddenColumnsClasses()).length || 0);

  return (
    <>
      {state.loadingRoleTemplates ? (
        <Box sx={{ overflow: 'auto' }}>
          <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
            <Table
              className={classNames('mt-0', {
                [`MuiTable-root__columns-${visibleColumns}`]: visibleColumns
              })}
              sx={{
                ...getHiddenColumnsClasses()
              }}
            >
              <TableHeader />

              <SkeletonTableLoader cells={skeletonTableLoaderCells} />
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <>
          {state.resultTotalCount ? (
            <Box sx={{ overflow: 'auto' }}>
              <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                <Table
                  className={classNames('mt-0', {
                    [`MuiTable-root__columns-${visibleColumns}`]: visibleColumns
                  })}
                  sx={{
                    ...getHiddenColumnsClasses()
                  }}
                >
                  <TableHeader />

                  <TableBodyWrap />
                </Table>

                {state.isVisiblePagination ? (
                  <MuiTablePagination
                    count={state.resultTotalCount}
                    page={state.pagination.page[0]}
                    rowsPerPage={state.pagination.rowsPerPage[0]}
                    rowsPerPageOptions={state.pagination.rowsPerPageOptions}
                    onChangePage={handleChangePagination}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                ) : null}
              </TableContainer>
            </Box>
          ) : state.filters.search[0] ? (
            <NotFoundScreens type={'search'} />
          ) : (
            <NotFoundScreens type={'companies'} title={'No users yet'} subTitle={'Add new users'} />
          )}
        </>
      )}
    </>
  );
};
