import { gql as gqlApollo } from '@apollo/client';

export const CREATE_TASK = gqlApollo(`
  mutation CreateTask($request: CreateOrEditTaskReqInput!) {
    createTask(request: $request) {
      id
      name
    }
  }
`);

export const CREATE_UNPLANNED_TASK = gqlApollo(`
  mutation CreateUnplannedTask($createRequest: CreateScheduledRoundReqInput!) {
    createScheduledRound(createRequest: $createRequest)
  }
`);

const LOCATION_ITEM_FRAGMENT = gqlApollo(`
  fragment locationFolderRes on LocationFolderRes {
    id
    name
    active
    parentsLocationFolders {
      depth
      id
      name
    }
  }
`);

export const SEARCH_ROUND_TASKS = gqlApollo(`
  query GroupedRounds($searchCriteria: SearchCriteriaInput, $statuses: [RoundStatus]) {
    dataItems: groupedRounds(searchCriteria: $searchCriteria, statuses: $statuses) {
      items {
        ids
        name
      }
      total
    }
  }
`);

export const GET_TASKS = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query RoundTasks(
    $searchCriteria: SearchCriteriaInput, 
    $type: RoundType!, 
    $statuses: [RoundStatus],
    $roundRegulationIds: [String]
  ) {
    dataItems: rounds(
      searchCriteria: $searchCriteria, 
      type: $type, 
      statuses: $statuses,
      roundRegulationIds: $roundRegulationIds
    ) {
      items {
        name
        id
        description
        serviceStaff {
          userAvatar {
            url
          }
          firstName
          lastName
        }
        parameters {
          parameterDetails {
            title
            type
          }
        }  
        createdDate
        equipment {
          fileUrl {
            fileKey
            url
          }
          name 
          location {
            ...locationFolderRes
            groupItems: locationFolders(searchCriteria: $searchCriteria) {
              items {
                ...locationFolderRes
                groupItems: locationFolders(searchCriteria: $searchCriteria) {
                  items {
                    ...locationFolderRes
                    groupItems: locationFolders(searchCriteria: $searchCriteria) {
                      items {
                        ...locationFolderRes
                        groupItems: locationFolders(searchCriteria: $searchCriteria) {
                          items {
                            ...locationFolderRes
                            groupItems: locationFolders(searchCriteria: $searchCriteria) {
                              items {
                                ...locationFolderRes
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } 
        }
        status
      }
      total
    }
  }
`;

export const GET_CUSTOM_TASKS = gqlApollo`
  query CustomTasks($searchCriteria: SearchCriteriaInput, $statuses: [TaskStatus]) {
    tasks(searchCriteria: $searchCriteria, statuses: $statuses) {
      items {
        id
        name
        description
        status
        startDate
        endDate
        performer {
          id
          firstName
          lastName
          userAvatar {
            url
          }
        }
      }
      total
    }
  }
`;

export const GET_CUSTOM_TASK = gqlApollo`
  query Task($taskId: String!) {
    task(taskId: $taskId) {
      id
      name
      description
      status
      startDate
      endDate
      performer {
        id
        firstName
        lastName
        userAvatar {
          url
        }
      }
    }
  }
`;
