import { styled } from '@mui/material/styles';

import { HEADER_HEIGHT } from '@/separatedModules/layouts';

export const StyledLayoutBase = styled('main')(() => ({
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  overflowY: 'auto',
  borderTop: '1px solid rgba(41, 49, 62, 0.15)',
  '.layout-base': {
    '&__container': {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '1920px',
      margin: '0 auto',
      width: '100%'
    },
    '&__left': {},
    '&__right': {
      flex: '0 1 100%',
      padding: '0 20px',
      '& > .MuiGrid-root': {
        flex: '0 1 100%',
        maxWidth: '1310px',
        margin: '0 auto',
        paddingTop: '40px !important',
        paddingBottom: '40px !important'
      }
    }
  }
}));

StyledLayoutBase.defaultProps = {
  className: 'layout-base'
};
