import { Text } from 'infinitytechnologies-ui';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { useLocations } from '@/logicLayers/infrastructure/redux';

import { ChevronLeftIcon, ChevronRightIcon } from '@/separatedModules/components';

export const LocationsProgressBar = () => {
  const { progressList, removeLocationFromProgressList } = useLocations();

  return (
    <Stack direction={'row'} spacing={'4px'} alignItems={'center'} overflow={'auto'}>
      <IconButton
        sx={{ width: '32px', height: '32px' }}
        onClick={() => removeLocationFromProgressList(progressList[progressList.length - 1])}
      >
        <ChevronLeftIcon />
      </IconButton>

      {progressList.map((item, index, originArr) => (
        <Stack key={index} direction={'row'} spacing={'4px'} alignItems={'center'}>
          <Text variant={index === 0 ? 'bodyMd' : 'labelMd'} m={0} sx={{ whiteSpace: 'nowrap' }}>
            {item.locationName}
          </Text>

          <ChevronRightIcon
            sx={{ fontSize: '16px', visibility: originArr.length - 1 === index ? 'hidden' : 'visible' }}
          />
        </Stack>
      ))}
    </Stack>
  );
};
