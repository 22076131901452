import classNames from 'classnames';
import { TextField, TextFieldProps } from 'infinitytechnologies-ui';

import { SxProps, Theme } from '@mui/material/styles';

import { TextBlockControl, TextBlockControlProps } from './../TextBlockControl';

import { StyledTextFieldControl } from './style';

type TextFieldControlProps = TextFieldProps &
  TextBlockControlProps & {
    sxSelect?: SxProps<Theme>;
    sxInputBox?: SxProps<Theme>;
    ContentAfterTitle?: React.ReactNode;
  };

export const TextFieldControl = ({
  title = '',
  subTitle,
  sxTitle,
  sxSubTitle,
  sxSelect,
  sxInputBox,
  ContentAfterTitle,
  ...rest
}: TextFieldControlProps) => {
  const isHasTitles = !title && !subTitle;

  return (
    <StyledTextFieldControl
      className={classNames({
        'no-titles': isHasTitles
      })}
      sx={sxInputBox}
    >
      <TextBlockControl
        className={'control-text-field__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
        ContentAfterTitle={ContentAfterTitle}
      />
      <TextField sx={sxSelect} {...rest} />
    </StyledTextFieldControl>
  );
};
