export const Project = {
  root: {
    padding: '8px 12px',
    color: 'var(--text-default, #090E16)',
    fontSize: '14px',
    lineHeight: '20px',
    transition: 'all 0.2s ease-in-out',
    flex: '0 1 100%',
    '.MuiSvgIcon-root': {
      transition: 'all 0.2s ease-in-out',
      fontSize: '20px',
      margin: '0 8px 0 0',
      color: '#63697C'
    },
    '&:hover': {
      background: 'var(--background-neutral-hovered, #F7F8F9)',
      '.MuiSvgIcon-root': {
        color: '#313849'
      }
    },
    '&.Mui-selected': {
      background: 'var(--background-selected-hovered, #E9F2FF)'
    },
    '&.MuiMenuItem-root-as-link': {
      padding: '0',
      '& > a': {
        padding: '8px 12px'
      }
    }
  }
};

export const Base = {
  root: {}
};
