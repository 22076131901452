import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PreviewIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: '21px !important', ...sx }}
      {...restProps}
    >
      <path d="M4.42292 17.0836C4.00866 17.0836 3.65402 16.9361 3.35902 16.6411C3.06401 16.3461 2.9165 15.9915 2.9165 15.5772V4.42341C2.9165 4.00915 3.06401 3.65451 3.35902 3.35951C3.65402 3.0645 4.00866 2.91699 4.42292 2.91699H15.5767C15.991 2.91699 16.3456 3.0645 16.6406 3.35951C16.9356 3.65451 17.0831 4.00915 17.0831 4.42341V15.5772C17.0831 15.9915 16.9356 16.3461 16.6406 16.6411C16.3456 16.9361 15.991 17.0836 15.5767 17.0836H4.42292ZM4.42292 15.8336H15.5767C15.6515 15.8336 15.7129 15.8096 15.761 15.7615C15.8091 15.7134 15.8332 15.652 15.8332 15.5772V5.83364H4.16648V15.5772C4.16648 15.652 4.19052 15.7134 4.23861 15.7615C4.28669 15.8096 4.34813 15.8336 4.42292 15.8336ZM9.99982 13.7503C8.97846 13.7503 8.06259 13.48 7.25223 12.9394C6.44189 12.3988 5.84334 11.6969 5.45659 10.8336C5.84334 9.9704 6.44189 9.26849 7.25223 8.72789C8.06259 8.18729 8.97846 7.91699 9.99982 7.91699C11.0212 7.91699 11.937 8.18729 12.7474 8.72789C13.5577 9.26849 14.1563 9.9704 14.543 10.8336C14.1563 11.6969 13.5577 12.3988 12.7474 12.9394C11.937 13.48 11.0212 13.7503 9.99982 13.7503ZM9.99982 12.7567C10.7188 12.7567 11.3804 12.5874 11.9846 12.2487C12.5888 11.91 13.0794 11.4383 13.4565 10.8336C13.0794 10.2289 12.5888 9.75726 11.9846 9.41858C11.3804 9.07991 10.7188 8.91058 9.99982 8.91058C9.2808 8.91058 8.61921 9.07991 8.01505 9.41858C7.41088 9.75726 6.92023 10.2289 6.54311 10.8336C6.92023 11.4383 7.41088 11.91 8.01505 12.2487C8.61921 12.5874 9.2808 12.7567 9.99982 12.7567ZM10.0017 11.9234C10.3049 11.9234 10.562 11.8172 10.773 11.605C10.984 11.3927 11.0895 11.135 11.0895 10.8317C11.0895 10.5285 10.9834 10.2714 10.7712 10.0604C10.5589 9.84941 10.3012 9.74391 9.99792 9.74391C9.6947 9.74391 9.43759 9.85004 9.22659 10.0623C9.01559 10.2746 8.91009 10.5323 8.91009 10.8355C8.91009 11.1388 9.01622 11.3959 9.22848 11.6069C9.44073 11.8179 9.69848 11.9234 10.0017 11.9234Z" />
    </SvgIcon>
  );
}
