import { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { GET_COMPANIES, GET_REGIONS_SIDEBAR, MOVE_COMPANY_TO_REGION } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { CreateNewFolderIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from '../Providers';

import { AddRegionModal } from './components';
import { FoldersTabs, LocationsProgressBar, TabsContent } from './components';

import { DISABLED_FOR_FOLDER_CREATION, MAX_MOVING_LEVEL, TAB_LIST } from './helpers';

import { StyledDialog } from './style';

export const MoveCompanyToRegionModal = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  const { isOpenMoveModal, selectedItems, setState, ...state } = useContextState();

  const isSingleItemSelected = selectedItems.length === 1;
  const selectedItem = isSingleItemSelected ? state.bodyDataRows.find((item) => item.id === selectedItems[0]) : null;
  const itemName = selectedItem ? selectedItem.name : '';

  const { isStartMoving, cancelMoving, progressList, destinationData } = useLocations();

  const [isOpenCreateFolderModal, setIsOpenCreateFolderModal] = useState(false);
  const [visibleTabIndex, setVisibleTabIndex] = useState(0);

  const [MoveCompanyToRegion] = useMutation(MOVE_COMPANY_TO_REGION, {
    variables: {
      request: {
        companyIds: selectedItems,
        regionId: destinationData.destinationLocationId ?? undefined
      }
    },
    refetchQueries: [GET_COMPANIES, GET_REGIONS_SIDEBAR]
  });

  const handleCloseModal = useCallback(() => {
    setState((state) => ({ ...state, isOpenMoveModal: false }));
    cancelMoving();
  }, []);

  const handleMoveCompanyToRegion = () => {
    MoveCompanyToRegion({
      onCompleted: () => {
        AlertService.showAlert({
          title: isSingleItemSelected ? (
            <Trans
              i18nKey={tGlobal('alertMessages.success.movedSuccessfully')}
              values={{
                sourceLocationName: itemName,
                destinationLocationName: destinationData.destinationLocationName
              }}
              components={{
                p: <p style={{ fontSize: '14px', fontWeight: 400, color: '#505668' }} />,
                span: <span style={{ fontWeight: 600 }} />
              }}
            />
          ) : (
            tGlobal('alertMessages.success.movedSuccessfullyMoreThanOne', {
              destinationLocationName: destinationData.destinationLocationName
            })
          ),
          severity: 'success'
        });
        handleCloseModal();
        setState((state) => ({ ...state, selectedItems: [] }));
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const handleCreateNewFolder = () => {
    setIsOpenCreateFolderModal(true);
  };

  const handleCloseCreateNewFolder = () => {
    setIsOpenCreateFolderModal(false);
  };

  const handleChangeTab = (tabIndex: number) => {
    setVisibleTabIndex(tabIndex);
  };

  useEffect(() => {
    return () => {
      cancelMoving();
    };
  }, []);

  const newFolderId = progressList.length > 0 ? progressList[progressList.length - 1].id : 'root';
  const isDisabledForFolderCreation =
    DISABLED_FOR_FOLDER_CREATION.some((disabledTabItem) => disabledTabItem.id === visibleTabIndex) ||
    progressList.length >= MAX_MOVING_LEVEL;

  return (
    <>
      <StyledDialog
        headerTitle={tLocations('actions.modal.move.title', {
          name: itemName
        })}
        headerSubTitle={tLocations('actions.modal.move.subTitle')}
        hideHeaderBorder
        contentFooter={
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              disabled={isDisabledForFolderCreation}
              variant={'text'}
              startIcon={
                <CreateNewFolderIcon
                  sx={{ fill: isDisabledForFolderCreation ? 'var(--text-disabled,rgba(9,14,22,0.30))' : '#313849' }}
                />
              }
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCreateNewFolder}
            >
              {tGlobal('table.modal.move.btnNewFolder')}
            </Button>

            <Box display={'flex'}>
              <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
                {tGlobal('table.modal.move.btnCancel')}
              </Button>

              <Button
                variant={'contained'}
                sx={{ margin: '0 24px 0 0 !important' }}
                onClick={handleMoveCompanyToRegion}
              >
                {tGlobal('table.modal.move.btnSave')}
              </Button>
            </Box>
          </Box>
        }
        open={isOpenMoveModal}
        onClose={handleCloseModal}
        disableEnforceFocus
      >
        {isStartMoving ? (
          <LocationsProgressBar />
        ) : (
          <FoldersTabs tabs={TAB_LIST} onChange={(tabIndex) => handleChangeTab(tabIndex)} />
        )}

        <TabsContent tabIndex={visibleTabIndex} />
      </StyledDialog>

      {isOpenCreateFolderModal ? (
        <AddRegionModal
          isModalOpen
          modalSize={'big'}
          folderId={newFolderId}
          onCloseModal={handleCloseCreateNewFolder}
        />
      ) : null}
    </>
  );
};
