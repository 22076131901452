import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n/config';

import { Parameter, ParameterTypeTyped } from '../../../helpers';

export const PARAMETER_LIST: Parameter[] = [
  {
    id: 0,
    title: i18n.t('createParameterItem.parameter.type.numeric.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.numeric.description', { ns: 'templates' }),
    logoPath: LINKS_IMAGES.parameterTypeNumeric,
    parameterType: ParameterTypeTyped.NUMERIC_INPUT
  },
  {
    id: 1,
    title: i18n.t('createParameterItem.parameter.type.boolean.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.boolean.description', { ns: 'templates' }),
    logoPath: LINKS_IMAGES.parameterTypeBoolean,
    parameterType: ParameterTypeTyped.BOOLEAN
  },
  {
    id: 2,
    title: i18n.t('createParameterItem.parameter.type.string.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.string.description', { ns: 'templates' }),
    logoPath: LINKS_IMAGES.parameterTypeString,
    parameterType: ParameterTypeTyped.STRING
  },
  {
    id: 3,
    title: i18n.t('createParameterItem.parameter.type.dateTime.title', { ns: 'templates' }),
    description: i18n.t('createParameterItem.parameter.type.dateTime.description', { ns: 'templates' }),
    logoPath: LINKS_IMAGES.parameterTypeDateTime,
    parameterType: ParameterTypeTyped.DATA_TIME,
    disabled: true
  }
];
