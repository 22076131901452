import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface GlobalStateI {
  theme: string;
  i18n: string;
  menuHeaderIsOpen: boolean;
}

const initialState: GlobalStateI = { theme: 'light', i18n: 'en', menuHeaderIsOpen: false };

export const menuHeaderIsOpenAction = createAction<boolean>('GLOBAL/TOGGLE_HEADER_MENU');
export const changeThemeAction = createAction<string>('GLOBAL/CHANGE_THEME');
export const changeLocaleAction = createAction<string>('GLOBAL/CHANGE_LOCALE');

export const GlobalReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(menuHeaderIsOpenAction, (state: GlobalStateI) => {
      state.menuHeaderIsOpen = !state.menuHeaderIsOpen;
    })
    .addCase(changeThemeAction, (state: GlobalStateI, action: PayloadAction<string>) => {
      state.theme = action.payload === 'dark' ? 'light' : 'dark';
    })
    .addCase(changeLocaleAction, (state: GlobalStateI, action: PayloadAction<string>) => {
      state.i18n = action.payload;
    });
});
