export const Project = {
  root: {
    background: 'var(--Base-tokens-Blue-Blue200, #CCE0FF)',
    border: '1px solid var(--Base-tokens-Blue-Blue200, #CCE0FF)',
    color: 'var(--Base-tokens-Blue-Blue900, #09326C)',
    '&:hover': {
      border: '1px solid var(--Base-tokens-Blue-Blue900, #09326C)',
      color: 'var(--Base-tokens-Blue-Blue900, #09326C)'
    }
  }
};

export const Base = {
  root: {
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid var(--button-disabled)',
    background: 'var(--button-disabled)',
    color: 'var(--primary-900)',
    fontWeight: '600',
    lineHeight: '1',
    textTransform: 'uppercase',
    '&:hover': {
      border: '1px solid var(--primary-800)',
      color: 'var(--primary-800)'
    },
    '&.MuiAvatar--is-profile': {
      position: 'relative',
      border: 'none !important',
      '.MuiAvatar__edit-profile': {
        background: 'rgba(0, 0, 0, 0.8)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0.1s linear, opacity 0.3s linear'
      },
      '&:hover': {
        '.MuiAvatar__edit-profile': {
          visibility: 'visible',
          opacity: '1'
        }
      }
    },
    '&.MuiAvatar--size-16': {
      fontSize: '8px',
      '.MuiSvgIcon-root': {
        display: 'none'
      }
    },
    '&.MuiAvatar--size-32': {
      fontSize: '12px',
      '.MuiSvgIcon-root': {
        fontSize: '16px'
      }
    },
    '&.MuiAvatar--size-48': {
      fontSize: '18px',
      '.MuiSvgIcon-root': {
        fontSize: '22px'
      }
    },
    '&.MuiAvatar--size-64': {
      fontSize: '24px',
      '.MuiSvgIcon-root': {
        fontSize: '28px'
      }
    },
    '&.MuiAvatar--size-80': {
      fontSize: '32px',
      '.MuiSvgIcon-root': {
        fontSize: '32px'
      }
    },
    '&.MuiAvatar--size-96': {
      fontSize: '40px',
      '.MuiSvgIcon-root': {
        fontSize: '32px'
      }
    },
    '&.MuiAvatar--size-136': {
      fontSize: '56px',
      '.MuiSvgIcon-root': {
        fontSize: '32px'
      }
    }
  }
};
