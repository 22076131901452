import { FC } from 'react';

import { TableBody as MuiTableBody } from '@mui/material';

import { DomainStatusChip, EMPTY_CELL_VALUE } from '@/separatedModules/components';

import { DEFAULT_HIDDEN_KEY_COLUMN, TableRow } from './table.config';

import { StyledTableBodyRow, StyledTableCell } from './style';

interface TableBodyProps {
  rows: TableRow[];
  hiddenColumns: string[];
  onRowClick: (id: string, type: number) => void;
}

export const TableBody: FC<TableBodyProps> = ({ rows, hiddenColumns, onRowClick }) => {
  return (
    <MuiTableBody>
      {rows?.map((row, key) => (
        <StyledTableBodyRow
          key={key}
          selected={row?.selected}
          onClick={(event: { detail: number }) => {
            if ((event.detail === 1 || event.detail === 2) && row.id) {
              onRowClick(row.id, event.detail);
            }
          }}
        >
          {Object.entries(row)?.map(([key, value]) => {
            const isDomainCell = key === 'domain';
            const domainValue = isDomainCell && value.length ? value[0] : false;

            return (
              <StyledTableCell
                key={key}
                align={'left'}
                // ToDo Refactor
                hide={Boolean(key === DEFAULT_HIDDEN_KEY_COLUMN || key === 'selected' || hiddenColumns.includes(key))}
              >
                {isDomainCell ? (
                  domainValue ? (
                    <DomainStatusChip color={domainValue.color} label={domainValue.name} />
                  ) : (
                    EMPTY_CELL_VALUE
                  )
                ) : value ? (
                  value
                ) : (
                  EMPTY_CELL_VALUE
                )}
              </StyledTableCell>
            );
          })}
        </StyledTableBodyRow>
      ))}
    </MuiTableBody>
  );
};
