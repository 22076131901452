export const Project = {
  root: {
    borderRadius: 'var(--4, 4px)',
    padding: '12px 20px 20px',
    margin: '0 0 20px',
    color: 'var(--text-subtle, #505668)',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    '& + .MuiAlert-root': {}
  },
  icon: {
    opacity: '1',
    padding: '8px 12px 0 0',
    margin: '0'
  },
  message: {
    padding: '0'
  },
  action: {
    padding: '0',
    margin: '0 0 0 auto',
    alignSelf: 'flex-start',
    cursor: 'pointer',
    button: {
      margin: '-2px 0 0 12px'
    }
  },
  standardInfo: {
    background: 'var(--background-selected-default, #F2F7FF)'
  },
  standardError: {
    background: 'var(--background-danger-default, #FFF5F7)'
  },
  standardSuccess: {
    background: 'var(--background-success-default, #FFFAE7)'
  },
  standardWarning: {
    background: 'var(--background-warning-default, #E6F6EF)'
  },
  /* For Alert Service */
  notice: {
    background: 'var(--white)',
    minWidth: '350px',
    maxWidth: '400px',
    padding: '12px',
    borderRadius: 'var(--4, 4px)',
    boxShadow: '0 8px 12px 0 rgba(9, 30, 66, 0.15), 0 0 1px 0 rgba(9, 30, 66, 0.31)',
    margin: '0',
    '.MuiAlert-content': {
      margin: '8px 0 4px'
    }
  }
};

export const Base = {
  root: {}
};
