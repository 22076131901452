import { useMemo } from 'react';

import { SelectsDropdown } from '@/separatedModules/components';

import { i18n, useTranslation } from '@/i18n';

import { useContextUpdateState } from '../../../Providers';

const createSortingBy = () => {
  return [
    {
      checked: false,
      label: i18n.t('table.filters.sortByDate.descending', { ns: 'global' }),
      key: i18n.t('table.filters.sortByDate.descending', { ns: 'global' }),
      value: 'DESC'
    },
    {
      checked: false,
      label: i18n.t('table.filters.sortByDate.ascending', { ns: 'global' }),
      key: i18n.t('table.filters.sortByDate.ascending', { ns: 'global' }),
      value: 'ASC'
    }
  ];
};

export const SortByDate = () => {
  const { t: tGlobal } = useTranslation('global');

  const { handleChangeSortByDate } = useContextUpdateState();

  const menuList = useMemo(() => createSortingBy(), []);

  return (
    <SelectsDropdown
      id={'domains-sort-by-date'}
      title={tGlobal('table.filters.sortByDate.title')}
      menuList={menuList}
      onChange={handleChangeSortByDate}
    />
  );
};
