import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Text } from 'infinitytechnologies-ui';
import { omit } from 'lodash';
import groupBy from 'lodash/groupBy';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  CreateOrEditRoleTemplateReqInput,
  EDIT_ROLE_TEMPLATE,
  GET_ROLE_TEMPLATE_BY_ID,
  GET_USER_DETAILED_PERMISSIONS,
  GetRoleTemplateQuery,
  SEARCH_DOMAINS,
  SearchDomainsQuery,
  updateRequestDataModel
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { PermissionItem } from '@/separatedModules/pages/Templates/pages/Role/pages/CreateRole/components';
import { MenuItemProps } from '@/separatedModules/pages/Templates/pages/Role/pages/CreateRole/components/helpers';

import {
  AutocompleteOptionT,
  AutocompleteTagsControl,
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  EditPageWrap,
  PageTitle,
  Preloader,
  PreloaderVariantsE,
  TextFieldControl
} from '@/separatedModules/components';

import { isSuperAdmin, ROLE_DESCRIPTION_REG_EXP, ROLE_NAME_REG_EXP, useIsUserCompanyAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import {
  createMockedMenuList,
  FIELD_DESCRIPTION,
  FIELD_DOMAIN_IDS,
  FIELD_PERMISSIONS,
  FIELD_ROLE,
  GroupOfPermissions
} from './helpers';

interface FormProps {
  [FIELD_ROLE]: string;
  [FIELD_DESCRIPTION]: string;
  [FIELD_PERMISSIONS]: string[];
  [FIELD_DOMAIN_IDS]: string[];
}

export const TemplatesRolesRoleEditPage: FC = () => {
  const { templateId = '' } = useParams();
  const navigateTo = useNavigate();
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const isUserCompanyAdmin = useIsUserCompanyAdmin();

  const { data: domainsData, loading: domainsDataLoading } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });
  const { data: detailedPermissionsData, loading: detailedPermissionsLoading } = useQuery(
    GET_USER_DETAILED_PERMISSIONS,
    { fetchPolicy: 'no-cache' }
  );
  const { data: roleTemplateData, loading: roleTemplateDataLoading } = useQuery<GetRoleTemplateQuery>(
    GET_ROLE_TEMPLATE_BY_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: templateId
      }
    }
  );
  const [EditRole, { error: editRoleError }] = useMutation(EDIT_ROLE_TEMPLATE);

  const roleTemplate = roleTemplateData?.roleTemplate;
  const roleDomains = roleTemplate?.domains;
  const domains = domainsData?.dataItems.items;
  const rolePermissions = roleTemplate?.permissions;
  const shouldShowDomains = !isSuperAdmin();

  const {
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_ROLE]: '',
      [FIELD_DESCRIPTION]: '',
      [FIELD_PERMISSIONS]: [],
      [FIELD_DOMAIN_IDS]: []
    }
  });

  const formPermissions = watch(FIELD_PERMISSIONS);
  const formDomainIds = watch(FIELD_DOMAIN_IDS);

  useEffect(() => {
    if (roleTemplate?.name) {
      setValue(FIELD_ROLE, roleTemplate?.name);
    }

    if (roleTemplate?.description) {
      setValue(FIELD_DESCRIPTION, roleTemplate?.description);
    }

    if (editRoleError) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [roleTemplate?.name, editRoleError]);

  useEffect(() => {
    if (rolePermissions?.length) {
      setValue(
        FIELD_PERMISSIONS,
        rolePermissions?.map((item) => item?.name ?? '')
      );
    }

    if (roleDomains?.length) {
      setValue(
        FIELD_DOMAIN_IDS,
        roleDomains?.map((item) => item?.id ?? '')
      );
    }
  }, [rolePermissions, roleDomains]);

  const handleSubmitForm = handleSubmit((data) => {
    EditRole({
      variables: {
        id: templateId,
        ...updateRequestDataModel<GetRoleTemplateQuery, CreateOrEditRoleTemplateReqInput>(
          roleTemplateData as GetRoleTemplateQuery,
          {
            name: data.name,
            description: data.description,
            permissions: Array.from(new Set([...data.permissions, ...formPermissions])),
            domainIds: Array.from(new Set([...data.domainIds, ...formDomainIds]))
          }
        )
      }
    }).then(() => {
      AlertService.showAlert({
        title: tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });
      navigateTo(LINKS_PAGES.templatesRoles);
    });
  });

  const handlePermissionClick = (item: MenuItemProps, operationName: string) => {
    const filteredFormPermissions = formPermissions.filter((permission) => !permission.includes(operationName));
    setValue(FIELD_PERMISSIONS, [...filteredFormPermissions, ...item.value]);
  };

  const handleDomainsChange = (items: string[]) => {
    if (!domains?.length) return;

    const domainIds = domains.filter((domain) => items.includes(domain?.name as string))?.map((domain) => domain?.id);

    setValue(FIELD_DOMAIN_IDS, domainIds as string[]);
  };

  const groupedPermissions = useMemo(() => {
    const grouped = groupBy(detailedPermissionsData?.permissions, 'resource');

    return isUserCompanyAdmin ? omit(grouped, 'COMPANIES') : grouped;
  }, [detailedPermissionsData?.permission, detailedPermissionsLoading]);

  const groupedInitialPermissions = useMemo(() => {
    if (rolePermissions?.length) {
      return groupBy(rolePermissions, 'resource');
    }

    return [];
  }, [rolePermissions, roleTemplateDataLoading]);

  const domainsOptions = useMemo(() => {
    if (!domains) {
      return [];
    }

    return domains?.map((domain) => ({
      label: domain?.name,
      value: domain?.id,
      key: domain?.id
    }));
  }, [domains]);

  return (
    <form onSubmit={handleSubmitForm}>
      <Grid justifyContent={'center'} container row>
        <Grid xs={12} sm={10} md={8} lg={6} item>
          <EditPageWrap navigateTo={LINKS_PAGES.templatesRoles}>
            <PageTitle title={tTemplates('editRole.pageTitle')} sxTitle={{ mt: '32px' }} />

            {!roleTemplateDataLoading ? (
              <>
                <Controller
                  name={FIELD_ROLE}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    pattern: {
                      value: ROLE_NAME_REG_EXP,
                      message: tGlobal('validation.textFieldLength')
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextFieldControl
                      label={tTemplates('createRole.mainInfo.roleInputLabel')}
                      name={FIELD_ROLE}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_ROLE]?.message,
                        error: errors[FIELD_ROLE]?.message
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />

                <Text variant={'titleMd'} sx={{ mt: '44px' }}>
                  {tTemplates('createRole.mainInfo.descriptionLabel')}
                </Text>
                <Text variant={'bodyMd'} sx={{ color: '#505668', mt: 0 }}>
                  {tTemplates('createRole.mainInfo.descriptionDescription')}
                </Text>

                <Controller
                  name={FIELD_DESCRIPTION}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: tGlobal('validation.required')
                    },
                    pattern: {
                      value: ROLE_DESCRIPTION_REG_EXP,
                      message: tGlobal('validation.descriptionLength', {
                        value: 100
                      })
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextFieldControl
                      label={tTemplates('createRole.mainInfo.descriptionInputLabel')}
                      name={FIELD_DESCRIPTION}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_DESCRIPTION]?.message,
                        error: errors[FIELD_DESCRIPTION]?.message
                      }}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />

                <BlockInfoWithEdit
                  sx={{
                    border: 'none',
                    p: 0,
                    pt: '24px',
                    '&.Mui-disabled': {
                      border: 'none !important'
                    }
                  }}
                  disabled
                >
                  <BlockInfoWithEditItem
                    title={tTemplates('createRole.mainInfo.imageLabel')}
                    label={tTemplates('createRole.mainInfo.imageDescription')}
                    sxDescription={{ maxWidth: '520px' }}
                    Edit={<Avatar size={'64'} />}
                  />
                </BlockInfoWithEdit>

                <BlockInfoWithEdit sx={{ border: 'none', p: 0, my: '24px' }}>
                  <BlockInfoWithEditItem
                    title={tTemplates('createRole.mainInfo.managePermissionsLabel')}
                    label={tTemplates('createRole.mainInfo.managePermissionsDescription')}
                  />
                </BlockInfoWithEdit>

                <Stack spacing={'24px'}>
                  {Object.entries(groupedPermissions).map(([key, value]) => (
                    <PermissionItem
                      key={key}
                      title={tTemplates(`createRole.mainInfo.${key.toLowerCase()}Label`)}
                      description={tTemplates(`createRole.mainInfo.${key.toLowerCase()}Description`)}
                      menuList={createMockedMenuList(key, value, groupedInitialPermissions as GroupOfPermissions)}
                      onMenuItemClick={(item) => handlePermissionClick(item, key)}
                    />
                  ))}
                </Stack>

                {shouldShowDomains ? (
                  <>
                    <BlockInfoWithEdit sx={{ border: 'none', p: 0 }}>
                      <BlockInfoWithEditItem
                        title={tTemplates('createRole.mainInfo.equipmentDomainsLabel')}
                        label={tTemplates('createRole.mainInfo.equipmentDomainsDescription')}
                      />
                    </BlockInfoWithEdit>

                    {!domainsDataLoading ? (
                      <Box mb={'108px'}>
                        <AutocompleteTagsControl
                          defaultValue={roleDomains ? roleDomains.map((option) => option?.name ?? '') : undefined}
                          name={FIELD_DOMAIN_IDS}
                          label={tTemplates('createRole.mainInfo.selectDomainLabel')}
                          options={domainsOptions as AutocompleteOptionT[]}
                          freeSolo={undefined}
                          onChange={(_, value: any) => handleDomainsChange(value)}
                        />
                      </Box>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
            )}

            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'flex-end'}
              position={'sticky'}
              bottom={0}
              pt={'24px'}
              pb={'40px'}
              sx={{ background: 'white' }}
            >
              <Button variant={'contained'} type={'submit'} disabled={!!errors[FIELD_ROLE]?.message}>
                {tTemplates('createRole.mainInfo.btnSubmit')}
              </Button>
            </Box>
          </EditPageWrap>
        </Grid>
      </Grid>
    </form>
  );
};
