import { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTranslation } from '@/i18n';

const isSxNotFixed = {
  padding: '24px 0 40px',
  background: 'var(--background-neutral-default, #FFF)',
  bottom: '0',
  right: '0',
  left: '0',
  '.MuiGrid-root': {
    flex: '0 1 100%',
    maxWidth: '100%'
  }
};

const isSxFixed = {
  padding: '24px 0 40px',
  background: 'var(--background-neutral-default, #FFF)',
  bottom: '0',
  right: '0',
  left: '0',
  borderTop: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
  position: 'fixed',
  zIndex: 5
};

interface MultiStepFooterProps {
  currentStep: number;
  totalSteps: number;
  handleSetPrevStep: () => void;
}

export const MultiStepFooter = memo(({ currentStep, totalSteps, handleSetPrevStep }: MultiStepFooterProps) => {
  const { ref, inView } = useInView({
    initialInView: true,
    threshold: 0
  });

  const { t: tTemplates } = useTranslation('templates');

  const ButtonContainer = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: currentStep > 1 ? 'space-between' : 'flex-end' }}>
        {currentStep > 1 ? (
          <Button className={'my-0'} variant={'text'} onClick={handleSetPrevStep}>
            {tTemplates('createEquipmentItem.multiStep.btnPrevStep')}
          </Button>
        ) : null}

        {currentStep < totalSteps ? (
          <Button className={'my-0'} variant={'contained'} type={'submit'}>
            {tTemplates('createEquipmentItem.multiStep.btnNextStep')}
          </Button>
        ) : null}

        {currentStep === totalSteps ? (
          <Button className={'my-0'} variant={'contained'} type={'submit'}>
            {tTemplates('createEquipmentItem.multiStep.btnCreateTemplate')}
          </Button>
        ) : null}
      </Box>
    );
  };

  return (
    <Box ref={ref}>
      <Box sx={inView ? isSxNotFixed : isSxFixed}>
        {inView ? (
          <ButtonContainer />
        ) : (
          <Grid className={'MuiGrid-container-main'} justifyContent={'center'} container>
            <Grid justifyContent={'center'} container row>
              <Grid xs={12} md={10} lg={8} xl={6} item>
                <ButtonContainer />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
});
