import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

interface ParameterTypeBoxProps {
  title: string;
  isEdit?: boolean;
}

export const ParameterTypeBox = ({ title, isEdit = false }: ParameterTypeBoxProps) => {
  return (
    <Box
      sx={{
        height: '24px',
        borderRadius: '16px',
        background: 'var(--background-neutral-subtle-default, rgba(9, 14, 22, 0.03))',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px 10px',
        marginRight: !isEdit ? '8px' : '0px'
      }}
    >
      <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', margin: '0px' }}>
        {title}
      </Text>
    </Box>
  );
};
