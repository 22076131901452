import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function HomeIcon(props: SvgIconProps) {
  const { sx, fill = '#313849', ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: fill, fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M5.99997 18.9999H9.34615V13.9614C9.34615 13.7053 9.43277 13.4907 9.606 13.3174C9.77923 13.1442 9.9939 13.0576 10.25 13.0576H13.75C14.0061 13.0576 14.2207 13.1442 14.3939 13.3174C14.5672 13.4907 14.6538 13.7053 14.6538 13.9614V18.9999H18V10.1537C18 10.1025 17.9888 10.056 17.9663 10.0143C17.9439 9.97265 17.9134 9.93579 17.875 9.90374L12.1827 5.62489C12.1314 5.58002 12.0705 5.55759 12 5.55759C11.9295 5.55759 11.8686 5.58002 11.8173 5.62489L6.12497 9.90374C6.08652 9.93579 6.05607 9.97265 6.03362 10.0143C6.01119 10.056 5.99997 10.1025 5.99997 10.1537V18.9999ZM4.5 18.9999V10.1537C4.5 9.86752 4.56402 9.59638 4.69207 9.34029C4.82012 9.08421 4.99712 8.87332 5.22307 8.70762L10.9154 4.41917C11.2313 4.17813 11.5923 4.05762 11.9984 4.05762C12.4046 4.05762 12.7666 4.17813 13.0846 4.41917L18.7769 8.70762C19.0028 8.87332 19.1798 9.08421 19.3079 9.34029C19.4359 9.59638 19.5 9.86752 19.5 10.1537V18.9999C19.5 19.4089 19.3522 19.7611 19.0567 20.0566C18.7612 20.3521 18.4089 20.4999 18 20.4999H14.0577C13.8016 20.4999 13.5869 20.4132 13.4137 20.24C13.2404 20.0668 13.1538 19.8521 13.1538 19.596V14.5575H10.8461V19.596C10.8461 19.8521 10.7595 20.0668 10.5863 20.24C10.413 20.4132 10.1984 20.4999 9.94227 20.4999H5.99997C5.59101 20.4999 5.23877 20.3521 4.94327 20.0566C4.64776 19.7611 4.5 19.4089 4.5 18.9999Z" />
    </SvgIcon>
  );
}
