/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Scalar for BigDecimal */
  BigDecimal: { input: any; output: any };
  /** Scalar for BigInteger */
  BigInteger: { input: any; output: any };
  /** Scalar for DateTime */
  DateTime: { input: any; output: any };
};

export enum AccessType {
  CreateUpdate = 'CREATE_UPDATE',
  Delete = 'DELETE',
  Deny = 'DENY',
  Read = 'READ'
}

export type AccessTypeResponse = {
  __typename?: 'AccessTypeResponse';
  access: Array<Maybe<AccessType>>;
  numericValue: Scalars['Int']['output'];
};

export type Client = {
  __typename?: 'Client';
  clientId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secret: Scalars['String']['output'];
};

export enum Color {
  Danger = 'DANGER',
  Default = 'DEFAULT',
  Lime = 'LIME',
  Magenta = 'MAGENTA',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Selected = 'SELECTED',
  Success = 'SUCCESS',
  Teal = 'TEAL',
  Warning = 'WARNING'
}

export type Company = {
  __typename?: 'Company';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Maybe<PermissionDto>>;
};

export type CompanyContactInfoRes = {
  __typename?: 'CompanyContactInfoRes';
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  representative?: Maybe<Scalars['String']['output']>;
};

export type CompanyRes = {
  __typename?: 'CompanyRes';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Scalars['String']['output']>;
  contactInfo: CompanyContactInfoRes;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  domainIds: Array<Maybe<Scalars['String']['output']>>;
  domains?: Maybe<Array<Maybe<DomainDto>>>;
  id?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<UrlInfo>;
  name: Scalars['String']['output'];
  users: Page_UserRes;
};

export type CompanyResUsersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Condition = {
  __typename?: 'Condition';
  exactValue?: Maybe<Scalars['String']['output']>;
  maxValue?: Maybe<Scalars['String']['output']>;
  minValue?: Maybe<Scalars['String']['output']>;
  type: ConditionType;
};

export type ConditionInput = {
  exactValue?: InputMaybe<Scalars['String']['input']>;
  maxValue?: InputMaybe<Scalars['String']['input']>;
  minValue?: InputMaybe<Scalars['String']['input']>;
  type: ConditionType;
};

export enum ConditionType {
  DontMatch = 'DONT_MATCH',
  FitRange = 'FIT_RANGE',
  Match = 'MATCH',
  OutOfRange = 'OUT_OF_RANGE'
}

export type CreateCompanyReqInput = {
  domainIds: Array<InputMaybe<Scalars['String']['input']>>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEquipmentReqInput = {
  domainId: Scalars['String']['input'];
  fileKey?: InputMaybe<Scalars['String']['input']>;
  itemDescription?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  modelNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  specifications?: InputMaybe<Scalars['String']['input']>;
  templateDescription?: InputMaybe<Scalars['String']['input']>;
  type: EquipmentType;
};

export type CreateLocationFolderReqInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrEditRoleTemplateReqInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  permissions: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateOrUpdateRegionReqInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateParameterReqInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  equipmentId: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  propertyId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: ParameterType;
  unitId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePermissionReqInput = {
  access: Scalars['Int']['input'];
  resourceId: Scalars['String']['input'];
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateRoundRegulationRequestInput = {
  /** ISO-8601 */
  activationTargetDate?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  equipmentId: Scalars['String']['input'];
  frequency?: InputMaybe<RoundFrequencyInput>;
  name: Scalars['String']['input'];
  parameters: Array<InputMaybe<ParameterInput>>;
  serviceStaff: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateUserFolderReqInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserReqInput = {
  domainIds: Array<InputMaybe<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  roleTemplateId: Scalars['String']['input'];
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DomainDto = {
  __typename?: 'DomainDto';
  color: Color;
  createdBy: UserRes;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['String']['output']>;
  itemsCount: Scalars['BigInteger']['output'];
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export type EditEquipmentReqInput = {
  domainId: Scalars['String']['input'];
  fileKey?: InputMaybe<Scalars['String']['input']>;
  itemDescription?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  modelNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  specifications?: InputMaybe<Scalars['String']['input']>;
  templateDescription?: InputMaybe<Scalars['String']['input']>;
};

export type EditLocationFolderReqInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type EditParameterRequestInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  unitId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EditProfileReqInput = {
  fileKey?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type EditUserAccessReqInput = {
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type EditUserReqInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentParameterRes = {
  parameterDetails: any;
  __typename?: 'EquipmentParameterRes';
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  equipmentId: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  order: Scalars['Int']['output'];
  propertyId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: ParameterType;
  status: keyof typeof TaskStatus;
  frequency: {
    frequency: Scalars['String']['output'];
  };
  unitId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  activationTargetDate: Scalars['DateTime']['output'];
  serviceStaff: [
    {
      firstName: Scalars['String']['output'];
      lastName: Scalars['String']['output'];
      userAvatar: {
        url: Scalars['String']['output'];
      };
    }
  ];
  userAvatar: {
    url: Scalars['String']['output'];
  };
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  permissionTag: Scalars['String']['output'];
  domains: [{ color: Scalars['String']['output']; name: Scalars['String']['output'] }];
  equipment: [{ name: string; location?: Maybe<LocationFolderRes>; fileUrl: { fileKey: string; url: string } }];
};

export type EquipmentRes = {
  __typename?: 'EquipmentRes';
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  createdBy: UserRes;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  domain: DomainDto;
  domainId: Scalars['String']['output'];
  fileKey?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<UrlInfo>;
  id?: Maybe<Scalars['String']['output']>;
  itemDescription?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<LocationFolderRes>;
  locationId?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  modelNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parameters: Page_EquipmentParameterRes;
  rounds: Page_RoundRes;
  serialNumber?: Maybe<Scalars['String']['output']>;
  specifications?: Maybe<Scalars['String']['output']>;
  systemName: Scalars['String']['output'];
  templateDescription?: Maybe<Scalars['String']['output']>;
  type: EquipmentType;
  companyId?: string;
};

export type CustomTasksRes = {
  __typename?: 'TaskRes';
  id: string;
  name: string;
  description: string | null;
  status: keyof typeof TaskStatus;
  startDate: string;
  endDate: string;
  performer: { id: string; firstName: string; lastName: string; userAvatar: { url: string } };
  profileId?: string;
};

export type CustomTaskRes = {
  task: CustomTasksRes;
};

export type EquipmentResRoundsArgs = {
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  type: RoundType;
};

export enum EquipmentType {
  Equipment = 'EQUIPMENT',
  EquipmentTemplate = 'EQUIPMENT_TEMPLATE'
}

export enum Frequency {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutely = 'MINUTELY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type LocationFolderRes = {
  __typename?: 'LocationFolderRes';
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  createdBy: UserRes;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  locationFolders: Page_LocationFolderRes;
  name: Scalars['String']['output'];
  parentsLocationFolders?: Maybe<Array<Maybe<ParentLocationFolderRes>>>;
};

export type LocationFolderResLocationFoldersArgs = {
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
};

export type MoveEquipmentsToLocationReqInput = {
  equipmentIds: Array<InputMaybe<Scalars['String']['input']>>;
  locationId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveFolderToFolderRequestInput = {
  destinationFolderId?: InputMaybe<Scalars['String']['input']>;
  sourceFolderId: Scalars['String']['input'];
};

export type MoveLocationToLocationReqInput = {
  destinationLocationId?: InputMaybe<Scalars['String']['input']>;
  sourceLocationId: Scalars['String']['input'];
};

export type MoveUserToFolderRequestInput = {
  folderId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  /** Create new company for super admin */
  createCompany: CompanyRes;
  /** Create new permissions for users */
  createCompanyResourcePermission: Scalars['Boolean']['output'];
  /** Create a new domain */
  createDomain: DomainDto;
  /** Create new equipment */
  createEquipment: EquipmentRes;
  /** Create new equipment by template */
  createEquipmentByTemplate: EquipmentRes;
  /** Create new equipment parameter */
  createEquipmentParameter: EquipmentParameterRes;
  /** Create new location folder */
  createLocationFolder: LocationFolderRes;
  /** Create new region */
  createRegion: RegionRes;
  /** Create new role template */
  createRoleTemplate: RoleTemplateRes;
  /** Create new equipment round regulation */
  createRoundRegulation: RoundRes;
  /** Create a new unit */
  createUnit: UnitDto;
  /** Create new user and join to creator company */
  createUser: UserRes;
  /** Create new user folder */
  createUserFolder: UserFolderRes;
  /** Edit existing equipment */
  editEquipment: EquipmentRes;
  /** Edit existing equipment parameter */
  editEquipmentParameter: EquipmentParameterRes;
  /** Edit existing location folder */
  editLocationFolder: LocationFolderRes;
  /** Edit current user profile */
  editProfile: ProfileRes;
  /** Update role template */
  editRoleTemplate: RoleTemplateRes;
  /** Edit user */
  editUser: UserRes;
  /** Update user access by template or domains */
  editUserAccess: Scalars['Boolean']['output'];
  /** Move equipments to location */
  moveEquipmentsToLocation: Scalars['Boolean']['output'];
  /** Move folder to folder */
  moveFolderToFolder: Scalars['Boolean']['output'];
  /** Move location to another location */
  moveLocationToLocation: Scalars['Boolean']['output'];
  /** Move user to folder */
  moveUserToFolder: Scalars['Boolean']['output'];
  /** Switch the active state of a company for super admin */
  switchCompanyState: Scalars['Boolean']['output'];
  /** Update active state for equipment parameters */
  switchEquipmentParametersActiveState: Scalars['Boolean']['output'];
  /** Update archive state for equipment parameters */
  switchEquipmentParametersArchiveState: Scalars['Boolean']['output'];
  /** Update active state for equipments */
  switchEquipmentsActiveState: Scalars['Boolean']['output'];
  /** Update archive state for equipments */
  switchEquipmentsArchiveState: Scalars['Boolean']['output'];
  /** Update active state for locations */
  switchLocationsActiveState: Scalars['Boolean']['output'];
  /** Update archive state for locations */
  switchLocationsArchiveState: Scalars['Boolean']['output'];
  /** Update active state for role templates */
  switchRoleTemplatesActiveState: Scalars['Boolean']['output'];
  /** Update archive state for role templates */
  switchRoleTemplatesArchiveState: Scalars['Boolean']['output'];
  /** Switch user state */
  switchUserState: Scalars['Boolean']['output'];
  /** Update company for super admin */
  updateCompany: CompanyRes;
  /** Update permissions for user */
  updateCompanyResourcePermission: RegionPermissionDto;
  /** Edit an existing domain */
  updateDomain: DomainDto;
  /** Update current user profile password */
  updateProfilePassword: Scalars['Boolean']['output'];
  /** Edit an existing unit */
  updateUnit: UnitDto;
};

/** Mutation root */
export type MutationCreateCompanyArgs = {
  company: CreateCompanyReqInput;
};

/** Mutation root */
export type MutationCreateCompanyResourcePermissionArgs = {
  request: CreatePermissionReqInput;
};

/** Mutation root */
export type MutationCreateDomainArgs = {
  name: Scalars['String']['input'];
};

/** Mutation root */
export type MutationCreateEquipmentArgs = {
  equipment: CreateEquipmentReqInput;
};

/** Mutation root */
export type MutationCreateEquipmentByTemplateArgs = {
  templateId: Scalars['String']['input'];
};

/** Mutation root */
export type MutationCreateEquipmentParameterArgs = {
  parameter: CreateParameterReqInput;
};

/** Mutation root */
export type MutationCreateLocationFolderArgs = {
  locationFolder: CreateLocationFolderReqInput;
};

/** Mutation root */
export type MutationCreateRegionArgs = {
  region: CreateOrUpdateRegionReqInput;
};

/** Mutation root */
export type MutationCreateRoleTemplateArgs = {
  request: CreateOrEditRoleTemplateReqInput;
};

/** Mutation root */
export type MutationCreateRoundRegulationArgs = {
  createRequest: CreateRoundRegulationRequestInput;
};

/** Mutation root */
export type MutationCreateUnitArgs = {
  name: Scalars['String']['input'];
};

/** Mutation root */
export type MutationCreateUserArgs = {
  companyUid?: InputMaybe<Scalars['String']['input']>;
  user: CreateUserReqInput;
};

/** Mutation root */
export type MutationCreateUserFolderArgs = {
  userFolder: CreateUserFolderReqInput;
};

/** Mutation root */
export type MutationEditEquipmentArgs = {
  equipment: EditEquipmentReqInput;
  id: Scalars['String']['input'];
};

/** Mutation root */
export type MutationEditEquipmentParameterArgs = {
  editRequest: EditParameterRequestInput;
  parameterId: Scalars['String']['input'];
};

/** Mutation root */
export type MutationEditLocationFolderArgs = {
  id: Scalars['String']['input'];
  locationFolder: EditLocationFolderReqInput;
};

/** Mutation root */
export type MutationEditProfileArgs = {
  request: EditProfileReqInput;
};

/** Mutation root */
export type MutationEditRoleTemplateArgs = {
  id: Scalars['String']['input'];
  request: CreateOrEditRoleTemplateReqInput;
};

/** Mutation root */
export type MutationEditUserArgs = {
  user: EditUserReqInput;
  userId: Scalars['String']['input'];
};

/** Mutation root */
export type MutationEditUserAccessArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  request: EditUserAccessReqInput;
};

/** Mutation root */
export type MutationMoveEquipmentsToLocationArgs = {
  moveRequest: MoveEquipmentsToLocationReqInput;
};

/** Mutation root */
export type MutationMoveFolderToFolderArgs = {
  moveRequest: MoveFolderToFolderRequestInput;
};

/** Mutation root */
export type MutationMoveLocationToLocationArgs = {
  moveRequest: MoveLocationToLocationReqInput;
};

/** Mutation root */
export type MutationMoveUserToFolderArgs = {
  moveRequest: MoveUserToFolderRequestInput;
};

/** Mutation root */
export type MutationSwitchCompanyStateArgs = {
  id: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

/** Mutation root */
export type MutationSwitchEquipmentParametersActiveStateArgs = {
  request: SwitchParameterActiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchEquipmentParametersArchiveStateArgs = {
  request: SwitchParameterArchiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchEquipmentsActiveStateArgs = {
  request: SwitchEquipmentActiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchEquipmentsArchiveStateArgs = {
  request: SwitchEquipmentArchiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchLocationsActiveStateArgs = {
  request: SwitchLocationActiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchLocationsArchiveStateArgs = {
  request: SwitchLocationArchiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchRoleTemplatesActiveStateArgs = {
  request: SwitchRoleTemplateActiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchRoleTemplatesArchiveStateArgs = {
  request: SwitchRoleTemplateArchiveStateReqInput;
};

/** Mutation root */
export type MutationSwitchUserStateArgs = {
  request: SwitchUserStatusRequestInput;
};

/** Mutation root */
export type MutationUpdateCompanyArgs = {
  company: UpdateCompanyReqInput;
  id: Scalars['String']['input'];
};

/** Mutation root */
export type MutationUpdateCompanyResourcePermissionArgs = {
  request: UpdatePermissionReqInput;
};

/** Mutation root */
export type MutationUpdateDomainArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Mutation root */
export type MutationUpdateProfilePasswordArgs = {
  newPassword: Scalars['String']['input'];
};

/** Mutation root */
export type MutationUpdateUnitArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum NullPrecedence {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type Page_CompanyRes = {
  __typename?: 'Page_CompanyRes';
  items: Array<Maybe<CompanyRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_DomainDto = {
  __typename?: 'Page_DomainDto';
  items: Array<Maybe<DomainDto>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_EquipmentParameterRes = {
  __typename?: 'Page_EquipmentParameterRes';
  items: Array<Maybe<EquipmentParameterRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_EquipmentRes = {
  __typename?: 'Page_EquipmentRes';
  items: Array<Maybe<EquipmentRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_LocationFolderRes = {
  __typename?: 'Page_LocationFolderRes';
  items: Array<Maybe<LocationFolderRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_RegionRes = {
  __typename?: 'Page_RegionRes';
  items: Array<Maybe<RegionRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_RoleTemplateRes = {
  __typename?: 'Page_RoleTemplateRes';
  items: Array<Maybe<RoleTemplateRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_RoundRes = {
  __typename?: 'Page_RoundRes';
  items: Array<Maybe<RoundRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_UnitDto = {
  __typename?: 'Page_UnitDto';
  items: Array<Maybe<UnitDto>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_UserFolderRes = {
  __typename?: 'Page_UserFolderRes';
  items: Array<Maybe<UserFolderRes>>;
  total: Scalars['BigInteger']['output'];
};

export type Page_UserRes = {
  __typename?: 'Page_UserRes';
  items: Array<Maybe<UserRes>>;
  total: Scalars['BigInteger']['output'];
};

export type PageableInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type ParameterInput = {
  addNote: Scalars['Boolean']['input'];
  condition?: InputMaybe<ConditionInput>;
  id: Scalars['String']['input'];
  uploadPhoto: Scalars['Boolean']['input'];
  validationConditions: Scalars['Boolean']['input'];
};

export enum ParameterType {
  Boolean = 'BOOLEAN',
  DataTime = 'DATA_TIME',
  NumericFixed = 'NUMERIC_FIXED',
  NumericFormula = 'NUMERIC_FORMULA',
  NumericInput = 'NUMERIC_INPUT',
  String = 'STRING'
}

export enum FrequencyType {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  EveryThirdMonth = 'EVERY_3_MONTH',
  EverySixthMonth = 'EVERY_6_MONTH',
  SingleTimeRound = 'SINGLE_TIME_ROUND',
  Yearly = 'YEARLY'
}

export enum ParameterStatus {
  Failed = 'FAILED',
  NoValidation = 'NO_VALIDATION',
  Passed = 'PASSED'
}

export type ParentLocationFolderRes = {
  __typename?: 'ParentLocationFolderRes';
  depth: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PermissionDto = {
  __typename?: 'PermissionDto';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operation: Scalars['String']['output'];
  resource: Scalars['String']['output'];
};

export type PermissionRes = {
  __typename?: 'PermissionRes';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operation: Scalars['String']['output'];
  resource: Scalars['String']['output'];
};

export type PermissionResInput = {
  name: Scalars['String']['input'];
  operation: Scalars['String']['input'];
  resource: Scalars['String']['input'];
};

export type ProfileRes = {
  __typename?: 'ProfileRes';
  companyId: Scalars['String']['output'];
  domainIds: Array<Maybe<Scalars['String']['output']>>;
  domains?: Maybe<Array<Maybe<DomainDto>>>;
  email: Scalars['String']['output'];
  fileKey?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastPasswordChangeDate?: Maybe<Scalars['DateTime']['output']>;
  permissionTag?: Maybe<Scalars['String']['output']>;
  permissions: Array<Maybe<PermissionDto>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileAvatar?: Maybe<UrlInfo>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  /** Get list all access types of permissions */
  accessTypes: Array<Maybe<AccessTypeResponse>>;
  /** Load available login to company */
  availableCompany: Array<Maybe<Client>>;
  /** Get list company for admin */
  companies: Page_CompanyRes;
  /** Get company for admin */
  company: CompanyRes;
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Get domain by ID */
  domain: DomainDto;
  /** Get equipment by id */
  equipment: EquipmentRes;
  /** Get equipment parameter by id */
  equipmentParameter: EquipmentParameterRes;
  /** Get list of parameters for equipment */
  equipmentParameters: Page_EquipmentParameterRes;
  /** Get list of equipments */
  equipments: Page_EquipmentRes;
  /** Get file URL by fileKey */
  fileUrl: UrlInfo;
  hello: Scalars['String']['output'];
  /** Get location folder by id */
  locationFolder: LocationFolderRes;
  /** Get list of location folders */
  locationFolders: Page_LocationFolderRes;
  /** Get list all available permissions */
  permissions: Array<Maybe<PermissionRes>>;
  /** Get a presigned URL for uploading a file to the specified S3 path */
  presignedUploadUrl: UrlInfo;
  /** Get current user profile */
  profile: ProfileRes;
  /** Get region by id */
  region: RegionRes;
  /** Get list of regions */
  regions: Page_RegionRes;
  /** Get role template */
  roleTemplate: RoleTemplateRes;
  /** Get role templates for current company */
  roleTemplates: Page_RoleTemplateRes;
  /** Get equipment round by id */
  round: RoundRes;
  /** Get round's parameters by id */
  roundParameters: Array<Maybe<RoundParameterRes>>;
  /** Get list of rounds for equipment */
  rounds: Page_RoundRes;
  /** Get all domains */
  searchDomains: Page_DomainDto;
  /** Get all units */
  searchUnits: Page_UnitDto;
  /** Get unit by ID */
  unit: UnitDto;
  /** Get user by uid */
  user: UserRes;
  /** Get user folder by id */
  userFolder: UserFolderRes;
  /** Get list of user folders */
  userFolders: Page_UserFolderRes;
  /** Get list users for admin from current company */
  users: Page_UserRes;
};

/** Query root */
export type QueryAvailableCompanyArgs = {
  email: Scalars['String']['input'];
};

/** Query root */
export type QueryCompaniesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInput>;
};

/** Query root */
export type QueryCompanyArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryDescriptionArgs = {
  permissions: Array<InputMaybe<PermissionResInput>>;
};

/** Query root */
export type QueryDomainArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryEquipmentArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryEquipmentParameterArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryEquipmentParametersArgs = {
  equipmentId: Scalars['String']['input'];
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  types?: InputMaybe<Array<InputMaybe<ParameterType>>>;
};

/** Query root */
export type QueryEquipmentsArgs = {
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  type: EquipmentType;
};

/** Query root */
export type QueryFileUrlArgs = {
  fileKey: Scalars['String']['input'];
};

/** Query root */
export type QueryLocationFolderArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryLocationFoldersArgs = {
  locationFolderId?: InputMaybe<Scalars['String']['input']>;
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
};

/** Query root */
export type QueryPresignedUploadUrlArgs = {
  path: S3Path;
};

/** Query root */
export type QueryRegionArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryRegionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['String']['input']>;
};

/** Query root */
export type QueryRoleTemplateArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryRoleTemplatesArgs = {
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
};

/** Query root */
export type QueryRoundArgs = {
  roundId: Scalars['String']['input'];
};

/** Query root */
export type QueryRoundParametersArgs = {
  roundId: Scalars['String']['input'];
};

/** Query root */
export type QueryRoundsArgs = {
  equipmentId: Scalars['String']['input'];
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  type: RoundType;
};

/** Query root */
export type QuerySearchDomainsArgs = {
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
};

/** Query root */
export type QuerySearchUnitsArgs = {
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
};

/** Query root */
export type QueryUnitArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryUserArgs = {
  uid: Scalars['String']['input'];
};

/** Query root */
export type QueryUserFolderArgs = {
  id: Scalars['String']['input'];
};

/** Query root */
export type QueryUserFoldersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userFolderId?: InputMaybe<Scalars['String']['input']>;
};

/** Query root */
export type QueryUsersArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  folderId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type RegionPermissionDto = {
  __typename?: 'RegionPermissionDto';
  access: Array<Maybe<AccessType>>;
  id: Scalars['String']['output'];
  resourceId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type RegionRes = {
  __typename?: 'RegionRes';
  active: Scalars['Boolean']['output'];
  companies: Page_CompanyRes;
  createdBy: UserRes;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  regions: Page_RegionRes;
};

export type RegionResCompaniesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type RegionResRegionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type RoleTemplateRes = {
  __typename?: 'RoleTemplateRes';
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  companyId: Scalars['String']['output'];
  createdBy: UserRes;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  domainIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  domains?: Maybe<Array<Maybe<DomainDto>>>;
  id: Scalars['String']['output'];
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  permissions: Array<Maybe<PermissionRes>>;
  usageCount: Scalars['BigInteger']['output'];
};

export type UsersRoleTemplateRes = {
  __typename?: 'UserRes';
  createdDate: any;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phoneNumber?: string | null;
  permissionTag?: string | null;
  status: UserStatus;
  userAvatar: {
    url: string;
  };
  company: Array<{ __typename?: 'Company'; name: string; id: string } | null>;
  domains?: Array<{ __typename?: 'DomainDto'; color: Color; name: string } | null> | null;
};

export type RoundFrequency = {
  __typename?: 'RoundFrequency';
  every: Scalars['Int']['output'];
  frequency: Frequency;
};

export type RoundFrequencyInput = {
  every: Scalars['Int']['input'];
  frequency: Frequency;
};

export type RoundParameterRes = {
  __typename?: 'RoundParameterRes';
  addNote: Scalars['Boolean']['output'];
  condition?: Maybe<Condition>;
  createdBy?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  parameterId?: Maybe<Scalars['String']['output']>;
  uploadPhoto: Scalars['Boolean']['output'];
  validationConditions: Scalars['Boolean']['output'];
};

export type RoundRes = {
  __typename?: 'RoundRes';
  /** ISO-8601 */
  activationTargetDate?: Maybe<Scalars['DateTime']['output']>;
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<RoundFrequency>;
  id?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  parameters: Array<Maybe<RoundParameterRes>>;
  serviceStaff: Array<Maybe<Scalars['String']['output']>>;
};

export enum RoundType {
  Round = 'ROUND',
  RoundRegulation = 'ROUND_REGULATION'
}

export enum S3Path {
  CompanyLogo = 'COMPANY_LOGO',
  EquipmentPhoto = 'EQUIPMENT_PHOTO',
  UserAvatar = 'USER_AVATAR'
}

export type SearchCriteriaInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isArchive?: InputMaybe<Scalars['Boolean']['input']>;
  pageable?: InputMaybe<PageableInput>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortable?: InputMaybe<SortInput>;
};

export type SortInput = {
  column?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Direction>;
  nullPrecedence?: InputMaybe<NullPrecedence>;
};

export type SwitchEquipmentActiveStateReqInput = {
  active: Scalars['Boolean']['input'];
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchEquipmentArchiveStateReqInput = {
  archived: Scalars['Boolean']['input'];
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchLocationActiveStateReqInput = {
  active: Scalars['Boolean']['input'];
  locationIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchLocationArchiveStateReqInput = {
  archived: Scalars['Boolean']['input'];
  locationIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchParameterActiveStateReqInput = {
  active: Scalars['Boolean']['input'];
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchParameterArchiveStateReqInput = {
  archived: Scalars['Boolean']['input'];
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchRoleTemplateActiveStateReqInput = {
  active: Scalars['Boolean']['input'];
  templateIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchRoleTemplateArchiveStateReqInput = {
  archived: Scalars['Boolean']['input'];
  templateIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SwitchUserStatusRequestInput = {
  status: UserStatus;
  userId: Scalars['String']['input'];
};

export type UnitDto = {
  __typename?: 'UnitDto';
  createdBy: Scalars['String']['output'];
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
};

export type UpdateCompanyContactInfoReqInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  representative?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyReqInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  contactInfo: UpdateCompanyContactInfoReqInput;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePermissionReqInput = {
  access: Scalars['Int']['input'];
  resourceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UrlInfo = {
  __typename?: 'UrlInfo';
  fileKey: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UserFolderRes = {
  __typename?: 'UserFolderRes';
  active: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  userFolders: Page_UserFolderRes;
};

export type UserFolderResUserFoldersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type UserRes = {
  __typename?: 'UserRes';
  company: Array<Maybe<Company>>;
  /** ISO-8601 */
  createdDate: Scalars['DateTime']['output'];
  domainIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  domains?: Maybe<Array<Maybe<DomainDto>>>;
  email: Scalars['String']['output'];
  fileKey?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  permissionTag?: Maybe<Scalars['String']['output']>;
  permissions: Array<Maybe<PermissionDto>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: UserStatus;
  userAvatar?: Maybe<UrlInfo>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export enum TaskStatus {
  ON_HOLD = 'On hold',
  IN_PROGRESS = 'In progress',
  PENDING = 'Pending',
  ON_HOLD_REQUESTED = 'On hold pending',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  OVERDUE = 'Overdue',
  NO_VALIDATION = 'No validation'
}

export enum TaskColorBackgroundStatus {
  ON_HOLD = 'rgba(41, 49, 62, 0.03)',
  IN_PROGRESS = '#EFFFD6',
  PENDING = '#FFFAE7',
  ON_HOLD_REQUESTED = '#F3F0FF',
  CANCELLED = '#FFF5F7',
  COMPLETED = '#E7F9FF',
  OVERDUE = '#FFF3EB',
  NO_VALIDATION = '#F3F3F3'
}

export enum TaskColorStatus {
  ON_HOLD = '#505864',
  IN_PROGRESS = '#4C6B1F',
  PENDING = '#7F5F01',
  ON_HOLD_REQUESTED = '#5E4DB2',
  CANCELLED = '#990A23',
  COMPLETED = '#206A83',
  OVERDUE = '#A54800',
  NO_VALIDATION = '#505864'
}

export type ContactInfoFragmentFragment = {
  __typename?: 'CompanyContactInfoRes';
  phoneNumber?: string | null;
  representative?: string | null;
} & { ' $fragmentName'?: 'ContactInfoFragmentFragment' };

export type GetCompaniesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  column?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Direction>;
  nullPrecedence?: InputMaybe<NullPrecedence>;
}>;

export type GetCompaniesQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_CompanyRes';
    total: any;
    items: Array<{
      __typename?: 'CompanyRes';
      id?: string | null;
      active: boolean;
      address?: string | null;
      name: string;
      domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string } | null> | null;
      contactInfo: { __typename?: 'CompanyContactInfoRes' } & {
        ' $fragmentRefs'?: { ContactInfoFragmentFragment: ContactInfoFragmentFragment };
      };
      logo?: { __typename?: 'UrlInfo'; url: string } | null;
    } | null>;
  };
};

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String']['input'];
  fileKey?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['String']['input']>;
  domainIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: { __typename?: 'CompanyRes'; id?: string | null; name: string };
};

export type CreateUserMutationVariables = Exact<{
  companyUid?: InputMaybe<Scalars['String']['input']>;
  user: CreateUserReqInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'UserRes'; email: string; id: string };
};

export type GetCompanyQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'CompanyRes';
    active: boolean;
    address?: string | null;
    id?: string | null;
    name: string;
    language?: string | null;
    domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string } | null> | null;
    contactInfo: {
      __typename?: 'CompanyContactInfoRes';
      email?: string | null;
      phoneNumber?: string | null;
      representative?: string | null;
    };
    logo?: { __typename?: 'UrlInfo'; url: string; fileKey: string } | null;
    users: {
      __typename?: 'Page_UserRes';
      total: any;
      items: Array<{
        __typename?: 'UserRes';
        email: string;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        permissionTag?: string | null;
      } | null>;
    };
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
  contactInfo: UpdateCompanyContactInfoReqInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: { __typename?: 'CompanyRes'; active: boolean; id?: string | null; name: string };
};

export type SwitchCompanyStateMutationVariables = Exact<{
  companyId: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
}>;

export type SwitchCompanyStateMutation = { __typename?: 'Mutation'; switchCompanyState: boolean };

export type CreateDomainMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type CreateDomainMutation = {
  __typename?: 'Mutation';
  createDomain: { __typename?: 'DomainDto'; id?: string | null; name: string };
};

export type UpdateDomainMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateDomainMutation = {
  __typename?: 'Mutation';
  updateDomain: { __typename?: 'DomainDto'; id?: string | null; name: string };
};

export type SearchDomainsQueryVariables = Exact<{
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
}>;

export type SearchDomainsQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_DomainDto';
    total: any;
    items: Array<{
      __typename?: 'DomainDto';
      id?: string | null;
      name: string;
      lastModifiedDate?: any | null;
      itemsCount: any;
    } | null>;
  };
};

export type SearchRoundTasksQuery = {
  __typename?: 'Query';
  dataItems: {
    total: string;
    items: Array<{
      ids: string[];
      name: string;
    } | null>;
  };
};

export type DomainQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DomainQuery = { __typename?: 'Query'; domain: { __typename?: 'DomainDto'; name: string } };

export type EquipmentQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type EquipmentQuery = {
  __typename?: 'Query';
  equipment: {
    __typename?: 'EquipmentRes';
    active: boolean;
    archived: boolean;
    createdBy: UserRes;
    createdDate: any;
    itemDescription?: string | null;
    templateDescription?: string | null;
    id?: string | null;
    lastModifiedDate?: any | null;
    manufacturer?: string | null;
    modelNumber?: string | null;
    name: string;
    specifications?: string | null;
    domainId: string;
    fileUrl?: { __typename?: 'UrlInfo'; url: string; fileKey: string } | null;
    domain: { __typename?: 'DomainDto'; color: Color; name: string; itemsCount: any; id?: string | null };
    companyId?: number;
    systemName?: string;
  };
};

export type EquipmentParameterQuery = {
  dataItems: {
    active: boolean;
    archived: boolean;
    createdBy: string;
    createdDate: string;
    description: string;
    equipmentId: string;
    id: string;
    lastModifiedDate: string;
    order: number;
    propertyId: string;
    title: string;
    type: string;
    unitId: string;
    readableFormula: string;
    value: string;
    unit: { name: string };
  };
};

export type EquipmentItemNameQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type EquipmentItemNameQuery = {
  __typename?: 'Query';
  equipment: { __typename?: 'EquipmentRes'; id?: string | null; name: string };
};

export type EquipmentItemInformationQuery = {
  __typename?: 'Query';
  equipment: {
    __typename?: 'EquipmentRes';
    id?: string | null;
    name: string;
    domain: { __typename?: 'DomainDto'; color: Color; id?: string | null; name: string };
    fileUrl: UrlInfo;
  };
};

export type EditEquipmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
  equipment: EditEquipmentReqInput;
}>;

export type EditEquipmentMutation = {
  __typename?: 'Mutation';
  editEquipment: { __typename?: 'EquipmentRes'; id?: string | null };
};

export type EquipmentsQueryVariables = Exact<{
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  type: EquipmentType;
}>;

export type EquipmentsQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_EquipmentRes';
    total: any;
    items: Array<{
      __typename?: 'EquipmentRes';
      companyId?: string;
      active: boolean;
      archived: boolean;
      createdDate: any;
      templateDescription?: string | null;
      domainId: string;
      id?: string | null;
      manufacturer?: string | null;
      modelNumber?: string | null;
      name: string;
      locationId?: string | null;
      location?: LocationFolderRes;
      specifications?: string | null;
      systemName?: string | null;
      domain: { __typename?: 'DomainDto'; color: Color; id?: string | null; name: string; itemsCount: any };
      fileUrl?: { __typename?: 'UrlInfo'; url: string; fileKey: string } | null;
    } | null>;
  };
};

export type CreateEquipmentMutationVariables = Exact<{
  equipment: CreateEquipmentReqInput;
}>;

export type CreateEquipmentMutation = {
  __typename?: 'Mutation';
  createEquipment: { __typename?: 'EquipmentRes'; id?: string | null; name: string; systemName: string };
};

export type CreateEquipmentByTemplateMutation = {
  __typename?: 'Mutation';
  createEquipmentByTemplate: { __typename?: 'EquipmentRes'; id?: string | null; name: string; systemName: string };
};

export type SwitchEquipmentsActiveStateMutationVariables = Exact<{
  request: SwitchEquipmentActiveStateReqInput;
}>;

export type SwitchEquipmentsActiveStateMutation = { __typename?: 'Mutation'; switchEquipmentsActiveState: boolean };

export type SwitchEquipmentsArchiveStateMutationVariables = Exact<{
  request: SwitchEquipmentArchiveStateReqInput;
}>;

export type SwitchEquipmentsArchiveStateMutation = { __typename?: 'Mutation'; switchEquipmentsArchiveState: boolean };

export type SwitchEquipmentParametersArchiveStateMutationVariables = Exact<{
  request: SwitchParameterArchiveStateReqInput;
}>;

export type SwitchEquipmentParametersArchiveStateMutation = {
  __typename?: 'Mutation';
  switchEquipmentParametersArchiveState: boolean;
};

export type SwitchEquipmentParametersActiveStateMutationVariables = Exact<{
  request: SwitchParameterActiveStateReqInput;
}>;

export type SwitchEquipmentParametersActiveStateMutation = {
  __typename?: 'Mutation';
  switchEquipmentParametersActiveState: boolean;
};

export type CreateEquipmentParameterMutationVariables = Exact<{
  parameter: CreateParameterReqInput;
}>;

export type CreateEquipmentParameterMutation = {
  __typename?: 'Mutation';
  createEquipmentParameter: {
    __typename?: 'EquipmentParameterRes';
    createdBy: string;
    description?: string | null;
    equipmentId: string;
    propertyId: string;
    id?: string | null;
    title: string;
  };
};

export type EquipmentParametersQueryVariables = Exact<{
  equipmentId: Scalars['String']['input'];
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  types?: InputMaybe<Array<InputMaybe<ParameterType>> | InputMaybe<ParameterType>>;
}>;

export type EquipmentParametersQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_EquipmentParameterRes';
    total: any;
    items: Array<{
      __typename?: 'EquipmentParameterRes';
      active: boolean;
      archived: boolean;
      description?: string | null;
      equipmentId: string;
      createdDate: any;
      id?: string | null;
      propertyId: string;
      title: string;
      type: ParameterType;
      unitId?: string | null;
      value?: string | null;
      condition?: {
        type?: string;
        minValue?: string;
        maxValue?: string;
        exactValue?: string;
      };
      uploadPhoto?: boolean;
      addNote?: boolean;
    } | null>;
  };
};

export type RoundRegulationsQuery = {
  __typename?: 'Page_RoundRes';
  dataItems: {
    items: Array<{
        activationTargetDate: string;
        active: boolean;
        description: string | null;
        frequency: { frequency: string; __typename: string };
        name: string;
        serviceStaff: [{ firstName: string; lastName: string; userAvatar: { url: string } }];
        id: number;
        createdDate: string;
        lastModifiedDate: string;
        equipment?: [{ name: string; id: string; fileUrl: { fileKey: string; url: string }, location?: LocationFolderRes }];
        status?: any;
      }>;
    total: number;
  };
};

export type CustomTasksQuery = {
  __typename?: 'Page_TaskRes';
  tasks: {
    items: [
      {
        id: string;
        name: string;
        description: string | null;
        status: TaskStatus;
        startDate: string;
        endDate: string;
        performer: { id: string; firstName: string; lastName: string; userAvatar: { url: string } };
      }
    ];
    total: number;
  };
};

export type RoundParametersRes = {
  addNote?: boolean;
  condition: {
    exactValue: string;
    maxValue: string;
    minValue: string;
    type: ConditionType;
  };
  createdBy?: string;
  createdDate?: any;
  description: string;
  id: string;
  lastModifiedDate?: any;
  parameterId?: string;
  uploadPhoto?: boolean;
  validationConditions?: boolean;
  parameterDetails: {
    active: boolean;
    archived: boolean;
    description?: string | null;
    equipmentId?: string;
    createdDate: any;
    id?: string | null;
    propertyId?: string;
    title: string;
    type: ParameterType;
    unitId?: string | null;
    value?: string | null;
  };
  parameterResult: {
    fileKey?: string;
    id: string;
    note: string;
    status: ParameterStatus;
    value: string;
    photoUrl?: {
      fileKey?: string;
      url?: string;
    };
  };
};

export type RoundRegulationQuery = {
  dataItems: {
    activationTargetDate: string;
    active: boolean;
    archived: boolean;
    description: string | null;
    frequency: { frequency: string; __typename: string };
    name: string;
    serviceStaff: [{ firstName: string; lastName: string; userAvatar: { url: string }; id: string }];
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    parameters: Array<RoundParametersRes>;
    status?: string;
    equipment?: [
      {
        name: string;
        id: string;
        domain?: { color: string; name: string };
        location?: Maybe<LocationFolderRes>;
        fileUrl: { fileKey: string; url: string };
      }
    ];
    __typename: 'RoundRes';
  };
};

export type GetImageUrlQueryVariables = Exact<{
  fileKey: Scalars['String']['input'];
}>;

export type GetImageUrlQuery = {
  __typename?: 'Query';
  fileUrl: { __typename?: 'UrlInfo'; fileKey: string; url: string };
};

export type SearchUnitsQueryVariables = Exact<{
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
}>;

export type SearchUnitsQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_UnitDto';
    items: Array<{ __typename?: 'UnitDto'; id?: string | null; name: string } | null>;
  };
};

export type LocationFolderResFragment = {
  __typename?: 'LocationFolderRes';
  id?: string | null;
  name: string;
  active: boolean;
} & { ' $fragmentName'?: 'LocationFolderResFragment' };

export type GetLocationsSidebarQueryVariables = Exact<{
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  locationFolderId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetLocationsSidebarQuery = {
  __typename?: 'Query';
  locationFolders: {
    __typename?: 'Page_LocationFolderRes';
    items: Array<
      | ({
          __typename?: 'LocationFolderRes';
          groupItems: {
            __typename?: 'Page_LocationFolderRes';
            items: Array<
              | ({
                  __typename?: 'LocationFolderRes';
                  groupItems: {
                    __typename?: 'Page_LocationFolderRes';
                    items: Array<
                      | ({
                          __typename?: 'LocationFolderRes';
                          groupItems: {
                            __typename?: 'Page_LocationFolderRes';
                            items: Array<
                              | ({
                                  __typename?: 'LocationFolderRes';
                                  groupItems: {
                                    __typename?: 'Page_LocationFolderRes';
                                    items: Array<
                                      | ({ __typename?: 'LocationFolderRes' } & {
                                          ' $fragmentRefs'?: { LocationFolderResFragment: LocationFolderResFragment };
                                        })
                                      | null
                                    >;
                                  };
                                } & { ' $fragmentRefs'?: { LocationFolderResFragment: LocationFolderResFragment } })
                              | null
                            >;
                          };
                        } & { ' $fragmentRefs'?: { LocationFolderResFragment: LocationFolderResFragment } })
                      | null
                    >;
                  };
                } & { ' $fragmentRefs'?: { LocationFolderResFragment: LocationFolderResFragment } })
              | null
            >;
          };
        } & { ' $fragmentRefs'?: { LocationFolderResFragment: LocationFolderResFragment } })
      | null
    >;
  };
};

export type CreateLocationFolderMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateLocationFolderMutation = {
  __typename?: 'Mutation';
  createLocationFolder: { __typename?: 'LocationFolderRes'; id?: string | null; name: string };
};

export type LocationFolderQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LocationFolderQuery = {
  __typename?: 'Query';
  locationFolder: { __typename?: 'LocationFolderRes'; id?: string | null; name: string; description?: string | null };
};

export type LocationFolderWithStatusesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LocationFolderWithStatusesQuery = {
  __typename?: 'Query';
  locationFolder: { __typename?: 'LocationFolderRes'; active: boolean; archived: boolean };
};

export type GetLocationsTabQueryVariables = Exact<{
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
  locationFolderId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetLocationsTabQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_LocationFolderRes';
    items: Array<{ __typename?: 'LocationFolderRes'; id?: string | null; name: string; active: boolean } | null>;
  };
};

export type GetRegionsQuery = {
  __typename?: 'Query';
  regions: {
    __typename?: 'Page_RegionRes';
    items: Array<{ __typename?: 'RegionRes'; id?: string | null; name: string; active: boolean } | null>;
  };
};

export type LocationFolderByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
  searchCriteria?: InputMaybe<SearchCriteriaInput>;
}>;

export type LocationFolderByIdQuery = {
  __typename?: 'Query';
  locationFolder: {
    __typename?: 'LocationFolderRes';
    id?: string | null;
    name: string;
    locationFolders: {
      __typename?: 'Page_LocationFolderRes';
      items: Array<{ __typename?: 'LocationFolderRes'; id?: string | null; name: string; active: boolean } | null>;
    };
  };
};

export type RegionByIdQuery = {
  __typename?: 'Query';
  region: {
    __typename?: 'RegionRes';
    id?: string | null;
    name: string;
    regions: {
      __typename?: 'Page_RegionRes';
      items: Array<{ __typename?: 'RegionRes'; id?: string | null; name: string; active: boolean } | null>;
    };
  };
};

export type MoveLocationToLocationMutationVariables = Exact<{
  sourceLocationId: Scalars['String']['input'];
  destinationLocationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type MoveLocationToLocationMutation = { __typename?: 'Mutation'; moveLocationToLocation: boolean };

export type SwitchLocationsActiveStateMutationVariables = Exact<{
  locationIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  active: Scalars['Boolean']['input'];
}>;

export type SwitchLocationsActiveStateMutation = { __typename?: 'Mutation'; switchLocationsActiveState: boolean };

export type SwitchLocationsArchiveStateMutationVariables = Exact<{
  locationIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  archived: Scalars['Boolean']['input'];
}>;

export type SwitchLocationsArchiveStateMutation = { __typename?: 'Mutation'; switchLocationsArchiveState: boolean };

export type EditLocationFolderMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;

export type EditLocationFolderMutation = {
  __typename?: 'Mutation';
  editLocationFolder: { __typename?: 'LocationFolderRes'; id?: string | null; name: string };
};

export type EditRegionMutation = {
  __typename?: 'Mutation';
  editLocationFolder: { __typename?: 'RegionRes'; id?: string | null; name: string };
};

export type GetDetailedPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDetailedPermissionsQuery = {
  __typename?: 'Query';
  permissions: Array<{
    __typename?: 'PermissionRes';
    resource: string;
    operation: string;
    label: string;
    value: string;
  } | null>;
};

export type RegionResFragment = { __typename?: 'RegionRes'; id?: string | null; name: string } & {
  ' $fragmentName'?: 'RegionResFragment';
};

export type GetRegionsSidebarQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetRegionsSidebarQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_RegionRes';
    items: Array<
      | ({
          __typename?: 'RegionRes';
          groupItems: {
            __typename?: 'Page_RegionRes';
            items: Array<
              | ({
                  __typename?: 'RegionRes';
                  groupItems: {
                    __typename?: 'Page_RegionRes';
                    items: Array<
                      | ({
                          __typename?: 'RegionRes';
                          groupItems: {
                            __typename?: 'Page_RegionRes';
                            items: Array<
                              | ({
                                  __typename?: 'RegionRes';
                                  groupItems: {
                                    __typename?: 'Page_RegionRes';
                                    items: Array<
                                      | ({ __typename?: 'RegionRes' } & {
                                          ' $fragmentRefs'?: { RegionResFragment: RegionResFragment };
                                        })
                                      | null
                                    >;
                                  };
                                } & { ' $fragmentRefs'?: { RegionResFragment: RegionResFragment } })
                              | null
                            >;
                          };
                        } & { ' $fragmentRefs'?: { RegionResFragment: RegionResFragment } })
                      | null
                    >;
                  };
                } & { ' $fragmentRefs'?: { RegionResFragment: RegionResFragment } })
              | null
            >;
          };
        } & { ' $fragmentRefs'?: { RegionResFragment: RegionResFragment } })
      | null
    >;
  };
};

export type CreateRoleTemplateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type CreateRoleTemplateMutation = {
  __typename?: 'Mutation';
  createRoleTemplate: {
    __typename?: 'RoleTemplateRes';
    name: string;
    permissions: Array<{
      __typename?: 'PermissionRes';
      name: string;
      operation: string;
      resource: string;
      description: string;
    } | null>;
  };
};

export type GetRoleTemplatesQueryVariables = Exact<{
  searchCriteria: SearchCriteriaInput;
}>;

export type GetRoleTemplatesQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_RoleTemplateRes';
    total: any;
    items: Array<{
      __typename?: 'RoleTemplateRes';
      id: string;
      companyId: string;
      name: string;
      lastModifiedDate?: any | null;
      usageCount: any;
      domainIds?: Array<string | null> | null;
      domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string } | null> | null;
      permissions?: Array<{ description: string; name: string; operation: string; resource: string }>;
    } | null>;
  };
};

export type GetRoleTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetRoleTemplateQuery = {
  __typename?: 'Query';
  roleTemplate: {
    __typename?: 'RoleTemplateRes';
    id: string;
    name: string;
    description?: string | null;
    permissions: Array<{ __typename?: 'PermissionRes'; name: string; operation: string; resource: string } | null>;
    domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string } | null> | null;
  };
};

export type GetRoleTemplateDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetRoleTemplateDetailsQuery = {
  __typename?: 'Query';
  roleTemplate: {
    __typename?: 'RoleTemplateRes';
    id: string;
    name: string;
    description?: string | null;
    usageCount: any;
    createdDate: any;
    lastModifiedDate?: any | null;
    createdBy: {
      __typename?: 'UserRes';
      firstName?: string | null;
      lastName?: string | null;
      userAvatar?: { __typename?: 'UrlInfo'; fileKey: string; url: string } | null;
    };
  };
};

export type EditRoleTemplateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type EditRoleTemplateMutation = {
  __typename?: 'Mutation';
  editRoleTemplate: {
    __typename?: 'RoleTemplateRes';
    name: string;
    permissions: Array<{ __typename?: 'PermissionRes'; name: string; operation: string; resource: string } | null>;
  };
};

export type GetPresignedUploadUrlQueryVariables = Exact<{
  path: S3Path;
}>;

export type GetPresignedUploadUrlQuery = {
  __typename?: 'Query';
  presignedUploadUrl: { __typename?: 'UrlInfo'; fileKey: string; url: string };
};

export type GetUserQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'UserRes';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    phoneNumber?: string | null;
    permissionTag?: string | null;
    status: UserStatus;
    createdDate: any;
    company: { __typename?: 'Company'; name: string; id: string };
  };
};

export type GetUserRoleByIdQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetUserRoleByIdQuery = {
  __typename?: 'Query';
  user: { __typename?: 'UserRes'; id: string; permissionTag?: string | null; firstName?: string; lastName?: string };
};

export type GetUserRoleTemplatesQueryVariables = Exact<{
  searchCriteria: SearchCriteriaInput;
}>;

export type GetUserRoleTemplatesQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_RoleTemplateRes';
    items: Array<{
      __typename?: 'RoleTemplateRes';
      id: string;
      name: string;
      description?: string | null;
      permissions: Array<{
        __typename?: 'PermissionRes';
        name: string;
        operation: string;
        resource: string;
        description: string;
      } | null>;
    } | null>;
  };
};

export type EditUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser: {
    __typename?: 'UserRes';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    phoneNumber?: string | null;
    permissionTag?: string | null;
    status: UserStatus;
    createdDate: any;
    company: Array<{ __typename?: 'Company'; name: string } | null>;
  };
};

export type GetUserPermissionsQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetUserPermissionsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'UserRes';
    permissions: Array<{ __typename?: 'PermissionDto'; name: string; operation: string; resource: string } | null>;
  };
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'ProfileRes';
    email: string;
    firstName?: string | null;
    id: string;
    companyId: string;
    lastName?: string | null;
    lastPasswordChangeDate?: any | null;
    phoneNumber?: string | null;
    createdDate: any;
    permissionTag?: string | null;
    fileKey?: string | null;
    domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string } | null> | null;
    permissions: Array<{ __typename?: 'PermissionDto'; name: string } | null>;
    profileAvatar?: { __typename?: 'UrlInfo'; url: string } | null;
  };
};

export type UpdatePasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
}>;

export type UpdatePasswordMutation = { __typename?: 'Mutation'; updateProfilePassword: boolean };

export type EditProfileMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  fileKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type EditProfileMutation = {
  __typename?: 'Mutation';
  editProfile: {
    __typename?: 'ProfileRes';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    fileKey?: string | null;
  };
};

export type SwitchUserStateMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  status: UserStatus;
}>;

export type SwitchUserStateMutation = { __typename?: 'Mutation'; switchUserState: boolean };

export type EditUserAccessMutationVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']['input']>;
  request: EditUserAccessReqInput;
}>;

export type EditUserAccessMutation = { __typename?: 'Mutation'; editUserAccess: boolean };

export type GetUserDomainsByIdQueryVariables = Exact<{
  uid: Scalars['String']['input'];
}>;

export type GetUserDomainsByIdQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'UserRes';
    domains?: Array<{ __typename?: 'DomainDto'; id?: string | null; name: string; color: Color } | null> | null;
  };
};

export type GetUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  folderId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  dataItems: {
    __typename?: 'Page_UserRes';
    total: any;
    items: Array<{
      __typename?: 'UserRes';
      createdDate: any;
      email: string;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
      permissionTag?: string | null;
      status: UserStatus;
      userAvatar: {
        url: string;
      };
      company: Array<{ __typename?: 'Company'; name: string; id: string } | null>;
      domains?: Array<{ __typename?: 'DomainDto'; color: Color; name: string } | null> | null;
    } | null>;
  };
};

export type GetUsersWithAccessQuery = {
  dataItems: Array<{
    __typename: "UserAccessToResourceRes",
    accessType: string,
    user: {
      __typename: "UserRes",
      id: string,
      firstName: string,
      lastName: string,
      userAvatar: {
        url: string;
      };
    }
  } | null>
};

export const ContactInfoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contactInfoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyContactInfoRes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'representative' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ContactInfoFragmentFragment, unknown>;
export const LocationFolderResFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'locationFolderRes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LocationFolderRes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LocationFolderResFragment, unknown>;
export const RegionResFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'regionRes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RegionRes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RegionResFragment, unknown>;
export const GetCompaniesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'active' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'column' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'direction' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Direction' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nullPrecedence' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NullPrecedence' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'companies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'active' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'direction' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'column' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'column' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'nullPrecedence' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'nullPrecedence' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'contactInfoFragment' } }]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'contactInfoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyContactInfoRes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'representative' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const CreateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'regionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domainIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'company' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'regionId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'regionId' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'domainIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'domainIds' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyUid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateUserReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyUid' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'user' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const GetCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'representative' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'permissionTag' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCompanyQuery, GetCompanyQueryVariables>;
export const UpdateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactInfo' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCompanyContactInfoReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'company' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'language' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contactInfo' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contactInfo' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const SwitchCompanyStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchCompanyState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isActive' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchCompanyState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isActive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isActive' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SwitchCompanyStateMutation, SwitchCompanyStateMutationVariables>;
export const CreateDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateDomainMutation, CreateDomainMutationVariables>;
export const UpdateDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateDomainMutation, UpdateDomainMutationVariables>;
export const SearchDomainsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchDomains' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'searchDomains' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SearchDomainsQuery, SearchDomainsQueryVariables>;
export const DomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Domain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DomainQuery, DomainQueryVariables>;
export const EquipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Equipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'equipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manufacturer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modelNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specifications' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domainId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fileUrl' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domain' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EquipmentQuery, EquipmentQueryVariables>;
export const EquipmentItemNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EquipmentItemName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'equipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EquipmentItemNameQuery, EquipmentItemNameQueryVariables>;
export const EditEquipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditEquipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'equipment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditEquipmentReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editEquipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'equipment' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditEquipmentMutation, EditEquipmentMutationVariables>;
export const EquipmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Equipments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EquipmentType' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'equipments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'templateDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domainId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manufacturer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modelNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specifications' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domain' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EquipmentsQuery, EquipmentsQueryVariables>;
export const CreateEquipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEquipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'equipment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEquipmentReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEquipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'equipment' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemName' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateEquipmentMutation, CreateEquipmentMutationVariables>;
export const SwitchEquipmentsActiveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchEquipmentsActiveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SwitchEquipmentActiveStateReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchEquipmentsActiveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SwitchEquipmentsActiveStateMutation, SwitchEquipmentsActiveStateMutationVariables>;
export const SwitchEquipmentsArchiveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchEquipmentsArchiveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SwitchEquipmentArchiveStateReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchEquipmentsArchiveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SwitchEquipmentsArchiveStateMutation, SwitchEquipmentsArchiveStateMutationVariables>;
export const SwitchEquipmentParametersArchiveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchEquipmentParametersArchiveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SwitchParameterArchiveStateReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchEquipmentParametersArchiveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SwitchEquipmentParametersArchiveStateMutation,
  SwitchEquipmentParametersArchiveStateMutationVariables
>;
export const SwitchEquipmentParametersActiveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchEquipmentParametersActiveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SwitchParameterActiveStateReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchEquipmentParametersActiveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  SwitchEquipmentParametersActiveStateMutation,
  SwitchEquipmentParametersActiveStateMutationVariables
>;
export const CreateEquipmentParameterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEquipmentParameter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parameter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateParameterReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEquipmentParameter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parameter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parameter' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'equipmentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateEquipmentParameterMutation, CreateEquipmentParameterMutationVariables>;
export const EquipmentParametersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EquipmentParameters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'equipmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParameterType' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'equipmentParameters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'equipmentId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'types' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'types' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'equipmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EquipmentParametersQuery, EquipmentParametersQueryVariables>;
export const GetImageUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImageUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetImageUrlQuery, GetImageUrlQueryVariables>;
export const SearchUnitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchUnits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'searchUnits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SearchUnitsQuery, SearchUnitsQueryVariables>;
export const GetLocationsSidebarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocationsSidebar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationFolderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationFolders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationFolderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locationFolderId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'locationFolderRes' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'groupItems' },
                        name: { kind: 'Name', value: 'locationFolders' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'searchCriteria' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'locationFolderRes' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'groupItems' },
                                    name: { kind: 'Name', value: 'locationFolders' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'searchCriteria' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
                                      }
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'items' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'locationFolderRes' }
                                              },
                                              {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'groupItems' },
                                                name: { kind: 'Name', value: 'locationFolders' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'searchCriteria' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'searchCriteria' }
                                                    }
                                                  }
                                                ],
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'items' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'locationFolderRes' }
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            alias: { kind: 'Name', value: 'groupItems' },
                                                            name: { kind: 'Name', value: 'locationFolders' },
                                                            arguments: [
                                                              {
                                                                kind: 'Argument',
                                                                name: { kind: 'Name', value: 'searchCriteria' },
                                                                value: {
                                                                  kind: 'Variable',
                                                                  name: { kind: 'Name', value: 'searchCriteria' }
                                                                }
                                                              }
                                                            ],
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'items' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                          kind: 'Name',
                                                                          value: 'locationFolderRes'
                                                                        }
                                                                      }
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            }
                                                          }
                                                        ]
                                                      }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'locationFolderRes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LocationFolderRes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLocationsSidebarQuery, GetLocationsSidebarQueryVariables>;
export const CreateLocationFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLocationFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLocationFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationFolder' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateLocationFolderMutation, CreateLocationFolderMutationVariables>;
export const LocationFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocationFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LocationFolderQuery, LocationFolderQueryVariables>;
export const LocationFolderWithStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocationFolderWithStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LocationFolderWithStatusesQuery, LocationFolderWithStatusesQueryVariables>;
export const GetLocationsTabDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocationsTab' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationFolderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'locationFolders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationFolderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locationFolderId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLocationsTabQuery, GetLocationsTabQueryVariables>;
export const LocationFolderByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocationFolderById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'locationFolders' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchCriteria' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'active' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LocationFolderByIdQuery, LocationFolderByIdQueryVariables>;
export const MoveLocationToLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveLocationToLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceLocationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'destinationLocationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveLocationToLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'moveRequest' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sourceLocationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceLocationId' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'destinationLocationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'destinationLocationId' } }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MoveLocationToLocationMutation, MoveLocationToLocationMutationVariables>;
export const SwitchLocationsActiveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchLocationsActiveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'active' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchLocationsActiveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'locationIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'locationIds' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'active' } }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SwitchLocationsActiveStateMutation, SwitchLocationsActiveStateMutationVariables>;
export const SwitchLocationsArchiveStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchLocationsArchiveState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchLocationsArchiveState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'locationIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'locationIds' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'archived' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'archived' } }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SwitchLocationsArchiveStateMutation, SwitchLocationsArchiveStateMutationVariables>;
export const EditLocationFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditLocationFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editLocationFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationFolder' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditLocationFolderMutation, EditLocationFolderMutationVariables>;
export const GetDetailedPermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDetailedPermissions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', alias: { kind: 'Name', value: 'label' }, name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', alias: { kind: 'Name', value: 'value' }, name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'operation' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetDetailedPermissionsQuery, GetDetailedPermissionsQueryVariables>;
export const GetRegionsSidebarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRegionsSidebar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'regions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'regionRes' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'groupItems' },
                        name: { kind: 'Name', value: 'regions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'regionRes' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'groupItems' },
                                    name: { kind: 'Name', value: 'regions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'items' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'regionRes' } },
                                              {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'groupItems' },
                                                name: { kind: 'Name', value: 'regions' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'items' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: { kind: 'Name', value: 'regionRes' }
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            alias: { kind: 'Name', value: 'groupItems' },
                                                            name: { kind: 'Name', value: 'regions' },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: { kind: 'Name', value: 'items' },
                                                                  selectionSet: {
                                                                    kind: 'SelectionSet',
                                                                    selections: [
                                                                      {
                                                                        kind: 'FragmentSpread',
                                                                        name: { kind: 'Name', value: 'regionRes' }
                                                                      }
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            }
                                                          }
                                                        ]
                                                      }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'regionRes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RegionRes' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetRegionsSidebarQuery, GetRegionsSidebarQueryVariables>;
export const CreateRoleTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRoleTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domainIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRoleTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'permissions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'domainIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'domainIds' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateRoleTemplateMutation, CreateRoleTemplateMutationVariables>;
export const GetRoleTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoleTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'roleTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'usageCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'domainIds' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetRoleTemplatesQuery, GetRoleTemplatesQueryVariables>;
export const GetRoleTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoleTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'resource' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetRoleTemplateQuery, GetRoleTemplateQueryVariables>;
export const GetRoleTemplateDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoleTemplateDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roleTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'usageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedDate' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetRoleTemplateDetailsQuery, GetRoleTemplateDetailsQueryVariables>;
export const EditRoleTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditRoleTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domainIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editRoleTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'permissions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'domainIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'domainIds' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'resource' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditRoleTemplateMutation, EditRoleTemplateMutationVariables>;
export const GetPresignedUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPresignedUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'S3Path' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'presignedUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'path' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPresignedUploadUrlQuery, GetPresignedUploadUrlQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissionTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetUserRoleByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserRoleById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissionTag' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUserRoleByIdQuery, GetUserRoleByIdQueryVariables>;
export const GetUserRoleTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserRoleTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchCriteriaInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'roleTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchCriteria' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchCriteria' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'permissions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUserRoleTemplatesQuery, GetUserRoleTemplatesQueryVariables>;
export const EditUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissionTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditUserMutation, EditUserMutationVariables>;
export const GetUserPermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'resource' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>;
export const GetProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPasswordChangeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissionTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileAvatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetProfileQuery, GetProfileQueryVariables>;
export const UpdatePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfilePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const EditProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileKey' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditProfileMutation, EditProfileMutationVariables>;
export const SwitchUserStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchUserState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserStatus' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchUserState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SwitchUserStateMutation, SwitchUserStateMutationVariables>;
export const EditUserAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditUserAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditUserAccessReqInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUserAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditUserAccessMutation, EditUserAccessMutationVariables>;
export const GetUserDomainsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserDomainsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uid' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUserDomainsByIdQuery, GetUserDomainsByIdQueryVariables>;
export const GetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dataItems' },
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'permissions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'permissions' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'folderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'permissionTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
