import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledBlockInfoWithEdit = styled(Box)(() => ({
  borderRadius: 'var(--4, 4px)',
  border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
  padding: '32px 24px',
  margin: '32px 0',
  '.block-info': {
    '&__i': {
      overflow: 'hidden',
      '& > [class*="MuiTypography"]:last-child': {
        margin: '0'
      },
      '& + .block-info__i': {
        margin: '24px 0 0',
        padding: '24px 0 0',
        borderTop: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))'
      },
      '&--row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      '&__left': {
        flex: '0 1 100%',
        padding: '0 20px 0 0',
        '& > [class*="MuiTypography"]:last-child': {
          margin: '0'
        }
      }
    }
  },
  '&.Mui-disabled': {
    border: '1px solid var(--border-disabled, rgba(9, 14, 22, 0.06)) !important',
    '*': {
      pointerEvents: 'none !important',
      color: 'var(--text-disabled, rgba(9, 14, 22, 0.30))  !important'
    },
    '.block-info': {
      '&__i': {
        '& + .block-info__i': {
          borderTopColor: 'var(--border-disabled, rgba(9, 14, 22, 0.06))'
        }
      }
    },
    img: {
      filter: 'grayscale(1)',
      opacity: '0.1'
    }
  }
}));

StyledBlockInfoWithEdit.defaultProps = {
  className: 'block-info'
};
