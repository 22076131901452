import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function EditIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M4.99997 19.0001H6.2365L16.3058 8.94624L15.6769 8.30779L15.0539 7.69434L4.99997 17.7636V19.0001ZM4.40385 20.5001C4.14617 20.5001 3.93111 20.4139 3.75868 20.2414C3.58623 20.069 3.5 19.8539 3.5 19.5962V17.8732C3.5 17.6322 3.5468 17.4005 3.6404 17.178C3.73398 16.9556 3.86282 16.7623 4.02692 16.5982L16.4789 4.16169C16.6237 4.01682 16.7907 3.90721 16.9798 3.83286C17.1689 3.7585 17.3615 3.72131 17.5577 3.72131C17.7602 3.72131 17.9519 3.7585 18.1327 3.83286C18.3134 3.90721 18.4814 4.02195 18.6365 4.17709L19.8384 5.39436C19.9935 5.53925 20.1057 5.70206 20.1749 5.88281C20.2442 6.06358 20.2788 6.25525 20.2788 6.45781C20.2788 6.6437 20.2442 6.83119 20.1749 7.02029C20.1057 7.20939 19.9935 7.38151 19.8384 7.53664L7.40187 19.9732C7.23777 20.1373 7.04451 20.2661 6.82208 20.3597C6.59963 20.4533 6.36789 20.5001 6.12688 20.5001H4.40385ZM16.3058 8.94624L15.6769 8.30779L15.0539 7.69434L16.3058 8.94624Z" />
    </SvgIcon>
  );
}
