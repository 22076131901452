import { FC, MouseEvent, useMemo, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, Switch } from '@mui/material';

import { BaseMenuItem } from '@/separatedModules/components/Dropdown/components/MenuItem';
import { MenuOption } from '@/separatedModules/components/Dropdown/dropdown.config';
import {
  BaseTableDropdown,
  DropdownProps
} from '@/separatedModules/components/Dropdown/TableDropdown/BaseTableDropdown';

import { useTranslation } from '@/i18n';

interface ToggleColumnsVisibilityProps extends DropdownProps {
  menuList: MenuOption[];
  defaultChecked?: boolean;
  onSwitchClick?: (option: MenuOption) => void;
  onChange: () => void;
}

export const ToggleColumnsVisibility: FC<ToggleColumnsVisibilityProps> = ({
  id,
  title,
  menuList,
  hasSearch = true,
  searchPlaceholder = 'Search',
  disabled = false,
  onSwitchClick,
  onChange
}) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchFilter('');
  };

  const handleItemClick = (option: MenuOption) => {
    onSwitchClick?.(option);
  };

  const handleShowAll = () => {
    handleClose();
    onChange();
  };

  const filteredMenuList = useMemo(() => {
    return menuList.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [menuList, searchFilter]);

  return (
    <BaseTableDropdown
      anchorEl={anchorEl}
      id={id}
      title={title}
      disabled={disabled}
      hasSearch={hasSearch}
      searchPlaceholder={searchPlaceholder as string}
      searchValue={searchFilter}
      onSearchChange={setSearchFilter}
      onDropdownClick={handleClick}
      onClose={handleClose}
      footer={
        <>
          <Button size={'small'} variant={'outlined'} onClick={handleShowAll}>
            {tGlobal('filterDropdown.btnShowAll')}
          </Button>
        </>
      }
    >
      {filteredMenuList.map((option) => (
        <BaseMenuItem key={option.key + option.label} onClick={() => handleItemClick(option)}>
          <Box display={'flex'} alignItems={'center'}>
            <Switch size={'small'} checked={option.checked} />

            <Text variant={'bodyMd'} color={'#090E16'} m={0}>
              {option.label}
            </Text>
          </Box>
        </BaseMenuItem>
      ))}
    </BaseTableDropdown>
  );
};
