import { FC } from 'react';

import Grid from '@mui/material/Grid';

import { PageTitle } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface UserSecurityPageProps {}

export const UserSecurityPage: FC<UserSecurityPageProps> = () => {
  const { t: tUser } = useTranslation('user');

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} md={10} xl={8} item>
        <PageTitle title={tUser('security.pageTitle')} subTitle={tUser('security.pageSubTitle')} />
      </Grid>
    </Grid>
  );
};
