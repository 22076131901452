import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_USER_ROLE_TEMPLATES, GetUserRoleTemplatesQuery } from '@/logicLayers/domain';

import { Flex, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { RoleCreationPlate, RolePlate } from '../RolePlate';

interface RolePlatesProps {
  userId: string;
  userCompanyId?: string;
  selectedRoleItem: string;
  onChange: (id?: string) => void;
}

export const RolePlates: FC<RolePlatesProps> = ({ userId, userCompanyId, selectedRoleItem, onChange }) => {
  const navigateTo = useNavigate();

  const { data: dataRoleTemplates, loading: loadingRoleTemplates } = useQuery<GetUserRoleTemplatesQuery>(
    GET_USER_ROLE_TEMPLATES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        searchCriteria: {
          pageable: {
            page: 0,
            pageSize: 1000
          },
          companyId: userCompanyId || undefined
        }
      },
      skip: !userCompanyId
    }
  );

  const roleTemplatesList = dataRoleTemplates?.dataItems?.items;

  const handleCreateNewRoleClick = () => {
    navigateTo(LINKS_PAGES.templatesRolesCreate);
  };

  const handleDetailsRoleClick = (roleId?: string) => {
    if (!roleId) return;

    const url = LINKS_PAGES.templatesRolesRoleDetails.replace(':templateId', roleId);

    navigateTo(url + '?uid=' + userId);
  };

  return (
    <>
      {loadingRoleTemplates ? (
        <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
      ) : (
        <>
          <RoleCreationPlate onClick={handleCreateNewRoleClick} />
          <Flex flexWrap={'wrap'} gap={'24px'} mb={'68px'}>
            {roleTemplatesList?.map((roleTemplate, index) => (
              <RolePlate
                key={index}
                name={roleTemplate?.name}
                description={roleTemplate?.description}
                permissions={roleTemplate?.permissions}
                isSelected={roleTemplate?.id === selectedRoleItem}
                onClick={() => onChange(roleTemplate?.id)}
                onDetailsClick={() => handleDetailsRoleClick(roleTemplate?.id)}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  );
};
