import Box from '@mui/material/Box';

import { NotFoundScreens } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

export const FavoritesTab = () => {
  const { t: tLocations } = useTranslation('locations');

  return (
    <Box
      display={'flex'}
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        '.not-found-screens__img': { mb: '24px !important' },
        '.not-found-screens__subTitle': { color: '#505668' }
      }}
    >
      <NotFoundScreens type={'locations'} subTitle={tLocations('actions.modal.move.emptyState.description')} />
    </Box>
  );
};
