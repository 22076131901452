import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import {
  EDIT_ROUND,
  EDIT_ROUND_PARAMETER,
  EDIT_ROUND_TASK,
  EDIT_ROUND_TASK_PARAMETER,
  RoundRegulationQuery
} from '@/logicLayers/domain';

import { Frequency } from '@/separatedModules/pages/Templates/pages/RoundRegulationItemCreate/components/ActivateRound/helpers';

import { DomainStatusChip, EditInputItem, Flex, ImageLazy } from '@/separatedModules/components';
import { EditDateAndTime } from '@/separatedModules/components/EditItem/EditDateAndTime';
import { EditFrequency } from '@/separatedModules/components/EditItem/EditFrequency';
import { EditRoundParameters } from '@/separatedModules/components/EditItem/EditRoundParameters';
import { EditUsersStaff } from '@/separatedModules/components/EditItem/EditUsersStaff';

import { TEXT_FIELD_REG_EXP } from '@/utils';

import {
  GetEditComponentProps,
  onSubmitUpdateDate,
  onSubmitUpdateFrequency,
  onSubmitUpdateName,
  onSubmitUpdateParameters,
  onSubmitUpdateRoundDescription,
  onSubmitUpdateRoundTaskDescription,
  onSubmitUpdateTaskName,
  onSubmitUpdateTaskUsers,
  onSubmitUpdateUsers,
  ROUND_FIELDS
} from './helpers';

interface ShortInfoProps {
  title: string;
  equipment?: [
    { name: string; domain?: { color: string; name: string }; id: string; fileUrl: { fileKey: string; url: string } }
  ];
  isTask?: boolean;
}

const ShortInfo = ({ title, equipment, isTask }: ShortInfoProps) => {
  return (
    <Box
      sx={{
        borderRadius: 'var(--4, 4px)',
        background: 'var(--background-neutral-hovered, #F7F8F9)',
        padding: '18px'
      }}
    >
      <Text variant={'labelLg'} className="m-0">
        {title}
      </Text>
      {isTask && (
        <Flex marginTop="12px" alignItems="center">
          {equipment?.[0].fileUrl ? (
            <ImageLazy src={equipment?.[0].fileUrl.url} variant={'rectangular'} width={24} height={24} alt={''} />
          ) : (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                flex: '0 0 24px',
                margin: '0',
                background: 'rgba(9, 14, 22, 0.06)',
                border: '1px solid var(--border-inverse, #F7F8F9)'
              }}
            />
          )}
          <Text
            variant={'labelLg'}
            className="m-0"
            sx={{ paddingLeft: '8px', paddingRight: '8px', fontSize: '12px', color: '#505864', fontWeight: 500 }}
          >
            {equipment?.[0].name}
          </Text>
          <DomainStatusChip color={equipment?.[0]?.domain?.color} label={equipment?.[0].domain?.name} fontSize="12px" />
        </Flex>
      )}
    </Box>
  );
};

export const RoundEditProvider = ({
  /* custom fields */
  editType,
  navigateToUrl,
  tGlobal,
  isTask,
  /* Company fields */
  ...restItem
}: GetEditComponentProps) => {
  return editType === ROUND_FIELDS.NAME ? (
    <EditInputItem
      editFieldName={'name'}
      editFieldValue={restItem.dataItems?.name || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldLength')}
      sxTitle={{ marginBottom: '16px' }}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={isTask ? 'Task title' : 'Round regulation title'}
      pageSubTitle={
        isTask
          ? `<ul>
        <li>Provide a descriptive and informative title for the task</li>
        <li>This title serves as a reference point for mechanics when conducting equipment rounds, helping them quickly identify the specific aspect of the equipment they need to monitor</li>
        </ul>`
          : `<ul>
        <li>Provide a descriptive and informative title for the round regulation</li>
        <li>This title serves as a reference point for mechanics when conducting equipment rounds, helping them quickly identify the specific aspect of the equipment they need to monitor</li>
        </ul>`
      }
      inputLabel={'Round regulation title'}
      mutationQuery={isTask ? EDIT_ROUND_TASK : EDIT_ROUND}
      regExp={TEXT_FIELD_REG_EXP}
      ContentAfterTitle={
        <ShortInfo title={restItem.dataItems?.name || ''} equipment={restItem.dataItems?.equipment} isTask={isTask} />
      }
      onSubmitForm={
        isTask
          ? onSubmitUpdateTaskName({ ...restItem } as RoundRegulationQuery)
          : onSubmitUpdateName({ ...restItem } as RoundRegulationQuery)
      }
    />
  ) : editType === ROUND_FIELDS.DESCRIPTION ? (
    <EditInputItem
      editFieldName={'description'}
      editFieldValue={restItem.dataItems?.description || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 400 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={isTask ? 'Task description' : 'Round regulation description'}
      pageSubTitle={
        isTask
          ? 'Provide a clear and concise description of the task. Clearly communicate the objectives and expectations to the service staff'
          : 'Provide a clear and concise description of the round regulation. Clearly communicate the objectives and expectations to the service staff'
      }
      inputLabel={'Description'}
      mutationQuery={isTask ? EDIT_ROUND_TASK : EDIT_ROUND}
      regExp={TEXT_FIELD_REG_EXP}
      regExpMaxLength={400}
      maxContentLength={400}
      sxDescription={{
        '.MuiFormHelperText-filled': {
          justifyContent: 'end'
        },
        '.Mui-error': {
          justifyContent: 'start'
        }
      }}
      rows={9}
      ContentAfterTitle={
        <ShortInfo title={restItem.dataItems?.name || ''} equipment={restItem.dataItems?.equipment} isTask={isTask} />
      }
      multiline={true}
      onSubmitForm={
        isTask
          ? onSubmitUpdateRoundTaskDescription({ ...restItem } as RoundRegulationQuery)
          : onSubmitUpdateRoundDescription({ ...restItem } as RoundRegulationQuery)
      }
    />
  ) : editType === ROUND_FIELDS.FREQUENCY ? (
    <EditFrequency
      editFieldName={'frequency'}
      editFieldValue={restItem.dataItems?.frequency?.frequency || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Round frequency'}
      pageSubTitle={'Determine the frequency at which equipment inspection rounds should occur'}
      mutationQuery={EDIT_ROUND}
      ContentAfterTitle={<ShortInfo title={restItem.dataItems?.name || ''} />}
      onSubmitForm={onSubmitUpdateFrequency({ ...restItem } as RoundRegulationQuery)}
    />
  ) : editType === ROUND_FIELDS.DATE ? (
    <EditDateAndTime
      editFieldName={'date'}
      editFieldValue={restItem.dataItems?.activationTargetDate || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Start date & time'}
      pageSubTitle={
        'Determine the start date & time for activation of scheduled tasks creation for service staff based on the round regulation'
      }
      mutationQuery={isTask ? EDIT_ROUND_TASK : EDIT_ROUND}
      ContentAfterTitle={<ShortInfo title={restItem.dataItems?.name || ''} />}
      onSubmitForm={onSubmitUpdateDate({ ...restItem } as RoundRegulationQuery)}
      frequency={restItem.dataItems?.frequency?.frequency as Frequency}
    />
  ) : editType === ROUND_FIELDS.STAFF ? (
    <EditUsersStaff
      editFieldName={'users'}
      editFieldValue={restItem.dataItems?.serviceStaff.map((staff) => staff?.id) || []}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Select service staff (optional)'}
      pageSubTitle={
        isTask
          ? `Select the service staff (individual or group) to execute the task.</br>
        Choose a user who has access to the domain corresponding to the current equipment template domain`
          : `Select the service staff (individual or group) tasked with conducting equipment inspection rounds.</br>
        Choose a user who has access to the domain corresponding to the current equipment template domain`
      }
      mutationQuery={isTask ? EDIT_ROUND_TASK : EDIT_ROUND}
      ContentAfterTitle={
        <ShortInfo title={restItem.dataItems?.name || ''} equipment={restItem.dataItems?.equipment} isTask={isTask} />
      }
      onSubmitForm={
        isTask
          ? onSubmitUpdateTaskUsers({ ...restItem } as RoundRegulationQuery)
          : onSubmitUpdateUsers({ ...restItem } as RoundRegulationQuery)
      }
    />
  ) : editType === ROUND_FIELDS.PARAMETERS ? (
    <EditRoundParameters
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      mutationQuery={isTask ? EDIT_ROUND_TASK_PARAMETER : EDIT_ROUND_PARAMETER}
      ContentAfterTitle={
        <ShortInfo title={restItem.dataItems?.name || ''} equipment={restItem.dataItems?.equipment} isTask={isTask} />
      }
      selectedItems={restItem.dataItems?.parameters || []}
      onSubmitForm={onSubmitUpdateParameters({ ...restItem } as RoundRegulationQuery)}
    />
  ) : null;
};
