import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog } from 'infinitytechnologies-ui';

import { EditRegionMutation, GET_REGION, GET_REGIONS_SIDEBAR, UPDATE_REGION } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Preloader, PreloaderVariantsE, TextFieldControl } from '@/separatedModules/components';

import { ROLE_NAME_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { FIELD_FOLDER_NAME } from '../../helpers';

interface FormProps {
  [FIELD_FOLDER_NAME]: string;
}

interface RenameLocationModalProps {
  folderId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const RenameFolderModal = ({ folderId, isModalOpen, onCloseModal }: RenameLocationModalProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tCompany } = useTranslation('company');

  const { data: dataRegionFolder, loading: loadingLocationFolder } = useQuery(GET_REGION, {
    variables: {
      id: folderId
    }
  });

  const [EditCompanyFolder] = useMutation<EditRegionMutation>(UPDATE_REGION, {
    variables: {
      id: folderId?.length ? folderId : undefined
    },
    refetchQueries: [GET_REGIONS_SIDEBAR]
  });

  const regionFolder = dataRegionFolder?.region;

  const {
    setValue,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      [FIELD_FOLDER_NAME]: ''
    }
  });

  useEffect(() => {
    if (regionFolder?.name) {
      setValue(FIELD_FOLDER_NAME, regionFolder?.name);
    }
  }, [regionFolder?.name]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();

    reset();
  }, []);

  const handleSubmitForm = handleSubmit((data) => {
    EditCompanyFolder({
      variables: { name: data.name, description: regionFolder.description },
      onCompleted: () => {
        handleCloseModal();

        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.folderUpdated'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  });

  return (
    <Dialog
      headerTitle={tCompany('actions.modal.rename.title')}
      contentFooter={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
            {tCompany('actions.modal.rename.btnCancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!!errors[FIELD_FOLDER_NAME]}
            sx={{ margin: '0 24px 0 0 !important' }}
            onClick={handleSubmitForm}
          >
            {tCompany('actions.modal.rename.btnSave')}
          </Button>
        </div>
      }
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmitForm}>
        {loadingLocationFolder ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '150px !important' }} isContainer />
        ) : (
          <>
            <Controller
              name={FIELD_FOLDER_NAME}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: ROLE_NAME_REG_EXP,
                  message: tGlobal('validation.textFieldLength')
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <TextFieldControl
                    label={tCompany('actions.modal.rename.folderInputLabel')}
                    placeholder={tCompany('actions.modal.rename.folderInputPlaceholder')}
                    name={FIELD_FOLDER_NAME}
                    value={value}
                    validation={{
                      isValid: !errors[FIELD_FOLDER_NAME]?.message,
                      error: errors[FIELD_FOLDER_NAME]?.message
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </>
        )}
      </form>
    </Dialog>
  );
};
