import { useQuery } from '@apollo/client';

import { EquipmentParametersQuery, GET_EQUIPMENT_PARAMETERS } from '@/logicLayers/domain';

export const MATH_OPERATIONS_LIST = [
  {
    id: 0,
    operation: '+'
  },
  {
    id: 1,
    operation: '-'
  },
  {
    id: 2,
    operation: '*'
  },
  {
    id: 3,
    operation: '/'
  },
  {
    id: 4,
    operation: '%'
  },
  {
    id: 5,
    operation: '('
  },
  {
    id: 6,
    operation: ')'
  }
];

export const useData = ({ templateId }: { templateId: string }) => {
  const { data: equipmentParametersData } = useQuery<EquipmentParametersQuery>(GET_EQUIPMENT_PARAMETERS, {
    variables: {
      equipmentId: templateId,
      searchCriteria: {
        pageable: {
          pageSize: 100
        }
      },
      types: ['NUMERIC_FIXED', 'NUMERIC_FORMULA', 'NUMERIC_INPUT'],
      fetchPolicy: 'no-cache'
    }
  });

  return {
    equipmentParametersData
  };
};
