import { useContextState } from '../../../ContextProvider';
import { TaskCustomStep2 } from '../Step2/components';

import { TaskCustomStep3 } from './components';

import { TaskTypes } from '../../../helpers';

export const Step3 = () => {
  const { taskType } = useContextState();

  return taskType === TaskTypes.CUSTOM ? <TaskCustomStep3 /> : <TaskCustomStep2 />;
};
