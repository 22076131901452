import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// import qs from 'qs';
import { API_REST_URL } from '@/config';

import { ApiResponseT } from '@/subsidiaryBinders/types';

import { APIErrorHandler } from './errorHandler';

declare module 'axios' {
  export interface AxiosResponse {
    isSuccess: boolean;
    error: undefined;
  }
}

const DEFAULT_OPTIONS: AxiosRequestConfig = {
  // withCredentials: true,
  baseURL: `${API_REST_URL}`,
  method: 'GET',
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest'
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  }
};

const request = (): AxiosInstance => {
  // Set config defaults when creating the instance
  const instance = axios.create(DEFAULT_OPTIONS);

  instance.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      // Success response
      response.isSuccess = true;
      response.error = undefined;

      return response;
    },
    (error: AxiosError): Promise<ApiResponseT> => {
      // ToDo ToDo LogOut when 401
      // ToDo ToDo Remove
      console.log('instance.interceptors.response error - ', error);

      if (error.request.status === 401) {
        // handleSignOut();
      }
      // Error response
      return Promise.reject(APIErrorHandler(error));
    }
  );

  instance.interceptors.request.use((config) => {
    // config.headers.Authorization = `Bearer ${LocalStorageService.get(ACCESS_TOKEN_NAME)}`;
    // console.log('CookiesService.get(ACCESS_TOKEN_NAME) - ', CookiesService.get(ACCESS_TOKEN_NAME));
    // config.headers.Authorization = `Bearer ${LocalStorageService.get(ACCESS_TOKEN_NAME)}`;

    return config;
  });

  return instance;
};

export const HttpClient = request();
export type HttpClientT = typeof HttpClient;
