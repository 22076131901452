import { ContextConsumer } from './ContextConsumer';
import { ContextProvider } from './ContextProvider';

export const RoundRegulationItemCreate = () => {
  return (
    <ContextProvider>
      <ContextConsumer />
    </ContextProvider>
  );
};
