import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';

import { GET_ROLE_TEMPLATE_BY_ID, GetRoleTemplateQuery } from '@/logicLayers/domain';

import { PageTitle } from '@/separatedModules/components';

export const PageTopBox = () => {
  const { roleId = '' } = useParams();

  const { data: roleTemplateData } = useQuery<GetRoleTemplateQuery>(GET_ROLE_TEMPLATE_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: roleId
    }
  });

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 24px' }}>
      <PageTitle
        title={roleTemplateData?.roleTemplate.name || ''}
        sxTitle={{ margin: '0' }}
        sxDataFoundTitle={{ marginBottom: '0' }}
        sxBox={{ margin: '0 0 0' }}
      />
    </Box>
  );
};
