import { Dispatch, SetStateAction } from 'react';
import { DropzoneState } from 'react-dropzone';
import classNames from 'classnames';
import { Button } from 'infinitytechnologies-ui';

import { AddPhotoIcon } from '@/separatedModules/components';

import { i18n } from '@/i18n/config';

import { ImageUploaderProps, ImageUploaderStateI } from '../ImageUploader/helpers';

// ToDo Refactor
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const fileExtRegEx = /(?:\.([^.]+))?$/;
// const val = file.name.match(/\.[0-9a-z]+$/i);

interface MyDropzoneProps
  extends Pick<DropzoneState, 'getRootProps' | 'getInputProps'>,
    Pick<ImageUploaderProps, 'titleContent' | 'btnUploadPhotoText'> {
  parentState: ImageUploaderStateI;
  handleOpenFileDialog: () => void;
  handleUpdateState: Dispatch<SetStateAction<ImageUploaderStateI>>;
}

export const ImageDropzone = ({
  btnUploadPhotoText,
  titleContent,
  getRootProps,
  getInputProps,
  handleOpenFileDialog
}: MyDropzoneProps) => {
  return (
    <div
      className={classNames('single-iu', {
        // 'single-iu__zone': variant === 'zone',
        // 'single-iu__button': variant === 'button'
        'single-iu__button': true
      })}
    >
      <div {...getRootProps()} className={classNames('single-iu__area')}>
        <input {...getInputProps()} />

        <div className={'single-iu__area__content'}>
          <div className={'single-iu__area__sub-title'}>{titleContent}</div>

          <Button
            variant={'outlined'}
            className={'single-iu__area__btn__upload'}
            startIcon={<AddPhotoIcon />}
            sx={{
              border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
              margin: '24px auto 0 !important'
            }}
            onClick={handleOpenFileDialog}
          >
            {btnUploadPhotoText || i18n.t('imageUploader.btnUploadPhoto', { ns: 'global' })}
          </Button>
        </div>
      </div>

      {/*
      {!error.isValid && error.message ? (
        <FormHelperText className={'single-iu__error Mui-error'} component={'div'}>
          <ErrorOutlineIcon sx={{ fontSize: 16 }} />

          {error.message ? <span className={'MuiFormHelperText-root__text'}>{error.message}</span> : null}
        </FormHelperText>
      ) : null}*/}
    </div>
  );
};
