import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

import { EquipmentParameterRes, ParameterType } from '@/logicLayers/domain';

import {
  ConfigTableI,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  ImageLazy,
  VisibilityIcon,
  VisibilityOffIcon
} from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { i18n } from '@/i18n';

export const CONFIG_TABLE: ConfigTableI<EquipmentParameterRes> = {
  cells: [
    {
      order: 0,
      columnName: 'parameter-title',
      columnClass: 'MuiTableCell-parameter-title',
      title: i18n.t('rounds.parameters.table.cells.title', { ns: 'templates' }),
      Render: ({ title, description, type }, className, index) => {
        const parameterTypeLabels: any = {
          [ParameterType.Boolean]: LINKS_IMAGES.parameterTypeBoolean,
          [ParameterType.DataTime]: LINKS_IMAGES.parameterTypeDateTime,
          [ParameterType.String]: LINKS_IMAGES.parameterTypeString,
          [ParameterType.NumericFixed]: LINKS_IMAGES.parameterTypeNumericFixed,
          [ParameterType.NumericFormula]: LINKS_IMAGES.parameterTypeNumericFormula,
          [ParameterType.NumericInput]: LINKS_IMAGES.parameterTypeNumericInput
        };
        const labels = parameterTypeLabels[type];

        return (
          <TableCell key={index} className={className}>
            {labels ? (
              <ImageLazy
                src={labels}
                variant={'rectangular'}
                width={24}
                height={24}
                alt={`Equipment ${title} - image`}
              />
            ) : (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  flex: '0 0 24px',
                  margin: '0',
                  background: 'rgba(9, 14, 22, 0.06)',
                  border: '1px solid #F7F8F9'
                }}
              />
            )}

            <Box sx={{ margin: '0 0 0 16px' }}>
              {title ? (
                <Text
                  className={'m-0'}
                  variant={'titleSm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {title}
                </Text>
              ) : null}

              {description ? (
                <Text
                  className={'m-0'}
                  variant={'bodySm'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {description}
                </Text>
              ) : (
                EMPTY_CELL_VALUE
              )}
            </Box>
          </TableCell>
        );
      }
    },
    {
      order: 1,
      columnName: 'parameter-visibility',
      columnClass: 'MuiTableCell-parameter-visibility',
      title: i18n.t('rounds.parameters.table.cells.visibility', { ns: 'templates' }),
      Render: ({ type }, className, index) => {
        return (
          <TableCell key={index} className={className}>
            {[ParameterType.NumericFixed.toUpperCase(), ParameterType.NumericFormula.toUpperCase()].includes(type) ? (
              <Text className={'m-0'} variant={'bodyMd'} sx={{ display: 'flex', alignItems: 'center' }}>
                <VisibilityOffIcon sx={{ fontSize: 20, mr: '8px' }} />
                Not visible in round form
              </Text>
            ) : (
              <Text className={'m-0'} variant={'bodyMd'} sx={{ display: 'flex', alignItems: 'center' }}>
                <VisibilityIcon sx={{ fontSize: 20, mr: '8px' }} />
                Visible in round form
              </Text>
            )}
          </TableCell>
        );
      }
    },
    {
      order: 2,
      columnName: 'parameter-type',
      columnClass: 'MuiTableCell-parameter-type',
      title: i18n.t('rounds.parameters.table.cells.type', { ns: 'templates' }),
      Render: ({ type }, className, index) => {
        const parameterTypeLabels: any = {
          [ParameterType.Boolean]: 'Boolean',
          [ParameterType.DataTime]: 'Date & Time',
          [ParameterType.String]: 'String',
          [ParameterType.NumericFixed]: ['Numeric data', 'Fixed value'],
          [ParameterType.NumericFormula]: ['Numeric data', 'Set formula'],
          [ParameterType.NumericInput]: ['Numeric data', 'Data input']
        };
        const labels = parameterTypeLabels[type];

        if (!type) {
          <TableCell key={index} className={className}>
            {EMPTY_CELL_VALUE}
          </TableCell>;
        }

        if (Array.isArray(labels)) {
          return (
            <TableCell key={index} className={className} sx={{ gap: '8px' }}>
              {labels.map((label, index) => (
                <DomainStatusChip key={index} label={label} />
              ))}
            </TableCell>
          );
        } else {
          return (
            <TableCell key={index} className={className}>
              <DomainStatusChip label={labels} />
            </TableCell>
          );
        }
      }
    },
    {
      order: 3,
      columnName: 'parameter-creation-date',
      columnClass: 'MuiTableCell-parameter-creation-date',
      title: i18n.t('rounds.parameters.table.cells.creationDate', { ns: 'templates' }),
      Render: ({ createdDate }, className, index) => {
        return (
          <TableCell key={index} className={className}>
            <Text className={'m-0'} variant={'bodyMd'}>
              {createdDate ? dayjs(createdDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
            </Text>
          </TableCell>
        );
      }
    }
  ],
  pagination: {
    page: [0],
    rowsPerPage: [20],
    rowsPerPageOptions: [20, 50, 100]
  }
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
