import { useCallback, useState } from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
// Import all Froala Editor plugins;
// Import a single Froala Editor plugin.
// import 'froala-editor/js/plugins/align.min.js';
// Import a single Froala Editor plugin.
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';

import { PLUGINS_ENABLED, TOOLBAR_BUTTONS } from './options';

// Import a language file.
// Import a third-party plugin.
// import 'froala-editor/js/third_party/image_tui.min.js';
// import 'froala-editor/js/third_party/embedly.min.js';
// import 'froala-editor/js/third_party/spell_checker.min.js';
// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/table.min.css';

interface FroalaEditorProps {
  direction?: string;
  placeholderText?: string;
  charCounterCount?: boolean;
  onChange?: () => void;
}

export function FroalaEditor({
  direction = 'ltr',
  placeholderText,
  charCounterCount = false,
  onChange
}: FroalaEditorProps) {
  const [model, setModel] = useState('Example Set');

  const handleModelChange = useCallback((e: string) => {
    console.log('FroalaEditor handleChangeModel e - ', e);
    onChange?.();

    setModel(e);
  }, []);

  console.log('FroalaEditor model - ', model);

  return (
    <div id={'froala-editor'}>
      <FroalaEditorComponent
        tag={'textarea'}
        config={{
          direction,
          placeholderText,
          charCounterCount,
          // froala will inline applied styles
          useClasses: false,
          charCounterMax: 150,
          // ToDo Remove style tag in editor content
          htmlAllowedAttrs: ['title', 'href', 'alt', 'src'],
          // ToDo Remove style tag in editor content
          htmlRemoveTags: ['script', 'style', 'base'],
          toolbarButtons: TOOLBAR_BUTTONS,
          pluginsEnabled: PLUGINS_ENABLED
        }}
        onModelChange={handleModelChange}
      />

      {/*
      <FroalaEditorView
        model={model}
      />
      */}
    </div>
  );
}
