import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import { EquipmentRes, EquipmentsQuery, EquipmentType, GET_EQUIPMENTS } from '@/logicLayers/domain';

import { checkTablePagination } from '@/separatedModules/components';

import { ContextStateI, FirstMountState, initState, initStateI, TableProviderProps } from './helpers';

export const ContextState = createContext<ContextStateI>({
  ...initState,
  loadingEquipmentTemplates: false,
  setState: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ProviderState = ({ children }: TableProviderProps) => {
  const [state, setState] = useState<initStateI>(initState);

  const firstMountState = useRef<FirstMountState>({
    isFirstMount: false,
    initCount: 0
  });

  const { data: dataEquipmentTemplates, loading: loadingEquipmentTemplates } = useQuery<EquipmentsQuery>(
    GET_EQUIPMENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        searchCriteria: {
          query: state.filters.search.length ? state.filters.search[0] : undefined,
          domainIds: state.filters.sortByDomains.length ? state.filters.sortByDomains : undefined,
          isActive: state.filters.sortByStatus.length ? Boolean(state.filters.sortByStatus[0].value) : undefined,
          isArchive: false,
          pageable: {
            page: state.pagination.page[0],
            pageSize: state.pagination.rowsPerPage[0]
          },
          sortable: {
            column: state.filters.sortByDate.length ? 'createdDate' : undefined,
            direction: state.filters.sortByDate.length ? state.filters.sortByDate[0].value : undefined,
            nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
          }
        },
        type: EquipmentType.EquipmentTemplate
      }
    }
  );

  const contextValue = useMemo(() => {
    return {
      ...state,
      loadingEquipmentTemplates,
      setState
    };
  }, [state]);

  useEffect(() => {
    if (!dataEquipmentTemplates) return;

    const { isVisiblePagination, rowsPerPageOptions } = checkTablePagination(
      dataEquipmentTemplates?.dataItems.total || 0,
      state.pagination.rowsPerPageOptions
    );

    const bodyDataRows = (dataEquipmentTemplates?.dataItems?.items || []) as EquipmentRes[];
    const resultSearchCount = bodyDataRows?.length || 0;

    if (!firstMountState.current.isFirstMount) {
      firstMountState.current.initCount = dataEquipmentTemplates?.dataItems.total;
      firstMountState.current.isFirstMount = true;
    }

    setState((state) => {
      return {
        ...state,
        isVisiblePagination,
        bodyDataRows,
        loadingEquipmentTemplates,
        resultSearchCount,
        resultTotalCount: firstMountState.current.initCount,
        pagination: {
          ...state.pagination,
          rowsPerPageOptions
        }
      };
    });
  }, [dataEquipmentTemplates, loadingEquipmentTemplates]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
