import Grid from '@mui/material/Grid';

import { PageTitle } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { CompanyDetails } from './components';

export const CompanyPage = () => {
  const { t: tCompany } = useTranslation('company');

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item>
        <PageTitle title={tCompany('overview.pageTitle')} />

        {/* <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer /> */}

        <CompanyDetails />
      </Grid>
    </Grid>
  );
};
