import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ROLE_TEMPLATES, GetRoleTemplatesQuery, SEARCH_DOMAINS, SearchDomainsQuery } from '@/logicLayers/domain';

import { TableDropdownCheckbox } from '@/separatedModules/components';

type TableFiltersBoxProviderProps = {
  tableState: any;
  companyId?: string;
  users: any[];
};

export const TableFiltersBoxProvider = ({ tableState, companyId }: TableFiltersBoxProviderProps) => {
  useEffect(() => {}, []);

  const { data: dataRoleTemplates } = useQuery<GetRoleTemplatesQuery>(GET_ROLE_TEMPLATES, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          page: 0,
          pageSize: 1000
        },
        companyId: companyId || undefined
      }
    }
  });

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    variables: {
      searchCriteria: {
        pageable: {
          page: 0,
          pageSize: 1000
        },
        companyId: companyId || undefined
      }
    }
  });

  const domains = useMemo(() => {
    if (!domainsData?.dataItems?.items) return [];

    return domainsData.dataItems.items?.map((domain) => ({
      label: domain?.name,
      value: domain?.id,
      checked: false,
      disabled: false
    }));
  }, [domainsData?.dataItems?.items]);

  const roles = useMemo(() => {
    if (!dataRoleTemplates?.dataItems?.items) return [];

    return dataRoleTemplates.dataItems.items.map((domain: any) => ({
      label: domain.name,
      value: domain.id,
      checked: false,
      disabled: false
    }));
  }, [dataRoleTemplates?.dataItems?.items]);

  return (
    <>
      <TableDropdownCheckbox
        title={'Domains'}
        // @ts-ignore
        menuList={domains}
        withSelectAll
        withSearch
        disabled={!domains.length}
        onChange={(value) => {
          console.log('onChange Domains value - ', value);
          tableState.handleChangeByType(value);
        }}
      />

      <TableDropdownCheckbox
        title={'Roles'}
        menuList={roles}
        withSelectAll
        withSearch
        disabled={!roles.length}
        onChange={(value) => {
          console.log('onChange Roles value - ', value);
          tableState.handleChangeByType(value);
        }}
      />
    </>
  );
};
