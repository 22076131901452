import { forwardRef, ReactNode } from 'react';

import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';

interface FlexProps {
  children: ReactNode;
}

export const Flex = forwardRef<FlexProps, BoxProps>(({ children, display = 'flex', ...restProps }, ref) => (
  <Box ref={ref} display={display} {...restProps}>
    {children}
  </Box>
));
