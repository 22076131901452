import { FC, MouseEvent, useMemo, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, Switch } from '@mui/material';

import { BaseMenuOption, MenuOption } from '@/separatedModules/components/Dropdown/dropdown.config';
import { BaseTableDropdown } from '@/separatedModules/components/Dropdown/TableDropdown/BaseTableDropdown';

import { useTranslation } from '@/i18n';

import { BaseMenuItem } from '../../';
import { DropdownProps } from '../BaseTableDropdown';

interface SwitchesDropdownProps extends DropdownProps {
  menuList: BaseMenuOption[];
  onSwitchClick?: (option: MenuOption) => void;
  defaultChecked?: boolean;
}

export const SwitchesDropdown: FC<SwitchesDropdownProps> = ({
  id,
  title,
  menuList,
  hasSearch = true,
  searchPlaceholder = 'Search',
  defaultChecked = false,
  disabled = false,
  onSwitchClick,
  onChange
}) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const updatedMenuList = useMemo<MenuOption[]>(() => {
    return menuList.map((item) => {
      const checkedValue = 'checked' in item ? item.checked : defaultChecked;

      return { ...item, checked: checkedValue } as MenuOption;
    });
  }, [menuList]);

  const [selectedItems, setSelectedItems] = useState<MenuOption[]>(updatedMenuList);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchFilter('');
  };

  const handleItemClick = (option: MenuOption) => {
    setSelectedItems((prev) =>
      prev.map((prevColumn) => {
        if (prevColumn.key === option.key) {
          return { ...prevColumn, checked: option.checked };
        }

        return prevColumn;
      })
    );
    onSwitchClick?.(option);
  };

  const handleShowAll = () => {
    setSelectedItems((prev) => {
      const arr = prev.map((prevColumn) => ({ ...prevColumn, checked: false }));
      onChange(arr);

      return arr;
    });
    handleClose();
  };

  const filteredMenuList = useMemo(() => {
    return selectedItems.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [selectedItems, searchFilter]);

  return (
    <BaseTableDropdown
      anchorEl={anchorEl}
      id={id}
      title={title}
      disabled={disabled}
      hasSearch={hasSearch}
      searchPlaceholder={searchPlaceholder as string}
      searchValue={searchFilter}
      onSearchChange={setSearchFilter}
      onDropdownClick={handleClick}
      onClose={handleClose}
      footer={
        <>
          <Button size={'small'} variant={'outlined'} onClick={handleShowAll}>
            {tGlobal('filterDropdown.btnShowAll')}
          </Button>
        </>
      }
    >
      {filteredMenuList.map((option) => (
        <BaseMenuItem
          key={option.key + option.label}
          onClick={() => handleItemClick({ ...option, checked: !option.checked })}
        >
          <Box display="flex" alignItems="center">
            <Switch checked={option.checked} size="small" />

            <Text variant={'bodyMd'} color={'#090E16'} m={0}>
              {option.label}
            </Text>
          </Box>
        </BaseMenuItem>
      ))}
    </BaseTableDropdown>
  );
};
