import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CheckListIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M4.72284 13.6154L7.8254 10.5128C7.94933 10.3889 8.0957 10.3277 8.26451 10.3293C8.4333 10.3309 8.57966 10.3963 8.70359 10.5256C8.81898 10.6515 8.87667 10.7983 8.87667 10.9662C8.87667 11.134 8.81898 11.2799 8.70359 11.4038L5.2709 14.8493C5.12026 15 4.94452 15.0753 4.74367 15.0753C4.54281 15.0753 4.36706 15 4.21642 14.8493L2.5129 13.1458C2.39753 13.0304 2.34117 12.8854 2.34384 12.7107C2.34651 12.536 2.40553 12.391 2.52092 12.2756C2.6363 12.1602 2.78177 12.1025 2.95734 12.1025C3.13291 12.1025 3.27749 12.1602 3.39109 12.2756L4.72284 13.6154ZM4.72284 7.26925L7.8254 4.16666C7.94933 4.04273 8.0957 3.98157 8.26451 3.98319C8.4333 3.98478 8.57966 4.05021 8.70359 4.17948C8.81898 4.30535 8.87667 4.45221 8.87667 4.62004C8.87667 4.78786 8.81898 4.93373 8.70359 5.05766L5.2709 8.50319C5.12026 8.65383 4.94452 8.72914 4.74367 8.72914C4.54281 8.72914 4.36706 8.65383 4.21642 8.50319L2.5129 6.79967C2.39753 6.68428 2.34117 6.53925 2.34384 6.36458C2.34651 6.1899 2.40553 6.04487 2.52092 5.92948C2.6363 5.8141 2.78177 5.75642 2.95734 5.75642C3.13291 5.75642 3.27749 5.8141 3.39109 5.92948L4.72284 7.26925ZM11.4648 13.798C11.2877 13.798 11.1393 13.7381 11.0195 13.6183C10.8997 13.4984 10.8398 13.3499 10.8398 13.1728C10.8398 12.9956 10.8997 12.8472 11.0195 12.7275C11.1393 12.6079 11.2877 12.5481 11.4648 12.5481H17.2981C17.4752 12.5481 17.6236 12.608 17.7434 12.7278C17.8632 12.8477 17.9231 12.9962 17.9231 13.1733C17.9231 13.3505 17.8632 13.4989 17.7434 13.6185C17.6236 13.7382 17.4752 13.798 17.2981 13.798H11.4648ZM11.4648 7.45189C11.2877 7.45189 11.1393 7.39197 11.0195 7.27212C10.8997 7.15229 10.8398 7.00379 10.8398 6.82662C10.8398 6.64947 10.8997 6.50107 11.0195 6.38142C11.1393 6.26176 11.2877 6.20194 11.4648 6.20194H17.2981C17.4752 6.20194 17.6236 6.26185 17.7434 6.38169C17.8632 6.50153 17.9231 6.65003 17.9231 6.82719C17.9231 7.00435 17.8632 7.15276 17.7434 7.27242C17.6236 7.39207 17.4752 7.45189 17.2981 7.45189H11.4648Z" />
    </SvgIcon>
  );
}
