export const Project = {
  root: {}
};

export const Base = {
  root: {
    margin: 0,
    boxShadow: 'none',
    border: '1px solid',
    padding: '0',
    '&:before': {
      opacity: '1 !important'
    },
    '&.Mui-expanded': {
      margin: '0'
    },
    '& + .MuiAccordion-root': {
      borderTop: 'none'
    }
  }
};
