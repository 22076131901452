import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ArrowOutwardIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon width={'18'} height={'18'} viewBox={'0 0 18 18'} fill={'none'} sx={{ fontSize: 18, ...sx }} {...restProps}>
      <path d="M12.1084 5.85283L5.10885 12.8452C5.005 12.949 4.87447 12.9997 4.71727 12.9973C4.56006 12.9949 4.42953 12.9418 4.3257 12.838C4.22185 12.7341 4.16992 12.6048 4.16992 12.45C4.16992 12.2952 4.22185 12.1659 4.3257 12.062L11.318 5.06246H5.17087C5.0115 5.06246 4.8779 5.00854 4.77009 4.90069C4.66229 4.79282 4.60839 4.65918 4.60839 4.49974C4.60839 4.34029 4.66229 4.20672 4.77009 4.09903C4.8779 3.99134 5.0115 3.9375 5.17087 3.9375H12.5555C12.7475 3.9375 12.9085 4.00246 13.0385 4.13239C13.1684 4.26231 13.2334 4.42331 13.2334 4.61537V12C13.2334 12.1594 13.1794 12.2929 13.0716 12.4007C12.9637 12.5086 12.8301 12.5625 12.6706 12.5625C12.5112 12.5625 12.3776 12.5086 12.2699 12.4007C12.1622 12.2929 12.1084 12.1594 12.1084 12V5.85283Z" />
    </SvgIcon>
  );
}
