import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function QrIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="none"
        style={{ width: '16px', height: '16px', marginLeft: '2px' }}
      >
        <path
          d="M2.33203 6.73075V2.93592C2.33203 2.76514 2.38981 2.62203 2.50536 2.50659C2.62081 2.39103 2.76392 2.33325 2.9347 2.33325H6.72953C6.9002 2.33325 7.04331 2.39103 7.15886 2.50659C7.27431 2.62203 7.33203 2.76514 7.33203 2.93592V6.73075C7.33203 6.90142 7.27431 7.04453 7.15886 7.16008C7.04331 7.27553 6.9002 7.33325 6.72953 7.33325H2.9347C2.76392 7.33325 2.62081 7.27553 2.50536 7.16008C2.38981 7.04453 2.33203 6.90142 2.33203 6.73075ZM3.33203 6.33325H6.33203V3.33325H3.33203V6.33325ZM2.33203 13.0639V9.26908C2.33203 9.09842 2.38981 8.95531 2.50536 8.83975C2.62081 8.72431 2.76392 8.66658 2.9347 8.66658H6.72953C6.9002 8.66658 7.04331 8.72431 7.15886 8.83975C7.27431 8.95531 7.33203 9.09842 7.33203 9.26908V13.0639C7.33203 13.2347 7.27431 13.3778 7.15886 13.4933C7.04331 13.6088 6.9002 13.6666 6.72953 13.6666H2.9347C2.76392 13.6666 2.62081 13.6088 2.50536 13.4933C2.38981 13.3778 2.33203 13.2347 2.33203 13.0639ZM3.33203 12.6666H6.33203V9.66658H3.33203V12.6666ZM8.66536 6.73075V2.93592C8.66536 2.76514 8.72309 2.62203 8.83853 2.50659C8.95409 2.39103 9.0972 2.33325 9.26786 2.33325H13.0627C13.2335 2.33325 13.3766 2.39103 13.492 2.50659C13.6076 2.62203 13.6654 2.76514 13.6654 2.93592V6.73075C13.6654 6.90142 13.6076 7.04453 13.492 7.16008C13.3766 7.27553 13.2335 7.33325 13.0627 7.33325H9.26786C9.0972 7.33325 8.95409 7.27553 8.83853 7.16008C8.72309 7.04453 8.66536 6.90142 8.66536 6.73075ZM9.66536 6.33325H12.6654V3.33325H9.66536V6.33325ZM12.4154 13.6666V12.4166H13.6654V13.6666H12.4154ZM8.66536 9.91658V8.66658H9.91536V9.91658H8.66536ZM9.91536 11.1666V9.91658H11.1654V11.1666H9.91536ZM8.66536 12.4166V11.1666H9.91536V12.4166H8.66536ZM9.91536 13.6666V12.4166H11.1654V13.6666H9.91536ZM11.1654 12.4166V11.1666H12.4154V12.4166H11.1654ZM11.1654 9.91658V8.66658H12.4154V9.91658H11.1654ZM12.4154 11.1666V9.91658H13.6654V11.1666H12.4154Z"
          fill="#326CC9"
        />
      </svg>
    </SvgIcon>
  );
}
