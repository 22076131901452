import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function VisibilityIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path d="M12.0024 15.5769C13.1354 15.5769 14.0978 15.1803 14.8895 14.3871C15.6811 13.5939 16.077 12.6308 16.077 11.4977C16.077 10.3646 15.6804 9.40224 14.8872 8.61058C14.094 7.81891 13.1309 7.42308 11.9978 7.42308C10.8647 7.42308 9.90235 7.81966 9.11068 8.61282C8.31901 9.40601 7.92318 10.3692 7.92318 11.5023C7.92318 12.6353 8.31976 13.5977 9.11293 14.3894C9.90611 15.181 10.8693 15.5769 12.0024 15.5769ZM12.0001 14.2C11.2501 14.2 10.6126 13.9375 10.0876 13.4125C9.56258 12.8875 9.30008 12.25 9.30008 11.5C9.30008 10.75 9.56258 10.1125 10.0876 9.58748C10.6126 9.06248 11.2501 8.79998 12.0001 8.79998C12.7501 8.79998 13.3876 9.06248 13.9126 9.58748C14.4376 10.1125 14.7001 10.75 14.7001 11.5C14.7001 12.25 14.4376 12.8875 13.9126 13.4125C13.3876 13.9375 12.7501 14.2 12.0001 14.2ZM12.0001 18.5C9.89496 18.5 7.96966 17.9384 6.22415 16.8153C4.47864 15.6923 3.08537 14.2147 2.04436 12.3827C1.96102 12.2391 1.90012 12.0944 1.86166 11.9485C1.82319 11.8027 1.80396 11.653 1.80396 11.4995C1.80396 11.346 1.82319 11.1965 1.86166 11.051C1.90012 10.9054 1.96102 10.7609 2.04436 10.6173C3.08537 8.78525 4.47864 7.30769 6.22415 6.18463C7.96966 5.06154 9.89496 4.5 12.0001 4.5C14.1052 4.5 16.0305 5.06154 17.776 6.18463C19.5215 7.30769 20.9148 8.78525 21.9558 10.6173C22.0391 10.7609 22.1 10.9056 22.1385 11.0514C22.177 11.1973 22.1962 11.347 22.1962 11.5005C22.1962 11.654 22.177 11.8035 22.1385 11.949C22.1 12.0945 22.0391 12.2391 21.9558 12.3827C20.9148 14.2147 19.5215 15.6923 17.776 16.8153C16.0305 17.9384 14.1052 18.5 12.0001 18.5ZM12.0001 17C13.8834 17 15.6126 16.5041 17.1876 15.5125C18.7626 14.5208 19.9667 13.1833 20.8001 11.5C19.9667 9.81664 18.7626 8.47914 17.1876 7.48748C15.6126 6.49581 13.8834 5.99998 12.0001 5.99998C10.1167 5.99998 8.38758 6.49581 6.81258 7.48748C5.23758 8.47914 4.03341 9.81664 3.20008 11.5C4.03341 13.1833 5.23758 14.5208 6.81258 15.5125C8.38758 16.5041 10.1167 17 12.0001 17Z" />
    </SvgIcon>
  );
}
