import { Button } from 'infinitytechnologies-ui';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';

import { Breadcrumbs } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiButtons, titleFallback: 'Buttons' }
];

export function UiButtonsPage() {
  return (
    <Grid justifyContent={'center'} container row>
      <Grid lg={12} item>
        <div>UiButtonsPage</div>
      </Grid>

      <Grid lg={12} item>
        <Breadcrumbs links={BreadcrumbsLinks} />
      </Grid>

      <Grid lg={12} item>
        <Grid justifyContent={'center'} container row>
          <Grid md={6} lg={4} item>
            <Box>
              <Button size={'small'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label sm
              </Button>
              <Button size={'medium'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label md
              </Button>
              <Button size={'large'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button size={'small'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label sm
              </Button>
              <Button size={'medium'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label md
              </Button>
              <Button size={'large'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button
                size={'small'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label sm
              </Button>
              <Button
                size={'medium'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label md
              </Button>
              <Button
                size={'large'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label lg
              </Button>
            </Box>

            <Box>
              <Button
                size={'small'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label sm
              </Button>
              <Button
                size={'medium'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label md
              </Button>
              <Button
                size={'large'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label lg
              </Button>
            </Box>

            <Box>
              <Button size={'small'}>Label sm</Button>
              <Button size={'medium'}>Label md</Button>
              <Button size={'large'}>Label lg</Button>
            </Box>

            <Box>
              <Button size={'small'} disabled>
                Label sm
              </Button>
              <Button size={'medium'} disabled>
                Label md
              </Button>
              <Button size={'large'} disabled>
                Label lg
              </Button>
            </Box>
          </Grid>

          <Grid md={6} lg={4} item>
            <Box>
              <Button variant={'outlined'} size={'small'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label sm
              </Button>
              <Button variant={'outlined'} size={'medium'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label md
              </Button>
              <Button variant={'outlined'} size={'large'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button variant={'outlined'} size={'small'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label sm
              </Button>
              <Button variant={'outlined'} size={'medium'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label md
              </Button>
              <Button variant={'outlined'} size={'large'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button
                variant={'outlined'}
                size={'small'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label sm
              </Button>
              <Button
                variant={'outlined'}
                size={'medium'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label md
              </Button>
              <Button
                variant={'outlined'}
                size={'large'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label lg
              </Button>
            </Box>

            <Box>
              <Button
                variant={'outlined'}
                size={'small'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label sm
              </Button>
              <Button
                variant={'outlined'}
                size={'medium'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label md
              </Button>
              <Button
                variant={'outlined'}
                size={'large'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label lg
              </Button>
            </Box>

            <Box>
              <Button variant={'outlined'} size={'small'}>
                Label sm
              </Button>
              <Button variant={'outlined'} size={'medium'}>
                Label md
              </Button>
              <Button variant={'outlined'} size={'large'}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button variant={'outlined'} size={'small'} disabled>
                Label sm
              </Button>
              <Button variant={'outlined'} size={'medium'} disabled>
                Label md
              </Button>
              <Button variant={'outlined'} size={'large'} disabled>
                Label lg
              </Button>
            </Box>
          </Grid>

          <Grid md={6} lg={4} item>
            <Box>
              <Button variant={'contained'} size={'small'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label sm
              </Button>
              <Button variant={'contained'} size={'medium'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label md
              </Button>
              <Button variant={'contained'} size={'large'} startIcon={<SvgIcon component={ChevronLeftIcon} />}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button variant={'contained'} size={'small'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label sm
              </Button>
              <Button variant={'contained'} size={'medium'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label md
              </Button>
              <Button variant={'contained'} size={'large'} endIcon={<SvgIcon component={ChevronRightIcon} />}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button
                variant={'contained'}
                size={'small'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label sm
              </Button>
              <Button
                variant={'contained'}
                size={'medium'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label md
              </Button>
              <Button
                variant={'contained'}
                size={'large'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
              >
                Label lg
              </Button>
            </Box>

            <Box>
              <Button
                variant={'contained'}
                size={'small'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label sm
              </Button>
              <Button
                variant={'contained'}
                size={'medium'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label md
              </Button>
              <Button
                variant={'contained'}
                size={'large'}
                startIcon={<SvgIcon component={ChevronLeftIcon} />}
                endIcon={<SvgIcon component={ChevronRightIcon} />}
                disabled
              >
                Label lg
              </Button>
            </Box>

            <Box>
              <Button variant={'contained'} size={'small'}>
                Label sm
              </Button>
              <Button variant={'contained'} size={'medium'}>
                Label md
              </Button>
              <Button variant={'contained'} size={'large'}>
                Label lg
              </Button>
            </Box>

            <Box>
              <Button variant={'contained'} size={'small'} disabled>
                Label sm
              </Button>
              <Button variant={'contained'} size={'medium'} disabled>
                Label md
              </Button>
              <Button variant={'contained'} size={'large'} disabled>
                Label lg
              </Button>
            </Box>
          </Grid>
        </Grid>

        <div className={'md:col-6'}>
          <div className={'flex'}>
            {/*
            <div className={'flex flex-col justify-center mx-3'}>
              <Text className={'mb-5'} size={20} weight={500}>
                Filled
              </Text>
              <IconButton className={'mb-4'} variant={ButtonIconE.FILLED}>
                <EditIcon />
              </IconButton>
              <IconButton className={'mb-4'} variant={ButtonIconE.FILLED}>
                <EditIcon />
              </IconButton>
              <IconButton className={'mb-4'} variant={ButtonIconE.FILLED}>
                <EditIcon />
              </IconButton>
              <IconButton className={'mb-4'} variant={ButtonIconE.FILLED} isDisabled>
                <EditIcon />
              </IconButton>
            </div>*/}
            {/*
            <div className={'flex-col mx-3'}>
              <Text className={'mb-5'} size={20} weight={500}>
                Outlined
              </Text>
              <div className={'flex flex-col items-center'}>
                <IconButton className={'mb-4'} variant={'outlined'}>
                  <EditIcon />
                </IconButton>
                <IconButton className={'mb-4'} variant={ButtonIconE.OUTLINED}>
                  <EditIcon />
                </IconButton>
                <IconButton className={'mb-4'} variant={ButtonIconE.OUTLINED}>
                  <EditIcon />
                </IconButton>
                <IconButton className={'mb-4'} variant={ButtonIconE.OUTLINED} isDisabled>
                  <EditIcon />
                </IconButton>
              </div>
            </div>
            */}
            {/*
            <div className={'flex flex-col mx-3'}>
              <Text className={'mb-5'} size={20} weight={500}>
                Icon
              </Text>
              <IconButton className={'mb-4'}>
                <EditIcon />
              </IconButton>
              <IconButton className={'mb-4'}>
                <EditIcon />
              </IconButton>
              <IconButton className={'mb-4'}>
                <EditIcon />
              </IconButton>
              <IconButton className={'mb-4'} disabled>
                <EditIcon />
              </IconButton>
            </div>*/}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
