import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import { CustomCheckIcon } from '@/separatedModules/components';
import { MenuOption } from '@/separatedModules/components/Dropdown/dropdown.config';
import { BaseTableDropdown } from '@/separatedModules/components/Dropdown/TableDropdown/BaseTableDropdown';

import { useTranslation } from '@/i18n';

import { BaseMenuItem } from '../..';
import { DropdownProps } from '../BaseTableDropdown';

interface ItemsDropdownProps extends Omit<DropdownProps, 'onChange'> {
  hasSelectAll?: boolean;
  defaultChecked?: boolean;
  menuList: MenuOption[];
  onChange: (options: MenuOption[]) => void;
}

export const ItemsDropdown = ({
  id,
  title,
  searchPlaceholder,
  menuList,
  hasSearch = true,
  disabled = false,
  onChange
}: ItemsDropdownProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const [items, setItems] = useState<MenuOption[]>([]);
  const [itemsSaved, setItemsSaved] = useState<MenuOption[]>([]);
  const [selectedItem, setSelectedItem] = useState<MenuOption[]>([]);

  const handleOpenDropdown = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);

    setSearchFilter('');
  };

  const handleItemClick = (option: MenuOption) => {
    const newArray = items.map((i) => {
      return { ...i, checked: option.key === i.key ? !option.checked : false };
    });

    setItems(newArray);
  };

  const handleApplyClick = () => {
    handleCloseDropdown();

    const selected = items.filter((option) => option.checked);

    setSelectedItem(selected);
    setItemsSaved(items);

    onChange?.(selected);
  };

  const handleCancelClick = () => {
    handleCloseDropdown();

    setItems(itemsSaved);
  };

  const filteredItems = useMemo(() => {
    return items.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [items, searchFilter]);

  useEffect(() => {
    setItems(menuList);
    setItemsSaved(menuList);
  }, [menuList]);

  return (
    <BaseTableDropdown
      id={id}
      title={title}
      anchorEl={anchorEl}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchFilter}
      counter={selectedItem.length}
      footer={
        <>
          <Button size={'small'} onClick={handleCancelClick}>
            {tGlobal('filterDropdown.btnCancel')}
          </Button>

          <Button size={'small'} variant={'contained'} onClick={handleApplyClick}>
            {tGlobal('filterDropdown.btnApply')}
          </Button>
        </>
      }
      disabled={disabled}
      hasSearch={hasSearch}
      onSearchChange={setSearchFilter}
      onDropdownClick={handleOpenDropdown}
      onClose={handleCloseDropdown}
    >
      {filteredItems.map((option) => (
        <BaseMenuItem
          key={option.key + option.label}
          selected={option.checked}
          sxMenuItem={{ justifyContent: 'space-between' }}
          onClick={() => handleItemClick(option)}
        >
          <Text variant={'bodyMd'} color={'#090E16'} m={0}>
            {option.label}
          </Text>

          {option.checked && <CustomCheckIcon className={'m-0'} />}
        </BaseMenuItem>
      ))}
    </BaseTableDropdown>
  );
};
