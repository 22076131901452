import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import Grid from '@mui/material/Grid';

import { CREATE_EQUIPMENT_PARAMETER } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Steps } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components';

import {
  ModalDiscardAllChanges,
  MultiStepFooter,
  MultiStepHeader,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { useContextState } from './ContextProvider';

import { DEFAULT_VALUES, FORM_ID, FormProps, ParameterTypeTyped, STRING_BOOLEAN_DATE_PARAMS, useData } from './helpers';

export const ContextConsumer = () => {
  const { templateId = '' } = useParams();
  const { t: tTemplates } = useTranslation('templates');
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const { setState, handleSetPrevStep, parameterType, ...state } = useContextState();

  const [CreateEquipmentParameter, { loading }] = useMutation(CREATE_EQUIPMENT_PARAMETER);
  const { equipmentItemNameData, equipmentItemNameLoading } = useData({ templateId });

  const generatedParameterId = useMemo(() => uuidv4(), []);

  const methods = useForm<FormProps>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const handleSuccessRedirect = () => {
    navigateTo(-1);
  };

  const handleSuccess = () => {
    setState((state) => ({ ...state, isDataCreated: true }));

    AlertService.showAlert({
      title: tGlobal('alertMessages.success.templates.parameters.created'),
      severity: 'success'
    });

    setTimeout(() => {
      setState((state) => ({ ...state, isDataCreated: false }));

      handleSuccessRedirect();
    }, 2500);
  };

  const handleError = (error?: ApolloError) => {
    setState((state) => ({ ...state, isDataCreate: true }));

    const errorObj = JSON.parse(error?.message || '');
    const isInvalidFormula = errorObj.reason === "Invalid formula, reason: 'null'";

    AlertService.showAlert({
      title: (isInvalidFormula && 'Invalid Formula') || tGlobal('alertMessages.errors.base'),
      severity: 'error'
    });
  };

  const handleCreateNumericInputFixedParameter = (data: FormProps) => {
    CreateEquipmentParameter({
      variables: {
        parameter: {
          propertyId: generatedParameterId || undefined,
          equipmentId: templateId || undefined,
          type: parameterType || undefined,
          title: data.title || undefined,
          description: data.description || undefined,
          value: data.value?.replace(/[,]/g, '.') || undefined,
          // @ts-ignore
          unitId: (data.unitId?.value as string) || undefined
        }
      },
      onCompleted(data) {
        if (data?.createEquipmentParameter?.id) {
          handleSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    });
  };

  const handleCreateStringBooleanDateParameter = (data: FormProps) => {
    CreateEquipmentParameter({
      variables: {
        parameter: {
          propertyId: generatedParameterId,
          equipmentId: templateId,
          type: parameterType,
          title: data.title,
          description: data.description
        }
      },
      onCompleted(data) {
        if (data?.createEquipmentParameter?.id) {
          handleSuccess();
        }
      },
      onError() {
        if (parameterType !== ParameterTypeTyped.NUMERIC_FORMULA) {
          handleError();
        }
      }
    });
  };

  const handleSubmitForm = methods.handleSubmit((data) => {
    if (state.currentStep < state.totalSteps) {
      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else if (STRING_BOOLEAN_DATE_PARAMS.includes(parameterType)) {
      handleCreateStringBooleanDateParameter(data);
    } else if (
      parameterType === ParameterTypeTyped.NUMERIC_INPUT ||
      parameterType === ParameterTypeTyped.NUMERIC_FIXED ||
      parameterType === ParameterTypeTyped.NUMERIC_FORMULA
    ) {
      handleCreateNumericInputFixedParameter(data);
    }
  });

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} style={{ width: '100%' }} onSubmit={handleSubmitForm}>
            {equipmentItemNameLoading ? (
              <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '300px !important' }} isContainer />
            ) : equipmentItemNameData?.equipment?.id ? (
              <>
                <ModalDiscardAllChanges navigateToUrl={-1 as any} sxBox={{ margin: '0 0 32px' }} />

                <MultiStepHeader
                  title={tTemplates('createParameterItem.multiStep.title')}
                  titleStep={tGlobal('multiStep.titleStep', {
                    currentStep: state.currentStep,
                    totalSteps: state.totalSteps
                  })}
                  description={
                    <Trans
                      i18nKey={tTemplates('createParameterItem.multiStep.description')}
                      values={{ equipmentName: equipmentItemNameData?.equipment.name }}
                      components={{ span: <span style={{ fontWeight: 600 }} /> }}
                    />
                  }
                  currentStep={state.currentStep}
                  totalStep={state.totalSteps}
                  sxDescription={{ maxWidth: 660 }}
                />

                <Steps step={`step${state.currentStep}`} />

                <MultiStepFooter
                  btnNextStepText={tGlobal('multiStep.btnNextStep')}
                  btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
                  btnCreateTemplateText={tTemplates('createParameterItem.multiStep.btnCreateTemplate')}
                  isLoadingBtnCreate={loading}
                  isDisabledBtnCreate={state.isDataCreated}
                  currentStep={state.currentStep}
                  totalSteps={state.totalSteps}
                  handleSetPrevStep={handleSetPrevStep}
                />
              </>
            ) : (
              <Navigate to={LINKS_PAGES.templatesEquipment} />
            )}
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
