import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import TableBody from '@mui/material/TableBody';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/Templates/pages/Role/components';
import { StyledTableRow } from '@/separatedModules/pages/Templates/pages/Role/components/TableBody/style';
import { CONFIG_TABLE } from '@/separatedModules/pages/Templates/pages/Role/helpers';

import { isSuperAdmin, useIsUserCanCRUD } from '@/utils';

import { useTranslation } from '@/i18n';

import { EditMenu } from './components';

export const TableBodyWrap = () => {
  const location = useLocation();
  const { t: tTemplates } = useTranslation('templates');
  const isUserSuperAdmin = isSuperAdmin();

  const state = useContextState();

  const { handleSelectRow, handleToggleAside } = useContextUpdateState();

  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  return (
    <TableBody>
      {state.bodyDataRows?.map((row) => {
        return (
          <StyledTableRow
            key={row.id}
            className={classNames({
              'Mui-selected': state.selectedItems.includes(row.id as string)
            })}
            onClick={() => {
              row.id && handleSelectRow(row.id);
            }}
            onDoubleClick={() => {
              handleToggleAside();
              row.id && handleSelectRow(row.id);
            }}
          >
            <>
              {CONFIG_TABLE.cells.map((cell, index) => {
                const className = `${CONFIG_TABLE.cells[index].columnClass}`;

                const isMainRole = location.pathname === '/role';
                const shouldHideItemsTemplate = isMainRole && isUserSuperAdmin && cell.columnName === 'itemsTemplate';
                const shouldHideCreatedBy = !isMainRole && cell.columnName === 'createdBy';

                if (shouldHideItemsTemplate || shouldHideCreatedBy) {
                  return null;
                }

                return cell.Render(row, className, index, tTemplates, location);
              })}
            </>

            {isUserCanCRUD && <EditMenu id={row.id} companyId={row.companyId} />}
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
