import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ChipIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'16'}
      height={'16'}
      viewBox={'0 0 16 16'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 16, ...sx }}
      {...restProps}
    >
      <path d="M8.00024 8.70254L4.61817 12.0846C4.52587 12.1769 4.40985 12.2241 4.2701 12.2263C4.13037 12.2284 4.01221 12.1812 3.91564 12.0846C3.81905 11.988 3.77075 11.8709 3.77075 11.7333C3.77075 11.5957 3.81905 11.4786 3.91564 11.3821L7.29769 7.99999L3.91564 4.61792C3.82332 4.52562 3.7761 4.4096 3.77397 4.26986C3.77182 4.13012 3.81905 4.01197 3.91564 3.91539C4.01221 3.8188 4.1293 3.77051 4.2669 3.77051C4.4045 3.77051 4.52159 3.8188 4.61817 3.91539L8.00024 7.29744L11.3823 3.91539C11.4746 3.82308 11.5906 3.77586 11.7304 3.77372C11.8701 3.77158 11.9883 3.8188 12.0848 3.91539C12.1814 4.01197 12.2297 4.12906 12.2297 4.26666C12.2297 4.40426 12.1814 4.52135 12.0848 4.61792L8.70279 7.99999L12.0848 11.3821C12.1771 11.4744 12.2244 11.5904 12.2265 11.7301C12.2286 11.8699 12.1814 11.988 12.0848 12.0846C11.9883 12.1812 11.8712 12.2295 11.7336 12.2295C11.596 12.2295 11.4789 12.1812 11.3823 12.0846L8.00024 8.70254Z" />
    </SvgIcon>
  );
}
