import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Html5Qrcode } from 'html5-qrcode';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import './Scanner.scss';

export const QRScanner = () => {
  const [isEnabled, setEnabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const config = { fps: 10, qrbox: 350 };
    const scanner = new Html5Qrcode('qrCodeContainer');

    const qrScannerStop = async () => {
      if (scanner && scanner.isScanning) {
        await scanner.stop();
      }
    };

    const qrCodeSuccess = (decodedText: string) => {
      setEnabled(false);
      window.location.href = decodedText;
    };

    if (isEnabled) {
      scanner.start({ facingMode: 'environment' }, config, qrCodeSuccess, () => {});
    } else {
      qrScannerStop();
    }

    return () => {
      qrScannerStop();
    };
  }, [isEnabled, navigate]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className="scanner">
      <div id="qrCodeContainer" className="qrCodeContainer" />
      <div className="scannerTitleContainer">
        <ArrowBackIosNewIcon sx={{ color: 'white' }} className="arrowIcon" onClick={handleBackButtonClick} />
        <div className="overlayText">Scan QR Code</div>
      </div>
    </div>
  );
};
