import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled('button')(
  ({ isActive, isRound = false }: { isActive: boolean; isRound: boolean }) => ({
    background: isActive ? '#090E160F' : 'white',
    border: `1px solid ${!isRound ? '#505668' : 'var(--border-default,rgba(9,14,22,0.15))'}`,
    borderRadius: '16px',
    outline: 'none',
    padding: '5px 12px',
    transition: '.25s ease',
    cursor: 'pointer',
    '.MuiSvgIcon-root': {
      width: 'inherit',
      height: 'inherit'
    },

    ':hover': {
      background: '#090E160F'
    },

    ':disabled': {
      pointerEvents: 'none',
      color: '#090E164D',
      borderColor: '#090E160F'
    }
  })
);

export const StyledSearchInput = styled(OutlinedInput)(() => ({
  height: '32px',
  borderRadius: '16px',
  border: '1px solid #090E1626',
  fontSize: '14px',
  paddingLeft: '12px',

  '.MuiSvgIcon-root': {
    color: 'inherit'
  }
}));

interface CounterProps {
  disabled?: boolean;
}

export const StyledCounter = styled('div')(({ disabled }: CounterProps) => ({
  minWidth: '18px',
  height: '18px',
  background: '#020204',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '-4px !important',
  borderRadius: '50%',
  color: '#FFFFFF',
  fontSize: '12px',
  fontWeight: 500,

  ...(disabled && {
    background: '#090E164D'
  })
}));
