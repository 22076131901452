import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { TextFieldControl } from '@/separatedModules/components';

import { ROLE_DESCRIPTION_REG_EXP } from '@/utils';

import { useTranslation } from '@/i18n';

import { PARAMETER_DESCRIPTION, PARAMETER_TITLE } from '../../../../../helpers';
import { checkParameterTypeText, MAX_GUIDE_LENGTH, MIN_GUIDE_LENGTH } from '../helpers';

interface StringBooleanDateTimeStepsProps {
  parameterType: string;
}

export const StringBooleanDateTimeSteps: FC<StringBooleanDateTimeStepsProps> = ({ parameterType }) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tTemplates } = useTranslation('templates');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Grid justifyContent={'center'} sx={{ margin: '0 0 80px 0' }} container row>
      <Grid xs={12} sm={10} md={8} lg={10} item>
        <Controller
          name={PARAMETER_TITLE}
          defaultValue={''}
          control={control}
          rules={{
            required: {
              value: true,
              message: tGlobal('validation.required')
            },
            pattern: {
              value: ROLE_DESCRIPTION_REG_EXP,
              message: tGlobal('validation.descriptionLength', {
                value: 100
              })
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextFieldControl
                title={tTemplates('createParameterItem.parameter.title.title')}
                subTitle={tTemplates('createParameterItem.parameter.title.subTitle', {
                  value: checkParameterTypeText(parameterType)
                })}
                label={tTemplates('createParameterItem.parameter.title.titleLabel')}
                placeholder={tTemplates('createParameterItem.parameter.title.titlePlaceholder')}
                name={PARAMETER_TITLE}
                value={value}
                validation={{
                  isValid: !errors[PARAMETER_TITLE]?.message,
                  error: errors[PARAMETER_TITLE]?.message as string
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />

        <Controller
          name={PARAMETER_DESCRIPTION}
          defaultValue={''}
          control={control}
          rules={{
            required: {
              value: true,
              message: tGlobal('validation.required')
            },
            pattern: {
              value: /^[\w\s`'.\-,#№()_]{3,400}$/,
              message: tGlobal('validation.textFieldMinMaxLength', {
                valueMin: MIN_GUIDE_LENGTH,
                valueMax: MAX_GUIDE_LENGTH
              })
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextFieldControl
                title={tTemplates('createParameterItem.parameter.description.title')}
                subTitle={tTemplates('createParameterItem.parameter.description.subTitle', {
                  value: checkParameterTypeText(parameterType)
                })}
                label={tTemplates('createParameterItem.parameter.description.titleLabel')}
                placeholder={tTemplates('createParameterItem.parameter.description.titlePlaceholder')}
                name={PARAMETER_DESCRIPTION}
                rows={7}
                maxContentLength={400}
                value={value}
                validation={{
                  isValid: !errors[PARAMETER_DESCRIPTION]?.message,
                  error: errors[PARAMETER_DESCRIPTION]?.message as string
                }}
                multiline
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
