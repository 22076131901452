import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PasswordIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M168.75 190.234H31.25C14.0188 190.234 0 176.216 0 158.984C0 141.753 14.0188 127.734 31.25 127.734H168.75C185.981 127.734 200 141.753 200 158.984C200 176.216 185.981 190.234 168.75 190.234Z"
          fill="#F3F4F6"
        />
        <path
          d="M80.4688 168.75C85.8622 168.75 90.2344 164.378 90.2344 158.984C90.2344 153.591 85.8622 149.219 80.4688 149.219C75.0753 149.219 70.7031 153.591 70.7031 158.984C70.7031 164.378 75.0753 168.75 80.4688 168.75Z"
          fill="#6B7280"
        />
        <path
          d="M41.4062 168.75C46.7997 168.75 51.1719 164.378 51.1719 158.984C51.1719 153.591 46.7997 149.219 41.4062 149.219C36.0128 149.219 31.6406 153.591 31.6406 158.984C31.6406 164.378 36.0128 168.75 41.4062 168.75Z"
          fill="#6B7280"
        />
        <path
          d="M123.438 61.3281H76.5625C73.3266 61.3281 70.7031 58.7047 70.7031 55.4688V39.0625C70.7031 22.9082 83.8457 9.76562 100 9.76562C116.154 9.76562 129.297 22.9082 129.297 39.0625V55.4688C129.297 58.7047 126.673 61.3281 123.438 61.3281ZM82.4219 49.6094H117.578V39.0625C117.578 29.3699 109.693 21.4844 100 21.4844C90.3074 21.4844 82.4219 29.3699 82.4219 39.0625V49.6094Z"
          fill="#6B7280"
        />
        <path
          d="M100 9.76562V21.4844C109.693 21.4844 117.578 29.3699 117.578 39.0625V49.6094H100V61.3281H123.438C126.673 61.3281 129.297 58.7047 129.297 55.4688V39.0625C129.297 22.9082 116.154 9.76562 100 9.76562Z"
          fill="#4B5563"
        />
        <path
          d="M137.109 107.812H62.8906C59.6547 107.812 57.0312 105.189 57.0312 101.953V55.4688C57.0312 52.2328 59.6547 49.6094 62.8906 49.6094H137.109C140.345 49.6094 142.969 52.2328 142.969 55.4688V101.953C142.969 105.189 140.345 107.812 137.109 107.812Z"
          fill="#FCA5A5"
        />
        <path
          d="M137.109 49.6094H100V107.812H137.109C140.345 107.812 142.969 105.189 142.969 101.953V55.4688C142.969 52.2328 140.345 49.6094 137.109 49.6094Z"
          fill="#F87171"
        />
        <path
          d="M109.766 75C109.766 71.7641 107.142 69.1406 103.906 69.1406H96.0938C92.8578 69.1406 90.2344 71.7641 90.2344 75C90.2344 77.55 91.8664 79.7137 94.1406 80.5188V82.8125C94.1406 86.0484 96.7641 88.6719 100 88.6719C103.236 88.6719 105.859 86.0484 105.859 82.8125V80.5188C108.134 79.7137 109.766 77.55 109.766 75Z"
          fill="#F87171"
        />
        <path
          d="M168.75 127.734H100V190.234H168.75C185.981 190.234 200 176.216 200 158.984C200 141.753 185.981 127.734 168.75 127.734Z"
          fill="#E5E7EB"
        />
        <path
          d="M119.531 168.75C124.925 168.75 129.297 164.378 129.297 158.984C129.297 153.591 124.925 149.219 119.531 149.219C114.138 149.219 109.766 153.591 109.766 158.984C109.766 164.378 114.138 168.75 119.531 168.75Z"
          fill="#4B5563"
        />
        <path
          d="M158.594 168.75C163.987 168.75 168.359 164.378 168.359 158.984C168.359 153.591 163.987 149.219 158.594 149.219C153.2 149.219 148.828 153.591 148.828 158.984C148.828 164.378 153.2 168.75 158.594 168.75Z"
          fill="#4B5563"
        />
        <path
          d="M103.906 69.1406H100V88.6719C103.236 88.6719 105.859 86.0484 105.859 82.8125V80.5188C108.134 79.7137 109.766 77.55 109.766 75C109.766 71.7641 107.142 69.1406 103.906 69.1406Z"
          fill="#EF4444"
        />
      </svg>
    </SvgIcon>
  );
}
