import { Avatar, Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

import { Alert, Breadcrumbs, Preloader as MyPreloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_BREADCRUMBS, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

const BreadcrumbsLinks = [
  LINKS_BREADCRUMBS[0],
  {
    link: LINKS_PAGES.ui,
    titleFallback: 'Back to UI list'
  },
  { link: LINKS_PAGES.uiOther, titleFallback: 'Other components' }
];

export const UiOtherPage = () => {
  const { t } = useTranslation('global');

  // ToDo Remove
  console.log('UiOtherPage t - ', t);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <Breadcrumbs className={'mb-10'} links={BreadcrumbsLinks} />
      </Grid>

      <Grid xs={12} md={6} lg={4} item>
        <Grid justifyContent={'center'} container row>
          <Grid xs={12} item>
            {/* <VoteCounter className={'mb-5'} votes={254} votesTarget={3000} /> */}

            {/* <Tags className={'mt-3 mb-3'} tags={TAGS_MOCK.tags as any} />*/}
          </Grid>

          <Grid xs={12} item>
            <Text className={'mb-5'} variant={'h5'} sx={{ margin: '0 0 24px' }}>
              Avatars
            </Text>

            {/* Size xs 32x32 */}
            <Grid justifyContent={'center'} container row>
              <Grid xs={12} item>
                <Avatar size={'32'}>
                  {'Viktor'.slice(0, 1)}
                  {'Kryvenda'.slice(0, 1)}
                </Avatar>
              </Grid>

              <Grid xs={12} item>
                <Avatar size={'48'} isProfile>
                  {'Viktor'.slice(0, 1)}
                  {'Kryvenda'.slice(0, 1)}
                </Avatar>
              </Grid>

              <Grid xs={12} item>
                <Avatar
                  size={'64'}
                  src={'https://www.yttags.com/blog/wp-content/uploads/2023/02/image-urls-for-testing.webp'}
                >
                  {'Viktor'.slice(0, 1)}
                  {'Kryvenda'.slice(0, 1)}
                </Avatar>
              </Grid>

              <Grid xs={12} item>
                <Avatar
                  size={'96'}
                  src={'https://www.yttags.com/blog/wp-content/uploads/2023/02/image-urls-for-testing.webp'}
                  isProfile
                >
                  {'Viktor'.slice(0, 1)}
                  {'Kryvenda'.slice(0, 1)}
                </Avatar>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} md={6} lg={4} item>
        <Text className={'mb-5'} variant={'h5'} sx={{ margin: '0 0 24px' }}>
          Preloaders
        </Text>

        <MyPreloader className={'d-none'} variant={PreloaderVariantsE.COMPANY_CIRCLE} />

        <MyPreloader className={'d-none'} variant={PreloaderVariantsE.COMPANY_PLANET} />
      </Grid>

      <Grid xs={12} md={6} lg={4} item>
        <Text className={'mb-5'} variant={'h5'} sx={{ margin: '0 0 24px' }}>
          Preloaders
        </Text>

        <MyPreloader className={'d-none'} />

        <MyPreloader className={'d-none'} variant={PreloaderVariantsE.STACK_BLITZ} />
      </Grid>

      <Grid xs={12} item>
        <Grid justifyContent={'center'} container row>
          <Grid xs={12} lg={6} item sx={{ margin: '0 0 32px' }}>
            <Alert
              variant={'notice'}
              severity={'info'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />

            <Alert
              variant={'notice'}
              severity={'success'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />

            <Alert
              variant={'notice'}
              severity={'warning'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />

            <Alert
              variant={'notice'}
              severity={'error'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />
          </Grid>

          <Grid xs={12} lg={6} item sx={{ margin: '0 0 32px' }}>
            <Alert
              severity={'info'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />

            <Alert
              severity={'success'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />

            <Alert
              severity={'warning'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />

            <Alert
              severity={'error'}
              title={'Company is disabled'}
              content={
                'All the company info is hidden from the system. To enable the company, use the “Enable company” button'
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
