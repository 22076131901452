import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { PageTitle } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface RolePermissionsPageProps {}

export const TemplatesRolesRolePermissionsPage: FC<RolePermissionsPageProps> = () => {
  const [searchParams] = useSearchParams();

  const { t: tTemplates } = useTranslation('templates');

  const roleId = searchParams.get('roleId');

  // ToDo Remove
  console.log('roleId — ', roleId);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        <PageTitle title={tTemplates('detailsRole.permissions.pageTitle')} sxTitle={{ mt: '32px' }} />
      </Grid>
    </Grid>
  );
};
