import { FC, useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Button } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { GET_LOCATIONS_SIDEBAR, MOVE_LOCATION_TO_LOCATION } from '@/logicLayers/domain';
import { useLocations } from '@/logicLayers/infrastructure/redux';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { CreateNewFolderIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { AddLocationModal, FoldersTabs, LocationsProgressBar, TabsContent } from '..';

import { TAB_LIST } from '../helpers';

import { StyledDialog } from './style';

interface MoveLocationModalProps {
  isModalOpen: boolean;
  folderId: string;
  locationName?: string;
  onCloseModal: () => void;
}

const DISABLED_FOR_FOLDER_CREATION = [TAB_LIST[1], TAB_LIST[2]];

export const MoveLocationModal: FC<MoveLocationModalProps> = ({
  isModalOpen,
  folderId,
  locationName,
  onCloseModal
}) => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tLocations } = useTranslation('locations');

  const { isStartMoving, cancelMoving, progressList, destinationData } = useLocations();

  const [isOpenCreateFolderModal, setIsOpenCreateFolderModal] = useState(false);
  const [visibleTabIndex, setVisibleTabIndex] = useState(0);

  const [MoveLocationToLocation] = useMutation(MOVE_LOCATION_TO_LOCATION, {
    variables: {
      sourceLocationId: folderId,
      destinationLocationId: destinationData.destinationLocationId ?? undefined
    },
    refetchQueries: [GET_LOCATIONS_SIDEBAR, 'locationFolders']
  });

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    cancelMoving();
  }, []);

  const handleMoveLocationToLocation = () => {
    MoveLocationToLocation({
      onCompleted: () => {
        AlertService.showAlert({
          title: (
            <Trans
              i18nKey={tGlobal('alertMessages.success.movedSuccessfully')}
              values={{
                sourceLocationName: locationName,
                destinationLocationName: destinationData.destinationLocationName
              }}
              components={{
                p: <p style={{ fontSize: '14px', fontWeight: 400, color: '#505668' }} />,
                span: <span style={{ fontWeight: 600 }} />
              }}
            />
          ),
          severity: 'success'
        });
        handleCloseModal();
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  const handleCreateNewFolder = () => {
    setIsOpenCreateFolderModal(true);
  };

  const handleCloseCreateNewFolder = () => {
    setIsOpenCreateFolderModal(false);
  };

  const handleChangeTab = (tabIndex: number) => {
    setVisibleTabIndex(tabIndex);
  };

  useEffect(() => {
    return () => {
      cancelMoving();
    };
  }, []);

  const newFolderId = progressList.length > 0 ? progressList[progressList.length - 1].id : folderId;

  return (
    <>
      <StyledDialog
        headerTitle={tLocations('actions.modal.move.title', {
          name: locationName
        })}
        headerSubTitle={tLocations('actions.modal.move.subTitle')}
        hideHeaderBorder
        contentFooter={
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              disabled={DISABLED_FOR_FOLDER_CREATION.some((disabledTabItem) => disabledTabItem.id === visibleTabIndex)}
              variant={'text'}
              startIcon={<CreateNewFolderIcon />}
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCreateNewFolder}
            >
              {tLocations('actions.modal.move.btnNewFolder')}
            </Button>

            <Box display={'flex'}>
              <Button variant={'text'} sx={{ margin: '0 24px 0 0 !important' }} onClick={handleCloseModal}>
                {tLocations('actions.modal.move.btnCancel')}
              </Button>

              <Button
                variant={'contained'}
                sx={{ margin: '0 24px 0 0 !important' }}
                onClick={handleMoveLocationToLocation}
              >
                {tLocations('actions.modal.move.btnSave')}
              </Button>
            </Box>
          </Box>
        }
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        {isStartMoving ? (
          <LocationsProgressBar />
        ) : (
          <FoldersTabs tabs={TAB_LIST} onChange={(tabIndex) => handleChangeTab(tabIndex)} />
        )}

        <TabsContent tabIndex={visibleTabIndex} sourceLocationId={folderId} />
      </StyledDialog>

      {isOpenCreateFolderModal ? (
        <AddLocationModal
          isModalOpen
          modalSize={'big'}
          folderId={newFolderId}
          onCloseModal={handleCloseCreateNewFolder}
        />
      ) : null}
    </>
  );
};
