import { i18n } from '@/i18n/config';

export function getBreadcrumbs(locationFolder: any) {
  const breadcrumbs = [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  return breadcrumbs;
}
