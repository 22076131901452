import { AutocompleteOptionT } from '@/separatedModules/components';

export interface EquipmentItem {
  id: number;
  title: string;
  description: string;
  logoPath: string;
  disabled?: boolean;
}

export const EquipmentItemTypes = {
  NEW: 'NEW',
  TEMPLATE: 'TEMPLATE'
};

export const FORM_ID = 'create-equipment-item';

export const FIELD_EQUIPMENT_TITLE = 'name';
export const FIELD_EQUIPMENT_MODEL_NUMBER = 'modelNumber';
export const FIELD_EQUIPMENT_MANUFACTURER = 'manufacturer';
export const FIELD_EQUIPMENT_ITEM_DESCRIPTION = 'itemDescription';
export const FIELD_EQUIPMENT_FILE_KEY = 'fileKey';
export const FIELD_EQUIPMENT_DOMAIN_ID = 'domainId';

export interface FormProps {
  [FIELD_EQUIPMENT_TITLE]: string;
  [FIELD_EQUIPMENT_MODEL_NUMBER]: string;
  [FIELD_EQUIPMENT_MANUFACTURER]: string;
  [FIELD_EQUIPMENT_ITEM_DESCRIPTION]: string;
  [FIELD_EQUIPMENT_FILE_KEY]: string;
  [FIELD_EQUIPMENT_DOMAIN_ID]: AutocompleteOptionT[];
}

export const DEFAULT_VALUES: FormProps = {
  name: '',
  modelNumber: '',
  manufacturer: '',
  itemDescription: '',
  fileKey: '',
  domainId: []
};

export const getBreadcrumbs = (data: any) => {
  const breadcrumbParts =
    data?.locationFolder?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  breadcrumbParts.push(data?.locationFolder?.name);

  return breadcrumbParts
    .filter((item: string | undefined) => item !== undefined)
    .map((item: string) => ({
      titleFallback: item
    }));
};
