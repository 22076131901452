import { memo } from 'react';

import { CompanyRes } from '@/logicLayers/domain';

import { CONFIG_TABLE } from '@/separatedModules/pages/Companies/helpers';

import { useTranslation } from '@/i18n';

interface TableRowCellsProps {
  row: CompanyRes;
}

export const TableRowCells = memo(({ row }: TableRowCellsProps) => {
  const { t: tCompanies } = useTranslation('companies');

  // ToDo Remove Test Table Render
  // console.log('Render TableCells');

  return (
    <>
      {CONFIG_TABLE.cells.map((i, index) => {
        const className = `${CONFIG_TABLE.cells[index].columnClass}`;

        return i.Render(row, className, index, tCompanies);
      })}
    </>
  );
});
