import { Sidebar, SideBarGroup } from '@/separatedModules/containers';

import { LINKS_SIDEBAR_COMPANY_I_MENU } from '@/subsidiaryBinders/constants';

import { NavListSimple } from '../NavListSimple';

interface SideBarCompanyProps {
  companyId: string;
}

export const SideBarCompany = ({ companyId }: SideBarCompanyProps) => (
  <Sidebar>
    <SideBarGroup>
      <NavListSimple menu={LINKS_SIDEBAR_COMPANY_I_MENU} insertValue={companyId} replaceValue={':companyId'} />
    </SideBarGroup>
  </Sidebar>
);
