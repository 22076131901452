import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ChatIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={'200'} height={'200'} viewBox={'0 0 200 200'} fill={'none'} {...props}>
      <g clipPath="url(#clip0_145_15396)">
        <path
          d="M198.573 119.954C200.096 121.712 200.447 124.172 199.393 126.28C198.456 128.389 196.465 129.677 194.122 129.677H158.509L123.833 111.754L47.7652 72.7437C47.4141 70.2831 47.2969 67.823 47.2969 65.2464C47.2969 29.7502 81.8942 0.0341797 123.833 0.0341797C165.773 0.0341797 199.979 29.7502 199.979 65.2464C199.979 79.3043 194.59 92.7758 184.75 103.905L198.573 119.954Z"
          fill="#DBEAFE"
        />
        <path
          d="M198.574 119.954L184.75 103.905C194.59 92.7758 199.979 79.3046 199.979 65.2464C199.979 29.7502 165.772 0.0341797 123.834 0.0341797C123.772 0.0341797 123.71 0.0361328 123.648 0.0361328V111.659L123.834 111.754L158.509 129.677H194.122C196.465 129.677 198.456 128.389 199.394 126.28C200.447 124.172 200.097 121.712 198.574 119.954Z"
          fill="#BFDBFE"
        />
        <path
          d="M82.4421 61.3804C37.2232 61.3804 0.048557 92.425 0.048557 130.614C0.048557 145.961 6.02318 160.721 16.8005 172.67L1.45403 190.242C-0.0690213 192 -0.419803 194.46 0.516527 196.568C1.57122 198.677 3.56224 199.965 5.9056 199.965H88.2991C121.686 199.965 164.836 171.967 164.836 130.614C164.836 92.4246 127.661 61.3804 82.4421 61.3804Z"
          fill="#93C5FD"
        />
        <path
          d="M82.4422 61.3804C82.434 61.3804 82.4262 61.3804 82.418 61.3804V199.966H88.2996C121.686 199.966 164.836 171.968 164.836 130.614C164.836 92.4246 127.661 61.3804 82.4422 61.3804Z"
          fill="#60A5FA"
        />
        <path
          d="M47.2969 141.393C40.8391 141.393 35.582 136.136 35.582 129.678C35.582 123.22 40.8387 117.963 47.2969 117.963C53.7551 117.963 59.0118 123.22 59.0118 129.678C59.0118 136.136 53.7547 141.393 47.2969 141.393Z"
          fill="#1D4ED8"
        />
        <path
          d="M82.4414 117.963C75.9984 117.963 70.7266 123.235 70.7266 129.678C70.7266 136.122 75.9981 141.393 82.4414 141.393C88.8848 141.393 94.1563 136.122 94.1563 129.678C94.1563 123.235 88.884 117.963 82.4414 117.963Z"
          fill="#1D4ED8"
        />
        <path
          d="M117.977 141.393C111.519 141.393 106.262 136.136 106.262 129.678C106.262 123.22 111.518 117.963 117.977 117.963C124.435 117.963 129.691 123.22 129.691 129.678C129.691 136.136 124.434 141.393 117.977 141.393ZM117.977 129.666C117.977 129.672 117.977 129.678 117.977 129.678V129.666Z"
          fill="#1E40AF"
        />
        <path
          d="M94.1563 129.678C94.1563 136.121 88.8848 141.393 82.4414 141.393V117.964C88.884 117.963 94.1563 123.235 94.1563 129.678Z"
          fill="#1E40AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_145_15396">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
