export const Project = {
  root: {}
};

export const Base = {
  root: {
    margin: '0 0 0 20px',
    '& > *': {
      fontFamily: 'inherit'
    },
    '&.MuiFormControl-root-MuiSelect': {
      margin: '12px 0',
      minWidth: '200px',
      width: '100%',
      display: 'flex',
      '&:hover': {
        '.MuiInputLabel-root': {
          color: 'var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))'
        },
        '.MuiInputBase-root': {
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))'
          }
        }
      },
      '.MuiInputLabel-root': {
        color: 'var(--custom-styles-grey-50, rgba(17, 24, 39, 0.50))',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '500',
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(16px, 16px) scale(1)'
        },
        '&.Mui-focused': {
          color: 'var(--Custom-styles-Main-Black, #111827)'
        },
        '&.Mui-error': {
          color: 'var(--Custom-styles-Main-Error, #BD1537)'
        },
        '&.Mui-disabled': {
          opacity: '0.4',
          color: 'var(--custom-styles-grey-30, rgba(17, 24, 39, 0.30))'
        }
      }
    },
    '&.MuiFormControl-root-MuiRadio': {
      margin: '0px'
    }
  }
};
