type CssProperties = Record<string, string>;

export const DOMAIN_STATUS_COLORS: { [index: string]: CssProperties } = {
  DEFAULT: {
    background: 'rgba(9, 14, 22, 0.03)',
    color: '#505668'
  },
  SELECTED: {
    background: '#F2F7FF',
    color: '#0C66E4'
  },
  WARNING: {
    background: '#FFFAE7',
    color: '#946F00'
  },
  SUCCESS: {
    background: '#E6F6EF',
    color: '#06845B'
  },
  DANGER: {
    background: '#FFF5F7',
    color: '#BE0E2C'
  },
  ORANGE: {
    background: '#FFF3EB',
    color: '#A54800'
  },
  PURPLE: {
    background: '#F3F0FF',
    color: '#5E4DB2'
  },
  LIME: {
    background: '#EFFFD6',
    color: '#4C6B1F'
  },
  MAGENTA: {
    background: '#FFECF8',
    color: '#943D73'
  },
  TEAL: {
    background: '#E7F9FF',
    color: '#206A83'
  }
};
