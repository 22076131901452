import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { InfoIcon, SearchAutocomplete } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../Providers';

import { SortByDate, SortByStatus, ToggleColumns } from './components';

import { FiltersContainerWrp } from './style';

export const FiltersContainer = () => {
  const { t: tCompanies } = useTranslation('companies');

  const state = useContextState();
  const { handleToggleAside, handleChangeBySearch } = useContextUpdateState();

  const [checked, setChecked] = useState(true);

  const handleClearSearch = () => handleChangeBySearch('');
  const handleChangeSearch = (value: string) => handleChangeBySearch(value || '');

  useEffect(() => {
    if (state.selectedItems.length) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [state.selectedItems]);

  // ToDo Remove Test Table Render
  console.log('Render FiltersContainer');

  return (
    <FiltersContainerWrp withSearch>
      <Collapse
        classes={{ root: 'MuiCollapse-root-filters', wrapperInner: 'MuiCollapse-wrapperInner-filters' }}
        in={checked}
      >
        <Box>
          <ToggleColumns />

          <SortByStatus />

          <SortByDate />
        </Box>

        <Box>
          <SearchAutocomplete
            placeholder={tCompanies('table.filters.bySearch.placeholder')}
            options={[]}
            sx={{ margin: '0' }}
            onChange={handleChangeSearch}
            onClear={handleClearSearch}
          />

          <IconButton size={'medium'} sx={{ borderRadius: '100%' }} onClick={handleToggleAside}>
            <InfoIcon />
          </IconButton>
        </Box>
      </Collapse>
    </FiltersContainerWrp>
  );
};
