import { styled } from '@mui/material/styles';

export const StyledUserDetails = styled('div')(() => ({
  '&.user-details': {
    display: 'block'
  },
  '&.user-details--full': {},
  '&.user-details--short': {
    padding: '8px 16px',
    margin: '0 0 12px',
    '.user-details': {
      '&__info': {
        margin: '0',
        '&__detail': {
          paddingLeft: '8px'
        }
      }
    }
  },
  '.user-details': {
    '&__info': {
      display: 'flex',
      alignItems: 'center',
      margin: '32px 0 0',
      '&__fio': {
        margin: '0 0 12px'
      },
      '&__detail': {
        display: 'block',
        paddingLeft: '24px'
      },
      '&__email': {
        display: 'flex'
      }
    }
  }
}));
